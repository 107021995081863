import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { COMPANYDB, CompanyDB } from 'src/app/shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { CommonService } from 'src/app/shared/services/common.service';
import { TableService } from 'src/app/shared/services/table.service';
import { BillingAndInvoiceService } from '../services/billing-and-invoice.service';
import { ColDef } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-billing-table',
  templateUrl: './billing-table.component.html',
  styleUrls: ['./billing-table.component.scss'],
  animations:[fadeInOut]
})
export class BillingTableComponent implements OnInit {
  headers = [
    { display: 'Invoice no.', key: 'Invoice_Number' },
    { display: 'Invoice Type', key: 'Invoice_Type' },
    { display: 'Plan Name ', key: 'Plan_Name' },
    { display: 'Enroll ID', key: 'Enroll_ID' },
    { display: 'Tax Amount', key: 'Tax_Amount' },
    { display: 'Revenue Amount', key: 'Revenue_Amount' },
    { display: 'Without Tax Amount', key: 'Without_Tax_Amount' },
    { display: 'Payment Amount', key: 'payment_amount' },
    { display: 'Processing Fee', key: 'processing_fee' },
    { display: 'Processing Tax', key: 'processing_tax' },
    { display: 'Invoice Source', key: 'Invoice_Source' },
    { display: 'Payment Type', key: 'Payment_Type' },
    { display: 'Created Date', key: 'Invoice_Created_Date' },
    { display: 'Reference No', key: 'reference_no' },
  ]

  public data: any = [];
  public column: any[];
  public pageDropdownMenu: any = [10, 20, 30];
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public invoiceTableColumn: ColDef[];
isSkeletonLoader: boolean=true;

  constructor(
    private commonService: CommonService,
    private datePipe: DatePipe,
    private billingAndInvoiceService: BillingAndInvoiceService,
  ) { }

  ngOnInit() {
    this.getInvoice();

    this.invoiceTableColumn = [
      {
        headerName: 'Invoice no.',
        field: 'Invoice_Number',
        // valueFormatter: p => {

        //   return this.datePipe.transform(date, 'MMM d, y') 
        // }
      },
      { headerName: 'Invoice Type', field: 'Invoice_Type' },
      { headerName: 'Plan Name ', field: 'Plan_Name' },

      { headerName: 'Enroll ID', field: 'Enroll_ID' },
      { headerName: 'Tax Amount', field: 'Tax_Amount' },
      {
        headerName: 'Revenue Amount',
        field: 'Revenue_Amount',
      },
      { headerName: 'Without Tax Amount', field: 'Without_Tax_Amount' },
      { headerName: 'Payment Amount', field: 'payment_amount' },

      { headerName: 'Processing Fee', field: 'processing_fee' },
      { headerName: 'Processing Tax', field: 'processing_tax' },
      { headerName: 'Invoice Source', field: 'Invoice_Source' },
      { headerName: 'Payment Type', field: 'Payment_Type' },
      {
        headerName: 'Created Date', field: 'Invoice_Created_Date',
        valueFormatter: p => this.commonService.formateDateAndTime(p.value)
      },
      { headerName: 'Reference No', field: 'reference_no' },
    ]
  }

  onGridLogReady(event) {

  }

  async getInvoice() {

    try {
      let data = {
        "per_page": this.itemsPerPage,
        "page": this.currentPage,
      }
      const res: any = await this.billingAndInvoiceService.getInvoices(data);
      console.log('getInvoices', res)
      if (res.Status) {
        this.data = res.data.data
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url;
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast("error", "Error", res.message)
        this.isSkeletonLoader=false
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
      this.isSkeletonLoader=false
    }

  }

  pageChange(event) {
    this.currentPage = event
    this.getInvoice()
  }
  onChangeNumberOfPage() {
    this.getInvoice()
  }
}