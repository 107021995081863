<div class="search-box-wrapper p-0">
  <form class="form-inline search-full-2 w-100 " [class.open]="navServices.search">
    <div class="form-group w-100 mb-0 m-0 item-search" style="position: relative;">
      <ng-container>
        <!-- Search Icon -->
        <svg focusable="false" class="search-icon" height="24px" viewBox="0 0 24 24" width="24px"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.49,19l-5.73-5.73C15.53,12.2,16,10.91,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.41,0,2.7-0.47,3.77-1.24L19,20.49L20.49,19z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z">
          </path>
          <path d="M0,0h24v24H0V0z" fill="none"></path>
        </svg>
        <!-- Search Input -->
        <input id="searchbar" class="form-control ps-5 search-input w-100" [(ngModel)]="text"
          [ngModelOptions]="{ standalone: true }" (click)="toggleGlobalSearch()" (input)="searchTerms($event)"
          (keydown.enter)="searchTerm($event)" type="text" autocomplete="off" placeholder="Search" autocomplete="off" />
        <!-- (input)="searchTerms($event)"  (click)="toggleGlobalSearch()"-->

        <!-- Filter Icon -->
        <svg xmlns="http://www.w3.org/2000/svg" (click)="toggleAdvanceSearch()" class="filter-icon" width="24"
          height="24" viewBox="0 0 24 24">
          <path
            d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z">
          </path>
        </svg>
        <app-feather-icons [icon]="'x'" class="close-icon" (click)="closeSearch()"></app-feather-icons>
      </ng-container>
    </div>
    <!-- Global Search Component -->
    <!-- Global Search Component with Separate Border -->


  </form>
  <div class="global-search-wrapper m-0" *ngIf="isShowSearch">
    <app-global-search class="global-search-container" [text]="text"
      (closeGlobalSearch)="closeGlobalSearch()"></app-global-search>
  </div>


  <!-- Advanced Search Component -->
  <div class="pt-2 adv-search" style="max-width:100% !important" *ngIf="isShowAdvanceSearch">
    <app-advance-search style="width:100%" (closeAdvanceSearch)="closeAdvanceSearch()"
      (submitAdvanceSearch)="onSubmitAdvanceSearch($event)"></app-advance-search>
    <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
      <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice : 0 : 8">
        <div class="ProfileCard-avatar">
          <app-svg-icon [icon]="menuItem.icon" class="header-search"></app-svg-icon>
        </div>
        <div class="ProfileCard-details">
          <div class="ProfileCard-realName">
            <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{ menuItem?.title }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">Oops! No results found.</div>
      </div>
    </div>
  </div>
</div>