import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class PlanChangeService {

  constructor(
    public http: HttpService
  ) { }

  getPlans(data) {
    try {
      return this.http.get('api/Customer/GetPlans', data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCustomerOtherInfo() {
    try {
      return this.http.get('api/Customer/getCustomerOtherInfo', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


getplanHeaders(){
  let data = {
      // "tagType":'Plan',
      // "typeId":2, 
  }
  try {
    return this.http.get('api/tickets/getTags',data)
  } catch (err) {
    throw Error(err)
  }
}
  getPlanTypes() {
    try {
      return this.http.get('api/orders/getPlanTypes', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  changeCustomerPlan(data) {
    try {
      return this.http.post('api/Customer/ChangeCustomerPlan', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  renewCustomerPlan(data) {
    try {
      return this.http.post('api/Customer/RenewCustomerPlan', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addProducts(data){
    try {
      return this.http.post('api/Customer/AddProducts', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getPrepaidPlans(data){
    try {
      return this.http.get('api/Customer/GetPrePaidPlans', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPlanTax(data){
    try {
      return this.http.get('api/Customer/GetPlanTax', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPlansDetails(data){
    try {
      return this.http.get('api/Customer/GetPlansDetails', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getMinimalprofileData(data){
    try {
      return this.http.get('api/Customer/getMinimalProfileData', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getProratedPlanCost(data){
    try {
      return this.http.post('api/Pre-paid/getProratedPlanCost',data).toPromise()
    } catch (error) {
      throw Error(error)
      
    }
  }
  getPlanProfile(data){
    try {
      return this.http.post('api/Plan/Plan-Profile',data).toPromise()
    } catch (error) {
      throw Error(error)
      
    }
  }


}
