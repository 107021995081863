<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row">
      <div class="col-md-12">
        <h5>Upload</h5>
      </div>
    </div>
 
  <div class="row">
<div class="col-md-4">
  <div class="form-group">
    <label>Select Category</label>
    <select class="form-control form-select">
      <option>Select </option>
      </select>
  </div>
</div>
<div class="col-md-4">
  <div class="form-group">
    <label>Select files from your computer:</label><br>
    <button type="button" class="btn btn-primary">+ Add Files</button>
  </div>
</div>
<div class="col-md-4">
  <div class="form-group">
    <p>
      only jpg, png, pdf, jpeg, bmp, mp3, wav, wma,
      gsm, 3gp, amr, txt, doc, doc files are allowed.
      Upload files
    </p>
    <button type="button" class="btn btn-primary">Upload Files</button>
    </div>
    </div>
    </div>
    <div class="row my-2">
      <div class="col-md-12">
        <h5>Documents</h5>
      </div>
    </div>
    <div class="row">
      <div class="custom-datatable table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" sortable="name" (sort)="onSort($event)">#</th>
                    <th scope="col" sortable="startDate">Proof Category</th>
                    <th scope="col" sortable="invoice" (sort)="onSort($event)">Date Time</th>
                    <th scope="col" sortable="position" (sort)="onSort($event)">Upload By</th>
                    <th scope="col" sortable="credit" (sort)="onSort($event)">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tableItem$ | async">
                    <td scope="row">
                        <ngb-highlight [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="item.startDate" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="item.invoice" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
                    </td>
               
                    <td>
                        <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                  
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-between mt-3">
            <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
            </select>
            <ngb-pagination class="ms-3" [collectionSize]="(total$ | async)!" [(page)]="service.page"
                [pageSize]="service.pageSize"> <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
        </div>
      </div>
    </div>
    </form>
    </div>