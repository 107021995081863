<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    Add-On Topup List</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body ">
  
  <div class="container-fluid">
    <div class="row my-2">
 <div class="col-md-3">
  <label>Plan Name</label>
 </div>
 <div class="col-md-9">
  $75 GB Unlimited Prepaid Plan
 </div>
    </div>

    <div class="row my-2">
      <div class="col-md-6">
       <label>Carrier</label><br>
       <select class="form-control form-select">
        <option>Select </option>
        </select></div>
        </div>
        <div class="mb-3 custom-datatable table-responsive">
          <table class="table">
              <thead>
                  <tr>
                      <th scope="col" sortable="name" (sort)="onSort($event)">Add/Remove</th>
                      <th scope="col" sortable="startDate">Topup Plan Name</th>
                      <th scope="col" sortable="invoice" (sort)="onSort($event)">Wholesale Topup Code</th>
                      <th scope="col" sortable="position" (sort)="onSort($event)">Carrier</th>
                      <th scope="col" sortable="credit" (sort)="onSort($event)">Topup Cost</th>
                    </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of tableItem$ | async">
                      <td scope="row">
                          <ngb-highlight [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                          <ngb-highlight [result]="item.invoice" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                          <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                      <td>
                          <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                    
                  </tr>
              </tbody>
          </table>
          <div class="d-flex justify-content-between mt-3">
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                  <option [ngValue]="2">2 items per page</option>
                  <option [ngValue]="4">4 items per page</option>
                  <option [ngValue]="6">6 items per page</option>
              </select>
              <ngb-pagination class="ms-3" [collectionSize]="(total$ | async)!" [(page)]="service.page"
                  [pageSize]="service.pageSize"> <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
          </div>
        </div>

<div class="my-3">
  <b>Selected Topup List:</b>
</div>

        <div class="custom-datatable table-responsive">
          <table class="table">
              <thead>
                  <tr>
                      <th scope="col" sortable="name" (sort)="onSort($event)">Add/Remove</th>
                      <th scope="col" sortable="startDate">Topup Plan Name</th>
                      <th scope="col" sortable="invoice" (sort)="onSort($event)">Wholesale Topup Code</th>
                      <th scope="col" sortable="position" (sort)="onSort($event)">Carrier</th>
                      <th scope="col" sortable="credit" (sort)="onSort($event)">Topup Cost</th>
                    </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of tableItem$ | async">
                      <td scope="row">
                          <ngb-highlight [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                          <ngb-highlight [result]="item.invoice" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                          <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                      <td>
                          <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                    
                  </tr>
              </tbody>
          </table>
          <div class="d-flex justify-content-between mt-3">
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                  <option [ngValue]="2">2 items per page</option>
                  <option [ngValue]="4">4 items per page</option>
                  <option [ngValue]="6">6 items per page</option>
              </select>
              <ngb-pagination class="ms-3" [collectionSize]="(total$ | async)!" [(page)]="service.page"
                  [pageSize]="service.pageSize"> <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
          </div>
        </div>
  </div>


</div>
<div class="modal-footer justify-content-center">
  <button type="button" ngbAutofocus class="btn btn-primary me-3" (click)="modal.close('Ok click')">Allow topup</button>
  <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">Cancel</button>

</div>