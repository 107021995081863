import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent {
  @Input() customerDetails
  constructor(private http: HttpService, private customerProfileService: CustomerProfileService, private router: Router) {

  }

  ngOnInit(): void {
    console.log("customer details 1",this.customerDetails)
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }



  openMap() {
    const address = this.customerDetails?.personalInfo?.billingAddress?.value;
    if (address) {
      const fullAddress = `${address.address1}, ${address.city}, ${address.state}, ${address.zipCode}`;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
      window.open(googleMapsUrl, '_blank');
    }
  }

  getStatusBadgeClass(status: string): any {
    switch (status) {
      case 'Active':
        return 'badge-success';
      case 'New':
        return 'badge-primary';
      case 'Deactivated':
        return 'badge-warning';
      case 'Suspended':
        return 'badge-danger';
      case 'Pending':
        return 'badge-danger';
      default:
        return '';
    }
  }

  async sendToCustomerDashboard() {
    await this.customerProfileService.setCustomerId(this.customerDetails?.personalInfo?.customerId)
    this.customerProfileService.getMinimalProfileData()
    this.router.navigate(['/customer-dashboard/customer-profile'], { queryParams: { showBack: true } });
  }
}
