<!-- <ng-container *ngIf="!isChildLoader">
  <div class="loader-wrapper" [ngClass]="[isChildLoader ? 'position-absolute' : 'position-fixed']">
    <div class="loader-box">
      <div class="loader-25"></div>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="isChildLoader">
  <div class="loader-container {{class}}">
    <div class="loader-box">
      <div [class]="variant"></div>
    </div>
  </div>

  <!-- <div class="loader-box">
    <div class="loader-12"></div>
  </div> -->
</ng-container>