import { ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommonService } from 'src/app/shared/services/common.service';
import { TicketSystemService } from '../services/ticket-system.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-group-table',
  templateUrl: './group-table.component.html',
  styleUrls: ['./group-table.component.scss'],
  animations:[fadeInOut]
})
export class GroupTableComponent {

  public data = [];

  public column: any[];

  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public isEmptyRow: boolean = false;
  public startDate: Date;
  public endDate: Date;

  public filteredData = [];
  public conditionalData = [];
  public inputFilterValue: string;
  public datePickerConfig: Partial<BsDatepickerConfig>;

  public showHideMore: boolean = true;
  private sourceTypeData = [];

  public inputElementRef: HTMLInputElement = null;
  public groupId: any = null;
  public singleGroupData: any = null;
  loader: boolean = false;
isSkeletonLoader: boolean=true;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public ticketSystemService: TicketSystemService,
    public commonService: CommonService
  ) {
    this.column = [
      {
        displayName: '#',
        mappingKey: ['id'],
      },
      {
        displayName: 'Code',
        mappingKey: ['Code'],
      },
      {
        displayName: 'Group Type ID',
        mappingKey: ['GroupTypeID'],
      },
      {
        displayName: 'Description',
        mappingKey: ['Description'],
      },
      {
        displayName: 'Action',
        mappingKey: [''],
      }
    ]

    const minYear: number = new Date().getFullYear() - 100;
    const maxYear: number = new Date().getFullYear();

    const minDate = new Date();
    minDate.setFullYear(minYear);

    const maxDate = new Date();
    maxDate.setFullYear(maxYear);

    this.datePickerConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      minDate,
      maxDate
    });

  }

  filterTableByInputRef(element: HTMLInputElement): void {
    this.inputElementRef = element;
    const value = element.value.trim().toLowerCase();
    this.inputFilterValue = value;
    let data;
    if (this.sourceTypeData.length > 0) {
      data = this.sourceTypeData.filter(record =>
        Object.values(record).join(' ').toLowerCase().includes(value)
      )
    }
    else {
      data = this.conditionalData.filter(record =>
        Object.values(record).join(' ').toLowerCase().includes(value)
      )
    }
    this.conditionalData = data;
    this.paginationUpdate()
  }

  ngOnInit() {
    this.conditionalData = [...this.data];
    this.totalItems = this.conditionalData.length;
    this.getGroups()
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  paginationUpdate() {
    this.totalItems = this.conditionalData.length;
    this.isEmptyRow = this.conditionalData.length === 0 ? true : false;
  }

  onEdit(data) {
    console.log('onedit', data)
    this.groupId = data.id
    this.singleGroupData = data
  }

  async getGroups() {
    this.loader = true;
    try {
      const res: any = await this.ticketSystemService.getGroups()
      console.log("get groups res", res)
      if (res.Status) {
        this.data = res.data
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast('error', "Error", res.message)
        this.isSkeletonLoader=false
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.isSkeletonLoader=false
    }
    this.loader = false;
  }

  addEditGroupEvent() {
    this.singleGroupData = null
    this.groupId = null
    this.getGroups()
  }

}

