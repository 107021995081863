<ng-container *ngIf="mapping[0] !== 'status'; else status">
    <ng-container *ngIf="mapping[0] === 'amountPaid'; else other">
        <span class="text-end">{{cellData | uppercase}}</span>
    </ng-container>
    <ng-template  #other>
        <ng-container *ngIf="mapping[0] !== 'orderIdUrl' && mapping[0] !== 'nladHistoryUrl'; else url">
            <span>{{cellData}}</span>
        </ng-container>
        <ng-template #url>
            <ng-container *ngIf="mapping[0] === 'orderIdUrl'; else modalUrl">
                <a [href]="cellData" class="d-flex text-center">
                    <app-feather-icons icon="link"></app-feather-icons>
                     <span class="p-l-10">{{convertIntoLink(cellData)}}</span>
                </a>
            </ng-container>
            <ng-template #modalUrl>
                <a role="button"(click)="openModal()">Click to modal</a>
            </ng-template>    
        </ng-template>
    </ng-template>
</ng-container>
<ng-template #status>
    <ng-container *ngIf="cellData === 'rejected'">
        <span class="font-danger">{{cellData | uppercase}}</span>
    </ng-container>
    <ng-container *ngIf="cellData === 'completed'">
        <span class="font-success">{{cellData | uppercase}}</span>
    </ng-container>
    <ng-container *ngIf="cellData === 'pending'">
        <span class="font-info">{{cellData | uppercase}}</span>
    </ng-container>
    <ng-container *ngIf="cellData === 'approved'">
        <span class="font-primary">{{cellData | uppercase}}</span>
    </ng-container>
</ng-template>

