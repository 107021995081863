<div class="container mt-0" style="max-width: 100%;">
    <div class="card form-card shadow main-card mt-0">
      <!-- Card Header -->
      <div class="card-header text-white  bg-primary d-flex justify-content-between align-items-center">
        <h5 class="card-title mb-0" style="font-size: 1.5rem;letter-spacing: 2px;">CUSTOMER EVENTS</h5>
        <app-feather-icons (click)="toggleCustomerEventModel()"
                        class="ms-2 text-light cursor-pointer" [icon]="'list'"></app-feather-icons>
        
      </div>
  
      <!-- Card Body with Form -->
      <div class="card-body p-3">
        <!-- Outer card body with padding -->
        <div class="card-body pt-0" style="height: 70vh; overflow-y: auto;" (scroll)="onScroll($event)">
            <!-- Scrollable content with nice spacing and height -->
    
          
            <ng-container *ngIf="showCustomerEventAsList1">
                <div class="row sticky-thead thead-dark" style="height: 3rem;">
                    <div class="col-4 p-2 fs-6 fw-bold" style="white-space: nowrap;">
                        <b>Event Type</b>
                    </div>
                    <div class="col-4 p-2 fs-6 fw-bold" style="white-space: nowrap;">
                        <b>Date </b>
                    </div>
                    <div class="col-4 p-2 fs-6 fw-bold" style="white-space: nowrap;">
                        <b>Description</b>
                    </div>
                    <div class="col-12 p-2 fs-6 fw-bold" style="white-space: nowrap;">
                        <hr>
                    </div>
                </div>
                <ng-container > 
                    <div class="row"
                        *ngFor="let item of moreInfoData">
                        <div class="col-4">
                            <ng-container *ngIf="item?.createdBy?.value">
                                <h5>{{item?.createdByUser?.value}}
                                    <span class="needInline" style="color: gray; font-size:small">
                                      
                                            [{{item?.createdBy?.value}}]
                                        
                                    </span>
                                </h5>
                            </ng-container>
                        </div>
                        <div class="col-4">
                            <p>{{item?.timeLine?.value | date : 'MMM d, y, h:mm:ss'}}</p>
                        </div>
                        <div class="col-4">
                            <p>{{item?.activityDetail?.value}}</p>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <!-- Timeline (non-list) view -->
            <ul *ngIf="!showCustomerEventAsList1" class="timeline-list p-0">
                <li class="d-flex mb-4" *ngFor="let item of moreInfoData">
                    <div class="activity-dot-primary"></div>
                    <div class="w-100 ms-3">
                        <p class="d-flex justify-content-between mb-2">
                            <span class="badge bg-light text-secondary p-2 rounded">
                                {{ item.timeLine?.value | date : 'MMM d, y, h:mm:ss' }}
                            </span>
                        </p>
                        <ng-container *ngIf="item?.createdBy?.value">
                            <h6 class="mb-1 text-secondary">{{ item?.createdByUser?.value }}
                                <span class="text-muted" style="font-size: 0.85rem;">
                                    [{{ item?.createdBy?.value }}]
                                </span>
                            </h6>
                        </ng-container>
                        <p class="text-secondary">{{ item.activityDetail?.value }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    
  
      <!-- Card Footer -->
      <!-- <div class="card-footer bg-primary"></div> -->
    </div>
  </div>
  
  <div *ngIf="isLoading" class="card-loader">
    <app-loader [isChildLoader]="true"></app-loader>
  </div>