<form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
    <div class="right-sidebar">
        <div class="sidebar-header bg-light text-dark ">
            <div *ngIf="!isEditingTitle && ticketForm.get('Title')?.value; else titleInput"
                (click)="startEditingTitle()">
                <h4>
                    {{ ticketForm.get('Title')?.value }}
                </h4>
            </div>
            <ng-template #titleInput>
                <div class="w-100">
                    <h5 *ngIf="this.mainTask?.Title">
                        Creating sub ticket for {{ this.mainTask?.Title}}
                    </h5>
                    <textarea #inputRef class="comment-input mb-0" rows="2" placeholder="Add a Title..."
                        formControlName="Title" (focus)="startEditingTitle()" (blur)="stopEditingTitle()"></textarea>
                </div>
            </ng-template>
            <button class="close-btn" (click)="closeSidebar()">
                <app-feather-icons [icon]="'x'" class="close-icon"></app-feather-icons>
            </button>
        </div>

        <div class="sidebar-content">
            <!-- Assignee Section -->
            <div class="form-group d-flex gap-2 mb-0">
                <label class="col-md-3 p-0">Assign Ticket To </label>
                <div class="col-md-5 pe-0 d-flex align-items-center ">
                    <div class="form-check form-check-inline pe-3 ps-0 d-flex align-items-center">
                        <input class="form-check-input" type="radio" formControlName="AssignmentType" id="inlineRadio1"
                            value="AGENT" (change)="onAssignmentTypeChange()">
                        <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio1">User</label>
                    </div>
                    <div class="form-check form-check-inline pe-3 d-flex align-items-center">
                        <input class="form-check-input" type="radio" formControlName="AssignmentType" id="inlineRadio2"
                            value="GROUP" (change)="onAssignmentTypeChange()">
                        <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio2">Group</label>
                    </div>
                </div>
                <div class="col-md-4  px-0  d-flex align-items-center">
                    <label class="col-md-3 p-0 ms-2">Priority </label>
                    <ng-select class="w-100 " formControlName="PriorityId" [multiple]="false">
                        <ng-option [value]="item.id" *ngFor="let item of priorityList">
                            <span>{{ item.Name }}</span>
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="section assignee-section row d-flex align-items-center">
                <label class="col-md-3">Assignee </label>
                <div class="col-md-6 d-flex align-items-center">
                    <div role="button" aria-label="Toggle assignee popover" aria-haspopup="listbox" class="icon-wrapper"
                        aria-disabled="false" tabindex="0">
                        <div class="AvatarPlaceholder">
                            <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                <path
                                    d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <ng-select class="w-100 mb-2"
                        *ngIf="ticketForm.get('AssignmentType')?.value === 'AGENT' ||ticketForm.get('AssignmentType')?.value === 'USER'"
                        formControlName="AgentID" [multiple]="false">
                        <ng-option [value]="item.id" *ngFor="let item of allAgents">
                            <div class="avatar-dropdown">
                                <div class="avatar-circle mx-2" [style.background-color]="getColor(item.name)">
                                    {{ getInitials(item.name) }}
                                </div>
                                <span class="user-name">{{ item.name }}</span>
                            </div>
                        </ng-option>
                    </ng-select>
                    <ng-select class="w-100 mb-2" *ngIf="ticketForm.get('AssignmentType')?.value === 'GROUP'"
                        formControlName="GroupID" [multiple]="false">
                        <ng-option [value]="item.id" *ngFor="let item of allGroups">
                            <div class="avatar-dropdown">
                                <div class="avatar-circle" [style.background-color]="getColor(item.Name)">
                                    {{ getInitials(item.Name) }}
                                </div>
                                <span class="user-name">{{ item.Name }}</span>
                            </div>
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="section assignee-section row d-flex align-items-center">
                <label class="col-md-3">Due Date </label>
                <div class="col-md-6 d-flex align-items-center">
                    <div role="button" aria-label="Toggle assignee popover" aria-haspopup="listbox" class="icon-wrapper"
                        aria-disabled="false" tabindex="0">
                        <div class="AvatarPlaceholder">
                            <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                <path
                                    d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <input id="bsdatepicker" type="text" class="form-control mb-2" #dp="bsDatepicker" bsDatepicker
                        formControlName="DueDate" placeholder="mm-dd-yy">
                    <!-- <input type="date" placeholder="mm-dd-yy" class="form-control mb-2" formControlName="DueDate" /> -->
                </div>
            </div>

            <div class="row">
                <div class="col-6 my-2">
                    <div class="form-group">
                        <label>Status </label>
                        <ng-select class="w-100 mb-2" formControlName="StatusID" [multiple]="false">
                            <ng-option [value]="item.id" *ngFor="let item of allStatusList">
                                <span class="user-name">{{item.Name}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-6 my-2">
                    <div class="form-group">
                        <label>Ticket Type </label>
                        <ng-select class="w-100 mb-2" formControlName="TicketTypeID" [multiple]="false">
                            <ng-option *ngFor="let ticket of allTicketTypesList" [value]="ticket.id">
                                <span class="user-name">{{ticket.Name}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 position-relative">
                    <label class="w-100">Customer Id</label>

                    <input class="form-control" (blur)="getCustomerData()" type="text" formControlName="CustomerId">
                    <span *ngIf="isCustomerFound" class="position-absolute"
                        style="top: 60%; right: 20px; transform: translateY(-50%);">
                        <i class="fa fa-check text-success"></i>
                    </span>
                    <span *ngIf="showcrossIcon" class="position-absolute"
                        style="top: 60%; right: 20px; transform: translateY(-50%);">
                        <i class="fa fa-times text-danger" aria-hidden="true"></i>
                    </span>

                </div>
                <div class="col-md-6">
                    <label class="w-100">Customer Name</label>
                    <input class="form-control" type="text" formControlName="CustomerName">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="w-100">Contact Number</label>
                    <input class="form-control" type="text" formControlName="ContactNumber">
                </div>
                <div class="col-md-6">
                    <label class="w-100">Alternate Number</label>
                    <input class="form-control" type="text" formControlName="AlternateNumber">
                </div>
            </div>
            <div class="d-flex py-2" *ngIf="!this.isSubTicket && !isSubticketMain">
                <div class="ButtonTrain-item">
                    <div role="button" class="add-subticket-btn" tabindex="0" (click)="onAddSubTicketClick()">
                        <svg class="MiniIcon ThemeableRectangularButtonPresentation-leftIcon PlusMiniIcon"
                            viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                            <path
                                d="M10,10V4c0-1.1,0.9-2,2-2s2,0.9,2,2v6h6c1.1,0,2,0.9,2,2s-0.9,2-2,2h-6v6c0,1.1-0.9,2-2,2s-2-0.9-2-2v-6H4c-1.1,0-2-0.9-2-2s0.9-2,2-2H10z">
                            </path>
                        </svg>Add sub ticket
                    </div>
                </div>
            </div>
            <div class="section description-section">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="col-md-3 p-0">Description</label>
                    <app-feather-icons *ngIf="!this.descriptionConfig.showToolbar" class="toolbar-icon" [icon]="'eye'"
                        (click)="toggleToolbar('descriptionConfig')"></app-feather-icons>
                    <app-feather-icons *ngIf="this.descriptionConfig.showToolbar" class="toolbar-icon"
                        [icon]="'eye-off'" (click)="toggleToolbar('descriptionConfig')"></app-feather-icons>
                </div>
                <angular-editor [placeholder]="'Enter text here...'" formControlName="Description"
                    [config]="descriptionConfig"></angular-editor>
                <div *ngIf="attachments?.length>0">
                    <div class="d-flex justify-content-center px-3 py-1" *ngFor="let img of this.attachments">
                        <img class="w-100 attachments" [src]="img.FileName">
                    </div>
                </div>
            </div>
            <div class="mt-3" *ngIf="subtaskList?.length>0">
                <label class="p-0">Sub Tickets</label>
                <div class="d-flex flex-column gap-1 ">
                    <div class=" ItemRow" *ngFor="let item of subtaskList" (click)="setChildrenToMain(item)">
                        <div>
                            <i class="fa fa-check-circle-o"></i>
                            <span class="p-3">{{item.Title}}</span>
                        </div>
                        <div class="hover-show d-flex align-items-center gap-2 ">
                            <div class="AvatarPlaceholder">
                                <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true"
                                    focusable="false">
                                    <path
                                        d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                                    </path>
                                </svg>
                            </div>
                            <!-- <div class="avatar-dropdown">
                                <div class="avatar-circle" [style.background-color]="getColor(item.name)">
                                    {{ getInitials(item.name) }}
                                </div>
                                <span class="user-name">{{ item.name }}</span>
                            </div> -->
                            <div class="AvatarPlaceholder">
                                <svg class="MiniIcon CalendarMiniIcon" viewBox="0 0 24 24" aria-hidden="true"
                                    focusable="false">
                                    <path
                                        d="M19.1,2H18V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H8V1c0-0.6-0.4-1-1-1S6,0.4,6,1v1H4.9C2.2,2,0,4.2,0,6.9v12.1  C0,21.8,2.2,24,4.9,24h14.1c2.7,0,4.9-2.2,4.9-4.9V6.9C24,4.2,21.8,2,19.1,2z M4.9,4H6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h8v1  c0,0.6,0.4,1,1,1s1-0.4,1-1V4h1.1C20.7,4,22,5.3,22,6.9V8H2V6.9C2,5.3,3.3,4,4.9,4z M19.1,22H4.9C3.3,22,2,20.7,2,19.1V10h20v9.1  C22,20.7,20.7,22,19.1,22z">
                                    </path>
                                </svg>
                            </div>
                            <i class="fa fa-angle-right"></i>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="section comments-section mt-2 bg-light  text-dark">
            <div class="comments-list sidebar-footer bg-light text-dark">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" (navChange)="onNavChange($event)"
                    class="nav-tabs mb-2">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Comments</a>
                        <ng-template ngbNavContent>
                            <div *ngFor="let comment of ticketForm.get('comments')?.value || []"
                                class="comment-item mb-3">
                                <div class="d-flex align-items-center gap-3">
                                    <div class="avatar-dropdown">
                                        <div class="avatar-circle m-0"
                                            [style.background-color]="getColor(comment?.created_by_user?.name)">
                                            {{ getInitials(comment?.created_by_user?.name) }}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column  gap-1 ">
                                        <div class="d-flex align-items-center gap-2">
                                            <span class="fw-semibold">{{ comment?.created_by_user?.name }}</span>
                                            <span class="comment-date text-muted">{{ formatDate(comment?.CreatedDate)
                                                }}</span>
                                        </div>
                                        <span [innerHTML]="comment?.CommentText"></span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ticketForm.get('comments')?.value.length==0"
                                class="d-flex p-3 justify-content-center align-items-center">
                                <span>No comments found</span>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>All activity</a>
                        <ng-template ngbNavContent>
                            <div *ngFor="let item of activityList || []" class="comment-item mb-2">
                                <div class="d-flex align-items-center gap-3">
                                    <div class="avatar-dropdown">
                                        <div class="avatar-circle m-0" [style.background-color]="getColor(item?.User)">
                                            {{ getInitials(item?.User) }}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column  gap-1 ">
                                        <div class="d-flex align-items-center gap-2">
                                            <span class="fw-semibold">{{ item?.User }}</span>
                                            <span class="comment-date text-muted">{{item?.CreatedDate }}</span>
                                        </div>
                                        <span [innerHTML]="item?.Activity" *ngIf="item.Type=='activity'"></span>
                                        <span [innerHTML]="item?.Comment" *ngIf="item.Type=='comment'"></span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="activityList.length==0"
                                class="d-flex p-3 justify-content-center align-items-center">
                                <span>No activity found</span>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" cla ss="mt-2"></div>
            </div>
            <div class="sticky-editor-wrapper bg-light text-dark">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="col-md-3 p-0">Comments</label>
                    <app-feather-icons *ngIf="!this.commentsConfig.showToolbar" class="toolbar-icon" [icon]="'eye'"
                        (click)="toggleToolbar('commentsConfig')"></app-feather-icons>
                    <app-feather-icons *ngIf="this.commentsConfig.showToolbar" class="toolbar-icon" [icon]="'eye-off'"
                        (click)="toggleToolbar('commentsConfig')"></app-feather-icons>
                </div>
                <angular-editor [placeholder]="'Enter text here...'" formControlName="CommentText"
                    [config]="commentsConfig"></angular-editor>
                <button class="btn btn-primary btn-sm mt-2 cmtbtn" type="button" (click)="onCommentAdd()"
                    [disabled]="!ticketId" [ngClass]="{'cursor-disabled': !ticketId}">Comment</button>
            </div>
            <div class="d-flex gap-2 justify-content-end p-2">
                <button class="btn-primary btn btn-sm mt-2" type="submit">{{isEditing?"Save Changes":"Save"}}</button>
            </div>
        </div>
    </div>
</form>