<!-- <div class="container-fluid tab-form-wrapper">
  <form [formGroup]="formData" *ngIf="!loader">
    <div class="row">
      <div class="col-md-12">
        <h5>
          CSR Screen For Change APS/WPS
        </h5>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>Phone Number</label>
      </div>
      <div class="col-md-9">
        <input type="text" readonly formControlName="phoneNumber" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Change APS/WPS To</label>
      </div>
      <div class="col-md-9">
        <select class="form-control form-select" formControlName="aps">
          <option value="">Select APS</option>
          <option *ngFor="let aps of apsCodeOptions" [value]="aps.id">{{aps.Description}}</option>
        </select>
      </div>
    </div>


    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" [disabled]="formData.invalid" (click)="onSubmit()" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</div> -->

<div class="container pt-0 pb-4 px-0 shadow-sm rounded custom-card" *ngIf="!isSkeletonLoader">
  <form [formGroup]="formData" *ngIf="!loader">
    <div class="card-header bg-light ">
      <div class="col-12 text-start py-2">
        <h4 class="fw-bold text-primary">CSR Screen For Change APS/WPS</h4>
      </div>
    </div>
  
    <div class="row g-3 mb-3 mt-3 mx-2">
      <div class="col-md-6">
        <label class="fw-semibold me-2">Phone Number</label>
        <input type="text" readonly formControlName="phoneNumber" class="form-control" placeholder="Enter Phone Number">
      </div>
  
      <div class="col-md-6">
        <label class="fw-semibold me-2">Change APS/WPS To</label>
        <select class="form-control form-select" formControlName="aps">
          <option value="">Select APS</option>
          <option *ngFor="let aps of apsCodeOptions" [value]="aps.id">{{aps.Description}}</option>
        </select>
      </div>
    </div>
  
    <div class="row mx-2">
      <div class="col-12 text-end">
        <button type="button" [disabled]="formData.invalid" (click)="onSubmit()" class="btn btn-primary px-4 py-2">
          Submit
        </button>
      </div>
    </div>
  </form>
  
</div>

<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
  <app-skeleton-loader  shape="payment-form"></app-skeleton-loader>
</div>