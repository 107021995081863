import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { CommonService } from "src/app/shared/services/common.service";
import { NavService } from "src/app/shared/services/nav.service";
import { PermissionService } from "src/app/shared/services/permission-service.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private navService: NavService,
    private permissionService :PermissionService
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() { }

  async login() {
    try {
      if (!this.loginForm.valid) return;

      const loginData = {
        "userName": this.loginForm.value.email,
        "password": this.loginForm.value.password,
        "companyId": "UNITYCRM0021"
      };

      const res: any = await this.authService.login(loginData);

      if (!res?.data?.unity?.Status && !res?.data?.mingle?.Status) {
        this.commonService.showToast("error", "Error", res?.data?.unity?.message || res?.data?.mingle?.message || "Login failed.");
        return;
      }

      const applications = [];
      let mingleData: any = null;
      let unityData: any = null;
      const currentCompany = localStorage.getItem("currentCompany");

      // Process each company's data and store it in the appropriate variable
      if (res?.data?.unity?.Status) {
        const { companyId, Token, refreshToken, data, host, icon } = res?.data?.unity;
        this.authService.setToken(companyId, Token);
        this.authService.setRefreshToken(companyId, refreshToken);
        this.authService.setUser(companyId, data?.[0]);
        unityData = res?.data?.unity;

        applications.push({ name: companyId, BaseUrl: `${host}/`, icon });
      }

      if (res?.data?.mingle?.Status) {
        const { companyId, Token, refreshToken, data, host, icon } = res?.data?.mingle;
        this.authService.setToken(companyId, Token);
        this.authService.setRefreshToken(companyId, refreshToken);
        this.authService.setUser(companyId, data?.[0]);
        mingleData = res?.data?.mingle;

        applications.push({ name: companyId, BaseUrl: `${host}/`, icon });
      }

      // Determine which company to set based on available data and currentCompany
      if (mingleData && !unityData) {
        // Only Mingle access
        this.setCompanyData(mingleData, 'mingle');
      } else if (unityData && !mingleData) {
        // Only Unity access
        this.setCompanyData(unityData, 'unity');
      } else if (mingleData && unityData) {
        // Both Mingle and Unity access
        if (currentCompany === "mingle") {
          this.setCompanyData(mingleData, 'mingle');
        } else if (currentCompany === "unity") {
          this.setCompanyData(unityData, 'unity');
        } else {
          // Default to Unity if no currentCompany is set
          this.setCompanyData(unityData, 'unity');
        }
      }

      // Save applications and navigate to the dashboard
      localStorage.setItem('Applications', JSON.stringify(applications));
      this.loadSideBarmenu();
      this.router.navigateByUrl(res?.data?.unity?.data?.[0]?.redirect_path ?? res?.data?.mingle?.data?.[0]?.redirect_path ?? '/dashboard');

    } catch (err) {
      const errorMessage = err?.error?.message ?? 'An error occurred during login.';
      this.commonService.showToast("error", "Error", errorMessage);
    } finally {
      this.commonService.stopLoader();
    }
  }


  // Helper function to set the company's data (Tokens, User, Base URL)
  setCompanyData(companyData: any, companyId: string) {
    const { Token, refreshToken, data, host } = companyData;

    this.authService.setToken(companyId, Token);
    this.authService.setRefreshToken(companyId, refreshToken);
    this.authService.setBaseUser(data?.[0]);

    localStorage.setItem("BaseUrl", `${host}/`);
    localStorage.setItem("currentCompany", companyId);
  }

  showPassword() {
    this.show = !this.show
  }
  async loadSideBarmenu() {
    try {
      const res: any = await this.permissionService.fetchAllResourceCategories(); // Await the API call
      const data = res.data;
      for (const val of data) {
        await this.permissionService.addResourceCategory(val); // Await each IndexedDB addition
      }
      const updatedCategories = await this.permissionService.getAllResourceCategories();
    } catch (error) {
      console.error('Error:', error);
    }
    await this.navService.sideMenuInitilize();
    this.navService.getSideMenuBar();
  }




}
