<div class=" cust data-usage-container " *ngIf="usageInfoData?.liveUsage && !isUsageSkeletonLoader" @fadeInOut>
    <!-- <p class="title ">Data usage statistics</p> -->
    <div class="d-flex col-md-12 justify-content-center">
        <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart" [dataLabels]="chartOptions?.dataLabels"
            [plotOptions]="chartOptions?.plotOptions" [yaxis]="chartOptions?.yaxis" [xaxis]="chartOptions?.xaxis"
            [fill]="chartOptions?.fill" [title]="chartOptions?.title" [labels]="chartOptions?.labels"
            [legend]="chartOptions?.legend" [colors]="chartOptions?.colors"></apx-chart>
    </div>
    <ul class="usage-list" *ngIf="chartOptions?.series && chartOptions?.labels">
        <li *ngFor="let label of chartOptions.labels; let i = index">
            <span>
                <span class="color-indicator" [ngStyle]="{'background-color': chartOptions.colors[i]}"></span>
                {{ label }}
            </span>

            <span class="usage-value " *ngIf="label.trim() === 'Mobile Data'"
                [ngStyle]="{'color': chartOptions.colors[i]}">
                {{remainingMobileData}} / {{ planLimitValue1 }}
            </span>

            <span class="usage-value " *ngIf="label == 'Hotspot Data'" [ngStyle]="{'color': chartOptions.colors[i]}">
                {{ hotspotDataValue }} /
                {{ usageLimitValue }}
            </span>
            <span class="usage-value " *ngIf="label.trim() === 'Remaining Data'"
                [ngStyle]="{'color': chartOptions.colors[i]}">
                {{ remainingDataValue }} / {{ planLimitValue }}
            </span>
            <span class="usage-value " *ngIf="label.trim() === 'Total Used Data'"
                [ngStyle]="{'color': chartOptions.colors[i]}">
                {{ totalUsedDataValue }} / {{ planLimitValue }}
            </span>
        </li>
    </ul>
    <div class="mx-3 mt-3" style="color:black">
        <ng-container *ngIf="usageInfoData?.liveUsage?.voice?.limit>0">
            <p class="mb-0 usage-value">Call ({{usageInfoData?.liveUsage?.voice?.used}} /
                {{usageInfoData?.liveUsage?.voice?.limit}})</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar"
                    [style.width]="(usageInfoData?.liveUsage?.voice?.used/usageInfoData?.liveUsage?.voice?.limit )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.voice?.used/usageInfoData?.liveUsage?.voice?.limit
                    )*100 | number : '1.2-2'}} %</div>
            </div>
        </ng-container>

        <ng-container *ngIf="usageInfoData?.liveUsage?.sms?.limit>0">
            <p class="mb-0 usage-value">SMS ({{usageInfoData?.liveUsage?.sms?.used}} /
                {{usageInfoData?.liveUsage?.sms?.limit}})</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar"
                    [style.width]="(usageInfoData?.liveUsage?.sms?.used/usageInfoData?.liveUsage?.sms?.limit )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.sms?.used/usageInfoData?.liveUsage?.sms?.limit
                    )*100 | number : '1.2-2'}} %
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usageInfoData?.liveUsage?.boltonData?.full?.limit>0">
            <p class="mb-0 usage-value">TOPUP DATA
                ({{(usageInfoData?.liveUsage?.boltonData?.full?.used / 1024) |
                number:'1.2-2' }} GB /
                {{(usageInfoData?.liveUsage?.boltonData?.full?.limit/ 1024) |
                number:'1.2-2' }} GB )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.boltonData?.full?.used/usageInfoData?.liveUsage?.boltonData?.full?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.boltonData?.full?.used/usageInfoData?.liveUsage?.boltonData?.full?.limit
                    )*100 | number : '1.2-2'}} %
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usageInfoData?.liveUsage?.roaming?.limit > 0">
            <p class="mb-0 usage-value">Roaming
                ({{(usageInfoData?.liveUsage?.roaming?.used) }} /
                {{(usageInfoData?.liveUsage?.roaming?.limit) }} )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.roaming?.used/usageInfoData?.liveUsage?.roaming?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.roaming?.used/usageInfoData?.liveUsage?.roaming?.limit
                    )*100 }} %
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usageInfoData?.liveUsage?.ild?.packageBalance1 > 0">
            <p class="mb-0 usage-value">Package Balance 2
                ({{(usageInfoData?.liveUsage?.ildUsage?.packageBalance1?.used) }} /
                {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance1?.limit) }} )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.ildUsage?.packageBalance1?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance1?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance1?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance1?.limit
                    )*100 }} %
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usageInfoData?.liveUsage?.ild?.packageBalance2 > 0">
            <p class="mb-0 usage-value">Package Balance 1
                ({{(usageInfoData?.liveUsage?.ildUsage?.packageBalance2?.used) }} /
                {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance2?.limit) }} )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.ildUsage?.packageBalance2?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance2?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance2?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance2?.limit
                    )*100 }} %
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="usageInfoData?.liveUsage?.ild?.packageBalance3 > 0">
            <p class="mb-0 usage-value">Package Balance 3
                ({{(usageInfoData?.liveUsage?.ildUsage?.packageBalance3?.used) }} /
                {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance3?.limit) }} )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.ildUsage?.packageBalance3?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance3?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance3?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance3?.limit
                    )*100 }} %
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usageInfoData?.liveUsage?.ild?.packageBalance4 > 0">
            <p class="mb-0 usage-value">Package Balance 4
                ({{(usageInfoData?.liveUsage?.ildUsage?.packageBalance4?.used) }} /
                {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance4?.limit) }} )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.ildUsage?.packageBalance4?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance4?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance4?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance4?.limit
                    )*100 }} %
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usageInfoData?.liveUsage?.ild?.packageBalance5 > 0">
            <p class="mb-0 usage-value">Package Balance 5
                ({{(usageInfoData?.liveUsage?.ildUsage?.packageBalance5?.used) }} /
                {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance5?.limit) }} )</p>
            <div class="progress mb-3">
                <div class="progress-bar bg-primary" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.ildUsage?.packageBalance5?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance5?.limit
          )*100 + '%'">
                    {{(usageInfoData?.liveUsage?.ildUsage?.packageBalance5?.used/usageInfoData?.liveUsage?.ildUsage?.packageBalance5?.limit
                    )*100 }} %
                </div>
            </div>
        </ng-container>
    </div>
</div>


<div *ngIf="isUsageSkeletonLoader" @fadeInOut style="max-width: 100%;">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="div d-flex flex-column justify-content-center align-items-center">
            <app-skeleton-loader [radius]="'60px'" [borderWidth]="'40px'" shape="hollow-circle"></app-skeleton-loader>
        </div>

    </div>
    <div class="div">
        <app-skeleton-loader [tableRow]="3" [tableColumn]="1" shape="table"></app-skeleton-loader>
    </div>
</div>