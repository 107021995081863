import { Component } from '@angular/core';
import { CommonService } from './shared/services/common.service';
import { MessageService } from 'primeng/api';
import { PermissionService } from './shared/services/permission-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent {

  constructor(public commonService: CommonService, private permissionService: PermissionService) {

    const location = window.location.href;
    const urlArray = location.split(':');

    /*    if(urlArray[0] === 'http'){
         window.location.href = `https:${urlArray[1]}`;
       } */
  }


  async ngOnInit() {
    try {
      const isCustomerLogin = JSON.parse(localStorage.getItem('isCustomerLogin')!);
      if (isCustomerLogin) {

        const res: any = await this.permissionService.fetchAllResourceCategories(); // Await the API call
        const data = res.data;
        for (const val of data) {
          await this.permissionService.addResourceCategory(val); // Await each IndexedDB addition
        }
        const updatedCategories = await this.permissionService.getAllResourceCategories();
      }

    } catch (error) {
      console.error('Error:', error);
    }
  }

}
