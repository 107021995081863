import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class PortRequestsService {
  userId: string;
  companyId: string;
  public portingOutRequestStatus$ = new Subject();
  public downGradesJob$ = new Subject();
  public closePortInModal$ = new BehaviorSubject<boolean>(false);


  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) {

  }
  getUser() {
    return this.userDetails;
  }




  getPortingInRequests(obj: any) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      status: "",
      ...obj
    }
    let url = "api/downgrade-jobs"
    return this.http.get(url, body)
  }

  getPortingInRequestsBySearch(obj: any,search) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      searchTerm:search,
      ...obj
    }
    let url = "api/downgrade-jobs"
    return this.http.get(url, body)
  }


  getCancelReasonList(){
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      "ReasonType": "PLAN_SCHEDULER"
    }
    let url = "api/subscription/getReasonsList"
    return this.http.get(url,body)
  }

  cancelDownGrade(id: number,csId:number, data: any,selectedOption): Observable<any> {
    try {
      let url = `api/downgrade-jobs/${id}/cancel`; 
      let body = {
        // "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "RemarksId" :selectedOption,
        "CustomerId":csId,
  
      };
      return this.http.put(url, body); 
    } catch (err) {
      throw Error(err);
    }
  }

  getPortingRequestStatusById(data: any) {
    let url = "api/downgrade-jobs"
    let data1 = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      iccid: data.iccid,
      CustomerId: data.CustomerID
    }
    return this.http.get(url, data1, true)
  }

  
  getPortOutDetail(customerId: any): Observable<any>{
    let url='api/downgrade-jobs/getPlanScheduler';
    let data = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      CustomerId: customerId
    }
    return this.http.get(url, data, true)
 }
  setPortingRequestStatus(requestStaus: any) {
    this.portingOutRequestStatus$.next(requestStaus)
  }

  getPortingRequestStatus(): Observable<any> {
    return this.portingOutRequestStatus$.asObservable();
  }

  setPortingInRequestStatus(requestStaus: any) {
    this.downGradesJob$.next(requestStaus)
  }

  getPortingInRequestStatus(): Observable<any> {
    return this.downGradesJob$.asObservable();
  }
  setClosePortInModal(requestStaus: any) {
    this.closePortInModal$.next(requestStaus)
  }

  get getClosePortInModal(): Observable<any> {
    return this.closePortInModal$.asObservable();
  }
}
