import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { ROUTE_CONFIG, RouteConfig } from './route-config';

export class CompositeReuseStrategy implements RouteReuseStrategy {
  private strategyMap: { [key: string]: CustomReuseStrategy } = {};

  constructor() {
    // Initialize a CustomReuseStrategy for each route in the config
    ROUTE_CONFIG.forEach((config) => {
      this.strategyMap[config.path] = new CustomReuseStrategy();
    });
  }

  /**
   * Find the route configuration based on the path.
   */
  private getRouteConfig(path: string): RouteConfig | undefined {
    return ROUTE_CONFIG.find(route => route.path === path);
  }

  private getStrategyForPath(path: string): CustomReuseStrategy | undefined {
    return this.strategyMap[path];
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path ?? '(empty)';
    const strategy = this.getStrategyForPath(path);
    return strategy ? strategy.shouldDetach(route) : false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    const path = route.routeConfig?.path ?? '(empty)';
    const strategy = this.getStrategyForPath(path);
    if (strategy) {
      strategy.store(route, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path ?? '(empty)';
    const strategy = this.getStrategyForPath(path);
    return strategy ? strategy.shouldAttach(route) : false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = route.routeConfig?.path ?? '(empty)';
    const strategy = this.getStrategyForPath(path);
    return strategy ? strategy.retrieve(route) : null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futurePath = future.routeConfig?.path ?? '(empty)';
    const currPath = curr.routeConfig?.path ?? '(empty)';
    const futureStrategy = this.getStrategyForPath(futurePath);
    const currStrategy = this.getStrategyForPath(currPath);

    if (futureStrategy && currStrategy && futurePath === currPath) {
      return futureStrategy.shouldReuseRoute(future, curr);
    }

    return future.routeConfig === curr.routeConfig; // Default reuse behavior for non-configured routes
  }
}
