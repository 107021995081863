import { Injectable } from '@angular/core';
import { RegisteredCard } from './interfaces/registered-cards.interface';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailedInformationService {
  private cardStatusSubject = new BehaviorSubject<boolean>(false);
  cardsAdded: boolean;
  private cardsRemoved = false;
  
  setCardAndExpandButtonStatus(cardStatus: boolean) {
    this.cardStatusSubject.next(cardStatus);

  }
  getCardAndExpandButtonStatus() {
    return this.cardStatusSubject.asObservable();
  }

  private activeTabSubject = new BehaviorSubject<string>(null);
  private autoRenewSubject = new BehaviorSubject<boolean>(true); 
  autoRenew$ = this.autoRenewSubject.asObservable(); 
    activeTab$ = this.activeTabSubject.asObservable();

    private registeredCards: RegisteredCard[] = [];
    private nextIndex = 0;
  constructor() { }

  setAutoRenewStatus(status: boolean) {
    this.autoRenewSubject.next(status);
  }
  doCardregistration(title: string,componentName:any,hasCard:boolean,hasExpand:boolean): void {
    this.nextIndex=0;
    if (!this.registeredCards.find(card => card.title === title)) {
      this.registeredCards.push({  index: this.nextIndex++,title,componentName,hasCard,hasExpand });
    }
    // this.deRegisterCards()

  }
  deRegisterCards(){
    this.registeredCards = this.registeredCards.filter(card => card.hasCard && card.hasExpand);
  }
  
  getregisteredCard(): RegisteredCard[] {
    if (this.cardsRemoved) {
      return [...this.registeredCards];
    }
    return [...this.registeredCards];
    
  }
  removeCardsFromRegisteredCards(componentName: any): boolean {
    const originalLength = this.registeredCards.length;

    this.registeredCards = this.registeredCards.filter(card => card.componentName !== componentName);

    if (this.registeredCards.length < originalLength) {
      this.cardsRemoved = true;  
      return true;  
    }
    this.cardsRemoved = false;  
    return false;
  }

  registerCard(title: string,componentName:any,hasCard:boolean,hasExpand:boolean): boolean {
    const originalLength = this.registeredCards.length;
    const cardExists = this.registeredCards.some(card => card.componentName === componentName);
    if (!cardExists) {
      this.registeredCards.push({  index: this.nextIndex++,title,componentName,hasCard,hasExpand });
      if (this.registeredCards.length > originalLength) {
        this.cardsAdded = true;  
        return true;  
      }
    }
    this.cardsAdded = false;  
    return false;
  }
  

  setActiveTab(tabName: string): void {
    this.activeTabSubject.next(tabName);  
  }


  formatDate(date:  string | null | undefined): string {
    if (!date) {
      return "Not Available";
    }
    let dateObj = date.endsWith('Z') ? new Date(date) : new Date(date + 'Z');
    if (isNaN(dateObj.getTime())) {
      return "Not Available";
    }
    let month = dateObj.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let hour = dateObj.getUTCHours() % 12 || 12;  
    let minute = dateObj.getUTCMinutes().toString().padStart(2, '0');
    let second = dateObj.getUTCSeconds().toString().padStart(2, '0');
    let ampm = dateObj.getUTCHours() >= 12 ? 'PM' : 'AM';
  return `${month} ${day} ${year}, ${hour}:${minute}:${second} ${ampm}`;
  }

  formatCurrency(value: string): string {
    return `$${parseFloat(value).toFixed(2)}`;
  }

  formatValue(value: any): string {
    if (value === null || value === undefined || value === '') {
      return 'Not Available';
    }
    return value;
  }
  formatKeyLabel(key: string) {
    return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase());
  }

  

}
