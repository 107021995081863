
  <!-- <div class="nav  nav-tabs mb-0 w-100" id="v-pills-tab" role="tablist">
    
    <button class="nav-link active" id="v-pills-credit-card-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-credit-card" type="button" role="tab" aria-controls="v-pills-credit-card"
      (click)="changeRechargeType('Y')" aria-selected="true">
      <div class="d-flex">
        <div>
     
        </div>
        <div class="d-flex align-items-center">
          <h5>Credit Card</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-free-topup-tab" data-bs-toggle="pill" data-bs-target="#v-pills-free-topup"
      type="button" (click)="changeRechargeType('N')" role="tab" aria-controls="v-pills-free-topup" aria-selected="false">
      <div class="d-flex">
        <div>
    
        </div>
        <div class="d-flex align-items-center">
          <h5>Free Top Up</h5>
        </div>
      </div>
    </button>
  </div> -->
  <div class="">
    <ul ngbNav #CustomHover="ngbNav" [activeId]="1" class="nav  nav-pills  animation-nav bg-light  d-flex gap-3 mb-2">
      <li [ngbNavItem]="1">
          <a ngbNavLink>
            <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position ">Credit Card</h5>
          </a>
          <ng-template ngbNavContent>
            <app-credit-card-add-data [toupTypeData]="topupType"></app-credit-card-add-data>
          </ng-template>
      </li>
      <li [ngbNavItem]="2">
          <a ngbNavLink>
            <!-- <i class="fa-solid fa-credit-card nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position ">Free Top Up</h5>
          </a>
          <ng-template ngbNavContent>
            <app-credit-card-add-data></app-credit-card-add-data>
          </ng-template>
      </li>
      

    </ul>
    <div class="card-body">
      <div class="tab-content text-muted">
        <div [ngbNavOutlet]="CustomHover"></div>
      </div>
  </div>
  <div class="tab-content" id="v-pills-tabContent" *ngIf="newtabsCondition">
    <div class="tab-pane fade show active" id="v-pills-credit-card" role="tabpanel"
      aria-labelledby="v-pills-credit-card-tab">
      <app-credit-card-add-data [toupTypeData]="topupType"></app-credit-card-add-data>
    </div>
    <div class="tab-pane fade" id="v-pills-cash" role="tabpanel" aria-labelledby="v-pills-cash-tab">
      <app-cash-add-data></app-cash-add-data>
    </div>
    <div class="tab-pane fade" id="v-pills-money-gram" role="tabpanel" aria-labelledby="v-pills-money-gram-tab">
      <app-money-gram-add-data></app-money-gram-add-data>
    </div>
    <div class="tab-pane fade" id="v-pills-money-order" role="tabpanel" aria-labelledby="v-pills-money-order-tab">
      <app-money-order-add-data></app-money-order-add-data>
    </div>
    <div class="tab-pane fade" id="v-pills-wallet" role="tabpanel" aria-labelledby="v-pills-wallet-tab">
      <app-wallet-add-data></app-wallet-add-data>
    </div>
    <div class="tab-pane fade" id="v-pills-dealer-wallet" role="tabpanel" aria-labelledby="v-pills-dealer-wallet-tab">
      <app-dealer-wallet-add-data></app-dealer-wallet-add-data>
    </div>
    <div class="tab-pane fade" id="v-pills-free-topup-tab" role="tabpanel" aria-labelledby="v-pills-free-topup-tab">
      <app-free-topup-add-data></app-free-topup-add-data>
    </div>
  </div>
