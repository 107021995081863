import { Injectable } from '@angular/core';
import { HttpService } from './httpService.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerSettingService {

    constructor(private http:HttpService) { }


}
