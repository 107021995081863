<!-- footer start-->
<div class="container-fluid ">
    <div class="row">
        <div class="col-md-12 footer-copyright p-3">
            <div class="row">
                <div class="col-md-10 col-8">
                    <!-- <div class="badge-container d-flex align-items-center">
                        <span class="badge badge-dark tag-pills-sm-mb">
                            <div class="align-equal">
                                <app-feather-icons [icon]="'user'"></app-feather-icons> Jane Doe (11356)
                            </div>
                        </span>
                        <span class="badge badge-dark tag-pills-sm-mb">
                            <app-feather-icons [icon]="'user'"></app-feather-icons> MDN 545567787
                        </span>
                        <span class="badge badge-dark tag-pills-sm-mb">
                            <div class="align-equal">
                                <app-feather-icons [icon]="'plus'"></app-feather-icons> Wallet Balance: $ 0.00
                            </div>
                        </span>
                        <span class="badge badge-dark tag-pills-sm-mb me-2">
                            <div class="align-equal">
                                <app-feather-icons [icon]="'circle'"></app-feather-icons> ACP
                            </div>
                        </span>
                        <app-feather-icons [icon]="'user'" class="cursor-pointer hover-primary"
                            [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home-tab2"
                            (click)="Customizer('option')"></app-feather-icons>

                    </div> -->
                </div>
                <div class="col-md-2 col-4" *ngIf="!showNotes">
                    <!-- <div class="curve-div"></div> -->
                    <div class="align-equal bottom-curve text-center">
                        <span class="text-success cursor-pointer rubik-scribble" (click)="toggleNotes()">Notes</span>
                        <app-feather-icons class="text-success" [icon]="'chevron-up'"></app-feather-icons>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="notes-panel" [ngClass]="{'open-notes': showNotes}">
    <div class="header">
        <div class="row">
            <div class="col-md-11 col-8">
                <!-- <h5>header</h5> -->
            </div>
            <div class="col-md-1 col-4 text-end">
                <div class="align-equal text-center">
                    <div class="top-curve">
                        <span class="text-success cursor-pointer rubik-scribble" (click)="toggleNotes()">Notes</span>
                        <app-feather-icons class="text-success" [icon]="'chevron-down'"></app-feather-icons>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 pt-3">
        <div class="col-md-11">
            <app-customer-notes *ngIf="showNotes" [isFooter]="true"></app-customer-notes>
        </div>
    </div>
</div>


<div class="customizer-contain" style="z-index: -1;" [ngClass]="{ open: customizer != '' }">
    <div class="tab-content" id="c-pills-tabContent">
        <div class="customizer-body custom-scrollbar">
            <div class="tab-pane fade " *ngIf="customizer == 'option'" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home"
                role="tabpanel" aria-labelledby="c-pills-home-tab2">
                <div class="d-flex justify-content-end p-2">
                    <i class="icofont-close icon-close" (click)="Customizer('')"></i>
                </div>
                <app-customer-account></app-customer-account>
            </div>
        </div>
    </div>
</div>