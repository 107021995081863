import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
  animations:[fadeInOut]
})
export class UsageComponent implements OnInit{
  recordsList: any = [
    "10", "20", "30", "40", "50", "60", "70", "80"
  ];
  objectKeys = Object.keys;
  datePickerPlaceHolder:string="Detailed Usage Information Between Range :";
  startDate: string;
  endDate: string;
  perPage:any;
  column: any = [
    "#",
    "MDN",
    "Dialed Digit",
    "CALL DATE",
    "Redirecting Part",
    "Billable unit",
    "UNIT",
    "Call Type",
    "Free Type",
    "Usage Type", 
    "Destination Class",
    "PLAN CODE",
    "ROAMING",
    "THREEGP RAT TYPE",
    "BILLING CODE"
  ]
  formattedDateRange = {
    startDate: '',
    endDate: ''
  };
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 10; // Default page size
  filterForm: any;
  list: any = [];
  maxDate: string;
   summary = {
    byDate: {},      // Summary by date
    overallTotal: 0  // Total billable units across all data
  };
isSkeletonLoader: any;
  constructor(private http: HttpService, public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    public fb: FormBuilder,private modalService: NgbModal) {
    this.filterForm = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      perPage: [10, Validators.required],
      page: [1] 
    });
    this.setDates();
  }
  ngOnInit() {
    this.filterForm = this.fb.group({
      startDate: [this.startDate],
      endDate: [this.endDate],
      perPage: [this.perPage || 10]

    });
    this.setMaxDate()
    
  }
  async sendTomail(){
    var res5: any = await this.customerProfileService.getSendToMail()
    if (res5.Status) {
      this.commonService.showToast('success', "Success", res5.message)
    }else{
      this.commonService.showToast('error', "Error", res5.message)
    }
  }
  openSummaryModal(content: TemplateRef<any>) {
    this.modalService.open(content, { size: 'md', centered: true });
  }
  setDates() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    this.endDate = today.toISOString().split('T')[0];
    this.startDate = today.toISOString().split('T')[0];
  }
  
  setMaxDate() {
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 20); // Set max date to 5 days from today
    this.maxDate = maxDate.toISOString().split('T')[0]; // Convert to 'YYYY-MM-DD'
  }

  formatDate(inputDate) {
    var parts = inputDate.split('-');

    var formattedDate = parts[1] + '-' + parts[2] + '-' + parts[0];

    return formattedDate;
  }

  async search() {

    if (this.filterForm.valid) {
      
      let start = this.formatDate(this.filterForm.value.startDate)
      let end = this.formatDate(this.filterForm.value.endDate)
      this.filterForm.value.startDate = start
      this.filterForm.value.endDate = end
      try {
        let res: any = await this.getData(this.filterForm.value)
        this.list = res.data.data
        this.totalItems = res?.data?.total
        console.log(this.list)
        this.filterForm.patchValue({
          startDate: null,
          endDate: null,
          perPage: 10
        });
        
      }
      catch (err) {
        this.filterForm.patchValue({
          startDate: null,
          endDate: null,
          perPage: 10
        });
        this.commonService.showToast("error", "Error", err.error.message)
        
      }
    }

  }
  getData(data: any) {
    try {
      let url = "api/Customer/GetUsagesCDR"
      return this.http.get(url, data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  formatedDate(inputDate: Date): string {
    const month = ('0' + (inputDate.getMonth() + 1)).slice(-2); 
    const day = ('0' + inputDate.getDate()).slice(-2); 
    const year = inputDate.getFullYear(); 
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }
  async handleDateRangeChange(dateRange: Date[], page: number = 1) {
    this.isSkeletonLoader=true;
    console.log('daterange: ',dateRange);
    if (dateRange && dateRange.length === 2) {
      let start = this.formatedDate(dateRange[0]);
      let end = this.formatedDate(dateRange[1]);

      this.formattedDateRange.startDate = start;
      this.formattedDateRange.endDate = end;  
      try {
        let data={
          startDate: start,
          endDate: end,
          page: page
        }
        let res: any = await this.getData(data);
        this.list = res?.data?.data;
        this.totalItems = res?.data?.total;
        this.currentPage = page; 
        this.summary = { byDate: {}, overallTotal: 0 };
        this.summarizeData(this.list);
        this.isSkeletonLoader=false; 
        console.log(this.list);
      } catch (err) {
        this.filterForm.patchValue({
          startDate: null,
          endDate: null,
          perPage: 10
        });
        this.isSkeletonLoader=false; 
        this.commonService.showToast("error", "Error", err.error.message);
      }
    }
  }
  parseBillableUnit(unit: string, callType: string): number {
    if (callType === "Data" && unit.includes("MB")) {
      return parseFloat(unit.replace("MB", "")) || 0;
    } else {
      return parseFloat(unit) || 0;
    }
  }
  

summarizeData(data: any[]) {
  data.forEach((entry) => {
    const { CallDate, CallType, BillableUnit } = entry;
    const dateKey = CallDate.split(" ")[0];
    if (!this.summary.byDate[dateKey]) {
      this.summary.byDate[dateKey] = {
        totalBillableUnit: 0,
        callTypes: {}
      };
    }
    if (!this.summary.byDate[dateKey].callTypes[CallType]) {
      this.summary.byDate[dateKey].callTypes[CallType] = 0;
    }
    const unitValue = this.parseBillableUnit(BillableUnit,CallType);
    this.summary.byDate[dateKey].callTypes[CallType] += unitValue;
    this.summary.byDate[dateKey].totalBillableUnit += unitValue;
    this.summary.overallTotal += unitValue;
  });

}
  onPageChange(page: number) {
    this.currentPage = page;
    const { startDate, endDate } = this.formattedDateRange;
    if (startDate && endDate) {
      this.handleDateRangeChange([new Date(startDate), new Date(endDate)], page);
    } else {
      console.warn("Date range is missing, unable to fetch paginated data.");
    }
  }

  
}
