import { Component } from '@angular/core';

@Component({
  selector: 'app-featured-service-account',
  templateUrl: './featured-service-account.component.html',
  styleUrls: ['./featured-service-account.component.scss']
})
export class FeaturedServiceAccountComponent {

}
