<div class="container mt-0 mb-4" style="max-width: 100%;">
    <div class="card form-card br-5 shadow mb-3 mt-0 pt-0 px-0" style=" max-height: 80vh;">
  

      <div class="card-header container-fluid bg-primary p-0">
        <div class="row col-12 p-0 m-0">
          <!-- First Column: Subscription Information -->
          <div class="col-12 col-lg-6 text-light Sub-info p-0">
            <div class="text-center p-3" style="font-size: 1.5rem;letter-spacing: 2px;">
              <b>SUBSCRIPTION&nbsp; INFORMATION</b>
            </div>
          </div> 
      
          <!-- Second Column: Buttons -->
          <div class="col-12 col-lg-6 d-flex justify-content-center">
            <div class="d-flex flex-column flex-lg-row align-items-center">
              <!-- First set of buttons -->
              <div class="d-flex flex-column flex-lg-row align-items-center mb-3 mb-lg-0">
                <div class="d-flex justify-content-center flex-wrap">
                  <button class="btn text-light bg-transparent border-0 m-2" 
                  style="transition: color 0.3s;"
                  onmouseover="this.querySelector('i').style.color='#17a2b8';"
                  onmouseout="this.querySelector('i').style.color='white';"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="View History" (click)="openHistoryPage('payment')">
                    <i class="fas fa-history fa-3x"></i> <!-- FontAwesome Icon -->
                  </button>
                  <button class="btn text-light bg-transparent border-0 m-2" 
                  style="transition: color 0.3s;"
                  onmouseover="this.querySelector('i').style.color='#17a2b8';"
                  onmouseout="this.querySelector('i').style.color='white';"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="View Invoice" (click)="openHistoryPage('invoice')">
                    <i class="fas fa-file-invoice fa-3x"></i> <!-- FontAwesome Icon -->
                  </button>
                </div>
              </div>
      
              <!-- Second set of buttons -->
              <div class="d-flex flex-column flex-lg-row align-items-center">
                <div class="d-flex justify-content-center flex-wrap">
                  <button class="btn text-light bg-transparent border-0 m-2" 
                  style="transition: color 0.3s;"
                  onmouseover="this.querySelector('i').style.color='red';"
                  onmouseout="this.querySelector('i').style.color='white';"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Add Payment Method" (click)="updatePaymentMethod()">
                    <i class="fas fa-credit-card fa-3x"></i> <!-- FontAwesome Icon -->
                  </button>
                  <button class="btn text-light bg-transparent border-0 m-2" 
                  style="transition: color 0.3s;"
                  onmouseover="this.querySelector('i').style.color='red';"
                  onmouseout="this.querySelector('i').style.color='white';"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Update Address Details" (click)="updatePaymentMethod(true)">
                    <i class="fas fa-map-marker-alt fa-3x"></i> <!-- FontAwesome Icon -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
      
      <div class="card-body" *ngIf="!isSkeletonLoader" @fadeInOut>
        <ng-container *ngFor="let row of formFields; let i = index">
          <div *ngIf="i % 2 === 0" class="row mb-3">
            
            <div class="col-12 col-md-6" *ngIf="formFields[i]">
              <div class="row">
                <div class="col-12 col-md-4 d-flex align-items-center">
                  <label for="{{formFields[i].controlName}}" class="form-label mb-0" 
                         style="font-size: 1rem; font-weight: 500; white-space: normal;">
                    {{ formFields[i].label }} <!-- Label based on the formatted key -->
                  </label>
                </div>
                <div class="col-12 col-md-8">
                  <div class="value-display-container">
                    {{ formFields[i].value || 'Not Available' }} 
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-12 col-md-6" *ngIf="formFields[i + 1]">
              <div class="row">
                <div class="col-12 col-md-4 d-flex align-items-center">
                  <label for="{{formFields[i + 1].controlName}}" class="form-label mb-0" 
                         style="font-size: 1rem; font-weight: 500; white-space: normal;">
                    {{ formFields[i + 1].label }}
                  </label>
                </div>
                <div class="col-12 col-md-8">
                  <div class="value-display-container">
                    {{ formFields[i + 1].value || 'Not Available' }}
                  </div>
                </div>
              </div>
            </div>
      
          </div>
        </ng-container>
      </div>
      <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
        <app-skeleton-loader [formColumnCount]="2" [formRowCount]="10" shape="single-column-form"></app-skeleton-loader>
      </div>
      
 
      <div class="card-footer bg-primary d-flex justify-content-end align-items-center pt-2 pb-2">
        <div class="footer-button d-flex justify-content-end flex-wrap gap-2">
          <!-- Generate Payment Link Button -->
          <button class="btn btn-info d-flex align-items-center mb-2" (click)="generatePaymentLink(modalTemplate)" style="font-size: 1rem;">
            <i class="fas fa-link fa-2x me-2"></i> Generate Payment Link
          </button>
      
          <!-- Cancel Button -->
          <button type="button" class=" d-flex align-items-center mb-2"
          [ngClass]="subscriptionMoreInformationData?.Status === 'Cancelled' ? 'btn btn-outline-primary' : 'btn btn-danger'" 
           [disabled]="subscriptionMoreInformationData?.Status === 'Cancelled'"  
           (click)="openModal(); withConfirmation()" style="font-size: 1rem; width: fit-content;">
            <i class="fas fa-times fa-2x me-2"></i> Cancel
          </button>
        </div>
      </div>
      
    </div>
    <div *ngIf="showHistoryPage && subscriptionDetail" class="history-invoice">
      <app-payment-history [subscriptionDetail]="subscriptionDetail" [displayPaymentHistory]="showHistoryDetail==='payment'? true :false" (closePaymentHistory)="onPaymentHistoryClose($event)"></app-payment-history>
    </div>
  </div>

  <ng-template #modalTemplate let-modal>
    <div class="modal-header">
      <h1 class="modal-title fs-5" id="staticBackdropLabel">Generated Payment Link</h1>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="col-md-12 card" style="padding-top:10px">
        <div class="col-md-12 d-flex flex-column justify-content-center align-items-start" style="margin-top:20px;">
          <div class="generatedLink d-flex flex-column align-items-start justify-content-start" style="width: 100%;">
            <h5 class="link-text" id="linkInput" 
                style="color: #808080; text-align: left; white-space: wrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%;">
              {{ paymentGeneratedLink || defaultPaymentLink }}
            </h5>
            <div class="d-flex justify-content-start mt-2"></div>
          </div>
        </div>
      </div>      
    </div>
    <div class="modal-footer br-5 d-flex justify-content-end">
      <button class="btn btn-outline-primary d-flex align-items-center me-2" style="height:auto" (click)="copyToClipboard()" title="Copy the link">
        <i class="fas fa-copy me-2"></i><span>Copy</span>
      </button>
      <button class="btn btn-outline-success d-flex align-items-center" style="height:auto" (click)="openInSameWindow()" title="Open in new window">
        <i class="fas fa-bolt me-2"></i><span>Open in current tab</span>
      </button>
      <button class="btn btn-outline-secondary d-flex align-items-center" style="height:auto" (click)="openInNewWindow()" title="Open in new window">
        <i class="fas fa-external-link-alt me-2"></i><span>Open in new tab</span>
      </button>
    </div>
  </ng-template>  
  
  <!-- <div *ngIf="isLoading" class="card-loader">
    <app-loader [isChildLoader]="true"></app-loader>
  </div> -->


  <!-- *ngIf="isSkeletonLoader;" -->