import { Injectable, Injector } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from './httpService.service';
import { firstValueFrom, Observable } from 'rxjs';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  @LocalStorage()
  private userDetails;
  private navService: NavService;
  constructor(private dbService: NgxIndexedDBService, private http: HttpService,private injector: Injector) { }

  async addResourceCategory(resourceCategory: any): Promise<any> {
    // Check if the store exists and create it if it doesn't
    await this.ensureStoreExists('resource_categories');
    // Now, proceed to add or update the resourceCategory
    return this.dbService.getByKey('resource_categories', resourceCategory.Name).toPromise().then(async existingCategory => {
      if (existingCategory) {
        // If the category exists, update it
        return await this.dbService.update('resource_categories', resourceCategory).toPromise();
      } else {
        // If the category doesn't exist, add it
        return await this.dbService.add('resource_categories', resourceCategory).toPromise();
      }
    });
  }

  getResourceCategoryById(id: string): Promise<any> {
    return firstValueFrom(this.dbService.getByKey('resource_categories', id));
  }

  getAllResourceCategories(): Promise<any[]> {
    return firstValueFrom(this.dbService.getAll('resource_categories'));
  }

  updateResourceCategory(resourceCategory: any): Promise<any> {
    return firstValueFrom(this.dbService.update('resource_categories', resourceCategory));
  }

  deleteResourceCategory(id: number): Promise<any> {
    return firstValueFrom(this.dbService.delete('resource_categories', id));
  }

  fetchAllResourceCategories() {
    try {
      let url = "api/system/resourcePermissionswithChilds";
      let body = {
        userID: this.getUser()?.userID,
        companyId: "UNITYCRM0021",
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getUser() {
    return this.userDetails;
  }

  private ensureStoreExists(storeName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = indexedDB.open('PermissionsDB');

      request.onupgradeneeded = (event) => {
        const db = request.result;

        // Create store if it doesn't exist
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'Name', autoIncrement: false });
          console.log(`Store '${storeName}' created successfully.`);
        }
      };

      request.onsuccess = () => {
        request.result.close();
        resolve();
      };

      request.onerror = (error) => {
        console.error(`Error opening database:`, error);
        reject(error);
      };
    });
  }

  async updatePermission() {
    try {
      const res: any = await this.fetchAllResourceCategories();
      const data = res.data;
      for (const val of data) {
        await this.addResourceCategory(val);
      }
      
      // Lazy-load NavService
      if (!this.navService) {
        this.navService = this.injector.get(NavService);
      }
      
      this.navService.sideMenuInitilize();
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
