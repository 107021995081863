<div class="notification-container">
  <div class="notification-header">
    <h6 class="text-dark">Notifications</h6>
  </div>
  <ul>
    <li *ngFor="let notification of notifications$ | async" [class.unread]="!notification?.IsRead">
      <div class="d-flex justify-content-between">
        <span class="notification-content text-start">
          {{ notification?.Message }} - {{ notification?.Name }}
        </span>
      </div>
      <div class="notification-footer">
        <span class="notification-time">{{ notification?.UpdatedDate | date }}</span>
      </div>
    </li>
  </ul>
  <button class="mark-all mt-3" (click)="markAllAsRead()">Mark All as Read</button>
</div>
