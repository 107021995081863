<div  class="d-flex justify-content-between">
  <p (click)="toggleMoreInf();moreInfoSelectedTab('Line Information')"><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{groupUserDetails?.DisplayName}}</p>
  <p><i class="fa-regular fa-circle-question hover-effect" 
    [attr.title]="groupUserDetails?.Description" 
                      data-bs-toggle="popover" 
                      data-bs-trigger="hover" 
                      data-bs-class="custom-popover"
                      data-bs-placement="top"
                      ></i></p>
  </div>

 <div class="customizer-contain" [ngClass]="{ open: moreInfo == true }" [ngStyle]="{ 'width': popup1Width }">
  <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
          <h5 class="mb-0">Group Informations</h5>
          <i class="icofont-close icon-close" (click)="toggleMoreInf()"></i>
      </div>

      <div class="customizer-header">
        <div class="row">
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Search" (keyup)="filterdData($event)">
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary" (click)="toggleMoreInf1();moreInfoSelectedTab1('Line Information');editGroup()">+ Add</button>
          </div>
        </div>
      </div>

      <div class="customizer-body" style="max-height: 400px; overflow-y: auto;">
          <div class="tab-pane fade" [ngClass]="{ 'active show': moreInfo }" id="c-pills-home" role="tabpanel"
              aria-labelledby="c-pills-home-tab">
              <div class="notes-panel" [ngClass]="{'open-notes': true}">
                  <div class="wrapper d-flex bg-white">
                      <div class="right-panel w-100">
                          <div class="container-fluid">
                              <div class="row">
                                  <div class="col-md-12 mx-auto">
                                      <div class="table-responsive marginB2">
                                          <table class="table">
                                              <thead>
                                                  <tr>
                                                      <th>
                                                        <div class="form-check">
                                                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                                        </div>                                                     
                                                       </th>
                                                      <th scope="col" sortable="fieldName">Group Name</th>
                                                      <th scope="col" sortable="description">Email address</th>
                                                      <th scope="col" sortable="example">Members</th>
                                                      <th scope="col" sortable="example">Description</th>
                                                      <th scope="col" sortable="example">Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let item of groupList ">
                                                    <td>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                                      </div>
                                                    </td>
                                                      <td scope="row">
                                                          <ngb-highlight [result]="item.Code"
                                                              [term]="'Code'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item?.owner?.email"
                                                              [term]="'owner?.email'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.users_count"
                                                              [term]="'users_count'"></ngb-highlight>
                                                      </td>
                                                      <td>
                                                          <ngb-highlight [result]="item.Description"
                                                              [term]="'Description'"></ngb-highlight>
                                                      </td>
                                                      <td class="d-flex">
                                                        <p class="mx-2" (click)="toggleMoreInf1();moreInfoSelectedTab1('Line Information');editGroup(item.id)"><i class="fa-solid fa-pen-to-square"></i></p>
                                                        <p class="mx-2"><i class="fa-solid fa-trash"></i></p>
                                                        <p  (click)="navigateForMember(item)" class="mx-2"><i class="fa-solid fa-eye" ></i></p>
                                                        <p  (click)="navigateForPermission(item?.id)" class="mx-2"><i class="fa-solid fa-user-lock"></i></p>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          
                                          <div class="pull-right p-20 d-flex">
                                            <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                                            (ngModelChange)="onChangeNumberOfPage($event);">
                                                <option *ngFor="let page of pageDropdownMenu" [ngValue]="page">{{page}}</option>
                                            </select>

                                          <ngb-pagination 
                                            [directionLinks]="true"
                                            [boundaryLinks]="true"
                                            [collectionSize]="totalItems"
                                            [(page)]="currentPage"
                                            (pageChange)="pageChange($event)"
                                            [maxSize]="itemsPerPage"
                                            [pageSize]="itemsPerPage"
                                          >
                                          <ng-template ngbPaginationPrevious>Previous</ng-template>
                                          <ng-template ngbPaginationNext>Next</ng-template>

                                          </ngb-pagination>
                                        </div>


                                      </div>
                                      <br><br>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<!-- ////////////////////////////////////// -->

<div class="customizer-contain" [ngClass]="{ open: moreInfo1 == true }" [ngStyle]="{ 'width': popup2Width }">
  <div class="tab-content" id="c-pills-tabContent">

      <div class="customizer-header">
          <h5 class="mb-0" *ngIf="!editId">Add roles and data restrictions </h5>
          <h5 class="mb-0"  *ngIf="editId">Edit roles and data restrictions </h5>
          <i class="icofont-close icon-close" (click)="toggleMoreInf1()"></i>
      </div>

      <div class="customizer-body " style="max-height: 400px; overflow-y: auto;">
          <div class="tab-pane fade" [ngClass]="{ 'active show': moreInfo1}" id="c-pills-home" role="tabpanel"
              aria-labelledby="c-pills-home-tab">
              <div class="notes-panel" [ngClass]="{'open-notes': true}">
                  <div class="wrapper d-flex bg-white">
                      <div class="right-panel w-100">
                          <div class="container-fluid">
                            <form [formGroup]="addGroupForm">
                              <div class="row d-flex justify-content-center">
                                 <div class="col-md-10">
                                     <div class="card">
                                        <div class="card-body">
                                          <div class="row">
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Group Name</label>
                                                <input class="form-control" type="text" placeholder="Enter Group name" formControlName="groupName">
                                               </div>
                                               <div class="text-danger" *ngIf="addGroupForm?.get('groupName')?.hasError('required') && addGroupForm?.get('groupName')?.touched">
                                                Group Name is required.
                                              </div>
                                            </div>


                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Group Types</label>
                                                <ng-select formControlName="groupType">
                                                  <ng-option *ngFor="let option of groupTypeList" [value]="option.Code">{{option.Code}}</ng-option>
                                                </ng-select>                                            
                                                 </div>
                                                 <div  class="text-danger" *ngIf="addGroupForm?.get('groupType')?.hasError('required') && addGroupForm?.get('groupType')?.touched">
                                                  Group type is required.
                                                 </div>
                                               </div>
                                          
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Group Email</label>
                                                <input  class="text-danger" class="form-control" type="text" placeholder="Enter Group Email" formControlName="groupEmail">
                                               </div>
                                               <div  class="text-danger" *ngIf="addGroupForm?.get('groupEmail')?.hasError('required') && addGroupForm?.get('groupEmail')?.touched">
                                                Group Email is required.
                                               </div>
                                            </div>
                                          </div>
                                          <div class="row mt-2">
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Group description</label>
                                                <input  class="text-danger" class="form-control" type="text" placeholder="Enter Group description" formControlName="groupDescription">
                                               </div>
                                            </div>

                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Group Owners</label>
                                                <ng-select formControlName="groupOwner">
                                                  <ng-option *ngFor="let item of groupowner" [value]="item.user.id">{{item?.user?.name}}</ng-option>
                                                </ng-select>                                            
                                                 </div>
                                               <div  class="text-danger" *ngIf="addGroupForm?.get('groupOwner')?.hasError('required') && addGroupForm?.get('groupOwner')?.touched">
                                                Group Owners is required.
                                               </div>
                                               </div>
                                        
                                            <div class="col-md-6">
                                              <label for="exampleSelect" class="form-label">Status</label>
                                              <select class="form-select" id="exampleSelect" formControlName="status">
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>
                                              </select>

                                              <div class="text-danger" *ngIf="addGroupForm?.get('status')?.hasError('required') && addGroupForm?.get('status')?.touched">
                                                Status is required.
                                              </div>

                                              <div class="mt-4">
                                                <p>Labels</p>
                                              </div>
                                              <div style="margin-left: -10px;">
                                              <mat-checkbox  [disabled]="true" [checked]="true">  Mailing
                                              </mat-checkbox>
                                              </div>
                                              <small>For Email and distribution lists.</small>
                                                 <div class="form-check mt-3">
                                                <input class="form-check-input" type="checkbox" id="exampleCheckbox" formControlName="newUserEmail">&nbsp;&nbsp;
                                                <label class="form-check-label" for="exampleCheckbox" >
                                                  Security
                                                </label>
                                              </div>
                                           </div>
                                          </div>
                                          <small>To easily identify and manage groups that you are apply policies  to such as acess control,add the security label to them.</small>
                                        </div>
                                      </div>   
                                 </div>
                              </div>

                             <div class="row d-flex justify-content-center marginB">
                              <div class="col-md-10">
                              <button  *ngIf="!editId" class="btn btn-primary" (click)="createGroup()">Submit</button>
                              <button  *ngIf="editId" class="btn btn-primary" (click)="createGroup()">Update</button>
                              </div>
                             </div>
                             </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
