import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { ROUTE_CONFIG, RouteConfig } from './route-config';

export class CustomReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: DetachedRouteHandle } = {};

  constructor() {}

  /**
   * Find the route configuration based on the path.
   */
  private getRouteConfig(path: string): RouteConfig | undefined {
    return ROUTE_CONFIG.find(route => route.path === path);
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path ?? '(empty)';
    const routeConfig = this.getRouteConfig(path);
    return routeConfig?.detach ?? false; // Only detach if route is explicitly set to detach
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    const path = route.routeConfig?.path ?? '(empty)';
    const routeConfig = this.getRouteConfig(path);

    if (handle && routeConfig?.detach) {
      this.handlers[path] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path ?? '(empty)';
    const routeConfig = this.getRouteConfig(path);
    return !!routeConfig && !!this.handlers[path]; // Attach only if route exists in config and has a handler
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = route.routeConfig?.path ?? '(empty)';
    return this.handlers[path] || null; // Retrieve handler if available
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futurePath = future.routeConfig?.path ?? '(empty)';
    const currPath = curr.routeConfig?.path ?? '(empty)';
    return futurePath === currPath; // Reuse route if paths match
  }
}
