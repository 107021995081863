<div class="customizer-contain">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header sticky-header2">
      <h5 class="mb-0">
        Resources Category <i class="fa fa-question-circle cursor-pointer" style="font-size: 17px;"></i>
      </h5>
      <i class="icofont-close icon-close" (click)="closeModal3()"></i>
    </div>

    <div class="main">
    <div class="bg-light">
      <div class="col-md-12 text-end ">
        <button class="btn btn-info my-3" type="button" data-toggle="collapse" data-target="#collapseExample"
          aria-expanded="false" aria-controls="collapseExample">
          Create
        </button>
      </div>
      <div class="custom-card mx-3">
      <form [formGroup]="resourcesTypeForm">
        <div class="row  mt-3 px-4 py-4" id="collapseExample">
          <div class="col-md-4 mb-3">
            <label for="resourceName">Category Name</label>
            <input type="text" class="form-control" id="resourceName" formControlName="resourceName"
              placeholder="Enter resource name"
              [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceName').invalid && resourcesTypeForm.get('resourceName').touched}">
          </div>
          <div class="col-md-4 mb-3">
            <label for="resourceDescription">Description</label>
            <input type="text" class="form-control" id="resourceDescription" formControlName="resourceDescription"
              placeholder="Enter description"
              [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceDescription').invalid && resourcesTypeForm.get('resourceDescription').touched}">
          </div>
          <div class="col-md-4 mb-3">
            <label for="displayName">Display Name</label>
            <input type="text" class="form-control" id="displayName" formControlName="displayName"
              placeholder="Enter display name"
              [ngClass]="{'is-invalid': resourcesTypeForm.get('displayName').invalid && resourcesTypeForm.get('displayName').touched}">
          </div>
          <div class="col-md-4 mt-4 gap-2">
            <label class="d-flex gap-1">
              <input type="radio" formControlName="status" [value]="1">
              Active
            </label>
            <label class="d-flex gap-1">
              <input type="radio" formControlName="status" [value]="0">
              Inactive
            </label>
          </div>

          <div class="col-md-12 text-end mt-3 px-0">
            <button *ngIf="!showupdate" class="btn btn-outline-info" style="font-size: 15px;" type="submit"
              (click)="submitForm()">Submit</button>
            <div class="col-md-12 text-end " *ngIf="showupdate">
              <button class="btn btn-outline-warning mx-2" style="font-size: 15px;" type="button"
                (click)="update()">Update</button>
              <button class="btn btn-outline-danger" style="font-size: 15px;" type="button"
                (click)="resetForm()">Reset</button>
            </div>
          </div>
        </div>

      </form>
       </div>
       <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="table-responsive marginB2">
              <table class="table project-list-table table-nowrap align-middle table-borderless">
                <thead class="table-light">
                  <tr class="text-center">
                    <th>Sr.No</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center" *ngFor="let row of resourcesTypeList;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{ row?.DisplayName || 'N/A' }}</td>
                    <td>{{ row?.Description || 'N/A' }}</td>
                    <td>
                      <!-- Action buttons -->
                      <button class="btn btn-sm btn-primary me-2"  id="edit" data-toggle="collapse" data-target="#collapseExample"
                      aria-expanded="false" aria-controls="collapseExample"  (click)="ViewOneRes(row.id)">
                        Edit
                      </button>
                      <button class="btn btn-sm btn-danger" (click)="deleteCategoryType(row.id)">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </div>
  </div>