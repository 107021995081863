import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent {
  @Input() filters: any[] = [];
  @Input() showAddFilter: boolean = true;

  @Output() filtersChanged = new EventEmitter<any>();

  appliedFilters: any = {};
  showFilterSection: boolean = false;
  bsRangeValue: any = []

  constructor(
    private eRef: ElementRef
  ) {

  }
  ngOnInit(): void {
    console.log(this.filters);

  }
  applyFilter(key: string, e: any) {
    const value = e && e.target ? e.target.value : e;
    const updatedFilters = { ...this.appliedFilters };
    updatedFilters[key] = value;
    this.appliedFilters = updatedFilters;
    this.filtersChanged.emit(this.appliedFilters);
  }

  clearFilters() {
    this.appliedFilters = {};
    this.bsRangeValue = []
    this.filtersChanged.emit(this.appliedFilters);
  }

  isActive(key: string, value: string) {
    return this.appliedFilters[key] === value;
  }

  toggleFilterDropdown() {
    this.showFilterSection = !this.showFilterSection;
  }

  onDateRangeChange(value: Date[]) {
    this.applyFilter('dateRange', value);
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showFilterSection = false
    }
  }
}
