<div class="col-sm-12">
    <div class="card p-20">
        <form [formGroup]="formData">
            <div class="row m-t-20 m-b-20">
                <div class="col-md-4">
                    <input type="text" class="form-control my-2" formControlName="Name" placeholder="Enter Title">
                </div>
                <div class="col-md-4">
                    <select formControlName="IsActive" class="form-control my-2">
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button type="submit" class="btn btn-secondary my-2" [disabled]="formData.invalid"
                        (click)="onSubmit()">{{id ? 'Edit' :'Add'}} Ticket Type</button>
                </div>
            </div>
        </form>

        <div class="table-responsive signal-table">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <ng-container *ngFor="let item of column">
                            <th scope="col">
                                {{item.displayName}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of data">
                        <tr class="shadow-showcase">
                            <ng-container *ngFor="let row of column">
                                <td *ngIf="row.mappingKey">
                                    <ng-container
                                        *ngIf="row.displayName != 'Action' && row.displayName != 'Status' && row.displayName != 'Created Date'">
                                        <app-table-cell [rowdata]="item" [mapping]="row.mappingKey"></app-table-cell>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="row.displayName == 'Created By' || row.displayName == 'Modified By'">
                                        Missing
                                    </ng-container>
                                    <ng-container *ngIf="row.displayName == 'Created Date'">
                                        {{item.created_at | date : 'mediumDate'}}
                                    </ng-container>
                                    <ng-container *ngIf="row.displayName == 'Status'">
                                        {{item.IsActive == 1 ? 'Active' : 'In Active'}}
                                    </ng-container>
                                    <ng-container *ngIf="row.displayName == 'Action'">
                                        <i class="fa fa-edit cursor-pointer" (click)="onEdit(item)"></i>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <tr *ngIf="isEmptyRow">
                        <td [colSpan]="column.length">
                            <div class="font-danger text-center">Sorry!!! No record matching....</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <div class="pull-right p-20">
                <ngb-pagination [collectionSize]="totalItems" [(page)]="currentPage"
                    [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
            </div> -->
        </div>
    </div>
</div>

<ng-template #modalPrompt let-modal>
    <div class="modal-body">
        <div class="close-modal p-absolute" (click)="modal.close()">
            <i class="icon-close"></i>
        </div>
        <div class="p-20">
            <h5 class="m-b-3">No content is available</h5>
        </div>
        <div class="form-group mb-3 text-end">
            <button type="button" class="btn btn-light" (click)="modal.close()">Close</button>
            <button type="button" class="btn btn-primary m-l-10" disabled>Save and Close</button>
        </div>
    </div>
</ng-template>

