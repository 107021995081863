   <!-- <div class="d-flex justify-content-between">
    <p class="f-18" (click)="commonSettingService.moreInfoSelectedTab('MANAGE_COMBOS')"><i
            class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{manageComboDetails?.DisplayName}}</p>
</div> -->


<div class="tab-content" id="c-pills-tabContentAgent">
    <div class="d-flex">
        <h4 class="mb-0 text-nowrap col-md-2" (click)="back()" style="cursor: pointer;"> <i
                class="fa-solid fa-arrow-left mt-1 mx-2"></i>List of Combos</h4>
        <div class="col-md-10">
            <div class="d-flex justify-content-end my-2">
                <div class="col-md-2">
                    <input type="text" class="form-control" placeholder="Search" (keyup)="filterdData($event)">
                </div>
                <div class="">
                    <button class="btn btn-primary" (click)="commonSettingService.moreInfoSelectedTab1('add-combo')">+
                        Add</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-3" @fadeInOut *ngIf="!isSkeletonLoader">
        <div class="col-md-12 mx-auto">
            <div class="table-responsive">
                <table class="table project-list-table table-nowrap align-middle table-borderless"
                    *ngIf="!isSkeletonLoader" @fadeInOut>
                    <thead>
                        <tr>
                            <th scope="col" sortable="fieldName">Name</th>
                            <th scope="col" sortable="description">Title</th>
                            <th scope="col" sortable="example">Description</th>
                            <th scope="col" sortable="example">Price</th>
                            <th scope="col" sortable="example">DisplayPrice</th>
                            <th scope="col" sortable="example">IsActive</th>
                            <th scope="col" sortable="example">Product Offer</th>
                            <th scope="col" sortable="example">Is OfferOn</th>
                            <th scope="col" sortable="example">Combo Type Name</th>
                            <th scope="col" sortable="example">ImagePath</th>
                            <th scope="col" sortable="example">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of comboTableData">
                            <td>{{ item.Name }}</td>
                            <td>{{ item.Title }}</td>
                            <td>{{ item.Description }}</td>
                            <td>{{ item.Price }}</td>
                            <td>{{ item.DisplayPrice }}</td>
                            <td>{{ item.IsActive }}</td>
                            <td>{{ item.ProductOffer }}</td>
                            <td>{{ item.IsOfferOn }}</td>
                            <td>{{ item.ComboTypeName }}</td>
                            <td>
                                <a *ngIf="item.ImagePath" (click)="openImage(item.ImagePath)" class="pointer"
                                    style="cursor: pointer;">Yes</a>
                                <span *ngIf="!item.ImagePath">No</span>
                            </td>
                            <td>
                                <ul class="list-inline mb-0">
                                    <li class="list-inline-item">
                                        <a (click)="editOpen(item)" class="text-primary" title="Edit"><i
                                                class="fa-solid fa-pen-to-square"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a class="text-danger" title="Delete"><i class="fa-solid fa-trash"></i></a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    
                    </tbody>
                
                </table>
                <div *ngIf="comboTableData.length == 0" class="text-danger fw-bold col-12 py-2 bg-white text-center ">
                    No data found
                </div>
                <div class="pull-right p-20 d-flex">
                    <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                        (change)="onChangeNumberOfPage()">
                        <option *ngFor="let page of pageDropdownMenu" [value]="page">{{ page }}</option>
                    </select>
                    <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true"
                        [collectionSize]="totalItems" [(page)]="currentPage" (pageChange)="pageChange($event)"
                        [pageSize]="itemsPerPage">
                        <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="isSkeletonLoader" @fadeInOut class="d-flex flex-column gap-4" style="max-width: 100%;">
        <app-skeleton-loader [tableColumn]="8" [tableRow]="8" shape="table"></app-skeleton-loader>
    </div>
</div>



<div class="customizer-contain custom-card" [ngClass]="{ open: commonSettingService.moreInfoLevel1 == true }"
    [ngStyle]="{ 'width': commonSettingService.popup2Width}">
    <div class="tab-content" id="c-pills-tabContentAgent">
        <div class="customizer-header">
            <h5 class="mb-0" *ngIf="!commonSettingService.editId">Add New Combos</h5>
            <h5 class="mb-0" *ngIf="commonSettingService.editId">Edit Combos</h5>
            <i class="icofont-close icon-close" (click)="closeModal()"></i>
        </div>
        <div class="customizer-body custom-scrollbar">
            <div class=" m-3">

                <ul class="nav nav-tabs">
                    <li class="nav-item" (click)="moveToStep(0)">
                        <a class="nav-link " [ngClass]="{'active':currentStep==0}">Create Combo</a>
                    </li>
                    <li class="nav-item" (click)="moveToStep(1)">
                        <a class="nav-link" [ngClass]="{'disabled':!product_combo_Id,'active':currentStep==1}">Add Combo
                            Item</a>
                    </li>
                    <li class="nav-item" (click)="moveToStep(2)">
                        <a class="nav-link" [ngClass]="{'disabled':!product_combo_Id,'active':currentStep==2}">Upload
                            Image</a>
                    </li>
                </ul>

                <div>
                    <div *ngIf="currentStep==0">
                        <form [formGroup]="addComboForm" @fadeInOut *ngIf="!isCreateComboSkeletonLoader">
                            <div class="card p-3" style="height: 70vh; overflow-y: auto;">
                                <div class="row g-3">
                                    <!-- Combo Type and Name (Row 1) -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Combo Type*</label>
                                            <select class="form-control form-select" formControlName="ComboType">
                                                <option [value]="1">Tablet</option>
                                                <option [value]="2">Smartphone</option>
                                                <option [value]="3">Sim</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Name*</label>
                                            <input type="text" formControlName="Name" class="form-control"
                                                placeholder="Enter name">
                                        </div>
                                    </div>

                                    <!-- Title and Price (Row 2) -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Title*</label>
                                            <input type="text" formControlName="Title" class="form-control"
                                                placeholder="Enter title">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Price*</label>
                                            <input type="number" formControlName="Price" class="form-control"
                                                placeholder="Enter price">
                                        </div>
                                    </div>

                                    <!-- Display Price and Product Offer (Row 3) -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Display Price*</label>
                                            <div class="input-group">
                                                <input type="text" formControlName="DisplayPrice" class="form-control"
                                                    placeholder="Display price">
                                                <span class="input-group-text">$</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Product Offer*</label>
                                            <i class="fa-solid fa-circle-info text-muted ms-2" data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                                title="This is the maximum number for which the offer is valid and the product will no longer be displayed to the end user. Valid for the combination bargain offer on ACP enrollment. To allow the offer, check the box next to 'Is Offer On'."></i>
                                            <input type="number" formControlName="ProductOffer"
                                                class="form-control mt-1" placeholder="Enter product offer">
                                        </div>
                                    </div>

                                    <!-- Application ID and Select Type (Row 4) -->
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Application ID*</label>
                                            <ng-select formControlName="ApplicationAreaID" [multiple]="true" required>
                                                <ng-option *ngFor="let item of applicationAreasList"
                                                    [value]="item.id">{{ item.Name }}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Select Type</label>
                                            <ng-select formControlName="type" [multiple]="false">
                                                <ng-option [value]="'10'">For ACP Device Eligible Customers</ng-option>
                                                <ng-option [value]="'00'">For ACP SIM Only Customers</ng-option>
                                                <ng-option [value]="'01'">For ACP Smartphone Offer</ng-option>
                                                <ng-option [value]="'11'">Miscellaneous</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>

                                    <!-- Error Message (Full Width) -->
                                    <div class="col-12 text-center" *ngIf="addComboForm.invalid && showErrorMsg">
                                        <p class="text-danger">Please fill in required fields.</p>
                                    </div>

                                    <!-- Checkboxes (Aligned at the Bottom) -->
                                    <div class="col-md-12 d-flex  align-items-center">
                                        <div class="form-check">
                                            <input type="checkbox" formControlName="IsOfferOn"
                                                class="  form-check-input">
                                            <label class="form-check-label mx-2">Is Offer On</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="checkbox" formControlName="IsActive" class="form-check-input">
                                            <label class="form-check-label mx-2">IsActive*</label>
                                        </div>
                                    </div>

                                    <!-- Submit/Update Button (Full Width) -->
                                    <div class="col-12 text-center mt-3">
                                        <button type="submit" class="btn btn-primary">
                                            {{ product_combo_Id ? 'Update' : 'Add' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>


                        <div *ngIf="isCreateComboSkeletonLoader" @fadeInOut class="d-flex flex-column gap-4"
                            style="max-width: 100%;">
                            <div class="card">
                                <app-skeleton-loader shape="payment-form"></app-skeleton-loader>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="currentStep==1">
                        <form [formGroup]="addComboItemForm" @fadeInOut>
                            <div class="card p-3">
                                <div class="container-fluid border" *ngIf="product_combo_item_id || showAdd">
                                    <div class="d-flex my-2 justify-content-end">
                                        <i class="icofont-close icon-close" (click)="closeModal1()"></i>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group mt-2">
                                                <label>Device Make Model*</label>
                                                <ng-select formControlName="ProductItemID"
                                                    (change)="onChangeDeviceMakeModel($event)" [multiple]="false"
                                                    required>
                                                    <ng-option *ngFor="let item of deviceMakeModelList"
                                                        [value]="item.id">{{item.Make}}-{{item.Model}}
                                                        ({{item.ModelNumber}}), {{item.Price}}
                                                        {{item.DeviceType}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group mt-2">
                                                <label>Quantity*</label>
                                                <input type="number" formControlName="Quantity" class="form-control">
                                            </div>
                                        </div>
                                        <!--  <div class="col-md-6">
                                            <div class="form-group mt-2">
                                                <label>Price*</label>
                                                <input type="number" formControlName="Price" class="form-control">
                                            </div>
                                        </div> -->
                                        <div class="col-md-6">
                                            <div class="form-group mt-2">
                                                <label>Display Price*</label>
                                                <div class="d-flex display-div">
                                                    <input type="number" formControlName="DisplayPrice"
                                                        class="form-control">
                                                    <div class="child">
                                                        $
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-6 my-auto">
                                            <div class="form-group mt-2 d-flex align-items-center ">
                                                <label>IsActive*</label>
                                                <input type="checkbox" formControlName="IsActive" class="ms-2">
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="p-3">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-6 my-2">
                                                            <label>Upload Files*</label><br>
                                                            <ngx-dropzone (change)="onFileItemChange($event)">
                                                                <ngx-dropzone-label>
                                                                    <div class="dz-message needsclick"><i
                                                                            class="icon-cloud-up"></i>
                                                                        <h6>Drop files here or click to upload.</h6>
                                                                    </div>
                                                                </ngx-dropzone-label>
                                                                <ngx-dropzone-preview *ngIf="uploadedComboItemFiles"
                                                                    [removable]="true"
                                                                    (removed)="removeComboItemFile(uploadedComboItemFiles)">
                                                                    <ngx-dropzone-label>{{ uploadedComboItemFiles.name
                                                                        }} ({{
                                                                        uploadedComboItemFiles.type
                                                                        }})</ngx-dropzone-label>
                                                                </ngx-dropzone-preview>
                                                            </ngx-dropzone>
                                                        </div>
                                                        <div *ngIf="imageItemPath" class="col-md-6">
                                                            <img [src]="imageItemPath" width="200px">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="col-12 my-2 text-center"
                                            *ngIf="addComboItemForm.invalid && showErrorMsgItem">
                                            <p class="text-danger">Please fill required fields.</p>
                                        </div>



                                        <div class="col-md-12 text-center my-3">
                                            <button type="button" (click)="addProductComboItems()"
                                                *ngIf="!product_combo_item_id" class="btn btn-primary">Add</button>
                                            <button type="button" *ngIf="product_combo_item_id"
                                                (click)="updateComboItem()" class="btn btn-primary">Update</button>
                                        </div>

                                    </div>
                                </div>

                                <div class="container-fluid my-2" *ngIf="product_combo_Id">
                                    <div class="text-end mb-2">
                                        <button class="btn btn-primary" (click)="addCombo()">+
                                            Add</button>
                                    </div>
                                    <div class="table-responsive marginB2" style="height: 70vh;overflow-y: auto;">

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID </th>
                                                    <th scope="col">Product Item ID </th>
                                                    <th scope="col">Product Combo ID</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Display Price</th>
                                                    <th scope="col">Is Active</th>
                                                    <th scope="col">Is Deleted</th>
                                                    <th scope="col">Image Path</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of comboItemList">

                                                    <td>
                                                        <ngb-highlight [result]="item.id" [term]="'id'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.ProductItemID"
                                                            [term]="'ProductItemID'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.ProductComboID"
                                                            [term]="'ProductComboID'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.Quantity"
                                                            [term]="'Quantity'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.Price"
                                                            [term]="'Price'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.DisplayPrice"
                                                            [term]="'DisplayPrice'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.IsActive"
                                                            [term]="'IsActive'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <ngb-highlight [result]="item.IsDeleted"
                                                            [term]="'IsDeleted'"></ngb-highlight>
                                                    </td>
                                                    <td>
                                                        <a *ngIf="item.ImagePath" (click)="openImage(item.ImagePath)"
                                                            class="pointer" style="cursor: pointer;">Yes </a>
                                                        <span *ngIf="!item.ImagePath"> No </span>
                                                    </td>
                                                    <td class="d-flex">
                                                        <p class="mx-2" (click)="editItemOpen(item)">
                                                            <i class="fa-solid fa-pen-to-square"></i>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="currentStep==2">
                        <div class="card p-3" @fadeInOut>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-6 my-2">
                                        <label>Upload Files*</label><br>
                                        <ngx-dropzone (change)="onFileChange($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                                                    <h6>Drop files here or click to upload.</h6>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngIf="uploadedFiles" [removable]="true"
                                                (removed)="removeFile(uploadedFiles)">
                                                <ngx-dropzone-label>{{ uploadedFiles.name }} ({{ uploadedFiles.type
                                                    }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                    <div class="col-md-6">
                                        <img [src]="imagePath" width="200px">
                                    </div>

                                    <div class="col-md-12 text-center my-3">
                                        <button type="button" (click)="submitUploadImage()"
                                            class="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>