import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { SettingCommonServices } from '../../services/setting-common.services';
import { Router } from '@angular/router';
import { ManageComboItemsService } from '../../services/manage-combo-items.service';
import { ManageComboService } from '../../services/manage-combo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { LocalStorage } from 'ngx-webstorage';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-manage-combo-items',
  templateUrl: './manage-combo-items.component.html',
  styleUrls: ['./manage-combo-items.component.scss'],
  animations: [fadeInOut] 
})
export class ManageComboItemsComponent implements OnInit {
  @LocalStorage()
  private userDetails;
  @Input() manageComboItemsDetails:any
  uploadedFiles: any = null
  addComboItemForm: any = FormGroup;
  filterList: any = []
  comboItemList: any = []
  comboList: any[] = []
  public pageDropdownMenu: any = [10, 20, 30];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  showErrorMsg: boolean = false;
  deviceMakeModelList: any = [];
  moreInfoTabLevel1 = ''
  product_combo_item_id: any = null;
  imagePath = null
isSkeletonLoader: boolean=true;
  constructor(
    private fb: FormBuilder,
    public commonSettingService: SettingCommonServices,
    private router: Router,
    private manageComboItemService: ManageComboItemsService,
    public manageComboService: ManageComboService,
    public commonService: CommonService,
    public modalService: NgbModal
  ) {

    this.addComboItemForm = this.fb.group({
      ProductComboID: [null],
      ProductItemID: [null, Validators.required],
      Quantity: [1, Validators.required],
      Price: [null, Validators.required],
      DisplayPrice: [null, Validators.required],
      SortOrder: [1],
      NavIconClass: ['spin'],
      IsActive: [false]
    })
  }

  ngOnInit(): void {
    this.commonSettingService.moreComboItemTabLevel1.subscribe(
      data => {
        if (data == 'MANAGE_COMBOS_ITEMS') {
          this.moreInfoTabLevel1 = data;
          this.getProductComboItems()
          this.manageComboService.getDeviceMakeModelList().subscribe((res: any) => {
            this.deviceMakeModelList = res.data.data;
          })
          this.manageComboService.getComboList().subscribe((res: any) => {
            this.comboList = res.data.data;
          })
        }
      }
    )
  }


  onChangeDeviceMakeModel(event: any) {
    let result = this.deviceMakeModelList.find(ele => ele.id == event)
    if (result) {
      this.addComboItemForm.get('DisplayPrice').patchValue(result.PriceAfterDiscount)
      this.addComboItemForm.get('Price').patchValue(result.Price)
      if(result.IsActive==1) {
        this.addComboItemForm.get('IsActive').patchValue(true)
      }
      else {
        this.addComboItemForm.get('IsActive').patchValue(false)
      }
      
    }
  }

  getProductComboItems() {
    
    let data = {
      "per_page": this.itemsPerPage,
      "page": this.currentPage,
    }
    this.manageComboItemService.getProductComboItemsList(data).subscribe((res: any) => {
      this.comboItemList = res.data.data
      this.filterList = res.data.data
      this.totalItems = res.data.total
      this.currentPage = res.data.current_page
      this.first_page_url = res.data.first_page_url
      this.last_page_url = res.data.last_page_url
      this.next_page_url = res.data.next_page_url
      this.isSkeletonLoader=false
    }, err => {
      this.isSkeletonLoader=false
    })
  }
  onChangeNumberOfPage() {
    this.getProductComboItems()
  }
  pageChange(event) {
    this.currentPage = event
    this.getProductComboItems()
  }

  submit() {
    if (this.addComboItemForm.valid) {
      
      const data = this.addComboItemForm.value
      const form = new FormData()
      form.append('companyId', "UNITYCRM0021")
      form.append('userID', this.getUser().userID)
      form.append('ProductComboID', data.ProductComboID)
      form.append('ProductItemID', data.ProductItemID)
      form.append('Quantity', data.Quantity)
      form.append('Price', data.Price)
      form.append('DisplayPrice', data.DisplayPrice)
      form.append('ProductComboID', data.ProductComboID)
      form.append('SortOrder', data.SortOrder ?? 1)
      form.append('NavIconClass', data.NavIconClass)
      if (data.IsActive == true) {
        form.append('IsActive', 'Y')
      }
      else {
        form.append('IsActive', 'N')
      }
      if (this.uploadedFiles) {
        form.append('File', this.uploadedFiles)
      }

      this.manageComboItemService.addProductComboItems(form).subscribe(res => {
        this.resetaddComboItemForm()
        this.uploadedFiles = null
        this.imagePath = null
        this.showErrorMsg = false;
        this.commonService.showToast("success", "Success", "Added successfully!")
        this.commonSettingService.toggleMoreComboItemLevel1()

        this.getProductComboItems()
        
      }, err => {
        
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        this.showErrorMsg = true;
        this.showErrorMsg = false;
      })
    }
    else {
      this.showErrorMsg = true;
      this.commonService.showToast('error', "Error", ("Form is invalid"))
    }
  }
  update() {
    if (this.addComboItemForm.valid) {
      
      let data = {
        ...this.addComboItemForm.value
      }

      const form = new FormData()

      form.append('companyId', "UNITYCRM0021")
      form.append('userID', this.getUser().userID)
      form.append('ProductComboID', data.ProductComboID)
      form.append('ProductItemID', data.ProductItemID)
      form.append('Quantity', data.Quantity)
      form.append('Price', data.Price)
      form.append('DisplayPrice', data.DisplayPrice)
      form.append('ProductComboID', data.ProductComboID)
      form.append('SortOrder', data.SortOrder)
      form.append('NavIconClass', data.NavIconClass)
      if (data.IsActive == true) {
        form.append('IsActive', 'Y')
      }
      else {
        form.append('IsActive', 'N')
      }
      if (this.uploadedFiles) {
        form.append('File', this.uploadedFiles)
      }
      this.manageComboItemService.updateProductComboItem(this.product_combo_item_id, form).subscribe(res => {

        this.resetaddComboItemForm()
        this.uploadedFiles = null
        this.imagePath = null
        this.product_combo_item_id = null
        this.showErrorMsg = false;
        this.commonService.showToast("success", "Success", "Updated successfully!")
        this.close();
        this.getProductComboItems()
        

      }, err => {
        
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        this.showErrorMsg = true;
        this.showErrorMsg = false;
      })
    }
    else {
      this.showErrorMsg = true;
      this.commonService.showToast('error', "Error", ("Form is invalid"))
    }
  }
  editOpen(item: any) {
    this.commonSettingService.moreComboItemSelectedTab1('Line Information');
    this.commonSettingService.editGroup(item.id);
    let data = {
      "ComboItemID": item.id
    }
    this.product_combo_item_id = item.id
    this.manageComboItemService.getProductComboItem(data).subscribe((res: any) => {
      this.addComboItemForm.patchValue(res.data.data[0])
      if (res.data.data[0].IsActive == "Y") {
        this.addComboItemForm.get('IsActive').patchValue(true)
      }
      else {
        this.addComboItemForm.get('IsActive').patchValue(false)
      }
      this.imagePath = res.data.data[0]?.ImagePath
    })
  }
  close() {
    this.commonSettingService.toggleMoreComboItemLevel1()
    this.product_combo_item_id = null;
    this.resetaddComboItemForm()
    this.uploadedFiles = null
    this.imagePath == null
  }
  openImage(path: any) {
    if (path) {
      let modalref = this.modalService.open(ImageModalComponent,
        {
          ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true,
        }
      )
      modalref.componentInstance.path = path;
    }

  }

  onFileChange(event: any) {
    this.uploadedFiles = event.addedFiles[0];
  }
  removeFile(file: File) {
    this.uploadedFiles = null;
  }

  getUser() {
    return this.userDetails;
  }

  resetaddComboItemForm() {
    const data = {
      SortOrder: 1,
      NavIconClass: 'spin',
      IsActive: false
    }
    this.addComboItemForm.reset()
    this.addComboItemForm.patchValue(data)
  }

}