import { NotificationState, notificationReducer } from './notifications/notification.reducer';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageMetaReducer } from './meta-reducers/local-storage.metareducer';

export interface AppState {
  notifications: NotificationState;
}

export const reducers: ActionReducerMap<AppState> = {
  notifications: notificationReducer
};

export const metaReducers: MetaReducer<AppState>[] = [localStorageMetaReducer];
