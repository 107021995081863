import { style } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input } from '@angular/core';
import { ApexNonAxisChartSeries, ApexChart, ApexResponsive, ApexLegend, ApexPlotOptions } from 'ng-apexcharts';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  responsive: ApexResponsive[];
  legend: ApexLegend;
  colors: string[];
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-usage-info',
  templateUrl: './usage-info.component.html',
  styleUrls: ['./usage-info.component.scss'],
  animations:[fadeInOut]
})

export class UsageInfoComponent {
  @Input() height:boolean;
  @Input() id:any
  public chartOptions
  totalData:number
  hotspotdata:number
  usedData:number
  seriesData:any
  customTotal:any
  totalUseddata:any
  totalInterData = 15.02
  usageInfoData:any
  public isUsage  = new EventEmitter<boolean>();
isUsageSkeletonLoader: boolean=true;
  constructor(    public customerProfileService: CustomerProfileService,private httpService :HttpService, public commonService: CommonService,private decimalPipe: DecimalPipe) {  
  }

  ngOnInit(): void {
    this.getData()
  }

   async getData(){
    let data = {
      CustomerId:this.id ? this.id : this.httpService.getCustomerId()
    }
    var res5: any = await this.customerProfileService.getCustomerUsagesCardData(data)
    if (res5.Status) {
      this.usageInfoData = res5?.data
      
      const reamainingData =  this.usageInfoData?.liveUsage?.planData?.full?.remaining / (1024)
      const hotspotData  = this.usageInfoData?.liveUsage?.tetherData?.full?.used ? this.usageInfoData?.liveUsage?.tetherData?.full?.used / (1024) :0
      const usedData = this.usageInfoData?.liveUsage?.planData?.full?.used / (1024)
      const subtotal = this.usageInfoData?.liveUsage?.planData?.full?.limit / (1024)
      this.customTotal = parseFloat(subtotal.toFixed(2))
      this.hotspotdata = parseFloat(hotspotData.toFixed(3));
      this.totalData = parseFloat(reamainingData.toFixed(2));
      this.isUsageSkeletonLoader=false;
      this.customerProfileService.setusageSkeletonLoader(false);
    this.usedData = parseFloat((usedData - hotspotData).toFixed(2));
    this.totalUseddata = parseFloat(usedData.toFixed(2))
    this.seriesData = [this.usedData, this.hotspotdata, this.totalData].map(value =>
      Number.isInteger(value) ? value : parseFloat(value.toFixed(2))
    );
      this.getChart()
    } else {
      this.commonService.showToast('error', "Error", res5.message)
      this.isUsageSkeletonLoader=false;
    }
    this.isUsageSkeletonLoader=false;
  }
  getChart(){
 
    this.chartOptions = {
      series: this.seriesData,
      chart: {
        height: this.height ? 250 :350, 
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%', 
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '12px',
                fontWeight: 'bold'
              },
              value: {
                show: true,
                fontSize: '25px',
                fontWeight: 'bold',
                formatter: (val) => `${val} GB`, 
              },
              total: {
                show: true,
                fontSize: '14px',
                fontWeight: 'bold',
                showAlways: false, 
                formatter: () => `${this.customTotal} GB` 
              }
            }
          }
        }
      },
      colors: ['#eb3b5a', '#FFD629', '#4caf50','#5f3deb'], 
      labels: ['Mobile Data', 'Hotspot Data', 'Remaining Data','Total Used Data'], 
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val) => `${val} GB`,
        }
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250,
            },
            legend: {
              position: 'bottom',
            }
          }
        }
      ],
    };
    
  }
  get hotspotDataValue(): string {
    const usedData = this.usageInfoData?.liveUsage?.tetherData?.full?.used || 0;
    return usedData < 1024
      ? `${Math.floor(usedData)} MB`
      : `${(usedData / 1024).toFixed(2)} GB`;
  }
  
  get usageLimitValue(): string {
    const limitData = this.usageInfoData?.liveUsage?.tetherData?.full?.limit || 0;
    return (limitData / 1024) < 1
      ? `${Math.floor(limitData)} MB`
      : `${(limitData / 1024).toFixed(2)} GB`;
  }
  get remainingDataValue(): string {
    const remainingData = this.usageInfoData?.liveUsage?.planData?.full?.remaining || 0;
    return remainingData < 1024 
      ? `${Math.floor(remainingData)} MB` 
      : `${(remainingData / 1024).toFixed(2)} GB`;
  }
  
  get totalUsedDataValue(): string {
    const totalUsedData = this.usageInfoData?.liveUsage?.planData?.full?.used || 0;
    return totalUsedData < 1024 
      ? `${Math.floor(totalUsedData)} MB` 
      : `${(totalUsedData / 1024).toFixed(2)} GB`;
  }
  
  get planLimitValue(): string {
    const planLimit = this.usageInfoData?.liveUsage?.planData?.full?.limit || 0;
    return planLimit < 1024 
      ? `${Math.floor(planLimit)} MB` 
      : `${(planLimit / 1024).toFixed(2)} GB`;
  }
  get remainingMobileData(): string {
    const usedData = this.usageInfoData?.liveUsage?.planData?.full?.used || 0;
    const hotspotData = this.usageInfoData?.liveUsage?.tetherData?.full?.used || 0;
  
    const remainingData = usedData - hotspotData;
  
    // Determine the unit based on the value
    return remainingData < 1024 
      ? `${remainingData.toFixed(2)} MB` 
      : `${(remainingData / 1024).toFixed(2)} GB`;
  }
  
  
  get planLimitValue1(): string {
    const planLimit = this.usageInfoData?.liveUsage?.planData?.full?.limit || 0;
    return planLimit < 1024 
      ? `${Math.floor(planLimit)} MB` 
      : `${(planLimit / 1024).toFixed(2)} GB`;
  }
  
}
