
<div class="">
  <ul ngbNav #CustomHover="ngbNav" [activeId]="1" class="nav  nav-pills  animation-nav   d-flex gap-3 mb-2">
    <li [ngbNavItem]="1">
      <a ngbNavLink>
        <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
        <h5 class="nav-titl nav-tab-position ">Change Sim</h5>
      </a>
      <ng-template ngbNavContent>
        <app-sim-change1 [customerData]="customerData"  ></app-sim-change1>
      </ng-template>
  </li>
    <li [ngbNavItem]="2">
        <a ngbNavLink>
          <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
          <h5 class="nav-titl nav-tab-position ">Change MDN</h5>
        </a>
        <ng-template ngbNavContent>
          <app-change-mdn [customerData]="customerData"></app-change-mdn>
        </ng-template>
    </li>
 

<li [ngbNavItem]="3">
  <a ngbNavLink>
    <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
    <h5 class="nav-titl nav-tab-position ">Update IMEI</h5>
  </a>
  <ng-template ngbNavContent>
    <app-change-imei [customerData]="customerData" [accountType]="accountType"></app-change-imei>
  </ng-template>
</li>
<li [ngbNavItem]="4">
  <a ngbNavLink>
    <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
    <h5 class="nav-titl nav-tab-position ">Features & Service</h5>
  </a>
  <ng-template ngbNavContent>
    <app-features-services ></app-features-services>
  </ng-template>
</li>

  </ul>
  <div class="card-body">
    <div class="tab-content text-muted">
      <div [ngbNavOutlet]="CustomHover"></div>
    </div>
</div>
<div class="d-lg-flex d-block align-items-start flex-column" *ngIf="newtabsCondition">
  <div class="nav nav-tabs mb-3 w-100" id="v-pills-tab" role="tablist">


    <!-- <button (click)="activeTab='change-address'" class="nav-link" id="v-pills-change-address-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-change-address" type="button" role="tab" aria-controls="v-pills-change-address"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Change Address</h5>
        </div>
      </div>
    </button> -->

    <button (click)="activeTab='change-mdn'" class="nav-link active" id="v-pills-change-mdn-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-change-mdn" type="button" role="tab" aria-controls="v-pills-change-mdn"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <!-- <img src="assets/images/mobile.png" class="card-img"> -->
        </div>
        <div class="d-flex align-items-center">
          <h5>Change MDN</h5>
        </div>
      </div>
    </button>

    <button (click)="activeTab='sim-change'" class="nav-link" id="v-pills-sim-change-tab" data-bs-toggle="pill"
    data-bs-target="#v-pills-sim-change" type="button" role="tab" aria-controls="v-pills-sim-change"
    aria-selected="false">
    <div class="d-flex">
      <div>
        <!-- <img src="assets/images/mobile.png" class="card-img"> -->
      </div>
      <div class="d-flex align-items-center">
        <h5>Change Sim</h5>
      </div>
    </div>
  </button>

    <button (click)="onUpdateIMEIButtonClicked('v-pills-change-imei-tab'); activeTab='change-imei'" class="nav-link" id="v-pills-change-imei-tab" data-bs-toggle="pill"
    data-bs-target="#v-pills-change-imei" type="button" role="tab" aria-controls="v-pills-change-imei"
      aria-selected="false">
      <div class="d-flex hide-div"  >
        <div>
          <!-- <img src="assets/images/mobile.png" class="card-img"> -->
        </div>
        <div class="d-flex align-items-center">
          <h5>Update IMEI</h5>
        </div>
      </div>
    </button>
    
    
    <!-- <div *ngIf="accountTypeDisplay" else #showButton class="account-type-display" >
      Account Type: {{ accountTypeDisplay }}
    </div> -->

    

    <!-- <button (click)="activeTab='change-device'" class="nav-link" id="v-pills-edit-device-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-edit-device" type="button" role="tab" aria-controls="v-pills-edit-device"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Edit Device Info</h5>
        </div>
      </div>
    </button> -->

    <!-- <button (click)="activeTab='change-info'" class="nav-link" id="v-pills-customer-info-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-customer-info" type="button" role="tab" aria-controls="v-pills-customer-info"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Customer Information</h5>
        </div>
      </div>
    </button> -->

    <!-- <button (click)="activeTab='change-tag'" class="nav-link" id="v-pills-tag-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-tag" type="button" role="tab" aria-controls="v-pills-tag" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Tag</h5>
        </div>
      </div>
    </button> -->


    <button (click)="activeTab='change-feature'" class="nav-link" id="v-pills-features-services-tab"
      data-bs-toggle="pill" data-bs-target="#v-pills-features-services" type="button" role="tab"
      aria-controls="v-pills-features-services" aria-selected="false">
      <div class="d-flex">
        <div>
          <!-- <img src="assets/images/mobile.png" class="card-img"> -->
        </div>
        <div class="d-flex align-items-center">
          <h5>Features & Service</h5>
        </div>
      </div>
    </button>
    

  </div>
  <div class="tab-content" id="v-pills-tabContent">

    <!-- <div class="tab-pane fade" id="v-pills-change-address" role="tabpanel" aria-labelledby="v-pills-change-address-tab">
      <app-change-address *ngIf="activeTab=='change-address'"></app-change-address>
    </div> -->
    <div class="tab-pane fade show active" id="v-pills-change-mdn" role="tabpanel"
      aria-labelledby="v-pills-change-mdn-tab">
      <app-change-mdn [customerData]="customerData"  *ngIf="activeTab=='change-mdn'"></app-change-mdn>
    </div>

    <div class="tab-pane fade show active" id="v-pills-sim-change" role="tabpanel"
      aria-labelledby="v-pills-sim-change-tab">
      <app-sim-change [customerData]="customerData"  *ngIf="activeTab=='sim-change'"></app-sim-change>
    </div>
    
    <div class="tab-pane fade" id="v-pills-change-imei" role="tabpanel" aria-labelledby="v-pills-change-imei-tab">

      <app-change-imei [customerData]="customerData" [accountType]="accountType" *ngIf="activeTab=='change-imei'"></app-change-imei>

    </div>
    <!-- <div class="tab-pane fade" id="v-pills-edit-device" role="tabpanel" aria-labelledby="v-pills-edit-device-tab">
      <app-edit-device *ngIf="activeTab=='change-device'"></app-edit-device>
    </div> -->
    <!-- <div class="tab-pane fade" id="v-pills-customer-info" role="tabpanel" aria-labelledby="v-pills-customer-info-tab">
      <app-customer-info *ngIf="activeTab=='change-info'"></app-customer-info>
    </div> -->
    <!-- <div class="tab-pane fade" id="v-pills-tag" role="tabpanel" aria-labelledby="v-pills-tag-tab">
      <app-tag *ngIf="activeTab=='change-tag'"></app-tag>
    </div> -->

    <div class="tab-pane fade " id="v-pills-features-services" role="tabpanel"
      aria-labelledby="v-pills-features-services-tab">
      <app-features-services *ngIf="activeTab=='change-feature'"></app-features-services>
    </div>
  </div>

 
</div>