
<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
   Image</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body ">
  
  <div class="container-fluid">
  <img [src]="path" *ngIf="path" width="200px">
  </div>


</div>
