import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-skeleton',
  templateUrl: './button-skeleton.component.html',
  styleUrls: ['./button-skeleton.component.scss']
})
export class ButtonSkeletonComponent implements OnInit {

  @Input() buttonCount: number; // Default to 3 buttons if no input is provided
  buttonArray: any[] = [];

  constructor() {}

  ngOnInit(): void {
    // Populate the buttonArray based on the buttonCount input
    this.buttonArray = Array(this.buttonCount).fill(0);
    console.log('ngOnInit ButtonSkeletonComponent executed');
  }
}
