import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { EquipmentPurchaseService } from '../services/equipment-purchase.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-credit-card-purchase',
  templateUrl: './credit-card-purchase.component.html',
  styleUrls: ['./credit-card-purchase.component.scss'],
  animations:[fadeInOut]
})
export class CreditCardPurchaseComponent {

  loader: boolean = false;
  deviceCombos: any = [];
  
  formData: FormGroup;
  expireMonthDropdown = Array.from({ length: 12 }, (_, i) => i + 1);
  currentYear: number = new Date().getFullYear();
  futureYear: number = 2050;
  expireYearDropdown = Array.from({ length: this.futureYear - this.currentYear + 1 }, (_, i) => this.currentYear + i);
  isSkeletonLoader: boolean=true;
;

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private equipmentService: EquipmentPurchaseService,
  ) { }

  ngOnInit() {
    this.initializeForm()
    this.getDeviceCombos()
  }

  initializeForm() {
    this.formData = this.fb.group({
      "combos": [],
      "cardCode": [,Validators.required],
      "amount": [],
      "cardNumber": [,Validators.required],
      "nameOnCard": [,Validators.required],
      "expireMonth": [,Validators.required],
      "expireYear": [,Validators.required],
    })
  }

  get combos() {
    return this.formData.get("combos")
  }
  get amount() {
    return this.formData.get("amount")
  }

  onSelectDeviceCombos() {
    const selectedCombos = this.formData.get('combos')?.value || [];

    // Append new selected combos to the existing list while avoiding duplicates
    selectedCombos.forEach((combo: any) => {
      // Check if the combo is already in the list to avoid duplicates
      if (!this.selectedCombosWithAmount.selectedCombos.some((c: any) => c.comboId === combo.comboId)) {
        this.selectedCombosWithAmount.selectedCombos.push(combo);
      }
    });
  
    // Log the updated selectedCombosWithAmount object
  
    this.calculateTotalAmount()
  }

  selectedCombosWithAmount: any = {
    selectedCombos: [],
    totalAmount: 0
  };
  calculateTotalAmount() {
    let amount = 0;
    this.combos.value.forEach((combo: any) => {
      combo.discountPrice = 0.00; // Set discountPrice to 0.00
      amount += Number(combo.comboPrice) - combo.discountPrice;
    });
    this.amount.patchValue(amount);
}


  getPlaceholder(fieldName: string): string {
    const control = this.formData.get(fieldName);
    
    // Define custom error messages based on the fieldName
    const errorMessages: { [key: string]: string } = {
      cardNumber: 'Card Number is required.',
      expireMonth: 'Expire Month is required.',
      expireYear: 'Expire Year is required.',
      cardCode: 'CVV is required.',
      nameOnCard: 'Name on card is required.',
      address1: 'Address is required.',
      city: 'City is required.',
      state: 'State is required.',
      zipCode: 'Zip Code is required.',
      
    };
    
    const patternErrorMessages: { [key: string]: string } = {
      cardNumber: 'Card Number must be numeric.'
    };
  
    if (control?.touched && control?.hasError('required')) {
      return errorMessages[fieldName] || 'This field is required.';
    }
  
    if (control?.touched && control?.hasError('pattern')) {
      return patternErrorMessages[fieldName] || 'Invalid input format.';
    }
  
    // Default placeholders
    const defaultPlaceholders: { [key: string]: string } = {
      cardNumber: 'Card number',
      expireMonth: 'Expire Month',
      expireYear: 'Expire Year',
      cardCode: 'CVV',
      nameOnCard: 'Name on card',
      address1: 'Address',
      city: 'City',
      state: 'State',
      zipCode: 'Zip Code',
      taxAmount:'Tax Amount',
      processingFee:'Processing Fee',
      amount:'Total Amount'
    };
  
    return defaultPlaceholders[fieldName] || 'Enter value';
  }
 
  getExpireMonthPlaceholder() {
    const expireMonthControl = this.formData.get('expireMonth');
    
    if (expireMonthControl?.touched && expireMonthControl?.hasError('required')) {
      return 'Expire Month is required.';
    }
  
    return 'Expire Month'; // Default placeholder
  }
  
  getExpireYearPlaceholder() {
    const expireYearControl = this.formData.get('expireYear');
    
    if (expireYearControl?.touched && expireYearControl?.hasError('required')) {
      return 'Expire Year is required.';
    }
  
    return 'Expire Year'; // Default placeholder
  }
  
  onCardNumberChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // if (/[^0-9]/.test(value)) {
    //   inputElement.value = value.replace(/[^0-9]/g, '');
    //   alert('Only numeric characters are allowed.');
    // }

    this.formData.get('cardNumber')?.setValue(inputElement.value);
    const cardNumberStr = event.target.value;

    if (cardNumberStr.length === 1 || cardNumberStr.length === 16) {
      const cardType = this.getCardType(cardNumberStr);
      
      for (const key in this.validCardTypes) {
        if (this.validCardTypes.hasOwnProperty(key)) {
          this.validCardTypes[key] = key === cardType;
        }
      }

    } else if (!cardNumberStr) {
      this.resetCardType();
    }
  }

  
  resetCardType() {
    for (const key in this.validCardTypes) {
      if (this.validCardTypes.hasOwnProperty(key)) {
        this.validCardTypes[key] = false;
      }
    }
  }
  getCardType(cardNumber: string): string | null {
    if (!cardNumber || cardNumber.length === 0) {
      return null; 
    }

    const firstDigit = cardNumber.charAt(0);

    if (firstDigit === "4") {
      return "Visa";
    } else if (firstDigit === "5") {
      return "MasterCard";
    } else if (firstDigit === "6") {
      return "Amex";
    } else {
      return null; 
    }
  }

  isCardTypeValid(cardType: string): boolean {
    return this.validCardTypes[cardType];
  }
  validCardTypes = {
    Amex: false,
    Visa: false,
    MasterCard: false,
  }; 
  async getDeviceCombos() {
    this.loader = true;
    try {
      const res: any = await this.equipmentService.getDeviceCombos()
      
      if (res?.Status) {
        this.deviceCombos = res?.data
        this.isSkeletonLoader=false
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
        this.isSkeletonLoader=false
      }
    }
    catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.isSkeletonLoader=false
    }
    this.loader = false;
  }

  async onSubmit() {
    if (this.formData.invalid) {
      this.formData.markAllAsTouched(); // Mark all fields as touched to show errors
      return; // Exit the function if the form is invalid
    }
    this.loader = true;
    try {
      let data = {
        "combos": this.combos.value.map(el => {
          return {
            "comboId": el.comboId,
            "products": el.products.map(el2 => {
              return { "productId": el2.productId }
            })
          }
        }),
        "taxIncluded":1,
        "taxAmount":0,
        "chargeCreditCard": "Y",
        "createTransactionRequest": {
          "refId": "123456",
          "transactionRequest": {
            "amount": this.formData.value.amount,
            "quantity": 1,
            "payment": {
              "creditCard": {
                "cardNumber": this.formData.value.cardNumber,
                "expirationDate": this.formData.value.expireYear + '-' + this.formData.value.expireMonth,
                "cardCode": this.formData.value.cardCode,
                "nameOnCard": this.formData.value.nameOnCard
              }
            }
          }
        }
      }
      const res: any = await this.equipmentService.saveDeviceCombos(data);
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Save Successful.')
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

}
