

<!-- app.component.html -->
<!-- 

<div class="container">
  <div class="row">

    <div class="col-md-12" style="margin-top: 50px;">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let item of profileData; let i = index">
          <h2 class="accordion-header" id="heading{{i}}">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
              {{ item?.DisplayName }}
            </button>
          </h2>
          <div id="collapse{{i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
            <div class="accordion-body">
             <p *ngFor="let item of item?.resource_types">{{item?.DisplayName}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</div> -->


<!-- <div class="p-3" style="margin-top: -10px;">
  <div class="row">
      <div class="col-12">
          <app-breadcrumb></app-breadcrumb>
      </div>
  </div>
</div> -->


<div class="container">
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end mt-3">
      <button mat-raised-button  class="btn btn-primary" routerLink="/user-settings"> <i class="fa fa-arrow-left" aria-hidden="true"></i> Back 
      </button>
    </div>
    
    <div class="col text-center">
    <div class="d-grid mt-3">
      <button type="button" class="btn  btn-dark"><strong>Agent Permission</strong></button>
    </div>
  </div>

    <div class="col-md-12 mt-3">
      <label>Agents</label>
      <ng-select [(ngModel)]="seletedAgent" (ngModelChange)="getAllPermissions($event)">
          <ng-option *ngFor="let item of agentList" [value]="item.UserID">{{item?.name}}</ng-option>
       </ng-select> 
   </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <label>Categeory</label>
      <ng-select [(ngModel)]="selectedCar1"  (change)="onSelectCar1()" (clear)="onClearSelection1()">
          <ng-option *ngFor="let item of profileData" [value]="item.id">{{item.DisplayName}}</ng-option>
       </ng-select>
   </div>
    <div class="col-md-6" *ngIf="selectedCar1">
    <label>Type</label>
    <ng-select [(ngModel)]="selectedCar2"  (change)="onSelectCar2()" (clear)="onClearSelection1()">
        <ng-option *ngFor="let item of resource_types" [value]="item.id">{{item.DisplayName}}</ng-option>
     </ng-select>
   </div>

    <div class="col-md-12" *ngIf="selectedCar2">
     <div class="card mt-5" >
      <h4 class="mt-4 mx-4">Permissions</h4>
       <div class="card-body">
        <div class="table-responsive tableFixHead">
         <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Permissions</th>
                <th scope="col">View</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of resources; let i = index">
                <td class="cursor-pointer" data-toggle="tooltip" data-placement="top" title="{{item?.Description}}">
                  {{item?.DisplayName}}
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="item.resource_permission[0]?.CanView || false" 
                           (change)="onCheckboxChange(item, 'CanView', $event)" id="readCheckbox">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="item.resource_permission[0]?.CanEdit || false" 
                           (change)="onCheckboxChange(item, 'CanEdit', $event)" id="editCheckbox">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="item.resource_permission[0]?.CanDelete || false" 
                           (change)="onCheckboxChange(item, 'CanDelete', $event)" id="deleteCheckbox">
                  </div>
                </td>
                <td *ngIf="item.flag == 1">
                  <button class="btn btn-primary" (click)="updatePermissions(item)">Save</button>
                </td>
                <td *ngIf="item.flag == 0">
                  <button class="btn btn-primary" (click)="createPermission(item)">Save</button>
                </td>
              </tr>
              
              
              
            </tbody>
          </table>
          </div>
          </div>
     </div>
   </div>
  </div>
  
</div>


