import { Component } from '@angular/core';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { NotesService } from '../../customer-footer/customer-notes/services/notes.service';
import { formatDate } from '@angular/common';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';
import { CommonService } from 'src/app/shared/services/common.service';
import Swal from 'sweetalert2';
declare global {
  interface String {
    hashCode(): number;
  }
}

String.prototype.hashCode = function () {
  let hash = 0, i, chr;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // hash * 31 + chr
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.scss']
})
export class NewDashboardComponent {
  applications = [
    { name: 'HVMS', icon: 'assets/hvms-icon.png' },
    { name: 'BMS', icon: 'assets/bms-icon.png' },
    { name: 'Admin', icon: 'assets/admin-icon.png' }
  ];

  releaseUpdates = [
    { date: '5 Oct, 2024', description: 'Cancel trip up to truck assignment stage.' },
    { date: '5 Oct, 2024', description: 'Show load dim. in HV assigner trip details.' },
    { date: '5 Oct, 2024', description: 'Permit download in driver page.' },
    // Add more updates here...
  ];
  public autoRenew: boolean = false;
  currentTime: string = '';
  currentDate: string = '';
  public currentPage: number = 1;
  public itemsPerPage: number = 50;
  allNotes: any = []
  first_page_url: any;
  last_page_url: any;
  hasMoreData: any;
  next_page_url: any;
  totalItems: any;
  simType: any;

  isShowMidContent:boolean = false
  isFading:boolean = false
  activeCard:any ;
  backgroundImages: string[] = [
    "https://img.freepik.com/free-photo/autumn-leaf-falling-revealing-intricate-leaf-vein-generated-by-ai_188544-9869.jpg",
    'https://wallpapers.com/images/featured/full-hd-background-vjzgi9qrproc2upa.jpg',
    // 'https://images.unsplash.com/photo-1527745600312-3ab9cf4434a3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080',
    'https://images.unsplash.com/photo-1519125323398-675f0ddb6308?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080',
    'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080',
    'https://images.unsplash.com/photo-1472214103451-9374bd1c798e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080'
  ];
   // Current background image index
   currentIndex: number = 0;

   // Current background image
   dashboardBackground: string = this.backgroundImages[this.currentIndex];
  constructor(public customerProfileService: CustomerProfileService, private notesService: NotesService,private subscriptionService:SubscriptionService,private commonService :CommonService) {

  }

  async ngOnInit() {
    await this.customerProfileService.setCustomerId(112)
    await this.customerProfileService.getMinimalProfileData(112)
    this.getData()
    this.updateDateTime();
    setInterval(() => this.updateDateTime(), 1000); // Update every second
  }

  updateDateTime() {
    const now = new Date();
    this.currentTime = now.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/New_York' // Specify the timezone (e.g., Eastern Time)
    });
    this.currentDate = now.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'America/New_York' // Specify the timezone
    });
  }

  toggleView() {
    // Handle view toggling
  }

  editSettings() {
    // Handle settings editing
  }

  fullscreen() {
    // Toggle fullscreen mode
  }

  toggleTheme() {
    // Toggle light/dark theme
  }

  lockScreen() {
    // Lock the screen
  }





  async getData() {
    try {
      let data = {
        "perPage": this.itemsPerPage,
        "page": this.currentPage,
        "CustomerId":112
      };
      const res: any = await this.notesService.getNotes(data);
      if (res.Status) {
        const newNotes = res.data.data || [];

        // If this.allNotes is empty (first-time load), skip the filtering logic
        if (this.allNotes && this.allNotes.length > 0) {
          // Filter out notes that already exist in this.allNotes (to avoid duplicates)
          const uniqueNotes = newNotes.filter((newNote: any) =>
            !this.allNotes.some((existingNote: any) => existingNote.id === newNote.id)
          );

          // Append only unique notes
          this.allNotes = [...this.allNotes, ...uniqueNotes];
        } else {
          // First-time load, assign the new notes directly
          this.allNotes = newNotes;
        }

        // Update pagination and other details
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page + 1;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
        this.hasMoreData = !!res.data.next_page_url;

        this.notesService.notesAvailable();

        // Convert CreatedDate to Date objects
        this.allNotes.forEach(item => {
          item.CreatedDate = new Date(item.CreatedDate);
        });
        console.log(this.allNotes);
        this.groupTimelineItems();
        console.log(this.allNotes);
      } else {
        this.notesService.noNotes();
        this.allNotes = [];
      }
    } catch (err) {
      this.allNotes = [];
    }
  }

  groupTimelineItems(): void {
    const groupedItems: any = [];
    let currentGroup = '';

    // Sort by CreatedDate descending
    this.allNotes.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());

    // Group items based on their date
    this.allNotes.forEach(item => {
      const itemGroup = this.getTimeGrouping(item.CreatedDate);
      item.group = "";

      if (itemGroup !== currentGroup) {
        // Insert a group marker only if it's a new group
        groupedItems.push({ id: itemGroup.hashCode(), ...item, group: itemGroup });
        currentGroup = itemGroup;
      }

      groupedItems.push(item); // Insert the note
    });

    this.allNotes = groupedItems;
    console.log(this.allNotes);
  }

  // Helper method to create a group marker
  createGroupMarker(group: string, item) {
    return {
      id: 0,
      CustomerID: 0,
      AgentID: 0,
      Text: group,
      CreatedDate: new Date(), // Set to now or a constant date
      type: 'group',
      customer: { id: 0, FirstName: '', LastName: '' },
      ...item // Mock customer data
    };
  }

  getTimeGrouping(date: Date): string {
    const today = new Date();
    const dayDiff = Math.floor((today.getTime() - date.getTime()) / (1000 * 3600 * 24));

    if (dayDiff === 0) {
      return 'Today';
    } else if (dayDiff <= 7) {
      return 'This Week';
    } else if (formatDate(today, 'MMMM y', 'en-US') === formatDate(date, 'MMMM y', 'en-US')) {
      return formatDate(date, 'MMMM y', 'en-US');
    } else if (today.getFullYear() === date.getFullYear()) {
      return formatDate(date, 'y', 'en-US');
    } else {
      return formatDate(date, 'y', 'en-US');
    }
  }

  refreshSimType(){

  }



  closeMidContent(){
    this.isShowMidContent = false
  }

  onCardClick(card){
    this.isShowMidContent = true
    this.activeCard = card
    console.log(this.activeCard,this.isShowMidContent);
  }

  changeBackground(direction: 'previous' | 'next') {
    if (direction === 'next') {
      this.currentIndex = (this.currentIndex + 1) % this.backgroundImages.length;
    } else {
      this.currentIndex =
        (this.currentIndex - 1 + this.backgroundImages.length) % this.backgroundImages.length;
    }
    this.dashboardBackground = this.backgroundImages[this.currentIndex];
  }
}





