<!-- <div class="container mt-4">
  <div class="row justify-content-center">
    <div class=" col-sm-6   mb-4" *ngFor="let plan of plans">
      <div class="card-container">
        <div class="row  custom-card mx-3 mb-2">

          <div class="core-benefits-container mt-2 col-md-12">
            <div class="d-flex justify-content-between mb-0">
              <div class="" [ngClass]="{'selected-plan': formData.get('planId').value === plan.id}"
                (click)="formData.get('planId').setValue(plan.id)">
                <div class="card-body d-flex flex-column align-items-center">
                  <h5 class="card-title mb-3">{{ plan.DisplayName }}</h5>
                </div>
              </div>
              <div class="div">
                <button type="button" [disabled]="formData.invalid" (click)="onSubmitPlanForm()"
                  class="btn btn-primary btn-lg px-5 py-2">
                  Select
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div> -->
<div class="container" *ngIf="!isSkeletonLoader">
  <div class="d-flex flex-column gap-3  p-2" style="height: 100vh; overflow: auto;" #cardsContainer>
    <div class="col-md-12" *ngFor="let card of plans" [attr.data-category]="card.category">
      <div class="card-container">
        <div class="row  custom-card mb-2">

          <div class="core-benefits-container mt-2 col-md-12">
            <div class="d-flex mb-0 flex-column-mobile">

              <ng-container *ngIf="card?.ImapgePath">
                <div class="image-container" style="max-width: 15%; overflow: hidden; border-radius: 10px;">
                  <img [src]="card?.ImapgePath" alt="Plan Image" style="
                                width: 100%;
                                height: auto;
                                object-fit: cover;
                                border-radius: 10px;
                              " />
                </div>
              </ng-container>
              <div class="d-flex flex-column w-100">
                <div class="top-data d-flex justify-content-evenly w-100 mb-2">
                  <div class="benefit-list mb-0">
                    <label class=" mb-0 gap-1 d-flex align-items-start">
                      <span class="text-muted mt-2">$</span>
                      <span class="benefit-title">{{ card.Price }}</span>
                    </label>
                    <span class="text-muted mx-4">Total</span>
                  </div>
                  <div class="benefit-list mb-0 d-flex flex-column">
                    <label class="benefit-title mb-0">{{ card.PlanData }}</label>
                    <span class="text-muted">Data</span>
                  </div>
                  <div class="benefit-list mb-0">
                    <label class="d-flex align-items-center mb-0 gap-1">
                      <span class="benefit-title">{{ card.ExpirationDays }}</span>
                      <span class="text-muted mt-1">Days</span>
                    </label>
                    <span class="text-muted">Validity</span>
                  </div>
                  <div class="benefit-list mb-0" *ngIf="card.PlanData == 'Unlimited'">
                    <label class="d-flex align-items-center mb-0 gap-1">
                      <span class="text-muted mt-2">$</span>
                      <span class="benefit-title">{{ card?.monthlyAmount }}</span>
                    </label>
                    <span class="text-muted">Monthly</span>
                    <!-- <span class="text-muted">Validity</span> -->
                  </div>
                </div>
                <div class="bottom-data d-flex justify-content-evenly w-100 flex-column-mobile">
                  <div class="d-flex align-items-center">
                    <app-feather-icons [icon]="'phone-call'" class="mt-2"></app-feather-icons>
                    <span class="ms-2">{{ card?.VoiceText || "-" }} Minutes</span>
                  </div>
                  <div class="d-flex align-items-center ">
                    <app-feather-icons [icon]="'smartphone'"></app-feather-icons>
                    <span class="ms-2 ellipsis-tooltip" [title]="card?.Name">{{ card?.Name || "-" }}</span>
                  </div>
                  <div class="d-flex align-items-center ">
                    <app-feather-icons [icon]="'message-square'" class="mt-2"></app-feather-icons>
                    <span class="ms-2">{{ card?.VoiceText || "-" }} SMS</span>
                  </div>
                  <div class="d-flex align-items-center " *ngIf="card.PlanData == 'Unlimited'">
                    <app-feather-icons [icon]="'dollar-sign'" class="mt-2"></app-feather-icons>
                    <span class="ms-2">{{ card?.savingAmount }} Savings</span>
                  </div>
                </div>
              </div>
              <div class="div mt-4">
                <button type="button" [disabled]="formData.invalid" (click)="onSubmitPlanForm(card.PlanId)"
                  class="btn btn-primary btn-lg px-5 py-2">
                  Select
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Submit Button -->
  <!-- <div class="row mt-4">
    <div class="col-md-12 text-center">
      <button type="button" [disabled]="formData.invalid" (click)="onSubmitPlanForm()"
        class="btn btn-primary btn-lg px-5 py-2">
        Submit
      </button>
    </div>
  </div> -->
  <div *ngIf="isSkeletonLoader;" style="max-width: 100%;">
    <app-skeleton-loader [alignment]="'row'" [cardCount]="10" shape="small-card"></app-skeleton-loader>
  </div>