<div class="container m-0 p-0" style="max-width: 100%;">
  <div class="card form-card shadow mt-0" style=" max-height: 80vh;">

    <div class="br-5 d-flex justify-content-between text-white text-center bg-primary">
      <h5 class="card-title mb-0" style="font-size: 1.5rem; letter-spacing: 2px;">APPLICATION INFORMATION</h5>
      <a  class="button local bg-dark" (click)="reset()" title="Send High Usage Email">
        <i class="fa-solid fa-rotate-right"></i> <span>Reset </span>
      </a>

    </div>


    <div class="card-body" *ngIf="!isSkeletonLoader" @fadeInOut>
      <form [formGroup]="applicationInfoFormData">
        <!-- Loop through form fields to display each field in its own row -->
        <ng-container *ngFor="let field of formFields">
          <div *ngIf="field.visible" class="row mb-3">
            <div class="col-12">
              <div class="row align-items-center">
                <!-- Label for the Field -->
                <div class="col-12 col-md-4">
                  <label for="{{ field.controlName }}" class="form-label mb-0" style="font-size: 1rem; font-weight: 500;">
                    {{ field.label }}
                  </label>
                </div>

                <!-- Input and Actions -->
                <div class="col-12 col-md-8">
                  <div class="input-group">
                    <!-- Display Mode (Default State) -->
                    <div *ngIf="!isEditing(field.controlName)" class="d-flex justify-content-between align-items-center w-100">
                      <div class="text-truncate p-2">{{ applicationInfoFormData.get(field.controlName)?.value }}</div>

                      <!-- Dropdown for userName Field (Visible on All Screens) -->
                      <div *ngIf="field.controlName === 'userName'" class="dropdown d-block" ngbDropdown #userDropdown="ngbDropdown" >
                        <button class="btn btn-custom-unique dropdown-toggle" ngbDropdownToggle >
                          <i class="fa fa-ellipsis-h"></i>
                        </button>
                        <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton" class="custom-dropdown-menu" (mouseleave)="userDropdown.close()">
                          <li>
                            <a class="dropdown-item" (click)="toggleEdit(field.controlName); userDropdown.open()">
                              <i class="fa fa-edit"></i> Edit
                            </a>
                          </li>
                          <li *ngIf="!hideMailButton">
                            <a class="dropdown-item" (click)="withConfirmation(); userDropdown.open()">
                              <i class="fa fa-envelope"></i> Send Email
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" (click)="userDropdown.close()">
                              <i class="fa fa-times"></i> Close
                            </a>
                          </li>
                        </ul>
                      </div>
                      

                      <!-- Buttons for Other Fields (Same for All Screens) -->
                      <div *ngIf="field.controlName !== 'userName'">
                        <button type="button" class="btn btn-custom" (click)="toggleEdit(field.controlName)">
                          <i class="fa fa-edit"></i>
                        </button>
                      </div>
                    </div>

                    <!-- Edit Mode -->
                    <div *ngIf="isEditing(field.controlName)" class="w-100">
                      <div class="d-flex justify-content-between align-items-center">
                        <!-- Input Field -->
                        <input [formControlName]="field.controlName" type="text" class="form-control form-control-lg" style="font-size: 0.8rem; height: 45px;" />

                        <!-- Save and Cancel Buttons for Other Fields (Same for All Screens) -->
                        <div *ngIf="field.controlName !== 'userName'" class="d-flex ms-2">
                          <button type="button" class="btn btn-custom-save" (click)="updateCustomerProfile(field.controlName)">
                            <i class="fa fa-save"></i>
                          </button>
                          <button type="button" class="btn btn-custom-cancel ms-2" (click)="removeInputField(field.controlName)">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>

                        <!-- Actions in Dropdown for userName (Visible on All Screens) -->
                        <div *ngIf="field.controlName === 'userName'" class="dropdown d-block" ngbDropdown #editUserNameDropdown="ngbDropdown">
                          <button class="btn btn-custom-unique dropdown-toggle" ngbDropdownToggle>
                            <i class="fa fa-ellipsis-h"></i>
                          </button>
                          <ul ngbDropdownMenu aria-labelledby="editUserNameDropdownMenu">
                            <li>
                              <a class="dropdown-item" (click)="onEditIconClick(field.controlName); editUserNameDropdown.close()" (click)="updateCustomerProfile(field.controlName)">
                                <i class="fa fa-save"></i> Save
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="removeInputField(field.controlName); editUserNameDropdown.close()">
                                <i class="fa fa-times"></i> Cancel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
    <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [formColumnCount]="1" [formRowCount]="10" shape="single-column-form"></app-skeleton-loader>
    </div>

    <!-- Card Footer -->
  </div>
</div>

<!-- Loader (if needed) -->
<!-- <div *ngIf="isLoading" class="card-loader">
  <app-loader [isChildLoader]="true"></app-loader>
</div> -->
