import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Notification } from 'src/app/store/notifications/notification.model';

@Component({
  selector: 'app-global-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class GlobalNotificationComponent implements OnInit {
  notifications$: Observable<Notification[]>;
  unreadCount$: Observable<number>;
  doNotDisturb = false;
  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notifications$ = this.notificationService.getAllNotifications();
    console.log(this.notifications$);
    this.unreadCount$ = this.notificationService.getUnreadCount();
  }

  markAsRead(id: number): void {
    this.notificationService.markAsRead(id);
  }

  markAllAsRead(): void {
    this.notificationService.markAllAsRead();
  }
}
