import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserAgentService } from '../../services/user-agent.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-new-manage-user',
  templateUrl: './new-manage-user.component.html',
  styleUrls: ['./new-manage-user.component.scss'],
  animations:[fadeInOut]
})
export class NewManageUserComponent implements OnInit {
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number;
  currentPage: number = 1; 
  totalPages: number = 1;
  userList: any;
  public first_page_url: any = "";
  public last_page_url: any = "";
  initials:any
  public next_page_url: any = "";
  totalusers:number
isSkeletonLoader: boolean=true;
  constructor(private fb:FormBuilder,private userAgentService:UserAgentService,private _snackBar:MatSnackBar,
    private router:Router,private http:HttpService,private commonService:CommonService){}


    ngOnInit(): void {

      this.getUserList()
    }
  getUserList(search?){
    
    let itemsPerPage = this.itemsPerPage;
    let obj={
      "per_page": itemsPerPage,
      "page": this.currentPage,
    }
    this.userAgentService.getUserList(search,obj).subscribe((res:any)=>{

    
      this.userList=res?.data?.data;
      this.totalusers=res?.data?.total
      this.totalItems = res.data.total
      this.currentPage = res.data.current_page
      this.first_page_url = res.data.first_page_url
      this.last_page_url = res.data.last_page_url
      this.next_page_url = res.data.next_page_url
      console.log('userlIst:', this.userList);

    })
    setTimeout(()=>{
      this.isSkeletonLoader=false
    },1500)
    // this.loadUser=false
  }
  onChangeNumberOfPage() {
    this.getUserList()
  }
  pageChange(event) {
    this.currentPage = event
    this.getUserList()
  }
  editRouter(user:any){
    console.log("roter function is calll")
    this.router.navigate([`/edit-users`],{state:{user:user}})
  }
   viewProfile(id) {
    console.log("value",id)
    this.router.navigate([`/new-profile/${id}`]);
  }
  back(){
    this.router.navigateByUrl(`/user-settings`)
  }
  filterdData(event:any){
    let data=event.target.value
    this.getUserList(data);
    // console.log('getUser data: ',this.getUserList(data))
  }
}
