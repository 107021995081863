import { createReducer, on } from '@ngrx/store';
import { addNotification, markAsRead, markAllAsRead, loadNotifications } from './notification.actions';
import { Notification } from './notification.model';

export interface NotificationState {
  notifications: Notification[];
}

export const initialState: NotificationState = {
  notifications: []
};

export const notificationReducer = createReducer(
  initialState,
  on(loadNotifications, (state, { notifications }) => ({
    ...state,
    notifications: [...notifications]
  })),
  on(addNotification, (state, { notification }) => ({
    ...state,
    notifications: [notification, ...state.notifications]
  })),
  on(markAsRead, (state, { id }) => ({
    ...state,
    notifications: state.notifications.map(notification =>
      notification.id === id ? { ...notification, IsRead: true } : notification
    )
  })),
  on(markAllAsRead, state => ({
    ...state,
    notifications: state.notifications.map(notification => ({ ...notification, IsRead: true }))
  }))
);
