import { Component } from '@angular/core';

@Component({
  selector: 'app-to-receive',
  templateUrl: './to-receive.component.html',
  styleUrls: ['./to-receive.component.scss']
})
export class ToReceiveComponent {

}
