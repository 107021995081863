<div class="card timeline-skeleton-container p-4">
    <!-- Top Input Section (Avatar, Input, Dropdown, and Button) -->
    <div class=" card d-flex align-items-start mb-4 p-4">
      <div class="skeleton-avatar bg-light me-3 m-1"></div>
      <div class="w-100">
        <div class="skeleton-input bg-light mb-3"></div>
        <div class="d-flex align-items-center">
          <div class="skeleton-select bg-light me-3"></div>
          <div class="skeleton-button bg-light"></div>
        </div>
      </div>
    </div>
  
    <!-- Timeline Skeleton Items -->
    <div *ngFor="let item of timelineArray" class="d-flex mb-4 mx-2">
      <!-- Timeline Vertical Line and Dots -->
      <div class="d-flex flex-column align-items-center">
        <div class="skeleton-dot bg-light mb-3"></div>
        <div class="timeline-line bg-light"></div>
      </div>
  
      <!-- Skeleton Event Content -->
      <div class="ms-4 w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div class="skeleton-text bg-light w-75 mb-2"></div>
          <div class="skeleton-time bg-light"></div>
        </div>
        <div class="skeleton-content bg-light w-50 mb-2"></div>
        <div class="d-flex align-items-center">
          <div class="skeleton-badge bg-light me-3"></div>
          <div class="skeleton-text bg-light w-25"></div>
        </div>
      </div>
    </div>
  </div>
  