
<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row">
      <div class="col-md-12">
        <h5>
          CSR Screen For Change APS/WPS
        </h5>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>Customer Id</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Change APS/WPS To</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Plan</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-12 text-end" >
        <button type="button" class="btn btn-primary">Submit</button>
      </div>  
    </div>

 
  </form>
</div>