import { Component } from '@angular/core';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent {
  showNotifications = true;
  showMessages = true;
  formattedMessagesList:any
  toggleButtonLabel = 'Clear';
  alerts:any =[]
  // List of messages to display
  messagesList = [
    { icon: 'https://app.crowdsignal.com/images/logo-white.svg', title: 'New Post', time: '8:24AM', description: 'Sarah just posted a story' },
    // { icon: 'fa-solid fa-thumbs-up', title: 'Engagement', time: '8:44AM', description: 'Daves liked your post' },
    // { icon: 'fa-solid fa-arrow-right-to-bracket', title: 'Security Alert', time: '8:56AM', description: 'New login from San Jose, CA' },
    // { icon: 'fa-brands fa-twitter', title: 'New Tweet', time: '9:32AM', description: 'Mary has posted a message' },
    // { icon: 'fa-solid fa-envelope', title: 'New Message', time: '10:16AM', description: 'You have a new message from John' }
  ];
  constructor(
    public customerProfileService: CustomerProfileService,
  ){}

  ngOnInit() {
  this.customerProfileService.getSubscriptionCardData().then((res: any) => {
    this.alerts =  res?.data?.alerts;
    console.log('alert length ',this.alerts)
    if (this.alerts) {
      this.formattedMessagesList = this.alerts.map(item => {
        const [msg, date] = item.message.split(' - ');
        return { ...item, msg, date };
      });
     
    }
 });


    
  }
  toggleNotifications() {
    this.showMessages = !this.showMessages;
    this.toggleButtonLabel = this.showMessages ? 'Clear' : 'Undo';
  }

  clearMessages() {
    this.showMessages = false;
    this.toggleButtonLabel = 'Undo';
  }

  closeNotifications() {
    this.showNotifications = false;
  }
}
