import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { CustomerProfileService } from "./customer-profile.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { PermissionService } from "./permission-service.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  isHidden?: boolean
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  sidebarMenus: any;
  headerMenus: any
  footerMenus: any
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  public sideMenubarSub = new Subject<any>();
  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;
  public changeCompany: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = true;
  // public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;



  // Full screen
  public fullScreen: boolean = false;
  constructor(private router: Router, private permissionService: PermissionService, private customerService: CustomerProfileService, private authService: AuthService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
    const isCustomerLogin = JSON.parse(localStorage.getItem('isCustomerLogin')!);
    console.log(isCustomerLogin);
    if (isCustomerLogin) {
      this.sideMenuInitilize();
    }

  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[]
    = [
      // {
      //   headTitle1: "Review Dashboard",
      // },
      // {
      //   title: "Dashboards",
      //   icon: "home",
      //   type: "sub",
      //   badgeType: "light-success",
      //   badgeValue: "5",
      //   active: true,
      //   // children: [
      //   //   { path: "/dashboard/default", title: "Default", type: "link" },
      //   //   { path: "/dashboard/ecommerce", title: "Ecommerce", type: "link" },
      //   //   { path: "/dashboard/online-course", title: "Online course", type: "link" },
      //   //   { path: "/dashboard/crypto", title: "Crypto", type: "link" },
      //   //   { path: "/dashboard/social", title: "Social", type: "link" },
      //   // ],
      // },

      {
        title: "Home",
        icon: "home",
        type: "link",
        badgeType: "light-success",
        path: "/customer-dashboard"
        // badgeValue: "5",
        // active: true,
        // children: [
        //   { path: "/customer/home", title: "Home", type: "link" },
        // ],
      },
      {
        title: "Order Review",
        icon: "activity",
        type: "link",
        badgeType: "light-success",
        /*
                badgeValue: this.sidebarMenus[3].children?.length,
                children: [
                  { path: "/review", title:this.sidebarMenus[3]?.children[0]?.DisplayName, type: "link" },
                ],
        */
        path: "/review"
        // badgeValue: "5",
        // active: true,
        // children: [
        //   { path: "/customer/home", title: "Home", type: "link" },
        // ],

      },
      {
        title: "Tickets",
        icon: "trello",
        type: "link",
        badgeType: "light-success",
        path: "/ticket-system"
      },
      {
        title: "Bulk process",
        icon: "send",
        type: "link",
        badgeType: "light-success",
        path: "/bulk-process"
      },
      {
        title: "Reports",
        icon: "pie-chart",
        type: "link",
        badgeType: "light-success",
        path: "/reports"
      },
      {
        title: "Fullfillment",
        icon: "home",
        type: "link",
        badgeType: "light-success",
        /*
                badgeValue: this.sidebarMenus[8].children?.length,
                children: [
                  { path: "/fullfillment", title:this.sidebarMenus[8]?.children[0]?.DisplayName, type: "link" },
                  { path: "", title:this.sidebarMenus[8]?.children[1]?.DisplayName, type: "link" },        ],
        */
        path: "/fullfillment/table-view"

      },
      {

        title: "Settings",
        icon: "settings",
        type: "link",
        badgeType: "light-success",
        path: "/user-settings"
      },
      {
        title: "Recent  Searches",
        icon: "clock",
        type: "link",
        badgeType: "light-success",
        path: "/recent-customer-searches"
      }
    ];

  async sideMenuInitilize() {
    try {
      const res = await this.permissionService.getResourceCategoryById('MENUS') as any;
      const data = res;
      const resourceTypes = data.resource_types;
      if (Array.isArray(resourceTypes) && resourceTypes.length > 0) {
        this.sidebarMenus = resourceTypes[0]?.resources || [];
        if (resourceTypes.length > 1) {
          this.headerMenus = resourceTypes[1] || {};
        }
        if (resourceTypes.length > 2) {
          this.footerMenus = resourceTypes[2] || {};
        }
        this.constructMenuItems();
      } else {
        console.error("Resource types array is empty or not defined");
      }

    } catch (error) {
      console.error("Error occurred while fetching menu types:", error);
    }
  }
  
  constructMenuItems() {
    this.MENUITEMS = this.mapedMenuItems()
    this.setSideMenuBar(this.MENUITEMS)
  }

  mapedMenuItems() {
    return this.sidebarMenus.map(el => {
      let tempData = {}
      let lastIndex = el.resource_permission.length - 1;
      let isHidden = el.resource_permission[0]?.CanView == 1 ? 0 : 1;
      if (el.children.length == 0) {
        tempData = {
          title: el.DisplayName,

          //isHidden: this.getPermission(el),
          // isHidden: convertToBoolean(this.getPermission(el)),

          isHidden,
          // isHidden: convertToBoolean(el.HideMenu),

          icon: el.NavIconClass,
          type: "link",
          badgeType: "light-success",
          path: el.UrlRoute
        }


      } else {
        tempData = {
          title: el.DisplayName,

          //isHidden: this.getPermission(el),

          isHidden,

          icon: el.NavIconClass,
          type: "sub",
          badgeType: "light-success",
          path: el.UrlRoute,
          badgeValue: el.children?.length,
          children: el.children.map(el2 => {
            return {
              path: el2.UrlRoute, title: el2.DisplayName, type: "link"
            }
          })
        }
      }

      return tempData
    })
  }

  setSideMenuBar(menuItem: Menu[]) {
    this.sideMenubarSub.next(menuItem);

  }

  getSideMenuBar() {
    return this.sideMenubarSub.asObservable();
  }


  getPermission(el: any) {
    if (!el?.resource_permission) {
      return 1
    }
    if (el?.resource_permission?.CanView == 1) {
      return 0
    }
    else {
      return 1
    }

  }

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
