import { Component } from '@angular/core';

@Component({
  selector: 'app-ivr-credit-card-renew',
  templateUrl: './ivr-credit-card-renew.component.html',
  styleUrls: ['./ivr-credit-card-renew.component.scss']
})
export class IvrCreditCardRenewComponent {

}
