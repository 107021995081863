<swiper [direction]="'vertical'" [loop]="false" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <!-- Only one ng-template for swiperSlide -->
  <ng-template swiperSlide *ngFor="let item of carousel">
    <div class="d-flex h-100">
      <img [src]="item.GifUrl" alt="carousel"/>
      <h6 class="mb-0 f-w-400"><span class="font-primary">{{ item.Message }}</span><span class="f-light"></span></h6>
      <a [href]="item.HyperlinkUrl" target="_blank" rel="noopener noreferrer">
        <i class="icon-arrow-top-right f-light"></i>
      </a>   
    </div>
  </ng-template>
</swiper>