import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-card-autopay',
  templateUrl: './credit-card-autopay.component.html',
  styleUrls: ['./credit-card-autopay.component.scss']
})
export class CreditCardAutopayComponent {

}
