<div class="container-fluid">
  <div class="row my-3">
    <div class="col-md-6">

    </div>
    <div class="col-md-6 text-end">
      <span class="my-2">
        Click here to view system configurations used in the screen 
      </span><br>
      <button type="button"  (click)="openAdd()" class="btn btn-primary">
        Download All Carrier Details</button>
        <button type="button" 
        (click)="open()"
        class="ms-2 btn btn-primary">
          Add Plan</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <label>Record Limit</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Plan Name</label><br>
      <input type="text" class="form-control ">
    </div>
    <div class="col-md">
      <label>State</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Carrier</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Plan Code</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Is Postpaid</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Is tribal</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Status</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md">
      <label>Display Status</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md-12 mt-3 d-flex align-items-end  ">
      <button type="button" 
      class="btn btn-primary">Search</button>

    </div>
  </div>

  <div class="custom-datatable table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th scope="col" sortable="name" (sort)="onSort($event)">Action</th>
                <th scope="col" sortable="startDate">Plan Name</th>
                <th scope="col" sortable="invoice" (sort)="onSort($event)">Plan Code</th>
                <th scope="col" sortable="invoice" (sort)="onSort($event)">Plan Type</th>

                <th scope="col" sortable="position" (sort)="onSort($event)">Carrier</th>
                <th scope="col" sortable="credit" (sort)="onSort($event)">Stats</th>
                <th scope="col" sortable="position" (sort)="onSort($event)">Is Tribal</th>
                <th scope="col" sortable="position" (sort)="onSort($event)">Plan Costs</th>

                <th scope="col" sortable="position" (sort)="onSort($event)">Plan Status</th>

                <th scope="col" sortable="position" (sort)="onSort($event)">voice</th>

                <th scope="col" sortable="position" (sort)="onSort($event)">Text</th>
                <th scope="col" sortable="position" (sort)="onSort($event)">Date</th>
                <th scope="col" sortable="position" (sort)="onSort($event)">Allow Topup</th>
              </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableItem$ | async">
                <td scope="row">
                    <ngb-highlight [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="item.startDate" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="item.invoice" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                  <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
              </td>
                <td>
                    <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                  <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td>
              <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
          </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-between mt-3">
        <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
            <option [ngValue]="2">2 items per page</option>
            <option [ngValue]="4">4 items per page</option>
            <option [ngValue]="6">6 items per page</option>
        </select>
        <ngb-pagination class="ms-3" [collectionSize]="(total$ | async)!" [(page)]="service.page"
            [pageSize]="service.pageSize"> <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
    </div>
  </div>
</div>