import { Component } from '@angular/core';

@Component({
  selector: 'app-wallet-renew',
  templateUrl: './wallet-renew.component.html',
  styleUrls: ['./wallet-renew.component.scss']
})
export class WalletRenewComponent {

}
