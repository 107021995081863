import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class PortRequestsService {
  userId: string;
  companyId: string;
  public portingOutRequestStatus$ = new Subject();
  public portingInRequestStatus$ = new Subject();
  public closePortInModal$ = new BehaviorSubject<boolean>(false);


  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) {

  }
  getUser() {
    return this.userDetails;
  }

  public geProfileData(customerID: any): Observable<any> {
    let url = "api/Customer/getMinimalProfileData";
    const params = {
      companyId: "UNITYCRM0021",
      userID: this.getUser()?.userID,
      CustomerId: customerID
    }

    return this.http.get(url,
      params,
      true
    )
  }

  // Porting Request Service Changes
  getPortingOutRequests(obj: any,) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      ...obj
    }
    let url = "api/Customer/GetAllPortOuts"
    return this.http.get(url, body)
  }

  // Porting Request Service Changes
  searchPortingOutRequests(obj: any, search: any) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      search: search,
      ...obj
    }
    let url = "api/Customer/GetAllPortOuts"
    return this.http.get(url, body)
  }

  // Porting Request Service Changes
  getPortinStatusByList(obj: any, status: any) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      status: status,
      ...obj
    }
    let url = "api/Customer/GetAllPortOutsStatuses"
    return this.http.get(url, body)
  }

  getPortingInRequests(obj: any) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      status: "",
      ...obj
    }
    let url = "api/Customer/GetPortIns"
    return this.http.get(url, body)
  }

  getPortingInRequestsBySearch(obj: any, search) {
    let body = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      search: search,
      ...obj
    }
    let url = "api/Customer/GetPortIns"
    return this.http.get(url, body)
  }

  deletePortingRequestById(data: any) {
    try {
      let url = `api/Customer/PortInDelete`;
      return this.http.delete(url, data, true)
    } catch (err) {
      throw Error(err)
    }
  }

  getPortingRequestStatusById(data: any) {
    let url = "api/Customer/GetPortInStatus"
    let data1 = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      iccid: data.iccid,
      CustomerId: data.CustomerID
    }
    return this.http.get(url, data1, true)
  }

  addPortingRequest(data) {
    let url = "api/Customer/PortInActivate"
    return this.http.post(url, data, true)
  }

  updatePortingRequest(data) {
    let url = "api/Customer/PortInUpdate"
    return this.http.post(url, data, true)
  }

  getPortInEligibility(mdnNumber: number) {
    let url = "api/Customer/GetPortInEligibility"
    let data1 = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      portInMdn: mdnNumber
    }
    return this.http.get(url, data1, true)
  }


  getStates(): Observable<any> {
    let url = "api/system/getStates"
    let data = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,

    }
    return this.http.get(url, data, true)
  }

  getCities(stateID: any): Observable<any> {
    let url = "api/system/getCities"
    let data = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      StateID: stateID
    }
    return this.http.get(url, data, true)
  }

  getPostalCodes(cityId: any): Observable<any> {
    let url = "api/system/getZipCodes"
    let data = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      CityID: cityId
    }
    return this.http.get(url, data, true)
  }

  getPortOutDetail(customerId: any): Observable<any> {
    let url = 'api/Customer/GetPortOutDetail';
    let data = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      CustomerId: customerId
    }
    return this.http.get(url, data, true)
  }

  getEsimNumber(customerId: any): Observable<any> {
    let url = 'api/Customer/AllocateEsim';
    let data = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      CustomerId: customerId
    }
    return this.http.get(url, data, true)
  }
  setPortingRequestStatus(requestStaus: any) {
    this.portingOutRequestStatus$.next(requestStaus)
  }

  getPortingRequestStatus(): Observable<any> {
    return this.portingOutRequestStatus$.asObservable();
  }

  setPortingInRequestStatus(requestStaus: any) {
    this.portingInRequestStatus$.next(requestStaus)
  }

  getPortingInRequestStatus(): Observable<any> {
    return this.portingInRequestStatus$.asObservable();
  }
  setClosePortInModal(requestStaus: any) {
    this.closePortInModal$.next(requestStaus)
  }

  get getClosePortInModal(): Observable<any> {
    return this.closePortInModal$.asObservable();
  }
}
