import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { CustomerDetailedInformationService } from './customer-detailed-information.service';

@Injectable({
  providedIn: 'root'
})
export class CardTrackingService {
  unregisteredCardsEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
  constructor(private customerdetailedInformationService:CustomerDetailedInformationService) { }

  trackingCardsAndExpandButton(cards: any[], el: ElementRef) {
    const cardsElements = el.nativeElement.querySelectorAll('.track-card');
    cards.forEach((cardInfo) => {
      const cardElement = Array.from(cardsElements).find((card: Element) => {
        const titleElement = (card as HTMLElement).querySelector('.card-title');
        return cardInfo.title === titleElement?.textContent;
      }) as HTMLElement | undefined; 
      
      const expandButton = cardElement?.querySelector('.track-expand');
      cardInfo.hasCard = !!cardElement;
      cardInfo.hasExpand = !!expandButton;
      this.customerdetailedInformationService.doCardregistration(
        cardInfo.title,
        cardInfo.componentName,
        cardInfo.hasCard,
        cardInfo.hasExpand,
        
      );
    });

    const registeredCards = this.customerdetailedInformationService.getregisteredCard();
    const unregisteredCards = cards.filter(cardInfo => 
      !registeredCards.some(registeredCard => registeredCard.title === cardInfo.title)
    );
    this.unregisteredCardsEvent.emit(unregisteredCards);
    this.hideUnregisteredExpandButtons(cards, registeredCards, el);
  }

  hideUnregisteredExpandButtons(cards: any[], registeredCards: any[], el: ElementRef) {
    const registeredCardTitles = registeredCards.map(card => card.title);
    cards.forEach(cardInfo => {
      if (!registeredCardTitles.includes(cardInfo.title)) {
        const cardElements = Array.from(el.nativeElement.querySelectorAll('.track-card')) as HTMLElement[];
  
        const cardElement = cardElements.find((card: HTMLElement) => {
          const titleElement = card.querySelector('.card-title') as HTMLElement;
          return titleElement?.textContent === cardInfo.title;
        });
  
        if (cardElement) {
          const expandButtons = cardElement.getElementsByTagName('app-feather-icons');
          for (let i = 0; i < expandButtons.length; i++) {
            const expandButton = expandButtons[i] as HTMLElement;
            expandButton.style.display = 'none'; 
          }
        }
      }
    });
  }

  
  
}
