import {  Component,  OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import {AuthService} from 'src/app/auth/services/auth.service';
import { HttpService } from "src/app/shared/services/httpService.service";

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  newtabsCondition:boolean = false;
  activeTab='change-mdn'
  customerData:any
  accountType:string |null=null;
  hideUpdateIMEIButton:boolean=false;
  accountTypeDisplay: string = '';
  companyId:string='UNITYCRM0021';
  constructor(public customerProfileService:CustomerProfileService,public commonService:CommonService,private authServices:AuthService, private httpservice:HttpService) {}
 

  ngOnInit(): void {
    this.getCustomerOtherInfo();
    
    
  }


  async getCustomerOtherInfo() {
    try {
      const res: any = await this.customerProfileService.getCustomerOtherInfo();
      if (res.Status) {
        this.customerData=res.data[0]
       
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.message)
    }
  }

  //account type checking
  async checkAccountType(){
    try{
      const requestData={
    companyId: this.companyId,
    userID:this.httpservice.getUser().userID ,
    CustomerId: this.httpservice.getCustomerId()
    }
    const response:any = await this.customerProfileService.getAccountType(requestData);


    if(response && response.Status && response.data){
        this.accountType=response.data;
        if(this.accountType==='SIM'){
          this.hideUpdateIMEIButton=true;
          this.accountTypeDisplay = ' SIM Only';
        }
    }
    }catch (err){
      console.error(err);
    }
  }

  //on tab click event
  async onUpdateIMEIButtonClicked(tabId:string){
    if(tabId==='v-pills-change-imei-tab'){
        await this.checkAccountType();
    }

}  

}