import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Store } from '@ngrx/store';
import { addNotification, startListeningNotifications } from 'src/app/store/notifications/notification.actions';
import { EchoService } from '../../services/echo.service';

@Component({
  selector: 'app-notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.scss']
})
export class NotificationBadgeComponent implements OnInit {
  unreadCount$: Observable<number>;
  isOpen = false;
  channel:any;
  constructor(private notificationService: NotificationService,private store :Store ,private injector :Injector) {}

  ngOnInit(): void {
    this.store.dispatch(startListeningNotifications());
   
    this.unreadCount$ = this.notificationService.getUnreadCount();
    this.subscribeToChannel()
  }

  toggleNotifications(): void {
    this.isOpen = !this.isOpen;
  }

  private subscribeToChannel(): void {
    console.log('notification');
    const echoService = this.injector.get(EchoService);
    this.channel = echoService.subscribe('new_notification');
    this.channel.listen('NotificationEvent', (data: any) => {
      console.log('Event data:', data);
      this.store.dispatch(addNotification({ notification: data }));
    });
  }

  ngOnDestroy(): void {
    if (this.channel) {
      const echoService = this.injector.get(EchoService);
      echoService.unsubscribe('new_notification');
    }

    
  }
}
