<div class="timeline">
    <div *ngFor="let event of events" class="timeline-event">
        <div class="event-icon">
            <i [class]="event.icon" ></i>
        </div>
        <div class="event-details">
            <p class="event-status mb-0">{{ event.status }}</p>
            <p class="event-timestamp mb-0">{{ event.timestamp }}</p>
        </div>
    </div>
</div>