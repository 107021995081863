<div class="card border-0 mt-0 mb-0 " *ngIf="fullScreen"
    style="border-radius: 16px;  border: 1px solid rgba(255, 255, 255, 0.2); backdrop-filter: blur(30px); background: transparent;">
    <div class="card-header p-3 pt-0" style="background: transparent; border-bottom: 0px;">
        <h4 class="card-title fs-6 mb-0 text-nowrap" class="text-white">Latest Activity</h4>
    </div>
    <div class="card-body pt-0">
        <div class="mx-n3">
            <div style="height: 793px; max-height: 793px;  overflow-y: auto; overflow-x: hidden;scrollbar-width: none;">
                <div class="timeline-2 bg-green " *ngFor="let note of allNotes">
                    <div class="timeline-continue">
                        <span *ngIf="note.group" class="text-center badge-container mb-3">
                            <span class="custom-badge text-white">{{ note.group }}</span>
                        </span>
                        <div class="row timeline-date bg-green" *ngIf="!note.group">
                            <div class="col-md-6">
                                <p class="mb-2"
                                    style="color: white; font-weight: 300; margin-left: 20px; font-size: 12px;">
                                    <ng-container *ngIf="note.type === 'ticketLog'; else otherTypes">
                                        {{note.createdByUser}} {{ note.activityDetail }}
                                    </ng-container>
                                    <ng-template #otherTypes>
                                        <ng-container *ngIf="note.type === 'note'">
                                            {{ note.Text }}
                                        </ng-container>
                                        <ng-container *ngIf="note.type === 'activity'">
                                            {{note.createdByUser?.value}} {{ note.activityDetail?.value }}
                                        </ng-container>
                                    </ng-template>
                                </p>
                            </div> 
                            <div class="col-md-6 text-end">
                                <p class="timeline-right  me-3 mb-0 "
                                    style="color: #bebebe; font-weight: 300;font-size: 12px;">{{
                                    note.CreatedDate | date: 'MMM d, y, h:mm a' }} </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>