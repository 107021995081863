import { Component, OnInit, Input } from '@angular/core';
import { CustomerProfileService } from '../../services/customer-profile.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-common-breadcrumb',
  templateUrl: './common-breadcrumb.component.html',
  styleUrls: ['./common-breadcrumb.component.scss']
})
export class CommonBreadcrumbComponent implements OnInit {

  @LocalStorage()
  private userDetails;

  title: string;
  items: any[]=[];
  active_item: string;

  toggleUserProfile: boolean = false;

  constructor(
    public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    public router:Router
  ) { 
    commonService.breadCrumb.subscribe(res=>{
      if(res.title) {
        this.title = res.title
      }
      if(res.items) {
        this.items = res?.items ?? []
      } 
      if(res.active_item) {
        this.active_item = res.active_item
      }
    })
  }

  ngOnInit() { }

  toggleCustomerProfile() {
    this.toggleUserProfile = !this.toggleUserProfile
    this.customerProfileService.setProfileQuicklink(this.toggleUserProfile)
    this.toggleUserProfile = !this.toggleUserProfile
  }

  routeDefault() {
    this.router.navigateByUrl(this.getUser()?.redirect_path ?? '/dashboard')
  }

  getUser() {
    return this.userDetails;
  }

}
