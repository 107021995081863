import { Component } from '@angular/core';

@Component({
  selector: 'app-dealer-wallet-add-data',
  templateUrl: './dealer-wallet-add-data.component.html',
  styleUrls: ['./dealer-wallet-add-data.component.scss']
})
export class DealerWalletAddDataComponent {

}
