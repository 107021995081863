/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { overrideConsoleLogs } from './app/shared/services/console-override.service';


if (environment.production) {
  enableProdMode();
}
else {

}
overrideConsoleLogs();


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
