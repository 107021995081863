<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="mb-3">
                <h5 class="card-title d-flex  " (click)="back()" style="cursor: pointer;">
                    <i class="fa-solid fa-arrow-left mt-1 mx-2" ></i>Agent List <span class="text-muted fw-normal ms-2">({{totalItems}})</span></h5>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
           
                <div class="d-flex">
                    <div class="col-md-6">
                        <input type="text" class="form-control" placeholder="Search" (keyup)="filterdData($event)">
                      </div>
                    <a routerLink="/update-agent" class="btn btn-primary text-nowrap" data-bs-toggle="modal" data-bs-target=".add-new">
                        <i class="bx bx-plus me-1"></i> Add New
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <tr>
                           
                            <th scope="col">Group Id</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Retailer Name</th>
                            <th scope="col">Distributor Name</th>
                            <th scope="col">Master Agent</th>
                            <th scope="col">Login Id</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Address</th>
                            <th scope="col">City</th>
                            <th scope="col">State</th>
                            <th scope="col">Zip</th>
                            <th scope="col">Status</th>
                            <th scope="col" style="width: 200px;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of agentList">
                            <td>{{ item.GroupID }}</td>
                            <td> <img src="https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=612x612&w=0&k=20&c=dhV2p1JwmloBTOaGAtaA3AW1KSnjsdMt7-U_3EZElZ0="
                                alt="" class="avatar-sm rounded-circle me-2" />{{ item?.user?.name }}</td>
                            <td>{{ item?.RetailerID }}</td>
                            <td>{{ item?.DistributorID }}</td>
                            <td>{{ item?.MasterAgentID }}</td>
                            <td>{{ item?.UserID }}</td>
                            <td>{{ item?.user.email }}</td>
                            <td>{{ item?.AddressLine1 }}</td>
                            <td>{{ item?.CityID }}</td>
                            <td>{{ item?.StateID }}</td>
                            <td>{{ item?.ZipID }}</td>
                            <td>{{ item?.user?.status_id }}</td>
                            <td>
                                <ul class="list-inline mb-0">
                                    <li class="list-inline-item">
                                        <a (click)="editRouter(item)" class="text-primary" title="Edit"><i class="fa-solid fa-pen-to-square"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a  class="text-danger" title="Delete"><i class="fa-solid fa-trash"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a (click)="viewProfile(item?.id)" class="text-success" title="View"><i class="fa-solid fa-eye"></i></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a (click)="navigateForPermission(item?.id)" class="text-warning" title="Permissions"><i class="fa-solid fa-user-lock"></i></a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end p-20">
                <select class="form-select col-1 me-2 page-dropdown" [(ngModel)]="itemsPerPage" (change)="onChangeNumberOfPage()">
                    <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                </select>
                <ngb-pagination  [directionLinks]="false" [boundaryLinks]="true" [collectionSize]="totalItems" [(page)]="currentPage" (pageChange)="pageChange($event)">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>
