<div class="container-fluid mainConatiner mt-3 p-0">

    <div class="card">
        <div class="card-header p-2">
            <div class="d-flex align-items-center justify-content-between " *ngIf="!showFilterRow && !isDraggable">
                <div class="d-flex gap-2 align-items-center overflow-auto mr-2">
                    <app-filter-btn *ngIf="rangeIds?.length>0" [optionList]="rangeIds" [title]="'Range'"
                        [label]="'Name'" [value]="'Code'" (selectionChanged)="handleDateChange($event)"
                        [preselectedItem]="rangeIds && rangeIds[0]"></app-filter-btn>

                    <div class=" d-flex align-items-end" *ngIf="selectedRange?.Name === 'Custom'">
                        <div class="date-picker" (click)="stop($event)">
                            <div class="date-picker hiringCal round-border d-flex" style="justify-content: center;">
                                <div class="f-row d-inline-block position-relative reports-datePicker">
                                    <button class="f-row f-a-center px-3 filter-btn round hover-light py-0 date-button">
                                        <i class="fa fa-calendar" (click)="drp.toggle()"
                                            [attr.aria-expanded]="drp.isOpen"></i>
                                        <input [(ngModel)]="bsRangeValue" (ngModelChange)="dateChanged()"
                                            [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                                            bsDaterangepicker
                                            class="pl-2 py-0 text border-0 bg-transparent cursor-pointer"
                                            placeholder="Daterangepicker" type="text" readonly
                                            #drp="bsDaterangepicker" />
                                        <i class="fa fa-chevron-down" (click)="drp.toggle()"
                                            [attr.aria-expanded]="drp.isOpen"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-2 align-items-center position-relative">
                        <button class="filter-btn d-flex gap-2 align-items-center" (click)="openAmoutDropdown()">
                            <span class="text"> Amount </span>
                            <div class="icon-wrapper">
                                <svg viewBox="1 1 18 18" focusable="false" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M6.24 8.2a.75.75 0 0 1 1.06.04l2.7 2.908 2.7-2.908a.75.75 0 1 1 1.1 1.02l-3.25 3.5a.75.75 0 0 1-1.1 0l-3.25-3.5a.75.75 0 0 1 .04-1.06Z">
                                    </path>
                                </svg>
                            </div>
                        </button>
                        <div class="dropdown-content " *ngIf="isAmountDropdownOpen">
                            <input type="number" class="form-control" [(ngModel)]="amount" (change)="onAmountChange()">
                        </div>
                    </div>

                    <app-filter-btn [optionList]="statusList" [title]="'Payment Status'" [label]="'Status'"
                        [value]="'StatusID'" (selectionChanged)="handleStatusChange($event)"></app-filter-btn>
                    <app-filter-btn [optionList]="paymentMethodList" [title]="'Payment method'" [label]="'title'"
                        [value]="'key'"></app-filter-btn>
                    <app-filter-btn [optionList]="filterList" [title]="'More filter'" [label]="'value'" [value]="'key'"
                        (selectionChanged)="handleMoreFilterChange($event)"></app-filter-btn>

                    <div class="d-flex gap-2 align-items-center position-relative" *ngFor="let item of selectedFilter">
                        <button class="filter-btn d-flex gap-2 align-items-center" (click)="openDropdown(item)">
                            <span class="text"> {{item.value}} </span>
                            <div class="icon-wrapper">
                                <svg viewBox="1 1 18 18" focusable="false" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M6.24 8.2a.75.75 0 0 1 1.06.04l2.7 2.908 2.7-2.908a.75.75 0 1 1 1.1 1.02l-3.25 3.5a.75.75 0 0 1-1.1 0l-3.25-3.5a.75.75 0 0 1 .04-1.06Z">
                                    </path>
                                </svg>
                            </div>
                        </button>
                        <div class="dropdown-content " *ngIf="item.isDropdownShow">
                            <input type="text" [placeholder]="'Search ' + item.value + '...'" class="form-control"
                                (change)="changeFilterValue($event,item)">
                        </div>
                    </div>
                    <button class="custom-btn active " (click)="clearAll()"> Clear All </button>
                </div>
                <div class="d-flex gap-2 align-items-center">
                    <app-filter-btn [icon]="'fa fa-search'" [isBtn]="true" (click)="onSearchClick()"></app-filter-btn>
                    <!-- <app-filter-btn [optionList]="[]" [icon]="'fa fa-sort-amount-asc'" [label]="'display'"
                        [value]="'key'"></app-filter-btn> -->
                    <span (click)="changeDraggable()">
                        <app-filter-btn [icon]="'fa fa-columns'" [isBtn]="true"></app-filter-btn>
                    </span>
                </div>
            </div>
            <div class="d-flex align-items-center gap-2 justify-content-end" *ngIf="isDraggable">
                <div class="d-flex justify-content-end gap-2 p-2">
                    <button class="custom-btn active" (click)="closeDragDrop()"> Cancel </button>
                    <button class="custom-btn  dark" (click)="saveColumnArrangement()"> Save </button>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between gap-2 custom-row"
                [ngClass]="{'r-0': showFilterRow}" *ngIf="showFilterRow">
                <div class="d-flex w-100 position-relative">
                    <input type="text" class="form-control ps-5" placeholder="Search..." (keyup)="searchChange($event)">
                    <i class="fas fa-search position-absolute"
                        style="left: 10px; top: 50%; transform: translateY(-50%);"></i>
                </div>
                <div class="d-flex align-items-center gap-2">
                    <button class="custom-btn active" (click)="closeFilter()"> Cancel </button>
                    <!-- <app-filter-btn [optionList]="sortOptions" [icon]="'fa fa-sort-amount-asc'" [label]="'label'"
                        [value]="'id'" (selectionChanged)="handleSelectionChange($event)"></app-filter-btn> -->
                </div>
            </div>
        </div>
        <div class="card-body p-0">

            <div class="table-responsive table-hover table-striped table-card"
                style="min-height: 500px; overflow-x: visible;">
                <table class="table table-nowrap mb-0">
                    <thead class="table-light">
                        <tr cdkDropList class="header-row" [cdkDropListDisabled]="!isDraggable"
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                            <th *ngFor="let header of headers; let i = index" cdkDrag
                                [ngClass]="{'draggable-column': isDraggable}" class="text-nowrap">
                                {{ header.display }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of rowDatas; let i = index" class="cursor-pointer">
                            <td *ngFor="let header of headers"
                                (click)="header.key !== 'Actions' ? goToDetails(item) : null">
                                <ng-container [ngSwitch]="header.key">


                                    <span *ngSwitchDefault>
                                        {{ item[header.key] || '-'}}
                                    </span>
                                    <div *ngSwitchCase="'Amount'"
                                        class="d-flex align-items-center gap-2 justify-content-between">
                                        <span class="text-black fw-semibold">{{ item.Amount }}</span>
                                        <span>USD</span>
                                        <span class="custom-badge d-flex align-items-center" [ngClass]="{
                                            'bg-success': item.PaymentStatus === 'RECEIVED',
                                            'bg-danger': item.PaymentStatus === 'FAILED',
                                            'bg-warning': item.PaymentStatus === 'Refunded',
                                            'bg-primary': item.PaymentStatus === 'Disputed',
                                            'bg-dark text-white': item.PaymentStatus === 'Declined'
                                          }">
                                            {{ item.PaymentStatus || 'FAILED'}}
                                            <i *ngIf="item.PaymentStatus === 'RECEIVED'" class="fa fa-check"></i>
                                            <i *ngIf="item.PaymentStatus === 'FAILED'" class="fa fa-times"></i>
                                            <i *ngIf="!item.PaymentStatus " class="fa fa-times"></i>
                                            <i *ngIf="item.PaymentStatus === 'Refunded'" class="fa fa-undo"></i>
                                            <i *ngIf="item.PaymentStatus === 'Disputed'" class="fa fa-exclamation"></i>
                                            <i *ngIf="item.PaymentStatus === 'Declined'" class="fa fa-ban"></i>
                                        </span>
                                    </div>

                                    <div *ngSwitchCase="'AccountNumber'" class="d-flex align-items-center gap-2">
                                        <img width="30" height="30" src="assets/svg/visa.png"
                                            *ngIf="item.AccountType === 'Visa'">
                                        <img width="30" height="30" src="assets/svg/card.png"
                                            *ngIf="item.AccountType === 'MasterCard'">
                                        <img width="30" height="30" src="assets/svg/symbol.png"
                                            *ngIf="item.AccountType === 'Klarna'">
                                        <img width="30" height="30" src="assets/svg/credit-card.png"
                                            *ngIf="!item.AccountType">
                                        <span>{{ item.AccountNumber }}</span>
                                    </div>

                                    <div *ngSwitchCase="'Actions'" class="position-relative">
                                        <i class="fa fa-ellipsis-h py-2 px-4" (click)="toggleDropdown(i)"></i>
                                        <div class="dropdown-content" *ngIf="dropdownVisible.has(i)">
                                            <div class="dropdown-item" (click)="onRefundClick(refundModal,item)">
                                                <span>Refund payment</span>
                                            </div>
                                            <div class="dropdown-item">
                                                <span>Send receipt</span>
                                            </div>
                                            <div class="dropdown-item" (click)="onCopyTransaction(item)">
                                                <span>Copy transaction Id</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
                    <app-skeleton-loader [tableRow]="8" [tableColumn]="8" shape="table"></app-skeleton-loader>
                </div>
            </div>

            <div class="d-flex justify-content-center p-3" *ngIf="rowDatas.length==0">
                <span class="text-danger">No data found</span>
            </div>


            <div class="pull-right  d-flex" *ngIf="totalOrders">
                <ngb-pagination class="pagination-block" [directionLinks]="true" [boundaryLinks]="true"
                    [collectionSize]="totalOrders" [(page)]="currentPage" (pageChange)="onPaginationChanged($event)"
                    [maxSize]="3" [pageSize]="itemsPerPage" [ellipses]="true">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div>


    <ng-template #refundModal let-modal>
        <div class="modal-header modal-copy-header p-2 bg-light text-dark">
            <h5 class="headerTitle mb-0">Refund Payment</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <!-- Refund Amount Field -->
            <div class="mb-3">
                <label for="refundAmount" class="form-label">Refund Amount</label>
                <input type="number" id="refundAmount" class="form-control" [(ngModel)]="refundAmount"
                    placeholder="Enter amount" />
            </div>

            <!-- Refund Reason Field -->
            <div class="mb-3">
                <label for="refundReason" class="form-label">Refund Reason</label>
                <select id="refundReason" class="form-select" [(ngModel)]="refundReason">
                    <option [value]="item.id" *ngFor="let item of refundReasonList">{{item.Description}}</option>
                </select>
            </div>
            <div class="d-flex justify-content-end gap-3 mt-2">
                <button class="btn btn-danger btn-sm" (click)="modal.dismiss('Cross click')">Cancel</button>
                <button class="btn btn-info btn-sm" (click)="confirmRefund()">Refund</button>
            </div>
        </div>
    </ng-template>