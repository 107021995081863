import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-address-info',
  templateUrl: './skeleton-address-info.component.html',
  styleUrls: ['./skeleton-address-info.component.scss']
})
export class SkeletonAddressInfoComponent {

}
