import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-navigation-skeleton',
  templateUrl: './sidebar-navigation-skeleton.component.html',
  styleUrls: ['./sidebar-navigation-skeleton.component.scss']
})
export class SidebarNavigationSkeletonComponent {

  iconArray: number[] = Array(10).fill(0); // Array to simulate 10 icons

  constructor() { }
}
