
<div class="container mt-4 " [ngClass]="{'transparent-container': accountType === 'SIM'}" style="width: 80%;" *ngIf="!isSkeletonLoader" >
  <ng-container *ngIf="accountType!='SIM'"; else notSimAccountType>
   <div class="card p-3">
    <form >
      <div class="row form-group">
        <div class="col-md-3">
          <label>IMEI</label>
        </div>
        <div class="col-md-9">
          <input type="text" [formControl]="imeiControl" class="form-control">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label>Select Make & Model</label>
        </div>
        <div class="col-md-9">
          <select [formControl]="deviceCombo" class="form-control">
            <option value="">Select Device Combo</option>
            <option [value]="item?.comboId" *ngFor="let item of deviceCombos">{{item.comboTitle}}</option>
        </select>
    
    
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label>Update NLAD</label>
        </div>
        <div class="col-md-9">
          <input type="checkbox"  [formControl]="updateNlad">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12 text-end">
          <button type="button" class="btn btn-primary" (click)="updateImei()" >Submit</button>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12 text-end">
          <button type="button" class="btn btn-danger" (click)="removeImei()" >Remove IMEI</button>
        </div>
      </div>
    </form>
   </div>
</ng-container>





<ng-container *ngIf="accountType === 'SIM'">
  <ng-container *ngTemplateOutlet="notSimAccountType"></ng-container>
</ng-container>

<ng-template #notSimAccountType>
  <div class="row form-group" style="position: relative; top: 40px;">
    
      <div  style="font-family: sans-serif;font-size: 15px;display: inline-block; vertical-align: middle; width:10em">
        Account Type: <span class="acc-type">  {{ accountType }}</span>
      </div>
    
  </div>
</ng-template>
</div>


<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
  <app-skeleton-loader  shape="payment-form"></app-skeleton-loader>
</div>