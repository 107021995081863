import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TicketSystemService } from "../services/ticket-system.service";
import { HttpService } from "src/app/shared/services/httpService.service";
import { TicketSidebarComponent } from "../add-ticket-sidebar/add-ticket-sidebar.component";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/shared/services/common-filter.service";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-ticket-kanban-board",
  templateUrl: "./ticket-kanban-board.component.html",
  styleUrls: ["./ticket-kanban-board.component.scss"],
  animations: [fadeInOut],
})
export class TicketKanbanBoardComponent implements OnInit, OnDestroy {
  @ViewChild(TicketSidebarComponent) sidebarComponent!: TicketSidebarComponent;
  boardData = {
    title: "My Kanban Board",
    columns: [],
  };

  isSidebarOpen = false;
  selectedCard: any = {};
  canDrag: boolean = false;
  isEditing: boolean = false;
  currentPage: any = 1;
  currentCompany: any = localStorage.getItem("currentCompany");
  private filterSubscription: Subscription;
  startDate: any;
  endDate: any;
  searchQuery: any;
  statusCode: any[];
  isSkeletonLoader: boolean = true;
  CustomerId: any;
  queryParamsSubscription: Subscription;

  constructor(
    public ticketSystemService: TicketSystemService,
    public http: HttpService,
    private cdr: ChangeDetectorRef,
    private filterService: FilterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isGlobalLoader();
    this.filterSubscription = this.filterService.filters$.subscribe(
      (filters) => {
        // Check if dateRange exists and has two dates, then format them
        if (filters.dateRange?.length == 2) {
          this.startDate = filters.dateRange[0].toISOString().split("T")[0];
          this.endDate = filters.dateRange[1].toISOString().split("T")[0];
        } else {
          this.startDate = null;
          this.endDate = null;
        }

        this.searchQuery = filters?.searchText || "";

        if (filters.ticket) {
          this.statusCode = [filters.ticket];
        }

        if (filters.CustomerId) {
          this.CustomerId = filters.CustomerId;
        }

        this.getAllTickets();
      }
    );
    this.getAllTickets();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    this.queryParamsSubscription.unsubscribe();
  }

  isGlobalLoader() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        if (params["isGlobalLoader"] === "true" && params["ticketId"]) {
          // Simulate fetching ticket data using the ticketId
          const ticketData = { id: params["ticketId"] }; // Replace with actual ticket data retrieval if needed
          this.selectedCard = { id: params["ticketId"] }; // Replace with actual ticket data retrieval if needed
          this.isEditing = true;
          this.isSidebarOpen = true;
          this.onCardClick(ticketData); // Call onCardClick with the ticket data
        }
      }
    );
  }
  async onCardMoved(event) {
    const previousColumnId = event.previousContainer.data.id; // Previous column ID
    const currentColumnId = event.container.data.id; // Current (new) column ID
    const selectedCard = event.item.data; // The moved card (ticket)

    if (previousColumnId !== currentColumnId) {
      try {
        // Call API to update the status of the moved card
        const updateData = {
          StatusID: currentColumnId,
          UpdatedBy: this.http.getUser()?.userID,
          AssignmentType: selectedCard.AssignmentType
            ? selectedCard.AssignmentType
            : "AGENT",
        };

        const response: any = await this.ticketSystemService.updateTicket(
          selectedCard.id,
          updateData
        );

        if (response.Status) {
          console.log(
            "Ticket status updated successfully",
            selectedCard.AssignmentType
          );
          this.getAllTickets();
        } else {
          console.error("Failed to update ticket status:", response.Error);
        }
      } catch (error) {
        console.error("Error while updating ticket status:", error);
      }
    }
  }

  onColumnMoved(event) {
    console.log("Column moved:", event);
  }

  onReachedEnd(event: any) {
    const columnIndex = this.boardData.columns.findIndex(
      (val) => val.id === event.data.id
    );

    if (columnIndex !== -1) {
      const columnData = { ...this.boardData.columns[columnIndex] };
      console.log("object");
      if (columnData.current_page < columnData.total_pages) {
        columnData.current_page++;
        this.boardData = {
          ...this.boardData,
          columns: [
            ...this.boardData.columns.slice(0, columnIndex),
            columnData,
            ...this.boardData.columns.slice(columnIndex + 1),
          ],
        };
        this.getTickets(columnData.id, columnData.current_page);
      }
    }
  }

  onCardClick(card: any) {
    console.log("cardclick");
    this.isSidebarOpen = false;
    this.cdr.detectChanges();
    this.selectedCard = card;
    this.isEditing = true;
    this.isSidebarOpen = true;
    this.cdr.detectChanges();
  }

  closeSidebar() {
    this.isSidebarOpen = false; // Close sidebar
  }

  async getAllTickets() {
    try {
      let data = {
        searchTerm: this.searchQuery,
        fromDate: this.startDate,
        toDate: this.endDate,
        CustomerId: this.CustomerId,
      };
      const res: any =
        await this.ticketSystemService.getAllTicketsForKanban(data);
      if (res.Status) {
        this.boardData.columns = res.data.filter(
          (column: any) =>
            column.title !== "Resolved" && column.title !== "On Hold"
        );
        console.log("Filtered column data", this.boardData.columns);
        this.isSkeletonLoader = false;
      } else {
        this.isSkeletonLoader = false;
      }
    } catch (err) {
      this.isSkeletonLoader = false;
    }
  }

  async getTickets(statusCode: number, currentPage: number) {
    try {
      const data = {
        per_page: 10,
        "StatusID[]": statusCode,
        page: currentPage,
      };
      const res: any = await this.ticketSystemService.getAllTickets(data);

      if (res.Status) {
        const columnIndex = this.boardData.columns.findIndex(
          (val) => val.id === statusCode
        );

        if (columnIndex !== -1) {
          const columnData = this.boardData.columns[columnIndex];
          console.log("culumn data", columnData);
          columnData.cards = [...columnData.cards, ...res?.data?.data];
          console.log(columnData);

          columnData.current_page = res.Data.current_page;
          columnData.total_pages = res.Data.total_pages;
        }
      } else {
        console.error("Error fetching tickets:", res.Error);
      }
    } catch (err) {
      console.error("API error:", err);
    }
  }

  addTicket(column) {
    this.isEditing = false;
    this.isSidebarOpen = false;
    this.cdr.detectChanges();
    this.isSidebarOpen = true;
    this.selectedCard = null;
  }

  isPastDueDate(dueDate: Date | string): boolean {
    const date = new Date(dueDate);
    const currentDate = new Date();

    return date < currentDate;
  }
  getInitials(name: string): string {
    if (name) {
      const initials = name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
      return initials.length > 1 ? initials.substring(0, 2) : initials;
    }
    return "";
  }
  getColor(name: string): string {
    if (name) {
      const colors = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#F3FF33",
        "#FF33A2",
        "#33FFF3",
      ];
      const hash = this.hashString(name);
      const colorIndex = hash % colors.length;
      return colors[colorIndex];
    }
    return "";
  }
  hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash);
  }
}
