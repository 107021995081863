<div class="container-fluid">
    <div class="row">
      <!-- Open Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- In Progress Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- Resolved Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- Closed Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- On Hold Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- Escalated Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- My Tickets Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
  
      <!-- Total Tickets Section -->
      <div class="col">
        <div class="skeleton-section mb-4">
          <div class="skeleton-title w-75 mb-3"></div>
          <div *ngFor="let card of cardArray" class="skeleton-card mb-2" style="height: 50px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
  