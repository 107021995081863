<!-- <div class="row my-3">
    <div class="col-12">
        <ngb-accordion #accT="ngbAccordion">
            <ngb-panel id="toggle-1" title="Search Filter">
                <ng-template ngbPanelContent>
                    <div class="d-flex">
                        <div class="input-control">
                            <label>From</label>
                            <input type="date" [(ngModel)]="startDate" placeholder="From" class="form-control" />
                        </div>
                        <div class="input-control ms-2">
                            <label>To</label>
                            <input type="date" [(ngModel)]="endDate" placeholder="To" class="form-control" />
                        </div>
                        <div class="input-control ms-2 d-flex align-items-end">
                            <app-feather-icons (click)="clearDateFilter()" [icon]="'x-circle'"
                                class="cursor-pointer"></app-feather-icons>
                            <app-feather-icons (click)="getCardData()" [icon]="'search'"
                                class="ms-2 cursor-pointer"></app-feather-icons>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div> -->



<div class="row">
    <div class="col-sm-3 col-6" *ngFor="let item of cards">
        <div class="card widget-hover" (click)="navigateToOrdersByStatus(item)">
            <div class="card-body radial-progress-card" [ngClass]="{'selectedCard': selectedCard == item?.Name}">
                <div>
                    <h6 class="mb-0" [ngStyle]="{color: getStatusCardColor(item)}">{{item.DisplayName}}</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-info custom-dash-card-icon"
                                [icon]="item?.NavIconClass ?  item.NavIconClass : 'default-icon'"></app-feather-icons>
                            {{getCount(item)}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">Opened Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-success custom-dash-card-icon"
                                icon="check-circle"></app-feather-icons>
                            20
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">Pending Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-danger custom-dash-card-icon"
                                icon="thumbs-down"></app-feather-icons>
                            0
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">Escalation Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-success custom-dash-card-icon"
                                icon="award"></app-feather-icons>
                            30
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">Callback Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-info custom-dash-card-icon"
                                icon="clock"></app-feather-icons>
                            0
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">In Progress Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-success custom-dash-card-icon"
                                icon="check-circle"></app-feather-icons>
                            5
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">Closed Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-danger custom-dash-card-icon"
                                icon="thumbs-down"></app-feather-icons>
                            100
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="card widget-hover">
            <div class="card-body radial-progress-card">
                <div>
                    <h6 class="mb-0 font-primary">Total Tickets</h6>
                    <div class="sale-details">
                        <span class="f-24 f-light f-w-500">
                            <app-feather-icons class="font-success custom-dash-card-icon"
                                icon="award"></app-feather-icons>
                            400
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>