import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { ManageAgentService } from '../../services/manage-agent.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-resource-type-modal',
  templateUrl: './resource-type-modal.component.html',
  styleUrls: ['./resource-type-modal.component.scss']
})
export class ResourceTypeModalComponent {
  @Output() close = new EventEmitter<any>();
  hepldata: any = []
  searchText = '';
  columnDefs: any[] = []
  resourcesTypeList: any
  resourcesTypeForm: FormGroup
  resourcesGategoryList: any
  // noData:boolean = false
  showCateGory_types: boolean = false
  showupdate: boolean = false
  showExtraInputs: boolean = false
  updateID: any
  badgeColorMap: { [key: string]: string } = {};
  colorPalette: string[] = ['#FF5733', '#33B5FF', '#33FF57', '#FF33A6', '#FFA533', '#8E44AD',  // New colors
    '#2ECC71',
    '#E74C3C'];
  colorIndex = 0;
  @ViewChild('legend') legend: ElementRef;
  constructor(private manageAgentService: ManageAgentService, private fb: FormBuilder, private commonService: CommonService, private renderer: Renderer2,) {

  }
  closeModal1(): void {
    this.close.emit();
  }
  openCateGoryModal() {
    this.showCateGory_types = true;

  }


  closeModal3() {
    this.showCateGory_types = false; // Hides the modal
  }
  ngOnInit() {
    this.getResourcesListType()
    this.getCategory()

    this.columnDefs = [
      { headerName: 'Resource Type Name', field: 'DisplayName', cellRenderer: this.boldRenderer },
      { headerName: ' ', field: ' ', },
      { headerName: 'Description', field: 'Description', cellRenderer: this.defaultRenderer },
      { headerName: ' ', field: ' ', },
      { headerName: 'Category', field: 'resource_category.DisplayName', cellRenderer: this.badgeRenderer.bind(this) },
      { headerName: 'Action', cellRenderer: this.actionRenderer.bind(this), }
    ];
    this.resourcesTypeForm = this.fb.group({
      resourceName: ['', Validators.required],
      resourceDescription: ['', Validators.required],
      displayName: ['', Validators.required],
      resourceCategoryID: [null, Validators.required],
      status: [null, Validators.required],
    });
  }
  ngAfterViewInit() {
    if (!this.legend) {
      console.error('Legend ViewChild is not initialized.');
    } else {
      console.log('Legend ViewChild initialized:', this.legend);
      this.generateLegend();
    }
  }
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true
  };
  onCellClicked(event: any) {
    // data-des-name="${params.data.Description}" data-ResId-name="${params.data.ResourceCategoryID}" data-isAct-name="${params.data.ResourceCategoryID}"
    const target = event.event.target.closest('.icon');
    if (target) {
      const action = target.getAttribute('data-action');
      const id = target.getAttribute('data-id');
      const DisplayName = target.getAttribute('data-display-name');
      const Name = target.getAttribute('data-name');
      const Description = target.getAttribute('data-des-name');
      const ResourceCategoryID = target.getAttribute('data-ResId-name');
      const IsActive = target.getAttribute('data-isAct-name');
      if (action === 'edit') {
        this.ViewOneRes(id);
      } else if (action === 'delete') {
        // Use the id for deletion
        this.deleteResouceType(id);
      }
    }
  }

  // Custom renderers
  boldRenderer(params: any) {
    return params.value ? `<strong>${params.value}</strong>` : '-';
  }

  defaultRenderer(params: any) {
    return params.value ? params.value : '-';
  }

  getBadgeColor(resourceTypeName: any): string {
    if (!this.badgeColorMap[resourceTypeName]) {
      // Assign a unique color if it's not already mapped
      if (this.colorIndex >= this.colorPalette.length) {
        // Reset the index if we run out of colors, or handle this case as needed
        this.colorIndex = 0;
      }
      this.badgeColorMap[resourceTypeName] = this.colorPalette[this.colorIndex];
      this.colorIndex++;
    }
    return this.badgeColorMap[resourceTypeName];
  }
  generateLegend() {
    const legendContainer = this.legend.nativeElement;
    if (legendContainer) {
      console.log('Legend container found:', legendContainer);

      // Clear existing legend items
      this.renderer.setProperty(legendContainer, 'innerHTML', '');

      this.resourcesGategoryList.forEach(item => {
        const resourceTypeName = item?.DisplayName;
        const color = this.getBadgeColor(resourceTypeName);
        console.log(`Resource: ${resourceTypeName}, Color: ${color}`);

        const legendItem = this.renderer.createElement('div');
        this.renderer.addClass(legendItem, 'legend-item');
        this.renderer.addClass(legendItem, 'me-3');
        this.renderer.addClass(legendItem, 'mb-2');
        const span = this.renderer.createElement('span');
        this.renderer.addClass(span, 'badge');
        this.renderer.setStyle(span, 'background-color', color);
        this.renderer.setStyle(span, 'color', 'white');
        const text = this.renderer.createText(resourceTypeName);
        this.renderer.appendChild(span, text);
        this.renderer.appendChild(legendItem, span);
        this.renderer.appendChild(legendContainer, legendItem);
      });

      console.log('Final legend container content:', legendContainer.innerHTML);
    } else {
      console.log('Legend container not found');
    }
  }


  badgeRenderer(params: any): string {
    const resourceTypeName = params.data?.resource_category.DisplayName || '-';
    const badgeColor = this.getBadgeColor(resourceTypeName);

    return `<span class="badge" style="background-color: ${badgeColor}; color: white;">${resourceTypeName}</span>`;
  }

  actionRenderer(params: any) {
    const container = document.createElement('div');
    container.innerHTML = `
      <span class="icon me-2 cursor-pointer" data-action="edit" data-id="${params.data.id}" 
            data-display-name="${params.data.DisplayName}" data-name="${params.data.Name}" 
            data-des-name="${params.data.Description}" data-ResId-name="${params.data.ResourceCategoryID}" 
            data-isAct-name="${params.data.IsActive}">
        <i class="fas fa-edit text-success"></i>
      </span>
      <span class="icon cursor-pointer " data-action="delete" data-id="${params.data.id}">
        <i class="fas fa-trash text-danger"></i>
      </span>
    `;
    const editIcon = container.querySelector('[data-action="edit"]');

    if (editIcon) {
      editIcon.addEventListener('click', () => {
        const mainContainer = document.getElementById('main');
        if (mainContainer) {
          mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          console.error('Main container not found.');
        }
      });
    }

    return container;
  }





  getResourcesListType() {
    this.manageAgentService.getAllResourcesType().subscribe((res: any) => {
      this.resourcesTypeList = res?.data;
    })

  }
  getCategory() {
    this.manageAgentService.getAllCategory().subscribe((res: any) => {

      this.resourcesGategoryList = res?.data;


    })

  }
  submitForm() {
    if (this.resourcesTypeForm.valid) {
      const payload = {
        Name: this.resourcesTypeForm.get('resourceName').value,
        Description: this.resourcesTypeForm.get('resourceDescription').value,
        DisplayName: this.resourcesTypeForm.get('displayName').value,
        ResourceCategoryID: this.resourcesTypeForm.get('resourceCategoryID').value,
        isActive: this.resourcesTypeForm.get('status').value,

      };
      this.manageAgentService.createNewResType(payload).subscribe({
        next: (res: any) => {
          if (res?.Status === true) {
            this.commonService.showToast("success", "Success", "Resources Type Created successfully!");
            this.getResourcesListType();
          } else {
            const errorMessage = res?.error.error?.Name[0] ?? "Cancellation failed.";
            this.commonService.showToast('error', "Error", errorMessage);
          }
        },
        error: (err: any) => {
          console.log(err, 'Error Occurred');
          const errorMessage = err?.error?.error.Name[0] ?? "An unexpected error occurred.";
          this.commonService.showToast('error', "Error", errorMessage);

        },
        complete: () => {
          console.log('Request complete');
        }
      });


    } else {
      this.commonService.showToast("error", "Error", "Please fill all required feilds");
    }
  }

  onSelectCategory1(event: any) {
    if (event == -1) {
      this.openCateGoryModal();
      // this.closeModal1()
      // this.resourcesTypeForm.get('categorySelect').setValue(null); // Clear the selection if necessary
    }

  }
  onStatusChange(status: number): void {
    console.log('Status changed to:', status);
  }

  ViewOneRes(id: any) {
    this.updateID = id
    this.manageAgentService.getAllResourceType().subscribe(
      (res: any) => {
        if (res?.Status) {
          const resourceData = res.data.find((item: any) => item.id == id);
          if (resourceData) {
            this.resourcesTypeForm.patchValue({
              resourceName: resourceData.Name ?? null,
              displayName: resourceData.DisplayName ?? null,
              resourceDescription: resourceData.Description ?? '',
              resourceCategoryID: resourceData.resource_category?.id ?? null,
              status: resourceData.IsActive ?? null,
            });
            this.showupdate = true;
            const collapseElement = document.getElementById('collapseExample');
            if (collapseElement) {
              this.renderer.addClass(collapseElement, 'show');
            }

            // Show success toast (optional)
            // this.commonService.showToast("success", "Success", "Data loaded successfully!");
          } else {
            // Show an error toast if the resource is not found
            this.commonService.showToast('error', 'Error', 'Resource not found.');
          }
        } else {
          // Show an error toast if the response status is invalid
          this.commonService.showToast('error', 'Error', res?.error?.message ?? 'Failed to load data.');
        }

        // Stop the loader
        this.commonService.stopLoader();
      },
      (err) => {
        // Handle errors and stop the loader
        this.commonService.showToast('error', 'Error', err?.error?.message ?? 'Some Error Occurred');
        this.commonService.stopLoader();
      }
    );
  }

  update() {
    if (this.resourcesTypeForm.valid) {
      let formData = {
        Name: this.resourcesTypeForm.get('resourceName').value,
        Description: this.resourcesTypeForm.get('resourceDescription').value,
        DisplayName: this.resourcesTypeForm.get('displayName').value,
        ResourceCategoryID: this.resourcesTypeForm.get('resourceCategoryID').value,
        isActive: this.resourcesTypeForm.get('status').value,
      }

      this.manageAgentService.updateResoureType(this.updateID, formData).subscribe(res => {
        this.getCategory()
        this.commonService.showToast("success", "Success", "Updated successfully!")

      }, err => {

        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        this.commonService.stopLoader()
      })
    }

  }

  async deleteResouceType(id: any) {
    try {
      let res = await this.manageAgentService.deleteResourceType(id) as any;
      if (res?.Status === true) {
        const successMessage = res?.data ?? "Resource Type deleted successfully!";
        this.commonService.showToast("success", "Success", successMessage);
        this.getResourcesListType();
      } else {
        const errorMessage = res?.error?.Name[0] ?? "Deletion failed.";
        this.commonService.showToast('error', "Error", errorMessage);
      }
    } catch (err) {
      const errorMessage = err?.error?.message ?? "Some error occurred";
      this.commonService.showToast('error', "Error", errorMessage);
    }
  }
  resetForm() {
    this.resourcesTypeForm.reset();
  }
}
