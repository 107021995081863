import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class PurchaseHistoryService {

  api: string = environment.BASE_API;

  constructor(
    private http: HttpService
  ) { }

  getPlanHistory(data) {
    try {
      return this.http.get('api/Customer/GetPlanHistory',data,false,true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

}
