<div class="d-flex align-items-start quick-link">
    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <button class="nav-link" [ngClass]="{active: selectedTab == 'Change-Address'}"
            (click)="onSelectTab('Change-Address')" id="v-pills-change-address-account-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-change-address-account" type="button" role="tab"
            aria-controls="v-pills-change-address-account" aria-selected="false">
            <h5>Change Address</h5>
        </button>
        <button class="nav-link" [ngClass]="{active: selectedTab == 'Change-Billing-Address'}"
        (click)="onSelectTab('Change-Billing-Address')" id="v-change-billing-address-account-tab" data-bs-toggle="pill"
        data-bs-target="#v-change-billing-address-account" type="button" role="tab"
        aria-controls="v-change-billing-address-account" aria-selected="false">
        <h5>Change Billing Address </h5>
    </button>

        <!-- <button class="nav-link" [ngClass]="{active: selectedTab == 'Edit Device Info'}"
            (click)="onSelectTab('Edit Device Info')" id="v-pills-edit-device-account-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-edit-device-account" type="button" role="tab"
            aria-controls="v-pills-edit-device-account" aria-selected="false">
            <h5>Edit Device Info</h5>
        </button> -->
        <!-- <button class="nav-link" [ngClass]="{active: selectedTab == 'Features-Service'}"
            (click)="onSelectTab('Features-Service')" id="v-pills-features-services-account-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-features-services-account" type="button" role="tab"
            aria-controls="v-pills-features-services-account" aria-selected="false">
            <h5>Features & Service</h5>
        </button> -->
        <!-- <button class="nav-link" [ngClass]="{active: selectedTab == 'Customer Information'}"
            (click)="onSelectTab('Customer Information')" id="v-pills-customer-info-account-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-customer-info-account" type="button" role="tab"
            aria-controls="v-pills-customer-info-account" aria-selected="false">
            <h5>Customer Information</h5>
        </button>
        <button class="nav-link" [ngClass]="{active: selectedTab == 'Other Information'}"
            (click)="onSelectTab('Other Information')" id="v-pills-other-info-account-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-other-info-account" type="button" role="tab"
            aria-controls="v-pills-other-info-account" aria-selected="false">
            <h5>Other Information</h5>
        </button>
        <button class="nav-link" [ngClass]="{active: selectedTab == 'Tag'}" (click)="onSelectTab('Tag')"
            id="v-pills-tag-account-tab" data-bs-toggle="pill" data-bs-target="#v-pills-tag-account" type="button"
            role="tab" aria-controls="v-pills-tag-account" aria-selected="false">
            <h5>Tag</h5>
        </button> -->

    </div>
    <div class="tab-content" id="v-pills-tabContent">
        <div class="tab-pane fade" *ngIf="selectedTab == 'Change-Address'" [ngClass]="{'show active': selectedTab == 'Change-Address'}"
            id="v-pills-change-address-account" role="tabpanel" aria-labelledby="v-pills-change-address-account-tab">
            <app-change-address-account></app-change-address-account>
            <br><br><br><br><br><br><br>
        </div>

        <div class="tab-pane fade" *ngIf="selectedTab == 'Change-Billing-Address'"
            [ngClass]="{'show active': selectedTab == 'Change-Billing-Address'}" id="v-change-billing-address-account" role="tabpanel"
            aria-labelledby="v-change-billing-address-account-tab">
            <app-change-billing-address></app-change-billing-address>
            <br><br><br><br><br><br><br>
        </div>
        <div class="tab-pane fade" *ngIf="selectedTab == 'Edit Device Info'" [ngClass]="{'show active': selectedTab == 'Edit Device Info'}"
            id="v-pills-edit-device-account" role="tabpanel" aria-labelledby="v-pills-edit-device-account-tab">
            <app-edit-device-info-account></app-edit-device-info-account>
            <br><br><br><br><br><br><br>
        </div>

        <div class="tab-pane fade" *ngIf="selectedTab == 'Features-Service'" [ngClass]="{'show active': selectedTab == 'Features-Service'}"
            id="v-pills-features-services-account" role="tabpanel"
            aria-labelledby="v-pills-features-services-account-tab">
            <app-features></app-features>
            <br><br><br><br><br><br><br>
        </div>

        <div class="tab-pane fade" *ngIf="selectedTab == 'Customer Information'" [ngClass]="{'show active': selectedTab == 'Customer Information'}"
            id="v-pills-customer-info-account" role="tabpanel" aria-labelledby="v-pills-customer-info-account-tab">
            <app-customer-info-account></app-customer-info-account>
            <br><br><br><br><br><br><br>
        </div>
        <div class="tab-pane fade" *ngIf="selectedTab == 'Other Information'" [ngClass]="{'show active': selectedTab == 'Other Information'}"
            id="v-pills-other-info-account" role="tabpanel" aria-labelledby="v-pills-other-info-account-tab">
            <app-other-info-account></app-other-info-account>
            <br><br><br><br><br><br><br>
        </div>
        <div class="tab-pane fade" *ngIf="selectedTab == 'Tag'" [ngClass]="{'show active': selectedTab == 'Tag'}" id="v-pills-tag-account"
            role="tabpanel" aria-labelledby="v-pills-tag-account-tab">
            <app-tag-account></app-tag-account>
            <br><br><br><br><br><br><br>
        </div>
    </div>
</div>