import { Component } from '@angular/core';

@Component({
  selector: 'app-money-gram-add-data',
  templateUrl: './money-gram-add-data.component.html',
  styleUrls: ['./money-gram-add-data.component.scss']
})
export class MoneyGramAddDataComponent {

}
