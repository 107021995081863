<div class="container-fluid card p-0">
    <div class="row m-2">
      <!-- Loop through each column -->
      <div *ngFor="let column of columnsArray" class="col">
        <!-- Loop through each row in the column -->
        <div *ngFor="let row of column" class="d-flex align-items-center justify-content-between m-3 p-0">
          <!-- Label skeleton -->
          <div class="skeleton-label w-25 me-3 bg-light"></div>
          <!-- Data skeleton -->
          <div class="skeleton-data w-50 bg-light"></div>
        </div>
      </div>
    </div>
  </div>
  