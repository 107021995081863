<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 box-col-12">
            <div class="file-content">
                <div class="card">
                    <div class="files">
                        <h6 class="mt-4" style="margin-left: 1rem;">Files</h6>
                        <button class="btn btn-secondary" (click)="downloadEnrollmentPdf()">Download Enrollment
                            Form</button>
                    </div>
                    <ul class="folder" style="margin: 1rem;">
                        <li class="folder-box" style="margin: 0rem 0.7rem 0.7rem 0rem !important;"
                            *ngFor="let file of filesList">
                            <div class="media">
                                <div class="file-top file-box"><i class="fa fa-file-pdf-o txt-icon "></i></div>
                                <div class="media-body ms-3">
                                    <p style="display: flex;justify-content: center;  font-size: 14px">Date :
                                        {{file.ConsentDate| date : 'mediumDate' }}</p>
                                    <div style="display: flex;justify-content: center;">
                                        <button class="btn btn-sm btn-outline-success" type="button"
                                            (click)="generateWorkSheet(file)" style="padding: 0.375rem 0.75rem">
                                            Download Worksheet</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>