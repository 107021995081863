<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row">
      <div class="col-md-12">
        <h5>
          CSR Screen For Family Plan
        </h5>
      </div>
    </div>
  <div class="row form-group">
<div class="col-md-3">
    <label>Phone Number</label></div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
   </div>
<div class="row form-group">
  <div class="col-md-12">
    <span class="text-danger">Family Plan not available on customer account.</span>
  </div>
</div>
   <div class="row">
    <div class="col-md-12 text-end">
      <button type="button" class="btn btn-primary">Submit</button>
    </div>
   </div>


    </form>
    </div>