import { Component } from '@angular/core';

@Component({
  selector: 'app-upgrade-family-plan',
  templateUrl: './upgrade-family-plan.component.html',
  styleUrls: ['./upgrade-family-plan.component.scss']
})
export class UpgradeFamilyPlanComponent {

}
