<div class="container mt-0" style="max-width: 100%;">
  <div class="card form-card shadow mt-0" style=" max-height: 80vh;">
    <div class="text-white bg-primary  br-5" >
      <div class=" d-flex align-items-center flex-column-mobile  justify-content-between" >
        <!-- Title Column -->
        <div class=" d-flex justify-content-center justify-content-md-start mb-2 mb-md-0 " >
          <h5 class="card-title mb-0" >PERSONAL INFORMATION</h5>
        </div>
    
        <!-- Status Container Column -->
        <div class="d-flex align-items-center justify-content-center p-1" >
          <!-- <div class="card "> -->
            <div class="subscriber-status d-flex align-items-center justify-content-md-end mb-2 mb-md-0 p-2 bg-light" style="font-size: 1rem;border-radius: 10px;">
              <b class="mr-1 text-muted" style="white-space: nowrap;">Account Status:</b>
              <span [ngClass]="{
                'badge-light-success': customerData?.personalInfo?.accountStatus?.value === 'Active',
                'badge-light-danger': customerData?.personalInfo?.accountStatus?.value === 'Cancelled' || 'Deactivated',
                'badge-light-warning': customerData?.personalInfo?.accountStatus?.value === 'Scheduled'
              }">
                {{ customerData?.personalInfo?.accountStatus?.value|| '-' }}
              </span>
            </div>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="card-body" *ngIf="!isSkeletonLoader" @fadeInOut>
      <form [formGroup]="personalInfoFormData">
        <!-- Loop through the form fields to display each field in its own row -->
        <ng-container *ngFor="let field of formFields">
          <div *ngIf="field.visible" class="row mb-3">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <label class="form-label mb-0" style="font-size: 1rem; font-weight: 500;">{{ field.label }}</label>
                </div>
                <div class="col-12 col-md-8">
                  <div class="input-group">
                    <!-- Display Mode (Default State) -->
                    <div *ngIf="!isEditing(field.controlName)" class="d-flex justify-content-between align-items-center w-100">
                      <div class="text-truncate p-2">{{ personalInfoFormData.get(field.controlName)?.value }}</div>
                      
                      <button type="button" class="btn btn-custom" 
                      (click)="field.controlName === 'mailingAddress' ? openChangeAddressModal() : toggleEdit(field.controlName)"
                      *ngIf="field.controlName !== 'serviceAddress'">
                      <i class="fa" [ngClass]="field.controlName === 'mailingAddress' ? 'fa-map-marker' : 'fa-edit'"></i>
                    </button>
                      <button type="button" class="btn btn-custom-lock " *ngIf="field.controlName === 'serviceAddress'">
                        <i class="fa fa-lock" ></i>
                      </button>
                    </div>
        
                    <!-- Edit Mode -->
                    <div *ngIf="isEditing(field.controlName)" class="w-100">
                      <div class="d-flex justify-content-between align-items-center">
                        <!-- Input Field -->
                        <input [formControlName]="field.controlName" type="text" class="form-control form-control-lg"/>
        
                        <!-- Save and Cancel Buttons -->
                        <div class="d-flex ms-2">
                          <button type="button" class="btn btn-custom-save" (click)="updateCustomerProfile(field.controlName)">
                            <i class="fa fa-save"></i>
                          </button>
                          <button type="button" class="btn btn-custom-cancel ms-2" (click)="removeInputField(field.controlName)">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        
      </form>
    </div>
    <div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
      <app-skeleton-loader [formColumnCount]="1" [formRowCount]="10" shape="single-column-form"></app-skeleton-loader>
    </div>
    
    
  </div>
</div>
<!-- <div *ngIf="isLoading" class="card-loader">
  <app-loader [isChildLoader]="true"></app-loader>
</div> -->


<!-- Define the modal content using ng-template -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Update Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Add your component inside the modal -->
      <app-change-address-account  #changeAddress></app-change-address-account>
  </div>
 
</ng-template>
