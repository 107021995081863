import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { CommonService } from '../shared/services/common.service';
import { AwaitingShipmentService } from './services/awaiting-shipment.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-awaiting-shipment',
  templateUrl: './awaiting-shipment.component.html',
  styleUrls: ['./awaiting-shipment.component.scss']
})
export class AwaitingShipmentComponent {
  public dataArray = [];
  @Input() isHideCard: any
  @Input() isDropshipOrder: any
  constructor(private commonService: CommonService, private awaitingShipmentService: AwaitingShipmentService, private authService:AuthService) { }

  ngOnInit(): void {
    this.getCardStatsData()
  }

  orderEmittedData: any
  handleOrderedEmitted(data: any) {
    this.orderEmittedData = data
    console.log(data,"data");
    // console.log('Received ordersData from child:', data);
  }

  openUnitywireless() {
    this.commonService.openBuyFlow();
  }

  getCardStatsData() {
    this.awaitingShipmentService.getCardStatData().then((res: any) => {
      if (res.Status)
        this.dataArray = res.data
      this.dataArray.map(val=>{
        if(val.orders){
          val.count= val.orders
        }
      })
      console.log("data",this.dataArray)
    })
  }


  exportOrder() {
    const data: any = {
      per_page: 100,
      page: 1,
      IsExport: 1,
    };

    this.commonService.getAwaitingShipmentOrder(data).subscribe((res: any) => {
      if (res.Status && res.data){
        const anchor = document.createElement("a");
        anchor.href = res.data;
        anchor.target = "_blank";
        anchor.download = "";
        anchor.click();
        anchor.remove();
        this.commonService.showToast('success', "Data download successfully ", "")
      }
    })
  }

}
