<form [formGroup]="formData" class="w-100">
    <div class="row">
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Title <span class="text-danger">*</span></label>
                <input type="text" formControlName="Title" placeholder="Title" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Ticket Type <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="TicketTypeID">
                    <option value="">Select Ticket Type</option>
                    <option *ngFor="let ticket of allTicketTypesList" [value]="ticket.id">{{ticket.Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 my-2">
            <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea formControlName="Description" maxlength="2000" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Customer ID <span class="text-danger">*</span></label>
                <input type="text" formControlName="CustomerId" placeholder="Customer ID" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Customer Name <span class="text-danger">*</span></label>
                <input type="text" formControlName="CustomerName" placeholder="Customer Name" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Contact Number</label>
                <input type="number" formControlName="ContactNumber" placeholder="Contact Number" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Alternate Number</label>
                <input type="number" formControlName="AlternateNumber" placeholder="Alternate Number"
                    class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Status <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="StatusID">
                    <option value="">Select Status</option>
                    <option *ngFor="let item of allStatusList" [value]="item.id">{{item.Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-3 my-2">
            <div class="form-group">
                <label>Assign Ticket To <span class="text-danger">*</span></label>
                <div>
                    <div class="form-check form-check-inline">
                        <input (change)="onAssignmentTypeChange()" class="form-check-input" type="radio"
                            formControlName="AssignmentType" id="inlineRadio1" value="AGENT">
                        <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio1">User</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input (change)="onAssignmentTypeChange()" class="form-check-input" type="radio"
                            formControlName="AssignmentType" id="inlineRadio2" value="GROUP">
                        <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio2">Group</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 my-2">
            <div class="form-group" *ngIf="getAssignmentType.value == 'AGENT'">
                <label>User List <span class="text-danger">*</span></label><br>
                <select class="form-control" formControlName="AgentID">
                    <option value="">Select User</option>
                    <option *ngFor="let user of allUserList" [value]="user.id">{{user.name}}</option>
                </select>
            </div>
            <div class="form-group" *ngIf="getAssignmentType.value == 'GROUP'">
                <label>Group List <span class="text-danger">*</span></label><br>
                <select class="form-control" formControlName="GroupID">
                    <option value="">Select Group</option>
                    <option *ngFor="let group of allGroupsList" [value]="group.id">{{group.Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 my-2">
            <label>Upload Files</label><br>
            <!-- <input type="file" class="form-control" (change)="onFileChange($event)" multiple> -->
            <ngx-dropzone (change)="onSelect($event)">
                <ngx-dropzone-label>
                    <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                        <h6>Drop files here or click to upload.</h6>
                    </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadedFiles" [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>

        <div class="col-12 my-2" *ngIf="attachments.length > 0">
            <h5 class="headerTitle mb-0">Uploaded Files:</h5>
            <div class="row my-2" *ngFor="let attachment of attachments;let i = index">
                <div class="col-4">
                    <a [href]="apiEndPoint + attachment.FilePath" target="_blank" download>
                        {{ attachment.FileName }}
                    </a>
                </div>
                <div class="col-4">
                    <button class="btn btn-danger" (click)="deleteAttachments(attachment?.id,i)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="p-3">
                <div class="text-center mt-3">
                    <button type="button" [disabled]="formData.invalid" (click)="onSubmit()" class="btn btn-primary">
                        Submit</button>
                </div>
            </div>
        </div>
    </div>
</form>

