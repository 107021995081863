import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  filesList: any;
  downloadDisclosurePDFLink: any;
  downloadWorksheetPDFLink: any
  downloadPDFLink: any
  @LocalStorage()
  private EnrollmentNo;
  constructor(private customerProfileService: CustomerProfileService, private commonService: CommonService) {
  }

  async ngOnInit() {
    this.getFilesByEnrollmentNo();
  }
  async getFilesByEnrollmentNo() {
    
    let res = await this.customerProfileService.getNVUpdates(this.EnrollmentNo);
    if (res && (res as any)?.data?.data) {
      this.filesList = (res as any).data.data;
      
    } else {
      
    }
  }
  async generateWorkSheet(fileData: any) {
    
    let res = await this.customerProfileService.createExceptionWorksheet(fileData);
    if (res && (res as any)?.data) {
      this.downloadDisclosurePDFLink = (res as any).data?.DisclosurePDF;
      this.downloadWorksheetPDFLink = (res as any).data?.worksheetPDF;
      
      if (this.downloadDisclosurePDFLink) this.downloadPdfFile(this.downloadDisclosurePDFLink, 'downloadDisclosurePDFLink');
      if (this.downloadWorksheetPDFLink) this.downloadPdfFile(this.downloadWorksheetPDFLink, 'downloadWorksheetPDFLink')

    }
  }

  async downloadEnrollmentPdf() {
    
    let res = await this.customerProfileService.downloadEnrollmentPdf();
    if (res && (res as any)?.data) {
      this.downloadPDFLink = (res as any)?.data;
      
      if (this.downloadPDFLink) this.downloadPdfFile(this.downloadPDFLink, "downloadPDFLink")
    } else {
      
    }
  }

  private downloadPdfFile(url: string, name: any) {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
