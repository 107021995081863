<div class="filter-wrapper">
    <div>
        <button class="filter-btn d-flex align-items-center" (click)="toggleDropdown()">
            <span class="text" *ngIf="!icon">

                {{ selectedItem ? selectedItem[label] : title }}

            </span>
            <span class="icon-wrapper" *ngIf="!icon && !isBtn">
                <svg viewBox="1 1 18 18" focusable="false" aria-hidden="true" *ngIf="!this.isDropdownOpen">
                    <path fill-rule="evenodd"
                        d="M6.24 8.2a.75.75 0 0 1 1.06.04l2.7 2.908 2.7-2.908a.75.75 0 1 1 1.1 1.02l-3.25 3.5a.75.75 0 0 1-1.1 0l-3.25-3.5a.75.75 0 0 1 .04-1.06Z">
                    </path>
                </svg>
                <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true" *ngIf="this.isDropdownOpen">
                    <path fill-rule="evenodd"
                        d="M6.24 11.8a.75.75 0 0 0 1.06-.04l2.7-2.908 2.7 2.908a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 0 0 .04 1.06Z">
                    </path>
                </svg>
            </span>
            <i [class]="icon" class="pe-1"></i>
        </button>
        <div *ngIf="this.isDropdownOpen && !isBtn" class="dropdown-content">
            <ul class="p-1">
                <li *ngFor="let item of optionList" class="list-item">
                    <button class="d-flex align-items-center justify-content-between" [ngClass]="{'active': selectedItem && selectedItem[value] == item[value]}" (click)="selectItem(item)">
                        <span>{{item[label]}}</span>
                        <span class="icon-wrapper" *ngIf="selectedItem && selectedItem[value] == item[value]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M13.78 3.97a.75.75 0 0 1 0 1.06l-6.5 6.5a.75.75 0 0 1-1.06 0l-3.25-3.25a.749.749 0 1 1 1.06-1.06l2.72 2.72 5.97-5.97a.75.75 0 0 1 1.06 0">
                                </path>
                            </svg>
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>