import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortRequestsService } from 'src/app/portIn-out/services/porting-requests.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';

@Component({
  selector: 'app-change-billing-address',
  templateUrl: './change-billing-address.component.html',
  styleUrls: ['./change-billing-address.component.scss']
})
export class ChangeBillingAddressComponent implements OnInit {
  updateBillingInfoForm: FormGroup;
  personalInfoData: any;
  loader: boolean = false;
  countries: any = [];
  states: any[] = [];
  cities: any[] = [];
  postalCodes: any[] = [];
  constructor(
    private customerProfileService: CustomerProfileService,
    private subscriptionService: SubscriptionService,
    public commonService: CommonService,
    private fb: FormBuilder,
    private portRequestsService: PortRequestsService
  ) { }

  ngOnInit() {
    this.initializeForm()
    this.getCustomerPersonalInfo();
    this.getCountries();
    this.patchProfileData()
  }

  initializeForm() {
    this.updateBillingInfoForm = this.fb.group({
      address: ['', Validators.required],
      address2: [''],
      state: ['', Validators.required],
      city: ['', Validators.required],
      postalcode: ['', Validators.required],
      country: ['', Validators.required],
    });
  }



  async getCustomerPersonalInfo() {
    this.loader = true;
    try {
      const res: any = await this.customerProfileService.getCustomerPersonalCardData();
      if (res.Status) {
        this.personalInfoData = res.data;
        console.log("personalInfoData :-", this.personalInfoData)

        this.patchBillingAddress()
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    this.loader = false;
  }

  patchBillingAddress() {
    this.updateBillingInfoForm.patchValue({
      address: this.personalInfoData?.personalInfo?.billingAddress?.value?.address1 || '',
      address2: this.personalInfoData?.personalInfo?.billingAddress?.value?.address2 || '',
      state: this.personalInfoData?.personalInfo?.billingAddress?.value?.state || '',
      city: this.personalInfoData?.personalInfo?.billingAddress?.value?.city || '',
      postalcode: this.personalInfoData?.personalInfo?.billingAddress?.value?.zipCode || '',
      country: this.personalInfoData?.personalInfo?.billingAddress?.value?.country ?? 'United States',
    });

  }
  get country() {
    return this.updateBillingInfoForm.get("country");
  }
  get state() {
    return this.updateBillingInfoForm.get("state");
  }
  get city() {
    return this.updateBillingInfoForm.get("city");
  }
  get zipCode() {
    return this.updateBillingInfoForm.get("zipCode");
  }
  async getCountries() {
    if (this.countries.length > 0) {
      return;
    }
    this.loader = true;
    try {
      const res: any = await this.commonService.getCountries();
      if (res.Status) {
        res.data.forEach(element => {
          element["value"] = element.CountryCode,
          element["label"] = element.CountryName
        });
        this.countries = res.data
        this.updateBillingInfoForm.patchValue({
          country: res.data[0]
        });

      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {

      this.commonService.showToast("error", "Error", err.error.message)
    }
    this.loader = false;
  }




  async patchProfileData() {
    const StateResponse = await this.portRequestsService.getStates().toPromise();
    this.states = StateResponse?.data;   

    const cityResponse = await this.portRequestsService.getCities(this.personalInfoData?.personalInfo?.billingAddress?.value?.stateId).toPromise();
    this.cities = cityResponse?.data;

    const city = this.cities.find(city => city.id === this.personalInfoData?.personalInfo?.billingAddress?.value?.cityId);

    const postalCodeResponse = await this.portRequestsService.getPostalCodes(city.id).toPromise();
    this.postalCodes = postalCodeResponse?.data;

  }

  async onSubmit() {
    this.loader = true;
    const userBillingDetails: any = {
      CustomerId: this.personalInfoData?.personalInfo?.customerId,
      subscriptionId: sessionStorage.getItem('SubscriptionId') ?? [],
      billingInfo: {
        firstName: this.personalInfoData?.personalInfo?.firstName?.value,
        lastName: this.personalInfoData?.personalInfo?.lastName?.value,
        phone: this.personalInfoData?.personalInfo?.phone?.value,
        address: this.updateBillingInfoForm.value?.address,
        address2: this.updateBillingInfoForm.value?.address2,
        city: this.updateBillingInfoForm.value?.city,
        state: this.updateBillingInfoForm.value?.state,
        zip: this.updateBillingInfoForm?.value?.postalcode,
        country: this.updateBillingInfoForm?.value?.country?.CountryName,
      }
    };
    console.log("onSubmit :-", userBillingDetails);
    try {
      this.subscriptionService.editBillingInfo(userBillingDetails).subscribe(
        (res: any) => {
          if (res?.data == 'Subscriptions that are canceled cannot be updated.') {
            this.commonService.showToast('error', "Error", (res?.data));
          } else {
            this.commonService.showToast('success', "Success", res?.data);
          }
        },
        (error: any) => {
          console.error("Error updating billing info:", error);
        }
      );
    } catch (error) {
      console.error("Exception caught:", error);
      
    }
    this.loader = false;
  }

    // Load States
    async loadStates() {
      
      try {
        await this.portRequestsService.getStates().subscribe((response: any) => {
          this.states = response?.data;
          
        });
      } catch (err) {
        
      }
    }

     //Load Postal Code
  onCityChange(event): void {
    const selectedCityName = event?.trim();
    try {
      
      if (!!selectedCityName && selectedCityName?.length) {
        const selectedCity = this.cities.find(state => state.CityName === selectedCityName);
        if (selectedCity?.id) {
          this.portRequestsService.getPostalCodes(selectedCity.id).subscribe((response: any) => {
            this.postalCodes = response?.data;
            
          });
        }
        else {
          
        }
      }
    } catch (error) {
      
    }
  }

  // Load Cities
  onStateChange(event: any): void {
    const selectedStateName = event?.trim();
    try {
      
      if (!!selectedStateName && selectedStateName?.length) {
        const selectedState = this.states.find(state => state.StateCode === selectedStateName);
        if (selectedState?.id) {
          this.portRequestsService.getCities(selectedState.id).subscribe((response: any) => {

            this.cities = response?.data;
            
          });
        } else {
          
        }
      }
    } catch {
      
    }
  }
}


