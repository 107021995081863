<div class="left-panel p-3 bg-light rounded shadow-sm">
  <nav class="nav flex-column">
    <ul class="list-group list-group-flush">
      <li *ngFor="let card of cards"  
          [ngClass]="{'active-card': card.title === activeTab, 'list-group-item': true, 'cursor-pointer': true, 'd-flex': true, 'align-items-center': true}"
          (click)="selectCard(card)" class="mb-3">
        <i [ngClass]="getIconClass(card.title)" class=" text-primary"></i>
        <span class="card-title ms-3">{{ card.title }}</span>
      </li>
    </ul>
  </nav>
</div>
