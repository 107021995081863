import { Component } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss']
})
export class OrderTableComponent {
  headers = [
    { key: 'OrderId', display: 'Order' },
    { key: 'DeliveryDate', display: 'Date' },
    { key: 'CustomerId', display: 'Customer' },
    { key: 'Channel', display: 'Channel' },
    { key: 'TotalAmount', display: 'Total' },
    { key: 'PaymentStatus', display: 'Payment status' },
    { key: 'OrderStatus', display: 'Fullfillment status' },
    { key: 'OrderType', display: 'Items' },
    { key: 'ShipmentStatus', display: 'Delivery status' },
    { key: 'Tags', display: 'Tags' }
  ];

  itemDropdownVisible = new Set<number>();
  rowDatas: any = []
  itemsPerPage: any = 10;
  currentPage: any = 1;
  startDate: any;
  endDate: any;
  selectedFilterObject: any;
  selectedColumn: null;
  totalOrders: any;
  customerData: any;
isSkeletonLoader: boolean=true;

  CustomerId:any;

  constructor(private service: CommonService, private http: HttpService, private customerProfileService: CustomerProfileService) {

  }

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    const res: any = await this.customerProfileService.getCustomerPersonalCardData();
    this.customerData = res?.data;
    this.CustomerId = this.customerData?.personalInfo?.customerId
    // this.fetchData()
  }
  async toggleItemDropdown(index: number, item) {
    if (this.itemDropdownVisible.has(index)) {
      this.itemDropdownVisible.delete(index); // Hide if already visible
    } else {
      this.itemDropdownVisible.clear();
      this.itemDropdownVisible.add(index); // Show if not visible
    }
  }


  private fetchData() {
    console.log(this.customerProfileService.userData);
    const data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      CustomerId: this.customerData?.personalInfo?.customerId || this.http.getCustomerId(),
      fromDate: this.startDate,
      toDate: this.endDate,
      orderStatus: this.selectedFilterObject?.OrderId || null,
      paymentStatus: this.selectedFilterObject?.PaymentStatus || null,
      shipmentStatus: this.selectedFilterObject?.OrderStatus || null,
      sortByField: this.selectedColumn || null,
    };
    this.service.getAwaitingShipmentOrder(data).subscribe(
      (response) => {
        this.rowDatas = response?.data?.Orders?.data || [];
        this.totalOrders = response?.data?.TotalOrders || 0;
        this.isSkeletonLoader=false
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.isSkeletonLoader=false
      }
    );
  }
}
