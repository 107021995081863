import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-sim-change',
  templateUrl: './sim-change.component.html',
  styleUrls: ['./sim-change.component.scss']
})
export class SimChangeComponent implements OnInit, AfterViewInit{
  formData: FormGroup;
  loader = false
  isPhysical:boolean=false
  shipperList:any
  @Input('customerData') customerData
isSkeletonLoader: boolean=true;
disconnectReasons = [];
  constructor(private customerProfileService: CustomerProfileService, private fb: FormBuilder, private commonService: CommonService, private cd:ChangeDetectorRef,private httpService:HttpService) {
    this.formData = this.fb.group({
      changeType: ['Select Type', Validators.required],
      simType: ['Select Type', Validators.required],
      simNo:new FormControl({ value: '', disabled: true }, [Validators.required]),
      storeId:['Select Store Id',Validators.required],
      disConnectPrevNumber: [false],  // Add default values as needed
      addPlan: [false],
      rejectionResasonID: [''],
      freeEsim: new FormControl({ value: false, disabled: true })
    });

  }
  ngOnInit() {
    this.getShipperList()
    this.getRejectionReasonOptions()
    console.log("customerData :-", this.customerData)
    this.formData.get('simNo').setValue(this.customerData?.Number);
    this.formData.get('simType')?.valueChanges.subscribe(value => {
      const simNoControl = this.formData.get('simNo');
      console.log('value:',value)
      console.log('customerdata: ',this.customerData)
      if (value == "PHYSICAL") {
      console.log('value:',value)

        simNoControl?.enable();
      } else {
        simNoControl?.disable();
      }
    });
      // Listen to changes in simType and changeType
  this.formData.get('simType')?.valueChanges.subscribe(() => {
    this.toggleFreeEsim();
  });

  this.formData.get('changeType')?.valueChanges.subscribe(() => {
    this.toggleFreeEsim();
  });
    this.isSkeletonLoader=false

  }

  async getRejectionReasonOptions() {
    try {
      
      const res: any = await this.customerProfileService.getRejectionsOptions()
      if (res.Status) {
        this.disconnectReasons = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges()
  }
  async isPhysicalStatus(value:any){
    if(value=='PHYSICAL'){
      this.isPhysical=true
    }else if(value=='ESIM'){
      this.isPhysical=false
    }
  }
   updateSimNo() {
    console.log('Change Type :-', this.formData.value)
    if (this.formData.get('changeType').value=='Electronic' && this.formData.get('simType').value=='PHYSICAL'){
      this.updateSimWithStoreId()
      console.log('updateSimWithStoreId')
    }else if( this.formData.get('changeType').value=='Electronic' && this.formData.get('simType').value=='PHYSICAL' || this.formData.get('changeType').value=='Electronic' && this.formData.get('simType').value=='ESIM'){
      this.electrnoicSimChange()
    }else  if( this.formData.get('simType').value=='ESIM' && this.formData.get('changeType').value=='NonElectronic'){
      this.eSimChange()
    }else{
      this.nonElectrnoicSimChange();
    }
  }

 async  updateSimWithStoreId(){
   try {
    if(this.formData.get('changeType').value=='Electronic' && this.formData.get('simType').value=='PHYSICAL'){
      let iccid= this.formData.get('simNo').value;
      let simType=this.formData.get('simType').value;
      let storeId=this.formData.get('storeId').value;
      let data ={
          simType:simType,
          newIccid: iccid,
          storeId:storeId
      }
      console.log('data: ',data)
      const res: any = await this.customerProfileService.updateSim(data)

      if(res?.Status){
        this.commonService.showToast('success', "Success", 'Successfully Updated')
      }else{
        this.commonService.showToast('error', "Error", 'Unable to change Sim')
      }
    }
   } catch (error) {
    this.commonService.showToast('error', "Error", error?.message)
    
   }
  }

  async getShipperList() {
    try {
      this.customerProfileService.getShipperStationData().subscribe({
        next: (res: any) => {
          if (res?.Status) {
            this.shipperList = res?.data;
            console.log('Shipper data:', this.shipperList);
          this.isSkeletonLoader=false

          } else {
            this.commonService.showToast('error', 'Error', 'Unable to get Stores');
         this.isSkeletonLoader=false
          }
        },
        error: (err) => {
          this.commonService.showToast('error', 'Error', err?.message || 'An error occurred while fetching data');
          this.isSkeletonLoader=false

        }
      });
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message || 'An unexpected error occurred');
     this.isSkeletonLoader=false

    }
  }
  
  async eSimChange(){
    
    
    // let res1= await this.customerProfileService.getEmail().then(email)
    let data={
      CustomerId:await this.httpService.getCustomerId(),
      Email:await this.customerProfileService.getEmail(),
      CustomerName: await this.customerProfileService.getCustomerName()
    }
    try {
      const response= await this.customerProfileService.generateQrCode(data) 
      
    } catch (error) {
      
      
    }

  }
  async electrnoicSimChange() {
    
    let iccid= this.formData.get('simNo').value;
    let simType=this.formData.get('simType').value;
    const freeEsim = this.formData.get('freeEsim')?.value;
    try {
      let data = {}
      if(this.formData.get('simType').value=='ESIM' ){
        data={
          simType:simType,
          freeEsim: freeEsim 
        }
      }else{
        data={
          simType:simType,
          newIccid: iccid
        }
      }
      
      const res: any = await this.customerProfileService.updateSim(data)
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Updated')
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }


    } catch (err) {
      console.log(err)
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }

  async nonElectrnoicSimChange(){
    
    let iccid= this.formData.get('simNo').value;
    let simType=this.formData.get('simType').value;
    let addPlan=this.formData.get('addPlan').value;
    let disConnectPrevNumber=this.formData.get('disConnectPrevNumber').value;
    let rejectionResasonID=this.formData.get('rejectionResasonID').value;
    try {
      const data = { 
        "simType": simType,
        "newIccid":iccid,
        "addPlan": addPlan , // optional | default:false
        "disConnectPrevNumber": disConnectPrevNumber , // optional | default:false
        "rejectionResasonID": rejectionResasonID ,
      }
      const res: any = await this.customerProfileService.simChangeNonElec(data)
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Updated');
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.error ?? "Some Error Occurred"))
    }
    
  }

    // Helper method to toggle freeEsim dynamically
toggleFreeEsim() {
  const simType = this.formData.get('simType')?.value;
  const changeType = this.formData.get('changeType')?.value;
  const freeEsimControl = this.formData.get('freeEsim');

  if (simType === 'ESIM' && changeType === 'Electronic') {
    freeEsimControl?.enable();
  } else {
    freeEsimControl?.disable();
    freeEsimControl?.setValue(false); // Reset value when disabled
  }
}
}