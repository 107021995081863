<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row">
      <div class="col-md-12">
        <h5>Create New Portin Request</h5>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Carrier</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Phone Number</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>ESN/SIM</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label> New ESN/SIM</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>IMEI</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Old Esn Status</label>
      </div>
      <div class="col-md-9">
        <select class="form-control form-select">
          <option>select </option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Who got it back</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Number To Port</label>
      </div>
      <div class="col-md-3">
        <input type="number" class="form-control">
      </div>
      <div class="col-md-6 text-end">
        <button type="button" class="btn btn-primary">Check Portability</button>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Plan Number</label>
      </div>
      <div class="col-md-3">
        <p>$15 GB prepaid plan</p>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>SSN (4 digits)</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>Account Number</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Password Pin</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Business Name</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Bill First Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Bill Last Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Zip</label>
      </div>
      <div class="col-md-9">
        <input type="text" maxlength="5" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>City</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>State</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>Street</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Address 1</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Address 2</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Swap Type</label>
      </div>
      <div class="col-md-9">
        <select class="form-control form-select">
          <option>Select</option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary">
          Submit
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>
          Note:- For T-Mobile if customer is infront of you and you have a new SIM available then you can do the portin
          with by choosing the option COMPLETE SWAP AS PORT-IN
          COMPLETES. However if the customer is not infront and the new SIM needs to be sent out via mail to the
          subscriber then please do the portin but choose the option DO NOT
          COMPLETE SWAP IMMEDIATELY. For CDMA, you can always place a request by choosing the option COMPLETE SWAP AS
          PORT-IN COMPLETES because in CDMA port-in can
          happen on the old device. However if the device is also changing then and device needs to be sent via mail
          then do the portin but choose the option DO NOT COMPLETE SWAP
          IMMEDIATELY.
        </p>
      </div>
    </div>
  </form>
</div>