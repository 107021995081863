<div class="d-lg-flex d-block align-items-start">
  <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <button class="nav-link active" id="v-pills-credit-card1-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-credit-card1" type="button" role="tab" aria-controls="v-pills-credit-card1"
      aria-selected="true">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Credit Card</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-cash1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cash1" type="button"
      role="tab" aria-controls="v-pills-cash1" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Cash</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-money-order1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-money-order1"
      type="button" role="tab" aria-controls="v-pills-money-order1" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Money Order</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-money-gram-tab" data-bs-toggle="pill" data-bs-target="#v-pills-money-gram"
      type="button" role="tab" aria-controls="v-pills-money-gram" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Money Gram</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-wallet1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-wallet1"
      type="button" role="tab" aria-controls="v-pills-wallet1" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Wallet</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-dealer-wallet1-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-dealer-wallet1" type="button" role="tab" aria-controls="v-pills-dealer-wallet1"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Dealer Wallet</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-add-topup1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-add-topup1"
      type="button" role="tab" aria-controls="v-pills-add-topup1" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Free Top Up</h5>
        </div>
      </div>
    </button>

  </div>
  <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade show active" id="v-pills-credit-card1" role="tabpanel"
      aria-labelledby="v-pills-credit-card1-tab">
      <app-credit-card></app-credit-card>
    </div>
    <div class="tab-pane fade" id="v-pills-cash1" role="tabpanel" aria-labelledby="v-pills-cash1-tab">
      <app-cash></app-cash>
    </div>
    <div class="tab-pane fade" id="v-pills-money-gram" role="tabpanel" aria-labelledby="v-pills-money-gram-tab">
      <app-money-gram></app-money-gram>
    </div>
    <div class="tab-pane fade" id="v-pills-money-order1" role="tabpanel" aria-labelledby="v-pills-money-order1-tab">
      <app-money-order></app-money-order>
    </div>
    <div class="tab-pane fade" id="v-pills-wallet1" role="tabpanel" aria-labelledby="v-pills-wallet1-tab">
      <app-wallet></app-wallet>
    </div>
    <div class="tab-pane fade" id="v-pills-dealer-wallet1" role="tabpanel" aria-labelledby="v-pills-dealer-wallet1-tab">
      <app-dealer-wallet></app-dealer-wallet>
    </div>
    <div class="tab-pane fade" id="v-pills-add-topup1" role="tabpanel" aria-labelledby="v-pills-add-topup1-tab">
      <app-free-topup></app-free-topup>
    </div>
  </div>
</div>