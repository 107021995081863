<div class="container-fluid card p-3">
  <div class="row">
    <div class="col-md-6">
      <h6>Customer activity log</h6>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs">
    <li [ngbNavItem]="1" class="w-50 text-center">
      <a ngbNavLink>Activity</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-sm-12">
            <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">

              <div class="row">
                <div class="col-md-12">
                  <h5>Activities</h5>
                </div>
                <div class="col-md-12 mt-3" *ngFor="let activity of data">
                  <div class="d-flex">
                    <div>
                      <div class="circle"><span>{{activity?.activityNo?.value}}</span></div>
                    </div>
                    <div class="ms-3">
                      <h6 class="mb-0"> {{activity?.name?.value}}</h6>
                      <p class="mb-0">{{activity?.activityDetail?.value}}</p>
                      <span class="text-muted fs-12">On {{activity?.timeLine?.value | date: 'MMM d, y, h:mm:ss'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" class="w-50 text-center">
      <a ngbNavLink>Audit Log</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-sm-12">
            <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
              <p>Audit Log</p>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>

  <br><br><br>

</div>