import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotesService } from '../services/notes.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-add-note-type',
  templateUrl: './add-note-type.component.html',
  styleUrls: ['./add-note-type.component.scss']
})
export class AddNoteTypeComponent {

  formData: FormGroup;
  @Output() addNoteTypeEvent = new EventEmitter<string>();
  public loader: boolean = false;

  constructor(
    public fb: FormBuilder,
    public notesService: NotesService,
    public commonService: CommonService,
  ) {
    this.initializeFormData()
  }

  initializeFormData() {
    this.formData = this.fb.group({
      "noteType": [""],
      "Description":[""]
    })
  }

  async onSubmit() {
    // 
    this.loader = true;
    try {
      console.log('customer notes type form data', this.formData.value)
      const res:any = await this.notesService.addNoteType(this.formData.value)
      console.log('notes type add res', res)
      this.addNoteTypeEvent.emit('')
      if(res.Status) {
        this.commonService.showToast("success","Success","Added successfully!")
      } else {
        this.commonService.showToast("error","Error",res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
    // 
  }

}
