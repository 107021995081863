import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-acp-detail-modal',
  templateUrl: './acp-detail-modal.component.html',
  styleUrls: ['./acp-detail-modal.component.scss']
})
export class AcpDetailModalComponent {

  @Input() type:any;
  @Input() data:any;

}
