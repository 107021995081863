import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { CustomerProfileComponent } from '../../customer-profile/customer-profile.component';
import { CustomerDetailedInformationService } from '../../customer-profile/shared/customer-detailed-information.service';

@Component({
  selector: 'app-line-info',
  templateUrl: './line-info.component.html',
  styleUrls: ['./line-info.component.scss']
})
export class LineInfoComponent {
  @Input() minimalProfileData: any;
  @Input() fullScreen: any;
  simType
  customerMoreLineInfo: any;
  formFields: any[];

  constructor(
    private customerProfileService:CustomerProfileService,
    private customerProfileComponent:CustomerProfileComponent,
    private commonService:CommonService,
    private customerDetailedInformationService:CustomerDetailedInformationService
  ){
  }
  async ngOnInit() {
    await this.getMoreLineInformation();
  }

  async getMoreLineInformation() {
    try {
      const response: any = await this.customerProfileService.getLineInfoMoreCardData();
      if (response?.Status) {
        // delete response?.data?.SLOs;
        delete response?.data?.makeModel
        this.customerMoreLineInfo = this.formatLineInfo(response?.data);
        this.initializeFields();
      } else {
        this.commonService.showToast('error', 'Error', response?.message);
      }
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message);
    } finally{
    }
  }

  formatLineInfo(lineInfo: any) {
    const formattedInfo: any = {};
    for (const key in lineInfo) {
      if (lineInfo.hasOwnProperty(key)) {
        const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
        formattedInfo[formattedKey] = this.customerDetailedInformationService.formatValue(lineInfo[key]);
      }
    }
    return formattedInfo;
  }


  initializeFields() {
    const planAttributes = ['Plan', 'Plan Id','Plan Detail', 'Plan Price'];
    const dateAttributes = ['Activation Date', 'Expiration Date', 'Next Renewal Date','Mdn Expiry Date'];
    const shortFormAttributes = ['E Sim', 'Sim Type', 'Iccid Status','Mdn','Imei','Esn','Msisdn','Imsi','Uiccid'];
    const remainingAttributes = [];
  
    const fields: any[] = [];

    planAttributes.forEach(attr => {
      if (this.customerMoreLineInfo[attr]) {
        let value = this.customerMoreLineInfo[attr];
        
        if (attr === 'Plan Price') {
          value = this.customerDetailedInformationService.formatCurrency(value);
        }
  
        fields.push({ controlName: attr, label: attr, value });
      }
    });
  
    dateAttributes.forEach(attr => {
      if (this.customerMoreLineInfo[attr]) {
        const value = this.customerDetailedInformationService.formatDate(this.customerMoreLineInfo[attr]);
        fields.push({ controlName: attr, label: attr, value });
      }
    });
  
    shortFormAttributes.forEach(attr => {
      if (this.customerMoreLineInfo[attr]) {
        fields.push({ controlName: attr, label: attr, value: this.customerMoreLineInfo[attr] });
      }
    });
  
    for (const key in this.customerMoreLineInfo) {
      if (
        this.customerMoreLineInfo.hasOwnProperty(key) &&
        !planAttributes.includes(key) &&
        !dateAttributes.includes(key) &&
        !shortFormAttributes.includes(key)
      ) {
        remainingAttributes.push({ controlName: key, label: key, value: this.customerMoreLineInfo[key] });
      }
    }
  
    this.formFields = [...fields, ...remainingAttributes];

    console.log(this.formFields);
  }
}
