
<div class="customizer-contain open" >
    <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
        <h5>{{payloadName}} Report</h5>
        <i (click)="closeBatchReportModal()" class="icofont-close icon-close"></i>
      </div>
      <div class="customizer-body custom-scrollbar">
        <div class="tab-pane fade active show" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
          <div class="row my-2">
            <div class="col-md-6 mt-2">
              <!-- <input type="text" class="form-control" [(ngModel)]="filterValue" (keyup)="filterDataBatch($event)"
                placeholder="Search"> -->
            </div>
  
          </div>
          <div class="wrapper d-flex bg-white quick-link">
  
            <div class="table-responsive">
              <div class="custom-datatable product-list-custom">
                <div class="table-responsive" style="height: 70vh;">
                  <div class="custom-datatable">
                    <table class="table">
                      <thead>
                        <tr>
                          
                          <th class="nowrap" scope="col">Name</th>
                          <th class="nowrap" scope="col">Code</th>
                          <th class="nowrap" scope="col">Check Sum</th>
                          <th class="nowrap" scope="col">Created Date</th>
                          <th class="nowrap" scope="col">Updated Date</th>
                          <th class="nowrap" scope="col">Is Completed</th>
                          <th class="nowrap" scope="col">Is Failed</th>
                          <th class="nowrap" scope="col">Is Processed</th>
                          <th class="nowrap" scope="col">{{code}} Done</th>
                          <th class="nowrap" scope="col">{{code}} Send</th>
                          <th class="nowrap" scope="col">{{code}} Failed</th>
                          <th class="nowrap" scope="col">Count</th>
                          <th class="nowrap" scope="col">Refresh</th>
  
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of batchReports; let i=index;">
                        <td>{{item?.Name || '-'}}</td>
                        <td>{{item?.Code}}</td>
                        <td>{{item?.Checksum}}</td>
                        <td>{{item?.CreatedDate | date : 'medium'}}</td>
                        <td>{{item?.UpdatedDate | date : 'medium'}}</td>
                        <td class="cursor-pointer" (click)="viewBatchReportDetails(item,'IsCompleted')" (click)="contentLoading()">
                          <span *ngIf="item?.countData"> {{item?.countData?.IsCompleted}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                        <td class="cursor-pointer" (click)="viewBatchReportDetails(item,'IsFailed')" (click)="contentLoading()">
                          <span *ngIf="item?.countData"> {{item?.countData?.IsFailed}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                        <td class="cursor-pointer" (click)="viewBatchReportDetails(item,'IsProcessed')" (click)="contentLoading()">
                          <span *ngIf="item?.countData"> {{item?.countData?.IsProcessed}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                        <td  (click)="contentLoading()">
                          <span *ngIf="item?.countData?.Done"> {{item?.countData?.Done}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                        <td (click)="contentLoading()">
                          <span *ngIf="item?.countData?.Send"> {{item?.countData?.Send}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                        <td  (click)="contentLoading()">
                          <span *ngIf="item?.countData?.Failed">
                            {{item?.countData?.Failed}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                       
                        <td  (click)="contentLoading()">
                          <span *ngIf="item?.countData"> {{item?.countData?.count}}</span>
                          <span *ngIf="!item?.countLoaded">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </td>
                        <td>
                          <i class="fa-solid fa-arrows-rotate" (click)="refreshCount(item,i)"></i>
                          <!--  <app-feather-icons (click)="refreshCount(item,i)" [icon]="'arrows-rotate'"></app-feather-icons>  -->
                        </td>

                      </tr>
                      </tbody>
                    </table>
  
                    <div class="d-flex justify-content-between p-2">
                      <select class="form-control me-2 page-dropdown" style="width: auto;" [(ngModel)]="itemsPerPage"
                        (change)="onChangeNumberOfPage()">
                        <option *ngFor="let page of pageDropdownMenu" [ngValue]="page">{{page}}</option>
                      </select>
                      <ngb-pagination 
                      [maxSize]="itemsPerPage"
                       [directionLinks]="true"
                        [boundaryLinks]="true"
                        [collectionSize]="totalItems" 
                        [(page)]="currentPage" 
                        (pageChange)="pageChange($event)"
                        [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <ng-container *ngIf="batchReportLoader">
          <app-loader [isChildLoader]="true"></app-loader>
        </ng-container> -->
      </div>
    </div>
  </div>

  <!--  -->

  <div class="customizer-contain open" *ngIf="showBatchReportDetailModal">
    <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
        <h5>{{payloadName}} Details </h5>
 
        <i (click)="closeBatchDetailModal()"class="icofont-close icon-close"></i>
      </div>
      <div class="customizer-body custom-scrollbar">
        <div class="tab-pane fade active show" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
          <div class="row my-2">
            <div class="col-md-6 mt-2">
              <!-- <input type="text" class="form-control" [(ngModel)]="filterValue" (keyup)="filterData($event)"
                placeholder="Search"> -->
            </div>
            <div class="col-md-6 text-end mt-2">
              <button type="button" class="btn btn-primary">
                Update
              </button>
            </div>
  
          </div>
          <div class="wrapper d-flex bg-white quick-link">
            <!-- <div [ngClass]=" (provisionHandleList || shipmentList) ? 'panelmainactive' : 'panelmaininactive'"> -->
  
              <div class="table-responsive">
  
                <div class="custom-datatable product-list-custom">
                  <div class="table-responsive me-2 pan" style="height: 70vh;">
                    <div class="custom-datatable">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="nowrap" scope="col">FileID</th>
                        
                            <th class="nowrap" scope="col">CustomerId</th>
                            <th class="nowrap" scope="col">IsProcessed</th>
                            <th class="nowrap" scope="col">isSynced</th>
                            <th class="nowrap" scope="col">CreatedBy</th>
                            <th class="nowrap" scope="col">Created Date</th>
                            <th class="nowrap" scope="col">Updated Date</th>
                            <th class="nowrap" scope="col">IsActive</th>
                            <th class="nowrap" scope="col">IsDeleted</th>
                            <th class="nowrap" scope="col">IsFailed</th>
                            <th class="nowrap" scope="col">Remarks</th>
                          
                            <th class="nowrap" scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of batchBulkDetails; let i=index;">
                            <td>{{item?.FileID || '-'}}</td>
                 
                            <td>{{item?.CustomerID || '-'}}</td>
                            <td>{{item?.IsProcessed}}</td>
                            <td>{{item?.isSynced}}</td>
                            <td>{{item?.CreatedBy}}</td>
                            <td>{{item?.CreatedDate | date : 'MMM d, y'}}</td>
                            <td>{{item?.UpdatedDate | date : 'MMM d, y'}}</td>
                            <td>{{item?.IsActive}}</td>
                            <td>{{item?.IsDeleted}}</td>
                            <td>{{item?.IsFailed}}</td>
                            <td>{{item?.Remarks}}</td>
                           
                            <td>
                              <div class="d-flex">
                                <button type="button" matTooltip="Update Provision" (click)="updateStgProvision(item,i)"
                                  class="repeat-logo">
  
                                  <app-feather-icons [icon]="'repeat'"></app-feather-icons>
                                </button>
                                <button type="button" matTooltip="Get Provision" (click)="getProvision(item)"
                                  class="btn btn-primary nowrap ms-2">
  
                                  <i class="fa fa-info"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div class="d-flex justify-content-center p-2" *ngIf="showLoading">
                      <h6>Loading...</h6>
                    </div> -->
                      <!-- <div class="d-flex justify-content-between p-2">
                      <select class="form-control me-2 page-dropdown" style="width: auto;" [(ngModel)]="itemsPerPage"
                        (change)="onChangeNumberOfPage()">
                        <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                      </select>
                      <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true"
                        [collectionSize]="totalItems" [(page)]="currentPage" (pageChange)="pageChange($event)"
                        [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
  
            <!-- <ng-container *ngIf="provisionHandleList || shipmentList">
              <div class="container-fluid pan"
                [ngClass]="(provisionHandleList || shipmentList) ? 'panelactive' : 'panelinactive'">
                <div class="d-flex justify-content-between p-3">
                  <h5>Provision Handle </h5>
                  <i (click)="closePanel()" class="icofont-close icon-close"></i>
                </div>
                <div class="p-3" style="height: 60vh;overflow-y: auto;">
                  <div class="row" *ngIf="provisionHandleList">
                    <div class="col-md-6 mt-2">
                      <b>StgID</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.StgID}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>CustomerID</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.CustomerID}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>iccid</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.iccid}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>ZipCode</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.ZipCode}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>ApsCode</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.ApsCode}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>ProductCode</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.ProductCode}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>UserID</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.UserID}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>CreatedDate</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.CreatedDate}}</p>
                    </div>
  
                    <div class="col-md-6 mt-2">
                      <b>MSISDN</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.MSISDN}}</p>
                    </div>
  
                    <div class="col-md-6 mt-2">
                      <b>SimEligible</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.SimEligible}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>Error</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.Error}}</p>
                    </div>
                    <div class="col-md-6 mt-2">
                      <b>Status</b>
                    </div>
                    <div class="col-md-6 mt-2">
                      <p>{{provisionHandleList.status}}</p>
                    </div>
                  </div>
                  <div class="row text-center" *ngIf="!provisionHandleList">
                    <h6 class="text-center">No data found</h6>
                  </div>
                  <hr>
                  <div class="row my-3">
                    <div class="table-responsive">
                      <h5>Shipment status</h5>
                      <div class="custom-datatable product-list-custom" *ngIf="shipmentList.length > 0">
                        <div class="table-responsive">
                          <div class="custom-datatable" style="overflow-x: auto;">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th class="nowrap" scope="col">Order Id</th>
                                  <th class="nowrap" scope="col">Parcel Carrier ID</th>
                                  <th class="nowrap" scope="col">Parcel Carrier Unit ID</th>
                                  <th class="nowrap" scope="col">Is Uploaded To 3PL</th>
                                  <th class="nowrap" scope="col">Tracking Number</th>
                                  <th class="nowrap" scope="col">ShipOrder Id</th>
                                  <th class="nowrap" scope="col">Carrier</th>
                                  <th class="nowrap" scope="col">label Data</th>
                                  <th class="nowrap" scope="col">Service</th>
  
                                  <th class="nowrap" scope="col">Shipment StatusId</th>
                                  <th class="nowrap" scope="col">Created Date</th>
                                  <th class="nowrap" scope="col">Updated Date</th>
                                  <th class="nowrap" scope="col">Shipment Status</th>
                                  <th class="nowrap" scope="col">Customer Id</th>
                                  <th class="nowrap" scope="col">Order Status Code</th>
                                  <th class="nowrap" scope="col">Order Status Name</th>
  
                                  <th class="nowrap" scope="col">Order Status Description</th>
                                  <th class="nowrap" scope="col">Order Type</th>
                                  <th class="nowrap" scope="col">Order Type Name</th>
                                  <th class="nowrap" scope="col">Order Remarks</th>
                                  <th class="nowrap" scope="col">Order Created Date</th>
  
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of shipmentList; let i=index;">
                                  <td>{{item?.OrderId || '-'}}</td>
                                  <td>{{item?.ParcelCarrierID}}</td>
                                  <td>{{item?.ParcelCarrierUnitID}}</td>
                                  <td>{{item?.IsUploadedTo3PL}}</td>
                                  <td>{{item?.TrackingNumber || '-'}}</td>
                                  <td>{{item?.ShipOrderId}}</td>
                                  <td>{{item?.Carrier}}</td>
                                  <td>{{item?.labelData}}</td>
                                  <td>{{item?.Service}}</td>
                                  <td>{{item?.ShipmentStatusId}}</td>
                                  <td>{{item?.CreatedDate | date : 'MMM d, y'}}</td>
                                  <td>{{item?.UpdatedDate | date : 'MMM d, y'}}</td>
  
                                  <td>{{item?.ShipmentStatus}}</td>
                                  <td>{{item?.CustomerId}}</td>
                                  <td>{{item?.OrderStatusCode}}</td>
                                  <td>{{item?.OrderStatusName}}</td>
                                  <td>{{item?.OrderStatusDescription}}</td>
                                  <td>{{item?.OrderType}}</td>
  
                                  <td>{{item?.OrderTypeName}}</td>
                                  <td>{{item?.OrderRemarks}}</td>
                                  <td>{{item?.OrderCreatedDate | date : 'MMM d, y'}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="my-3">
                        <div class="row" *ngIf="shipmentList.length ==0">
                          <h6 class="text-center">No data found</h6>
                        </div>
                      </div>
                    </div>
                  </div>
  
  
  
                </div>
              </div>
            </ng-container> -->
  
          </div>
        </div>
        <!-- <ng-container *ngIf="batchReportLoader">
          <app-loader [isChildLoader]="true"></app-loader>
        </ng-container> -->
      </div>
    </div>