import { Injectable } from '@angular/core';
import { Dexie } from 'dexie';
import { DBStores } from './cache';
import {CarouselDTO} from '../../model/carousel.model'

@Injectable({
  providedIn: 'root',
})
export class WirelessAppDatabase extends Dexie {

  public Carousel: Dexie.Table<CarouselDTO, string> = undefined as any;

  _version: number = 1;
  _dbName: string = 'wireless-app-db';

  public constructor() {
    super('wireless-app-db');
    this.setSchemaAndSets();
  }

  setSchemaAndSets() {
    this.version(this._version).stores(this.GetTablesSchema());
    console.log('Database initialized');
    this.Carousel = this.table(DBStores.Carousel.TableName);
  }

  private GetTablesSchema() {
    let tables = {} as any;
    let dbSchema = DBStores as any;
    Object.keys(dbSchema).forEach(function (key, index) {
      tables[dbSchema[key].TableName] = dbSchema[key].Columns;
    });
    return tables;
  }

  async clearDB() {
    console.log('deleting DB...');
    this.close();
    await this.delete();
    await this.open();
    console.log('DB deleted.');
  }
}