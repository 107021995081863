import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  public expiredTokenModalOpen = false; // Flag to track expired token modal state

  constructor() { }

  showLoader() {
    if (this.apiCount < 0) {
      this.apiCount = 0;
    }
    if (this.apiCount === 0 && !this.expiredTokenModalOpen) { // Check modal state before showing loader
      this.isLoadingSubject.next(true);
    }
    this.apiCount++;
  }

  hideLoader() {
    this.apiCount--;
    if (this.apiCount === 0 && !this.expiredTokenModalOpen) { // Check modal state before hiding loader
      this.isLoadingSubject.next(false);
    }
  }

  // Method to handle the expired token modal state
  setExpiredTokenModalOpen(isOpen: boolean) {
    this.expiredTokenModalOpen = isOpen;
    if (isOpen) {
      this.isLoadingSubject.next(false); // Hide loader when the modal is open
    } else if (this.apiCount > 0) {
      this.isLoadingSubject.next(true); // Show loader if there are pending requests
    }
  }
}
