import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import { SearchService } from "../../services/search.service";
import { CommonService } from "../../services/common.service";
import { fadeInOut } from "../../services/app.animation";
import { CustomerProfileService } from "../../services/customer-profile.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { SearchHistoryService } from "../../services/search-history.service";
import { PermissionService } from "../../services/permission-service.service";

interface CustomerResult {
  type: "Customers";
  customerId: number;
  username: string;
  statusId: number;
  enrollNumber: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  enrollmentId: number;
  suffix?: string | null;
  email: string;
  primaryPhone: string;
}

interface OrderResult {
  type: "Orders";
  id: number;
  customerId: number;
  totalAmount: string;
  paymentStatusId: number;
  orderStatusId: number;
  storeId?: number | null;
  orderTypeId: number;
  orderRemarks?: string | null;
  createdDate: string;
  createdBy: number;
  updateDate?: string | null;
  updatedBy?: number | null;
  isExported: number;
  orderStatus: string;
}

interface SubscriptionResult {
  type: "Subscriptions";
  id: number;
  email: string;
  customerId: number;
  profileId: number;
  paymentProfileId: number;
  subscriptionId: number;
  subscriptionTypeId: number;
  status: string;
  remarks?: string | null;
  planId: number;
  nextBillingDate: string;
  nextRenewalDate?: string | null;
  intervalLength?: number | null;
  startDate: string;
  endDate?: string | null;
  autoRenew: number;
  createdDate: string;
  updatedDate: string;
}

interface TicketResult {
  type: "Tickets";
  id: number;
  title: string;
  description?: string | null;
  ticketTypeId: number;
  agentId: number;
  groupId?: number | null;
  customerId: number;
  assigneeId: number;
  departmentId?: number | null;
  statusId: number;
  priorityId: number;
  assignmentType: string;
  createdDate: string;
  createdBy: number;
  dueDate?: string | null;
  updatedDate: string;
  updatedBy: number;
  resolvedBy?: number | null;
  closedBy?: number | null;
  isNotified: number;
  closedDate?: string | null;
  escalationLevel?: number | null;
  parentId?: number | null;
  isCallbackRequired: number;
  callbackDate?: string | null;
  callbackTime?: string | null;
  isActive: number;
  isDeleted: number;
  status: string;
  priority: string;
}

interface PaymentResult {
  type: "Payments";
  id: number;
  amount: number;
  nameOnCard: string;
  responseCode: string;
  tracId: string;
  refId: string;
  customerId: number;
  invoiceId: number;
  invoiceItemId?: number | null;
  authCode: string;
  avsResultCode: string;
  cvvResultCode: string;
  refTransId: string;
  transHash: string;
  restRequest: string;
  accountNumber: string;
  accountType: string;
  message?: string | null;
  messageCode: string;
  quantity: number;
  address: string;
  createdDate: string;
  createdBy: number;
}

interface AddressResult {
  type: "Addresses";
  id: number;
  enrollmentId: number;
  serviceAddress1: string;
  serviceAddress2: string;
  serviceUnit?: string | null;
  cityId: number;
  stateId: number;
  zipId: number;
  mailServiceAddress1: string;
  mailServiceAddress2: string;
  mailCityId: number;
  mailStateId: number;
  mailZipId: number;
  isMailAddrSame: string;
  isPermanent?: string | null;
  billingAddress1: string;
  billingAddress2: string;
  billingZipId: number;
  billingCityId: number;
  billingStateId: number;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingZipId: number;
  shippingCityId: number;
  shippingStateId: number;
  isActive: number;
  isDeleted: number;
  createdDate: string;
  updatedBy?: number | null;
  createdBy?: number | null;
  updatedDate?: string | null;
}

type SearchResult =
  | CustomerResult
  | OrderResult
  | SubscriptionResult
  | TicketResult
  | PaymentResult
  | AddressResult;

@Component({
  selector: "app-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"],
  animations: [fadeInOut],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  searchQuery: string = "";
  recentSearches: any[] = [];
  selectedBadgeId: number | null = 7;
  @Output() closeGlobalSearch = new EventEmitter<void>();
  badges = [
    { id: 1, type: "Customers" ,show:false ,dsc:"customers"},
    { id: 2, type: "Orders" ,show:false ,dsc:"orders"},
    { id: 3, type: "Subscriptions" ,show:false ,dsc:"subscriptions"},
    { id: 4, type: "Tickets" ,show:false ,dsc:"tickets"},
    { id: 5, type: "Payments" ,show:false ,dsc:"payments"}
  ];
  @Input() text;
  searchResults: SearchResult[] = [];
  private searchSubscription!: Subscription;
  isSkeletonLoader: boolean = false;
  noResultFound: boolean = false;
  private currentRequestId = 0;
  private controller: AbortController;
  isRecentSearch: boolean = true;

   orderJsonCodeMap = [
    { code: 'RO_CARD_PENDING_REVIEW_ORDERS', name: 'Pending Review' },
    { code: 'RO_CARD_IN_PROCESS', name: 'Processing' },
    { code: 'RO_CARD_SHIPPED', name: 'Shipped' },
    { code: 'RO_CARD_DELIVERED', name: 'Delivered' },
    { code: 'RO_CARD_CANCELLED', name: 'Cancelled' },
    { code: 'RO_CARD_ON_HOLD', name: 'On Hold' },
    { code: 'RO_CARD_COMPLETED_ORDERS', name: 'Completed' },
    { code: 'RO_CARD_RETURNED', name: 'Returned' },
    { code: 'RO_CARD_REFUNDED', name: 'Refunded' },
    { code: 'RO_CARD_AWAITING_PAYMENT', name: 'Awaiting Payment' },
    { code: 'Payment Received', name: 'Payment Received' },
    { code: 'Payment Failed', name: 'Payment Failed' },
    { code: 'InTransit', name: 'In Transit' },
    { code: 'OP_PENDING_PROVISIONING', name: 'Approved' },
    { code: 'OP_PROVISION_ITEM_ASSIGNED', name: 'Provisioning in progress' },
    { code: 'OP_PROVISIONED', name: 'Completed' },
    { code: 'OP_PROVISION_FAILED', name: 'Failed' },
    { code: 'SH_AWAITING_SHIPMENT', name: 'Awaiting Shipment' },
    { code: 'SH_AWAITING_SHIPMENT_FAILED', name: 'Failed to stage shipment' },
    { code: 'Reviewing', name: 'Reviewing' },
    { code: 'DISPATCHED', name: 'Dispatched' },
    { code: 'RO_CARD_APPROVED_ORDERS', name: 'Approved' },
    { code: 'RO_CARD_REJECTED_ORDERS', name: 'Rejected Orders' },
    { code: 'Payment receive', name: 'Payment Received' },
    { code: 'EQP_NEW_ORDER_CSR', name: 'New Oder CSR' },
    { code: 'STAGING_INITIATED', name: 'Staging Initiated' }
  ];
   orderBadgeGroups = {
    'badge-success': [
      'Delivered',
      'Completed',
      'Approved',
      'Shipped',
      'Payment Received'
    ],
    'badge-danger': [
      'On Hold',
      'Failed',
      'Failed to stage shipment',
      'Rejected Orders',
      'Cancelled'
    ],
    'badge-primary': [
      'Processing',
      'Provisioning in progress',
      'In Transit',
      'Dispatched',
      'Pending Review'
    ],
    'badge-warning': [
      'Escalated',
      'Awaiting Payment',
      'Awaiting Shipment',
      'Reviewing',
      'Staging Initiated'
    ],
    'badge-secondary': [
      'Open',
      'New Oder CSR',
      'Returned',
      'Refunded',
      'Payment Failed'
    ]
  };
  
  constructor(
    private searchService: SearchService,
    public commonService: CommonService,
    private customerProfileService: CustomerProfileService,
    public router: Router,
    private searchHistoryService: SearchHistoryService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.loadRecentSearchHistory();
    this.searchSubscription = this.searchService.searchTerm$.subscribe(
      (term) => {
        this.searchQuery = typeof term === "string" ? term : "";
        console.log("searchQuery: ", this.searchQuery);
        if (this.searchQuery.trim()) {
          this.isSkeletonLoader = true;
          this.onSearchChange();
        } else {
          this.isSkeletonLoader = false;
          this.isRecentSearch = true;
          this.selectedBadgeId = 7;
          this.clearSearchResults();
        }
      }
    );

    this.permissionService.getResourceCategoryById("MISCELLANEOUS").then((res: any) => {
      if (res) {
        const tabs = res?.resource_types[0]?.resources[0]?.children;

        // Check if type and description match
        tabs?.forEach((tab: any) => {
          const matchingBadge:any = this.badges.find((badge) => badge.dsc === tab.Description);
          if (matchingBadge) {
            // Set the 'show' property to true if a match is found
            matchingBadge.show = tab?.resource_permission[0]?.CanView === 1;
          }
        });

        console.log(this.badges); // Updated badges array with matched types
      }
    });
  }

  showRecentSearch() {
    this.selectedBadgeId = 7;
    this.isRecentSearch = true;
    this.clearSearchResults();
  }

  loadRecentSearchHistory(): void {
    this.searchHistoryService.getAllSearchRecords().then((history) => {
      this.recentSearches = history;
      console.log("recentSearches: ", this.recentSearches);
    });
  }


  ngOnDestroy(): void {
    if (this.controller) {
      this.controller.abort();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  startNewRequest() {
    this.currentRequestId++;
    if (this.controller) {
      this.controller.abort();
      this.isSkeletonLoader = true;
    }
    this.controller = new AbortController();
  }
  onClickRecentSearch() {
    this.searchResults = [];
    this.isRecentSearch = true;
    this.selectedBadgeId = 7;
    this.clearSearchResults();
    this.noResultFound = false;
    console.log("recent search: ", this.recentSearches);
  }
  async onSearchChange() {
    if (!this.searchQuery.trim()) {
      this.clearSearchResults();
      return;
    }
    if (this.selectedBadgeId === 7) {
      this.setSearchQuery("customers");
    }

    this.isRecentSearch = false;
    this.startNewRequest();
    const requestId = this.currentRequestId;
    const activeTab = this.badges.find(
      (badge) => badge.id === this.selectedBadgeId
    )?.type;

    if (!this.searchQuery.trim() || !activeTab) {
      this.isSkeletonLoader = false;
      return;
    }

    try {
      const res: any = await this.searchService.globalSearch(
        this.searchQuery.trim(),
        activeTab,
        { signal: this.controller.signal }
      );

      if (this.currentRequestId === requestId && res?.Status) {
        this.searchResults = this.mapDataToResults(res.data, activeTab);
        this.noResultFound = this.searchResults.length === 0;
      } else {
        console.log("Stale response discarded");
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", requestId);
      } else {
        this.handleError(error, "Failed to fetch search results");
      }
    } finally {
      if (this.currentRequestId === requestId) {
        this.isSkeletonLoader = false;
      }
    }
  }

  private mapDataToResults(data: any, type: string): SearchResult[] {
    switch (type) {
      case "Customers":
        return (
          data.customers?.map(
            (customer: any): CustomerResult => ({
              type: "Customers",
              customerId: customer.CustomerId,
              username: customer.Username,
              statusId: customer.StatusID,
              enrollNumber: customer.EnrollNumber,
              firstName: customer.FirstName,
              middleName: customer.MiddleName,
              lastName: customer.LastName,
              enrollmentId: customer.EnrollmentID,
              suffix: customer.Suffix,
              email: customer.Email,
              primaryPhone: customer.PrimaryPhone,
            })
          ) || []
        );
      case "Orders":
        return (
          data.orders?.map(
            (order: any): OrderResult => ({
              type: "Orders",
              id: order.id,
              customerId: order.CustomerId,
              totalAmount: order.TotalAmount,
              paymentStatusId: order.PaymentStatusId,
              orderStatusId: order.OrderStatusId,
              storeId: order.StoreID,
              orderTypeId: order.OrderTypeID,
              orderRemarks: order.OrderRemarks,
              createdDate: order.CreatedDate,
              createdBy: order.CreatedBy,
              updateDate: order.UpdateDate,
              updatedBy: order.UpdatedBy,
              isExported: order.IsExported,
              orderStatus: this.orderJsonCodeMap.find((status) => status.code === order.OrderStatus)?.name || order.OrderStatus,
            })
          ) || []
        );
      case "Subscriptions":
        return (
          data.subscriptions?.map(
            (subscription: any): SubscriptionResult => ({
              type: "Subscriptions",
              id: subscription.id,
              email: subscription.Email,
              customerId: subscription.CustomerId,
              profileId: subscription.ProfileId,
              paymentProfileId: subscription.PaymentProfileId,
              subscriptionId: subscription.SubscriptionId,
              subscriptionTypeId: subscription.SubscriptionTypeId,
              status: subscription.Status,
              remarks: subscription.Remarks,
              planId: subscription.PlanId,
              nextBillingDate: subscription.NextBillingDate,
              nextRenewalDate: subscription.NextRenewalDate,
              intervalLength: subscription.IntervalLength,
              startDate: subscription.StartDate,
              endDate: subscription.EndDate,
              autoRenew: subscription.AutoRenew,
              createdDate: subscription.CreatedDate,
              updatedDate: subscription.UpdatedDate,
            })
          ) || []
        );
      case "Tickets":
        return (
          data.tickets?.map(
            (ticket: any): TicketResult => ({
              type: "Tickets",
              id: ticket.id,
              title: ticket.Title,
              description: ticket.Description,
              ticketTypeId: ticket.TicketTypeID,
              agentId: ticket.AgentID,
              groupId: ticket.GroupID,
              customerId: ticket.CustomerID,
              assigneeId: ticket.AssigneeID,
              departmentId: ticket.DepartmentID,
              statusId: ticket.StatusID,
              priorityId: ticket.PriorityId,
              assignmentType: ticket.AssignmentType,
              createdDate: ticket.CreatedDate,
              createdBy: ticket.CreatedBy,
              dueDate: ticket.DueDate,
              updatedDate: ticket.UpdatedDate,
              updatedBy: ticket.UpdatedBy,
              resolvedBy: ticket.ResolvedBy,
              closedBy: ticket.ClosedBy,
              isNotified: ticket.IsNotified,
              closedDate: ticket.ClosedDate,
              escalationLevel: ticket.EscalationLevel,
              parentId: ticket.ParentId,
              isCallbackRequired: ticket.IsCallbackRequired,
              callbackDate: ticket.CallbackDate,
              callbackTime: ticket.CallbackTime,
              isActive: ticket.IsActive,
              isDeleted: ticket.IsDeleted,
              status: ticket.Status,
              priority: ticket.Priority,
            })
          ) || []
        );
      case "Payments":
        return (
          data.payments?.map(
            (payment: any): PaymentResult => ({
              type: "Payments",
              id: payment.id,
              amount: payment.Amount,
              nameOnCard: payment.NameOnCard,
              responseCode: payment.ResponseCode,
              tracId: payment.TracID,
              refId: payment.RefID,
              customerId: payment.CustomerID,
              invoiceId: payment.InvoiceID,
              invoiceItemId: payment.InvoiceItemID,
              authCode: payment.AuthCode,
              avsResultCode: payment.AvsResultCode,
              cvvResultCode: payment.CvvResultCode,
              refTransId: payment.RefTransID,
              transHash: payment.TransHash,
              restRequest: payment.RestRequest,
              accountNumber: payment.AccountNumber,
              accountType: payment.AccountType,
              message: payment.Message,
              messageCode: payment.MessageCode,
              quantity: payment.Quantity,
              address: payment.Address,
              createdDate: payment.CreatedDate,
              createdBy: payment.CreatedBy,
            })
          ) || []
        );

      case "addresses":
        return (
          data.addresses?.map(
            (address: any): AddressResult => ({
              type: "Addresses",
              id: address.id,
              enrollmentId: address.EnrollmentID,
              serviceAddress1: address.ServiceAddress1,
              serviceAddress2: address.ServiceAddress2,
              serviceUnit: address.ServiceUnit,
              cityId: address.CityID,
              stateId: address.StateID,
              zipId: address.ZipID,
              mailServiceAddress1: address.MailServiceAddress1,
              mailServiceAddress2: address.MailServiceAddress2,
              mailCityId: address.MailCityID,
              mailStateId: address.MailStateID,
              mailZipId: address.MailZipID,
              isMailAddrSame: address.isMailAddrSame,
              isPermanent: address.isPermanent,
              billingAddress1: address.BillingAddress1,
              billingAddress2: address.BillingAddress2,
              billingZipId: address.BillingZipID,
              billingCityId: address.BillingCityID,
              billingStateId: address.BillingStateID,
              shippingAddress1: address.ShippingAddress1,
              shippingAddress2: address.ShippingAddress2,
              shippingZipId: address.ShippingZipID,
              shippingCityId: address.ShippingCityID,
              shippingStateId: address.ShippingStateID,
              isActive: address.IsActive,
              isDeleted: address.IsDeleted,
              createdDate: address.CreatedDate,
              updatedBy: address.UpdatedBy,
              createdBy: address.CreatedBy,
              updatedDate: address.UpdatedDate,
            })
          ) || []
        );
      default:
        return [];
    }
  }

  triggerClose() {
    this.closeGlobalSearch.emit();
  }

  async onResultClick(result: SearchResult) {
    console.log("SearchResult: ", this.searchResults);
    console.log("recentSearches: ", this.recentSearches);
    const activeTab = this.badges.find(
      (badge) => badge.id === this.selectedBadgeId
    )?.type;
    this.searchHistoryService.saveSearchRecord(
      this.searchQuery,
      activeTab,
      result
    );
    switch (result.type) {
      case "Customers":
        await this.handleCustomerClick(result as CustomerResult).catch((err) =>
          this.handleError(err)
        );
        break;
      case "Orders":
        await this.handleOrderClick(result as OrderResult).catch((err) =>
          this.handleError(err)
        );
        break;
      case "Subscriptions":
        await this.handleSubscriptionClick(result as SubscriptionResult).catch(
          (err) => this.handleError(err)
        );
        break;
      case "Tickets":
        await this.handleTicketClick(result as TicketResult).catch((err) =>
          this.handleError(err)
        );
        break;
      case "Payments":
        await this.handlePaymentClick(result as PaymentResult).catch((err) =>
          this.handleError(err)
        );
        break;
      case "Addresses":
        await this.handleAddressClick(result as AddressResult).catch((err) =>
          this.handleError(err)
        );
        break;
      default:
        console.log("Unknown type");
        break;
    }
  }

  private async handleCustomerClick(result: CustomerResult) {
    const customerData = { search: result.customerId, page: "1" };
    const customerResponse: any =
      await this.searchService.searchCustomer(customerData);

    if (customerResponse?.Status) {
      this.searchService.setSearchData(customerResponse.data);
      await this.customerProfileService.setCustomerId(
        customerResponse.data.data[0]?.CustomerID
      );
      this.customerProfileService.getMinimalProfileData();
      this.searchService.updateSearchTerm(
        customerResponse.data.data[0]?.CustomerID
      );
      this.router.navigateByUrl("/customer-dashboard/customer-profile");
      this.closeGlobalSearch.emit();
    } else {
      this.commonService.showToast("error", "Error", customerResponse.message);
    }
  }

  private async handleOrderClick(result: OrderResult) {
    const order = { orderId: result.id, customerId: result.customerId };
    this.goToDetails(order);
    this.clearSearchResults();
    this.closeGlobalSearch.emit();
  }

  private async handleSubscriptionClick(result: SubscriptionResult) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/subscriptions"], {
          queryParams: {
            subscriptionId: result.subscriptionId,
            isGlobalLoader: true,
          },
        });
      });
    this.clearSearchResults();
    this.closeGlobalSearch.emit();
  }

  private async handlePaymentClick(result: PaymentResult) {
    console.log("PaymentResult : ", result);
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/transaction/detail"], {
          state: { transactionData: result },
          queryParams: { isGlobalSearch: "true" }, // Explicitly pass 'true' as a string
        });
      });
    this.clearSearchResults();
    this.closeGlobalSearch.emit();
  }

  private async handleAddressClick(result: AddressResult) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/ticket-system"], {
          queryParams: { ticketId: result.id, isGlobalLoader: true },
        });
      });
    this.clearSearchResults();
    this.closeGlobalSearch.emit();
  }

  private async handleTicketClick(result: TicketResult) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/ticket-system"], {
          queryParams: { ticketId: result.id, isGlobalLoader: true },
        });
      });
    this.clearSearchResults();
    this.closeGlobalSearch.emit();
  }
  private handleError(
    error: any,
    fallbackMessage: string = "An error occurred"
  ) {
    if (error?.Status === false) {
      this.commonService.showToast(
        "error",
        "Error",
        error.message || fallbackMessage
      );
    } else if (error instanceof HttpErrorResponse) {
      let message = `Error ${error.status}: ${error.statusText}`;
      console.log("message: ", message);
      this.commonService.showToast(
        "error",
        "Error",
        message || fallbackMessage
      );
    } else {
      this.commonService.showToast("error", "Error", fallbackMessage);
    }
    this.searchService.updateSearchTerm("");
    this.closeGlobalSearch.emit();
  }

  goToDetails(order: any) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(
          [`/awaiting-shipment/order-details/${order.orderId}`],
          {
            queryParams: { customerid: order.customerId },
            state: { order },
          }
        );
      });
  }

  setSearchQuery(type: string) {
    const badge = this.badges.find(
      (badge) => badge.type === type && badge.id !== 7
    );
    if (badge) {
      this.selectedBadgeId = badge.id;
      this.isRecentSearch = false;
      this.onSearchChange().catch((err) => this.handleError(err));
    }
  }

  clearSearchResults() {
    this.searchResults = [];
  }
  colorClasses = [
    "blue",
    "purple",
    "yellow",
    "green",
    "pink",
    "orange",
    "red",
    "teal",
  ];

  getColorClassForType(index: number): string {
    return this.colorClasses[index % this.colorClasses.length];
  }

  hasSearchResults(): boolean {
    return this.searchResults && this.searchResults.length > 0;
  }

  public handleResultClick(
    id: number | { id: number; tracId: string },
    tabName: string,
    customerId?: number,
    searchTerm?: string
  ) {
    const searchQuery = this.searchQuery;
    let result;
    switch (tabName.toLowerCase()) {
      case "customers":
        this.handleRecentCustomerClick(id as number, tabName);
        result = { id };
        break;
      case "orders":
        this.handleRecentOrderClick(id as number, tabName, customerId);
        result = { id };
        break;
      case "subscriptions":
        this.handleRecentSubscriptionClick(id as number, tabName);
        result = { id };
        break;
      case "tickets":
        this.handleRecentTicketClick(id as number, tabName);
        result = { id };
        break;
      case "payments":
        if (typeof id === "object" && id.id && id.tracId) {
          this.handleRecentPaymentClick({
            id: id.id,
            tracId: id.tracId,
          }).catch((err) => this.handleError(err));
          result = { id: id.id, tracId: id.tracId };
        } else {
          console.error(
            "Invalid payment data passed to handleResultClick:",
            id
          );
        }
        break;
      default:
        console.log(`Unknown tab name: ${tabName}`);
        break;
    }
    if (result) {
      this.searchHistoryService.saveSearchRecord(searchTerm, tabName, result);
      console.log("Search record saved:", { searchQuery, tabName, result });
    }
  }

  private async handleRecentPaymentClick(transactionData: {
    id: number;
    tracId: string;
  }) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/transaction/detail"], {
          state: { transactionData }, // Pass the id and tracId as transactionData
          queryParams: { isGlobalSearch: "true" }, // Explicitly pass 'true' as a string
        });
      });
    this.clearSearchResults();
    this.closeGlobalSearch.emit();
  }

  private async handleRecentCustomerClick(id: number, tabName: string) {
    try {
      const customerData = { search: id, page: "1" };
      console.log("customerData:", customerData);
      const customerResponse: any =
        await this.searchService.searchCustomer(customerData);

      if (customerResponse?.Status) {
        this.searchService.setSearchData(customerResponse.data);
        await this.customerProfileService.setCustomerId(
          customerResponse.data.data[0]?.CustomerID
        );
        this.customerProfileService.getMinimalProfileData();
        this.router.navigateByUrl("/customer-dashboard/customer-profile");
        this.closeGlobalSearch.emit();
      } else {
        this.commonService.showToast(
          "error",
          `Error in ${tabName}`,
          customerResponse.message
        );
      }
    } catch (error) {
      this.commonService.showToast(
        "error",
        `Error in ${tabName}`,
        "Failed to load customer details"
      );
    }
  }

  private async handleRecentOrderClick(
    id: number,
    tabName: string,
    customerId: number
  ) {
    try {
      const order = { orderId: id, customerId: customerId };
      this.goToDetails(order);
      this.clearSearchResults();
      this.closeGlobalSearch.emit();
    } catch (error) {
      this.commonService.showToast(
        "error",
        `Error in ${tabName}`,
        "Failed to load order details"
      );
    }
  }

  private async handleRecentSubscriptionClick(id: number, tabName: string) {
    try {
      this.router
        .navigateByUrl("/reload", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["/subscriptions"], {
            queryParams: { subscriptionId: id, isGlobalLoader: true },
          });
        });
      this.clearSearchResults();
      this.closeGlobalSearch.emit();
    } catch (error) {
      this.commonService.showToast(
        "error",
        `Error in ${tabName}`,
        "Failed to load subscription details"
      );
    }
  }

  private async handleRecentTicketClick(id: number, tabName: string) {
    try {
      this.router
        .navigateByUrl("/reload", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["/ticket-system"], {
            queryParams: { ticketId: id, isGlobalLoader: true },
          });
        });
      this.clearSearchResults();
      this.closeGlobalSearch.emit();
    } catch (error) {
      this.commonService.showToast(
        "error",
        `Error in ${tabName}`,
        "Failed to load ticket details"
      );
    }
  }
}
