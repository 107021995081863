import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { CustomerSettingService } from 'src/app/shared/services/customer-settings';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { PermissionService } from 'src/app/shared/services/permission-service.service';

@Injectable({
  providedIn: 'root'
})
export class ManageAgentService {
  userId: string;
  companyId: string;
  seetingsPermission:any
  
  public sideMenubarSub = new BehaviorSubject<any[]>([]);
  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
    private customerProfile:CustomerSettingService,
    private permissionService:PermissionService,
  ) {
  
  }


  getUser() {
    return this.userDetails;
  }


  saveAgent(obj) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }

  getAgentList() {
    let body = {
      companyId: "UNITYCRM0021",
      
    }
    let url = "api/system/agents-list"
    return this.http.get(url, body)
  }
  getAgentList1(data) {
    let body = {
      companyId: "UNITYCRM0021",
      ...data
    }
    let url = "api/system/agents-list"
    return this.http.get(url, body)
  }
  getAgentListNopage() {
    let body = {
      companyId: "UNITYCRM0021",
    }
    let url = "api/system/agents-list-no-page"
    return this.http.get(url, body)
  }

  updateAgent(obj: any) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }
  CreateAgent(obj: any) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }
  updateAgent1(obj: any,id:any) {
    let url = "api/system/update-agent/"+id
    return this.http.put(url, obj)
  }
  getDistributorList(id) {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/distributors/" + id
    return this.http.get(url, obj)
  }
  getDistributorList1() {

    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/distributors"
    return this.http.get(url, obj)
  }
  getRetailers() {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/retailers"
    return this.http.get(url, obj)
  }

  getAllResourcesNOpage(data) {
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      ...data
    }
    let url = "api/system/resources"
    return this.http.get(url, obj)
  }

  getAllResources(data) {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
      ...data
    }
    let url = "api/system/resources"
    return this.http.get(url, obj)
  }
  getAllResourcesType() {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/getResourceTypes"
    return this.http.get(url, obj)
  }
  getAllResourceType() {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID,
    
    }
    let url = "api/system/getResourceTypes"
    return this.http.get(url, obj)
  }
  getAllCategory() {
    //  console.log(JSON.parse(localStorage.getItem('userDetails')),"detailss<<<<<<<<<<>>>>>>>>>>>>>.")
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/getResourceCategories"
    return this.http.get(url, obj)
  }
  getRetailersList(obj) {
    let url = "api/system/create-agent"
    return this.http.post(url, obj)
  }

  getMasterAgent() {
    let obj = {
      companyId: "UNITYCRM0021",
      userID: this.getUser().userID
    }
    let url = "api/system/masteragent"
    return this.http.get(url, obj)
  }

  getCities() {
    let url = `api/system/getCities`;
    return this.http.get(url)

  }

 createNewRes(obj) {
    let url = "api/system/resource/create"
    return this.http.post(url, obj)
  }
  oneResouces(id: any) {
    let url = `api/system/resource/show/${id}`
  
    return this.http.get(url)
  }
  updateResoure(id: any,body) {
    let url = `api/system/resource/update/${id}`
    return this.http.post(url,body)
  }
  updateCateGory(id: any,body) {
    let url = `api/system/update-resource-category/${id}`
    return this.http.put(url,body)
  }
  createNewResType(obj) {
    let url = "api/system/create-resource-type"
    return this.http.post(url, obj)
  }
  deleteResource(id) {
    try {
      let url = "api/system/resource/delete/" + id
      return this.http.delete(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  showresourceType(id){
    let url = `api/system/getResourceTypes`
    let body = {
      typeId:id
    }
    return this.http.get(url,body)
  }
  showCategoryType(id){
    let url = `api/system/getResourceCategories`
    let body = {
      categoryId :id
    }
    return this.http.get(url,body)
  }
  updateResoureType(id: any,body) {
    let url = `api/system/update-resource-type/${id}`
    return this.http.put(url,body)
  }
  oneResoucesGet(id: any) {
    let url = `api/system/update-resource-type/${id}`
  
    return this.http.put(url)
  }

  deleteResourceType(id) {
    try {
      let url = "api/system/delete-resource-type/" + id
      return this.http.delete(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  createCategory(obj) {
    let url = "api/system/create-resource-category"
    return this.http.post(url, obj)
  }
  deleteCategory(id) {
    try {
      let url = "api/system/delete-resource-category/" + id
      return this.http.delete(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async sideMenuInitilize() {
    try {
      const res = await  this.permissionService.getResourceCategoryById('SYSTEM_SETTINGS') as any;
      const data = res;
        const resourceTypes = data?.resource_types;
        if (Array.isArray(resourceTypes) && resourceTypes.length > 0) {
          this.seetingsPermission = resourceTypes[0]?.resources || [];
          const mappedMenuItems = this.mapedMenuItems();
          this.sideMenubarSub.next(mappedMenuItems);
      }
    } catch (error) {
      console.error("Error occurred while fetching menu types:", error);
    }
  }
  

mapedMenuItems() {
  return this.seetingsPermission.map(el => {
    let tempData = {}
    let lastIndex = el.resource_permission.length - 1;
    let isHidden = el.resource_permission[0]?.CanView || 0;
    if (el.children.length == 0) {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),
        // isHidden: convertToBoolean(this.getPermission(el)),

        isHidden,
        // isHidden: convertToBoolean(el.HideMenu),

        icon: el.NavIconClass,
        type: "link",
        badgeType: "light-success",
        path: el.UrlRoute
      }


    } else {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),

        isHidden,

        icon: el.NavIconClass,
        type: "sub",
        badgeType: "light-success",
        path: el.UrlRoute,
        badgeValue: el.children?.length,
        children: el.children.map(el2 => {
          return {
            path: el2.UrlRoute, title: el2.DisplayName, type: "link"
          }
        })
      }
    }

    return tempData
  })
}

getSideMenuBar() {
  return this.sideMenubarSub.asObservable();
}

}


