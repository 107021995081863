import { Component, OnInit } from '@angular/core';
import { ActivityLogService } from './services/activity-log.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  public active1 = 1;

  data: any;

  constructor(
    public activityLogService: ActivityLogService,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
    this.getData()
  }

  async getData() {
    try {
      const res: any = await this.activityLogService.getActivityInfo()
      if (res.Status) {
        this.data = res?.data?.data
        console.log('Customer Events', this.data);
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }
}
