import { Component } from '@angular/core';

@Component({
  selector: 'app-plan-detail-skeleton',
  templateUrl: './plan-detail-skeleton.component.html',
  styleUrls: ['./plan-detail-skeleton.component.scss']
})
export class PlanDetailSkeletonComponent {

}
