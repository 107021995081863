<form [formGroup]="formData" class="w-100">
    <div class="row">
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Title <span class="text-danger">*</span></label>
                <input type="text" formControlName="Title" placeholder="Title" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Ticket Type <span class="text-danger">*</span></label>

                <ng-select bindLabel="Name" bindValue="id" [items]="ticketType" formControlName="TicketTypeID"
                    placeholder="Select Ticket Type">
                </ng-select>

                <!-- <select class="form-control" formControlName="TicketTypeID">
                    <option value="">Select Ticket Type</option>
                    <option *ngFor="let ticket of ticketType" [value]="ticket.id">{{ticket.Name}}</option>
                </select> -->
            </div>
        </div>
        <div class="col-12 my-2">
            <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea formControlName="Description" maxlength="2000" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Customer ID <span class="text-danger">*</span></label>
                <input type="text" formControlName="CustomerId" placeholder="Customer ID" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Customer Name <span class="text-danger">*</span></label>
                <input type="text" formControlName="CustomerName" placeholder="Customer Name" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Contact Number</label>
                <input type="number" formControlName="ContactNumber" placeholder="Contact Number" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Alternate Number</label>
                <input type="number" formControlName="AlternateNumber" placeholder="Alternate Number"
                    class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Status <span class="text-danger">*</span></label>
                <ng-select bindLabel="Name" bindValue="id" [items]="status" formControlName="StatusID"
                    placeholder="Select Status">
                </ng-select>
            </div>
        </div>
        <div class="col-3 my-2">
            <div class="form-group">
                <label>Assign Ticket To <span class="text-danger">*</span></label>
                <div>
                    <div class="form-check form-check-inline">
                        <input (change)="onAssignmentTypeChange()" class="form-check-input" type="radio"
                            formControlName="AssignmentType" id="inlineRadio1" value="AGENT">
                        <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio1">User</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input (change)="onAssignmentTypeChange()" class="form-check-input" type="radio"
                            formControlName="AssignmentType" id="inlineRadio2" value="GROUP">
                        <label class="form-check-label mb-0 mt-1 ms-1" for="inlineRadio2">Group</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 my-2">
            <div class="form-group" *ngIf="getAssignmentType.value == 'AGENT'; else groupSelectTemplate">
                <label>User List <span class="text-danger">*</span></label><br>
                <ng-select bindLabel="name" bindValue="id" [items]="userList" formControlName="AgentID"
                    placeholder="Select User">
                </ng-select>

            </div>
            <ng-template #groupSelectTemplate>
                <div class="form-group">
                    <label>Group List <span class="text-danger">*</span></label><br>
                    <ng-select [items]="groupList" bindLabel="Name" bindValue="id" formControlName="GroupID"
                        placeholder="Select Group">
                    </ng-select>


                </div>
            </ng-template>
        </div>
        <div class="col-12 my-2">
            <label>Upload Files</label><br>
            <input type="file" class="form-control" (change)="onFileChange($event)" multiple>
        </div>
        <div class="col-12 my-2" *ngIf="uploadedFiles.length > 0">
            <h5 class="headerTitle mb-0">Selected Files:</h5>
            <div class="row my-2" *ngFor="let file of uploadedFiles">
                <div class="col-4">
                    {{ file.name }}
                </div>
                <div class="col-4">
                    <button class="btn btn-danger" (click)="removeFile(file)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="p-3">
                <div class="text-center mt-3">
                    <button type="button" [disabled]="formData.invalid" (click)="onSubmit()" class="btn btn-primary">
                        Submit</button>
                </div>
            </div>
        </div>
    </div>
</form>


