<div class="container-fluid p-0" >
    <!-- Flex container to align cards based on the row or column -->
    <div [ngClass]="{
          'd-flex flex-row flex-wrap justify-content-center align-items-center': alignment === 'row',
          'd-flex flex-column align-items-center': alignment === 'column'
        }" class="w-100 gap-3 row" >
      
      <!-- Generate the skeleton cards -->
      <div *ngFor="let card of cardArray"
           class="skeleton-card m-2"
           [ngStyle]="{'width': width, 'height': height}"> 
      </div>
    </div>
  </div>
  