<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="form-group row"> 
      <div class="col-md-3">
        <label>Dealer Wallet Amount</label>
      </div>
      <div class="col-md-9" >
        <input type="text" class="form-control">
      </div>
    </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Customer Name</label>
    </div>
    <div class="col-md-9" >
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Phone </label>
    </div>
    <div class="col-md-9">
      <input type="number" class="form-control">
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Change Plan to </label>
    </div>
    <div class="col-md-9" >
      <input type="number" class="form-control">
      <div class="d-flex align-items-center text-dark">
        <span>
          <app-feather-icons [icon]="'plus'" class="mt-1"></app-feather-icons> 
          Add new topup plan
        </span>
      </div>
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Existing Plan Expiry Date </label>
    </div>
    <div class="col-md-9">
      <span class="text-dark">2023/12/12</span>
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Plan Change Mode  </label>
    </div>
    <div class="col-md-3">
      <input type="radio" name="plan" class="me-2">
      <label class="mb-0">Instant</label></div>
      <div class="col-md-3">
      <input type="radio" name="plan" class="me-2">
      <label class="mb-0">On Expiry</label></div>
      <div class="col-md-3">
      <input type="radio" name="plan" class="me-2">
      <label class="mb-0">Custom Date</label>
    </div>
    <div class="col-md-3"></div>
    <div class="col-md-9">
      <input type="checkbox" class="me-2" >
      <label class="mb-0 checkbox-label">Keep the expiry an original date</label>
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Total Amount</label>
    </div>
    <div class="col-md-9">
      <input type="number" class="form-control">
    </div>
  </div>
 
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Receipt Number</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>

 
  <div class="form-group row"> 
    <div class="col-md-12 text-end">
    <button type="button" class="btn btn-primary"> Submit</button>
  </div>
  </div>
  </form>