import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './httpService.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filterSubject = new BehaviorSubject<any>({});
  filters$ = this.filterSubject.asObservable(); // Observable for components to subscribe to
  private apiDataSubject = new BehaviorSubject<any>(null); // Default value is null
  apiData$ = this.apiDataSubject.asObservable(); 
  constructor(
    private http:HttpService

  ){
  }
  // Method to update the filters
  updateFilters(filters: any) {
    this.filterSubject.next(filters);
  }

  formatDateToMMDDYYYY(date: Date): any {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  private isFirstTime: boolean = true;
  private isFirstResponse = true; 
  private loaderSubject = new BehaviorSubject<boolean>(false); // Loader status
  loader$ = this.loaderSubject.asObservable();
  fetchExecutiveDashboard(stats: { cmds: string[]; types: string[]; frequency: string }, year?: number, month?: string) {
    const url = `api/dashboard/executiveDashboard`;
  
    const body = {
      cmds: stats.cmds || [],        // Default to an empty array if not provided
      types: stats.types || [],      // Default to an empty array if not provided
      frequency: stats.frequency || "YEAR", // Default to "MONTH" if not provided
      ...(year && { year }), // Include year if provided
      ...(month && { month }) // Include month if provided
    };
  
    return this.http.post(url, body).toPromise().then((response) => {

      this.apiDataSubject.next(response); // Emit the data
      return response; // Return the response to allow chaining
    });
  }

  fetchExecutiveStats(stats: { cmds: string[]; types: string[]; frequency: string }) {
    const url = `api/dashboard/executiveDashboard`;
  
    const body = {
      cmds: stats.cmds || [],        // Default to an empty array if not provided
      types: stats.types || [],      // Default to an empty array if not provided
      frequency: stats.frequency || "YEAR", // Default to "MONTH" if not provided
    };
    if (this.isFirstResponse) {
      this.loaderSubject.next(false); // Emit loader completion status
      this.isFirstResponse = false; // Disable future emissions
    }
  
    return this.http.post(url, body).toPromise();
  }
  
  
}

