import { Component } from '@angular/core';

@Component({
  selector: 'app-money-gram-renew',
  templateUrl: './money-gram-renew.component.html',
  styleUrls: ['./money-gram-renew.component.scss']
})
export class MoneyGramRenewComponent {

}
