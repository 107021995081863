<div  class="d-flex justify-content-between">
  <p (click)="toggleMoreInf();moreInfoSelectedTab('Line Information')"><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{manageUserDetails?.DisplayName}}</p>
  <p><i class="fa-regular fa-circle-question hover-effect hover-effect"
    [attr.title]="manageUserDetails?.Description"
    data-bs-toggle="popover" 
    data-bs-trigger="hover" 
    data-bs-class="custom-popover"
    data-bs-placement="top"
   ></i></p>
  </div>

 <div class="customizer-contain" [ngClass]="{ open: moreInfo == true }" [ngStyle]="{ 'width': popup1Width }">
  <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
          <h5 class="mb-0">User Informations</h5>
          <i class="icofont-close icon-close" (click)="toggleMoreInf()"></i>
      </div>

      

    
      <div class="customizer-body" style="max-height: 400px; overflow-y: auto;">
        <div class="tab-pane fade" [ngClass]="{ 'active show': moreInfo }" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
          <div class="notes-panel" [ngClass]="{'open-notes': true}">
            <div class="wrapper d-flex bg-white">
              <div class="right-panel w-100">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <div class="table-responsive marginB2" style="overflow: scroll;">
                        <div class="head d-flex justify-content-between align-items-center">
                        <div class="search-result" style="margin-bottom: 5px;">Search Results : {{totalusers}}</div>
                          <div class="customizer-header">
                            <div class="row">
                              <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Search" (keyup)="filterdData($event)">
                              </div>
                              <div class="col-md-6">
                                <button class="btn btn-primary" (click)="toggleMoreInf1();moreInfoSelectedTab1('Line Information')">+ Add</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <ag-grid-angular 
                          style=" height: 500px; width: 100%;" 
                          class="ag-theme-alpine"
                          [gridOptions]="gridOptions"
                          [rowData]="userList" 
                          [columnDefs]="columnDefs"
                          [defaultColDef]="defaultColDef" 
                          [rowHeight]="50" 
                          [pagination]="false"
                          [paginationPageSize]="itemsPerPage"
                          [rowSelection]="'multiple'"
                          [suppressRowHoverHighlight]="true" [animateRows]="true"
                          (selectionChanged)="onSelectionChanged($event)">
                        </ag-grid-angular>
                        
                        <div class="pull-right p-20 d-flex">
                          <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage" (ngModelChange)="onChangeNumberOfPage();">
                            <option *ngFor="let page of pageDropdownMenu" [ngValue]="page">{{page}}</option>
                          </select>
                          
                          <ngb-pagination 
                            [directionLinks]="true" 
                            [boundaryLinks]="true" 
                            [collectionSize]="totalItems" 
                            [(page)]="currentPage"
                            (pageChange)="pageChange($event)"
                            [maxSize]="itemsPerPage" 
                            [pageSize]="itemsPerPage">
                            <ng-template ngbPaginationPrevious>Previous</ng-template>
                            <ng-template ngbPaginationNext>Next</ng-template>
                          </ngb-pagination>
                        </div>
                        
                      </div>
                      
                      <br><br>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
  </div>
</div>




<!-- ////////////////////////////////////// -->

<div class="customizer-contain" [ngClass]="{ open: moreInfo1 == true }" [ngStyle]="{ 'width': popup2Width }">
  <div class="tab-content" id="c-pills-tabContent">

      <div class="customizer-header">
          <h5 *ngIf="!editId" class="mb-0">Add roles and data restrictions </h5>
          <h5  *ngIf="editId" class="mb-0">Edit roles and data restrictions </h5>
          <i class="icofont-close icon-close" (click)="toggleMoreInf1()"></i>
      </div>

      <div class="customizer-body" style="max-height: 400px; overflow-y: auto;">
          <div class="tab-pane fade" [ngClass]="{ 'active show': moreInfo1}" id="c-pills-home" role="tabpanel"
              aria-labelledby="c-pills-home-tab">
              <div class="notes-panel" [ngClass]="{'open-notes': true}">
                  <div class="wrapper d-flex bg-white">
                      <div class="right-panel w-100">
                          <div class="container-fluid">
                            <form [formGroup]="addAgentForm">
                              <div class="row d-flex justify-content-center">
                                 <div class="col-md-10">
                                     <div class="card">
                                        <div class="card-body">
                                          <div class="row">
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Full Name</label>
                                                <input class="form-control" type="text" placeholder="Enter full name" formControlName="fullName">
                                               </div>

                                               <div class="text-danger" *ngIf="addAgentForm?.get('fullName')?.hasError('required') && addAgentForm?.get('fullName')?.touched">
                                                Full Name is required.
                                              </div>
                                            </div>

                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label>Email Address</label>
                                                <input class="text-danger" class="form-control" type="text" placeholder="Enter email address" formControlName="email">
                                               </div>

                                               <div class="text-danger" *ngIf="addAgentForm?.get('email')?.hasError('required') && addAgentForm?.get('email')?.touched">
                                                Email is required.
                                              </div>
                                              <div  class="text-danger" *ngIf="addAgentForm?.get('email')?.hasError('email') && addAgentForm?.get('email')?.touched">
                                                Please enter a valid email address.
                                              </div>
                                            </div>
                                          
                                          </div>
                                          <div class="row mt-2">
          
                                            <div class="col-md-6" *ngIf="!editId">
                                              <div class="form-group">
                                                <label>User Name</label>
                                                <input class="form-control" type="text" placeholder="Enter user name" formControlName="userName" (change)="userNameVerify($event)">
                                              </div>
                                              <div class="text-danger" *ngIf="addAgentForm?.get('userName')?.hasError('required') && addAgentForm?.get('userName')?.touched">
                                                User Name is required.
                                              </div>
                                              <div class="text-danger" *ngIf="addAgentForm?.get('userName')?.hasError('pattern') && addAgentForm?.get('userName')?.touched">
                                                User Name must be alphanumeric and at least 8 characters long.
                                              </div>
                                              <div [hidden]="check"
                                                   [ngClass]="{'green-text': checkDuplicateUser ==='User not registered', 'red-text': checkDuplicateUser === 'User is already registered'}">{{checkDuplicateUser}}</div>
                                            </div>
                                            

                                            <div class="col-md-6" *ngIf="!editId">
                                            <div class="form-group">
                                             <label class="col-form-label">Password</label>
                                              <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                                             <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                             <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                             <div class="text-danger" *ngIf="addAgentForm?.get('password')?.hasError('required') && addAgentForm?.get('password')?.touched">
                                              Password is required.
                                            </div>
                                            <div class="text-danger" *ngIf="addAgentForm?.get('password')?.hasError('pattern') && addAgentForm?.get('password')?.touched">
                                              Password must be alphanumeric and at least 8 characters long.
                                            </div>
                                              </div>
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleSelect" class="form-label">Select User Status</label>
                                              <select class="form-select" id="exampleSelect" formControlName="user_type">
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>
                                              </select>

                                              <div class="text-danger" *ngIf="addAgentForm?.get('user_type')?.hasError('required') && addAgentForm?.get('user_type')?.touched">
                                                User Type is required.
                                              </div>

                                        </div>

                                          </div>

                                          <!-- <div class="row mt-3">
                                            <div class="col-md-10">
                                              <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="exampleCheckbox" formControlName="newUserEmail">&nbsp;&nbsp;
                                                <div  class="text-danger" *ngIf="addAgentForm?.get('newUserEmail')?.hasError('required') && addAgentForm?.get('newUserEmail')?.touched">
                                                  Notify new users by email
                                                  is required.
                                                 </div>
                                                <label class="form-check-label" for="exampleCheckbox" >
                                                  Notify new users by email
                                                </label>
                                              </div>
                                            </div>
                                          </div> -->
                                        </div>
                                      </div>   
                                 </div>
                              </div>

                              <div class="row d-flex justify-content-center">
                                 <div class="col-md-10">
                                     <div class="card">
                                        <div class="card-body">
                                         <h4>Direct roles and data restrictions</h4>
                                         <h4>Standard roles</h4>
                                         <div class="form-check" *ngFor="let item of allRoleList">
                                          <!-- [value]="capitalizeAndFormat(item?.Code)" -->
                                          <input class="form-check-input" type="radio" id="exampleRadio1"  [value]="item.id" formControlName="role">&nbsp;&nbsp;
                                          <label class="form-check-label" for="exampleRadio1">
                                            {{item.Name}}
                                          </label>
                                         </div>
                                         <div  class="text-danger" *ngIf="addAgentForm?.get('role')?.hasError('required') && addAgentForm?.get('role')?.touched">
                                          role is required.
                                         </div>
                                        </div>
                                      </div>   
                                 </div>
                              </div>
                              <div class="row d-flex justify-content-center">
                                <div class="col-md-10">
                                    <div class="card">
                                       <div class="card-body">
                                        <h4>Application</h4>
                                        <div class="form-check" *ngFor="let application of applicationInfo">
                                         <!-- [value]="capitalizeAndFormat(item?.Code)" -->
                                         <input class="form-check-input" type="radio" id="exampleRadio1"  [value]="application.id" formControlName="application">&nbsp;&nbsp;
                                         <label class="form-check-label" for="exampleRadio1">
                                           {{application?.Name}}
                                         </label>
                                        </div>
                                        <div  class="text-danger" *ngIf="addAgentForm?.get('application')?.hasError('required') && addAgentForm?.get('application')?.touched">
                                          application is required.
                                        </div>
                                       </div>
                                     </div>   
                                </div>
                             </div>

                              <!-- <div class="row d-flex justify-content-center">
                                <div class="col-md-10">
                                    <div class="card">
                                       <div class="card-body">
                                         <div class="row">
                                          <h4>Data restrictions (GA4 properties only)</h4>
                                           <div class="col-md-12">
                                             <div class="form-check">
                                              <input class="form-check-input" type="checkbox" id="exampleCheckbox" formControlName="costMetrix">&nbsp;&nbsp;
                                              <label class="form-check-label" for="exampleCheckbox">
                                                No Cost Metrics
                                              </label>
                                             </div>
                                           </div>

                                           <div class="col-md-12">
                                             <div class="form-check">
                                              <input class="form-check-input" type="checkbox" id="exampleCheckbox" formControlName="revenueMetrix">&nbsp;&nbsp;
                                              <label class="form-check-label" for="exampleCheckbox">
                                                No Revenue Metrics
                                              </label>
                                             </div>
                                           </div>

                                         </div>
                                       </div>
                                     </div>   
                                </div>
                             </div> -->
                             <div class="row d-flex justify-content-center marginB">
                              <div class="col-md-10">
                              <button *ngIf="!editId" class="btn btn-primary" (click)="submitAgent()">Submit</button>
                              <button *ngIf="editId" class="btn btn-primary" (click)="submitAgent()">Update</button>
                              </div>
                             </div>
                            </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
