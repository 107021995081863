import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserAgentService } from '../../services/user-agent.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ticket-tags',
  templateUrl: './ticket-tags.component.html',
  styleUrls: ['./ticket-tags.component.scss'],
  animations:[fadeInOut]
})
export class TicketTagsComponent {
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number;
  currentPage: number = 1; 
  totalPages: number = 1;
  userList: any;
  public first_page_url: any = "";
  public last_page_url: any = "";
  initials:any
  public next_page_url: any = "";
  totalusers:number
  tagForm:FormGroup;
  modalRef:any
  tagId:any
  showmodal:boolean = false
  tagTypeList:any
isSkeletonLoader: boolean=true;
  constructor(private fb:FormBuilder,private userAgentService:UserAgentService,private _snackBar:MatSnackBar,
    private router:Router,private http:HttpService,private commonService:CommonService,private modalService: NgbModal){}
    @ViewChild('tagModal') tagModal;

    ngOnInit(): void {
      this.tagForm = this.fb.group({
        tagName: ['', Validators.required],
        tagTypeId:[1,Validators.required],
        isActive: [1, Validators.required]
      });
      this.getUserList()
      this.getTagType()
    }

    getTagType(search?){
      let itemsPerPage = this.itemsPerPage;
      let obj={
        "per_page": itemsPerPage,
        "page": this.currentPage,
      }
      this.userAgentService.getTagTypeList(search,obj).subscribe((res:any)=>{
        this.tagTypeList=res?.data;
      })
    }
  getUserList(search?){
    let itemsPerPage = this.itemsPerPage;
    let obj={
      "per_page": itemsPerPage,
      "page": this.currentPage,
    }
    this.userAgentService.getTagList(search,obj).subscribe((res:any)=>{
      this.userList=res?.data;
      this.totalusers=res?.data?.total
      this.totalItems = res.data.total
      this.currentPage = res.data.current_page
      this.first_page_url = res.data.first_page_url
      this.last_page_url = res.data.last_page_url
      this.next_page_url = res.data.next_page_url
    })
    setTimeout(()=>{
      this.isSkeletonLoader=false
    },1500)
    // this.loadUser=false
  }
  onChangeNumberOfPage() {
    this.getUserList()
  }
  pageChange(event) {
    this.currentPage = event
    this.getUserList()
  }
  editRouter(user:any){
    this.tagForm.patchValue({
      tagName: user.Name,
      tagTypeId:user.TypeId,
      isActive: user.IsActive
    });
    this.tagId = user.id
    this.showmodal = true

   this.modalRef= this.modalService.open(this.tagModal, { centered: true });
  }
  back(){
    this.router.navigateByUrl(`/user-settings`)
  }
  filterdData(event:any){
    let data=event.target.value
    this.getUserList(data);
    // console.log('getUser data: ',this.getUserList(data))
  }
  openModal() {

  if(this.showmodal){
    this.tagForm.reset()
  }
   this.showmodal = false
   this.modalRef =  this.modalService.open(this.tagModal, { centered: true });
  }
  saveTagData() {
    if (this.tagForm.valid) {
      const data = this.tagForm.value;
      if(!this.showmodal){
      this.userAgentService.createTags(data).subscribe(
        (res: any) => {
          if (res.Status) {
            this.commonService.showToast("success", "Success", res.data);
            this.modalRef.close()
            this.getUserList()
          } else {
            this.commonService.showToast("error", "Error", "Something went wrong");
          }
        },
        (error) => {
          this.commonService.showToast("error", "Error", "Failed to add resource");
          console.error("API error:", error);
        }
      );
    }else{
      this.userAgentService.UpdateTags(data,this.tagId).subscribe(
        (res: any) => {
          if (res.Status) {
            this.commonService.showToast("success", "Success", res.data);
            this.getUserList()
          } else {
            this.commonService.showToast("error", "Error", "Something went wrong");
          }
        },
        (error) => {
          this.commonService.showToast("error", "Error", "Failed to add resource");
          console.error("API error:", error);
        }
      );
    }
    } else {
      console.log("Form is invalid");
    }
  }
    deleteTag(id){
      this.userAgentService.DeleteTags(id).subscribe(
        (res: any) => {
          if (res.Status) {
            this.commonService.showToast("success", "Success", res.data);
            this.getUserList()
          } else {
            this.commonService.showToast("error", "Error", "Something went wrong");
          }
        },
        (error) => {
          this.commonService.showToast("error", "Error", "Failed to add resource");
          console.error("API error:", error);
        }
      );
    }
    onTagTypeChange(event: Event) {
    

 
      // Check if the selected value corresponds to "Create New Tag Type"
      if (event == null) {
        console.log("event",event)
        // Navigate to the "Create Tag Type" page
        this.router.navigateByUrl('/tag-type');
       
         this.modalRef.close()
      }
    }
}
