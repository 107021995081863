import { Component } from '@angular/core';

@Component({
  selector: 'app-add-invoices',
  templateUrl: './add-invoices.component.html',
  styleUrls: ['./add-invoices.component.scss']
})
export class AddInvoicesComponent {

}
