import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import { IPostReport } from 'src/app/shared/model/report.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ReportSystemService {
  
  private dataSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSource.asObservable();
  public hasGetListReport:boolean = false;
  public reportListLength:any
  per_page: any;

  constructor(
    private http: HttpService,
    private _http: HttpClient,
  ) {}

  reportGenerate(reportId:any){
    try{
      let url = `api/report/generateReport/${reportId}`
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url,body).toPromise()
    }catch (err){
      throw Error(err)
    }
  }

  getReportCategories() {
    try {
      let url = "api/report/categories"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getReportTypes(categoryID) {
    try {
        // Prepare the endpoint URL
        let url = "api/report/types/activity";

        // Prepare the body with necessary parameters
        let body = {
            "cmd": "get",
            "category_id": categoryID
        };

        // Use the getJson function to make the request
        return this.http.post<any>(url, body); // You can replace `any` with the appropriate type for your response
    } catch (err) {
        throw new Error(err);
    }
}

  getReportFormats(reportID) {
    try {
      let url = "api/report/formats"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "ReportTypeID": reportID
      }
      return this.http.get(url, body);
    } catch (err) {
      throw Error(err)
    }
  }

  getInterActiveReport(data:any) {
    try {
      let url = "api/report/interActiveReport"
      let body = {
        userID:this.http.getUser()?.userID,
        companyId:"UNITYCRM0021",
        ...data
      }
      return this.http.get(url, body);
    } catch (err) {
      throw Error(err)
    }
  }
  getInterActiveReportClick(id:any) {
    try {
      let url = "api/report/types/activity"
      let body = {
        userID:this.http.getUser()?.userID,
        companyId:"UNITYCRM0021",
        "cmd":"click",
        "report_type_id":id
  
      }
      return this.http.get(url, body);
    } catch (err) {
      throw Error(err)
    }
  }
  getInterActiveReportPIn(id:any) {
    try {
      let url = "api/report/types/activity"
      let body = {
        userID:this.http.getUser()?.userID,
        companyId:"UNITYCRM0021",
        "cmd":"pin",
        "report_type_id":id
  
      }
      return this.http.get(url, body);
    } catch (err) {
      throw Error(err)
    }
  }
  downloadReportCSV(data:any) {
    try {
      let url = "api/report/exportInteractiveReport"
      let body = {
        userID:this.http.getUser()?.userID,
        companyId:"UNITYCRM0021",
        ...data
      }
      return this.http.get(url, body);
    } catch (err) {
      throw Error(err)
    }
  }

  getReportRanges() {
    try {
      let url = "api/report/ranges"
      let body = {
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getReportFilterMapping(reportID) {
    try {
      let url = "api/report/filterMapping"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "ReportTypeID": reportID
      }
      return this.http.get(url, body);
    } catch (err) {
      throw Error(err)
    }
  }
  
  getReports(data:any){
      let url = "api/report/list-reports"
      let body = {
        "companyId": "UNITYCRM0021",
        "per_page":data?.per_page,
        "page":data?.page
      }
      return this.http.get(url, body).pipe(
        catchError(error => {
          console.error('An error occurred', error);
          return []; 
        }),
        tap(result => {
          if(result.Status){
            this.reportListLength=result.data.total;
            this.per_page=result.data.per_page;
            this.dataSource.next(result.data.data);
            this.hasGetListReport = result.Status;
          }
        })
      ).subscribe()
  }

  getReportFrequencies() {
    try {
      let url = "api/report/frequencies"
      let body = {
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  postReports(payload: IPostReport) {
    try {
      let url = "api/report/create"
      return this.http.post(url, payload, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateReports(payload: IPostReport, id) {
    try {
      let url = `api/report/update-report/${id}`;

      return this.http.put(url, payload, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  logReports(reportId) {
    try {
      let url = `api/report/reportLogs`;
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "ReportID": reportId
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  

  getStatusCount() {
    try {
      let url = ""
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getReportData(body:any) {
     let url = "api/Customer/usageDetails"
      return this.http.get(url,body,false,false);
  }
 /*  getReportDataFilter(body:any) {
  let url = "api/Customer/usageDetails"
     return this.http.get(url,body,false,false);
 } */
  getRecordTypes() {
    let url = "api/Customer/getRecordTypes"
     return this.http.get(url,null,false,false);
 }
 getPlansList() {
  let url = "api/Customer/getPlansList"
   return this.http.get(url,null,false,false);
}
getStatusesList() {
  let url = "api/Customer/getStatusesList"
   return this.http.get(url,null,false,false);
}
getCarriersCodesList() {
  let url = "api/Customer/getCarriersCodesList"
   return this.http.get(url,null,false,false);
}
}
