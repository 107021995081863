import { Injectable, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UserAgentService } from './user-agent.service';
import { ManageAgentService } from './manage-agent.service';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
  

export class SettingCommonServices implements OnInit {
 
  public show: boolean = false
  public check=true;

  moreInfo: boolean = false;
  moreInfoLevel1: boolean = false;

  moreNote: boolean = false;
  moreNoteLevel1: boolean = false;

  moreComboItem: boolean = false;
  moreComboItemLevel1: boolean = false;
  
  moreMakeModel: boolean = false;
  moreMakeModelLevel1: boolean = false;


  popup1Width;
  popup2Width;

  popup1WidthNote;
  popup2WidthNote;

  popup1WidthComboItem;
  popup2WidthComboItem;
  popup1WidthMakeModel;
  popup2WidthMakeModel;
  
  agentList: any;
  editId: any;
  masterAgentList: any;
  distributorList: any;
  
  moreInfoTabLevel1 = new Subject<string>();
  moreInfoTabLevel2 = new Subject<string>();

  moreNoteTabLevel1 = new Subject<string>();
  moreNoteTabLevel2 = new Subject<string>();
  moreComboItemTabLevel1 = new Subject<string>();
  moreComboItemTabLevel2 = new Subject<string>();
  moreMakeModelTabLevel1 = new Subject<string>();
  moreMakeModelTabLevel2 = new Subject<string>();
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number;
  currentPage: number = 1; 
  totalPages: number = 1;
  pagedUserList: any[];

  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";

  @LocalStorage()
  private userDetails;

  
  constructor(
    private fb:FormBuilder,
    private userAgentService:UserAgentService,
    private agentService:ManageAgentService,
    private router:Router,
    private commonService: CommonService
    ){

//     this.addAgentForm=this.fb.group({
//       groupName:[""],
//       employeeName:[""],
//       retailerName:[""],
//       distributerName:[""],
//       masterAgent:[""],
//       city:[""],
//       state:[""],
//       zip:[""],
//       email:[""],
//       status:[""]
//   })
  }

  
  ngOnInit(): void {
    // this.getUser()
    // this.getAgentList()
    // this.getMasterAgent();
  }

/* for Combo */
  toggleMoreInfo() {
    this.moreInfo = !this.moreInfo
    if(this.moreInfo){
    this.popup1Width = '83%';
    }else{
      this.popup1Width='';
      this.popup2Width='';
      this.editId='';
    }
  }
  moreInfoSelectedTab(tabName:string) {
    this.moreInfoTabLevel1.next(tabName)
    this.toggleMoreInfo();
  }
toggleMoreInfoLevel1(){
  this.moreInfoLevel1= !this.moreInfoLevel1
  if(this.moreInfoTabLevel2){
  this.popup2Width = '83%';
  this.popup1Width='92%'
  }else{
    this.editId=''
    this.popup2Width=''
    this.popup1Width='83%'
  }
}
moreInfoSelectedTab1(tabName:string){
  this.moreInfoTabLevel2.next(tabName);
  this.toggleMoreInfoLevel1();
}

/* for note */
toggleMoreNote() {
  this.moreNote = !this.moreNote
  if(this.moreNote){
  this.popup1WidthNote = '83%';
  }else{
    this.popup1WidthNote='';
    this.popup2WidthNote='';
   // this.editId='';
  }
}
moreNoteSelectedTab(tabName:string) {
  this.moreNoteTabLevel1.next(tabName)
  this.toggleMoreNote();
}
toggleMoreNoteLevel1(){
this.moreNoteLevel1= !this.moreNoteLevel1
if(this.moreNoteTabLevel2){
this.popup2WidthNote = '83%';
this.popup1WidthNote='92%'
}else{
  //this.editId=''
  this.popup2WidthNote=''
  this.popup1WidthNote='83%'
}
}
moreNoteSelectedTab1(tabName:string){
  this.moreNoteTabLevel2.next(tabName);
  this.toggleMoreNoteLevel1();
}

/* for combo item */
toggleMoreComboItem() {
  this.moreComboItem = !this.moreComboItem
  if(this.moreComboItem){
  this.popup1WidthComboItem = '83%';
  }else{
    this.popup1WidthComboItem='';
    this.popup2WidthComboItem='';
   // this.editId='';
  }
}
moreComboItemSelectedTab(tabName:string) {
  this.moreComboItemTabLevel1.next(tabName)
  this.toggleMoreComboItem();
}
toggleMoreComboItemLevel1(){
this.moreComboItemLevel1= !this.moreComboItemLevel1
if(this.moreComboItemTabLevel2){
this.popup2WidthComboItem = '83%';
this.popup1WidthComboItem='92%'
}else{
  //this.editId=''
  this.popup2WidthComboItem=''
  this.popup1WidthComboItem='83%'
}
}
moreComboItemSelectedTab1(tabName:string){
  this.moreComboItemTabLevel2.next(tabName);
  this.toggleMoreComboItemLevel1();
}

/* for Make Model */
toggleMoreMakeModel() {
  this.moreMakeModel = !this.moreMakeModel
  if(this.moreMakeModel){
  this.popup1WidthMakeModel = '83%';
  }else{
    this.popup1WidthMakeModel='';
    this.popup2WidthMakeModel='';
   // this.editId='';
  }
}
moreMakeModelSelectedTab(tabName:string) {
  this.moreMakeModelTabLevel1.next(tabName)
  this.toggleMoreMakeModel();
}
toggleMoreMakeModelLevel1(){
this.moreMakeModelLevel1= !this.moreMakeModelLevel1
if(this.moreMakeModelTabLevel2){
this.popup2WidthMakeModel = '83%';
this.popup1WidthMakeModel='92%'
}else{
  //this.editId=''
  this.popup2WidthMakeModel=''
  this.popup1WidthMakeModel='83%'
}
}
moreMakeModelSelectedTab1(tabName:string){
  this.moreMakeModelTabLevel2.next(tabName);
  this.toggleMoreMakeModelLevel1();
}


editGroup(dataId: string){
  this.editId = dataId;
}


submitAgent(){
  
//     let obj={
//       companyId: "UNITYCRM0021",
//       userID: 3, 
//       UserID: 5,
//       DepartmentID: 4, 
//       MarketID: 5, 
//       SupervisorID: 4, 
//       RegistrationDate: "2023-12-28", 
//       PhoneNumber: "1234567890",
//       AddressLine1: "123 Main Street",
//       AddressLine2: "36th Apartment",
//       CountryID: 1, 
//       StateID: 8, 
//       CityID: 9,
//       ZipID: 10, 
//       SerialNumber: 123,
//       ActivationDate:2023-12-28, 
//       IsRoyaltyRequired: true, 
//       RoyaltyAmount: 12.34, 
//       AgentModeID: 2, 
//       CurrentVersion: "1.0", 
//       ShowGoogleMap: true, 
//       PlanSetting: "Basic", 
//       MigrationStatusId: 1, 
//       EntityTypeID: 2,
//       AgentTypeId: 1 
//   } 

  
// if(!this.editId){
//   this.agentService.saveAgent(obj).subscribe((res:any)=>{
//     // console.log(res,"res>>>>>>>>>save user Data>>")
//   })
// }else{
//   this.agentService.updateAgent(obj).subscribe((res:any)=>{
//     // console.log(res,"updateData>>>>>>>>>>>>>>")
//   })
// }
}


/////////////////////////////////// get Agent List ////////////////////////////////////////


getAgentList(){
  this.agentService.getAgentList().subscribe((res:any)=>{
    this.agentList=res?.data.data;
    this.totalItems = res.data.total
    this.currentPage = res.data.current_page
    this.first_page_url = res.data.first_page_url
    this.last_page_url = res.data.last_page_url
    this.next_page_url = res.data.next_page_url
  })
}


editAgentData(id){
//   this.editId=id
//   this.singleAgentData=[]
//   this.agentList.filter((item)=>{
//     this.singleAgentData.push(item)
//   })
}

getMasterAgent(){
  this.agentService.getMasterAgent().subscribe((res:any)=>{
    this.masterAgentList=res?.data?.data
})
}

onMasterAgentSelected(event:any){
//  console.log(event,this.addAgentForm.value.masterAgent,"event>>>>>>>>>>>>>")
 this.getDistributorList(event.id)
}


getDistributorList(id){
 this.agentService.getDistributorList(id).subscribe((res:any)=>{
  this.distributorList=res
  console.log(res,"res of get distributorsList")
 })
}


    getRetailersList(){
    let obj={}
    this.agentService.getRetailersList(obj).subscribe((res:any)=>{
        console.log(res,"res of get distributorsList")
    })
    }

    getUser() {
    return this.userDetails;
    }

    navigateForPermission(id:any){
    this.router.navigate(["/manage-permissions"], {
        queryParams: { id: id}
    })
    }

    onChangeNumberOfPage() {
    this.getAgentList()
    }

    pageChange(event) {
  this.currentPage = event
  this.getAgentList()
}
}
