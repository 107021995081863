import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '../../services/user-agent.service';
import { ManageAgentService } from '../../services/manage-agent.service';
import { MyProfileService } from 'src/app/profile/services/my-profile.service';
import { ManagePermissionService } from '../../services/manage-permission.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorage } from 'ngx-webstorage';
import { PermissionService } from 'src/app/shared/services/permission-service.service';

declare var $: any;

@Component({
  selector: 'app-manage-permissions',
  templateUrl: './manage-permissions.component.html',
  styleUrls: ['./manage-permissions.component.scss']
})

export class ManagePermissionComponent implements OnInit {
  profileData: any;
  permissions: any;
  permissionsMain: any;
  resource_types: any;
  resources: any;
  showCreate: boolean = false
  agentId: any;
  public seletedAgent: any

  public selectedCar1: any;
  public selectedCar2: any;
  public selectedCar3: any;
  public showFirstDropdown: any = [];
  agentList: any;

  @LocalStorage()
  private userDetails;

  constructor(private myprofileService: MyProfileService, private managePermissionService: ManagePermissionService,
    private commonService: CommonService, private route: ActivatedRoute, private _snackBar: MatSnackBar,
    private permissionService: PermissionService, private manageAgentService: ManageAgentService) {
    this.agentId = this.route.snapshot.queryParamMap.get('id')
  }

  ngOnInit() {
    this.getUser()
    // this.getProfileData();
    this.getAllPermissions();
    this.getAllAgentList();

  }


  //////////////////////// set the breadcrumb here //////////////////////////

  setBreadCrumb() {
    this.commonService.setbreadCrumb({
      "title": "Manage Permissions",
      "active_item": "Manage Permissions"
    })
  }


  // openSnackBar(message: string) {
  //   this._snackBar.open(message);
  // }

  openSnackBar(message: string, duration: number = 2000) {
    this._snackBar.open(message, undefined, {
      duration: duration, verticalPosition: 'top'

    });
  }

  ///////////////////////// get data from local storage //////////////////////////////////

  getUser() {
    return this.userDetails;

  }

  ////////////////////////////////////////////////////////////////////////////

  getAllPermissions(id?) {
    id ? this.agentId = id : this.agentId
    console.log('id', id)
    this.managePermissionService.getAllPermissions(this.agentId).subscribe((res: any) => {
      console.log('user id', this.agentId)
      this.profileData = res.data;
    })

  }
  onCheckboxChange(item: any, permissionType: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.checked;
    // Initialize resource_permission if it is null
    if (!item.resource_permission) {
      item.resource_permission = [];
    }

    // Update or create logic based on resource_permission length
    if (item.resource_permission == null) {
      this.showCreate = true;
      console.log("show create ", this.showCreate)
    } else {
      this.showCreate = false;
    }
    item.resource_permission[permissionType] = value;
  }

  updatePermissions(item: any) {
    const permissions = {
      CanView: item.resource_permission?.CanView === true ? 1 : 0,
      CanEdit: item.resource_permission?.CanEdit === true ? 1 : 0,
      CanDelete: item.resource_permission?.CanDelete === true ? 1 : 0,
    };

    const obj = {
      UserID: this.getUser().userID,
      userID: this.agentId, // user for which permission is being updated
      ResourceID: item?.id,
      CanView: permissions.CanView,
      CanEdit: permissions.CanEdit,
      CanDelete: permissions.CanDelete,
      UpdatedBy: this.getUser().userID,
    };

    this.managePermissionService.updatePermission(item.resource_permission[0]?.id, obj).subscribe((res: any) => {
      if (res.Status == true) {
        this.openSnackBar('Permission Updated Successfully.', 3000);
        this.fetchResourcePermissionAndUpdate()
      }
    });
  }

  async fetchResourcePermissionAndUpdate() {
    try {
      const res: any = await this.permissionService.fetchAllResourceCategories(); // Await the API call
      const data = res.data;
      for (const val of data) {
        await this.permissionService.addResourceCategory(val); // Await each IndexedDB addition
      }
      const updatedCategories = await this.permissionService.getAllResourceCategories();
    } catch (error) {
      console.error('Error:', error);
    }
  }

  createPermission(item: any) {
    const permissions = {
      CanView: item.resource_permission?.CanView === true ? 1 : 0,
      CanEdit: item.resource_permission?.CanEdit === true ? 1 : 0,
      CanDelete: item.resource_permission?.CanDelete === true ? 1 : 0,
    };

    const obj = {
      userID: this.getUser().userID,
      UserID: this.agentId, // user for which permission is being updated
      ResourceID: [item?.id],
      CanView: permissions.CanView,
      CanEdit: permissions.CanEdit,
      CanDelete: permissions.CanDelete,
      CreatedBy: this.getUser().userID,
    };

    this.managePermissionService.createPermission(obj).subscribe(
      (res: any) => {
        if (res.Status == true) {
          this.openSnackBar('Permission Created Successfully.', 3000);
        } else {
          this.openSnackBar('Failed to create permission.', 3000);
        }
      },
      (error) => {
        this.openSnackBar('Error creating permission.', 3000);
      }
    );
  }


  onSelectCar1() {
    this.selectedCar2 = ''
    this.profileData.filter((item) => {
      if (this.selectedCar1 == item.id) {
        this.resource_types = item?.resource_types
        console.log("resource type data ", this.resource_types, 'pro', this.profileData)
      }
    })
  }

  onSelectCar2() {
    this.resource_types.filter((item) => {
      if (this.selectedCar2 == item.id) {
        this.resources = item?.resources
      }
    })
  }

  onClearSelection1() {
    this.selectedCar2 = ''
  }


  getAllAgentList() {
    this.manageAgentService.getAgentListNopage().subscribe((res: any) => {
      this.agentList = res?.data;
      let newAgent: any = [];
      this.agentList.filter((item) => {
        if (item.id == this.agentId) {

          newAgent.push(item)
          this.seletedAgent = newAgent[0]?.name;

        }
      })
    })

  }


}
