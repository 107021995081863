import { Component } from '@angular/core';

@Component({
  selector: 'app-ivr-credit-card',
  templateUrl: './ivr-credit-card.component.html',
  styleUrls: ['./ivr-credit-card.component.scss']
})
export class IvrCreditCardComponent {

}
