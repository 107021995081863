import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-normal-card-skeleton',
  templateUrl: './normal-card-skeleton.component.html',
  styleUrls: ['./normal-card-skeleton.component.scss']
})
export class NormalCardSkeletonComponent implements OnInit{


  @Input() cardCount: number = 5; // Number of cards
  @Input() width: string = '300px'; // Default width for cards
  @Input() height: string = '150px'; // Default height for cards
  @Input() alignment: 'row' | 'column' = 'row'; // Default alignment to 'row'

  cardArray: any[] = [];

  constructor() {}

  ngOnInit(): void {
    // Initialize the card array with the number of cards to render
    console.log('NormalCardSkeletonComponent initialized with:', this.cardCount, this.width, this.height, this.alignment);
    this.cardArray = Array(this.cardCount).fill(0);
  }
}
