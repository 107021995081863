import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';
import Swal from 'sweetalert2';
import { InactivityService } from './inactivity.service';
import { environment } from 'src/environments/environment.dev';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthCleanupService } from "src/app/shared/services/auth-clean-up.service";
import { EchoService } from 'src/app/shared/services/echo.service';
import { SearchHistoryService } from 'src/app/shared/services/search-history.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { StateService } from 'src/app/shared/services/state.service';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private inactivityService: InactivityService;
  private stateService: StateService;

  @LocalStorage()
  private userDetails;
  @LocalStorage()
  private currentCompany;
  isRefreshTokenDialogAlreadyDisplayed = false;
  isExpiredTokenDialogAlreadyDisplayed = false;
  isCustomerLogin = false;
  mingleToken:any;
  unityToken:any;
  userID:any;
  public isRefreshingToken = false;
  public company = null;
  private isLoggedIn = false; 
  public buyflow: boolean = false;
  constructor(
    private http: HttpClient,
    public router: Router,
    private localStorage: LocalStorageService,
    private injector: Injector,
    private ngZone: NgZone,
    private httpService: HttpService,
    private echoService: EchoService,
    private authCleanupService: AuthCleanupService,
    private dbService: NgxIndexedDBService, private searchHistoryService: SearchHistoryService,private commonService:CommonService
  ) {
    this.inactivityService = this.injector.get(InactivityService);
    this.stateService = this.injector.get(StateService);
  }

  setLoginStatus(status: boolean): void {
    this.isLoggedIn = status;
    localStorage.setItem('isLoggedIn', JSON.stringify(status));
  }
  
  isUserLoggedIn(): boolean {
    if (this.isLoggedIn) return true;
  
    // Check persistent status in localStorage if in-memory flag is not set
    const persistedStatus = localStorage.getItem('isLoggedIn');
    this.isLoggedIn = persistedStatus ? JSON.parse(persistedStatus) : false;
    return this.isLoggedIn;
  }
  
  async login(data) {
    try {
      const response: any = await this.http
        .post(environment.CAS_BASE_RUL + "api/CAS/login", data)
        .toPromise();
      if (response?.status) {
        localStorage.setItem("isCustomerLogin", JSON.stringify(true));
        this.getInactivityService().start();
        // this.startTokenChecks();
      }
      return response;
    } catch (err) {
      this.isCustomerLogin = false;
      throw err;
    }
  }

  private getInactivityService(): InactivityService {
    if (!this.inactivityService && this.isCustomerLogin) {
      this.inactivityService = this.injector.get(InactivityService);
    }
    return this.inactivityService;
  }

  getCompanyName() {
    if (!this.company) {
      this.company = localStorage.getItem("currentCompany");
    }
    return this.company;
  }
  getBuyFlowHost(companyName: string) {
    if (!this.buyflow) {
      this.buyflow = environment[`${companyName}_BUYFLOW`];
    }
    return this.buyflow;
  }

  generateTokenByRefereshToken() {
    const key = this.getCompanySpecificKey("Refreshtoken");
    const Refreshtoken = localStorage.getItem(key);
    let url = environment.CAS_BASE_RUL + "api/CAS/tokenRefresh";
    let data = {
      userID: this.userDetails?.userID,
      companyId: localStorage.getItem("currentCompany"),
      refreshToken: Refreshtoken,
      userName: this.userDetails?.userName,
    };
    return this.http.post(url, data);
  }

  // "userName": "test_username12",
  //     "companyId": "unity",
  //     "userID": 8938,
  async generateRefreshTokenByRefereshToken() {
    if (this.isRefreshingToken) {
      return; // Skip if already refreshing
    }
    const key = this.getCompanySpecificKey("Refreshtoken");
    const Refreshtoken = localStorage.getItem(key);
    this.isRefreshingToken = true;
    try {
      let apiBaseUrl = localStorage.getItem("BaseUrl");
      let url = apiBaseUrl + "api/Authorization/RefreshToken";
      let data = {
        userID: this.userDetails.userID,
        companyId: "UNITYCRM0021",
        refreshToken: Refreshtoken,
      };
      const res: any = await this.http.post(url, data).toPromise();
      const currentCompany = localStorage.getItem("currentCompany") || "unity";
      this.setRefreshToken(currentCompany, res?.data[0]?.refreshToken);
    } catch (err) {
      console.log("err", err);
      this.logout();
      throw Error(err?.error?.message);
    } finally {
      this.isRefreshingToken = false; // Reset the flag after completion
    }
  }

  setToken(companyId, token) {
    // if(companyId.)
    this.localStorage.store(companyId + "-Token", token);
  }

  get getToken() {
    return this.localStorage.retrieve("token");
  }

  setRefreshToken(companyId, refreshToken) {
    this.localStorage.store(companyId + "-Refreshtoken", refreshToken);
    let timestamp = new Date();
    timestamp.setMinutes(
      timestamp.getMinutes() + environment.JWT_EXPIRATION_TIME_IN_MINUTES
    );
    this.localStorage.store(
      companyId + "-RefreshTokenExpiry",
      timestamp.toString()
    );
    this.isRefreshTokenDialogAlreadyDisplayed = false;
    this.isExpiredTokenDialogAlreadyDisplayed = false;
  }

  setUser(companyId, data) {
    this.localStorage.store(companyId + "-userDetails", data);
  }

  setBaseUser(data) {
    this.localStorage.store("userDetails", data);
  }

  async logout() {
    if (this.isLoggedIn) {
      await this.authCleanupService.handleLogout(); // Perform cleanup actions
    } 
    this.stateService.resetState();
   this.setLoginStatus(false); 
    const keyToKeep = "currentCompany";
    const valueToKeep = localStorage.getItem(keyToKeep);
    localStorage.clear();
    if (valueToKeep !== null) {
      localStorage.setItem(keyToKeep, valueToKeep);
    }
    this.httpService.ngOnDestroy();
    const deleteRequest = await indexedDB.deleteDatabase("PermissionsDB");

    deleteRequest.onsuccess = () => {
      console.log("Database deleted successfully.");
    };

    deleteRequest.onerror = (event) => {
      console.error("Failed to delete database:", event);
    };
    this.router.navigateByUrl("auth/login");
    this.isCustomerLogin = false;
    this.getInactivityService().stop();
    sessionStorage.clear();
    this.echoService.unsubscribe('new_notification');
  }

  setCompanyTokens(tokens:any){
    this.unityToken= tokens?.unityToken;
    this.mingleToken= tokens?.mingleToken;
    this.userID= tokens?.userID;
    console.log('setCompanyTokens: ',  this.unityToken,  this.mingleToken, this.userID);
  }

  getCompanyTokens(companyName: string): Promise<{ token: string | null; userId: number | null }> {
    return new Promise((resolve) => {
      if (companyName === 'mingle') {
        resolve({ token: this.mingleToken, userId: this.userID });
      } else if (companyName === 'unity') {
        resolve({ token: this.unityToken, userId: this.userID });
      } else {
        resolve({ token: null, userId: null });
      }
    });
  }
  
  
  
  getCompanySpecificKey(key: string): string {
    const currentCompany = localStorage.getItem("currentCompany") || "unity";
    return `tibss.${currentCompany}-${key}`;
  }

  isTokenExpired(): boolean {
    const key = this.getCompanySpecificKey("RefreshTokenExpiry");
    const expiryTime = localStorage.getItem(key);
    console.log(expiryTime);
    if (expiryTime) {
      const expiryDate = new Date(expiryTime);
      const currentDate = new Date();
      const differenceInMin =
        (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60);
      return differenceInMin <= 0.5;
    }
    return false;
  }

  isRefreshTokenAboutToExpire(): boolean {
    const key = this.getCompanySpecificKey("RefreshTokenExpiry");
    const expiryTime = localStorage.getItem(key);
    if (expiryTime) {
      const expiryDate = new Date(expiryTime);
      const currentDate = new Date();
      const differenceInMin =
        (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60);
      return (
        differenceInMin < environment.JWT_EXPIRATION_WARNING_TIME_IN_MINUTES
      );
    }
    return false;
  }

  showRefreshTokenDialog(): Promise<boolean> {
    if (this.isRefreshingToken) {
      return Promise.resolve(false); // Return immediately if already in the process
    }

    this.isRefreshTokenDialogAlreadyDisplayed = true;
    return Swal.fire({
      title: "Your Session is about to expire.",
      text: "Do you want to continue the session?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.generateRefreshTokenByRefereshToken();
      }
      return result.isConfirmed;
    });
  }

  showTokenExpiredDialog(): Promise<boolean> {
    this.isExpiredTokenDialogAlreadyDisplayed = true;
    return Swal.fire({
      title: "Session Expired.",
      text: "Login again.",
      icon: "error",
      confirmButtonText: "Login",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        this.router.navigateByUrl("auth/login");
        console.clear();
      }
      return result.isConfirmed;
    });
  }
}
