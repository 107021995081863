<div class="row">
    <div class="col-12">
        <form [formGroup]="formData" class="w-100">
            <div class="p-3">
                <div class="form-group">
                    <input type="text" formControlName="noteType" placeholder="Note Type" class="form-control">
                </div>
                <div class="form-group mt-3">
                    <input type="text" formControlName="Description" placeholder="Description" class="form-control">
                </div>
                <div class="text-center mt-3">
                    <button type="button" (click)="onSubmit()"
                        class="btn btn-primary">
                        Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>


