<div class="customizer-contai" [ngClass]="{ open: moreInfo == true }" [ngStyle]="{ 'width': popup1Width }">
  <div class="customizer-contai">
    <div class="tab-content" id="c-pills-tabContentAgent">
      <div
        class="customizer-header sticky-top bg-white py-3 d-flex justify-content-between align-items-center border-bottom " style="z-index: 1;">
        <h5 class="mb-0"(click)="back()" style="cursor: pointer;"> <i
          class="fa-solid fa-arrow-left mt-1 mx-2"></i> Manage Resources
        </h5>
        <!-- <i class="icofont-close icon-close cursor-pointer" (click)="closeModal()"></i> -->
      </div>
      <div class="main">
      <div class="">
        <div class="col-md-12 row mt-3">
     
          <div class="col-md-12 gap-2 d-flex col-12 text-end">
            <div class="input-group d-flex justify-content-end pb-3">
              <div class="input-container col-md-4 col-12" *ngIf="showSearch">
                <input type="text" class="form-control px-4 pt-2" placeholder="Search" (keyup)="filterdData($event)">
              </div>
              <div class=" px-0">
                <button type="button" class="btn btn-outline-secondary" (click)="showSearch1()" style="position: relative; border: 1px solid;">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="div">
            <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              Create
            </button>
            </div>
          </div>
        </div>
        <form [formGroup]="form" >
          <div class="collapse mt-3" id="collapseExample">
            <div class="custom-card card-body mx-3 mb-2 overflow-visible ">
              <div class="row align-items-center justify-content-end">
                <!-- Resource Dropdown -->
                <div class="col-md-4 mb-3">
                  <label for="resourceSelect" class="required">Resource Code</label>
                  <ng-select id="resourceSelect" formControlName="resourceSelect"
                    [placeholder]="'Select or add a Resource Exm-RESOURCE_NAME'" (change)="onSelectResource()" (clear)="onClearResource()"
                    [allowClear]="true" [addTag]="true" (add)="onAddNewResource($event)"
                    [ngClass]="{'is-invalid': form.get('resourceSelect').invalid && form.get('resourceSelect').touched}">
                    <ng-option *ngFor="let resource of resourcesListDrop" [value]="resource.DisplayName">
                      {{ resource.DisplayName }}
                    </ng-option>
                    <ng-option [value]="-1" disabled>
                      + Add new resource...
                    </ng-option>
                  </ng-select>
                  <div *ngIf="form.get('resourceSelect').invalid && form.get('resourceSelect').touched"
                    class="invalid-feedback">
                    Resource Code is required.
                  </div>
                </div>

                <!-- Description Input -->
                <div class="col-md-4 mb-3">
                  <label for="resourceDescription" class="required">Description</label>
                  <input type="text" class="form-control" id="resourceDescription" formControlName="resourceDescription"
                    placeholder="Description..."
                    [ngClass]="{'is-invalid': form.get('resourceDescription').invalid && form.get('resourceDescription').touched}">
                  <div *ngIf="form.get('resourceDescription').invalid && form.get('resourceDescription').touched"
                    class="invalid-feedback">
                    Description is required.
                  </div>
                </div>

                <!-- Category Dropdown -->
                <div class="col-md-4 mb-3">
                  <label for="categorySelect" class="required">Resource Type</label>
                  <ng-select id="categorySelect" formControlName="categorySelect"
                    [placeholder]="'Select or add a category'" (change)="onSelectCategory($event)"
                    (clear)="onClearCategory()" [allowClear]="true" [addTag]="false" (add)="onAddNewCategory($event)"
                    [ngClass]="{'is-invalid': form.get('categorySelect').invalid && form.get('categorySelect').touched}">
                    <ng-option *ngFor="let category of resourcesTypeList" [value]="category.id">
                      {{ category.DisplayName }}
                    </ng-option>
                    <ng-option [value]="-1" [ngClass]="'add-category-option'">
                      + Add new resource type...
                    </ng-option>
                  </ng-select>
                  <div *ngIf="form.get('categorySelect').invalid && form.get('categorySelect').touched"
                    class="invalid-feedback">
                    Resource Type is required.
                  </div>
                </div>
              </div>

              <div class="col-md-12 span-head">
                <span class="text-info cursor-pointer" style="font-size: 15px;" (click)="toggleExtraInputs()">
                  More Optional Fields <span *ngIf="showExtraInputs">-</span><span *ngIf="!showExtraInputs">+</span>
                </span>
              </div>

              <!-- Additional Inputs Section -->
              <div *ngIf="showExtraInputs" class="row mt-3" id="collapseExample2">
                <div class="col-md-4 mb-3">
                  <label for="resourceName">Resource Name</label>
                  <input type="text" class="form-control" id="resourceName" formControlName="resourceName"
                    placeholder="Enter resource name">
                </div>
                <div class="col-md-4 mb-3">
                  <label for="navIconClass">Nav Icon Class</label>
                  <input type="text" class="form-control" id="navIconClass" formControlName="navIconClass"
                    placeholder="Enter nav icon class">
                </div>
                <div class="col-md-4 mb-3">
                  <label for="urlRoute">URL Route</label>
                  <input type="text" class="form-control" id="urlRoute" formControlName="urlRoute"
                    placeholder="Enter URL route">
                </div>
                <div class="col-md-4 mb-3">
                  <label for="resourceSelect" class="required">Parent </label>
                  <ng-select id="resourceSelect" formControlName="parentId"
                    [placeholder]="'Select Parents'" (change)="onSelectResource()" (clear)="onClearResource()"
                    [allowClear]="true"
>
                    <ng-option *ngFor="let resource of resourcesListDrop" [value]="resource.id">
                      {{ resource.DisplayName }}
                    </ng-option>
                  </ng-select>
      
                </div>
                <div class="col-md-4 mt-4 gap-2">
                  <label class="d-flex gap-1">
                    <input type="radio" formControlName="status" [value]="1" (change)="onStatusChange(1)">
                    Active
                  </label>
                  <label class="d-flex gap-1">
                    <input type="radio" formControlName="status" [value]="2" (change)="onStatusChange(2)">
                    Inactive
                  </label>
                </div>
           
              </div>

              <!-- Submit Button -->
              <div class="col-md-12 text-end mt-3 px-0">
                <button *ngIf="!showupdate" class="btn btn-outline-info" style="font-size: 15px;"
                  type="submit" (click)="submitForm()" >Submit</button>
                <div class="col-md-12 text-end " *ngIf="showupdate">
                  <button class="btn btn-outline-warning mx-2" style="font-size: 15px;" type="button"
                    (click)="update()">Update</button>
                  <button class="btn btn-outline-danger" style="font-size: 15px;" type="button"
                    (click)="resetForm()">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </form>


<div class="container-fluid">
  <!-- Table Section -->
  <div class="row">
    <div class="col-md-12 mx-auto">
      <div class="table-responsive marginB2" >
  
        <!-- Table displaying resources list -->
        <table class="table project-list-table table-nowrap align-middle table-borderless">
          <thead class="table-light">
            <tr>
              <th class="text-end" *ngFor="let col of columnDefs">{{ col.headerName }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- Row for each resource in resourcesList -->
            <tr class="text-end" *ngFor="let row of resourcesList; let i = index">
              <td>{{i+1}}</td>
              <td>{{ row.DisplayName }}</td>
              <td>{{ row.Description }}</td>
              <td>{{ row.resource_type?.DisplayName || 'N/A' }}</td>
              <td>
                <!-- Action buttons -->
                <button class="btn btn-sm btn-primary me-2" (click)="ViewOneRes(row.id)">Edit</button>
                <button class="btn btn-sm btn-danger" (click)="deleteResouce(row.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <!-- Skeleton loader placeholder when data is loading -->
        <div *ngIf="isSkeletonLoader" @fadeInOut class="d-flex flex-column gap-4" style="max-width: 100%;">
          <app-skeleton-loader [tableColumn]="8" [tableRow]="8" shape="table"></app-skeleton-loader>
        </div>
  
        <!-- Pagination -->
        <div class="col-md-12 d-flex justify-content-end px-0 mt-1">
          <div class="col-md-6 d-flex justify-content-end px-0">
            <ngb-pagination 
              [maxSize]="3" 
              [directionLinks]="true" 
              [boundaryLinks]="true" 
              [collectionSize]="totalItems" 
              [(page)]="currentPage" 
              (pageChange)="pageChange($event)" 
              [pageSize]="itemsPerPage">
              <ng-template ngbPaginationPrevious>Previous</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  
 
</div>

      </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showresource_types">
  <app-resource-type-modal (close)="closeModal1()"></app-resource-type-modal>
</div>