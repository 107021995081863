import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timline-card-skeleton',
  templateUrl: './timline-card-skeleton.component.html',
  styleUrls: ['./timline-card-skeleton.component.scss']
})
export class TimlineCardSkeletonComponent implements OnInit {

  timelineArray: any[] = [];

  ngOnInit(): void {
    this.timelineArray = Array(5).fill(0); // Simulate 5 skeleton timeline items
  }
}
