import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent implements OnChanges, OnInit {
  @Input() width: string;
  @Input() height: string;
  @Input() borderRadius: string = "4px";
  @Input() margin: string = "10px 0";
  @Input() isRounded: boolean = false;
  @Input() animated: boolean = true;
  @Input() shape: string = ""; // New input for shape
  @Input() tableRow: number = 0;
  @Input() tableColumn: number = 0;
  @Input() alignment: "row" | "column" = "row"; // Default to 'row'
  @Input() cardCount: number;
  @Input() formColumnCount: number;
  @Input() formRowCount: number;
  @Input() isAddressSummary: boolean;
  @Input() isPaymentForm: boolean;
  rowCount: number[] = []; // Array to generate rows
  columnCount: number[] = []; // Array to generate columns
  @Input() numberOfCards: number;
  @Input() buttonCount: number;
  @Input() animatedRowCount: number;
  @Input() borderWidth: any;
  @Input() radius: any;
  @Input() loaderCount: number;

  // Helper method to check if shape is graph
  isBarGraph(): boolean {
    return this.shape === "barGgraph";
  }
  isPieChart(): boolean {
    return this.shape === "pieChart";
  }
  isTable() {
    // return this.shape === 'table';
  }

  constructor() {}
  ngOnInit(): void {
    console.log("button count: ", this.buttonCount);
    console.log("shape: ", this.shape);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateSkeleton(this.tableRow, this.tableColumn);
  }
  getArcStyle(index: number): any {
    const angle = (index - 1) * 45; // Divide 360° into 8 parts, each arc is 45° apart
    return {
      transform: `rotate(${angle}deg)`,
      "transform-origin": "center",
      position: "absolute",
      top: "0",
      left: "50%",
      width: "50%",
      height: "100%",
      "clip-path": "polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%)",
      overflow: "hidden",
    };
  }

  generateSkeleton(rows: number, columns: number): void {
    // Fill rowCount and columnCount arrays based on the number of rows and columns required
    this.rowCount = Array(rows)
      .fill(0)
      .map((x, i) => i);
    this.columnCount = Array(columns)
      .fill(0)
      .map((x, i) => i);
  }
}
