import { Component } from '@angular/core';

@Component({
  selector: 'app-plan-renew',
  templateUrl: './plan-renew.component.html',
  styleUrls: ['./plan-renew.component.scss']
})
export class PlanRenewComponent {

}
