<div class="container mt-0 mb-4" style="max-width: 100%;">
  <div class="card form-card shadow mt-0 mb-3" style="max-height: 80vh;">
    <div class="card-header text-white br-5 bg-primary sticky-header">
      <h5 class="card-title mb-0" style="font-size: 1.5rem; letter-spacing: 2px;">UPCOMING PLANS</h5>
    </div>

    <div class="card-body p-0" *ngIf="!isSkeletonloader" @fadeInOut>
      <!-- Parent Table -->
      <table class="table mb-0">
        <!-- Sticky Header -->
        <thead class="thead-dark sticky-thead" style="height: 3rem;">
          <tr class="text-center">
            <th *ngFor="let header of getPlanDetailsHeaders()" class="text-center p-2 fs-6 fw-bold" style="white-space: nowrap;">
              {{ header.label }}
            </th>
          </tr>
        </thead>

        <!-- Scrollable Body -->
        <tbody>
          <tr *ngFor="let row of planDetails">
            <td *ngFor="let header of getPlanDetailsHeaders()" class="text-center">
              <span [ngClass]="{
                'badge-light-success': row[header.label] === 'Yes' || row[header.label] === 'Active',
                'badge-light-danger': row[header.label] === 'No' || row[header.label] === 'Cancelled',
                'badge-light-warning': row[header.label] === 'Scheduled'
              }" class="fw-normal fs-6 p-1" style="white-space: nowrap;">
                {{ row[header.label] || 'Not Available' }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="isSkeletonloader" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [tableRow]="8" [tableColumn]="8"  shape="table"></app-skeleton-loader>
    </div>

    <!-- <div class="card-footer bg-primary"></div> -->
  </div>
</div>
<!-- 
<div *ngIf="isLoading" class="card-loader">
  <app-loader [isChildLoader]="true"></app-loader>
</div> -->
