<div class="container mt-2">
  <div class="wt-tabs-header-sticky" *ngIf="tabs?.length>0">
    <button class="tab-nav-btn prev" (click)="goToPrevTab()" [disabled]="activeTabIndex === 0">
      <i class="fas fa-chevron-left"></i>
    </button>
    <div id="tabsHeaderContainer" class="wt-tabs-header-container" #tabsContainer>
      <ng-container *ngFor="let tab of tabs; let i = index">
        <div class="wt-tabs-header-scroll-section" (click)="sendplanId(tab.id)" *ngIf="tab.TagType=='Plan'">
          <button [ngClass]="{'active': activeTabIndex === i}" class="wt-tab " (click)="setActiveTab(i)">
            <label class="wt-typography wt-tab-label" data-type="body-single-line-sm " style="cursor: pointer;">
              {{ tab.Name }}
            </label>
          </button>
        </div>
        <span class="wt-tabs-active-indicator" [style.left.px]="activeTabIndicatorLeft"
          (click)="sendplanId(tab.id)" *ngIf="tab.TagType=='Plan'"></span>
      </ng-container>
    </div>
    <button class="tab-nav-btn next" (click)="goToNextTab()" [disabled]="activeTabIndex === tabs.length - 1">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <div class="row p-2" style="height: 100vh; overflow: auto;" #cardsContainer *ngIf="!isPlanSkeleton" @fadeInOut>
    <div class="col-md-12" *ngFor="let card of plans" [attr.data-category]="card.category">
      <div class="card-container">
        <div class="row  custom-card mb-2" (click)="openDiv(); getSelectedPlanId(card?.PlanId); onPlanSelected($event)">

          <div class="core-benefits-container mt-2 col-md-12">
            <div class="d-flex mb-0">

              <ng-container *ngIf="card?.ImapgePath">
                <div class="image-container" style="max-width: 15%; overflow: hidden; border-radius: 10px;">
                  <img [src]="card?.ImapgePath" alt="Plan Image" style="
                                  width: 100%;
                                  height: auto;
                                  object-fit: cover;
                                  border-radius: 10px;
                                " />
                </div>
              </ng-container>
              <div class="d-flex flex-column w-100">
                <div class="top-data d-flex justify-content-evenly w-100 mb-2">
                  <div class="benefit-list mb-0">
                    <label class=" mb-0 gap-1 d-flex align-items-start">
                      <span class="text-muted mt-2">$</span>
                      <span class="benefit-title">{{ card.Price }}</span>
                    </label>
                    <span class="text-muted mx-4">Total</span>
                  </div>
                  <div class="benefit-list mb-0 d-flex flex-column">
                    <label class="benefit-title mb-0">{{ card.PlanData }}</label>
                    <span class="text-muted">Data</span>
                  </div>
                  <div class="benefit-list mb-0">
                    <label class="d-flex align-items-center mb-0 gap-1">
                      <span class="benefit-title">{{ card.ExpirationDays }}</span>
                      <span class="text-muted mt-1">Days</span>
                    </label>
                    <span class="text-muted">Validity</span>
                  </div>
                  <div class="benefit-list mb-0" *ngIf="card.PlanData == 'Unlimited'">
                    <label class="d-flex align-items-center mb-0 gap-1">
                      <span class="text-muted mt-2">$</span>
                      <span class="benefit-title">{{ card?.monthlyAmount }}</span>
                    </label>
                    <span class="text-muted">Monthly</span>
                    <!-- <span class="text-muted">Validity</span> -->
                  </div>
                </div>
                <div class="bottom-data d-flex justify-content-evenly w-100 flex-column-mobile">
                  <div class="d-flex align-items-center">
                    <app-feather-icons [icon]="'phone-call'" class="mt-2"></app-feather-icons>
                    <span class="ms-2">{{ card?.VoiceText || "-" }} Minutes</span>
                  </div>
                  <div class="d-flex align-items-center ">
                    <app-feather-icons [icon]="'smartphone'"></app-feather-icons>
                    <span class="ms-2 ellipsis-tooltip" [title]="card?.Name">{{ card?.Name || "-" }}</span>
                  </div>
                  <div class="d-flex align-items-center ">
                    <app-feather-icons [icon]="'message-square'" class="mt-2"></app-feather-icons>
                    <span class="ms-2">{{ card?.VoiceText || "-" }} SMS</span>
                  </div>
                  <div class="d-flex align-items-center " *ngIf="card.PlanData == 'Unlimited'">
                    <app-feather-icons [icon]="'dollar-sign'" class="mt-2"></app-feather-icons>
                    <span class="ms-2">{{ card?.savingAmount }} Savings</span>
                  </div>
                </div>
              </div>
              <!-- <div class="d-flex">
                                <button class="btn btn-primary " >Details</button>
                            </div> -->
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div *ngIf="isPlanSkeleton" style="max-width: 100%;" @fadeInOut>
    <app-skeleton-loader [numberOfCards]="10" shape="plan-card"></app-skeleton-loader>
  </div>
</div>




<div class="slide-in-div container-fluid" [ngClass]="{ open: isOpen }" style="margin: 0; padding: 0">
  <div class="container-fluid sticky-header" style="background-color: #f5f5f5; border-bottom: 2px solid black;">
    <div class="div d-flex flex-row justify-content-between align-items-center p-2">
      <h5 style="margin-left: 5px; letter-spacing: 3px">PLAN DETAIL</h5>
      <app-feather-icons [icon]="'x'" class="text-primary" (click)="closeDiv()"
        style="cursor: pointer"></app-feather-icons>
    </div>
  </div>

  <div class="content m-3">
    <div class=" flex-row justify-content-evenly align-items-center p-3" *ngIf="!isPlanDetailSkeleton" @fadeInOut>
      <!-- Prorated Plan Section -->
      <div *ngIf="planSelected" class="d-flex gap-3 mb-5 justify-content-center flex-column-mobile">
        <div class="card d-flex flex-column  align-items-center shadow-sm"
          style="border-radius: 10px;border: 1px solid lightblue;">
          <div class="card-body text-center d-flex justify-content-between col-md-12">
            <div class="button-group d-block justify-content-center mt-3">
              <div class="first-content text-start mb-5">
                <h5 class="card-title mb-1" style="font-weight: bold; color: #4a4a4a; font-size: 32px;">Prorated Plan
                </h5>
                <h6 class="card-subtitle mb-2 my-0 py-0 text-muted" style="font-size: 36px;">$ {{
                  proratedDetails?.newPlanPrice || "-" }}</h6>

              </div>
              <button *ngIf="proratedDetails?.changeType !== 'SamePlan'" class="btn btn-outline-primary w-100"
                style="border-radius: 10px; font-size: 1rem; font-weight: 500;" (click)="handleCheckOut(modalTemplate)">
                Check Out
              </button>
              <button *ngIf="proratedDetails?.changeType === 'SamePlan'" class="btn btn-outline-primary w-100"
                style="border-radius: 10px; font-size: 1rem;  font-weight: 500;" disabled>
                Current Plan
              </button>
            </div>
            <!-- <div class="divider my-3" style="width: 100%; height: 1px; background-color: #dcdcdc;"></div> -->
          </div>
          
        </div>
        <div class="plan-details d-flex flex-column align-items-start mt-2 col-md-4 ">
          <div class="d-flex align-items-center mb-2">
            <app-feather-icons [icon]="'smartphone'" style="color: #6c63ff;"></app-feather-icons>
            <span class="ms-2">
              <i><u>{{ newPlanDetails?.plan?.DisplayName || "-" }}</u></i>
            </span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <app-feather-icons [icon]="'phone-call'" style="color: #6c63ff;"></app-feather-icons>
            <span class="ms-2">{{ proratedDetails?.changeType || "-" }}</span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <app-feather-icons [icon]="'dollar-sign'" style="color: #6c63ff;"></app-feather-icons>
            <span class="ms-2">$ {{ proratedDetails?.proratedPrice || "-" }} <span class="text-danger"><b>
                  [Payable Amount]</b></span></span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
            <span class="ms-2">{{ proratedDetails?.validity || "-" }} Days Validity</span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
            <span class="ms-2">{{ paymentType }} <span class="text-success"></span> </span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
            <span class="ms-2">{{ proratedDetails?.effectiveDate | date }} <span class="text-success"><b>[Effective
                  Date]</b></span> </span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <ng-container *ngIf="proratedDetails?.changeType === 'Downgrade'">
              <div class="text-danger mt-2  " role="alert"
                style=" width: 20em;word-wrap: break-all; white-space:normal">
                The amount won't be taken out right now. According to the prearranged billing cycle, payment will
                be handled through the subscription profile.
              </div>
            </ng-container>
          </div>
        </div>
        <div class="extra-details mt-2 p-3 col-md-4"
          style="border: 1px solid #cce5ff; border-radius: 10px; background-color: #eaf3ff; ">
          <h6 class="text-dark" style="font-weight: bold;">Additional Details</h6>
          <hr>
          <div class="d-flex flex-column align-items-start">
            <div class="d-flex align-items-center mb-2">
              <app-feather-icons [icon]="'calendar'" style="color: #4d79ff;"></app-feather-icons>
              <span class="ms-2">Prorated Days: <b>{{ proratedDetails?.proratedDays || "0" }} Days</b></span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <app-feather-icons [icon]="'calendar'" style="color: #4d79ff;"></app-feather-icons>
              <span class="ms-2">Used Plan Days: <b>{{ proratedDetails?.usedPlanDays || "0" }} Days</b></span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <app-feather-icons [icon]="'clock'" style="color: #4d79ff;"></app-feather-icons>
              <span class="ms-2">Remaining Days: <b>{{ proratedDetails?.remainingDays || "0" }} Days</b></span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <app-feather-icons [icon]="'check-circle'" style="color: #4d79ff;"></app-feather-icons>
              <span class="ms-2">Validity: <b>{{ proratedDetails?.validity || "0" }} Days</b></span>
            </div>
          </div>

          <div class="d-flex align-items-center mb-2">
            <ng-container *ngIf="proratedDetails?.changeType === 'Downgrade'">
              <div class="text-danger mt-2" role="alert"
                style="width: 20em; word-wrap: break-all; white-space: normal;">
                The amount won't be taken out right now. According to the prearranged billing cycle, payment will
                be handled through the subscription profile.
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <!-- Old Plan Card -->
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="price-item text-center" style="position: relative;">
            <div class="ribbon" style="position: absolute;     top: 22px;
  left: -19px; z-index: 1;">
              <span class="ribbonStyleOld"
                style="background-color: #ff6347; color: #fff; font-size: 12px; border-radius: 5px;  box-shadow: 0 0 8px rgba(108, 99, 255, 0.8);">
                Old Plan
              </span>
            </div>
            <div class="price-top">
              <h4>{{ oldPlanDetails?.plan?.DisplayName || "-" }}</h4>
              <h2 class="mb-0"><sup>$</sup>{{ oldPlanDetails?.plan?.Price || "-" }}</h2>
              <span class="text-capitalize">{{ oldPlanDetails?.planDetail?.ExpirationDays || "-" }} Days Validity</span>
            </div>
            <div class="price-content">
              <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'smartphone'" class="mr-2"></app-feather-icons>
                  <span>{{ oldPlanDetails?.plan?.DisplayName || "-" }}</span>
                </li>
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'dollar-sign'" class="mr-2"></app-feather-icons>
                  <span>$ {{ oldPlanDetails?.plan?.Price || "-" }}</span>
                </li>
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'calendar'" class="mr-2"></app-feather-icons>
                  <span>{{ oldPlanDetails?.planDetail?.ExpirationDays || "-" }} Days Validity</span>
                </li>
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'radio'" class="mr-2"></app-feather-icons>
                  <span>{{ oldPlanDetails?.planDetail?.PlanData || "-" }}</span>
                </li>
              </ul>
              <!-- <a href="#" class="btn btn-custom">Buy now</a> -->
            </div>
          </div>
        </div>
        <!-- New Plan Card -->
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="price-item text-center popular" style="position: relative;">
            <div class="ribbon" style="position: absolute; top: 20px; left: -19px; z-index: 1;">
              <span class="ribbonStyleNew" style="background-color: #6c63ff; color: #fff; font-size: 12px; border-radius: 5px; 
                        box-shadow: 0 0 8px rgba(108, 99, 255, 0.8);">
                New Plan
              </span>
            </div>

            <div class="price-top">
              <h4>{{ newPlanDetails?.plan?.DisplayName || "-" }}</h4>
              <h2 class="mb-0"><sup>$</sup>{{ newPlanDetails?.plan?.Price || "-" }}</h2>
              <span class="text-capitalize">{{ newPlanDetails?.planDetail?.ExpirationDays || "-" }} Days Validity</span>
            </div>
            <div class="price-content">
              <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'smartphone'" class="mr-2" style="color: #6c63ff;"></app-feather-icons>
                  <span class="c-black">{{ newPlanDetails?.plan?.DisplayName || "-" }}</span>
                </li>
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'dollar-sign'" class="mr-2" style="color: #9b9bf4"></app-feather-icons>
                  <span class="c-black">$ {{ newPlanDetails?.plan?.Price || "-" }}</span>
                </li>
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'calendar'" class="mr-2" style="color: #9b9bf4"></app-feather-icons>
                  <span class="c-black">{{ newPlanDetails?.planDetail?.ExpirationDays || "-" }} Days Validity</span>
                </li>
                <li class="d-flex align-items-center">
                  <app-feather-icons [icon]="'radio'" class="mr-2" style="color: #9b9bf4"></app-feather-icons>
                  <span class="c-black">{{ newPlanDetails?.planDetail?.PlanData || "-" }}</span>
                </li>
              </ul>
              <!-- <a href="#" class="btn btn-custom btn-light">Buy now</a> -->
            </div>
          </div>
        </div>




      </div>
    </div>
    <div *ngIf="isPlanDetailSkeleton" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader shape="plan-detail-card"></app-skeleton-loader>
    </div>
  </div>
</div>

<ng-container *ngIf="isPaymentModelOpen">
  <app-plan-change-payment (isPaymentModelOpenChange)="onIsPaymentModelOpenChange($event)"
    [isPaymentModelOpen]="isPaymentModelOpen" [proratedDetails]="proratedDetails" [selectedPlanId]="selectedPlanId"
    [selectedPlanName]="selectedPlanName" [newPlanDetails]="newPlanDetails"
    (transactionStatus)="isTransactionStatus()"></app-plan-change-payment>
</ng-container>