import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { COMPANYDB, CompanyDB } from 'src/app/shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { PlanSetupModalComponent } from './plan-setup-modal/plan-setup-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddonTopupListModalComponent } from './addon-topup-list-modal/addon-topup-list-modal.component';

@Component({
  selector: 'app-plan-topup',
  templateUrl: './plan-topup.component.html',
  styleUrls: ['./plan-topup.component.scss']
})
export class PlanTopupComponent {
  public selected = [];

  public tableItem$: Observable<CompanyDB[]>;
  public searchText;
  total$: Observable<number>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TableService,public modalService: NgbModal) {

    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(COMPANYDB)

  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }
  open() {
    this.modalService.open(PlanSetupModalComponent, { size: 'xl' });
	}
  openAdd(){
    this.modalService.open(AddonTopupListModalComponent, { size: 'xl' });

  }
}
