import { Component } from '@angular/core';

@Component({
  selector: 'app-wallet-plan-change',
  templateUrl: './wallet-plan-change.component.html',
  styleUrls: ['./wallet-plan-change.component.scss']
})
export class WalletPlanChangeComponent {

}
