import { Component } from '@angular/core';

@Component({
  selector: 'app-equipment-purchase',
  templateUrl: './equipment-purchase.component.html',
  styleUrls: ['./equipment-purchase.component.scss']
})
export class EquipmentPurchaseComponent {
  newtabsCondition:boolean = false;
}
