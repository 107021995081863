<div class="container">
    <div class="d-flex align-items-center justify-content-between overflow-auto">
        <div class="d-flex gap-3 align-items-baseline">
            <button (click)="back()" class="custom-btn bg-transparent d-flex align-items-center">
                <i class="icon-arrow-left fw-semibold"></i>
            </button>
            <div class="d-flex flex-column">
                <span class="title heading">
                    Refund
                </span>
                <span class="text-muted">#{{this.id}}</span>
            </div>
        </div>
    </div>
    <div class="row mx-0 mt-3">
        <div class="col-lg-8">
            <div class="custom-card">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="custom-badge  bg-yellow">
                        <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true">
                            <path
                                d="M12.53 4.78a.75.75 0 0 0-1.06-1.06l-1.47 1.47-1.47-1.47a.75.75 0 0 0-1.06 1.06l1.47 1.47-1.47 1.47a.75.75 0 0 0 1.06 1.06l1.47-1.47 1.47 1.47a.75.75 0 1 0 1.06-1.06l-1.47-1.47 1.47-1.47Z">
                            </path>
                            <path fill-rule="evenodd"
                                d="m14.844 10-.336-2.265a.75.75 0 1 1 1.484-.22l.413 2.792c.063.425.095.853.095 1.282v1.661a3.25 3.25 0 0 1-3.25 3.25h-6.5a3.25 3.25 0 0 1-3.25-3.25v-1.66c0-.43.032-.858.094-1.283l.414-2.792a.75.75 0 0 1 1.484.22l-.336 2.265h2.484a1.25 1.25 0 0 1 1.185.855l.159.474a.25.25 0 0 0 .237.171h1.558a.25.25 0 0 0 .237-.17l.159-.475a1.25 1.25 0 0 1 1.185-.855h2.484Zm-9.843 1.5-.001.09v1.66c0 .967.784 1.75 1.75 1.75h6.5a1.75 1.75 0 0 0 1.75-1.75v-1.75h-2.46l-.1.303a1.75 1.75 0 0 1-1.66 1.197h-1.56a1.75 1.75 0 0 1-1.66-1.197l-.1-.303h-2.46Z">
                            </path>
                        </svg>
                        <span class="text-nowrap">
                            {{order?.ShipmentStatus}}
                        </span>
                    </span>
                    <div class="d-flex align-items-center">
                        <span>Shop location</span>
                    </div>
                </div>
                <div class="border mt-2">
                    <div class="items flex-row justify-content-between" *ngFor="let item of order.order_items">
                        <div class="d-flex align-items-center gap-3">
                            <img width="40" height="40" class="img" [src]="item.product_combo_item.ImagePath">

                            <div class="d-flex gap-1 flex-column">
                                <span class="fs-6 ">{{item.product_combo_item.devicemakemodel.Make}}
                                    {{item.product_combo_item.devicemakemodel.Model}}</span>
                                <span
                                    class="custom-badge justify-content-center">${{item.product_combo_item.devicemakemodel.Price}}</span>
                                <span>${{item.product_combo_item.devicemakemodel.Price}} × {{item.Quantity}} </span>
                            </div>
                        </div>
                        <div class="d-flex gap-5 align-items-center">
                            <div class="counter-container" (mouseenter)="showIcons = true"
                                (mouseleave)="showIcons = false">
                                <span class="count-value">{{ countValue }}</span>
                                <span>/ 1</span>
                                <i class="fa-solid fa-sort-up up-btn"
                                    (click)="increment(item.product_combo_item.devicemakemodel.Price)"></i>
                                <i class="fa-solid fa-sort-down down-btn"
                                    (click)="decrement(item.product_combo_item.devicemakemodel.Price)"></i>
                            </div>
                            <span>${{total||0}}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-start mt-2 gap-2">
                    <span class="text-muted">Refunded items will be removed from the order</span>
                </div>
            </div>
            <div class="custom-card mt-3">
                <div class="d-flex flex-column gap-2">
                    <span class="fw-semibold">Reason for refund</span>
                    <input class="w-100 form-control" type="text">
                    <span class="text-muted">Only you and other staff can see this reason</span>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="custom-card p-4 mb-3">
                <div class="d-flex flex-column">
                    <h5>Summary</h5>
                    <span *ngIf="!total">No items selected.</span>
                    <div class="d-flex justify-content-between align-items-center items p-2" *ngIf="total">
                        <span class=""> Items subtotal (1)</span>
                        <span class=""> ${{this.total}}</span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center  p-2" *ngIf="total">
                        <span class=""> Refund total</span>
                        <span class=""> ${{this.total}}</span>
                    </div>
                </div>
                <div class="d-flex flex-column mt-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5>PO number</h5>
                        <i class="fa fa-pencil"></i>
                    </div>
                    <span class="text-muted"> No PO number
                    </span>
                </div>
            </div>

        </div>
    </div>

</div>