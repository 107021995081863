<div class="container-fluid" style="max-width: 100%;">
  <div 
    [ngClass]="{
      'd-flex': true, 
      'flex-row': alignment === 'row', 
      'flex-column': alignment === 'column'
    }" 
    class="d-flex flex-wrap justify-content-start gap-2 row "
  >
    <div *ngFor="let card of cardArray" class="card p-4 small-skeleton-card mb-2" style="flex-grow: 1; flex-basis: 0; min-width: 250px; border-radius: 0;">
      <div class="d-flex align-items-center mb-3">
        <div class="skeleton-icon" style="height: 30px; width: 30px; border-radius: 4px;"></div>
        <div class="skeleton-text ms-3 w-50"></div>
      </div>
      <div class="skeleton-number mb-2" style="width: 50px; height: 40px;"></div>
      <div class="skeleton-subtext w-75 mb-2" style="height: 20px;"></div>
      <div class="skeleton-subtext w-50" style="height: 15px;"></div>
    </div>
  </div>
</div>
