import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { TicketSystemService } from '../../../ticket-system/services/ticket-system.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-manage-comments',
  templateUrl: './manage-comments.component.html',
  styleUrls: ['./manage-comments.component.scss']
})
export class ManageCommentsComponent implements OnInit {
  @LocalStorage()
  private userDetails;
  formData: FormGroup;
  @Input() ticketId: any = null;
  @Input() allAgents: any = [];
  @Input() ticketTypes: any = [];
  @Output() commentEvent = new EventEmitter<string>();
  data: any;
  commentId: any;
  loader: boolean = false;
  allStatusList: any[] = []
  constructor(
    public fb: FormBuilder,
    public ticketSystemService: TicketSystemService,
    public commonService: CommonService,
  ) {

    this.initializeFormData()
  }

  ngOnInit() {
    this.getComments()
    this.getTicketsStatus()
  }


  async getTicketsStatus() {
    try {
      const res: any = await this.ticketSystemService.getTicketsStatus()
      if (res.Status) {
        this.allStatusList = res.data.map(obj => ({
          ...obj,
          active: false
        }));
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  initializeFormData() {
    this.formData = this.fb.group({
      "CommentText": [, Validators.required],
      "AgentID": 9,
      "IsInternalNote": 0,
      "AssigneeID":[],
      "TicketTypeID":[],
      "StatusID": [],
      "CreatedBy": [this.getUser().userID],
    })
  }

  async getComments() {
    this.loader = true;
    try {
      const res: any = await this.ticketSystemService.getCommentsByTicketId(this.ticketId)

      if (res.Status) {
        this.data = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

  getUser() {
    return this.userDetails;
  }

  async onSubmit() {
    this.loader = true;
    try {
      if (this.commentId) {
        const res: any = await this.ticketSystemService.updateComment(this.commentId, this.formData.value)
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Updated successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
      } else {
        const res: any = await this.ticketSystemService.addComment(this.ticketId, this.formData.value)
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Added successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
      }
      this.getComments()     
      this.initializeFormData()
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

  async deleteComment(id: any) {
    this.loader = true;
    try {
      const res = await this.ticketSystemService.deleteComment(id);
      console.log('delete comment', res)
      this.getComments()
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }


}
