import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  api: string = environment.BASE_API;

  @LocalStorage()
  private CustomerId;

  constructor(
    private http: HttpService
  ) { }

  getOrderHistoryData() {
    try {
      let data = {
        "companyId": "UNITYCRM0021",
        "CustomerId": this.CustomerId
      }
      return this.http.get('api/Customer/getCustomerOrdersHistory',data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAcpData() {
    try {
     
      // return this.http.get(this.api + 'api/Customer/getMinimalProfileData').toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

}
