import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

@Component({
  selector: 'app-change-mdn',
  templateUrl: './change-mdn.component.html',
  styleUrls: ['./change-mdn.component.scss']
})
export class ChangeMdnComponent implements OnChanges {
  formData: FormGroup;
  loader = false
  @Input('customerData') customerData
isSkeletonLoader: boolean=true;

  constructor(
    private customerProfileService: CustomerProfileService,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.formData = this.fb.group({
      mdnType: ['', Validators.required],
      mdnNo: ['', Validators.required],
      zipCode: ['']
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formData.get('mdnNo').setValue(this.customerData?.MDN);
    this.isSkeletonLoader=false
  }

  updateMDN() {
    if (this.formData.get('mdnType').value == 'Electronic') {
      this.electrnoicMdnChange()
    } else {
      this.nonElectrnoicMdnChange();
    }
  }

  async electrnoicMdnChange() {
    
    let mdnNo = this.formData.get('mdnNo').value;
    try {
      const data = {
        newIccid: mdnNo,
        zipCode:  this.formData.get('zipCode').value
      }
      const res: any = await this.customerProfileService.updateMDN(data)
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Updated')
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      console.log(err)
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }

  async nonElectrnoicMdnChange() {
    
    let mdnNo = this.formData.get('mdnNo').value;
    try {
      const data = {
        "mdn": mdnNo,
      }
      const res: any = await this.customerProfileService.simChangeNonElec(data)
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Updated');
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.error ?? "Some Error Occurred"))
    }
    
  }
}

