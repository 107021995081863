<div class="container-fluid tab-form-wrapper">
  <form>
  <div class="row form-group">
    <div class="col-md-3">
<label>Tag#</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group area-text">
    <div class="col-md-10">
      <textarea class="form-control" rows="3"></textarea>
    </div>
    <div class="col-md my-auto text-end">
      <button type="button" class="btn btn-secondary">Add</button>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12 text-end">
      <button type="button" class="btn btn-danger me-3">Close</button>

      <button type="button" class="btn btn-primary">Submit</button>
    </div>
  </div>
  </form>
  </div>