import { Component } from '@angular/core';

@Component({
  selector: 'app-money-gram',
  templateUrl: './money-gram.component.html',
  styleUrls: ['./money-gram.component.scss']
})
export class MoneyGramComponent {

}
