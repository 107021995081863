<div class="container-fluid tab-form-wrapper">
  <form>
  <div class="row form-group">
    <div class="col-md-3">
<label>Tag#</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12 text-end">
      <button type="button" class="btn btn-primary">Add</button>
    </div>
  </div>
  </form>
  </div>