import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwaitingShipmentComponent } from './awaiting-shipment.component';
import { AwaitingShipmentRoutingModule } from './awaiting-shipment-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AwaitingShipmentOrderComponent } from './awaiting-shipment-order/awaiting-shipment-order.component';
import { AgGridModule } from 'ag-grid-angular';
import {SaleStatusComponent} from "./card-stats/sale-status.component";
import { OrderDetailsComponent } from './order-details/order-details.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RefundComponent } from './refund/refund.component';
import { EditOrderComponent } from './edit-order/edit-order.component';
import { RenewalReportsModule } from '../renewal-reports/renewal-reports.module';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [
    AwaitingShipmentComponent,
    AwaitingShipmentOrderComponent,
    SaleStatusComponent,
    OrderDetailsComponent,
    RefundComponent,
    EditOrderComponent
  ],
  imports: [
      CommonModule,
      AwaitingShipmentRoutingModule,
      SharedModule,
      CarouselModule,
      AgGridModule,
      RenewalReportsModule,
      // NgbModule,
      DragDropModule,
      PickerComponent,
      ReactiveFormsModule, // Required for form handling
      MatAutocompleteModule, // For autocomplete
      MatInputModule, // For input field
      MatFormFieldModule, // For form field
      MatOptionModule, // For MatOption
      MatTooltipModule

  ],
  exports:[AwaitingShipmentComponent,AwaitingShipmentOrderComponent],

})
export class AwaitingShipmentModule { }
