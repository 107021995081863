<app-common-filter [filters]="filterConfig" (filtersChanged)="onFiltersChanged($event)">

</app-common-filter>

<div class="position-relative" *ngIf="this.showViewChangeIcon">
  <div class=" icon-wrapper" (click)="onChangeViewClick()">
    <i class="fa fa-list" *ngIf="!changeView"></i>
    <i class="fa fa-grip" *ngIf="changeView"></i>

    <span>
      {{changeView?"Board":"List"}}
    </span>
  </div>
</div>

<ng-container *ngIf="changeView" >

  <app-ticket-status-card *ngIf="!isDisabledMyTicket"></app-ticket-status-card>
  <app-ticket-table></app-ticket-table>
</ng-container>
<ng-container *ngIf="!changeView">
<app-ticket-kanban-board></app-ticket-kanban-board>
</ng-container>

