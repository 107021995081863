<div class="card p-3 " [ngClass]="{'active': activeCardId==data?.title}" *ngIf="!isSkeletonLoader" @fadeInOut >
    <div class="d-flex flex-column align-items-start justify-content-center ">
        <h5 class="" [ngClass]="{'text-muted': activeCardId!=data?.title}">
            {{data?.title}}
        </h5>
        <h5>
            {{data?.count}}
        </h5>
    </div>
</div>
<div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
    <app-skeleton-loader [cardCount]="1" [width]="'100%'" [height]="'100%'" [alignment]="'row'"  shape="normal-card"></app-skeleton-loader>
  </div>