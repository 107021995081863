import { Component } from '@angular/core';

@Component({
  selector: 'app-adjust-wallet',
  templateUrl: './adjust-wallet.component.html',
  styleUrls: ['./adjust-wallet.component.scss']
})
export class AdjustWalletComponent {

}
