import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ManageComboItemsService {
  constructor(
    private http: HttpService,
  ) { }

  public getProductComboItemsList(body:any):Observable<HttpResponse<any>>{
    let url="api/product/getProductComboItems"
    return this.http.get(url,body);
  }
  getProductComboItem(body:any){
    let url="api/product/getProductComboItems"
       return this.http.get(url,body,false,false)
     }
  addProductComboItems(body:any){
    let url="api/product/add-product-combo-items"
     return this.http.post(url,body,true,true)
   }
   updateProductComboItem(id:any,body:any){
    let url=`api/product/updateProductComboItem/${id}`
    return this.http.post(url,body,true)
   }
}
