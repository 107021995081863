import { Injectable } from '@angular/core';

export interface AppState {
  filters: any; // Generic filter criteria
  items: any[]; // Generic list of items
  selectedIndex?: number | null; // Index of the highlighted/selected item
}

@Injectable({
  providedIn: 'root', // Makes the service available application-wide
})
export class StateService {
  private state: AppState = {
    filters: {}, // Default filters
    items: [], // Default item list
    selectedIndex: null, // No item selected by default
  };

  /**
   * Sets the new state. This will completely replace the current state.
   * @param newState The new state object to set.
   */
  setState(newState: Partial<AppState>): void {
    this.state = {
      ...this.state, // Preserve existing state properties
      ...newState,   // Merge new state properties
    };
  }

  /**
   * Retrieves the current state.
   * @returns The current state object.
   */
  getState(): AppState {
    return { ...this.state }; // Return a copy to prevent direct mutation
  }

  /**
   * Reset the state to its initial values.
   */
  resetState(): void {
    this.state = {
      filters: {}, // Reset filters
      items: [], // Clear the item list
      selectedIndex: null, // Clear selection
    };
  }
}
