import { Component, Input } from '@angular/core';
import { objectKeys } from 'src/app/helpers';
import { CommonService } from 'src/app/shared/services/common.service';
import { TicketSystemService } from '../services/ticket-system.service';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/common-filter.service';

@Component({
  selector: 'app-ticket-status-card',
  templateUrl: './ticket-status-card.component.html',
  styleUrls: ['./ticket-status-card.component.scss']
})
export class TicketStatusCardComponent {

  public data;
  public startDate: Date;
  public endDate: Date;
  public cards = [];
  selectedCard: string = 'TICKET_OPENED';

  filterConfig = [
    { type: 'text', key: 'searchText', label: 'Ticket Name', placeholder: 'Enter text...' },
    { type: 'dateRange', key: 'dateRange', label: 'Date Range' },
    { type: 'select', key: 'status', label: 'Status', options: ['Open', 'Closed'] },
    { type: 'buttonGroup', key: 'ticket', label: 'Ticket Status', options: ['Opened', 'Closed', 'Grouped'] }
  ];
  private filterSubscription: Subscription;
  previousDateRange: any;
  constructor(
    public commonService: CommonService,
    public ticketSystemService: TicketSystemService,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.getCardData()
    this.getTicketCards()

    this.ticketSystemService.refreshCardandCount.subscribe(res => {
      if (res) {
        this.getCardData()
        this.getTicketCards()
      }

    })

    this.filterSubscription = this.filterService.filters$.subscribe(filters => {
      // React to the filters as necessary
      // const dateRange = filters.dateRange;
      // console.log(filters);
      // if (dateRange?.length == 2) {
      //   const startDate = new Date(dateRange[0]);
      //   const endDate = new Date(dateRange[1]);
      //   const fromDate :any = this.filterService.formatDateToMMDDYYYY(startDate);
      //   const toDate :any = this.filterService.formatDateToMMDDYYYY(endDate);
      //   this.startDate = fromDate;
      //   this.endDate = toDate;
      //   this.getCardData();
      // }

      const dateRange = filters.dateRange;

      // Check if dateRange exists and has two dates
      if (dateRange?.length === 2) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);

        // Format the start and end dates to MM/DD/YYYY
        const fromDate :any = this.filterService.formatDateToMMDDYYYY(startDate);
        const toDate :any = this.filterService.formatDateToMMDDYYYY(endDate);

        // Compare the current dateRange with the previous one
        if (!this.previousDateRange || (this.previousDateRange[0] !== fromDate || this.previousDateRange[1] !== toDate)) {
          // If the dateRange has changed, update previousDateRange
          this.previousDateRange = [fromDate, toDate];
          this.startDate = fromDate;
          this.endDate = toDate;
          // Call the API since dateRange changed
          this.getCardData();
        }
      }

    });
  }





  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  async getCardData() {
    // 
    try {
      let data = {
        "resourceType[]": [
          "TS_DASHBOARD_CARD"
        ],
        "fromDate": this.startDate,
        "toDate": this.endDate,
      }
      if (!this.startDate || this.startDate == undefined) {
        delete data['fromDate']
      }
      if (!this.endDate || this.startDate == undefined) {
        delete data['toDate']
      }
      const res: any = await this.ticketSystemService.ticketAnalytics(data)
      if (res.Status) {
        this.data = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    // 
  }

  getStatusCardColor(cardData) {
    const value = this.getCount(cardData)
    const minThreshold = cardData?.benchmark?.MinValue;
    const midThreshold = cardData?.benchmark?.MidValue;
    const maxThreshold = cardData?.benchmark?.MaxValue;

    if (value > minThreshold && value <= midThreshold) {
      return cardData?.benchmark?.MinColor
    } else if (value > midThreshold && value <= maxThreshold) {
      return cardData?.benchmark?.MidColor
    } else {
      return cardData?.benchmark?.MaxColor
    }
  }

  clearDateFilter() {
    this.endDate = null;
    this.startDate = null;
    this.getCardData()
  }

  async getTicketCards() {
    
    try {
      const res: any = await this.ticketSystemService.getTicketCards()
      if (res.Status) {
        this.cards = res.data.CARDS.TS_DASHBOARD_CARD
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }

  getCount(card) {
    if (this.data?.length > 0) {
      let data = this.data.find(el => el.resource?.Name == card.Name)
      return data ? data?.count : 0
    } else {
      return 0
    }
  }

  getStatusId(card) {
    if (this.data?.length > 0) {
      let data = this.data.find(el => el.resource?.Name == card.Name)
      return data ? data?.StatusId : ''
    } else {
      return ''
    }
  }



  navigateToOrdersByStatus(item) {
    try {
      if (item?.Name == this.selectedCard) {
        this.selectedCard = null
        this.ticketSystemService.updateStatusCode(null, item)
      } else {
        this.selectedCard = item?.Name
        console.log('item: ', item);
        this.ticketSystemService.updateStatusCode(this.getStatusId(item), item)
      }
    } catch (error) {
      this.commonService.showToast('info', "Info", (error?.message ?? "Some Error Occurred"))
    }
  }


}
