<div class="msg-box  pb-2   mt-3" *ngIf="!fullScreen">
    <h5 class="text-white">Line Information</h5>
    <p style="margin-bottom: 0px; color: white; line-height: 1.2; font-size: 12px; font-weight: 300;"
        class="d-flex flex-column gap-2">
    <div class="d-flex justify-content-between mb-2">
        <span>Line Status :</span>
        <span>
            {{minimalProfileData?.lineInfo?.subscriberStatus?.value || '-'}}
        </span>
    </div>
    <div class="d-flex justify-content-between mb-2">
        <span>MSISDN :</span>
        <span>
            {{minimalProfileData?.lineInfo.MDN.value}}
        </span>
    </div>

    <div class="d-flex justify-content-between mb-2">
        <span>IccidStatus :</span>
        <span>
            {{minimalProfileData?.lineInfo?.simCarrierStatus?.value || '-'}}
        </span>
    </div>
    <div class="d-flex justify-content-between mb-2">
        <span>Puk1 :</span>
        <span>
            {{minimalProfileData?.lineInfo?.puk1?.value || '-'}}
        </span>
    </div>
    <div class="d-flex justify-content-between mb-2">
        <span>Puk2 :</span>
        <span>
            {{minimalProfileData?.lineInfo?.puk2?.value || '-'}}
        </span>
    </div>
    <div class="d-flex justify-content-between mb-2">
        <span>Current APS :</span>
        <span>
            {{minimalProfileData?.plan?.currentAps?.value || '-'}}
        </span>
    </div>
    <div class="d-flex justify-content-between mb-2">
        <span>Product Id :</span>
        <span>
            {{minimalProfileData?.plan?.carrierCode?.value || '-'}}
        </span>
    </div>

    <div class="">
        <span>

            <a class="d-block f-w-500 " *ngIf="!simType">SIM Number
                [{{minimalProfileData?.lineInfo?.simType?.value
                || '-'}}]
                <!-- <app-feather-icons [icon]="'refresh-cw'" (click)="refreshSimType()"
        class="ms-2 small-icon"></app-feather-icons> -->
                <i class="fa fa-refresh text-primary cursor-pointer" aria-hidden="true"></i>
            </a>
            <a class="d-block f-w-500 text-nowrap" *ngIf="simType">SIM Number [{{simType ||
                '-'}}]
    
            </a>
            <span>{{minimalProfileData?.lineInfo?.simNumber?.value || '-'}}</span>
        </span>

    </div>
    <div class="d-flex justify-content-between mb-2">
        <span class="text-nowrap">Customer Plan :</span>
        <span>
            {{minimalProfileData?.plan?.customerPlan?.value || '-'}}
        </span>
    </div>

</div>

<div *ngIf="fullScreen">
    <div class="card-form">
        <h2 class="text-center">Line Information</h2>
        <div class="row">
            <ng-container *ngFor="let item of formFields">
                <div class="col-6" *ngIf="item.label != 'SLOs'">
                    <div class="d-flex justify-content-between mb-2  mb-3">
                        <span class="text-nowrap">
                            {{item.label}} :
                        </span>
                        <span>
                            {{item.value}}
                        </span>

                    </div>
                </div>
                <div class="col-12" *ngIf="item.label == 'SLOs'">
                    <div class="d-flex justify-content-between mb-2  mb-3">
                        <span class="text-nowrap me-3">
                            {{item.label}} :
                        </span>
                        <div class='d-flex gap-2 align-items-center' style="flex-wrap: wrap;">
                            <span *ngFor="let val of item.value" class="custome-badge "> {{val}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>