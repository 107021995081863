import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { TicketSystemService } from '../../../ticket-system/services/ticket-system.service';
import { environment } from 'src/environments/environment.dev';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-add-edit-ticket',
  templateUrl: './add-edit-ticket.component.html',
  styleUrls: ['./add-edit-ticket.component.scss']
})
export class AddEditTicketComponent implements OnInit {

  formData: FormGroup;
  @Output() addEditTicketEvent = new EventEmitter<string>();
  @Input() id: any;
  allTicketTypesList: any[];
  allUserList: any[];
  allGroupsList: any[];
  allStatusList: any[];
  uploadedFiles: File[] = [];
  loader: boolean = false;
  attachments: any = [];
  apiEndPoint: string = environment.BASE_API;

  @LocalStorage()
  private userDetails;
  files: File[] = [];
  constructor(
    public fb: FormBuilder,
    public ticketSystemService: TicketSystemService,
    public commonService: CommonService,
  ) {
    this.initializeFormData()
    this.getAgents()
    this.getGroups()
    this.getTicketTypes()
    this.getTicketsStatus()
  }

  initializeFormData() {
    this.formData = this.fb.group({
      "Title": [null, [Validators.required]],
      "TicketTypeID": [null, [Validators.required]],
      "Description": [null, [Validators.required]],
      "CustomerId": [, Validators.required],
      "CustomerName": [null, [Validators.required]],
      "ContactNumber": [],
      "AlternateNumber": [],
      "StatusID": [1, [Validators.required]],
      "AssigneeID": [this.getUser().userID, [Validators.required]],
      "AgentID": [null],
      "GroupID": [null],
      "CreatedBy": [this.getUser().userID],
      "UpdatedBy": [this.getUser().userID],
      "CategoryID": [1],
      "AssignmentType": ["USER"]
    })
  }

  get getAssignmentType() {
    return this.formData.get("AssignmentType") as FormGroup;
  }

  ngOnInit() {
    if (this.id) {
      this.getTicketById()
    }
  }
  onSelect(event) {
    this.uploadedFiles.push(...event.addedFiles);
  }

  onRemove(event) {
    this.uploadedFiles = this.uploadedFiles.filter(f => f !== event);

  }
  async getTicketById() {
    this.loader = true;
    try {
      const res: any = await this.ticketSystemService.getTicketById(this.id)
      if (res.Status) {
        this.formData.patchValue(res.data)
        this.formData.get('CustomerId').patchValue(res?.data?.CustomerID)
        if (res?.data?.customer?.enrollment?.personal_info?.FirstName) {
          this.formData.get('CustomerName').patchValue(res?.data?.customer?.enrollment?.personal_info?.FirstName + ' ' + res?.data?.customer?.enrollment?.personal_info?.MiddleName + ' ' + res?.data?.customer?.enrollment?.personal_info?.LastName)
        }
        this.formData.get('ContactNumber').patchValue(res?.data?.customer?.enrollment?.personal_info?.PrimaryPhone ?? '')
        this.formData.get('AlternateNumber').patchValue(res?.data?.customer?.enrollment?.personal_info?.SecondaryPhone ?? '')
        if (res.data.attachments.length > 0) {
          this.attachments = res.data.attachments;
        }
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.error.message)
    }
    this.loader = false;
  }


  async onSubmit() {
    this.loader = true;
    try {
      if (this.id) {
        const payload = this.formData.value
        payload.UpdatedBy = this.getUser().userID
        const res: any = await this.ticketSystemService.updateTicket(this.id, payload)
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Updated successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
        if (this.uploadedFiles.length > 0) {
          this.uploadFiles()
        }
      } else {
        const payload = this.formData.value
        payload.CreatedBy = this.getUser().userID
        const res: any = await this.ticketSystemService.addTicket(payload)
        if (res.Status) {
          this.id = res.data.id
          this.commonService.showToast("success", "Success", "Added successfully!")
          if (this.uploadedFiles.length > 0) {
            this.uploadFiles()
          }
        } else {

          this.commonService.showToast("error", "Error", res.message)
        }
      }
      this.addEditTicketEvent.emit('')
    } catch (err) {
      this.commonService.showToast("error", "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

  onFileChange(event: any) {
    this.uploadedFiles = Array.from(event.target.files);
  }

  async uploadFiles() {
    // Implement file upload logic here
    const form = new FormData()
    form.append('companyId', "UNITYCRM0021")
    form.append('userID', this.getUser().userID)
    for (let index = 0; index < this.uploadedFiles.length; index++) {
      const element = this.uploadedFiles[index];
      form.append('files[]', element)
    }
    form.append('TicketID', this.id)
    try {
      const res = await this.ticketSystemService.attachFile(form);
      this.id = null
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async deleteAttachments(id: any, index) {
    this.loader = true;
    try {
      const res = await this.ticketSystemService.deleteattachFile(id);
      this.attachments.splice(index, 1);
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

  getUser() {
    return this.userDetails;
  }

  removeFile(file: File) {
    this.uploadedFiles = this.uploadedFiles.filter(f => f !== file);
  }

  onAssignmentTypeChange() {
    this.formData.get('AgentID').reset()
    this.formData.get('GroupID').reset()
  }



  async getTicketsStatus() {
    try {
      const res: any = await this.ticketSystemService.getTicketsStatus()
      if (res.Status) {
        this.allStatusList = res.data.map(obj => ({
          ...obj,
          active: false
        }));
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async getGroups() {
    try {
      const res: any = await this.ticketSystemService.getGroups()
      if (res.Status) {
        this.allGroupsList = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async getAgents() {
    try {
      const res: any = await this.ticketSystemService.getAgents()
      if (res.Status) {
        this.allUserList = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }


  async getTicketTypes() {
    try {
      const res: any = await this.ticketSystemService.getTicketTypes()
      if (res.Status) {
        this.allTicketTypesList = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

}
