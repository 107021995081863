import { ChangeDetectorRef, Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { TicketSystemService } from '../services/ticket-system.service';
import { FilterService } from 'src/app/shared/services/common-filter.service';
import { PermissionService } from 'src/app/shared/services/permission-service.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html',
  styleUrls: ['./ticket-table.component.scss'],
  animations:[fadeInOut]
})
export class TicketTableComponent implements OnDestroy {

  public data;
  public column: any[];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public isEmptyRow: boolean = false;
  public startDate: Date;
  public endDate: Date;
  public filteredData = [];
  public conditionalData = [];
  public inputFilterValue: string;
  public sourceType: any[];
  public buttons: any[];
  public datePickerConfig: Partial<BsDatepickerConfig>;
  public showHideMore: boolean = true;
  private sourceTypeData = [];
  public inputElementRef: HTMLInputElement = null;
  public ticketId: any = null;
  public groupId: any = null;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public searchQuery: any = null;
  public pageDropdownMenu: any = [10, 20, 30];
  changeTicketStatusType: any;
  dropdownOpen: boolean = false
  isDisabledMyTicket = false;
  private subscription: Subscription;
  allStatusList: any[];

  statusCode: any = 1;
  public ticketTypes: any[] = []
  public allAgents: any[] = []
  @Input() filters: any;
  private filterSubscription: Subscription;
isSkeletonLoader: boolean=true;
  constructor(
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    public commonService: CommonService,
    public ticketSystemService: TicketSystemService,
    public filterService: FilterService,
    private permissionService: PermissionService
  ) {
    this.column = [
      {
        displayName: '',
        value: 'UpdateStatus'
      },
      {
        displayName: '#',
        value: 'id',
      },

      {
        displayName: 'Title',
        value: 'Title',
      },
      {
        displayName: 'Ticket Type',
        value: 'TicketType',
      },
      {
        displayName: 'Agent Name',
        value: 'AgentName'
      },
      {
        displayName: 'Status',
        value: 'status'
      },
      {
        displayName: 'Created Date',
        value: 'CreatedDate'
      },
      {
        displayName: 'Created By',
        value: 'CreatedByName'
      },
      {
        displayName: 'Customer ID',
        value: 'CustomerID'
      },
      {
        displayName: 'Customer Name',
        value: 'customerName'
      },
      {
        displayName: 'Updated Date',
        value: 'UpdatedDate'
      },
      {
        displayName: 'Updated By',
        value: 'UpdatedByName'
      },
      {
        displayName: 'Closed By',
        value: 'ClosedByName'
      },
      {
        displayName: 'Resolved By',
        value: 'ResolvedByName'
      },

      {
        displayName: 'Priority',
        value: 'priority'
      },
      {
        displayName: 'Closed Date',
        value: 'ClosedDate'
      },
      {
        displayName: 'Escalation Level',
        value: 'EscalationLevel'
      },
      {
        displayName: 'Linked Tickets',
        value: 'LinkedTickets'
      },
      // {
      //   displayName: 'Callback Date',
      //   value: 'CallbackDate'
      // },
      // {
      //   displayName: 'Callback Time',
      //   value: 'CallbackTime'
      // },
      {
        displayName: 'Action',
        value: 'action'
      }
    ]



    const minYear: number = new Date().getFullYear() - 100;
    const maxYear: number = new Date().getFullYear();

    const minDate = new Date();
    minDate.setFullYear(minYear);

    const maxDate = new Date();
    maxDate.setFullYear(maxYear);

    this.datePickerConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      minDate,
      maxDate
    });
    this.getTicketsStatus();

    this.getStatusCode()
  }

  ngOnChanges() {
    // Apply filtering logic to the table based on the filters
  }
  getStatusCode() {
    this.ticketSystemService.statusCode.subscribe(res => {
      this.statusCode = res
      this.getTickets()
    })
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  filterTableByInputRef(element: HTMLInputElement): void {
    this.inputElementRef = element;
    const value = element.value.trim().toLowerCase();
    this.inputFilterValue = value;
    let data;
    if (this.sourceTypeData.length > 0) {
      data = this.sourceTypeData.filter(record =>
        Object.values(record).join(' ').toLowerCase().includes(value)
      )
    }
    else {
      data = this.conditionalData.filter(record =>
        Object.values(record).join(' ').toLowerCase().includes(value)
      )
    }
    this.conditionalData = data;
    this.paginationUpdate()
  }

  ngOnInit() {
    this.subscription = this.commonService.myTicketsSubject$.subscribe((value) => {
      this.isDisabledMyTicket = value;
    });
    this.getTickets()
    this.getSource()
    this.getButton()
    this.getAgents()
    this.getTicketTypes()

    this.filterSubscription = this.filterService.filters$.subscribe(filters => {
      // Check if dateRange exists and has two dates, then format them
      if (filters.dateRange?.length == 2) {
        const startDate = new Date(filters.dateRange[0]);
        const endDate = new Date(filters.dateRange[1]);

        // Format dates to MM/DD/YYYY
        this.startDate = this.filterService.formatDateToMMDDYYYY(startDate);
        this.endDate = this.filterService.formatDateToMMDDYYYY(endDate);
      }

      if (filters.searchText) {
        this.searchQuery = filters.searchText
      }
      if (filters.ticket) {
        this.statusCode = [filters.ticket]
      }
      console.log(filters);
      this.getTickets()
      // Call the API with the updated filter payload

    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }



  filterByDateRange() {
    this.conditionalData = this.data.filter(item => {
      const itemDate = new Date(item.orderDate);
      return itemDate >= this.startDate && itemDate <= this.endDate;
    });
    this.paginationUpdate()
  }

  updateColumn(event, item) {
    this.column.forEach((col) => {
      if (col.mappingKey[0] === item.mappingKey[0]) {
        col.active = event.currentTarget.checked
      }
    });
  }

  onDateSelection(event: any): void {
    if (!this.startDate) {
      this.startDate = event;
    } else if (!this.endDate || this.endDate > this.startDate) {
      this.endDate = event;
    } else {
      this.startDate = event;
      this.endDate = null;
    }
    if (this.inputElementRef) {
      this.inputElementRef.value = '';
    }
  }

  showHideMoreAction() {
    this.showHideMore = !this.showHideMore
  }

  refreshTable() {
    this.data.map(it => it.customerName = (it.customerFirstName ?? '') + ' ' + (it.customerLastName ?? ''))
    this.conditionalData = this.data;
    if (this.inputElementRef) {
      this.inputElementRef.value = ''
    }
    this.startDate = null;
    this.endDate = null;
    this.paginationUpdate();
    this.sourceType.forEach((sourceItem) => {
      sourceItem.active = false
    })
  }

  filterDataBySource(source, index) {

    let tempIndex = this.sourceType.findIndex(el => el.active == true);
    if (tempIndex != -1) {
      this.sourceType[tempIndex].active = false;
    }
    this.sourceType[index].active = !source.active
    if (tempIndex == index) {
      this.sourceType[index].active = false;
    }
    this.getTickets()
  }

  paginationUpdate() {
    this.totalItems = this.conditionalData.length;
    this.isEmptyRow = this.conditionalData.length === 0 ? true : false;
  }

  openModal(popup, ticketId?) {
    if (ticketId) {
      this.ticketId = ticketId
    }
    this.modalService.open(popup, { backdropClass: "dark-modal", size: 'xl', centered: true });
  }

  addEditTicketEvent(event) {
    this.modalService.dismissAll()
    this.ticketId = null
    this.getTickets()
    this.ticketSystemService.refreshCardandCount.next(true)

  }

  onEdit(id, content) {
    this.ticketId = id
    this.openModal(content)
  }

  commentEvent(event) {
    this.modalService.dismissAll()
  }

  addEditGroupEvent(event) {
    this.modalService.dismissAll()
    this.groupId = null
  }

  async getTickets() {
    try {
      let selectedStatus = this.sourceType?.length > 0 ? this.sourceType.filter(el => el.active == true).map(el => (el.Name)) : []

      if (this.statusCode) {
        selectedStatus.push(this.statusCode)
      }
      let data = {
        "per_page": this.itemsPerPage,
        "StatusID[]": this.statusCode,
        "fromDate": this.startDate,
        "toDate": this.endDate,
        "page": this.currentPage,
        "query": this.searchQuery
      }
      if (selectedStatus.length == 0) {
        delete data['StatusID[]']
      }
      if (!this.startDate || this.startDate == undefined) {
        delete data['fromDate']
      }
      if (!this.endDate || this.startDate == undefined) {
        delete data['toDate']
      }
      if (!this.searchQuery) {
        delete data['query']
      }
      const res: any = await this.ticketSystemService.getAllTickets(data)

      if (res.Status) {
        this.data = res.data.data
        this.data.map(it => it.customerName = (it.customerFirstName ?? '') + ' ' + (it.customerLastName ?? ''))
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.isSkeletonLoader=false;
      } else {
        this.commonService.showToast('error', "Error", res.message)
        this.isSkeletonLoader=false;
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.isSkeletonLoader=false;
    }
  }

  clearDateFilter() {
    this.endDate = null;
    this.startDate = null;
    this.searchQuery = null
    this.getTickets()
  }



  async getSource() {

    try {
      const res: any = await this.permissionService.getResourceCategoryById('TABS')
      if (res) {
        this.sourceType = res.resource_types[0]?.resources
          .filter(obj => obj.resource_permission[0]?.CanView === 1) // Only include objects with CanView === 1
          .map(obj => ({
            ...obj,
            active: false // Add the 'active' property
          }));
        console.log(this.sourceType);
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }

  }

  async getAgents() {
    try {
      const res: any = await this.ticketSystemService.getAgents()
      if (res.Status) {
        this.allAgents = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }
  getStatusId(card) {
    if (this.data?.length > 0) {
      let data = this.data.find(el => el.resource?.Name == card.Name)
      return data ? data?.StatusId : ''
    } else {
      return ''
    }
  }

  async downloadTickets() {
    try {
      let selectedStatus = this.sourceType?.length > 0 ? this.sourceType.filter(el => el.active == true).map(el => (el.Name)) : []

      if (this.statusCode) {
        selectedStatus.push(this.statusCode)
      }
      let data = {
        "perPage": this.itemsPerPage,
        "StatusID[]": selectedStatus,
        "fromDate": this.startDate,
        "toDate": this.endDate,
        "page": this.currentPage,
        "query": this.searchQuery,
        "format": "xlsx"
      }
      if (selectedStatus.length == 0) {
        delete data['StatusID[]']
      }
      if (!this.startDate || this.startDate == undefined) {
        delete data['fromDate']
      }
      if (!this.endDate || this.startDate == undefined) {
        delete data['toDate']
      }
      if (!this.searchQuery) {
        delete data['query']
      }
      const res: any = await this.ticketSystemService.downloadTickets(data)
      if (res.Status) {
        window.open(res.data, '_blank')
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }


  async downloadAllTickets() {
    try {
      let selectedStatus = this.sourceType?.length > 0 ? this.sourceType.filter(el => el.active == true).map(el => (el.Name)) : []

      if (this.statusCode) {
        selectedStatus.push(this.statusCode)
      }
      let data = {
        "perPage": 999999,
        "StatusID[]": selectedStatus,
        "fromDate": this.startDate,
        "toDate": this.endDate,
        "page": this.currentPage,
        "query": this.searchQuery,
        "format": "xlsx"
      }
      if (selectedStatus.length == 0) {
        delete data['StatusID[]']
      }
      if (!this.startDate || this.startDate == undefined) {
        delete data['fromDate']
      }
      if (!this.endDate || this.startDate == undefined) {
        delete data['toDate']
      }
      if (!this.searchQuery) {
        delete data['query']
      }
      const res: any = await this.ticketSystemService.downloadTickets(data)
      if (res.Status) {
        window.open(res.data, '_blank')

      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }


  async getButton() {

    try {
      const res: any = await this.ticketSystemService.getButtons()

      if (res.Status) {
        this.buttons = res.data.ACTION_BUTTONS.ACTION_BUTTON_TICKETS

      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }

  }

  async changeTicketStatus(event: any) {
    if (!!event) {

      try {
        const res: any = await this.ticketSystemService.updateMultipleTicketsStatus(this.selectedIds, event)
        if (res.Status) {
          this.selectedIds = [];
          this.getTickets();
          this.ticketSystemService.refreshCardandCount.next(true)
        } else {
          this.commonService.showToast('error', "Error", res.message)
        }
      } catch (err) {
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      }

    }

  }

  pageChange(event) {
    this.currentPage = event
    this.getTickets()
  }

  onChangeNumberOfPage() {
    this.getTickets()
  }

  ticketTypeOutputEvent(event) {
    this.ticketTypes = event
  }

  async getTicketTypes() {
    try {
      const res: any = await this.ticketSystemService.getTicketTypes()
      if (res.Status) {
        this.ticketTypes = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  performAction(button, addEditTicketModal, TicketTypeModal, groupModal, addEditGroupModal) {
    switch (button.Name) {
      case 'ADD_TICKET': {
        this.ticketId = null
        this.openModal(addEditTicketModal)
        break;
      };
      case 'VIEW_TICKET_TYPE': {
        this.openModal(TicketTypeModal)
        break;
      };
      case 'DOWNLOAD_TICKETS': {
        this.downloadTickets()
        break;
      };
      case 'DOWNLOAD_TS_STATUS_REPORT': {

        break;
      };
      case 'ADD_GROUPS': {
        this.openModal(addEditGroupModal)
        break;
      };
      case 'VIEW_GROUPS': {
        this.openModal(groupModal)
        break;
      };
    }
  }

  selectedIds: number[] = [];
  toggleCheckbox(id: number) {
    if (this.isSelected(id)) {
      this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  selectAllCheckboxes(data: any) {
    const itemIds = this.data.map(item => item.id);
    const allSelected = itemIds.every(id => this.isSelected(id));

    if (allSelected) {
      itemIds.forEach(id => {
        const index = this.selectedIds.indexOf(id);
        if (index !== -1) {
          this.selectedIds.splice(index, 1);
        }
      });
    } else {
      itemIds.forEach(id => {
        if (!this.isSelected(id)) {
          this.selectedIds.push(id);
        }
      });
    }
  }
  isSelected(id: number): boolean {
    return this.selectedIds.includes(id);
  }

  async getTicketsStatus() {
    try {
      const res: any = await this.ticketSystemService.getTicketsStatus()
      if (res.Status) {
        this.allStatusList = res.data.map(obj => ({
          ...obj,
          active: false
        }));
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  onSort(event) {
    this.data.sort((a, b) => {
      if (event.direction === 'asc') {
        if (a[event.column] < b[event.column]) return -1;
        if (a[event.column] > b[event.column]) return 1;
        return 0;
      } else {
        if (a[event.column] > b[event.column]) return -1;
        if (a[event.column] < b[event.column]) return 1;
        return 0;
      }
    });
  }
}