import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plan-card-skeleton',
  templateUrl: './plan-card-skeleton.component.html',
  styleUrls: ['./plan-card-skeleton.component.scss']
})
export class PlanCardSkeletonComponent {
  @Input() numberOfCards: number = 3; // Default to 3 cards if no input is provided
  cardArray: number[] = [];

  ngOnInit(): void {
    // Dynamically set the number of skeleton cards based on the input
    this.cardArray = Array(this.numberOfCards).fill(0);
  }
}
