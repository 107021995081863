import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RenewalListComponent } from "./renewal-list/renewal-list.component";
import { RenewalDetailComponent } from "./renewal-detail/renewal-detail.component";


const routes: Routes = [
    {
        path: "",
        component: RenewalListComponent,
    },
    {
        path: "details",
        component: RenewalDetailComponent,
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RenewalReportsRoutingModule { }
