import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardNumber'
})
export class CreditCardNumberPipe implements PipeTransform {
  transform(value: string): string {
    // Remove existing spaces and format the number into groups of 4 digits
    return value.replace(/\s+/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  }
}
