<div class="container-fluid tab-form-wrapper">
  <form>
  <div class="row form-group">
    <div class="col-md-3">
<label>Service Initialization Date</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Disconnection Date</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Reconnection Date</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Xerox Approval Date</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Xerox Denial Date</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
 
  <div class="row form-group">
    <div class="col-md-3">
      <h6 class="m-0" >Eligible Program</h6>
      </div>
      <div class="col-md-9">
        <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Supplemental Nutrition Assistance Program SNAP| Food Stamps</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Supplemental Security Income |SS||</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Federal Public housing Assistance</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Veletors Person and Survivor Benefit Program</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Special Supplemental and Nutrition Program for Women, Incant and Children</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Food Distribution Program on Indian Reservation</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">School Lunch/Breakfast Program</label>
         </div>
         <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Federal Fell Grant</label>
         </div>
        </div>
   
    </div>
    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-danger me-3">Close</button>
  
        <button type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
  </div>