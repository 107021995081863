import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Echo, { Channel } from 'laravel-echo';
import Pusher from 'pusher-js';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { addNotification } from 'src/app/store/notifications/notification.actions';
import { Notification } from 'src/app/store/notifications/notification.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EchoService {
  private echo: Echo; // Define 'echo' as an instance

  constructor(private store: Store<AppState>) {
    this.initEcho();
  }

  private initEcho(): void {
    window['Pusher'] = Pusher;


    this.echo = new Echo({
      broadcaster: 'reverb',
      key: environment.REVERB_APP_KEY,
      wsHost: environment.REVERB_HOST,
      wsPort: environment.REVERB_PORT,
      wssPort: environment.REVERB_PORT,
      forceTLS: environment.REVERB_SCHEME === 'https',
      enabledTransports: ['ws', 'wss'], // WebSocket and Secure WebSocket
    });

    console.log('Echo initialized with Reverb broadcaster.');

    console.log(this.echo);
  }

  /**
   * Subscribe to a channel
   * @param channelName Name of the channel
   */
  subscribe(channelName: string): Channel  {
      // Correct type for 'subscribe'
    return this.echo.channel(channelName);
  }

  /**
   * Unsubscribe from a channel
   * @param channelName Name of the channel
   */
  unsubscribe(channelName: string): void {
    this.echo.leave(channelName);
  }

  subscribeToNotifications(): Observable<Notification> {
    return new Observable((observer) => {
      console.log('Subscribing to notifications');
      const channel = this.echo.channel('new_notification'); // Replace with your channel name
      channel.listen('NewNotification', (data: Notification) => {
        observer.next(data); // Emit the notification
      });
  
      // Cleanup when unsubscribed
      return () => {
        this.echo.leave('new_notification');
        console.log('Unsubscribed from notifications');
      };
    });
  }
}
