import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotesService } from './services/notes.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { MandrillEmailServiceService } from 'src/app/customer-email/services/mandrill-email-service.service';
declare global {
  interface String {
    hashCode(): number;
  }
}

String.prototype.hashCode = function () {
  let hash = 0, i, chr;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // hash * 31 + chr
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

@Component({
  selector: 'app-customer-notes',
  templateUrl: './customer-notes.component.html',
  styleUrls: ['./customer-notes.component.scss'],
  animations: [fadeInOut]
})
export class CustomerNotesComponent implements OnInit, OnDestroy {
  @Input() isCalledFromCustomerProfile: boolean = false;
  activeTab: string = 'customer-notes'; // Default tab
  @Input('isFooter') isFooter: boolean = false;
  allNotesType: any;
  allNotes: any = [];
  formData: FormGroup;
  hasMoreData = true;
  isVisible: boolean = true
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 50;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];
  public loader: boolean = false;
  selectedNoteTypeId: any; // Holds the selected id
  @LocalStorage()
  private CustomerId;
  activeNavItem: string = 'customerNotes';
  customerNameSubscription: Subscription
  customerName: string;
  firstLettersCustomerName: string;
  noNotesEventSubscription: Subscription;
  isNoNotes: boolean;
  isSkeletonLoader: boolean = true;
  isSidebarOpen: boolean;
  emailContent: any;
  ticketId: any;
  isEditing: boolean;
  setActive(navItem: string) {
    this.activeNavItem = navItem;
  }
  constructor(
    public fb: FormBuilder,
    public notesService: NotesService,
    public commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal, public customerProfileService: CustomerProfileService,
    private httpService: HttpService,
    private MandrillEmailServiceService: MandrillEmailServiceService
  ) {
  }
  ngOnDestroy(): void {
    this.noNotesEventSubscription.unsubscribe()
  }

  ngOnInit() {
    this.noNotes()
    this.initializeFormData()
    this.getData()
    this.getNotesTypes()
    this.customerNameSubscription = this.customerProfileService.customerNameEvent$.subscribe(data => {
      this.customerName = data;
      const abbr = this.extractFirstLetters(this.customerName);
      this.firstLettersCustomerName = abbr
    })
  }


  initializeFormData() {
    this.formData = this.fb.group({
      "Text": [""],
      "NoteTypeID": [],
      "PriorityID": [1],
      "IsActive": [1],
      "IsDeleted": [0]
    })
  }

  getCustomerId() {
    return this.CustomerId;
  }


  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop;
    const totalHeight = element.scrollHeight;
    const visibleHeight = element.clientHeight;
    if (scrollPosition + visibleHeight >= totalHeight - 100 && !this.loader && this.hasMoreData) {
      if (this.hasMoreData) {
        this.getData();
      }
    }
  }

  async loadMoreData() {
    if (this.hasMoreData && !this.loader) {
      this.loader = true;

      try {
        let data = {
          "per_page": this.itemsPerPage,
          "page": this.currentPage,
          "CustomerId": this.httpService.getCustomerId()
        };

        const res: any = await this.notesService.getNotes(data);

        if (res.Status) {
          const newNotes = res.data.data || [];
          console.log('newNotes', newNotes);

          // Filter unique notes
          const uniqueNotes = newNotes.filter(
            (newNote: any) => !this.allNotes.some((existingNote: any) => existingNote.id === newNote.id)
          );
          console.log('uniqueNotes', uniqueNotes);
          if (uniqueNotes.length > 0) {
            this.allNotes = [...(this.allNotes || []), ...uniqueNotes];
            this.totalItems = res.data.total;
            this.currentPage = res.data.current_page + 1;
            this.hasMoreData = !!res.data.next_page_url;

            // Group timeline items after updating notes
            this.groupTimelineItems();
          } else {
            this.hasMoreData = false;
          }
        } else {
          this.commonService.showToast('error', "Error", res.message);
          this.hasMoreData = false;
        }
      } catch (error) {
        console.error('Error loading notes:', error);
        this.commonService.showToast('error', "Error", 'Failed to load more notes.');
      } finally {
        this.loader = false;
      }
    }

    if (!this.hasMoreData) {
      this.commonService.showToast('info', "Info", 'No More Data To Show');
    }
  }



  async getData() {
    this.loader = true;
    try {
      let data = {
        "perPage": this.itemsPerPage,
        "page": this.currentPage,
        "CustomerId": this.httpService.getCustomerId()
      };

      const res: any = await this.notesService.getNotes(data);
      console.log(res);
      if (res.Status) {
        const newNotes = res.data.data || [];
        console.log("uniqueNotes", newNotes);
        // Filter out duplicate notes based on unique identifier (e.g., id)
        if (this.allNotes.length > 0) {
          const uniqueNotes = newNotes.filter(
            (newNote: any) => !this.allNotes.some((existingNote: any) => existingNote.id === newNote.id)
          );
          console.log("uniqueNotes", uniqueNotes);
          this.allNotes = [...(this.allNotes || []), ...uniqueNotes];
        } else {
          this.allNotes = newNotes
        }

        // Append only unique notes to allNotes
        console.log("allNotes", this.allNotes);
        // Update pagination and other details
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page + 1;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
        this.hasMoreData = !!res.data.next_page_url;

        this.notesService.notesAvailable();

        // Convert CreatedDate to Date objects for allNotes
        this.allNotes.forEach(item => {
          item.CreatedDate = new Date(item.CreatedDate);
        });

        // Group the timeline items after processing
        this.groupTimelineItems();
        console.log("allNotes", this.allNotes);
      } else {
        this.commonService.showToast('error', "Error", res.message);
        this.notesService.noNotes();
        this.allNotes = [];
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"));
      this.allNotes = [];
    } finally {
      this.loader = false;
    }
  }


  groupTimelineItems(): void {
    const groupedItems: any = [];
    let currentGroup = '';

    // Sort by CreatedDate descending
    this.allNotes.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());

    // Group items based on their date
    this.allNotes.forEach(item => {
      if (!item?.group) {
        const itemGroup = this.getTimeGrouping(item.CreatedDate);
        if (itemGroup !== currentGroup) {
          groupedItems.push({ id: itemGroup.hashCode(), ...item, group: itemGroup });
          currentGroup = itemGroup;
        }
        groupedItems.push(item); // Insert the note
      }
    });
    this.allNotes = groupedItems;
    this.isSkeletonLoader = false
  }

  // Helper method to create a group marker
  createGroupMarker(group: string, item) {
    return {
      id: 0,
      CustomerID: 0,
      AgentID: 0,
      Text: group,
      CreatedDate: new Date(), // Set to now or a constant date
      type: 'group',
      customer: { id: 0, FirstName: '', LastName: '' },
      ...item // Mock customer data
    };
  }

  getTimeGrouping(date: Date): string {
    const today = new Date();
    const dayDiff = Math.floor((today.getTime() - date.getTime()) / (1000 * 3600 * 24));

    if (dayDiff === 0) {
      return 'Today';
    } else if (dayDiff <= 7) {
      return 'This Week';
    } else if (formatDate(today, 'MMMM y', 'en-US') === formatDate(date, 'MMMM y', 'en-US')) {
      return formatDate(date, 'MMMM y', 'en-US');
    } else if (today.getFullYear() === date.getFullYear()) {
      return formatDate(date, 'y', 'en-US');
    } else {
      return formatDate(date, 'y', 'en-US');
    }
  }

  // Add a hashCode method to create unique ids for group markers


  noNotes() {
    this.noNotesEventSubscription = this.notesService.noNotesEvent$.subscribe(isNoNotes => {
      this.isNoNotes = isNoNotes;



    })
  }
  async getNotesTypes() {
    try {
      const res: any = await this.notesService.getNotesTypes();

      if (res.Status) {
        this.allNotesType = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async onSubmit() {
    // 
    this.loader = true;
    try {
      const res: any = await this.notesService.addNote(this.formData.value)
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Added successfully!")
        this.onRefereshNotes()
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
      this.initializeFormData()
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
    // 
  }

  openModal(content?, size?) {
    this.isSidebarOpen = true
    // this.modalService.open(content, { backdropClass: "dark-modal", size: size, centered: true });
  }

  onRefereshNotes() {
    this.currentPage = 1
    this.allNotes = []
    this.getData()
  }

  onSelectNoteType() {
    const notetypeid = this.formData.get('NoteTypeID').value

    const notetype = this.allNotesType.find((it) => it.id == notetypeid)
    this.formData.get('Text').setValue(notetype?.Description)
  }

  onSelectNote(selectedNote: any) {
    if (selectedNote && selectedNote.id) {
      const selectedNoteTypeId = selectedNote.id;
      const selectedNoteType = this.allNotesType.find(it => it.id === selectedNoteTypeId);
      if (selectedNoteType) {
        this.formData.get('Text').setValue(selectedNoteType.Description);
      }
    }
  }
  extractFirstLetters(fullName: string): string {

    return fullName;
  }


  addNoteTypeEvent() {
    this.modalService.dismissAll()
    this.getNotesTypes()
  }

  addEditTicketEvent() {
    this.modalService.dismissAll()
  }

  pageChange(event) {
    this.currentPage = event
    this.getData()
  }

  onChangeNumberOfPage() {
    this.getData()
  }


  closeSidebar() {
    this.isSidebarOpen = false
  }

  saveTicket(e) {

  }

  resendEmail(item: any): void {
    // Check if emailId is valid before proceeding
    if (!item.emailId?.value) {
      this.commonService.showToast("error", "Invalid email ID", "Please provide a valid email ID.");
      return;
    }

    // Prepare data for sending the email
    const data = {
      emailId: item.emailId.value,
      toemail: ""  // Assuming this is to be set based on other logic
    };

    // Call the Mandrill service to resend the email
    this.MandrillEmailServiceService.reSendEmail(data).subscribe(
      (res: any) => {
        if (res.Status) {
          this.commonService.showToast("success", "Email sent successfully", "");
        } else {
          this.commonService.showToast("error", "Email resend failed", "An error occurred while resending the email.");
        }
      },
      (error) => {
        // Handle any error that occurs during the API call
        console.error("Error occurred while resending the email: ", error);
        this.commonService.showToast("error", "Email resend failed", "An error occurred while resending the email.");
      }
    );
  }

  previewEmail(item, content) {
    // Check if emailId is valid before proceeding
    if (!item.emailId?.value) {
      this.commonService.showToast("error", "Invalid email ID", "Please provide a valid email ID.");
      return;
    }

    // Prepare data for sending the email
    const data = {
      emailId: item.emailId.value  // Assuming this is to be set based on other logic
    };

    // Call the Mandrill service to resend the email
    this.MandrillEmailServiceService.getMandrillEmailPreview(data).subscribe(
      (res: any) => {
        if (res?.html) {
          this.emailContent = res?.html
          this.modalService.open(content, { centered: true, size: 'xl' })
        }
      },
      (error) => {
        // Handle any error that occurs during the API call
        console.error("Error occurred while resending the email: ", error);
        this.commonService.showToast("error", "Email resend failed", "An error occurred while resending the email.");
      }
    );
  }

  extractTicketId(activityDetail: string): string {
    const match = activityDetail.match(/Ticket#\d+/);
    return match ? match[0] : '';
  }

  // Extract the remaining text after Ticket#ID
  extractRemainingText(activityDetail: string): string {
    return activityDetail.replace(/Ticket#\d+/, '');
  }

  // Handle click event
  onTicketClick(activityDetail: string): void {
    // Extract the numeric ticket ID from the activityDetail string
    this.isSidebarOpen = false
    this.cdr.detectChanges();
    const match = activityDetail.match(/Ticket#(\d+)/);
    const ticketId = match ? parseInt(match[1], 10) : null;

    if (ticketId !== null) {
      console.log(`Clicked Ticket ID: ${ticketId}`);
      this.ticketId = ticketId; // Set the extracted ticket ID
      this.isEditing = true;
      // Open the modal or sidebar
      this.isSidebarOpen = true
      this.cdr.detectChanges();
    } else {
      console.error('No valid Ticket ID found in activityDetail.');
    }
  }


}
