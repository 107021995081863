import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Output() onDateRangeChange = new EventEmitter<Date[]>();
  @Input() placeholder: string = 'Select Date Range';
  bsRangeValue: Date[];
  maxDate: Date = new Date();
  bsConfig = { 
    rangeInputFormat: 'MMM DD, YYYY', 
    adaptivePosition: true, 
    showWeekNumbers: false 
  };

  constructor() {
    // Default date range: last 30 days
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    this.bsRangeValue = [startDate, this.maxDate];
  }
}
