import { Component } from '@angular/core';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent {
  public customizer: string = "";
  constructor(){}
  
  Customizer(val) {
    this.customizer = val;
  }
}
