<div class="row">
    <div class="col-12">
        <b>{{data?.Title}}</b>
        <!-- <b>TKT33266 : Cancel order / services</b> -->
        <hr>
    </div>
</div>
<div class="row" [formGroup]="formData">
    <div class="col-12 col-md-8">
        <div>
            <h5>Description</h5>
            <p>
                {{data?.Description}}
            </p>
            <hr>
        </div>
        <div>
            <b class="text-primary">Comments</b>
        </div>
        <div *ngFor="let comment of data?.comments">
            <div class="row">
                <div class="col-11">
                    <div>
                        <p class="mb-0"><b>{{comment?.created_by_user?.name}}: </b>{{comment?.CommentText}}</p>
                    </div>
                    <div class="text-end">
                        <small class="text-muted">{{comment?.CreatedDate | date : 'shortTime'}}</small>
                    </div>
                </div>
                <div class="col-1">
                    <i class="fa fa-trash cursor-pointer text-danger" (click)="deleteComment(comment?.id)"></i>
                </div>
            </div>
            <hr>
        </div>


        <div>
            <br><br><br>
            <hr>
            <div class="row">
                <div class="col-12 my-2">
                    <div class="form-group">
                        <label>Comment <span class="text-danger">*</span></label>
                        <textarea formControlName="CommentText" maxlength="2000" class="form-control"
                            rows="5"></textarea>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <button type="submit" [disabled]="formData.invalid" (click)="onSubmit()"
                        class="btn btn-secondary">Post Comment</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-4 border-left">
        <div class="row">
            <div class="col-6">
                <p>Last Updated:</p>
            </div>
            <div class="col-6">
                <p>
                    {{data?.UpdatedDate | date: 'mediumDate'}}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p>Last Commented:</p>
            </div>
            <div class="col-6">
                -
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p>Created Date:</p>
            </div>
            <div class="col-6">
                <p>{{data?.CreatedDate | date : 'mediumDate'}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <label>Assign To</label>
                <select formControlName="AssigneeID" class="form-control">
                    <option value="">Select</option>
                    <option *ngFor="let agent of allAgents" [value]="agent?.UserID">{{agent?.name}}</option>
                </select>
            </div>
            <div class="col-6">
                <label>Status</label>
                <select formControlName="StatusID" class="form-control">
                    <option *ngFor="let item of allStatusList" [value]="item.id">{{item.Name}}</option>
                </select>
            </div>
            <div class="col-6 my-2">
                <label>Type</label>
                <select formControlName="TicketTypeID" class="form-control">
                    <option value="">Select</option>
                    <option *ngFor="let type of ticketTypes" [value]="type.id">{{type.Name}}</option>
                </select>
            </div>

        </div>
    </div>
</div>

