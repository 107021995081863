<div class="dashboard-container"
    [style.backgroundImage]="'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%) , url(' + dashboardBackground + ')'"
    [class.fade-out]="isFading">




    <div class="d-flex justify-content-between mx-3">

        <div class="d-flex flex-column ">
            <div (click)="onCardClick('personal_info')">
                <app-personal-info [minimalProfileData]="customerProfileService.minimalProfileData"></app-personal-info>
            </div>
            <div (click)="onCardClick('line_info')">
                <app-line-info [minimalProfileData]="customerProfileService.minimalProfileData"></app-line-info>
            </div>
            <div (click)="onCardClick('application_info')">
                <app-application-info
                    [minimalProfileData]="customerProfileService.minimalProfileData"></app-application-info>
            </div>
        </div>
        <div class="msg-box mid-content " *ngIf="isShowMidContent">
            <div class="d-flex text-white justify-content-end align-items-center gap-2 ">
                <i class="fa fa-minus"></i>
                <i class="fa fa-close" (click)="closeMidContent()"></i>
            </div>
            <div *ngIf="activeCard == 'personal_info'">
                <app-personal-info [minimalProfileData]="customerProfileService.minimalProfileData"
                    [fullScreen]="true"></app-personal-info>
            </div>
            <div *ngIf="activeCard == 'line_info'">
                <app-line-info [minimalProfileData]="customerProfileService.minimalProfileData"
                    [fullScreen]="true"></app-line-info>
            </div>
            <div *ngIf="activeCard == 'application_info'">
                <app-application-info [minimalProfileData]="customerProfileService.minimalProfileData"
                    [fullScreen]="true"></app-application-info>
            </div>
            <div *ngIf="activeCard == 'subscription_info'">
                <app-subscription-info [minimalProfileData]="customerProfileService.minimalProfileData"
                    [fullScreen]="true"></app-subscription-info>
            </div>
            <div *ngIf="activeCard == 'latest_Actvity'">
                <app-latest-activity [fullScreen]="true"></app-latest-activity>
            </div>
            <div *ngIf="activeCard == 'usage_info'">
                <app-usages-information [fullScreen]="true"></app-usages-information>
            </div>
            <div *ngIf="activeCard == 'add_Data_topTup'">
                <app-add-data></app-add-data>
            </div>
            <div *ngIf="activeCard == 'plan_change'">
                <app-plan-change></app-plan-change>
            </div>
            <div *ngIf="activeCard == 'change_Aps'">
                <app-change-aps></app-change-aps>
            </div>
            <div *ngIf="activeCard == 'equipment_purchase'">
                <app-equipment-purchase></app-equipment-purchase>
            </div>
            
            <div *ngIf="activeCard == 'features'">
                <app-features></app-features>
            </div>
        </div>
        <div class="d-flex flex-column ">
            <div class="card mt-3 mb-0 " (click)="onCardClick('latest_Actvity')"
                style="border-radius: 16px; max-width: 300px; border: 1px solid rgba(255, 255, 255, 0.2); backdrop-filter: blur(30px); background: transparent;">
                <div class="card-header p-3" style="background: transparent; border-bottom: 0px;">
                    <h4 class="card-title fs-6 mb-0 text-nowrap" class="text-white">Latest Activity</h4>
                </div>
                <div class="card-body pt-0">
                    <div class="mx-n3">
                        <div
                            style="height: 250px; max-height: 290px; max-width: 310px; overflow-y: auto; overflow-x: hidden;scrollbar-width: none;">
                            <div class="timeline-2 bg-green " *ngFor="let note of allNotes">
                                <div class="timeline-continue">
                                    <span *ngIf="note.group" class="text-center badge-container mb-3">
                                        <span class="custom-badge text-white">{{ note.group }}</span>
                                    </span>
                                    <div class="row timeline-right">
                                        <div class="col-12">
                                            <p class="timeline-date me-3 mb-0 bg-green"
                                                style="color: #bebebe; font-weight: 300;font-size: 12px;">{{
                                                note.CreatedDate | date: 'MMM d, y, h:mm a' }} </p>
                                        </div>
                                        <div class="col-12">
                                            <p class="mb-2"
                                                style="color: white; font-weight: 300; margin-left: 20px; font-size: 12px;">
                                                <ng-container *ngIf="note.type === 'ticketLog'; else otherTypes">
                                                    {{note.createdByUser}} {{ note.activityDetail }}
                                                </ng-container>
                                                <ng-template #otherTypes>
                                                    <ng-container *ngIf="note.type === 'note'">
                                                        {{ note.Text }}
                                                    </ng-container>
                                                    <ng-container *ngIf="note.type === 'activity'">
                                                        {{note.createdByUser?.value}} {{ note.activityDetail?.value }}
                                                    </ng-container>
                                                </ng-template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div (click)="onCardClick('subscription_info')">
                <app-subscription-info
                    [minimalProfileData]="customerProfileService.minimalProfileData"></app-subscription-info>
            </div>
            <div class="msg-box  pb-2  mt-3" (click)="onCardClick('usage_info')">
                <h5 class="text-white">Usage</h5>
                <app-usage-info></app-usage-info>
            </div>
        </div>
    </div>

    <div class="bottom-row d-flex justify-content-between px-3">
        <div class="d-flex justify-content-center align-items-center gap-2 w-100">
            <div class="bottom_icon_2 carousel_effect pointer d-flex align-items-center gap-3">
                <i class="fa fa-angle-left" (click)="changeBackground('previous')"></i>
                <img alt="background image" style="height: 30px; width: 60px; border-radius: 4px;"
                    [src]="dashboardBackground" />
                <i class="fa fa-angle-right" (click)="changeBackground('next')"></i>
            </div>

            <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect"
                (click)="onCardClick('add_Data_topTup')" data-toggle="tooltip" data-placement="top"
                title="Add Data Topup">
                <img src=" https://static.thenounproject.com/png/3593200-200.png" alt="" width="35px">

            </div>

            <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect"
                (click)="onCardClick('plan_change')" data-toggle="tooltip" data-placement="top" title="Plan Change">
                <img src="assets\images\new-dashboard\phone.png" alt="" width="35px">

            </div>

            <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect"
                (click)="onCardClick('change_Aps')" data-toggle="tooltip" data-placement="top" title="Change APS/WPS">
                <img src="assets\images\new-dashboard\disaster-recovery.png" alt="" width="35px">

            </div>

            <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect"
                (click)="onCardClick('equipment_purchase')" data-toggle="tooltip" data-placement="top"
                title="Equipment Purchase">
                <img src="assets\images\new-dashboard\payment-method.png" alt="" width="35px">

            </div>
            <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect"
                (click)="onCardClick('features')" data-toggle="tooltip" data-placement="top"
                title="Features">
                <img src="assets\images\new-dashboard\features.png" alt="" width="35px">

            </div>
        </div>

        <!-- <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect">
            <i class="fa fa-moon-o fs-5"></i>
        </div>
        <div ngbtooltip="Logout" placement="top" class="bottom_icon_2 pointer tran_effect">
            <i class="fa fa-moon-o fs-5"></i>
        </div> -->

        <div class="time-info text-white d-flex align-items-center">
            <img src="https://oges.nesr.com/assets/images/platform/Time_Clock.svg">
            <div class="d-flex flex-column mx-2" style="font-size: 12px;">
                <span>{{ currentTime }}</span>
                <span class="text-nowrap">{{ currentDate }}</span>
            </div>
        </div>
    </div>

</div>