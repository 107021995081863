<div class="card">
    <div class="card-header p-10">
        <h6 class="mb-0">Add Ad Hoc- Invoice</h6>
    </div>
    <div class="card-body">
        <form class="theme-form">
            <div class="mb-3 row">
                <div class="col-6">
                    <label for="inputEmail3">Customer ID:</label>
                    <input class="form-control" id="inputEmail3" type="text" placeholder="Customer ID:">
                </div>
                <div class="col-6">
                    <label for="inputEmail3">Telephone Number:</label>
                    <input class="form-control" id="inputEmail3" type="text" placeholder="Telephone Number:">
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-6">
                    <label for="inputEmail3">Invoice Type</label>
                    <input class="form-control" id="inputEmail3" type="text" placeholder="Invoice Type">
                </div>
                <div class="col-6">
                    <label for="inputEmail3">Amount ($)</label>
                    <input class="form-control" id="inputEmail3" type="text" placeholder="Amount ($)">
                </div>
            </div>

            <div class="row">
                <div class="col-4 my-auto">
                    <label class="col-form-label3">Do you want to add tax?:</label>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <div class="m-t-15 m-checkbox-inline custom-radio-ml">
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline1" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline2" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline2">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-6">
                    <label for="inputEmail3">Tax Amount ($):</label>
                    <input class="form-control" id="inputEmail3" type="text" placeholder="Tax Amount ($):">
                </div>
                <div class="col-6">
                    <label for="inputEmail3">Tax Breakup ($):</label>
                    <input class="form-control" id="inputEmail3" type="text" placeholder="Tax Breakup ($):">
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <label for="inputPassword3">Invoice Amount ($):</label>
                    <input class="form-control" id="Website" type="url" placeholder="Invoice Amount ($)">
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <label for="inputPassword3">Address</label>
                    <textarea class="form-control" placeholder="Address" rows="3"></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer text-end">
        <button class="btn btn-primary me-1">Submit</button>
        <button class="btn btn-secondary">Cancel</button>
    </div>
</div>