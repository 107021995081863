import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class EquipmentPurchaseService {

  api: string = environment.BASE_API;

  constructor(
    private http: HttpService
  ) { }

  getDeviceCombos() {
    try {
      const data = {
        "appAreaId[]": [2]
      }
      return this.http.get('api/Customer/GetDeviceCombos', data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  saveDeviceCombos(data) {
    try {
      return this.http.post('api/Customer/SaveDeviceCombos', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}