import { Component } from '@angular/core';

@Component({
  selector: 'app-money-order-renew',
  templateUrl: './money-order-renew.component.html',
  styleUrls: ['./money-order-renew.component.scss']
})
export class MoneyOrderRenewComponent {

}
