import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';
import Swal from 'sweetalert2';
import { CustomerDetailedInformationService } from '../../customer-profile/shared/customer-detailed-information.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardUpdateComponent } from 'src/app/subscriptions/credit-card-update/credit-card-update.component';

@Component({
  selector: 'app-subscription-info',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss']
})
export class SubscriptionInfoComponent {
  @Input() minimalProfileData: any;
  @Input() fullScreen: any;
  autoRenew: any;
  isAutoRenewModelOpen: boolean;
  customerSubscriptionData: any = []
  formFields: any = []
  subscriptionData: any;
  subscriptionFormData: any;
  moreInfoData: any;
  planFields: any;
  subscriptionMoreInformationData: any;
  showHistoryPage: boolean;
  showHistoryDetail: any;
  subscriptionDetail: any;
  constructor(public subscriptionService: SubscriptionService, 
    public commonService: CommonService, public customerProfileService: CustomerProfileService,
    public httpService :HttpService,
    public customerDetailedInformationService:CustomerDetailedInformationService,
    public modalService:NgbModal
  ) {

  }


  ngOnInit(): void {
    this.getCustomerSubscriptionInformation()
  }
  async getCustomerSubscriptionInformation() {
    try {
      const response: any = await this.customerProfileService.getCustomerSubscription(112, true);
      if (response?.Status) {
        this.customerSubscriptionData = response?.data
        this.subscriptionMoreInformationData = await this.formatSubscriptionInfo(response?.data);
        this.initializeFields();

        if (response?.data?.plan) {
          this.subscriptionMoreInformationData.plan = await this.formatSubscriptionPlanInfo(response?.data.plan);
        }
        delete this.subscriptionMoreInformationData?.Plan
        this.initializeFields()
        this.planFields = this.subscriptionMoreInformationData?.plan;
      } else {
      }
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message)
    }
  }

  formatSubscriptionInfo(subscriptionInfo: any) {
    const formattedInfo: any = {};
    for (const key in subscriptionInfo) {
      if (subscriptionInfo.hasOwnProperty(key)) {
        const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
        const formattedValue = this.customerDetailedInformationService.formatValue(subscriptionInfo[key]);
        formattedInfo[formattedKey] = formattedValue;

      }
    }
    return formattedInfo;
  }

  formatSubscriptionPlanInfo(planInfo: any) {
    const formattedPlan: any = {};

    if (Array.isArray(planInfo)) {
      formattedPlan.plans = planInfo.map(plan => {
        const formattedPlanItem: any = {};

        for (const key in plan) {
          if (plan.hasOwnProperty(key)) {
            const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
            const formattedValue = this.customerDetailedInformationService.formatValue(plan[key]);
            formattedPlanItem[formattedKey] = formattedValue;
          }
        }
        return formattedPlanItem;
      });
    } else {
      for (const key in planInfo) {
        if (planInfo.hasOwnProperty(key)) {
          const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
          const formattedValue = this.customerDetailedInformationService.formatValue(planInfo[key]);
          formattedPlan[formattedKey] = formattedValue;
        }
      }
    }
    return formattedPlan;
  }
  initializeFields() {
    const personalAttributes = ['First Name', 'Last Name', 'Email Address', 'Customer Id', 'Profile Id', 'Payment Profile Id'];
    const dateAttributes = ['Start Date', 'Next Billing Date', 'End Date', 'Created Date', 'Updated Date'];
    const subscriptionAttributes = ['Subscription Id', 'Subscription Type Id', 'Status', 'Remarks', 'Imei', 'Auto Renew', 'Enrollment Id'];
    const autoRenewAttribute = ['Auto Renew']
    const planAttributes = ['plan'];
    const remainingAttributes = [];
    const fields: any[] = [];
    personalAttributes.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        let value = this.subscriptionMoreInformationData[attr] || 'Not Available';


        fields.push({ controlName: attr, label: attr, value });
      }
    });
    autoRenewAttribute.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        const value = this.subscriptionMoreInformationData[attr] ? 'Yes' : 'No';
        fields.push({ controlName: attr, label: attr, value });
      }
    })
    dateAttributes.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        const value = this.customerDetailedInformationService.formatDate(this.subscriptionMoreInformationData[attr]) || 'Not Available';
        fields.push({ controlName: attr, label: attr, value });
      }
    });

    subscriptionAttributes.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        fields.push({ controlName: attr, label: attr, value: this.subscriptionMoreInformationData[attr] });
      }
    });


    for (const key in this.subscriptionMoreInformationData) {
      if (
        this.subscriptionMoreInformationData.hasOwnProperty(key) &&
        !personalAttributes.includes(key) &&
        !autoRenewAttribute.includes(key) &&
        !dateAttributes.includes(key) &&
        !subscriptionAttributes.includes(key) &&
        !planAttributes.includes(key)
      ) {
        remainingAttributes.push({ controlName: key, label: key, value: this.subscriptionMoreInformationData[key] });
      }
    }
    this.formFields = [...fields, ...remainingAttributes];
    console.log(this.formFields);
  }


  async autoRenewSub(event: any) {
    if (event.target.checked) {
      this.isAutoRenewModelOpen = true
    } else {
      this.withConfirmation()
    }
  }

  withConfirmation() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to cancel this subscription?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
      customClass: {
        popup: 'swal2-custom-width'
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.cancelsubscription()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.autoRenew = true
      }

    })
  }

  async cancelsubscription() {
    try {
      this.subscriptionService.cancelSubscription(this.customerSubscriptionData, 'Cancel Subscription via the Auto-Renewal Toggle in the Customer Profile.').subscribe(
        (res: any) => {
          if (res?.Status) {
            this.autoRenew = false;
            this.commonService.showToast('success', "sucess", res?.data);
            this.customerSubscriptions()
            this.customerProfileService.getMinimalProfileData(null, true)
          }
        },
        (error: any) => {
          this.autoRenew = true
          this.commonService.showToast('error', "Error", 'An error occurred while cancelling the subscription.');
        }
      );
    } catch (err) {
      this.autoRenew = true
      this.commonService.showToast('error', "Error", 'An unexpected error occurred.');
    }
  }

  async customerSubscriptions() {
    try {

      const res: any = await this.customerProfileService.getCustomerSubscription(112, true);

      if (res.Status) {
        this.customerSubscriptionData = res?.data || [];
        sessionStorage.setItem('SubscriptionId', this.customerSubscriptionData.id);
        this.subscriptionData = this.customerSubscriptionData || [];
        this.autoRenew = this.customerSubscriptionData.Status === 'Active' ? true : false;
        this.subscriptionFormData.patchValue(this.moreInfoData);
      } else {
        this.commonService.showToast('error', "Error", res.message);
      }
    } catch (error) {
      throw new Error(error.message || 'Error fetching customer subscriptions');
    } finally {

    }
  }

  async openHistoryPage(data: any) {
    this.showHistoryDetail = data;
    await this.getSubscriptionDetail()
    setTimeout(() => {
      this.showHistoryPage = true
    }, 40);
  }

  onPaymentHistoryClose(show: any) {
    this.showHistoryPage = show;
  }

  updatePaymentMethod(justUpdateAddress?: boolean) {
    const modalRef = this.modalService.open(CreditCardUpdateComponent, {
      backdropClass: "dark-modal", // Applies to backdrop
      windowClass: "dark-modal", // Applies to modal window
      centered: true
    });
    modalRef.componentInstance.subscriptionDetail = this.subscriptionDetail;
    modalRef.componentInstance.justUpdateAddress = justUpdateAddress;
  }


  async getSubscriptionDetail() {
    try {
      
      await this.subscriptionService.getDetail(this.subscriptionMoreInformationData?.Id).subscribe(
        (res: any) => {
          this.subscriptionDetail = res?.data;
          
        },
        (error: any) => {
           this.commonService.showToast('error', "Error", 'An error occurred while getting the subscription details.');
        }
      );
    } catch (error) {
      console.error("Exception caught:", error?.message);
      
    }
  }
}
