<div class="page" *ngIf="!isSkeletonLoader" @fadeInOutSlow>
  <!-- <div class="p-3" style="margin-top: -10px;">
  <div class="row">
      <div class="col-12">
          <app-breadcrumb></app-breadcrumb>
      </div>
  </div>
</div> -->
  <div class="container" >
    <div class="row"  >
      <h2 *ngIf="settingDetails?.[0]">{{settingDetails[0]?.DisplayName}}</h2>
      <small *ngIf="settingDetails?.[0]">{{settingDetails[0]?.Description}}</small>

      <p class="mt-0 mb-1" *ngIf="isHiddenData && isHiddenData[0]?.isHidden == 1">System Configuration</p>
      <div class="col-sm-10" *ngIf=" isHiddenData && isHiddenData?.[0]?.isHidden == 1">
        <div class="custom-card">
          <div class="card-body">
            <h4>New Order</h4>
            <small>{{settingInfo?.orderDetails[0]?.Description}} </small>
            <div class="row mt-4">
              <div class="col-md-6 d-flex justify-content-between"
                *ngFor="let orderDetail of settingInfo?.orderDetails[0]?.children">
                <p *ngIf="orderDetail?.DisplayName==='Service Area'"><i class="fa-solid fa-building-circle-xmark"></i>
                  &nbsp;{{orderDetail?.DisplayName}}</p>
                <p *ngIf="orderDetail?.DisplayName==='Plan Configuration'"> <i
                    class="fa-sharp fa-solid fa-arrows-rotate"></i> &nbsp;{{orderDetail?.DisplayName}}</p>
                <p><i class="fa-regular fa-circle-question hover-effect" title="{{orderDetail?.Description}}"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top"></i></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p *ngIf="isHiddenData?.[1]?.isHidden == 1" class="mt-3 mb-1">User Management</p>
      <div class="col-sm-10" *ngIf="isHiddenData?.[1]?.isHidden == 1">
        <div class="row">
          <div class="col-md-12">
            <div class="custom-card">
              <div class="card-body">
                <h4>User Management</h4>
                <small>{{settingInfo?.usermanagementDetails?.[0]?.Description}} </small>
                <div class="row mt-4">
                  <!-- <app-manage-user [manageUserDetails]="manageUserDetails"></app-manage-user> -->
                  <div class="d-flex align-items-center justify-content-between mb-2 col-md-6" style="font-size: 19px;">
                    <a classs="text-dark" style="font-size: 19px;" routerLink="/manage-user">
                      <i class="fa-sharp fa-solid fa-window-restore"></i>
                      <span class="mx-3">{{manageUserDetails?.DisplayName}}</span>
                    </a>
                    <i class="fa-regular fa-circle-question hover-effect"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-2 col-md-6" style="font-size: 19px;">
                    <a classs="text-dark" style="font-size: 19px;" routerLink="/manage-agent">
                      <i class="fa-sharp fa-solid fa-window-restore"></i>
                      <span class="mx-3">{{manageAgentDetails?.DisplayName}}</span>
                    </a>
                    <i class="fa-regular fa-circle-question hover-effect"></i>
                  </div>
                  <div class="d-flex justify-content-between col-md-6">
                    <p class="accountChange"><i class="fa-sharp fa-solid fa-window-restore "></i> &nbsp;&nbsp;
                      {{accountChangeHistory?.DisplayName}}</p>
                    <p><i #popoverElement class="fa-regular fa-circle-question  hover-effect "
                        [attr.title]="accountChangeHistory?.Description" data-bs-toggle="popover"
                        data-bs-trigger="hover" data-bs-class="custom-popover" data-bs-placement="top"></i></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-4" *ngIf="isHiddenData[2]?.isHidden == 1">
            <div class="custom-card">
              <div class="card-body">
                <h4>Customer Settings</h4>
                <small>{{settingInfo?.customerSettingDetails [0]?.Description}}
                </small>
                <div class="row mt-4 col-md-12">
                  <div class="d-flex justify-content-between col-md-6"
                    *ngFor="let customerDetail of settingInfo?.customerSettingDetails[0]?.children">
                    <p class="mb-0 "><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{customerDetail?.DisplayName}}
                    </p>
                    <p><i class="fa-regular fa-circle-question hover-effect" title="{{customerDetail?.Description}}"
                        data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                        data-bs-placement="top"></i></p>
                  </div>

                  <!-- <app-manage-note-types></app-manage-note-types> -->
                  <!--  <div  class="d-flex justify-content-between">
                    <p><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;Note Types
                    </p>
                    <p><i class="fa-regular fa-circle-question"></i></p>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ///////////////////////////////////////Group Management /////////////////////////////////// -->

      <p *ngIf="isHiddenData?.[3]?.isHidden == 1" class="mt-3 mb-1">Group Management</p>
      <div class="col-sm-10" *ngIf="isHiddenData?.[3]?.isHidden == 1">
        <!-- <div class="row">
      <div class="col-md-10"> -->
        <div class="custom-card">
          <div class="card-body">
            <h4>Group Management</h4>
            <small>{{settingInfo?.groupManagementDetails?.[0]?.Description}}</small>
            <div class="row mt-4">
              <div class="d-flex align-items-center justify-content-between mb-2" style="font-size: 19px;">
                <a classs="text-dark" style="font-size: 19px;" routerLink="/group-user">
                  <i class="fa-sharp fa-solid fa-window-restore"></i>
                  <span class="mx-3">{{groupUserDetails?.DisplayName}}</span>
                </a>
                <i class="fa-regular fa-circle-question hover-effect"></i>
              </div>
              <!-- <app-group-user [groupUserDetails]="groupUserDetails"></app-group-user> -->
              <div class="d-flex justify-content-between">
                <p class="accountChange"><i class="fa-sharp fa-solid fa-window-restore  "></i> &nbsp;&nbsp;
                  {{settingInfo?.groupManagementDetails?.[0]?.children?.[1]?.DisplayName}}</p>
                <p><i class="fa-regular fa-circle-question hover-effect"
                    [attr.title]="settingInfo?.groupManagementDetails?.[0]?.children?.[1]?.Description"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top"></i></p>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class="col-md-6">
        <div class="custom-card">
          <div class="card-body">
            <h4>Manage Partner Integration</h4>
            <small>Settings will allow you to control and update all system integrations.
            </small>
            <div class="row mt-4">
              <div class="d-flex justify-content-between">
                <p><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;Customer Notes</p>
                <p><i class="fa-regular fa-circle-question"></i></p>
               </div>
                <div  class="d-flex justify-content-between">
                <p><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;Customer Disconnections
                </p>
                <p><i class="fa-regular fa-circle-question"></i></p>

                </div>
                <div  class="d-flex justify-content-between">
                <p><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;Emails
                </p>
                <p><i class="fa-regular fa-circle-question"></i></p>
                </div>
            </div>
          </div>
        </div>
      </div> -->

        <!-- </div> -->
      </div>

      <p class="mt-4" *ngIf="isHiddenData?.[4]?.isHidden == 1" class="mt-3 mb-1">Combos Settings</p>
      <div class="col-sm-10" *ngIf="isHiddenData?.[4]?.isHidden == 1">
        <div class="row">
          <div class="col-12">
            <div class="custom-card">
              <div class="card-body">
                <h4>Combos Management</h4>
                <small>{{settingInfo?.comboManagementDetails?.[0]?.Description}}</small>
                <div class="row mt-4">
                  <div class="col-md-4">
                    <div class="d-flex align-items-center justify-content-between mb-2" style="font-size: 19px;">
                      <a classs="text-dark" style="font-size: 19px;" routerLink="/manage-combo">
                        <i class="fa-sharp fa-solid fa-window-restore"></i>
                        <span class="mx-3">{{settingInfo?.comboManagementDetails?.[0]?.children[0]?.DisplayName}}</span>
                      </a>
                 
                    </div>
                    <!-- <app-manage-combos
                      [manageComboDetails]="settingInfo?.comboManagementDetails?.[0]?.children[0]"></app-manage-combos> -->
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex align-items-center justify-content-between mb-2" style="font-size: 19px;">
                      <a classs="text-dark" style="font-size: 19px;" routerLink="/combo-item">
                        <i class="fa-sharp fa-solid fa-window-restore"></i>
                        <span class="mx-3">{{settingInfo?.comboManagementDetails?.[0]?.children[1]?.DisplayName}}</span>
                      </a>
                    </div>
                    <!-- <app-manage-combo-items
                      [manageComboItemsDetails]="settingInfo?.comboManagementDetails?.[0]?.children[1]"></app-manage-combo-items> -->
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex align-items-center justify-content-between mb-2" style="font-size: 19px;">
                      <a classs="text-dark" style="font-size: 19px;" routerLink="/manage-make-model">
                        <i class="fa-sharp fa-solid fa-window-restore"></i>
                        <span class="mx-3">{{settingInfo?.comboManagementDetails?.[0]?.children[2]?.DisplayName}}</span>
                      </a>
                    </div>
                    <!-- <app-manage-make-models
                      [manageMakeModelsDetails]="settingInfo?.comboManagementDetails?.[0]?.children[2]"></app-manage-make-models> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- //////////////////////////////////// other Settings ////////////////////////////////// -->
      <p class="mt-4" *ngIf="isHiddenData?.[5]?.isHidden == 1" class="mt-3 mb-1">Manage Partner Integration</p>
      <div class="col-sm-10" *ngIf="isHiddenData?.[5]?.isHidden == 1">
        <div class="custom-card">
          <div class="card-body">
            <h4>Other Settings</h4>
            <small>{{settingInfo?.otherSettingsDetails?.[0]?.Description}}</small>
            <div class="row mt-4">
              <div class="col-md-4 d-flex justify-content-between">
                <p>
                  <i class="fa-solid fa-building-circle-xmark"></i>
                  &nbsp;{{settingInfo?.otherSettingsDetails?.[0]?.children?.[0]?.DisplayName}}
                </p>
                <p><i class="fa-regular fa-circle-question hover-effect"
                    [attr.title]="settingInfo?.otherSettingsDetails?.[0]?.children?.[0]?.Description"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top"></i></p>
              </div>

              <div class="col-md-4 d-flex justify-content-between">
                <p><i class="fa-sharp fa-solid fa-arrows-rotate"></i>
                  &nbsp;&nbsp;{{settingInfo?.otherSettingsDetails[0]?.children[1]?.DisplayName}}</p>
                <p><i class="fa-regular fa-circle-question hover-effect"
                    [attr.title]="settingInfo?.otherSettingsDetails[0]?.children[1]?.Description"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top"></i></p>
              </div>

              <div class="col-md-4 d-flex justify-content-between cursor-pointer" routerLink="/tag-list">
                <p><i class="fa-sharp fa-solid fa-arrows-rotate"></i>
                  &nbsp;&nbsp;{{settingInfo?.otherSettingsDetails[0]?.children[2]?.DisplayName}}</p>
                <p><i class="fa-regular fa-circle-question hover-effect"
                    [attr.title]="settingInfo?.otherSettingsDetails[0]?.children[2]?.Description"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top"></i></p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <p class="mt-4" *ngIf="isHiddenData?.[6]?.isHidden == 1" class="mt-3 mb-1">Manage Resources</p>
      <div class="col-sm-10" *ngIf="isHiddenData?.[6]?.isHidden == 1">
        <div class="custom-card">
          <div class="card-body">
            <h4>Manage Resources</h4>
            <small>{{settingInfo?.manageResourceSetting?.[0]?.Description}}</small>
            <div class="row mt-4">
              <div class="col-md-6 d-flex justify-content-between cursor-pointer " (click)="detail()">
                <p>
                  <i class="fa-solid fa-building-circle-xmark "></i> &nbsp;
                  {{settingInfo?.manageResourceSetting?.[0]?.DisplayName}}
                </p>
                <p><i class="fa-regular fa-circle-question hover-effect"
                    [attr.title]="settingInfo?.otherSettingsDetails?.[0]?.children?.[0]?.Description"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top"></i></p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 text-center">
        <p>© 2024 {{companyName}} Terms of Service | Privacy Policy | </p>
      </div>
    </div>
 
</div>

   
</div>
<div *ngIf="isSkeletonLoader" @fadeInOutSlow class=" container d-flex flex-column gap-4 mx-8"  >
<app-skeleton-loader [cardCount]="1" [width]="'80vw'" [height]="'10vh'" [alignment]="'column'"  shape="normal-card"></app-skeleton-loader>
<app-skeleton-loader [cardCount]="2" [width]="'45%'" [height]="'20vh'" [alignment]="'row'"  shape="normal-card"></app-skeleton-loader>
<app-skeleton-loader [cardCount]="5" [width]="'80vw'" [height]="'10vh'" [alignment]="'column'"  shape="normal-card"></app-skeleton-loader>

</div>


<div *ngIf="showManageUser">
  <app-manage-resources (close)="closeModal()"></app-manage-resources>
</div>

