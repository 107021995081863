<div class="container-fluid" style="height:80vh">
  
 
  
    <!-- Additional Details Section -->
    <div class="row skeleton-additional-details mb-4" style="height:40vh">
      <div class="col-md-12">
        <div class="skeleton-details-card p-3">
          <div class="skeleton-line mb-2"></div>
          <div class="skeleton-line mb-2"></div>
          <div class="skeleton-line mb-2"></div>
          <div class="skeleton-line"></div>
        </div>
      </div>
    </div>
  
    <!-- Old and New Plan Cards -->
    <div class="row skeleton-plan-cards" >
      <div class="col-md-6">
        <div class="skeleton-plan-card p-4" style="height:30vh">
          <div class="skeleton-plan-header mb-3"></div>
          <div class="skeleton-plan-content mb-2"></div>
          <div class="skeleton-plan-content"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="skeleton-plan-card p-4" style="height:30vh">
          <div class="skeleton-plan-header mb-3"></div>
          <div class="skeleton-plan-content mb-2"></div>
          <div class="skeleton-plan-content"></div>
        </div>
      </div>
    </div>
  
  </div>
  