import { Component, OnInit } from '@angular/core';
import { PurchaseHistoryService } from './services/purchase-history.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss'],
  animations:[fadeInOut]
})
export class PurchaseHistoryComponent implements OnInit {

  data: any = [];
  datesData: any;
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];
isSkeletonLoader: boolean = true;

  headers: any = [
    { display: 'First Name', key: 'FirstName' },
    { display: 'Last Name', key: 'LastName' },
    { display: 'Name', key: 'Name' },
    { display: 'Plan ID', key: 'PlanID' },
    { display: 'Renew Date', key: 'RenewDate' },
    { display: 'Transaction ID', key: 'TransactionID' },
    { display: 'Type', key: 'Type' }
  ]
  constructor(
    private purchaseService: PurchaseHistoryService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit() {
    this.getData()
  }

  async getData() {

    try {
      let data = {
        "per_page": this.itemsPerPage,
        "page": this.currentPage,
      }
      const res: any = await this.purchaseService.getPlanHistory(data)

      if (res.Status) {
        this.data = res.data[0]?.data
        this.totalItems = res.data[0].total
        this.currentPage = res.data[0].current_page
        this.first_page_url = res.data[0].first_page_url
        this.last_page_url = res.data[0].last_page_url
        this.next_page_url = res.data[0].next_page_url
        this.datesData = res.data[1]
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast("error", "Error", res.message);
        this.isSkeletonLoader=false
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.error.message);
      this.isSkeletonLoader=false
    }

  }

  pageChange(event) {
    this.currentPage = event
    this.getData()
  }

  onChangeNumberOfPage() {
    this.getData()
  }


}
