<div class="container bg-white py-3 px-5" *ngIf="!isSkeletonLoader"  @fadeInOut >
    <div class="d-flex align-items-center justify-content-between text-muted">
        <div class="d-flex align-items-center gap-2 justify-content-start w-100">
            <button (click)="back()" class="custom-btn  d-flex align-items-center">
                <i class="icon-arrow-left fw-semibold"></i>
            </button>
            <div class="d-flex gap-2 align-items-center">
                <i class="fa fa-money-bill"></i>
                <span>Payment</span>
            </div>
        </div>
        <span>{{transaction.Description}}</span>
    </div>

    <div class="d-flex align-items-center justify-content-between my-4">
        <div class="d-flex gap-2 align-items-center">
            <span class="text-black fw-semibold fs-4">
                {{ transaction.Amount }}
            </span>
            <span class="fs-4"> USD</span>
            <span class="custom-badge d-flex align-items-center"
                [ngClass]="{'bg-success': transaction.PaymentStatus === 'RECEIVED', 'bg-danger ': transaction.PaymentStatus === 'FAILED'}">
                {{ transaction.PaymentStatus }}
                <i *ngIf="transaction.PaymentStatus === 'RECEIVED'" class="fa fa-check"></i>
                <i *ngIf="transaction.PaymentStatus === 'FAILED'" class="fa fa-x"></i>
            </span>
        </div>
        <div class="d-flex gap-2  align-items-center">
            <app-filter-btn [title]="'Refund'" [isBtn]="true" (click)="onRefundClick(refundModal)"></app-filter-btn>
            <app-filter-btn [icon]="'fa fa-ellipsis'" [isBtn]="true"></app-filter-btn>
        </div>
    </div>

    <div class="info-wrapper">
        <div class="d-flex align-items-center gap-4 p-3">
            <div class="border-light">
                <div class="d-flex align-items-center gap-2 flex-column justify-content-start w-100">
                    <span class="fw-semibold text-muted fs-5">Last update</span>
                    <span class="fw-semibold">{{transactionDetail?.submitTimeUTC | date}} </span>
                </div>
            </div>
            <div class="border-light">
                <div class="d-flex align-items-center flex-column gap-2 justify-content-start w-100">
                    <span class="fw-semibold text-muted fs-5">Customer</span>
                    <span class="fw-semibold">{{transactionDetail?.billTo?.firstName + " " +transactionDetail?.billTo?.lastName}}</span>
                </div>
            </div>
            <div class="border-light">
                <div class="d-flex align-items-center flex-column gap-2 justify-content-start w-100">
                    <span class="fw-semibold text-muted fs-5">Payment Method</span>
                    <div class="d-flex align-items-center gap-2 justify-content-start ">
                        <img width="30" height="30" src="../../../assets/svg/visa.png"
                            *ngIf="transactionDetail?.payment?.creditCard?.cardType=='Visa'">
                        <img width="30" height="30" src="assets/svg/card.png"
                            *ngIf="transactionDetail?.payment?.creditCard?.cardType=='MasterCard'">
                        <img width="30" height="30" src="assets/svg/symbol.png" *ngIf="transactionDetail?.payment?.creditCard?.cardType=='Klarna'">
                        <span>
                            {{ transactionDetail?.payment?.creditCard?.cardNumber}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div class="d-flex justify-content-between my-3 ">
        <span class=" text-black fw-semibold fs-4">Timeline</span>
        <app-filter-btn [title]="'Add note'" [isBtn]="true"></app-filter-btn>
    </div>
    <div class="info-wrapper p-3">
        <app-timeline [events]="timelineEvents"></app-timeline>
    </div>

    <div class="d-flex justify-content-between my-3 ">
        <span class=" text-black fw-semibold fs-4">Checkout summary</span>
    </div>
    <div class="info-wrapper border-bottom-0 p-3">
        <div class="d-flex gap-4">
            <span class="text-muted">Customer</span>
            <div class="d-flex flex-column gap-1">
                <span class=" text-muted ">{{transactionInfo?.customer?.Email}}</span>
                <span class=" text-muted ">{{transactionInfo?.customer?.FirstName + " " +transactionInfo?.customer?.LastName}}</span>
                <span class=" text-muted ">{{transactionDetail?.billTo?.address}}</span>
                <span class=" text-muted ">{{transactionDetail?.billTo?.city}} , {{transactionDetail?.billTo?.state}}</span>
            </div>
        </div>
    </div>

    <div class="">
        <div class="item-details-container">
            <table class="table">
                <thead>
                    <tr>
                        <th>ITEMS</th>
                        <th>QTY</th>
                        <th>UNIT PRICE</th>
                        <th>AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of transactionInfo?.invoice?.invoice_items">
                        <td>
                            <img src="https://api.aqsatel.com/get-image/Sim_Card_Only.png" alt="Item Image" width="50" height="auto">
                            <strong>{{ item.name }}</strong>
                            <br>
                            <small>{{ item.description }}</small>
                        </td>
                        <td>1</td>
                        <td>${{ transaction.Amount }}</td>
                        <td>${{ transaction.Amount }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3">Total</td>
                        <td>${{transactionInfo?.invoice?.TotalAmount}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>


    <div class="d-flex justify-content-between my-3 ">
        <span class=" text-black fw-semibold fs-4">Payment breakdown</span>
    </div>
    <div class=" border-bottom-0 py-3">
        <div class="d-flex flex-column w-100 justify-content-start">
            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                <span class="text-muted">Payment amount</span>
                <span class="text-muted">${{transactionInfo?.invoice?.TotalAmount}} USD</span>
            </div>
            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                <span class="text-muted">Tax</span>
                <span class="text-muted">-$0.59 USD</span>
            </div>
            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                <span class="text-bold">Total</span>
                <span class="">${{transactionInfo?.invoice?.TotalAmount}} USD</span>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Payment details</span>
    </div>

    <div class="info-wrapper p-3">
        <div class="d-flex flex-column gap-2 ">
            <div class="d-flex justify-content-between ">
                <span class="text-muted">Statement descriptor</span>
                <span>{{transactionInfo?.payment?.MessageCode}}</span>
            </div>
            <div class="d-flex justify-content-between ">
                <span class="text-muted">Description</span>
                <span *ngIf="transaction.Description; else editLink">{{ transaction.Description }}</span>
                <ng-template #editLink>
                    <a href="#" class="text-primary">Edit</a>
                </ng-template>
            </div>
        </div>
    </div>


    <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Payment method</span>
    </div>

    <div class="info-wrapper p-3">
        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">ID</span>
                <span>{{ transactionInfo?.transactionDetail?.transId }} </span>
            </div>


            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Owner</span>
                <span>{{ transactionInfo?.customer?.FirstName  }}{{ transactionInfo?.customer?.LastName  }}</span>
            </div>



        </div>

        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Number</span>
                <span> {{ transactionInfo?.transactionDetail?.payment?.creditCard?.cardNumber }}</span>
            </div>


            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Owner email</span>
                <span>{{ transactionInfo?.customer?.Email  }}</span>
            </div>


        </div>

        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Type</span>
                <span>{{ transactionInfo?.transactionDetail?.payment?.creditCard?.cardType }}</span>
            </div>
            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Zip check</span>
                <span [ngClass]="{'text-success': transaction.ZipCheck === 'Passed'}">{{ transaction.ZipCheck }}</span>
            </div>

            <!-- <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Issuer</span>
                <span>{{ transaction.CardIssuer }}</span>
            </div> -->

        </div>

        <div class="d-flex justify-content-between mb-3">
            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Street check</span>
                <span [ngClass]="{'text-success': transaction.StreetCheck === 'Passed'}">{{ transaction.StreetCheck
                    }}</span>
            </div>
    
        </div>
        <div class="d-flex justify-content-between mb-3">
            <!-- <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Fingerprint</span>
                <a href="#" class="text-primary">{{ transaction.CardFingerprint }}</a>
            </div> -->
            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Expires</span>
                <span>{{ transactionInfo?.transactionDetail?.payment?.creditCard?.expirationDate }}</span>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-3">

            <div class="d-flex align-items-center gap-2 justify-content-start w-100">
                <span class="text-muted">Address</span>
                <span>{{ transactionInfo?.transactionDetail?.billTo?.address }}</span>
            </div>

        </div>
    </div>


    <!-- <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Automatic tax calculation</span>
    </div>

    <div class="info-wrapper p-3">
        <div class="d-flex flex-column gap-2">
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Status</span>
                    <span class="badge bg-primary w-fit-content">Complete</span>
                </div>
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Shipping address</span>
                    <span>No address</span>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Tax location</span>
                    <span>California, United States 🇺🇸</span>
                </div>
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Customer billing address</span>
                    <span>CA, 93637-5729 United States 🇺🇸</span>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Tax amount</span>
                    <span>$0.00 USD</span>
                </div>
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Payment method country</span>
                    <span>Not determined</span>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Customer Tax ID</span>
                    <span>No Tax ID provided</span>
                </div>
                <div class="d-flex flex-column w-100 justify-content-start">
                    <span class="text-muted">Customer IP address</span>
                    <span>73.12.225.207 United States 🇺🇸</span>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column  justify-content-start">
                    <span class="text-muted">Taxability</span>
                    <span class="badge bg-warning">Not registered</span>
                </div>
            </div>
        </div>
    </div>


    <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Risk insights</span>
    </div>

    <div class="info-wrapper p-3">
        <div class="d-flex flex-column gap-2">
            <div class="d-flex justify-content-between align-items-center">
                <span>Upgrade to Radar for Fraud Teams to get advanced fraud insights.</span>
                <a href="#" class="btn btn-primary">Try it free for 30 days</a>
            </div>

            <div class="d-flex justify-content-between my-3">
                <div>
                    <span class="text-muted ">USER AGENT</span>
                    <div class="d-flex flex-column gap-1 mt-2">
                        <span>Operating system: Mac OS X 10.15.4</span>
                        <span>Browser: Chrome</span>
                        <span>Device: Desktop</span>
                    </div>
                </div>
                <div>
                    <span class="text-muted ">LOCATION</span>
                    <div class="d-flex flex-column gap-1 mt-2">
                        <span>Device IP address: 73.12.225.207</span>
                        <span>Card billing address: CA, 93637 United States</span>
                    </div>
                </div>
                <div class="d-flex flex-column gap-2">
                    <span class="text-muted ">DISTANCE</span>
                    <img height="100"
                        src="https://storage.googleapis.com/gweb-uniblog-publish-prod/images/google_maps_helpful_hero_1.width-1200.format-webp.webp"
                        alt="Map showing distance">
                </div>
            </div>

            <div class="d-flex justify-content-between flex-column ">
                <span class="text-muted ">Related payments</span>
                <div class="table-responsive">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th>RISK</th>
                                <th>AMOUNT</th>
                                <th>STATUS</th>
                                <th>SOURCE</th>
                                <th>CUSTOMER</th>
                                <th>DEVICE IP ADDRESS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>30</td>
                                <td>$50.00</td>
                                <td>Disputed</td>
                                <td>Visa</td>
                                <td>Ramona Patzan</td>
                                <td>73.12.225.207</td>
                            </tr>
                            <tr>
                                <td>40</td>
                                <td>$20.00</td>
                                <td>Disputed</td>
                                <td>Visa</td>
                                <td>Ramona Patzan</td>
                                <td>73.12.225.207</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Metadata</span>
        <app-filter-btn [icon]="'fas fa-edit'" [isBtn]="true"></app-filter-btn>
    </div>
    <div class=" p-3">
        <div class="metadata-box p-3 border rounded-lg text-center">
            <span class="text-muted">No metadata</span>
        </div>
    </div> -->
    <!-- Connections Section -->
    <!-- <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Connections</span>
    </div>
    <div class="p-3">
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-between my-2">
                <span class="text-muted w-100">Latest charge</span>
                <span class="w-100">ch_305IiQH9B5qg8qQ0Q9ZDJxVd</span>
            </div>
            <div class="d-flex justify-content-between my-2">
                <span class="text-muted w-100">Payout</span>
                <a href="#" class="text-primary w-100">po_106NXvH9B5qg8qQ0WZ0NkP8F</a>
            </div>
        </div>
    </div> -->

    <!-- Receipt History Section -->

    <!-- <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Receipt history</span>
    </div>
    <div class="p-3">
        <div class="d-flex justify-content-between">
            <span class="text-muted">No receipts sent</span>
            <div class="d-flex gap-2">
                <app-filter-btn [title]="'View receipt'" [isBtn]="true"></app-filter-btn>
                <app-filter-btn [title]="'Send receipt'" [isBtn]="true"></app-filter-btn>

            </div>
        </div>
    </div> -->

    <!-- Events and Logs Section -->
    <div class="d-flex justify-content-between my-3">
        <span class="text-black fw-semibold fs-4">Events and logs</span>
    </div>
    <div class="p-3">
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-between flex-column my-2 gap-2">
                <span class="text-muted">LATEST ACTIVITY</span>
                <span>PaymentIntent status: succeeded</span>
            </div>
        </div>
    </div>



    <div class="p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="all-activity">
              <span class="text-muted">ALL ACTIVITY</span>
              <!-- Timeline -->
              <!-- <div class="timeline">
                <div *ngFor="let activity of activities" class="timeline-item">
                  <div class="timeline-dot"></div>
                  <div class="timeline-content">
                    <p class="mb-1">{{ activity.title }}</p>
                    <small class="text-muted">{{ activity.time }}</small>
                    <div *ngIf="activity.status" class="status-label">
                      <span class="badge badge-pill badge-primary">{{ activity.status }}</span>
                    </div>
                  </div>
                </div>
              </div> -->
            <app-latest-activity [CustomerId]="transactionInfo?.customer?.CustomerId" *ngIf="transactionInfo?.customer?.CustomerId"></app-latest-activity>
            
            </div>
          </div>
        </div>
      </div>
      


</div>

<div *ngIf="isSkeletonLoader"  @fadeInOut  style="max-width: 100%;" class="container bg-white py-3 px-5" >
    <div class="card">
        <app-skeleton-loader shape="transaction-card"></app-skeleton-loader>
    </div>
</div>


<ng-template #refundModal let-modal>
    <div class="modal-header modal-copy-header p-2 bg-light text-dark">
        <h5 class="headerTitle mb-0">Refund Payment</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <!-- Refund Amount Field -->
        <div class="mb-3">
            <label for="refundAmount" class="form-label">Refund Amount</label>
            <input type="number" id="refundAmount" class="form-control" [(ngModel)]="refundAmount" placeholder="Enter amount" />
        </div>
        
        <!-- Refund Reason Field -->
        <div class="mb-3">
            <label for="refundReason" class="form-label">Refund Reason</label>
            <select id="refundReason" class="form-select" [(ngModel)]="refundReason">
                <option [value]="item.id" *ngFor="let item of refundReasonList">{{item.Description}}</option>
            </select>
        </div>
        <div class="d-flex justify-content-end gap-3 mt-2">
            <button class="btn btn-danger btn-sm" (click)="modal.dismiss('Cross click')">Cancel</button>
            <button class="btn btn-info btn-sm" (click)="confirmRefund()">Refund</button>
        </div>
    </div>
</ng-template>