<div class="credit-card">
    <div class="price-tag">
      $ {{price}} <!-- Bind price here -->
    </div>

    <div class="card-chip">
        <img src="../../../../assets/images/credit-cards/card-chip.jpg" alt="Chip" />
    </div>

    <div class="card-number" style="margin-top:43px">
      {{ accountNumber }}
    </div>

    <div class="card-details d-flex flex-row gap-2 my-2">
      <div class="card-holder">
        <label>Card Holder</label>
        <span>{{ nameOnCard }}</span>
      </div>
      <div class="expiry-date wrap">
        <label>Paid On</label>
        <span>{{ createdDate }}</span>
      </div>
    </div>

    <div class="card-logo">
        <img [src]="getCardLogo()" [alt]="cardType + ' logo'" />
    </div>
</div>
