<div class="container-fluid skeleton-form p-4">
    <!-- Title Skeleton -->
    <div class="skeleton-button mt-3 mb-3"></div>
  
    <!-- First and Last Name Skeleton -->
    <div class="row mb-3">
      <div class="col-md-6">
        <div class="skeleton-input"></div>
      </div>
      <div class="col-md-6">
        <div class="skeleton-input"></div>
      </div>
    </div>
  
    <!-- Card Number Skeleton -->
    <div class="row mb-3">
      <div class="col-12">
        <div class="skeleton-input"></div>
      </div>
    </div>
  
    <!-- Expire Month and Year Skeleton -->
    <div class="row mb-3">
      <div class="col-md-6">
        <div class="skeleton-select"></div>
      </div>
      <div class="col-md-6">
        <div class="skeleton-select"></div>
      </div>
    </div>
  
    <!-- CVV Skeleton -->
    <div class="row mb-3">
      <div class="col-12">
        <div class="skeleton-input"></div>
      </div>
    </div>
  
    <!-- Submit Button Skeleton -->
    <div class="skeleton-button mt-3"></div>
  </div>
  