<div class="container mt-0" style="max-width: 100%;">
  <div class="card form-card mt-0 shadow">
    <!-- Card Header -->
    <div class="card-header text-white text-center bg-primary">
      <h5 class="card-title mb-0" style="font-size: 1.5rem; letter-spacing: 2px;">ELIGIBILITY INFORMATION</h5>
    </div>

    <!-- Card Body with Form -->
    <div class="card-body">
      <form [formGroup]="eligibilityInfoFormData">
        <!-- Loop through form fields to display each field in its own row -->
        <ng-container *ngFor="let field of formFields">
          <div *ngIf="field.visible" class="row mb-3">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <label for="{{field.controlName}}" class="form-label mb-0" style="font-size: 1rem; font-weight: 500;">
                    {{ field.label }}
                  </label>
                </div>
                <div class="col-12 col-md-8">
                  <div class="input-group">
                    <!-- Display Mode (Default State) -->
                    <div *ngIf="!isEditing(field.controlName)" class="d-flex justify-content-between align-items-center w-100">
                      <div class="text-truncate p-2">{{ eligibilityInfoFormData.get(field.controlName)?.value }}</div>
                      <button type="button" class="btn btn-custom" (click)="toggleEdit(field.controlName)">
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>

                    <!-- Edit Mode -->
                    <div *ngIf="isEditing(field.controlName)" class="w-100">
                      <div class="d-flex justify-content-between align-items-center">
                        <!-- Input Field -->
                        <input [formControlName]="field.controlName" type="text" class="form-control form-control-lg" style="font-size: 0.8rem; height: 45px;"/>

                        <!-- Save and Cancel Buttons -->
                        <div class="d-flex ms-2">
                          <button type="button" class="btn btn-custom-save" (click)="updateCustomerProfile(field.controlName)">
                            <i class="fa fa-save"></i>
                          </button>
                          <button type="button" class="btn btn-custom-cancel ms-2" (click)="removeInputField(field.controlName)">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>

    
  </div>
</div>

<!-- Loader (if needed) -->
<div *ngIf="isLoading" class="card-loader">
  <app-loader [isChildLoader]="true"></app-loader>
</div>
