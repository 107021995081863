<div class="container-fluid card p-3">
  <div class="row">
    <div class="col-md-6">
      <h6>Manage Saved Card</h6>
    </div>
    <div class="col-md-6 text-end">
      <button type="button" class="btn btn-primary" >Add New Card</button>
    </div>
  </div>

<div class="custom-datatable table-responsive" *ngIf="!isSkeletonLoader">
  <table class="table">
      <thead>
          <tr>
              <th scope="col" sortable="name" (sort)="onSort($event)">#</th>
              <th scope="col" sortable="startDate">Name On Card</th>
              <th scope="col" sortable="invoice" (sort)="onSort($event)">Type of Card</th>
              <th scope="col" sortable="position" (sort)="onSort($event)">Last 4 digit</th>
              <th scope="col" sortable="credit" (sort)="onSort($event)">Is Credit Card Expired</th>
              <th scope="col" sortable="office" (sort)="onSort($event)">Is Primary </th>
              <th scope="col" sortable="Budget" (sort)="onSort($event)">Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let item of tableItem$ | async">
              <td scope="row">
                  <ngb-highlight [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
              </td>
              <td>
                  <ngb-highlight [result]="item.startDate" [term]="service.searchTerm"></ngb-highlight>
              </td>
              <td>
                  <ngb-highlight [result]="item.invoice" [term]="service.searchTerm"></ngb-highlight>
              </td>
              <td>
                  <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
              </td>
         
              <td>
                  <ngb-highlight [result]="item.office" [term]="service.searchTerm"></ngb-highlight>
              </td>
            
              <td>
                  <ngb-highlight [result]="item.Budget" [term]="service.searchTerm"></ngb-highlight>
              </td>
              <td>
                  <ngb-highlight [result]="item.Budget" [term]="service.searchTerm"></ngb-highlight>
              </td>
          </tr>
      </tbody>
  </table>
  <div class="d-flex justify-content-between mt-3">
      <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
          <option [ngValue]="2">2 items per page</option>
          <option [ngValue]="4">4 items per page</option>
          <option [ngValue]="6">6 items per page</option>
      </select>
      <ngb-pagination class="ms-3" [collectionSize]="(total$ | async)!" [(page)]="service.page"
          [pageSize]="service.pageSize"> <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
  </div>
</div>

</div>

<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
    <app-skeleton-loader [tableRow]="8" [tableColumn]="6"  shape="table"></app-skeleton-loader>
</div>