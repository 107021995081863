import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class OrderReviewService {

  public statusCode: BehaviorSubject<any> = new BehaviorSubject('');

  constructor(
    private http: HttpService,
  ) { }

  updateStatusCode(newStatusCode: any) {
    this.statusCode.next(newStatusCode);
  }

  getAllOrdersUnderReview(data) {
    try {
      let url = "api/orders/review-details";
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAllOrdersUnderReviewBySource(source, data) {
    try {
      let url = "api/orders/review/source/" + source;
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAllOrdersUnderReviewByStatus(data, status) {
    try {
      let url = "api/orders/review/status/" + status;
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  advanceOrderSearch(data) {
    try {
      let url = "api/Search/Advance";
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getStatusCount(data) {
    try {
      let url = "api/orders/status-counts";
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getOrderById(id) {
    try {
      let url = "api/getOrder/" + id;
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getNladHistroy(id, body) {
    try {
      let url = "api/nladHistory/" + id
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async getSourceOrderReview() {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "resourceType[]": [
          "TABS_REVIEW_ORDER",
          // "ACTION_BUTTON_REVIEW_ORDER"
        ]
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async getOrderCards() {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "resourceType[]": [
          "RO_DASHBOARD_CARD",
        ]
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  async getButtonOrderReview() {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "resourceType[]": [
          "ACTION_BUTTON_REVIEW_ORDER"
        ]
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }

  }

  advanceSearch(data) {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}

