<div class="custom-ard w-screen max-w-md flex-auto overflow-hidden rounded-3xl text-sm leading-6 ring-2" *ngIf="alerts.length > 0">
  <div class="accordion mb-3" id="notificationAccordion">
    <div class="accordion-item custom-card  pt-0">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button d-flex justify-content-between"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span
            (click)="toggleNotifications()"
            class="inline-flex items-center d-flex gap-2 title font-semibold leading-6"
          >
            <i class="fa-solid fa-bell fa-shake mt-1"></i>
            <span>Alert</span>
          </span>
         
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#notificationAccordion"
      >
        <div class="accordion-body p-0">
          <!-- <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-100 mb-3">
            <a
              (click)="closeNotifications()"
              class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-white"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </div> -->
          <div id="messages" [class.show]="showMessages">
            <div
              *ngFor="let message of formattedMessagesList"
              class="group mt-3 relative d-flex gap-3 rounded-lg p-2"
              [style.background-color]="message?.color"
            >
              <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50">
                <img
                  src="https://app.crowdsignal.com/images/logo-white.svg"
                  class="fa-xl text-white"
                  style="height: 27px;"
                />
              </div>
              <div>
                <a class="font-semibold text-white">
                  {{ message.msg }}
                </a>
                <p class="mt-1 text-white">{{ message.date }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
