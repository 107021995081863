import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { CustomerPagesRoutingModule } from "./customer-pages.routing.module";
import { HomeComponent } from "./home/home.component";
import { CustomerProfileComponent } from "./customer-profile/customer-profile.component";
import { BillingAndInvoiceComponent } from "./billing-and-invoice/billing-and-invoice.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { PurchaseHistoryComponent } from "./purchase-history/purchase-history.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { CustomerHistoryComponent } from "./customer-history/customer-history.component";
import { ActivityLogComponent } from "./activity-log/activity-log.component";
import { ManageCardComponent } from "./manage-card/manage-card.component";
import { AddInvoicesComponent } from "./billing-and-invoice/invoice-forms/add-invoices/add-invoices.component";
import { AddWalletComponent } from "./billing-and-invoice/invoice-forms/add-wallet/add-wallet.component";
import { EBillSettingComponent } from "./billing-and-invoice/invoice-forms/e-bill-setting/e-bill-setting.component";
import { PayInvoiceComponent } from "./billing-and-invoice/invoice-forms/pay-invoice/pay-invoice.component";
import { AdjustWalletComponent } from "./billing-and-invoice/invoice-forms/adjust-wallet/adjust-wallet.component";
import { BillingTableComponent } from "./billing-and-invoice/billing-table/billing-table.component";
import { AcpComponent } from "./order-history/acp/acp.component";
import { ToReceiveComponent } from "./order-history/to-receive/to-receive.component";
import { CompleteComponent } from "./order-history/complete/complete.component";
import { ReturnedComponent } from "./order-history/returned/returned.component";
import { NewComponent } from "./order-history/new/new.component";
import { ProductDetailsComponent } from "./order-history/new/product-details/product-details.component";
import { CustomerHistoryTableComponent } from "./customer-history/customer-history-table/customer-history-table.component";
import { CustomerPagesComponent } from "./customer-pages.component";
import { SharedModule } from "src/app/shared/shared.module";
import { AcpDetailModalComponent } from "./order-history/acp/acp-detail-modal/acp-detail-modal.component";
import { QuickLinksModule } from "../quick-links/quick-links.module";
import { AgGridModule } from "ag-grid-angular";
import { FilesComponent } from "./files/files.component";
import { DatePipe } from "@angular/common";
import { SubscriptionsModule } from "src/app/subscriptions/subscriptions.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ApplicationInformationComponent } from "./customer-profile/application-information/application-information.component";
import { CustomerEventsComponent } from "./customer-profile/customer-events/customer-events.component";
import { CustomerProfileViewComponent } from "./customer-profile/customer-profile-view/customer-profile-view.component";
import { CustomerProfileViewLeftPanelComponent } from "./customer-profile/customer-profile-view-left-panel/customer-profile-view-left-panel.component";
import { CustomerProfileViewRightPanelComponent } from "./customer-profile/customer-profile-view-right-panel/customer-profile-view-right-panel.component";
import { EligibilityInformationComponent } from "./customer-profile/eligibility-information/eligibility-information.component";
import { LineInformationComponent } from "./customer-profile/line-information/line-information.component";
import { PersonalInformationComponent } from "./customer-profile/personal-information/personal-information.component";
import { SubscriptionInformationComponent } from "./customer-profile/subscription-information/subscription-information.component";
import { UpcomingPlansComponent } from "./customer-profile/upcoming-plans/upcoming-plans.component";
import { UsagesInformationComponent } from "./customer-profile/usages-information/usages-information.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { CustomerDashboardModule } from "../customer-dashboard.module";
import { TicketSystemModule } from "src/app/ticket-system/ticket-system.module";
import { OrderTableComponent } from "./order-history/order-table/order-table.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AwaitingShipmentModule } from "src/app/awaiting-shipment/awaiting-shipment.module";
import { AwaitingShipmentOrderComponent } from "src/app/awaiting-shipment/awaiting-shipment-order/awaiting-shipment-order.component";
import { AlertCardComponent } from "./customer-profile/alert-card/alert-card.component";
import { UsageInfoComponent } from "./customer-profile/usage-info/usage-info.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { NewDashboardComponent } from "./new-dashboard/new-dashboard.component";
import { PersonalInfoComponent } from "./new-dashboard/personal-info/personal-info.component";
import { LineInfoComponent } from "./new-dashboard/line-info/line-info.component";
import { ApplicationInfoComponent } from "./new-dashboard/application-info/application-info.component";
import { SubscriptionInfoComponent } from "./new-dashboard/subscription-information/subscription-information.component";
import { LatestActivityComponent } from "./new-dashboard/latest-activity/latest-activity.component";
import { AddDataComponent } from "./new-dashboard/add-data/add-data.component";
import { CreditCardAddDataComponent1 } from "./new-dashboard/add-data/credit-card-add-data/credit-card-add-data.component";
import { PlanChangeComponent } from "./new-dashboard/plan-change/plan-change.component";
import { NewPlanChangeComponent } from "./new-dashboard/plan-change/new-plan-change/new-plan-change.component";
import { CreditCardPlanChangeComponent } from "./new-dashboard/plan-change/credit-card-plan-change/credit-card-plan-change.component";
import { ChangeApsComponent } from "./new-dashboard/change-aps/change-aps.component";
import { EquipmentPurchaseComponent } from "./new-dashboard/equipment-purchase/equipment-purchase.component";
import { CreditCardPurchaseComponent } from "./new-dashboard/equipment-purchase/credit-card-purchase/credit-card-purchase.component";
import { FeaturesComponent } from "./new-dashboard/features/features.component";
import { SimChangeComponent } from "./new-dashboard/features/sim-change/sim-change.component";



@NgModule({
  declarations: [
    CustomerPagesComponent,
    HomeComponent,
    CustomerProfileComponent,
    BillingAndInvoiceComponent,
    OrderHistoryComponent,
    PurchaseHistoryComponent,
    TicketsComponent,
    CustomerHistoryComponent,
    ActivityLogComponent,
    ManageCardComponent,
    AddInvoicesComponent,
    AddWalletComponent,
    EBillSettingComponent,
    PayInvoiceComponent,
    AdjustWalletComponent,
    BillingTableComponent,
    AcpComponent,
    ToReceiveComponent,
    CompleteComponent,
    ReturnedComponent,
    NewComponent,
    ProductDetailsComponent,
    CustomerHistoryTableComponent,
    AcpDetailModalComponent,
    FilesComponent,
    ApplicationInformationComponent,
    CustomerEventsComponent,
    CustomerProfileViewComponent,
    CustomerProfileViewLeftPanelComponent,
    CustomerProfileViewRightPanelComponent,
    EligibilityInformationComponent,
    LineInformationComponent,
    PersonalInformationComponent,
    SubscriptionInformationComponent,
    UpcomingPlansComponent,
    UsagesInformationComponent,
    OrderTableComponent,
    AlertCardComponent,
    UsageInfoComponent,
    PersonalInfoComponent,
    LineInfoComponent,
    ApplicationInfoComponent,
    SubscriptionInfoComponent,
    LatestActivityComponent,
    AddDataComponent,
    CreditCardAddDataComponent1,
    PlanChangeComponent,
    NewPlanChangeComponent,
    CreditCardPlanChangeComponent,
    ChangeApsComponent,
    EquipmentPurchaseComponent,
    CreditCardPurchaseComponent,
    FeaturesComponent,
    SimChangeComponent,

  ],
  imports: [
    CommonModule,
    CustomerPagesRoutingModule,
    SharedModule,
    QuickLinksModule,
    AgGridModule,
    InfiniteScrollModule,
    NgbDropdownModule,
    CustomerDashboardModule,
    TicketSystemModule,
    BsDatepickerModule,
    DragDropModule,
    AwaitingShipmentModule,
    NgApexchartsModule,
 

  ],


  providers: [DatePipe, CurrencyPipe, CustomerProfileComponent],
  exports: [
    PersonalInfoComponent,
    LineInfoComponent,
    ApplicationInfoComponent,
    SubscriptionInfoComponent,
    LatestActivityComponent,
    AddDataComponent,
    PlanChangeComponent,
    ChangeApsComponent,
    EquipmentPurchaseComponent,
    CreditCardPurchaseComponent,
    FeaturesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomerPagesModule {}
