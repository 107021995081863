import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-topup-add',
  templateUrl: './topup-add.component.html',
  styleUrls: ['./topup-add.component.scss']
})
export class TopupAddComponent {

  @Output() toggleTopup = new EventEmitter<boolean>();

  closeTopup() {
    this.toggleTopup.emit();
  }
}
