import { Component } from '@angular/core';

@Component({
  selector: 'app-cash-add-data',
  templateUrl: './cash-add-data.component.html',
  styleUrls: ['./cash-add-data.component.scss']
})
export class CashAddDataComponent {

}
