<div class="custom" *ngIf="!isSkeletonLoader" @fadeInOut>
  <div class="tab-pane ">
    <div class="notes-panel">
      <div class="wrapper d-flex ">
        <div class=" w-100">
          <div class="card-header mt-0 ml-4 d-flex align-items-center gap-2 " (click)="back()">
            <i class="fa-solid fa-arrow-left" style="font-size: 20px; cursor: pointer;"></i>
            <div class="heading">
              <h4 *ngIf="!editId" class="mb-0">Add roles and data restrictions </h4>
              <h4 *ngIf="editId" class="mb-0">Edit roles and data restrictions </h4>
            
            </div>
          </div>
          <div class="container mt-5">

            <form [formGroup]="addAgentForm">
              <div class="row d-flex justify-content-end">
                <div class="col-md-4">
                  <h4>General information</h4>

                </div>
                <div class="col-md-8">
                  <div class="card">
                    <div class="card-body custom-card">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <label>Full Name</label>
                            <input class="form-control" type="text" placeholder="Enter full name"
                              formControlName="fullName">
                          </div>
                          <span class="text-danger"
                            *ngIf="addAgentForm?.get('fullName')?.hasError('required') && addAgentForm?.get('fullName')?.touched">
                            Full Name is required.
                        </span>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <label>Email Address</label>
                            <input class="text-danger" class="form-control" type="text"
                              placeholder="Enter email address" formControlName="email">
                          </div>

                          <div class="text-danger"
                            *ngIf="addAgentForm?.get('email')?.hasError('required') && addAgentForm?.get('email')?.touched">
                            Email is required.
                          </div>
                          <div class="text-danger"
                            *ngIf="addAgentForm?.get('email')?.hasError('email') && addAgentForm?.get('email')?.touched">
                            Please enter a valid email address.
                          </div>
                        </div>

                      </div>
                      <div class="row mt-2">

                        <div class="col-md-6" *ngIf="!editId">
                          <div class="form-group mb-0">
                            <label>User Name</label>
                            <input class="form-control" type="text" placeholder="Enter user name"
                              formControlName="userName" (change)="userNameVerify($event)">
                          </div>
                          <div class="text-danger"
                            *ngIf="addAgentForm?.get('userName')?.hasError('required') && addAgentForm?.get('userName')?.touched">
                            User Name is required.
                          </div>
                          <div class="text-danger"
                            *ngIf="addAgentForm?.get('userName')?.hasError('pattern') && addAgentForm?.get('userName')?.touched">
                            User Name must be alphanumeric and at least 8 characters long.
                          </div>
                          <div [hidden]="check"
                            [ngClass]="{'green-text': checkDuplicateUser ==='User not registered', 'red-text': checkDuplicateUser === 'User is already registered'}">
                            {{checkDuplicateUser}}</div>
                        </div>


                        <div class="col-md-6" *ngIf="!editId">
                          <div class="form-group mb-0">
                            <label class="col-form-label">Password</label>
                            <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password"
                              required="" placeholder="*********" />
                            <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show mb-3"></span>
                            </div>
                            <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide mb-3"></span>
                            </div>
                            <div class="text-danger"
                              *ngIf="addAgentForm?.get('password')?.hasError('required') && addAgentForm?.get('password')?.touched">
                              Password is required.
                            </div>
                            <div class="text-danger"
                              *ngIf="addAgentForm?.get('password')?.hasError('pattern') && addAgentForm?.get('password')?.touched">
                              Password must be alphanumeric and at least 8 characters long.
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <label for="exampleSelect" class="form-label">Select User Status</label>
                          <select class="form-select" id="exampleSelect" formControlName="user_type">
                            <option value="1">Active</option>
                            <option value="2">Inactive</option>
                          </select>

                          <div class="text-danger"
                            *ngIf="addAgentForm?.get('user_type')?.hasError('required') && addAgentForm?.get('user_type')?.touched">
                            User Type is required.
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row d-flex justify-content-end">
                <div class="col-md-4">
                  <h4>Permissions</h4>

                </div>
                <div class="col-md-8">
                  <div class="card">
                    <div class="card-body custom-card">
                      <h4>Direct roles and data restrictions Standard roles</h4>

                      <div class="form-check" *ngFor="let item of allRoleList">
                        <!-- [value]="capitalizeAndFormat(item?.Code)" -->
                        <input class="form-check-input" type="radio" id="exampleRadio1" [value]="item.id"
                          formControlName="role">&nbsp;&nbsp;
                        <label class="form-check-label" for="exampleRadio1">
                          {{item.Name}}
                        </label>
                      </div>
                      <div class="text-danger"
                        *ngIf="addAgentForm?.get('role')?.hasError('required') && addAgentForm?.get('role')?.touched">
                        role is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row d-flex justify-content-end">
                <div class="col-md-4">
                  <h4>Application And resources</h4>
                </div>
                <div class="col-md-8">
                  <div class="card">
                    <div class="card-body custom-card">
                      <h4>Application</h4>
                      <div class="form-check" *ngFor="let application of applicationInfo">
                        <!-- [value]="capitalizeAndFormat(item?.Code)" -->
                        <input class="form-check-input" type="radio" id="exampleRadio1" [value]="application.id"
                          formControlName="application">&nbsp;&nbsp;
                        <label class="form-check-label" for="exampleRadio1">
                          {{application?.Name}}
                        </label>
                      </div>
                      <div class="text-danger"
                        *ngIf="addAgentForm?.get('application')?.hasError('required') && addAgentForm?.get('application')?.touched">
                        application is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-end marginB ">
                <div class="col-md-6 text-end mb-5">
                  <button *ngIf="!editId" class="btn btn-primary" (click)="submitAgent()">Submit</button>
                  <button *ngIf="editId" class="btn btn-primary" (click)="submitAgent()">Update</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSkeletonLoader" @fadeInOut class=" container-fluid ">
  <div class="div d-flex flex-column justify-content-between">
      <app-skeleton-loader [cardCount]="3" [width]="'100%'" [height]="'40vh'" [alignment]="'column'"  shape="normal-card"></app-skeleton-loader>
  </div>
</div>