<div class="translate_wrapper" [class.active]="navServices.changeCompany" *ngIf="displayDropdowns">
  <div class="current_lang">
    <div class="lang">
      <i class="icofont icofont-{{selectedCompany.icon}}" style="
        font-size: 22px;"></i><span class="lang-txt">{{selectedCompany.name}}</span>
    </div>
  </div>
  <div class="more_lang" [class.active]="navServices.changeCompany">
    <div class="lang selected" data-value="en"  style="width: 150px;" (click)="changeCompany(change)" *ngFor="let change of Company">
      <i class="icofont icofont-{{change.icon}}" style="
      font-size: 22px;"></i>
      <span class="lang-txt text-capitalize">{{change.name}}</span>
    </div>
  </div>
</div>