<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Choose Auto Pay Type</label>
      </div>
      <div class="col-md-3">
        <input type="radio" class="me-1">
        <label>Top Up</label>
      </div>
      <div class="col-md-3">
        <input type="radio" class="me-1">
        <label>Data Top Up</label>
      </div>
      <div class="col-md-3">
        <input type="radio" class="me-1">
        <label>Plan</label>
      </div>
    </div>

    <div class="border p-3">
      <div class="row form-group">
        <div class="col-md-12 mb-2">
          <span class="text-danger">NOTE2: *IF YOU CHOOSE AUTO TOPUP RECHARGE, CARD WILL BE CHARGED ON LOW SPECIFIED
            BALANCE.</span>
        </div>
        <div class="col-md-3">
          <label>Topup Plan</label>
        </div>
        <div class="col-md-9">
          <select class="form-control form-select">
            <option>Select</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label>Choose Autopay Setting</label>
        </div>
        <div class="col-md-3">
          <input type="radio" class="me-1">
          <label>Day of month</label>
        </div>
        <div class="col-md-4">
          <input type="radio" class="me-1">
          <label>Voice Balance Goes Below</label>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>Phone Number</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Tax Breakup</label>
      </div>
      <div class="col-md-9">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Total Tax</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Processing Fee</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Total Amount</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Credit Card Number</label>
      </div>
      <div class="col-md">
        <input type="number" class="form-control">
      </div>
      <div class="col-md">
        <input type="number" class="form-control">
      </div>
      <div class="col-md">
        <input type="number" class="form-control">
      </div>
      <div class="col-md">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Types Of Card</label>
      </div>
      <div class="col-md-9">
        <select class="form-control form-select">
          <option>Select</option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Security Code</label>
      </div>
      <div class="col-md-3">
        <input type="number" class="form-control">
      </div>
      <div class="col-md-2 my-auto">
        <label>Exp Date</label>

      </div>
      <div class="col-md-2">
        <select class="form-control form-select">
          <option>Select </option>
        </select>
      </div>
      <div class="col-md-2">
        <select class="form-control form-select">
          <option>Select </option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Name On Card</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Receipt Number</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Zip Code</label>
      </div>
      <div class="col-md-9">
        <input type="number" maxlength="5" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>City</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>State/Province</label>
      </div>
      <div class="col-md-9">
        <select class="form-control form-select">
          <option>Select</option>
        </select>
        <div class="d-flex align-items-center mt-2">
          <input type="checkbox" class="me-2">
          <label class="mb-0 checkbox-label">Apply Address Suggestion</label>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Billing Address 1</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Billing Address 2</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Save Card Details</label>
      </div>
      <div class="col-md-9">
        <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2">
          <label class="mb-0 checkbox-label">Do you want to save card details?</label>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>