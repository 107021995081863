<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="border mb-3 p-1 pb-3">
      <div class="row form-group">
        <div class="col-md-3 my-auto">
          <label>Present Service Address</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control" value="390 POINCIANA DRIVE, MELBOURNE, FL, 32935">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3 my-auto">
          <label>Present Biling Address</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control" value="390 POINCIANA DRIVE, MELBOURNE, FL, 32935">
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-4">
        <input type="radio" name="address" class="me-2">
        <label class="mb-0">Service Address</label>
      </div>
      <div class="col-md-4">
        <input type="radio" name="address" class="me-2">
        <label class="mb-0">Biling Address</label>
      </div>
      <div class="col-md-4">
        <input type="radio" name="address" class="me-2">
        <label class="mb-0">Both</label>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Last Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Zip Code</label>
      </div>
      <div class="col-md-9">
        <input type="text" maxlength="5" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>City</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>State</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Address Line 1</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Address Line 2</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-9">
        <div class="d-flex align-items-center">
          <input type="checkbox" class="me-2">
          <label class="mb-0 checkbox-label">Bypass USPS address validation</label>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</div>