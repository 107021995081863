<div class="container-fluid" *ngIf="!isSkeletonLoader" >
    <div class="row widget-grid">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="mb-1">
                        <ngb-accordion #accT="ngbAccordion">
                            <ngb-panel id="toggle-1" title="Search Filter">
                                <ng-template ngbPanelContent>
                                    <div class="d-flex">
                                        <div class="input-control">
                                            <label>From</label>
                                            <input type="date" placeholder="From" class="form-control" />
                                        </div>
                                        <div class="input-control ms-2">
                                            <label>To</label>
                                            <input type="date" placeholder="To" class="form-control" />
                                        </div>
                                        <div class="input-control ms-2 align-items-end">
                                            <label>Select Report</label>
                                            <select class="form-control" style="min-width: 150px;">
                                                <option>Printout</option>
                                                <option>Fix price</option>
                                            </select>
                                            <!-- <input type="search" placeholder="Ticket Number " class="form-control" /> -->
                                        </div>
                                        <div class="input-control ms-2 d-flex align-items-end">
                                            <app-feather-icons [icon]="'x-circle'"></app-feather-icons>
                                            <app-feather-icons [icon]="'search'" class="ms-2"></app-feather-icons>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>

                        <!-- <ngb-accordion #accT="ngbAccordion">
                            <ngb-panel id="toggle-1" title="Search Filters">
                                <ng-template ngbPanelContent>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="form theme-form">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="mb-3">
                                                            <label>From</label>
                                                            <input class="datepicker-here form-control"
                                                                placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                                                                #d="ngbDatepicker" (click)="d.toggle()" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="mb-3">
                                                            <label>To</label>
                                                            <input class="datepicker-here form-control"
                                                                placeholder="yyyy-mm-dd" name="dp2" ngbDatepicker
                                                                #d2="ngbDatepicker" (click)="d2.toggle()" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="mb-3">
                                                            <label>Select Report</label>
                                                            <select class="form-control digits">
                                                                <option>Printout</option>
                                                                <option>Fix price</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-end">
                                            <button class="btn btn-primary me-1">Submit</button>
                                            <button class="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion> -->
                    </div>
                    
                        <div class="mb-1">
                            <!-- <ngb-accordion #accT="ngbAccordion" (shown)="onShown(accordian)">
                                <ngb-panel [id]="accordian.value" [title]="accordian.label">
                                    <ng-template ngbPanelContent> -->
                                        
                                        <!-- <ng-container *ngIf="accountStatusHistoryData.length>0">
                                            <app-customer-history-table [tableItem]="accountStatusHistoryData" [total]="total"
                                                [columns]="accountStatusHistoryColumns"></app-customer-history-table>
                                            </ng-container> -->
                                        <!-- <ng-container *ngIf="accordian.value == 'Plan History'">
                                            <app-customer-history-table></app-customer-history-table>
                                        </ng-container>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion> -->
                            <button  *ngFor="let accordian of accordians;let i = index" class="btn btn-pill btn-outline-primary m-l-10 m-t-10" (click)="onShown(accordian)">{{accordian.label}}</button>
                        </div>
                        
                        <!-- {{tableData | json}} -->
                    
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
    <app-skeleton-loader [buttonCount]="15"  shape="button"></app-skeleton-loader>
</div>
