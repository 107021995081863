import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class BillingAndInvoiceService {

  constructor(
    private http: HttpService
  ) { }

  async getInvoices(data) {
    try {
      return await this.http.get('api/Customer/GetCustomerInvoices', data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
