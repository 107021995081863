<div class="container-fluid" *ngIf="!isSkeletonLoader" >
    <div class="row">
        <div class="col-md-6 mx-auto">
            <div class="appointment cursor-pointer">
                <div class="card" (click)="openDetailModal(orderDetailModal,'Account Information',data?.accountInfo)">
                    <div class="card-header card-no-border">
                        <div class="header-top">
                            <h5 class="m-0">Account Information</h5>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="appointment-table table-responsive">
                            <table class="table table-bordernone">
                                <tbody>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Account Number</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.accountInfo?.accountNumber?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Account Status</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.accountInfo?.accountStatus?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Subscriber Status</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.accountInfo?.subscriberStatus?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mx-auto">
            <div class="appointment cursor-pointer">
                <div class="card" (click)="openDetailModal(orderDetailModal,'Completed Order',data?.completedOrder)">
                    <div class="card-header card-no-border">
                        <div class="header-top">
                            <h5 class="m-0">Completed Order</h5>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="appointment-table table-responsive">
                            <table class="table table-bordernone">
                                <tbody>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Brand</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.completedOrder?.brand?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Order Description</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.completedOrder?.orderDescription?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Order Status</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.completedOrder?.orderStatus?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 mx-auto">
            <div class="appointment cursor-pointer">
                <div class="card" (click)="openDetailModal(orderDetailModal,'Order Intake',data?.orderIntake)">
                    <div class="card-header card-no-border">
                        <div class="header-top">
                            <h5 class="m-0">Order Intake</h5>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="appointment-table table-responsive">
                            <table class="table table-bordernone">
                                <tbody>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Name</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.orderIntake?.programs[0]?.name}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Order Description</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.orderIntake?.orderDescription?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Order Status</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.orderIntake?.orderStatus?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mx-auto">
            <div class="appointment cursor-pointer">
                <div class="card" (click)="openDetailModal(orderDetailModal,'Shipped Order',data?.shippedOrder)">
                    <div class="card-header card-no-border">
                        <div class="header-top">
                            <h5 class="m-0">Shipped Order</h5>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="appointment-table table-responsive">
                            <table class="table table-bordernone">
                                <tbody>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">IMEI</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.shippedOrder?.imei?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">MDN</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.shippedOrder?.mdn?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="img-content-box">
                                            <a class="d-block f-w-500">Brand</a>
                                        </td>
                                        <td class="text-end">
                                            <p class="m-0 font-success">
                                                {{data?.shippedOrder?.brand?.value}}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<ng-template #orderDetailModal let-modal>
    <div class="modal-header modal-copy-header">
        <h5 class="headerTitle mb-0">{{type}} Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-acp-detail-modal [type]="type" [data]="modalData"></app-acp-detail-modal>
    </div>
</ng-template>

<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
    <app-skeleton-loader [alignment]="'row'" [cardCount]="2" shape="medium-card"></app-skeleton-loader>
</div>
