
  
  <div class="row notification  p-5 pt-1 " >
    <div class="col-12 customerNotes  p-0 ">
      <div class="body m-0 p-2 content-scroll" (scroll)="onScroll($event)"
        style="height: 70vh;overflow-y: auto; overflow-x: hidden;">
        <ng-container *ngIf="allNotes?.length">
          <ul class="mt-0">
            <ng-container>
              <li class="d-flex mb-4" *ngFor="let note of allNotes">
                <div class="activity-dot-info" *ngIf="!note.group"></div>
                <div class="d-flex flex-column w-100 mx-3">
                  <span class="fw-semibold fs-4 m-3 mt-40 pt-3" *ngIf="note.group">{{ note.group }}</span>
                  <div class="timeline-content w-100 ms-3" *ngIf="!note.group">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="d-flex gap-2 align-items-end">
                        <img *ngIf="note.type === 'activity'" src="../../../../assets/svg/restore.png"
                          width="30" height="30">
                        <img *ngIf="note.type === 'note'" src="../../../../assets/svg/post-it.png" width="30"
                          height="30">
                        <div>
                          <h5>{{ note.type === 'note' ? note.Text : note.activityDetail?.value }}</h5>
                        </div>
                      </div>
                      <small class="text-muted fw-bold">{{ note.CreatedDate | date: 'h:mm a' }}</small>
                    </div>
                    <div class="mb-0 d-flex flex-row justify-content-between">
                      <p class="mt-3">
                        <small
                          [ngClass]="{'bg-primary': note.type === 'activity', 'bg-secondary': note.type === 'note'}"
                          class="badge text-light h-fit-content">
                          {{ note.CreatedDate | date: 'MMM d, y' }}
                        </small>
                        <span class="badge bg-light text-dark">
                          {{ note.type === 'note' ? (note.agent?.user?.name ||
                          'Old system') : (note.createdByUser?.value || 'Old system') }}
                        </span>
                        <span *ngIf="note.type === 'note' && note.notetype?.Name">
                          <b>Type:</b> {{ note.notetype.Name }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <ng-container *ngIf="!allNotes?.length">
          <p class="text-danger">No notes available.</p>
        </ng-container>
      </div>
    </div>
  </div> 
