<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row my-3 border bg-white">
      <div class="col-md-6">
        <h5 class="border-0">Add Link Topup</h5>
      </div>
      <div class="col-md-6 text-end my-auto">
        <button type="button" class="btn btn-primary" (click)="closeTopup()">Back to link topup list</button>
      </div>
    </div>
    <span class="text-primary my-3">Linked topup is working carrier wise to add another topup linked them separately.
    </span>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Plan Type</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Carrier</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Carrier Code</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Link Topup Type</label><br>
      <div class="d-flex align-items-center">
        <div>
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Topup</label>
        </div>
        <div class="mx-3">
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">Data</label>
        </div>
        <div>
          <input type="checkbox" class="me-2"> 
          <label class="mb-0 checkbox-label">ILD</label>
        </div>
       </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Status</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12 ">
      <button type="button" class="btn btn-primary">Submit</button>
      <button type="button" class="btn btn-primary ms-3">Cancel</button>
    </div>
  </div>
  </form>
  </div>