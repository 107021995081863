export const environment = {
  production: false,
  G_CAPTCHA_SITE_KEY: "6LfAuBgpAAAAAJNyzQNZS1qgS69LVWD37CcmH3D",
  G_CAPTCHA_SECRET_KEY: "6LfAuBgpAAAAAFIf5zPZGy_yI54hAooCunpIrRre",
  COMPANY_ID: "UNITYCRM0021",

  CAS_BASE_RUL:'https://identity-stg.aqsatel.com/',
  BASE_API: "https://manage-api.aqsatel.com/",
  AQSATEL_API: "https://manage-api.aqsatel.com/",
  Prepaid_Mingle_API: "https://mingle-stg-api.aqsatel.com/",


  UNITY_BUYFLOW: "https://prepaid.unitywireless.com",
  MINGLE_BUYFLOW: "https://prepaid.minglemobile.com",

  USER_ID: "1",
  FETCH_DATA_REPORT_TABLE_INTERVAL: 3,

  PREPAID_UNITYWIRELESS: "https://prepaid.unitywireless.com/",
  MINGLE_MOBILE: "https://minglemobile.com",
  // session timeout or user inactivity constants
  INACTIVITY_SCREEN_TIME_IN_MINIUTES: 60,
  JWT_EXPIRATION_TIME_IN_MINUTES: 60,
  JWT_EXPIRATION_WARNING_TIME_IN_MINUTES: 5,
  INACTIVITY_SCREEN_TIMEOUT_TIME_IN_Millisconds: 60000,
  // notification keys
  REVERB_APP_KEY: 'l1ylkjx1fe40lqlskwce',
  REVERB_HOST: 'mingle-stg-api.aqsatel.com',
  REVERB_PORT: 8081,
  REVERB_SCHEME: 'http',
  //searchHistoryRetention Variables
  searchHistoryStorageBasis: "login", // Use 'days' or 'login'
  searchHistoryDaysToKeep: 1, // default days to retain records if day-based is selected
  // searchHistoryDaysToKeep will totally irrelevent in case of searchHistoryStorageBasis= login

  // Logging controls
  enableConsoleLogs: true,
};
