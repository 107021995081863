import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
TemplateRef,
ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PlanChangeService } from "../services/plan-change.service";
import { HttpService } from "src/app/shared/services/httpService.service";
import { CommonService } from "src/app/shared/services/common.service";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';
import { AuthService } from "src/app/auth/services/auth.service";
@Component({
  selector: "app-change-plan-to",
  templateUrl: "./change-plan-to.component.html",
  styleUrls: ["./change-plan-to.component.scss"],
})
export class ChangePlanToComponent implements OnInit, AfterViewInit {

  @ViewChild('modalTemplate', { static: true }) modalTemplate: ElementRef;
  activeSection: string = 'proratedDetail'; 
  @Input() currentPlanId:any
  isPaymentModelOpen:boolean
  formData: FormGroup;
  plans: any;
  currentPlan:string
  ispaymentProcessable:boolean
  plansDetected: boolean;
  isPlanDetails:boolean
  planSelected: boolean;
  oldPlanDetails: any;
  newPlanDetails:any
  proratedDetails: any;
  selectedPlanId: any;
  private disableTimeout: any; 
  disableTimeoutCr:any
  isCvvSelected: boolean;
  isCreditCardSelected: boolean;
  isSubmitButtonDisabled:boolean
  isSubmitButtonDisabled_cr:boolean
  footer: boolean;
  expireMonthDropdown = Array.from({ length: 12 }, (_, i) => i + 1);
  currentYear: number = new Date().getFullYear();
  futureYear: number = 2050;
  activeNav: string = 'oldPlan';
  expireYearDropdown = Array.from(
    { length: this.futureYear - this.currentYear + 1 },
    (_, i) => this.currentYear + i
  );
  billingAddress: any;
  loader: boolean;
  isOldNewPlan: boolean;
  isScheduled:boolean
  isImmediate:boolean
  isScheduledDateToday:boolean
  paymentType:string
  selectedPlanName: any;
  paymentSuccessful:boolean;
  paymentFailure:boolean
  paymentStatus: boolean;
  modalOpen: boolean;
  modalRef: NgbModalRef;

  constructor(
    private planChangeService: PlanChangeService,
    private httpService: HttpService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private elRef: ElementRef,
    private modalService: NgbModal,private authService:AuthService
  ) {
    this.footer = true;
  }
  activeTab: string = 'existingCard';
  async ngOnInit() {
    let data = {
      CustomerId: this.httpService.getCustomerId(),
    };
    const resposne: any =
      await this.planChangeService.getMinimalprofileData(data);
    if (resposne?.Status) {
      this.billingAddress = resposne?.data?.personalInfo?.mailingAddress?.value;
      this.currentPlan=resposne?.data?.plan?.planDetail?.value
    }
    await this.getPlansDetails();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  isOpen = false;
  
  setActiveSection(section: string) {
    this.activeSection = section;
  }
  onIsPaymentModelOpenChange(newValue: boolean) {
    this.isPaymentModelOpen = newValue;
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }
  handleCheckOut(modalTemplate: any) {
    this.isPaymentModelOpen=true
    this.ispaymentProcessable=true
  }

  handleCreditCardSelected(modalTemplate: any) {
    this.creditCardSelected();  
    this.scrollTo('newCardSection'); 
  }

  openDiv() {
    this.isOpen = true;
  }

  closeDiv() {
    this.isOpen = false;
  }
  getSelectedPlanId(planId:any){
    this.planSelected=true
    this.selectedPlanId=planId
  }

  

  isPlanDetail(){
    this.isPlanDetails=true
    this.isOldNewPlan=true
  }
  closePanel(){
    this.isCreditCardSelected=false
    this.isCvvSelected=false
    
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close(); 
      this.modalRef = null; 
    }
  }

  scrollTo(id: string) {
    const element = this.elRef.nativeElement.querySelector(`#${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  setActiveNav(navItem: string) {
    this.activeNav = navItem;
  }
  closePlanDetails(){
    this.isPlanDetails=false
  }
  async getPlansDetails() {
    this.loader = true;  
    this.commonService.loader=true
    try {
      const data = {
        planType: "Prepaid",
        stateAbbr: this.billingAddress?.state,
        'planAreas[]':["CSR"]
      };
      // const companyName = this.authService.getCompanyName();
      // if (companyName === "unity") {
      //   delete data['planAreas[]'];
      // }
      const response:any = await this.planChangeService.getPrepaidPlans(data);
      if (response?.Status) {
        this.plans = response.data;
        const sortedPlans = this.plans.sort((a, b) => {
          if (a.Name === 'Unlimited Plan') return -1;  // Move "Unlimited Plan" to the top
          if (b.Name === 'Unlimited Plan') return 1;
          return 0; // Keep other plans in their original order
        });

        this.plansDetected = !this.plansDetected;
        this.loader=false

      } else {
        this.commonService.showToast("error", "Error", response?.message);
        this.loader=false

      }
      this.commonService.loader=false
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
      this.commonService.loader=false
    } finally {
      this.loader = false;
      this.commonService.loader=false

    }
  }
  

  isEffectiveDateToday(): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (this.proratedDetails?.effectiveDate) {
      const effectiveDate = new Date(this.proratedDetails.effectiveDate);
      effectiveDate.setHours(0, 0, 0, 0); 
      this.isScheduledDateToday = effectiveDate.getTime() === today.getTime();
      return this.isScheduledDateToday; 
    }
    this.isScheduledDateToday = false;
    return this.isScheduledDateToday; 
  }

  async getPaymentMethod(){
    await this.isEffectiveDateToday()
    if(this.proratedDetails?.changeType=='Upgrade' || this.proratedDetails?.changeType=="SamePlan" && this.isEffectiveDateToday){
      this.paymentType='Immediate'
    }else{
      this.paymentType ='Scheduled'
    }
    return ''
  }

  async isTransactionStatus(){
    this.isOpen = false;   // Close the slide-in div
    this.isPaymentModelOpen = false;
    let data = {
      CustomerId: this.httpService.getCustomerId(),
    };
    this.loader=false
    
    const resposne: any =await this.planChangeService.getMinimalprofileData(data);
      if(resposne?.Status){
        this.currentPlan=resposne?.data?.plan?.planDetail?.value
      }
  }

  async onPlanSelected(event: any) {
    this.planSelected = true;
    this.isOldNewPlan=false
    this.loader = true;
    this.commonService.loader=true
    this.isCreditCardSelected=false
    this.isCvvSelected=false
    try {
      const currentPlan = this.plans.find(el => el.id == this.selectedPlanId);
      const params={ 
        planId: this.currentPlanId,
      CustomerId:this.httpService.getCustomerId() }
      const planDetailsResponse:any = await this.planChangeService.getPlansDetails(params);
  
      if (planDetailsResponse?.Status) {
        this.oldPlanDetails = planDetailsResponse?.data;
        
        const proratedParams = {
          CustomerId: this.httpService.getCustomerId(),
          planId: this.selectedPlanId
        };
        const proratedResponse:any = await this.planChangeService.getProratedPlanCost(proratedParams);
        
        if (proratedResponse?.Status) {
          this.proratedDetails = proratedResponse?.data;
          this.getPaymentMethod()
          const newPlanParams = { planId: this.proratedDetails?.newPlanId };
          const newPlanDetailsResponse:any = await this.planChangeService.getPlansDetails(newPlanParams);
          this.isEffectiveDateToday()
          if (newPlanDetailsResponse?.Status) {
            this.newPlanDetails = newPlanDetailsResponse?.data;
            this.selectedPlanName=newPlanDetailsResponse?.data?.plan?.Name
          }
          this.loader = false;
          if(proratedResponse?.Status && proratedResponse?.data?.changeType=='SamePlan'){
            this.commonService.showToast('info','Info','You Already Have This Plan')
          }
        }
        this.commonService.loader=false
      }
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
      this.commonService.loader=false

    } finally {
      this.loader = false;
      this.commonService.loader=false

    }
  }
  

  cvvSelected() {
    this.isCvvSelected = !this.isCvvSelected;
    this.isCreditCardSelected = false;
    this.footer = false;
  }
  creditCardSelected() {
    this.isCreditCardSelected = !this.isCreditCardSelected;
    this.isCvvSelected = false;

    this.footer = false;
  }

  calculateMonthlyCost(price: string, validity: number): number {
    const totalPrice = parseFloat(price);
    if (validity > 0) {
      const averageMonthlyCost = (totalPrice / validity) * 30;  // Assume 30 days per month
      return parseFloat(averageMonthlyCost.toFixed(2));
    }
    return 0;
  }

  // Calculate the savings
  calculateSavings(price: string, validity: number): any {
    const totalPrice = parseFloat(price);
    if (validity > 0) {
      const averageMonthlyCost = (validity / 30) * 49 - totalPrice  ;  // Assume 30 days per month
      return parseFloat(averageMonthlyCost.toFixed(2));
    }
    return 0 
  }
}
