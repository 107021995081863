import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observer } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class CustomerHistoryService {

  api: string = environment.BASE_API;
  @LocalStorage()
  private CustomerId

  constructor(
    private http: HttpService
  ) { }

  getPlanHistory(data) {
    try {
      return this.http.get('api/Customer/GetPlanHistory', data, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getPlanChangeHistory(){
    return this.http.get(`api/Customer/planChangeHistory/${this.CustomerId}`, null, false, true);
  }

  getCustomerPortOutHistory(){
    return this.http.get(`api/Customer/customerPortOutHistory/${this.CustomerId}`, null, false, true);
  }


  getAcHistory() {
    return this.http.get(`api/Customer/customerStatusHistory/${this.CustomerId}`, null, false, true);
  }
  
  

}
