<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Phone</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Receipt Number </label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Amount</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
        <div class="d-flex align-items-center text-dark">
          <app-feather-icons [icon]="'plus'" class="mt-1" id="c-pills-home-tab2" role="button"
            [ngClass]="{ 'active show': customizer == 'topup' }" (click)="Customizer('topup')"></app-feather-icons>
          <span role="button" [ngClass]="{ 'active show': customizer == 'topup' }" (click)="Customizer('topup')">Add new
            topup plan</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Tax Breakup</label>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Total Tax</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Processing Fee</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Total Amount</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary"> Submit</button>
      </div>
    </div>
  </form>
</div>

<div class="customizer-contain" [ngClass]="{ open: customizer != '' }">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-body custom-scrollbar">
      <div class="tab-pane fade " [ngClass]="{ 'active show': customizer == 'topup' }" id="c-pills-home" role="tabpanel"
        aria-labelledby="c-pills-home-tab2">
        <div class="d-flex justify-content-end p-2">
          <i class="icofont-close icon-close" (click)="Customizer('')"></i>
        </div>
        <app-new-topup-plan></app-new-topup-plan>
      </div>
    </div>
  </div>
</div>