import { Component, Input } from '@angular/core';

interface TimelineEvent {
  status: string;
  icon: string;
  timestamp: string;
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
  @Input() events: TimelineEvent[] = [];
}
