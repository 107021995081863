<div class="container-fluid card p-3" *ngIf="!isSkeletonLoader" @fadeInOut>

    <div class="row">
        <div class="col-4">
            <div class="row">
                <h5>Activation Date : <span class="text-danger">{{datesData?.activationDate | date : 'MMM d, y' ||
                        '-'}}</span></h5>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-7">
                    <h5>Renewal Date : <span class="text-danger">{{datesData?.renewalDate | date : 'MMM d, y' ||
                            '-'}}</span></h5>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-7">
                    <h5>Expiration Date : <span class="text-danger">{{datesData?.exipirationDate | date : 'MMM d, y' ||
                            '-'}}</span></h5>
                </div>
            </div>
        </div>
        <div class="col-12">
            <hr>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-6">
           
        </div>
        <div class="col-md-6 text-end">
            <button type="button" (click)="getData()" class="btn btn-primary">Reload purchase</button>
        </div>
    </div> -->

    <div class="d-flex align-items-center justify-content-between">
        <div>
            <h6>Minutes</h6>
        </div>
        <app-filter-btn [icon]="'fa fa-refresh'" [isBtn]="true" (click)="getData()"></app-filter-btn>
    </div>

    <div class="table-responsive table-hover table-striped table-card">
        <table class="table table-nowrap mb-0">
            <thead class="table-light">
                <tr class="header-row">
                    <th *ngFor="let header of headers; let i = index" class="text-nowrap">
                        {{ header.display }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data; let i = index" class="cursor-pointer">
                    <td *ngFor="let header of headers">
                        <ng-container [ngSwitch]="header.key">
                            <span *ngSwitchDefault class="text-nowrap">
                                {{ item[header.key] }}
                            </span>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center p-3" *ngIf="data?.length==0">
            <span class="text-muted">
                No data found
            </span>
        </div>
        <div class="d-flex justify-content-between mt-3">
            <select class="form-control me-2 page-dropdown" style="width: auto" [(ngModel)]="itemsPerPage"
                (change)="onChangeNumberOfPage()">
                <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
            </select>

            <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
                [(page)]="currentPage" (pageChange)="pageChange($event)" [pageSize]="itemsPerPage"><ng-template
                    ngbPaginationPrevious>Previous</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>


        </div>
    </div>

</div>

<div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
    <app-skeleton-loader [tableRow]="8" [tableColumn]="6"  shape="table"></app-skeleton-loader>
</div>