import { Component, OnInit } from "@angular/core";
import { PlanChangeService } from "./services/plan-change.service";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { HttpService } from "src/app/shared/services/httpService.service";
import { AuthService } from "src/app/auth/services/auth.service";

@Component({
  selector: "app-plan-change",
  templateUrl: "./plan-change.component.html",
  styleUrls: ["./plan-change.component.scss"],
})
export class PlanChangeComponent implements OnInit {
  label: string = "Plans";
  planChange: boolean = true;
  planId: any;
  customerId: any;
  prepaidPlans: any[] = [];
  planDetails: any;
  customerAddress: any[] = [];
  list: any = [
    { name: "Credit Card", link: "/customer/quick-links/add-topup/creditcard" },
    { name: "Cash", link: "/customer/quick-links/add-topup/cash" },

    {
      name: "Money Order",
      link: "/customer/quick-links/add-topup/money-order",
    },
    { name: "Money Gram", link: "/customer/quick-links/add-topup/money-gram" },
    { name: "Wallet", link: "/customer/quick-links/add-topup/wallet" },
    {
      name: "Dealer Wallet",
      link: "/customer/quick-links/add-topup/dealer-wallet",
    },
    { name: "Free Top UP", link: "/customer/quick-links/add-topup/free-topup" },
  ];

  topupType: string = "Y";

  constructor(
    private planChangeService: PlanChangeService,
    private customerProfileService: CustomerProfileService,
    private httpService: HttpService,private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.getPlanId();
  }

  //To get Prepaid PlanId
  async getPlanId() {
    const response: any = await this.planChangeService.getCustomerOtherInfo();
    if (response.Status) {
      this.planId = response?.data[0]?.PlanID;
      this.customerId = response?.data[0]?.CustomerID;
  
    }
    this.getPrepaidPlans();
  }

  /*To get all Prepaid Plans in a particular state
  step-1: Getting stateCode of a user by calling getMinimalprofileData function 
  step-2: Passing the statecode in getPrepaidPlans function to get all prepaid plans of a particular user
  */
  async getPrepaidPlans() {
    let dataa = {
      CustomerId: this.httpService.getCustomerId(),
    };
    const res: any = await this.planChangeService.getMinimalprofileData(dataa);
    let stateCode:string = res?.data?.personalInfo?.address?.value?.state;

    this.customerAddress.push(
      res?.data?.personalInfo?.address?.value?.address1
    );
    this.customerAddress.push(
      res?.data?.personalInfo?.address?.value?.address2
    );
    this.customerAddress.push(res?.data?.personalInfo?.address?.value?.zipCode);

    let data = {
      planType: "Prepaid",
      stateAbbr: stateCode,
      'planAreas[]':["CSR"]
    };
    const companyName = this.authService.getCompanyName();

    if (companyName === "unity") {
      delete data['planAreas[]'];
    }
    const response: any = await this.planChangeService.getPrepaidPlans(data);
    for (let i = 0; i < response?.data?.length; i++) {
      this.prepaidPlans.push(response?.data[i]);
    }
 
    this.getPlanTax();
  }

  /* Funtion for getting taxes on particular plan by passing the customerId of a customer and planId of a plan in getPlanTax function  */
  async getPlanTax() {
    let data = {
      CustomerId: this.customerId,
      planId: this.planId,
    };
    const response: any = await this.planChangeService.getPlanTax(data);
 
    this.getPlanDetails();
  }

  /* Getting plan details by just passing the plan Id in getPlanDetails function */
  async getPlanDetails() {
    let data = {
      // "CustomerId":this.customerId,
      planId: this.planId,
    };
    const response: any = await this.planChangeService.getPlansDetails(data);
    this.planDetails = response?.data?.plan;

  }
}
