<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Phone</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Receipt Number </label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Amount</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
        <div class="d-flex align-items-center text-dark">
          <span>
            <app-feather-icons [icon]="'plus'" class="mt-1"></app-feather-icons> 
            Add new topup plan
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Tax Breakup</label>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Total Tax</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Processing Fee</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label>Total Amount</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary"> Submit</button>
      </div>
    </div>
  </form>
</div>