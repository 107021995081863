<ng-container *ngIf="type == 'Account Information'">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h5>Account Number</h5>
            </div>
            <div class="col-6">
                <p>{{data?.accountNumber?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Account Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.accountStatus?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Subscriber Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.subscriberStatus?.value || '-'}}</p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type == 'Completed Order'">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h5>IMEI</h5>
            </div>
            <div class="col-6">
                <p>{{data?.IMEI?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>MDN</h5>
            </div>
            <div class="col-6">
                <p>{{data?.MDN?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Brand</h5>
            </div>
            <div class="col-6">
                <p>{{data?.brand?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Order Description</h5>
            </div>
            <div class="col-6">
                <p>{{data?.orderDescription || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Order Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.orderStatus || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>PUK 1</h5>
            </div>
            <div class="col-6">
                <p>{{data?.puk1?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>PUK 2</h5>
            </div>
            <div class="col-6">
                <p>{{data?.puk2?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Sim Number</h5>
            </div>
            <div class="col-6">
                <p>{{data?.simNumber?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Sim Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.simStatus?.value || '-'}}</p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <h5 class="text-primary">Personal Info</h5>
            </div>
            <div class="col-6">
                <h5>Name</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.name?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Phone</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.phone?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Email</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.email?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Date Of Birth</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.dateOfBirth?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>SSN</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.ssn?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Is Address Permanent</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.isAddressPermanent?.value ? 'Yes' : 'No'}}</p>
            </div>
            <div class="col-6">
                <h5>Address</h5>
            </div>
            <div class="col-6">
                <p>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.address1">
                        {{data?.personalInfo?.address?.value?.address1}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.address2">
                        {{data?.personalInfo?.address?.value?.address2}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.city">
                        {{data?.personalInfo?.address?.value?.city}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.state">
                        {{data?.personalInfo?.address?.value?.state}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.zipCode">
                        {{data?.personalInfo?.address?.value?.zipCode}}
                    </ng-container>
                </p>
            </div>
            <div class="col-6">
                <h5>EnrollmentId</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.enrollmentId || '-'}}</p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <h5 class="text-primary">
                    Plan
                </h5>
            </div>
            <div class="col-6">
                <h5>Carrier Code</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.carrierCode?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Current Aps</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.currentAps?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Customer Plan</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.customerPlan?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Plan Detail</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.planDetail?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Plan Price</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.planPrice?.value || '-'}}</p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type == 'Order Intake'">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h5>Order Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.orderStatus}}</p>
            </div>
            <div class="col-6">
                <h5>Order Description</h5>
            </div>
            <div class="col-6">
                <p>{{data?.orderDescription || '-'}}</p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <h5 class="text-primary">Personal Info</h5>
            </div>
            <div class="col-6">
                <h5>Name</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.name?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Phone</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.phone?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Email</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.email?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Date Of Birth</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.dateOfBirth?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>SSN</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.ssn?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Is Address Permanent</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.isAddressPermanent?.value ? 'Yes' : 'No'}}</p>
            </div>
            <div class="col-6">
                <h5>Address</h5>
            </div>
            <div class="col-6">
                <p>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.address1">
                        {{data?.personalInfo?.address?.value?.address1}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.address2">
                        {{data?.personalInfo?.address?.value?.address2}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.city">
                        {{data?.personalInfo?.address?.value?.city}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.state">
                        {{data?.personalInfo?.address?.value?.state}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.zipCode">
                        {{data?.personalInfo?.address?.value?.zipCode}}
                    </ng-container>
                </p>
            </div>
            <div class="col-6">
                <h5>EnrollmentId</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.enrollmentId || '-'}}</p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <h5 class="text-primary">
                    Plan
                </h5>
            </div>
            <div class="col-6">
                <h5>Carrier Code</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.carrierCode?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Current Aps</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.currentAps?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Customer Plan</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.customerPlan?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Plan Detail</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.planDetail?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Plan Price</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.planPrice?.value || '-'}}</p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type == 'Shipped Order'">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h5>IMEI</h5>
            </div>
            <div class="col-6">
                <p>{{data?.IMEI?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>MDN</h5>
            </div>
            <div class="col-6">
                <p>{{data?.MDN?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Brand</h5>
            </div>
            <div class="col-6">
                <p>{{data?.brand?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Order Description</h5>
            </div>
            <div class="col-6">
                <p>{{data?.orderDescription || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Order Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.orderStatus || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>PUK 1</h5>
            </div>
            <div class="col-6">
                <p>{{data?.puk1?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>PUK 2</h5>
            </div>
            <div class="col-6">
                <p>{{data?.puk2?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Sim Number</h5>
            </div>
            <div class="col-6">
                <p>{{data?.simNumber?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Sim Status</h5>
            </div>
            <div class="col-6">
                <p>{{data?.simStatus?.value || '-'}}</p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <h5 class="text-primary">Personal Info</h5>
            </div>
            <div class="col-6">
                <h5>Name</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.name?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Phone</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.phone?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Email</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.email?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Date Of Birth</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.dateOfBirth?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>SSN</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.ssn?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Is Address Permanent</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.isAddressPermanent?.value ? 'Yes' : 'No'}}</p>
            </div>
            <div class="col-6">
                <h5>Address</h5>
            </div>
            <div class="col-6">
                <p>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.address1">
                        {{data?.personalInfo?.address?.value?.address1}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.address2">
                        {{data?.personalInfo?.address?.value?.address2}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.city">
                        {{data?.personalInfo?.address?.value?.city}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.state">
                        {{data?.personalInfo?.address?.value?.state}},
                    </ng-container>
                    <ng-container *ngIf="data?.personalInfo?.address?.value?.zipCode">
                        {{data?.personalInfo?.address?.value?.zipCode}}
                    </ng-container>
                </p>
            </div>
            <div class="col-6">
                <h5>EnrollmentId</h5>
            </div>
            <div class="col-6">
                <p>{{data?.personalInfo?.enrollmentId || '-'}}</p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <h5 class="text-primary">
                    Plan
                </h5>
            </div>
            <div class="col-6">
                <h5>Carrier Code</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.carrierCode?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Current Aps</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.currentAps?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Customer Plan</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.customerPlan?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Plan Detail</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.planDetail?.value || '-'}}</p>
            </div>
            <div class="col-6">
                <h5>Plan Price</h5>
            </div>
            <div class="col-6">
                <p>{{data?.plan?.planPrice?.value || '-'}}</p>
            </div>
        </div>
    </div>
</ng-container>