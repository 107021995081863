import { Component } from '@angular/core';

@Component({
  selector: 'app-e-bill-setting',
  templateUrl: './e-bill-setting.component.html',
  styleUrls: ['./e-bill-setting.component.scss']
})
export class EBillSettingComponent {

}
