
<input 
[(ngModel)]="bsRangeValue" 
(ngModelChange)="onDateRangeChange.emit(bsRangeValue)"
[bsConfig]="bsConfig"
bsDaterangepicker 
type="text" 
readonly 
#drp="bsDaterangepicker"
class="pl-2 border-0 bg-transparent cursor-pointer"
style="width: 100%; color: #11100e; border:1px solid red"
/>

