import { Component, OnInit, Input, DoCheck, ChangeDetectorRef, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { CustomerProfileService } from '../../services/customer-profile.service';
import { CommonService } from '../../services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { SearchService } from '../../services/search.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit,DoCheck {

  @Input() isCustomerProfilePage: boolean = false;
  @LocalStorage()
  private userDetails;

  title: string;
  items: any=[];
  active_item: string;
  toggleUserProfile: boolean = false;
  statusFormData: FormGroup;
  statusOptions = [];
  rejectionReasonOptions = [];
  deEnrollCodeOptions = [];
  transExceptionOptions = [];
  loader: boolean = false;
  showTab: string = 'electronic';
  isHiddenData:any
  SETTINGSItems:any
  constructor(
    public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router:Router,
    private cd:ChangeDetectorRef,
    public breadcrumbService :BreadcrumbService,
    private searchService :SearchService,
    public navServices :NavService
  ) {
    commonService.breadCrumb.subscribe(res => {
      if (res.title) {
        this.title = res.title
        // if(this.title == 'Customer Profile'){
        //   this.items = [ {label:'Customer Profile'}]
        // }
      }
      // if (res.items) {
      //   this.items = res?.items ?? []
      // }
      if (res.active_item) {
        this.active_item = res.active_item
      }
    })

    this.navServices.collapseSidebar
  }

  ngDoCheck(): void {
    this.cd.detectChanges()
  }

  ngOnInit() {
   
    this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs) => {
      this.isCustomerProfilePage = breadcrumbs.some(breadcrumb => breadcrumb.isCustomerProfilePage);
      this.items = breadcrumbs;
      this.active_item =this.items[this.items.length-1]?.label
      console.log(this.items);
    });
   }

  initializeStatusForm() {
    this.statusFormData = this.fb.group({
      "status": [this.customerProfileService?.minimalProfileData?.personalInfo?.accountStatus?.value],
      "rejectionReason": [],
      "deEnrollCode": [],
      "transException": [],
    })
  }

  get status() {
    return this.statusFormData.get('status')
  }

  toggleCustomerProfile() {
    // this.toggleUserProfile = !this.toggleUserProfile
    this.customerProfileService.setProfileQuicklink(true)
    // this.toggleUserProfile = !this.toggleUserProfile
  }

  openModal(content) {
    this.initializeStatusForm()
    this.modalService.open(content, { backdropClass: "dark-modal", centered: true });
     this.customerProfileService.getDeactivatedResouceDetails()
    this.customerProfileService.deactiveInitilize();
    this.customerProfileService.getSideMenuBar2().subscribe((SETTINGSItems: any) => {
      this.isHiddenData = SETTINGSItems
      this.cd.detectChanges();
      this.SETTINGSItems = SETTINGSItems.filter(item => item.isHidden);
     
         this.getStatusOptions()
    });
  }

  async getStatusOptions() {
    try {
      const res: any = await this.customerProfileService.getStatusOptions();
      if (res.Status) {
        const statusData = res.data;
  
        // Map SETTINGSItems to match statusOptions based on the title and update the DisplayName
        this.statusOptions = this.SETTINGSItems.map((item, index) => {
          const matchedStatus = statusData.find(status => status.Name === item.title);
  
          if (matchedStatus) {
            // Update the DisplayName and set the item to statusOptions
            return {
              id: matchedStatus.id,
              Name: matchedStatus.Name,
              DisplayName: matchedStatus.DisplayName
            };
          }
  
          return null; // Return null if no match found
        }).filter(option => option !== null); // Filter out null values if no match found
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err);
    }
  }

  async getRejectionReasonOptions() {
    try {
      if (this.rejectionReasonOptions.length > 0) {
        return
      }
      const res: any = await this.customerProfileService.getRejectionsOptions()
      if (res.Status) {
        this.rejectionReasonOptions = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
  }

  async getTransExceptionOptions(){
    try {
      if (this.transExceptionOptions.length > 0) {
        return
      }
      const res: any = await this.customerProfileService.getExceptionCodeOptions();
      if (res.Status) {
        this.transExceptionOptions = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
  }

  async getDeEnrollCodeOptions() {
    try {
      if (this.deEnrollCodeOptions.length > 0) {
        return
      }
      const res: any = await this.customerProfileService.getDeEnrollCodeOptions()
      if (res.Status) {
        this.deEnrollCodeOptions = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
  }

  cancel() {
    this.initializeStatusForm()
  }

  onStatusChange() {
    if (this.status.value == 'Suspend') {
      this.getDeEnrollCodeOptions()
    } else if (this.status.value == 'Deactivated') {
      this.getRejectionReasonOptions()
      this.getDeEnrollCodeOptions()
    } else if (this.status.value == 'Active') {
      this.getTransExceptionOptions()
    }
  }

  async onSubmit() {
    this.loader = true;
    try {
      let data = this.statusFormData.value
      if (this.status.value == 'Suspend') {
        delete data.rejectionReason
        delete data.transException
      }
      if (this.status.value == 'Deactivated') {
        delete data.transException
      }
      if (this.status.value == 'Active') {
        delete data.rejectionReason
        delete data.deEnrollCode
      }
      const res: any = await this.customerProfileService.updateStatus(data, this.showTab);
      if(res.Status) {
        this.commonService.showToast("success", "Success", "Update successfully!")
        this.customerProfileService.getMinimalProfileData()
      } else {
        if (typeof res?.message === 'string') {
          this.commonService.showToast("error", "Error", res.message);
        } else if (typeof res?.message === 'object') {
          this.commonService.showToast("error", "Error", res.message?.error);
        } else {
          this.commonService.showToast("error", "Error", "An unknown error occurred");
        }
      }
    } catch (err) {
      this.commonService.showToast('error', 'Error', err?.message)
    }
    this.loader = false;
  }
  
  toggleHead(item){
    if(this.showTab !== item){
      this.showTab = item;
    }
  }

  getMinimalProfileData() {
    this.customerProfileService.setBreadcrumbRefreshed(true)
    this.customerProfileService.getMinimalProfileData()
    this.searchService.updateSearchTerm('hello');
  }

  routeDefault() {
    this.router.navigateByUrl(this.getUser()?.redirect_path ?? '/dashboard')
  }

  getUser() {
    return this.userDetails;
  }

}
