import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FeaturesService } from "../services/features.service";
import { CommonService } from "src/app/shared/services/common.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { LocalStorage } from "ngx-webstorage";
import { PlanChangeService } from "../../plan-change/services/plan-change.service";
import Swal from "sweetalert2";
import { HttpService } from "src/app/shared/services/httpService.service";
import { fadeInOut } from "src/app/shared/services/app.animation";

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

declare const google: any; // Declare the google variable
@Component({
  selector: "app-features-services",
  templateUrl: "./features-services.component.html",
  styleUrls: ["./features-services.component.scss"],
  animations:[fadeInOut]
})
export class FeaturesServicesComponent implements OnInit, AfterViewInit {
  wifiCallingStatus: any;
isPlanSkeleton: boolean=true;
  wifiCallingToggleChange(event: Event) {
    const target = event.target as HTMLInputElement; // Cast to HTMLInputElement
    if (target?.checked) {
      this.wifiCallingStatus = true;
      this.openModal(this.addWfcModal, "wfc");
    } else {
      this.withConfirmation();
    }
  }

  // @ViewChild("addressInput",{static:false}) public addressInput!:ElementRef ;
  @ViewChild("addWfcModal") addWfcModal!: TemplateRef<any>;
  // addWfcModal!: TemplateRef<any>;
  formData: FormGroup;

  loader: boolean = false;
  modalLoader: boolean = false;
  networkInfoDetails: any;
  networkReset;
  throttleSpeed;
  wfcFormData: FormGroup;
  countries: any = [];
  states: any = [];
  cities: any = [];
  zipcodes: any = [];
  throttleSpeedList: any = [];
  statesData: any;
  modalopen: boolean;
  wifiCalling: any;

  constructor(
    private commonService: CommonService,
    private featuresService: FeaturesService,
    private modalService: NgbModal,
    private customerProfileService: CustomerProfileService,
    private fb: FormBuilder,
    public planChangeService: PlanChangeService,
    private httpService:HttpService
  ) {
    this.initializeWfcForm();
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.getStates();
    this.initializeForm();
    this.initializeWfcForm();
    this.getNetworkInfo();

    this.networkReset =
      this.customerProfileService.minimalProfileData.lineInfo.MDN.value;
  }

  dismissModal(modal: any) {
    this.wifiCallingStatus = false; // Set wifiCallingStatus to false
    modal.dismiss("Cross click"); // Dismiss the modal with a reason
  }
  initAutocomplete(addressInput: HTMLInputElement): void {
    if (typeof google !== "undefined" && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components.map(
            (component: any) => ({
              long_name: component.long_name,
              short_name: component.short_name,
              types: component.types,
            })
          );
          this.bindAddressComponentsToForm(addressComponents);
        }
      });
    } else {
      console.error("Google Maps API not loaded");
    }
  }

  bindAddressComponentsToForm(components: AddressComponent[]): void {
    let address1 = "";
    let address2 = "";
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";

    components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = component.long_name;
          break;
        case "route":
          address1 += " " + component.long_name;
          break;
        case "sublocality":
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.short_name;
          break;
        case "postal_code":
          zipCode = component.long_name;
          break;
        default:
          break;
      }
    });

    this.wfcFormData.patchValue({
      address1: address1.trim(),
      address2: address2.trim(),
      country: country.trim(),
      state: state.trim(),
      city: city.trim(),
      zipCode: zipCode.trim(),
    });
  }
  async getStates() {
    let data = {};
    try {
      const response = (await this.commonService.getStates(data)) as any;
      if (
        response &&
        response.Status === true &&
        response.data &&
        Array.isArray(response.data)
      ) {
        const StatesData = response.data;
        this.statesData = StatesData;
        this.states = StatesData;
        StatesData.forEach((element) => {
          if (element.StateName) {
            this.states.push(element.StateName);
          }
        });

        let stateSet = new Set(this.states);
        this.states = [...stateSet];
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  initializeWfcForm() {
    this.wfcFormData = this.fb.group({
      address1: [, Validators.required],
      address2: [],
      country: [, Validators.required],
      state: [, Validators.required],
      city: [, Validators.required],
      zipCode: [, Validators.required],
    });
  }
  initializeForm() {
    this.formData = this.fb.group({
      chargeCreditCard: ["N"],
      planId: [, Validators.required],
    });
  }

  get country() {
    return this.wfcFormData.get("country");
  }
  get state() {
    return this.wfcFormData.get("state");
  }
  get city() {
    return this.wfcFormData.get("city");
  }

  async onAddProduct() {
    this.loader = true;
    try {
      // let data = {
      //   chargeCreditCard: "N",
      // };
      let data={
        "CustomerId":this.httpService.getCustomerId(),
        "retianData":true,
        "retianVoice":true,
        "retianSms":true, 
      }
      const res: any = await this.planChangeService.addProducts(data);
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Product added successfully!"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.message);
    }
    this.loader = false;
  }

  async onSubmitRenewPlan() {
    console.log("formData", this.formData);
    this.loader = true;
    try {
      let data = {
        chargeCreditCard: "N",
      };
      const res: any = await this.planChangeService.renewCustomerPlan(data);
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Update successfully!"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.message);
    }
    this.loader = false;
  }

  async getNetworkInfo() {
    this.loader = true;
    try {
      const res: any = await this.featuresService.getNetworkInfo();
      console.log("getNetworkInfo", res);
      if (res.Status) {
        this.networkInfoDetails = res.data;
        this.throttleSpeed =
          this.networkInfoDetails?.settings?.dataThrottle?.speed || "";
        this.wifiCallingStatus =
          this.networkInfoDetails?.settings?.wifiCalling?.enabled;
          this.isPlanSkeleton=false;
      } else {
        this.commonService.showToast("error", "Error", res.message);
        this.isPlanSkeleton=false;
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
      this.isPlanSkeleton=false;
    }
    this.loader = false;
  }

  async getThrottleSpeed() {
    this.loader = true;
    try {
      const res: any = await this.featuresService.getThrottleSpeed();
      console.log("getThrottleSpeed", res);
      if (res.Status) {
        this.throttleSpeedList = res.data;
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.loader = false;
  }

  async onNetworkReset() {
    this.loader = true;
    try {
      const res: any = await this.featuresService.networkReset();
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Updated"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.loader = false;
  }

  async onUpdateVoiceMail() {
    this.loader = true;
    try {
      let data = {
        enable: !this.networkInfoDetails.settings?.voicemail?.enabled,
      };
      const res: any = await this.featuresService.updateVoiceMail(data);
      if (res.Status) {
        this.networkInfoDetails.settings.voicemail.enabled =
          !this.networkInfoDetails.settings?.voicemail?.enabled;
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Updated"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.loader = false;
  }

  async onUpdateRoaming() {
    this.loader = true;
    try {
      let data = {
        enable: !this.networkInfoDetails.settings?.roamControl?.enabled,
      };
      const res: any = await this.featuresService.updateRoaming(data);
      console.log("updateRoaming res", res);
      if (res.Status) {
        this.networkInfoDetails.settings.roamControl.enabled =
          !this.networkInfoDetails.settings?.roamControl?.enabled;
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Updated"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.loader = false;
  }

  async addThrottleSpeed() {
    this.modalLoader = true;
    try {
      let data = {
        enable: true,
        throttleSpeed: this.throttleSpeed,
      };
      const res: any = await this.featuresService.updateDataThrottle(data);
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Updated"
        );
        this.closeModal();
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.modalLoader = false;
  }

  async onUpdateDataSaver() {
    this.loader = true;
    try {
      let data = {
        enable: !this.networkInfoDetails.settings?.dataSaver?.enabled,
      };
      const res: any = await this.featuresService.updateDataSaver(data);
      if (res.Status) {
        this.networkInfoDetails.settings.dataSaver.enabled =
          !this.networkInfoDetails.settings?.dataSaver?.enabled;
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Updated"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.loader = false;
  }

  async onDeleteVoiceMail() {
    this.loader = true;
    try {
      const res: any = await this.featuresService.deleteVoiceMail();
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Deleted",
          "Successfully Deleted"
        );
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err: any) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.loader = false;
  }

  openModal(content: TemplateRef<any>, type) {
    if (type == "wfc") {
      this.modalopen = true;
      this.getCountries();
    } else if (type == "throttle") {
      this.getThrottleSpeed();
      this.modalopen = false;
    }

    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
      centered: true,
    });
    modalRef.shown.subscribe(() => {
      setTimeout(() => {
        const addressInput = document.getElementById(
          "addressInput"
        ) as HTMLInputElement;
        if (addressInput) {
          this.initAutocomplete(addressInput);
        } else {
          console.error("addressInput is undefined after modal is shown");
        }
      }, 500);
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  async getCountries() {
    this.modalLoader = true;
    try {
      const res: any = await this.commonService.getCountries();
      console.log("countries", res);
      if (res.Status) {
        this.countries = res.data;
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.modalLoader = false;
  }

  async onSelectCountry() {
    this.modalLoader = true;
    try {
      let data = {
        CountryID: this.country.value,
      };
      const res: any = await this.commonService.getStates(data);
      console.log("getStates", res);
      if (res.Status) {
        this.states = res.data;
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.modalLoader = false;
  }

  async onSelectState() {
    this.onSelectCountry();
    if (!this.state.value) {
      return;
    }
    this.modalLoader = true;
    try {
      let state = this.states.find((el) => el.StateCode == this.state.value);
      let data = {
        StateID: state.id,
      };
      const res: any = await this.commonService.getCities(data);
      console.log("getCities", res);
      if (res.Status) {
        this.cities = res.data?.map((city): any => {
          return { city: city.CityName, id: city.id };
        });
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
    this.modalLoader = false;
  }

  withConfirmation() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel Wifi Calling?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
      customClass: {
        popup: "swal2-custom-width",
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.onCancelWfc();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.wifiCallingStatus = true;
      }
    });
  }

  cityObject(event: any) {
    console.log("event: ", event);
  }
  async onSelectCity() {
    let cityID = this.cities.find((el) => el.city === this.city.value)?.id;

    this.modalLoader = true;
    try {
      let data = {
        CityID: cityID,
      };
      const res: any = await this.commonService.getZipCodes(data);
      console.log("getZipCodes", res);
      if (res.Status) {
        this.zipcodes = res.data;
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
      console.log("city: ", this.city.value);
    }
    this.modalLoader = false;
  }

  async onSubmitWfc() {
    this.modalLoader = true;
    try {
      console.log(this.wfcFormData);
      let data = {
        enable: true,
        address: this.wfcFormData.value,
      };
      delete data.address["country"];
      console.log(data);
      const res: any = await this.featuresService.addWfc(data);
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Added"
        );
        this.wifiCallingStatus = true;
        this.closeModal();
      } else {
        this.commonService.showToast("error", "Error", res.message);
        this.wifiCallingStatus = false;
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
      this.wifiCallingStatus = false;
    }
    this.modalLoader = false;
  }

  async onCancelWfc() {
    console.log("city: ", this.city.value);

    this.modalLoader = true;
    try {
      console.log(this.wfcFormData);
      let data = {
        enable: false,
      };
      console.log("city: ", this.city.value);

      console.log(data);
      const res: any = await this.featuresService.addWfc(data);
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Successfully Added"
        );
        this.wifiCallingStatus = false;
        this.closeModal();
      } else {
        this.commonService.showToast("error", "Error", res.message);
        this.wifiCallingStatus = true;
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
      this.wifiCallingStatus = true;
    }
    this.modalLoader = false;
  }
}
