import { Component, OnInit } from '@angular/core';
import { PlanChangeService } from '../services/plan-change.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-credit-card-plan-change1',
  templateUrl: './credit-card-plan-change.component.html',
  styleUrls: ['./credit-card-plan-change.component.scss']
})
export class CreditCardPlanChangeComponent implements OnInit {

  plans: any = [];
  loader: boolean = false;
  formData: FormGroup
  customerOtherInfo: any;
  isSkeletonLoader: boolean = true
  plansList: any = [];
  billingAddress: any
  constructor(
    public planChangeService: PlanChangeService,
    public commonService: CommonService,
    public fb: FormBuilder,
    private httpService: HttpService,
  ) { }

  async ngOnInit() {
    this.initializeForm()
    // this.getPlans()
    this.getCustomerOtherInfo()
    let data = {
      CustomerId: this.httpService.getCustomerId(),
    };
    const resposne: any =
      await this.planChangeService.getMinimalprofileData(data);
    if (resposne?.Status) {
      this.billingAddress = resposne?.data?.personalInfo?.mailingAddress?.value;
      // this.currentPlan = resposne?.data?.plan?.planDetail?.value
    }
    await this.getPlansDetails();
  }

  initializeForm() {
    this.formData = this.fb.group({
      "chargeCreditCard": ["N"],
      "planId": [, Validators.required]
    })
  }

 
  calculateMonthlyCost(item): number {
    const totalPrice = parseFloat(item?.Price);
    if (item?.ExpirationDays > 0) {
      const averageMonthlyCost = (totalPrice / (item?.ExpirationDays == 365 ? 360 : item?.ExpirationDays)) * 30;  // Assume 30 days per month
      return parseFloat(averageMonthlyCost.toFixed(2));
    }
    return 0;
  }

  // Calculate the savings
  calculateSavings(item): any {
    const totalPrice = parseFloat(item?.Price);
    const baseAmount = parseFloat(item?.MonthlyPaymentAmount);

    // const saving = item?.MonthlyPaymentAmount / 30 * (item?.ExpirationDays == 365 ? 360 : item?.ExpirationDays) - item?.Price;

    if (item?.ExpirationDays > 0) {
      const averageMonthlyCost = ((item?.ExpirationDays == 365 ? 360 : item?.ExpirationDays) / 30) * baseAmount - totalPrice;  // Assume 30 days per month
      return parseFloat(averageMonthlyCost.toFixed(2));
    }
    return 0
  }

  async getPlansDetails() {
    this.commonService.loader = true
    try {
      const data = {
        planType: "Prepaid",
        stateAbbr: this.billingAddress?.state,
        'planAreas[]': ["CSR"],
        // "planTagId":this.planID || this.tabs[0].id,
      };
      // const companyName = this.authService.getCompanyName();
      // if (companyName === "unity") {
      //   delete data['planAreas[]'];
      // }
      const response: any = await this.planChangeService.getPrepaidPlans(data);
      if (response?.Status) {
        this.plansList = response.data;
        if (this.plansList.length > 0) {
          this.plansList.map(val => {
            if (val?.PlanData == 'Unlimited') {
              val.savingAmount = this.calculateSavings(val)
              val.monthlyAmount = this.calculateMonthlyCost(val)
            }
          })
          this.plans = this.plansList
          this.isSkeletonLoader = false
        }
        // this.plansDetected = !this.plansDetected;
        // this.loader=false

      } else {
        this.commonService.showToast("error", "Error", response?.message);
        this.isSkeletonLoader = false

      }
      this.commonService.loader = false
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
      this.commonService.loader = false
    } finally {
      // this.loader = false;
      this.commonService.loader = false
    }
  }
  // async getPlans() {
  //   this.loader = true;
  //   try {
  //     let data = {
  //       "stateAbbr": "WA"
  //     }
  //     const res: any = await this.planChangeService.getPlans(data);
  //     console.log('getPlans', res)
  //     if (res.Status) {
  //       this.plans = res.data
  //     } else {
  //       this.commonService.showToast("error", "Error", res.message)
  //     }
  //   } catch (err) {
  //     this.commonService.showToast("error", "Error", err.message)
  //   }
  //   this.loader = false;
  // }

  async getCustomerOtherInfo() {
    this.loader = true;
    try {
      const res: any = await this.planChangeService.getCustomerOtherInfo();
      if (res.Status) {
        this.formData.patchValue({ planId: res.data[0].PlanID })
        // this.plans = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.message)
    }
    this.loader = false;
  }

  async onSubmitPlanForm(planId: any) {
    console.log('formData', this.formData);
    let data = {
      "chargeCreditCard": ["N"],
      "planId": planId
    }
    this.loader = true;
    try {
      const res: any = await this.planChangeService.changeCustomerPlan(data)
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Update successfully!")
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.message)
    }
    this.loader = false;
  }

  async onSubmitRenewPlan() {
    console.log('formData', this.formData);
    this.loader = true;
    try {
      let data = {
        chargeCreditCard: "N"
      }
      const res: any = await this.planChangeService.renewCustomerPlan(data)
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Update successfully!")
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.message)
    }
    this.loader = false;
  }

  async onAddProduct() {
    this.loader = true;
    try {
      let data = {
        chargeCreditCard: "N"
      }
      const res: any = await this.planChangeService.renewCustomerPlan(data)
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Added successfully!")
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.message)
    }
    this.loader = false;
  }
}
