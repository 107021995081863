<div class="wrapper d-flex bg-white">
  <div class="left-panel">
    <ul ngbNav #CustomHover="ngbNav" [activeId]="1" class="nav  nav-pills  animation-nav   d-flex  mb-2">
      <li [ngbNavItem]="1" [routerLink]="['/customer-dashboard/order-history/order-table']">
          <a ngbNavLink>
            <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position "> Prepaid</h5>
          </a>
          <ng-template ngbNavContent>
            <!-- <app-credit-card-add-data [toupTypeData]="topupType"></app-credit-card-add-data> -->
          </ng-template>
      </li>
      <li [ngbNavItem]="2" [routerLink]="['/customer-dashboard/order-history/acp']">
          <a ngbNavLink>
            <!-- <i class="fa-solid fa-credit-card nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position ">Acp</h5>
          </a>
          <ng-template ngbNavContent>
            <app-credit-card-add-data></app-credit-card-add-data>
          </ng-template>
      </li>

    </ul>
  </div>

  <div class="right-panel w-100 px-3 py-2">
    <router-outlet></router-outlet>
  </div>
</div>