import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-device-info-account',
  templateUrl: './edit-device-info-account.component.html',
  styleUrls: ['./edit-device-info-account.component.scss']
})
export class EditDeviceInfoAccountComponent {

}
