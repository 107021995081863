import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss']
})

export class TableCellComponent implements OnInit {
  
  @Input() public rowdata;
  @Input() public mapping: string[];

  public cellData:string;

  constructor(){}

  @Output()
  promptModal: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.cellData = this.mapping.map(objAttr => this.rowdata[objAttr]).join(' ');
    }

  convertIntoLink(url){
    let lastIndex = url.lastIndexOf('/') + 1;
    return url.substring(lastIndex, )
  }

  openModal(){
    console.log("hello world");
  }
}
 