<div class="d-lg-flex d-block align-items-start">
  <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <button class="nav-link active" id="v-pills-credit-card-renew-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-credit-card-renew" 
    type="button" role="tab"  aria-controls="v-pills-credit-card-renew" 
    aria-selected="true">
    <div class="d-flex">
      <div>
  <img src="assets/images/mobile.png" class="card-img">
</div>
    <div class="d-flex align-items-center" >
      <h5>Credit Card</h5>
    </div>
    </div>
  </button>



    <button class="nav-link" id="v-pills-money-order-renew-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-money-order-renew" 
    type="button" role="tab" aria-controls="v-pills-money-order-renew" 
    aria-selected="false">
    <div class="d-flex">
      <div>
  <img src="assets/images/mobile.png" class="card-img">
</div>  
    <div class="d-flex align-items-center" >
      <h5>Money Order</h5>
    </div>
    </div>
  </button>

    <button class="nav-link" id="v-pills-money-gram-renew-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-money-gram-renew"
     type="button" role="tab" aria-controls="v-pills-money-gram-renew" 
     aria-selected="false">
     <div class="d-flex">
      <div>
  <img src="assets/images/mobile.png" class="card-img">
</div>  
    <div class="d-flex align-items-center" >
      <h5>Money Grams</h5>
    </div>
    </div>
    </button>

     <button class="nav-link" id="v-pills-wallet-renew-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-wallet-renew"
     type="button" role="tab" aria-controls="v-pills-wallet-renew" 
     aria-selected="false">
     <div class="d-flex">
      <div>
  <img src="assets/images/mobile.png" class="card-img">
</div>
    <div class="d-flex align-items-center" >
      <h5>Wallet</h5>
    </div>
    </div>
    </button>

     <button class="nav-link" id="v-pills-dealer-wallet-renew-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-dealer-wallet-renew"
     type="button" role="tab" aria-controls="v-pills-dealer-wallet-renew" 
     aria-selected="false">
    <div class="d-flex">
      <div>
  <img src="assets/images/mobile.png" class="card-img">
</div>
    <div class="d-flex align-items-center" >
      <h5>Dealer Wallet</h5>
    </div>
    </div> 
    </button>

     <button class="nav-link" id="v-pills-ivr-credit-card-renew-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-ivr-credit-card-renew"
     type="button" role="tab" aria-controls="v-pills-ivr-credit-card-renew" 
     aria-selected="false">
     <div class="d-flex">
      <div>
  <img src="assets/images/mobile.png" class="card-img">
</div> 
    <div class="d-flex align-items-center" >
      <h5>IVr credit card</h5>
    </div>
    </div>
    </button>

  </div>
  <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade show active" id="v-pills-credit-card-renew" role="tabpanel" aria-labelledby="v-pills-credit-card-renew-tab">
      <app-credit-card-renew></app-credit-card-renew></div>
   <div class="tab-pane fade" id="v-pills-money-gram-renew" role="tabpanel" aria-labelledby="v-pills-money-gram-renew-tab">
      <app-money-gram-renew></app-money-gram-renew></div>
    <div class="tab-pane fade" id="v-pills-money-order-renew" role="tabpanel" aria-labelledby="v-pills-money-order-renew-tab">
      <app-money-order-renew></app-money-order-renew></div>
    <div class="tab-pane fade" id="v-pills-wallet-renew" role="tabpanel" aria-labelledby="v-pills-wallet-renew-tab">
    <app-wallet-renew></app-wallet-renew></div>
    <div class="tab-pane fade" id="v-pills-dealer-wallet-renew" role="tabpanel" aria-labelledby="v-pills-dealer-wallet-renew-tab">
      <app-dealer-wallet-renew></app-dealer-wallet-renew></div>
    <div class="tab-pane fade" id="v-pills-ivr-credit-card-renew" role="tabpanel" aria-labelledby="v-pills-add-topup-tab">
      <app-ivr-credit-card-renew></app-ivr-credit-card-renew></div>
  </div>
</div>