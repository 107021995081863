<!-- <div class="custom-datatable table-responsive">
    <table class="table">
        <thead>
            <tr>
                <ng-container *ngFor="let column of columns">
                    <th scope="col" [sortable]="column.key" (sort)="onSort($event)">{{column.title}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableItem;let i = index">
                <td scope="row">
                    <ngb-highlight [result]="item.name" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="item.startDate" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="item.invoice" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-between mt-3">
        <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
            <option [ngValue]="2">2 items per page</option>
            <option [ngValue]="4">4 items per page</option>
            <option [ngValue]="6">6 items per page</option>
        </select>
        <ngb-pagination class="ms-3" [collectionSize]="(total$ | async)!" [(page)]="service.page"
            [pageSize]="service.pageSize"> <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
    </div>
</div> -->

<div class="custom-datatable table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Name</th>
                <th scope="col">PlanID</th>
                <th scope="col">RenewDate</th>
                <th scope="col">TransactionID</th>
                <th scope="col">Type</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td>
                    {{item?.FirstName || '-'}}
                </td>
                <td>
                    {{item?.LastName || '-'}}
                </td>
                <td>
                    {{item?.Name || '-'}}
                </td>
                <td>
                    {{item?.PlanID || '-'}}
                </td>
                <td>
                    {{item?.RenewDate | date : 'MMM d, y' || '-'}}
                </td>
                <td>
                    {{item?.TransactionID || '-'}}
                </td>
                <td>
                    {{item?.Type || '-'}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-between mt-3">
        <select class="form-control me-2 page-dropdown" style="width: auto" [(ngModel)]="itemsPerPage"
            (change)="onChangeNumberOfPage()">
            <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
        </select>

        <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
            [(page)]="currentPage" (pageChange)="pageChange($event)" [pageSize]="itemsPerPage"><ng-template
                ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
    </div>
</div>