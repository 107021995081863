<!-- Skeleton Credit Card Component -->
<div class="skeleton-credit-card-container d-flex justify-content-center align-items-center position-relative w-100 h-100 m-0 p-0">
    <div class="skeleton-credit-card w-100">
      
      <!-- Card Number Skeleton -->
      <div class="skeleton-card-number"></div>
  
      <!-- Cardholder Name Skeleton -->
      <div class="skeleton-cardholder-name"></div>
  
      <!-- Expiry Date and CVV Skeleton -->
      <div class="skeleton-card-details d-flex justify-content-between">
        <div class="skeleton-expiry"></div>
        <div class="skeleton-cvv"></div>
      </div>
    </div>
  </div>
      