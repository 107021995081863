<form [formGroup]="formData" class="w-100">
    <div class="row">
        <div class="col-6 my-2">
            <div class="form-group">
                <label>Code <span class="text-danger">*</span></label>
                <input type="text" formControlName="Code" placeholder="Code" class="form-control">
            </div>
        </div>
        <div class="col-6 my-2">
            <div class="form-group">
                <p>Group Type : Ticket System
                    | Group Email : info@aqsatel.com
                    | Group Owner : 1</p>

            </div>
        </div>
        <div class="col-12 my-2">
            <div class="form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea formControlName="Description" maxlength="2000" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <div class="col-12 my-2">
            <div class="form-group">
                <div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="IsActive"
                            id="flexCheckDefault">
                        <label class="form-check-label ms-2" for="flexCheckDefault">
                            Is Active
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <button type="button" (click)="cancel()" class="btn btn-warning">
                Cancel</button>
        </div>
        <div class="col-6 text-end">
            <button type="button" [disabled]="formData.invalid" (click)="onSubmit()" class="btn btn-primary">
                Submit</button>
        </div>
    </div>
</form>