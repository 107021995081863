import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from './httpService.service';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  @LocalStorage()
  private userDetails;
  constructor(private dbService: NgxIndexedDBService,private http :HttpService) { }

  addResourceCategory(resourceCategory: any): Promise<any> {
    return this.dbService.getByKey('resource_categories', resourceCategory.Name).toPromise().then(existingCategory => {
      if (existingCategory) {
        // If the category exists, update it
        return this.dbService.update('resource_categories', resourceCategory).toPromise();
      } else {
        // If the category doesn't exist, add it
        return this.dbService.add('resource_categories', resourceCategory).toPromise();
      }
    });
  }

  getResourceCategoryById(id: string): Promise<any> {
    return firstValueFrom(this.dbService.getByKey('resource_categories', id));
  }

  getAllResourceCategories(): Promise<any[]> {
    return firstValueFrom(this.dbService.getAll('resource_categories'));
  }

  updateResourceCategory(resourceCategory: any): Promise<any> {
    return firstValueFrom(this.dbService.update('resource_categories', resourceCategory));
  }

  deleteResourceCategory(id: number): Promise<any> {
    return firstValueFrom(this.dbService.delete('resource_categories', id));
  }

  fetchAllResourceCategories() {
    try {
      let url = "api/system/resourcePermissionswithChilds";
      let body = {
        userID: this.getUser()?.userID,
        companyId: "UNITYCRM0021",
      };
      return this.http.get(url, body).toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getUser() {
    return this.userDetails;
  }
}
