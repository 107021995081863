<div class="card py-3 px-2">
    <div
    class="d-flex flex-column flex-lg-row w-100 px-3 justify-content-between position-relative pb-2"
  >
    <!-- Title -->
    <h6 class="mb-3 mb-lg-0 col-lg-4 col-12">
      Scheduled Downgrades ({{ totalItems || 0 }})
    </h6>
  
    <!-- Actions Container -->
    <div class="d-flex col-lg-8 col-12 align-items-center gap-3">
      <!-- Search Input -->
      <input
        type="text"
        class="form-control ps-3"
        placeholder="Search..."
        [(ngModel)]="searchPortIn"
        (input)="filtePortInRequests($event)"
        (keyup.enter)="portingInRequestStatus(searchPortIn)"
      />
  
      <!-- Date Range Picker (if applicable) -->

  
      <!-- Filter Dropdown -->
      <select
        class="form-select"
        [(ngModel)]="selectedTypeFilter"
        (change)="selectFilter(selectedTypeFilter)"
        style="max-width: 250px"
      >
        <option *ngFor="let item of subscriptionTypeFilter" [value]="item.value">
          {{ item.DisplayName }}
        </option>
      </select>
  
      <!-- Refresh Button -->
      <app-filter-btn
        [icon]="'fa fa-refresh'"
        [isBtn]="true"
        (click)="reprocess()"
      ></app-filter-btn>
  
      <!-- Export Button -->
      <!-- <button class="btn btn-dark" (click)="exportData()">Export</button> -->
    </div>
  </div>
  
    <!-- Table -->
    <div class="table-responsive table-hover table-striped table-card" style="min-height: 500px; overflow-x: auto;">
      <table class="table table-nowrap mb-0" *ngIf="!isSkeletonLoader" @fadeInOut>
        <thead class="table-light">
          <tr>
            <th *ngFor="let column of columnDefs" style="white-space: nowrap;">{{ column.headerName }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of portingRequestList; let i = index" [class.table-row-active]="dropdownVisible && dropdownRow === i">
            <td *ngFor="let column of columnDefs">
                <span>
                  <!-- Handle fields that start with 'Is' -->
                  <ng-container *ngIf="column.field.startsWith('Is')">
                    <span class="custom-badge" [ngClass]="row[column.field] ? 'badge-success' : 'badge-danger'">
                      {{ row[column.field] ? 'Yes' : 'No' }}
                    </span>
                  </ng-container>
              
                  <!-- Handle Status field -->
                  <ng-container *ngIf="column.field === 'Status'">
                    <span class="custom-badge" [ngClass]="{
                      'badge-success': row[column.field] === 'Complete',
                      'badge-warning': row[column.field] === 'Scheduled',
                      'badge-info': row[column.field] === 'Cancelled',
                      'badge-danger': row[column.field] === 'Failed'
                    }">
                      {{ row[column.field] || '-' }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="column.field.endsWith('Date')">
                    {{ commonService.formatToLocalDateTime(row[column.field], 'date') }}
                  </ng-container>
                  <ng-container *ngIf="column.field === 'Action'">
                    <div class="action-icon-container" (click)="onClickAction(row)">
                      <i class="fa fa-ellipsis" style="cursor: pointer;"></i>
                    </div>
                    <div *ngIf="dropdownVisible && dropdownRow === row" class="dropdown-content-2 bg-white">
                      <div class="dropdown-item">
                        <span (click)="reprocessData(row)">
                          <i class="fas fa-redo mr-2"></i>Reprocess
                        </span>
                      </div>
                      <div class="dropdown-item">
                        <span (click)="withConfirmation(row, row.id, row.CustomerID)">
                          <i class="fas fa-times mr-2"></i>Cancel
                        </span>
                      </div>
                      <div class="dropdown-item">
                        <span (click)="viewDowngradeStatus(row)">
                          <i class="fas fa-eye mr-2"></i>View
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  
                  
                  
                  
                  <!-- Default case for all other fields -->
                  <ng-container *ngIf="!column.field.startsWith('Is') && column.field !== 'Status' && !column.field.endsWith('Date') && column.field !== 'Action'">
                    {{ row[column.field] || '-' }}
                  </ng-container>
                </span>
              </td>
              
              
              
              
              
          </tr>
        </tbody>
      </table>
  
      <!-- Skeleton Loader -->
      <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
        <app-skeleton-loader [tableRow]="8" [tableColumn]="6" shape="table"></app-skeleton-loader>
      </div>
  
      <!-- No Data Found -->
      <div class="text-center py-3 text-muted" *ngIf="portingRequestList?.length === 0">
        No data found
      </div>
    </div>
  
    <!-- Pagination -->
    <div class="pull-right py-3 row align-items-center justify-content-end">
      <div class="col-md-6 d-flex justify-content-end">
        <select
          class="form-select me-2 page-dropdown"
          [(ngModel)]="itemsPerPage"
          (change)="onChangeNumberOfPage()"
          style="width: auto;"
        >
          <option *ngFor="let page of pageDropdownMenu" [value]="page">{{ page }}</option>
        </select>
        <ngb-pagination
          [maxSize]="1"
          [directionLinks]="true"
          [boundaryLinks]="true"
          [collectionSize]="totalItems"
          [(page)]="currentPage"
          (pageChange)="pageChange($event)"
          [pageSize]="itemsPerPage"
        >
          <ng-template ngbPaginationPrevious>Previous</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
  