import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class AddDataService {

  constructor(
    private http: HttpService
  ) { }

  getTopUps(data) {
    try {
      return this.http.get('api/MyAccount/GetTopUps', data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }

  transferToPrepaid(data){
    try {
      return this.http.post('api/Customer/ConvertToPrePaid', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  addCustomerTopUp(data) {
    try {

      return this.http.post('api/Customer/AddCustomerTopUp', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getTopUpTaxes(data){
    try{
      return this.http.get('api/Customer/GetTopUpTaxes',data).toPromise()
    }catch(err){
      throw Error(err)
    }
  }
  addFreeCustomerTopUp(data){
    try{
      return this.http.post('api/Customer/FreeCustomerTopUp',data).toPromise()
    }catch(err){
      throw Error(err)
    }
  }
}
