import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  noNotesEvent = new BehaviorSubject<boolean>(false);
  noNotesEvent$= this.noNotesEvent.asObservable();

  noNotes(){
    this.noNotesEvent.next(true);
  }
  notesAvailable(){
    this.noNotesEvent.next(false);
  }
  constructor(
    private http: HttpService,
  ) { }

  addNote(data) {
    try {
      return this.http.post('api/note/add-note', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getNotes(data) {
    try {
      return this.http.get('api/Customer/getActivityAndNotes', data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addNoteType(data) {
    try {
      return this.http.post('api/system/add-note-types', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getNotesTypes() {
    try {
      return this.http.get('api/system/get-note-types', null, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketById(id) {
    try {
      let url = "api/tickets/single-ticket/" + id
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addTicket(data) {
    try {
      let url = "api/tickets/create"
      return this.http.post(url, data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  attachFile(data) {
    try {
      let url = "api/tickets/upload-attachments";
      return this.http.post(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  deleteAttachFile(id) {
    try {
      let url = "api/tickets/delete-attachment/" + id
      return this.http.delete(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketsStatus() {
    try {
      let url = "api/tickets/get-ticket-statuses"
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketTypes() {
    try {
      let url = "api/tickets/ticket-types"
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAgents() {
    try {
      let url = "api/system/agents-list-no-page"
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getGroups() {
    try {
      let url = "api/system/groups-list"
      let body = {
        "groupTypeName": "TICKET_SYSTEM"
      }
      return this.http.get(url, body, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

}