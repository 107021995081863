


<div class="">
  <ul ngbNav #CustomHover="ngbNav" [activeId]="1" class="nav  nav-pills  animation-nav bg-light  d-flex gap-3 mb-2">
    <li [ngbNavItem]="1">
        <a ngbNavLink>
          <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
          <h5 class="nav-titl nav-tab-position "> Credit Card</h5>
        </a>
        <ng-template ngbNavContent>
          <app-credit-card-purchase></app-credit-card-purchase>
        </ng-template>
    </li>


  </ul>
  <div class="card-body">
    <div class="tab-content text-muted">
      <div [ngbNavOutlet]="CustomHover"></div>
    </div>
</div>
<div class="d-lg-flex d-block align-items-start flex-column" *ngIf="newtabsCondition">
  <div class="nav  nav-tabs mb-0 w-100" id="v-pills-tab" role="tablist">
    <button class="nav-link active" id="v-pills-credit-card-purchase-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-credit-card-purchase" type="button" role="tab"
      aria-controls="v-pills-credit-card-purchase" aria-selected="true">
      <div class="d-flex">
        <div>
          <!-- <img src="assets/images/mobile.png" class="card-img"> -->
        </div>
        <div class="d-flex align-items-center">
          <h5> Credit Card</h5>
        </div>
      </div>
    </button>


    <!-- <button class="nav-link" id="v-pills-money-order-purchase-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-money-order-purchase" type="button" role="tab"
      aria-controls="v-pills-money-order-purchase" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5> Money Order</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-money-gram-purchase-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-money-gram-purchase" type="button" role="tab" aria-controls="v-pills-money-gram-purchase"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5> Money Gram</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-wallet-purchase-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-wallet-purchase" type="button" role="tab" aria-controls="v-pills-wallet-purchase"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5> Wallet</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-dealer-wallet-purchase-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-dealer-wallet-purchase" type="button" role="tab"
      aria-controls="v-pills-dealer-wallet-purchase" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5> Dealer Wallet</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-ivr-credit-card-purchase-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-ivr-credit-card-purchase" type="button" role="tab"
      aria-controls="v-pills-ivr-credit-card-purchase" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5> IVR Credit Card</h5>
        </div>
      </div>
    </button> -->

  </div>
  <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade show active" id="v-pills-credit-card-purchase" role="tabpanel"
      aria-labelledby="v-pills-credit-card-purchase-tab">
      <app-credit-card-purchase></app-credit-card-purchase>
    </div>

    <!-- <div class="tab-pane fade" id="v-pills-money-gram-purchase" role="tabpanel"
      aria-labelledby="v-pills-money-gram-purchase-tab">
      <app-money-gram-purchase></app-money-gram-purchase>
    </div>
    <div class="tab-pane fade" id="v-pills-money-order-purchase" role="tabpanel"
      aria-labelledby="v-pills-money-order-purchase-tab">
      <app-money-order-purchase></app-money-order-purchase>
    </div>
    <div class="tab-pane fade" id="v-pills-wallet-purchase" role="tabpanel"
      aria-labelledby="v-pills-wallet-purchase-tab">
      <app-wallet-purchase></app-wallet-purchase>
    </div>
    <div class="tab-pane fade" id="v-pills-dealer-wallet-purchase" role="tabpanel"
      aria-labelledby="v-pills-dealer-wallet-purchase-tab">
      <app-dealer-wallet-purchase></app-dealer-wallet-purchase>
    </div>
    <div class="tab-pane fade" id="v-pills-ivr-credit-card-purchase" role="tabpanel"
      aria-labelledby="v-pills-ivr-credit-card-purchase-tab">
      <app-ivr-credit-card-purchase></app-ivr-credit-card-purchase>
    </div> -->
  </div>
</div>