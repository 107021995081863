<div class="container mt-0" style="max-width: 100%;">
  <div class="custom-card form-card shadow mt-0">
    <!-- Card Header -->
    <div class="card-header text-white d-flex justify-content-between br-5 text-center bg-primary">
      <div class="col-md-2"></div>
      <h5 class="card-title mb-0" style="font-size: 1.5rem; letter-spacing: 2px;">USAGE INFORMATION</h5>
      <a  class="button local bg-danger" (click)="sendTomail()" title="Send High Usage Email">
        <i class="fa fa-envelope mr-2 text-nowrap"></i> <span>Send High Usage Email </span>
      </a>
    </div>

    <div class="card-body" *ngIf="!isSkeletonLoader" @fadeInOut>
      <div class="card p-3 ">
        <div class="row">
          <div class="custom-card col-md-4">
            <div class="header-top border-bottom">
              <h5 class="f-w-500 text-dark card-title">Data Usage</h5>
              </div>
            <app-usage-info></app-usage-info>
          </div>
          <div class="col-md-8 text-center">

            <app-usage></app-usage>
            <!-- <div class="col-12">
              <div class="custom-card  ">
                <b class="d-block f-w-500">Call</b>
                <ng-container *ngIf="usageInfoData?.liveUsage?.voice?.limit > 0">
                  <p class="mb-0">
                    Call ({{ usageInfoData?.liveUsage?.voice?.used }} /
                    {{ usageInfoData?.liveUsage?.voice?.limit }})
                  </p>
                  <div class="progress mb-3">
                    <div class="progress-bar" role="progressbar"
                      [style.width]="(usageInfoData?.liveUsage?.voice?.used / usageInfoData?.liveUsage?.voice?.limit) * 100 + '%'">
                      {{ (usageInfoData?.liveUsage?.voice?.used / usageInfoData?.liveUsage?.voice?.limit) * 100 | number
                      : '1.2-2' }} %
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="col-12">
              <div class="custom-card ">
                <b class="d-block f-w-500">SMS</b>
                <ng-container *ngIf="usageInfoData?.liveUsage?.sms?.limit > 0">
                  <p class="mb-0">
                    SMS ({{ usageInfoData?.liveUsage?.sms?.used }} /
                    {{ usageInfoData?.liveUsage?.sms?.limit }})
                  </p>
                  <div class="progress mb-3">
                    <div class="progress-bar" role="progressbar"
                      [style.width]="(usageInfoData?.liveUsage?.sms?.used / usageInfoData?.liveUsage?.sms?.limit) * 100 + '%'">
                      {{ (usageInfoData?.liveUsage?.sms?.used / usageInfoData?.liveUsage?.sms?.limit) * 100 | number :
                      '1.2-2' }} %
                    </div>
                  </div>
                </ng-container>
              </div>
            </div> -->

            <!-- <div class="col-12">
              <div class="card  ">
                <b class="d-block f-w-500">DATA</b>
                <p class="mb-0">
                  DATA ({{ usageInfoData?.liveUsage?.planData?.full?.used }} /
                  {{ usageInfoData?.liveUsage?.planData?.full?.limit }})
                </p>
                <div class="progress mb-3">
                  <div class="progress-bar" role="progressbar"
                    [style.width]="(usageInfoData?.liveUsage?.planData?.full?.used / usageInfoData?.liveUsage?.planData?.full?.limit) * 100 + '%'">
                    {{ (usageInfoData?.liveUsage?.planData?.full?.used /
                    usageInfoData?.liveUsage?.planData?.full?.limit) * 100 | number : '1.2-2' }} %
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div *ngIf="usageInfoData?.liveUsage?.tetherData?.full?.limit > 0" class="col-12">
              <b class="d-block f-w-500">Hotspot</b>
              <p class="mb-0">
                Hotspot ({{ usageInfoData?.liveUsage?.tetherData?.full?.used }} /
                {{ usageInfoData?.liveUsage?.tetherData?.full?.limit }})
              </p>
              <div class="progress mb-3">
                <div class="progress-bar" role="progressbar"
                  [style.width]="(usageInfoData?.liveUsage?.tetherData?.full?.used / usageInfoData?.liveUsage?.tetherData?.full?.limit) * 100 + '%'">
                  {{ (usageInfoData?.liveUsage?.tetherData?.full?.used /
                  usageInfoData?.liveUsage?.tetherData?.full?.limit) * 100 | number : '1.2-2' }} %
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="isSkeletonLoader;" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [alignment]="'row'" [cardCount]="3" shape="small-card"></app-skeleton-loader>
      <div class="card w-100" style="height: 150px;background-color: white"></div>
    </div>

    <!-- Card Footer -->
    <!-- <div class="card-footer bg-primary"></div> -->
  </div>

  <!-- <div *ngIf="isLoading" class="card-loader">
      <app-loader [isChildLoader]="true"></app-loader>
    </div> -->
</div>