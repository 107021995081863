import { Component, Input, Output, EventEmitter, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { TicketSystemService } from '../services/ticket-system.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortRequestsService } from 'src/app/portIn-out/services/porting-requests.service';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-ticket-sidebar',
  templateUrl: './add-ticket-sidebar.component.html',
  styleUrls: ['./add-ticket-sidebar.component.scss'],
  providers: [DatePipe]
})
export class TicketSidebarComponent {
  @Input() isSidebarOpen: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() customerId: any
  @Input() ticketId: any = null; // or use a specific interface if available
  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<any>();
  allAgents: any = []
  allGroups: any = []
  activeId: any = 1
  allStatusList: any = [];
  editorContent: any;
  toolbarIcon: string = 'eye'
  commentsConfig: any = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '100',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    upload: (file: File) => { this.uploadFile(file) },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  descriptionConfig: any = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '100',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    upload: (file: File) => { this.uploadFile(file) },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  ticketForm: FormGroup;
  @LocalStorage()
  private userDetails;
  AssignType
  isEditingTitle: boolean;
  uploadedFiles: any;
  attachments: any = []
  apiEndPoint: string = environment.BASE_API;
  selectedParentTicketId: any;
  isSubTicket: boolean;
  initialFormValues: any;
  mainTask: any;
  allTicketTypesList: any;
  commentId: any;
  subtaskList: any = [];
  isSubticketMain: boolean;
  priorityList: any = [];
  activityList: any = [];
  isCustomerFound: boolean;
  showcrossIcon: boolean;
  @ViewChild('inputRef') inputElement!: ElementRef;
  constructor(
    public ticketSystemService: TicketSystemService,
    private fb: FormBuilder,
    public portRequestsService: PortRequestsService,
    public commonService: CommonService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {
    this.ticketForm = this.fb.group({
      Title: ['', Validators.required],
      AssignmentType: ['AGENT', Validators.required],
      TicketTypeID: [null, [Validators.required]],
      DueDate: ['', Validators.required],
      CustomerId: [''],
      CustomerName: [''],
      ContactNumber: [''],
      AlternateNumber: [''],
      Description: [''],
      comments: [''],
      StatusID: [1, [Validators.required]],
      AssigneeID: [this.getUser().userID, [Validators.required]],
      AgentID: [null],
      CommentText: [''],
      GroupID: [null],
      CreatedBy: [this.getUser().userID],
      UpdatedBy: [this.getUser().userID],
      CategoryID: [1],
      IsInternalNote: [0],
      ParentId: [null],
      PriorityId: [2]
    });
    this.initialFormValues = this.ticketForm.value;
    this.getAgents()
    this.getGroups()
    this.getTicketTypes()
    this.getTicketsStatus()
    this.getticketPriorityOption()

    if (this.ticketId) {
      this.getTicketById()
      this.getActivityById()
      this.isCustomerFound = true
    }

    if (this.customerId) {
      this.getCustomerData(this.customerId)
    }
  }



  closeSidebar() {
    this.close.emit();
  }



  async getAgents() {
    try {
      const res: any = await this.ticketSystemService.getAgents()
      if (res.Status) {
        this.allAgents = res.data
      } else {
      }
    } catch (err) {
    }
  }

  async getGroups() {
    try {
      const res: any = await this.ticketSystemService.getGroups()
      if (res.Status) {
        this.allGroups = res.data
      } else {
      }
    } catch (err) {
    }
  }

  async getTicketsStatus() {
    try {
      const res: any = await this.ticketSystemService.getTicketsStatus()
      if (res.Status) {
        this.allStatusList = res.data.map(obj => ({
          ...obj,
          active: false
        }));
      } else {
      }
    } catch (err) {
    }
  }

  async getticketPriorityOption() {
    try {
      const res: any = await this.ticketSystemService.getticketPriorityOption()
      if (res.Status) {
        this.priorityList = res.data
      } else {
      }
    } catch (err) {
    }
  }
  async getTicketTypes() {
    try {
      const res: any = await this.ticketSystemService.getTicketTypes()
      if (res.Status) {
        this.allTicketTypesList = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }



  getInitials(name: string): string {
    if (name) {
      const initials = name.split(' ').map(n => n[0]).join('').toUpperCase();
      return initials.length > 1 ? initials.substring(0, 2) : initials;

    }
    return ""
  }

  // This function generates a random color for the avatar background
  getColor(name: string): string {
    if (name) {
      const colors = ['#FFB3A7', '#A7FFB3', '#A7B3FF', '#FFF3A7', '#FFA7D7', '#A7FFF3']; // Lighter colors
      const hash = this.hashString(name);
      const colorIndex = hash % colors.length;
      return colors[colorIndex];
    }
    return "";
  }

  // This function generates a hash from the name string to assign a consistent color
  hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash);
  }

  uploadFile(file) {

    if (this.isEditing) {
      this.uploadFiles(file);
    } else {
      this.uploadedFiles = file
    }

  }

  toggleToolbar(config) {
    this[config].showToolbar = !this[config].showToolbar;
  }

  saveTicket() {
    console.log(this.ticketForm.value);
  }

  async getCustomerData(customerIdControl?) {
    console.log(this.ticketForm?.value, customerIdControl);
    this.portRequestsService.geProfileData(customerIdControl ? customerIdControl : this.ticketForm?.value?.CustomerId).subscribe(async (res: any) => {
      if (res?.Status) {
        const profileData = res.data.personalInfo;
        this.ticketForm.patchValue({
          CustomerName: profileData?.name?.value,
          ContactNumber: profileData?.phone?.value,
          AlternateNumber: profileData.AlternateNumber || '', // Optional field
          CustomerId: customerIdControl ? customerIdControl : this.ticketForm?.value?.CustomerId // Optional field
        });
        this.isCustomerFound = true
        this.showcrossIcon = false
      }
    }, err => {
      this.isCustomerFound = false
      this.showcrossIcon = true
      this.commonService.showToast('error', "Customer Not Found", "No customer was found associated with the provided ID. Please check and try again.")
    });

  }

  getUser() {
    return this.userDetails;
  }
  private formatDateToYYYYMMDD(date: string | Date): string {
    const selectedDate = new Date(date);
    return selectedDate.toISOString().split('T')[0]; // Extracts only the date part
  }

  async onSubmit() {
    try {
      const formValues = this.ticketForm.value;



      if (formValues.DueDate) {
        formValues.DueDate = this.formatDateToYYYYMMDD(formValues.DueDate);
      }


      // Perform validations
      const title = this.ticketForm.get("Title").value;
      const customerId = this.ticketForm.get("CustomerId").value;

      if (!title || !customerId || !this.isCustomerFound) {
        const missingFields = [];
        if (!title) missingFields.push("title");
        if (!customerId) missingFields.push("customerId");



        if (this.isSubTicket) this.isSubTicket = false;
        if (!this.isCustomerFound) {
          this.commonService.showToast(
            "error",
            "Error",
            `Please enter valid customerid .`
          )
          this.showcrossIcon = true
        } else {
          this.showcrossIcon = false
          this.commonService.showToast(
            "error",
            "Error",
            `Please fill the ${missingFields.join(" and ")}.`
          );
        }

        return;
      }

      // Set user-related fields
      const currentUser = this.getUser().userID;
      if (this.isEditing && !this.isSubTicket) {
        formValues.UpdatedBy = currentUser;
        console.log(formValues);
        const res: any = await this.ticketSystemService.updateTicket(this.ticketId, formValues);
        res.Status
          ? this.commonService.showToast("success", "Success", "Updated successfully!")
          : this.commonService.showToast("error", "Error", res.message);
        this.getTicketById()
      } else {
        formValues.CreatedBy = currentUser;

        // Handle linked ticket
        if (this.selectedParentTicketId) {
          formValues.LinkedTickets = [this.selectedParentTicketId];
        }

        const res: any = await this.ticketSystemService.addTicket(formValues);
        if (res.Status) {
          this.ticketId = res.data.id;
          this.isEditing = true
          this.getTicketById()
          this.selectedParentTicketId = res.data.id;
          this.commonService.showToast("success", "Success", "Added successfully!");

          // Reset form if it’s a sub-ticket
          if (this.isSubTicket) {
            this.resetFormToInitialState(false);
            this.attachments = [];
            this.isSubTicket = false
          }

          // Handle file uploads
          if (this.uploadedFiles) {
            this.uploadFiles(this.uploadedFiles);
          }

        } else {
          this.commonService.showToast("error", "Error", res.message);
        }
      }

      this.save.emit();
    } catch (err) {
      console.log(err);
      if (this.isSubTicket) this.isSubTicket = false;
      if (err.error.error && err.error.error.CustomerId && err.error.error.CustomerId.length > 0) {
        this.commonService.showToast("error", "Error", err.error.error.CustomerId[0]);
      } else {
        this.commonService.showToast("error", "Error", err.message || "An error occurred");
      }
    }
  }



  async getTicketById() {
    try {
      this.subtaskList = []
      const res: any = await this.ticketSystemService.getTicketById(this.ticketId)
      if (res.Status) {
        const resData = res.data;
        const duedate =new Date(resData?.DueDate)
        const formattedData = {
          ...resData,
          DueDate: duedate,
          AssigneeID: resData?.AssigneeID ? resData.AssigneeID.toString() : ''
        };
        this.ticketForm.patchValue(formattedData)

        this.attachments = res.data.attachments
        this.subtaskList = res.data.children
        this.ticketForm.get('CustomerId').patchValue(res?.data?.CustomerID)
        if (res?.data?.customer?.personal_info?.FirstName) {
          this.ticketForm.get('CustomerName').patchValue(`${res?.data?.customer?.personal_info?.FirstName || ''} ` +
            `${res?.data?.customer?.personal_info?.MiddleName ? res?.data?.customer?.personal_info?.MiddleName + ' ' : ''}` +
            `${res?.data?.customer?.personal_info?.LastName || ''}`)
        }
        this.ticketForm.get('ContactNumber').patchValue(res?.data?.customer?.personal_info?.PrimaryPhone ?? '')
        this.ticketForm.get('AlternateNumber').patchValue(res?.data?.customer?.personal_info?.SecondaryPhone ?? '')
      } else {
        this.commonService.showToast("error", "Error", res?.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err?.error?.message)
    }
  }

  async getActivityById() {
    try {
      const res: any = await this.ticketSystemService.getActivityById(this.ticketId)
      if (res.Status) {
        this.activityList = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.error.message)
    }
  }

  onAssignmentTypeChange() {
    this.ticketForm.get('AgentID').reset()
    this.ticketForm.get('GroupID').reset()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticketId'] && changes['ticketId'].currentValue) {
      this.isSubTicket = false
      // this.getTicketById(); // Fetch new ticket data when ticketId changes
      // this.getActivityById(); // Fetch new ticket data when ticketId changes
    } else {
      // this.resetFormToInitialState(false)
      this.attachments = []
    }

  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return ''; // Return a placeholder or handle it as needed
    }
    return formatDate(dateString, 'MMM d, y h:mm a', 'en-US');
  }


  startEditingTitle() {
    this.isEditingTitle = true;
    setTimeout(() => {
      this.inputElement.nativeElement.focus();  // Programmatically focus on the textarea
    }, 0);
  }

  stopEditingTitle() {
    this.isEditingTitle = false;
  }


  async uploadFiles(file) {
    // Implement file upload logic here
    const form = new FormData()
    form.append('companyId', "UNITYCRM0021")
    form.append('userID', this.getUser().userID)
    form.append('files[]', file)
    form.append('TicketID', this.ticketId)
    try {
      const res = await this.ticketSystemService.attachFile(form);
      this.ticketId = null
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  onAddSubTicketClick() {
    this.mainTask = this.ticketForm.value
    this.isSubTicket = true
    if (!this.ticketId) {
      this.onSubmit()
    } else {
      this.selectedParentTicketId = this.ticketId
      this.resetFormToInitialState(true)
      this.attachments = []
      this.ticketId = null
    }
    console.log(this.ticketId);
    console.log(this.ticketForm.get('Title')?.value);

  }

  resetFormToInitialState(addParentId) {
    this.ticketForm?.setValue({
      Title: '',
      AssignmentType: 'AGENT',
      TicketTypeID: null,
      DueDate: '',
      CustomerId: '',
      CustomerName: '',
      ContactNumber: '',
      AlternateNumber: '',
      Description: '',
      comments: '',
      StatusID: 1,
      AssigneeID: this.getUser().userID,
      AgentID: null,
      CommentText: '',
      GroupID: null,
      CreatedBy: this.getUser().userID,
      UpdatedBy: this.getUser().userID,
      CategoryID: 1,
      IsInternalNote: 0,
      PriorityId: null,
      ParentId: this.selectedParentTicketId && addParentId ? parseInt(this.selectedParentTicketId, 10) : null
    });
  }

  async onCommentAdd() {
    try {
      const modifiedFormValue = { ...this.ticketForm.value };
      modifiedFormValue.CreatedBy = this.getUser()?.userID
      modifiedFormValue.CommentText = modifiedFormValue.CommentText.replace(/&nbsp;/g, ' ').replace(/&#160;/g, ' ');
      console.log(modifiedFormValue);
      const res: any = await this.ticketSystemService.addComment(this.ticketId, modifiedFormValue)
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Comment added successfully!")
        this.getTicketById()
        this.ticketForm.patchValue({
          CommentText: ""
        });
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }


  setChildrenToMain(item) {
    this.isSubticketMain = true
    this.ticketId = item?.id
    this.getTicketById()
    this.getActivityById()
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    console.log(changeEvent);
  }

}
