<div class="container-fluid m-0">
  <!-- Wrapper for the skeleton cards -->
  <div [ngClass]="{
        'd-flex flex-wrap justify-content-start': alignment === 'row',
        'flex-column': alignment === 'column'
      }" 
      class="gap-1"
  >
    <!-- Loop through cardArray to generate the cards -->
    <div *ngFor="let card of cardArray" class="card skeleton-card p-4 w-100" >
      
      <div *ngFor="let row of rowsArray" class="d-flex justify-content-between mb-3">
        <div class="bg-light w-25 p-2"></div> <!-- Label Skeleton -->
        <div class="bg-light w-50 p-2"></div> <!-- Data Skeleton -->
      </div>

    
  </div>
</div>
