<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    Setup the plan in Oss</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body ">
  
  <div class="container-fluid">
    <div class="row my-2">
 <div class="col-md-6">
  <label>Plan Name</label><br>
  <input type="text" class="form-control" >
 </div>
 <div class="col-md-6">
  <label>Plan Description</label><br>
  <input type="text" class="form-control" >
 </div>
    </div>
    <div class="row my-2">
      <div class="col-md-6">
       <label>Plan Category</label><br>
       <select class="form-control form-select">
        <option>Select </option>
        </select>
      </div>
      <div class="col-md-6">
       <label>Plan State</label><br>
       <select class="form-control form-select">
        <option>Select </option>
        </select>
      </div>
         </div>
  </div>


</div>
<div class="modal-footer justify-content-end">
  <button type="button" class="btn btn-primary" (click)="modal.dismiss('cancel click')">Cancel</button>
  <button type="button" ngbAutofocus class="btn btn-primary ms-3" (click)="modal.close('Ok click')">Submit</button>
</div>