import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserAgentService } from "../../services/user-agent.service";
import { Router } from "@angular/router";
import { ManageAgentService } from "../../services/manage-agent.service";
import { CommonService } from "src/app/shared/services/common.service";

@Component({
  selector: "app-edit-add-agent",
  templateUrl: "./edit-add-agent.component.html",
  styleUrls: ["./edit-add-agent.component.scss"],
})
export class EditAddAgentComponent {
  addAgentForm: FormGroup;
  private selectedState: any;
  private selectedCity: any;
  public citiesList: any;
  editId: boolean;
  public stateList: any[] = [];
  public singleAgentDatas: any = [];
  public stateId: any;
  public statesData: any;
  masterAgentList: any = [];
  distributorList: any = [];
  reTailarList: any;
  constructor(
    private fb: FormBuilder,
    private userAgentService: UserAgentService,
    private agentService: ManageAgentService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.addAgentForm = this.fb.group({
      groupName: [""],
      employeeName: [""],
      retailerName: [""],
      distributerName: [""],
      masterAgent: ["Select"],
      city: ["Select"],
      state: ["Select"],
      zip: ["Select"],
      email: [""],
      status: [""],
      user_type: [],
    });
  }

  grtStateId() {
    const stateObject = this.stateList.find(
      (state) => state.StateName == this.selectedState
    );
  }
  ngOnInit(): void {
    history.state.user;
    if (history.state.user) {
      this.editId = true;
      this.addAgentForm.patchValue({
        groupName: history.state.user.Group || "",
        employeeName: history.state.user.createdby.name || "",
        retailerName: history.state.user.RetailerID || "",
        distributerName: history.state.user.DistributorID || "",
        masterAgent: history.state.user.MasterAgentID || "Select",
        city: history.state.user.CityID || "Select",
        state: history.state.user.StateID || "Select",
        zip: history.state.user.ZipID || "Select",
        email: history.state.user.createdby.email || "",
        status: history.state.user.createdby.status_id || "",
        user_type: history.state.user.AgentTypeId || [],
      });
    } else {
      this.editId = false;
    }
    console.log("agent data", history.state.user);
    this.addAgentForm.get("state").valueChanges.subscribe((selectedState) => {
      this.selectedState = selectedState;
      this.addAgentForm.value.state = this.selectedState;
      this.onStateSelect(this.selectedState);
    });
    this.addAgentForm.get("city").valueChanges.subscribe((selectedCity) => {
      this.selectedCity = selectedCity;
      this.addAgentForm.value.city = this.selectedCity;
      this.onSelectCity(this.selectedCity);
    });
    this.getMasterAgent();
    this.getStates();
    this.getRetailerList();
    this.getDistributorList();
  }
  onStateSelect(state: string) {
    const stateObject = this.statesData.find((s) => s.id === state);
    if (stateObject) {
      const stateId = stateObject.id;
      this.getCities(stateId);
    } else {
      console.error("Selected state not found in stateList");
    }
  }
  onSelectCity(city: string) {
    const cityObject = this.citiesData.find((s) => s.id === city);
    this.getZipCodes(cityObject.id);
  }
  async getStates() {
    let data = {};
    try {
      const response = (await this.commonService.getStates(data)) as any; // Use 'any' to handle unknown response type
      if (
        response &&
        response.Status === true &&
        response.data &&
        Array.isArray(response.data)
      ) {
        const StatesData = response.data;
        this.statesData = StatesData;
        console.log("stat", this.statesData); // Assign the cities array to this.cities
        StatesData.forEach((element) => {
          if (element.StateName) {
            this.stateList.push(element.StateName);
          }
          // console.log('agent form:',this.stateList)
        });
        let stateSet = new Set(this.stateList);
        this.stateList = [...stateSet];
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  public citiesData: any;
  async getCities(stateId) {
    let data = {
      StateID: stateId,
    };
    try {
      const response = (await this.commonService.getCities(data)) as any;
      if (
        response &&
        response.Status === true &&
        response.data &&
        Array.isArray(response.data)
      ) {
        const CitiesData = response.data;
        this.citiesData = CitiesData;

        this.citiesList = CitiesData.map((city) => city.CityName); // Assuming CitiesData is an array of objects with CityName property
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  }
  public zipCodeDatas: any;
  async getZipCodes(cityId) {
    let data = {
      CityID: cityId,
    };
    try {
      const response = (await this.commonService.getZipCodes(data)) as any;
      if (
        response &&
        response.Status === true &&
        response.data &&
        Array.isArray(response.data)
      ) {
        const zipCodesData = response.data;
        console.log("zips:", zipCodesData);

        this.zipCodeDatas = zipCodesData.map((zip) => zip.ZipCode); // Assuming CitiesData is an array of objects with CityName property

        console.log("zipLists:", this.zipCodeDatas);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  }

  submitAgent() {
    let obj = {
      DepartmentID: 4,
      MarketID: 5,
      SupervisorID: 4,
      MasterAgentID: this.addAgentForm.get("masterAgent").value,
      DistributerID: this.addAgentForm.get("distributerName").value,
      RetailerID: this.addAgentForm.get("retailerName").value,
      PhoneNumber: "1234567890",
      AddressLine1: "123 Main Street",
      AddressLine2: "36th Apartment",
      CountryID: 1,
      StateID: this.addAgentForm.get("state").value,
      CityID: this.addAgentForm.get("city").value,
      ZipID: this.addAgentForm.get("zip").value,
      SerialNumber: 123,
      ActivationDate: "2023-12-28",
      RegistrationDate: "2023-12-28",
      IsRoyaltyRequired: true,
      RoyaltyAmount: 12.34,
      AgentModeID: 2,
      CurrentVersion: "1.0",
      ShowGoogleMap: true,
      PlanSetting: "Basic",
      MigrationStatusId: 1,
      EntityTypeID: 2,
      AgentTypeId: 1,
    };
    if (history.state.user) {
      this.agentService
        .updateAgent1(obj, history.state.user.user.id)
        .subscribe({
          next: (res: any) => {
            if (res.Status) {
              console.log("Success:", res);
              this.commonService.showToast(
                "success",
                "Success",
                "Successfully Updated"
              );
            } else {
              console.log("Error:", res);
              this.commonService.showToast("error", "Error", res.message);
            }
          },
          error: (error) => {
            console.error("Error:", error);
            this.commonService.showToast(
              "error",
              "Error",
              "An unexpected error occurred."
            );
          },
        });
    } else {
      this.agentService.CreateAgent(obj).subscribe({
        next: (res: any) => {
          if (res.Status) {
            console.log("Success:", res);
            this.commonService.showToast(
              "success",
              "Success",
              "Agent created successfully"
            );
          } else {
            console.log("Error:", res);
            this.commonService.showToast("error", "Error", res.message);
          }
        },
        error: (error) => {
          console.error("Error:", error);
          this.commonService.showToast(
            "error",
            "Error",
            "An unexpected error occurred while creating the agent."
          );
        },
      });
    }
  }
  getMasterAgent() {
    this.agentService.getMasterAgent().subscribe((res: any) => {
      this.masterAgentList = res?.data?.data;
    });
  }
  onMasterAgentSelected(event: any) {
    // console.log("master id",event)
    // this.getDistributorList()
  }
  getDistributorList() {
    this.agentService.getDistributorList1().subscribe((res: any) => {
      this.distributorList = res?.data?.data;
      console.log("distributer", this.distributorList);
    });
  }
  getRetailerList() {
    this.agentService.getRetailers().subscribe((res: any) => {
      this.reTailarList = res?.data?.data;
    });
  }
  back() {
    this.router.navigateByUrl(`/manage-agent`);
  }
}
