import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UserSettingsDashboard } from './user_settings/user_settings_dashboard/user_settings_dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { SearchResultPageComponent } from './search-result-page/search-result-page.component';
import { ManageUserComponent } from './user_settings/user_settings_dashboard/manage-user/manage-user.component';
import { ManageAgentComponent } from './user_settings/user_settings_dashboard/manage-agent/manage-agent.component';
import { GroupUserComponent } from './user_settings/user_settings_dashboard/group-user/group-user.component';
import { ManagePermissionComponent } from './user_settings/user_settings_dashboard/manage-permissions/manage-permissions.component';
import { MessageService } from 'primeng/api';
import { AddMemberGroupComponent } from './add-member-group/add-member-group.component';
import { ManageGroupPermissionComponent } from './user_settings/user_settings_dashboard/manage-group-permissions/manage-group-permissions.component';
import { ManageCombosComponent } from './user_settings/user_settings_dashboard/manage-combos/manage-combos.component';
import { ManageNoteTypesComponent } from './user_settings/user_settings_dashboard/manage-note-types/manage-note-types.component';
import { ManageComboItemsComponent } from './user_settings/user_settings_dashboard/manage-combo-items/manage-combo-items.component';
import { ManageMakeModelsComponent } from './user_settings/user_settings_dashboard/manage-make-models/manage-make-models.component';
import { ImageModalComponent } from './user_settings/user_settings_dashboard/manage-combo-items/image-modal/image-modal.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AgGridModule } from 'ag-grid-angular';
import { LoaderInterceptor } from './shared/services/loader.interceptor';
import { ManageResourcesComponent } from './user_settings/user_settings_dashboard/manage-resources/manage-resources.component';
import { ResourceTypeModalComponent } from './user_settings/user_settings_dashboard/resource-type-modal/resource-type-modal.component';
import { ManageCategoryComponent } from './user_settings/user_settings_dashboard/manage-category/manage-category.component';
import { NewManageUserComponent } from './user_settings/user_settings_dashboard/new-manage-user/new-manage-user.component';
import { EditManageUsersComponent } from './user_settings/user_settings_dashboard/edit-manage-users/edit-manage-users.component';
import { NewProfileComponent } from './user_settings/user_settings_dashboard/new-profile/new-profile.component';
import { NewManageAngentComponent } from './user_settings/user_settings_dashboard/new-manage-angent/new-manage-angent.component';
import { EditAddAgentComponent } from './user_settings/user_settings_dashboard/edit-add-agent/edit-add-agent.component';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { reducers } from './store/app.state';
// import { metaReducers } from './store/app.effect';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './store/notifications/notification.effect';
import { NewDashboardComponent } from './customer-dashboard/customer-pages/new-dashboard/new-dashboard.component';
import { CustomerPagesModule } from './customer-dashboard/customer-pages/customer-pages.module';
import { TagTypeComponent } from './user_settings/user_settings_dashboard/tag-type/tag-type.component';
import { TicketTagsComponent } from './user_settings/user_settings_dashboard/ticket-tags/ticket-tags.component';
import { PostRequestBlockerInterceptorService } from './shared/services/post-request-blocker-interceptor.service';
import { RouteReuseStrategy } from '@angular/router';
import { CompositeReuseStrategy } from './shared/services/composite-reuse-strategy';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function localStorageSyncReducer(reducer: any): any {
  return localStorageSync({ keys: ['notifications'], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
const dbConfig: DBConfig = {
  name: 'PermissionsDB',
  version: 1,
  objectStoresMeta: [{
    store: 'resource_categories', // Name of store (table)
    storeConfig: { keyPath: 'Name', autoIncrement: false }, // Unique identifier (id)
    storeSchema: []
  }]
}

const combinedDBConfig: DBConfig = {
  name: "CombinedDB", // New unified database name
  version: 3,
  objectStoresMeta: [
    {
      store: "resource_categories",
      storeConfig: { keyPath: "Name", autoIncrement: false },
      storeSchema: [],
    },
    {
      store: "unity_searches", // Store for Unity searches
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        {
          name: "searchTerm",
          keypath: "searchTerm",
          options: { unique: false },
        },
        { name: "activeTab", keypath: "activeTab", options: { unique: false } },
        { name: "items", keypath: "items", options: { unique: false } },
        { name: "timestamp", keypath: "timestamp", options: { unique: false } },
      ],
    },
    {
      store: "mingle_searches", // Store for Mingle searches
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        {
          name: "searchTerm",
          keypath: "searchTerm",
          options: { unique: false },
        },
        { name: "activeTab", keypath: "activeTab", options: { unique: false } },
        { name: "items", keypath: "items", options: { unique: false } },
        { name: "timestamp", keypath: "timestamp", options: { unique: false } },
      ],
    },
  ],
};

@NgModule({
  declarations: [
    AppComponent,
    // OrderDashboardComponent,
    // StatusCardComponent,
    //TabsCardComponent,
    // FiltersComponent,
    // OrderTablesComponent,
    ManageUserComponent,
    ManageAgentComponent,
    ManagePermissionComponent,
    ManageGroupPermissionComponent,
    GroupUserComponent,
    UserSettingsDashboard,
    LoginComponent,
    ProfileComponent,
    AddMemberGroupComponent,
    SearchResultPageComponent,
    ManageCombosComponent,
    ManageNoteTypesComponent,
    ManageComboItemsComponent,
    ManageMakeModelsComponent,
    ImageModalComponent,
    ManageResourcesComponent,
    ResourceTypeModalComponent,
    ManageCategoryComponent,
    NewManageUserComponent,
    EditManageUsersComponent,
    NewProfileComponent,
    NewManageAngentComponent,
    EditAddAgentComponent,
    NewDashboardComponent,
    TagTypeComponent,
    TicketTagsComponent,
  ],
  imports: [
    AgGridModule,
    CommonModule,
    BrowserModule,
    CdkStepperModule,
    FormsModule,
    NgxWebstorageModule.forRoot({
      prefix: "tibss",
      separator: ".",
      caseSensitive: true,
    }),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    RecaptchaModule,
    NgbPaginationModule,
    RecaptchaFormsModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    NgxIndexedDBModule.forRoot(combinedDBConfig),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([NotificationEffects]),
    CustomerPagesModule
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: CompositeReuseStrategy },
    ManageCategoryComponent,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
