import { Component } from '@angular/core';

@Component({
  selector: 'app-transsection-detail-skeleton',
  templateUrl: './transsection-detail-skeleton.component.html',
  styleUrls: ['./transsection-detail-skeleton.component.scss']
})
export class TranssectionDetailSkeletonComponent {

}
