import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TransactionComponent } from "./transaction.component";
import { TransactionDetailComponent } from "./transaction-detail/transaction-detail.component";


const routes: Routes = [
    {
        path: "",
        component: TransactionComponent,
    },
    {
        path: "detail",
        component: TransactionDetailComponent,
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TransactionRoutingModule { }
