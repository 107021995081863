import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service'; // Import LoaderService

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService, private localStorage: LocalStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/login')) {
      return next.handle(req); // Bypass for login URL
    }

    const token = this.getTokenBasedOnUrl(req.url);
    if (token) {
      req = this.addToken(req, token); // Add token to the request
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next); // Handle token refresh on 401
        }
        return throwError(error); // Forward other errors
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.generateTokenByRefereshToken().pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;

          const tokenKey = this.getCompanySpecificKey('Token');
          this.localStorage.store(tokenKey, res.data.Token); // Store new token
          const RefreshtokenKey = this.getCompanySpecificKey('Refreshtoken');
          this.localStorage.store(RefreshtokenKey, res.data.data[0].refreshToken); // Store new token

          this.refreshTokenSubject.next(res.data.Token);
          return next.handle(this.addToken(req, res.data.Token)); // Retry the failed request with new token
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logout()
          return throwError(error); // Propagate error if token refresh fails
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null), // Wait for token to be set
        take(1), // Take only the first emission
        switchMap(token => next.handle(this.addToken(req, token!))) // Retry with new token
      );
    }
  }

  private getTokenBasedOnUrl(url: string): string | null {
    const currentCompany = localStorage.getItem("currentCompany");
    if (currentCompany) {
      return this.localStorage.retrieve(`${currentCompany}-Token`); // Safely retrieve token based on company
    }
    return null;
  }

  getCompanySpecificKey(key: string): string {
    const currentCompany = localStorage.getItem('currentCompany') || 'unity';
    return `${currentCompany}-${key}`;
  }
}
