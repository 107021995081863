import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  constructor(
    private http: HttpService
  ) { }

  getNetworkInfo() {
    try {
      return this.http.get('api/Customer/GetNetworkInfo', null, false, true).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
  
  getThrottleSpeed() {
    try {
      return this.http.get('api/Customer/GetThrottleSpeed', null, false, true).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
  
  networkReset() {
    try {
      return this.http.post('api/Customer/NetworkReset').toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  updateVoiceMail(data) {
    try {
      return this.http.post('api/Customer/VoiceMail', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  updateDataThrottle(data) {
    try {
      return this.http.post('api/Customer/DataThrottle', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  updateWifiCalling(data) {
    try {
      return this.http.post('api/Customer/WifiCalling', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  updateRoaming(data) {
    try {
      return this.http.post('api/Customer/UpdateRoam', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  updateDataSaver(data) {
    try {
      return this.http.post('api/Customer/DataSaver', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  deleteVoiceMail() {
    try {
      return this.http.delete('api/Customer/DeleteVoiceMail').toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addWfc(data) {
    try {
      return this.http.post('api/Customer/WifiCalling', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
