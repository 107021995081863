// breadcrumb.service.ts
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

export interface Breadcrumb {
  label: string;
  url: string;
  isCustomerProfilePage?: boolean; // Optional flag
}


@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$ = this.breadcrumbs.asObservable();

  constructor(private router: Router, private route: ActivatedRoute) {
    this.createInitialBreadcrumbs();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        const root: ActivatedRoute = this.route.root;
        this.breadcrumbs.next(this.createBreadcrumbs(root));
      });
  }

  private createInitialBreadcrumbs() {
    const root: ActivatedRoute = this.route.root;
    this.breadcrumbs.next(this.createBreadcrumbs(root));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;
  
    if (children.length === 0) {
      return breadcrumbs;
    }
  
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
  
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
  
      const breadcrumbLabel = child.snapshot.data['breadcrumb'];
      const isCustomerProfilePage = child.snapshot.data['isCustomerProfilePage']; // Capture the flag
  
      // Check if the breadcrumb is already added to avoid duplicates
      if (breadcrumbLabel && !breadcrumbs.some(breadcrumb => breadcrumb.label === breadcrumbLabel)) {
        breadcrumbs.push({ 
          label: breadcrumbLabel, 
          url, 
          isCustomerProfilePage  // Include the flag in the breadcrumb
        });
      }
  
      // Recurse through children to get nested breadcrumbs
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  
    return breadcrumbs;
  }
  
}
