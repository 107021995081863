import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeAddressAccountComponent } from 'src/app/customer-dashboard/customer-footer/customer-account/change-address-account/change-address-account.component';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { InactivityService } from 'src/app/auth/services/inactivity.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  animations:[fadeInOut]
})
export class PersonalInformationComponent implements OnInit,OnDestroy {
  private addressUpdateSubscription!: Subscription
  modalRef: any; 
  @ViewChild('content', { static: true }) content: TemplateRef<any>;
  @ViewChild(ChangeAddressAccountComponent) changeAddressAccountComponent: ChangeAddressAccountComponent;
isSkeletonLoader: boolean=true;
openChangeAddressModal() {
  this.modalRef=this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', centered: true });
  this.inactivityService.setModalRef(this.modalRef);  
}

  isMailingAddressUpdatable:boolean;
  personalInfoFormData: FormGroup;
  moreInfoData: any = [];
  editFieldName: string = '';
  formFields: any[] = [];
  customerData: any;
isLoading: any;

  constructor(
    public customerProfileService: CustomerProfileService,
    private formBuilder: FormBuilder,
    private httpService: HttpService, private commonService: CommonService,private customerDetailedInformationService:CustomerDetailedInformationService,
    private modalService: NgbModal,
    private inactivityService: InactivityService
  ) { 
    this.initializePersonalInfoForm()
  }
  ngOnDestroy(): void {
    if (this.addressUpdateSubscription) {
      this.addressUpdateSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.isLoading=true
    await this.customerProfileService.getMinimalProfileData(this.httpService.getCustomerId());
    await this.getCardsData();
    this.listenUpdateMailingAddress()
  }

  initializePersonalInfoForm() {
    this.personalInfoFormData = this.formBuilder.group({
      firstName: [{ value: '', disabled: false }, Validators.required],
      lastName: [{ value: '', disabled: false }, Validators.required],
      phone: [{ value: '', disabled: false }, Validators.required],
      email: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      dateOfBirth: [{ value: '', disabled: false }, Validators.required],
      ssn: [{ value: '', disabled: false }, Validators.required],
      serviceAddress: [{ value: '', disabled: false }, Validators.required],
      mailingAddress: [{ value: '', disabled: false }, Validators.required],
      accountStatus: [{ value: '', disabled: false }],
      accountPassword: [{ value: '', disabled: false }]
    });
  }
  patchFormData() {
    if (this.customerData && this.customerData.personalInfo) {
      const serviceAddress = this.customerData?.personalInfo?.serviceAddress?.value;
      const mailingAddress = this.customerData?.personalInfo?.mailingAddress?.value;

      const combinedServiceAddress = [
        serviceAddress?.address1, serviceAddress?.address2, serviceAddress?.city, serviceAddress?.zipCode, serviceAddress?.state
      ].filter(part => part).join(', ');

      const combinedMailingAddress = [
        mailingAddress?.address1, mailingAddress?.address2, mailingAddress?.city, mailingAddress?.zipCode, mailingAddress?.state
      ].filter(part => part).join(', ');

      this.personalInfoFormData.patchValue({
        firstName: this.customerData.personalInfo.firstName.value,
        lastName: this.customerData.personalInfo.lastName.value,
        phone: this.customerData.personalInfo.phone.value,
        email: this.customerData.personalInfo.email.value,
        dateOfBirth: this.customerData.personalInfo.dateOfBirth.value,
        ssn: this.customerData.personalInfo.ssn.value,
        serviceAddress: combinedServiceAddress,
        mailingAddress: combinedMailingAddress,
        accountStatus: this.customerData.personalInfo.accountStatus.value,
        accountPassword: this.customerData.personalInfo.accountPassword.value
      });
    } else {
      console.error('Customer data or personalInfo is undefined');
    }
  }

  buildFormFields() {
    this.formFields = [
      { controlName: 'firstName', label: 'First Name', editable: this.customerData?.personalInfo?.firstName?.editable, visible: this.customerData?.personalInfo?.firstName?.visible },
      { controlName: 'lastName', label: 'Last Name', editable: this.customerData?.personalInfo?.lastName?.editable, visible: this.customerData?.personalInfo?.lastName?.visible },
      { controlName: 'phone', label: 'Alternate Contact', editable: this.customerData?.personalInfo?.phone?.editable, visible: this.customerData?.personalInfo?.phone?.visible },
      { controlName: 'email', label: 'Email', editable: this.customerData?.personalInfo?.email?.editable, visible: this.customerData?.personalInfo?.email?.visible },
      { controlName: 'dateOfBirth', label: 'Date of Birth', editable: this.customerData?.personalInfo?.dateOfBirth?.editable, visible: this.customerData?.personalInfo?.dateOfBirth?.visible },
      { controlName: 'ssn', label: 'SSN', editable: this.customerData?.personalInfo?.ssn?.editable, visible: this.customerData?.personalInfo?.ssn?.visible },
      { controlName: 'serviceAddress', label: 'Service Address ', editable: 'N' , visible: this.customerData?.personalInfo?.serviceAddress?.visible},
      { controlName: 'mailingAddress', label: 'Billing Address ', editable: this.customerData?.personalInfo?.mailingAddress?.editable, visible: this.customerData?.personalInfo?.mailingAddress?.visible },
      { controlName: 'accountStatus', label: 'Account Status ', editable: this.customerData?.personalInfo?.accountStatus?.editable , visible: this.customerData?.personalInfo?.accountStatus?.visible},
      { controlName: 'accountPassword', label: 'Account Password ', editable: this.customerData?.personalInfo?.accountPassword?.editable, visible: this.customerData?.personalInfo?.accountPassword?.visible },
     
    ];
  }

  enableEdit(controlName: string) {
    const control = this.personalInfoFormData.get(controlName);
    if (control) {
      control.enable(); // Enable the form field to be editable
    }
  }


  async getCardsData() {
    try {
      this.isLoading=true
      const res1: any = await this.customerProfileService.getCustomerPersonalCardData();
    this.moreInfoData = res1?.data;
    this.customerData = res1?.data;
    if (this.customerData) {
      this.patchFormData(); 
      this.buildFormFields();
      this.isSkeletonLoader=false

    }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
    } finally{
      this.isLoading=false
    }

  }

  updateCustomerProfile(controlName: string) {
    let isSuccess = false;  
    let errorMessage = '';  
    this.isLoading=true;
    const data: any = {};
    data[controlName] = this.personalInfoFormData.get(controlName)?.value;
    this.customerProfileService.updateCustomerProfile(data)
      .then((res: any) => {
        if (res?.Status) {
          isSuccess = true;
          this.editFieldName = '';
          this.customerProfileService.getMinimalProfileData();
          this.getCardsData();
          this.removeInputField(controlName)
        } else {
          errorMessage = res?.message || 'Failed to update profile';
        }
      })
      .finally(() => {
        if (isSuccess) {
          this.commonService.showToast('success', 'Success', `${this.customerDetailedInformationService.formatKeyLabel(controlName)} updated successfully`);
        } else {
          this.commonService.showToast('error', 'Error', errorMessage || `An error occurred while updating the ${this.customerDetailedInformationService.formatKeyLabel(controlName)}`);
        }
        this.isLoading=false;
      });
  }
  

  removeInputField(controlName: string) {
    const field = this.formFields.find(f => f.controlName === controlName);
    if (field) {
    this.editingFields.delete(controlName);
      this.personalInfoFormData.get(controlName)?.setValue(this.customerData.personalInfo?.[controlName]?.value);
      const originalValue = this.personalInfoFormData.get(controlName)?.value;
      this.personalInfoFormData.get(controlName)?.setValue(originalValue);  
    }
  }

  onEditIconClick(editFieldName: string) {
    this.editFieldName = editFieldName;
  }
  editingFields: Set<string> = new Set<string>();


  toggleEdit(controlName: string) {
    const field = this.formFields.find(f => f.controlName === controlName);
    if (field && field.editable=='Y') {
      if (this.editingFields.has(controlName)) {
        this.editingFields.delete(controlName);
      } else {
        this.editingFields.add(controlName);
      }
    } else {
      console.warn(`${controlName} is not editable.`);
    }
  }
  saveEdit(controlName: string) {
    this.editingFields.delete(controlName);
  }

  isEditing(controlName: string): boolean {
    return this.editingFields.has(controlName);
  }
  listenUpdateMailingAddress(){
    this.addressUpdateSubscription=this.customerProfileService.submitSuccess$.subscribe((isUpdated) => {
      if(isUpdated){
        this.modalService.dismissAll();
        this.getCardsData();
        this.updateAddressTracker(isUpdated);
      }else{
        this.modalService.dismissAll();
        this.updateAddressTracker(isUpdated);
      }
    });
  }
  
  updateAddressTracker(status: any) {
    if (status) {
     
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Address Updated Successfully.",
        html: 'Address Updated Successfully.<br><strong id="swal-timer">10</strong> seconds remaining.',
        timer: 10000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";
  
          closeButton.onclick = () => {
            Swal.close();
          };
  
          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      });
    } else {
    
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error.",
        html: 'Address Updation failed.<br><strong id="swal-timer">10</strong> seconds remaining.',
        timer: 5000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";
  
          closeButton.onclick = () => {
            Swal.close();
   
          };
  
          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      })
    }
  }
}
