import { Component } from '@angular/core';
import { SearchService } from '../shared/services/search.service';
import { CustomerProfileService } from '../shared/services/customer-profile.service';
import { CommonService } from '../shared/services/common.service';
import { Router } from '@angular/router';
import { HttpService } from '../shared/services/httpService.service';
import { LocalStorage } from 'ngx-webstorage';
import { FormBuilder} from '@angular/forms';
import { OrderReviewService } from '../review/services/order-review.service';
import { SearchHistoryService } from '../shared/services/search-history.service';


@Component({
  selector: 'app-search-result-page',
  templateUrl: './search-result-page.component.html',
  styleUrls: ['./search-result-page.component.scss']
})
export class SearchResultPageComponent {

  data: any = [];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public selectedSource: any = "";
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public prev_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];
  loader = false;
  searchCount:any
  advanceSearchPayload:any

  isAdvanceSearched: boolean = false;
  column = [];

  @LocalStorage()
  private userDetails;

  constructor(
    public searchService: SearchService,
    public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    public router: Router,
    public http: HttpService,
    public fb: FormBuilder,
    public orderReviewService: OrderReviewService,
    private searchHistoryService:SearchHistoryService
  ) {
    this.column = [
      {
        displayName: 'Serial Number',
        value: 'serialNumber',
      },
      {
        displayName: 'Order Date',
        value: 'orderDate',
      },
      {
        displayName: 'Order Items',
        value: 'orderItems',
      },
      {
        displayName: 'nvNlad Date',
        value: 'nvNladDate'
      },
      {
        displayName: 'Enroll Number',
        value: 'EnrollNumber'
      },
      {
        displayName: 'Enroll Id',
        value: 'enrollId'
      },
      {
        displayName: 'Subscriber Id',
        value: 'subscriberId'
      },
      {
        displayName: 'Customer Id',
        value: 'customerId'
      },
      {
        displayName: 'First Name',
        value: 'FirstName'
      },
      {
        displayName: 'Last Name',
        value: 'LastName'
      },
      {
        displayName: 'Full Name',
        value: 'FullName'
      },
      {
        displayName: 'Email',
        value: 'Email'
      },
      {
        displayName: 'State Name',
        value: 'StateName'
      },
      {
        displayName: 'Zip Code',
        value: 'ZipCode'
      },
      {
        displayName: 'Agent Id',
        value: 'agentId'
      },
      {
        displayName: 'Status',
        value: 'status'
      },
      {
        displayName: 'Amount Paid',
        value: 'amountPaid'
      },
      {
        displayName: 'Address',
        value: 'address'
      },
      {
        displayName: 'Device Info',
        value: 'deviceInfo'
      },
      {
        displayName: 'Tribal',
        value: 'Tribal'
      },
      {
        displayName: 'Carrier',
        value: 'carrier'
      },
      {
        displayName: 'Application Id',
        value: 'applicationId'
      },
      {
        displayName: 'Enrollment Type',
        value: 'EnrollmentType'
      },
      {
        displayName: 'Last Comment',
        value: 'LastComment'
      },
      {
        displayName: 'Source',
        value: 'source'
      }
    ]
    this.searchService.advanceSearchedData.subscribe(res => {
      if (res) {
        this.isAdvanceSearched = true;
        this.data = res.data.data
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.prev_page_url = res.data.prev_page_url
        //here we can access the data
      } else {
        this.isAdvanceSearched = false;
      }
    })

    this.searchService.advanceSearchedPayloadData.subscribe(res=>{
      if(res){
        this.advanceSearchPayload =res;
      }
    })
  }

  ngOnInit() {
    this.searchService.searchData.subscribe(res => {
      this.isAdvanceSearched = false;
      this.data = res.data
      this.totalItems = res.total
      this.currentPage = res.current_page
      this.first_page_url = res.first_page_url
      this.last_page_url = res.last_page_url
      this.next_page_url = res.next_page_url
      this.prev_page_url = res.prev_page_url
    })
    this.searchService.searchCount.subscribe(res => {
      this.searchCount= res?.totalCount;
    });
    this.setBreadCrumb()
  }

  async setUser(data) {
    try {
      const customerId = this.isAdvanceSearched ? data.customerId : data.CustomerID
      this.customerProfileService.setCustomerId(customerId);
      this.searchService.addToCustomerSearchedData(data);

      this.router.navigateByUrl("/customer-dashboard/customer-profile");
    this.searchHistoryService.saveSearchRecord(customerId, 'customers', data);

    }
    catch (err) {
      console.log(err)
      this.commonService.showToast('error', "Error", err?.error?.message ?? "Some Error Occurred")
    }

  }

  setBreadCrumb() {
    this.commonService.setbreadCrumb({
      "title": "Search Result Page",
      "active_item": "Search Result Page"
    })
  }

  pageChange(event) {
    this.currentPage = event

  }

  onChangeNumberOfPage() {
    
  }



  getUser() {
    return this.userDetails;
  }

  async onNext() {
    this.loader = true;
    try {
      const searchParams = new URLSearchParams();
      if (this.advanceSearchPayload) {
        Object.keys(this.advanceSearchPayload).forEach((key) => {
          searchParams.append(key, this.advanceSearchPayload[key]);
        });
      }
      const fullUrl = `${this.next_page_url}&companyId=UNITYCRM0021&userID=${this.getUser()?.userID}&${searchParams.toString()}`;
      const res: any = await this.http.get(fullUrl, false, true, false, true, true).toPromise();
      if (res?.Status) {
        this.data = res.data.data;
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
        this.prev_page_url = res.data.prev_page_url;
      } else {
        this.data = [];
        this.commonService.showToast('error', 'Error', res?.message);
      }
    } catch (err) {
      this.commonService.showToast('error', 'Error', err?.error?.message ?? 'Some Error Occurred');
    }

    this.loader = false;
  }

  async onPrevious() {
    this.loader = true
    try {
      const res: any = await this.http.get(this.prev_page_url + '&companyId=UNITYCRM0021&userID=' + this.getUser()?.userID, false, true, false, true, true).toPromise();
      if (res?.Status) {
        this.data = res.data.data
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.prev_page_url = res.data.prev_page_url
      }
      else {
        this.data = []
        this.commonService.showToast('error', "Error", res?.message)
      }

    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))

    }
    this.loader = false
  }

}
