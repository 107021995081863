import { Component } from '@angular/core';

@Component({
  selector: 'app-new-topup-plan',
  templateUrl: './new-topup-plan.component.html',
  styleUrls: ['./new-topup-plan.component.scss']
})
export class NewTopupPlanComponent {

}
