import { Component, Input,OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { PortRequestsService } from '../services/porting-requests.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-view-schedule-downgrades',
  templateUrl: './view-schedule-downgrades.component.html',
  styleUrls: ['./view-schedule-downgrades.component.scss'],
  animations:[fadeInOut]
})
export class ViewScheduleDowngradesComponent implements OnInit  {
  // @Input() portOutInformation: boolean=false;
  // portOutDisplay:boolean=false
  @Input() item: any;
  portOutData: any;
  @Input() portIn:any;
  showLoader: boolean = false;
isSkeletonLoader: boolean=true;

  ngOnInit(){
    console.log("value of items ",this.portIn)
      this.GetPortOutDetail() 
  }
  constructor(
    public activeModal: NgbActiveModal,
    private portRequestsService: PortRequestsService,
    private commonService: CommonService){
  }

   // Load States
   async GetPortOutDetail() {
    console.log("item id value ",this.item?.CustomerID)
    
    try {
      await this.portRequestsService.getPortOutDetail(this.portIn?.CustomerID).subscribe((response: any) => {
       if(response && response.Status){
        this.portOutData = response?.data;
        this.isSkeletonLoader=false
        // this.portOutDisplay=true;
        console.log("portOutDatails:-", this.portOutData)
        
       }else{ 
        // this.portOutDisplay=false;
        this.isSkeletonLoader=false
      this.commonService.showToast('error', "Error", (response?.error.CustomerId));
       
       }
      });
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.error?.message ?? "Invalied Form Fields"));
      this.isSkeletonLoader=false
    }
  }
}
