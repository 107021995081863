import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingCommonServices } from '../../services/setting-common.services';
import { ManageComboService } from '../../services/manage-combo.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ManageComboItemsService } from '../../services/manage-combo-items.service';
import { ImageModalComponent } from '../manage-combo-items/image-modal/image-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { fadeInOut } from 'src/app/shared/services/app.animation';


@Component({
  selector: 'app-manage-combos',
  templateUrl: './manage-combos.component.html',
  styleUrls: ['./manage-combos.component.scss'],
  animations: [fadeInOut] 
})

export class ManageCombosComponent implements OnInit {
  @LocalStorage()
  private userDetails;
  @Input() manageComboDetails:any
  moreInfoTabLevel1: string = '';
  moreInfoTabLevel2: string = '';
  comboTableData: any[] = [];
  public addComboForm: FormGroup;
  public addComboItemForm: FormGroup;
  applicationAreasList: any = []
  deviceMakeModaddComboFormelList: any = [];
  uploadedFiles: any;
  product_combo_Id: any = null
  showErrorMsg: boolean = false;
  showErrorMsgItem: boolean = false;
  uploadedComboItemFiles: any;
  currentStep = 0
  imagePath: any;
  comboItemList: any = [];
  product_combo_item_id: any = null;
  showAdd: boolean = false;
  imageItemPath: any
  deviceMakeModelList: any = []
  filterList: any = []
  public pageDropdownMenu: any = [10, 20, 30];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
isSkeletonLoader: boolean=true;
isCreateComboSkeletonLoader: boolean=true;


  constructor(
    public commonSettingService: SettingCommonServices,
    private fb: FormBuilder,
    public modalService: NgbModal,
    private router: Router,
    private manageComboService: ManageComboService,
    public commonService: CommonService,
    public manageComboItemService: ManageComboItemsService
  ) {

    this.addComboForm = this.fb.group({
      ComboType: [null, Validators.required],
      Name: [null, Validators.required],
      Title: [null, Validators.required],
      Description: [null, Validators.required],
      Price: [null, Validators.required],
      DisplayPrice: [null, Validators.required],
      isDefault: [0],
      isBase: [0],
      ProductOffer: [null],
      IsOfferOn: [0],
      SortOrder: [1],
      NavIconClass: ['slack'],
      IsActive: [false],
      ApplicationAreaID: [null],
      type: [null]
    })
    this.addComboItemForm = this.fb.group({
      ProductComboID: [null],
      ProductItemID: [null, Validators.required],
      Quantity: [1, Validators.required],
      Price: [null],
      DisplayPrice: [null, Validators.required],
      SortOrder: [1],
      NavIconClass: ['arrow'],
      IsActive: [false],
    })
  }


  moveToStep(index) {
    if (index == 0) {
      this.currentStep = index
    }
    else {
      if (this.product_combo_Id) {
        this.currentStep = index
      }
      else {
        return
      }
    }

  }
  onChangeDeviceMakeModel(event: any) {
    let result = this.deviceMakeModelList.find(ele => ele.id == event)
    if (result) {
      this.addComboItemForm.get('DisplayPrice').patchValue(result.PriceAfterDiscount)
      this.addComboItemForm.get('Price').patchValue(result.Price)
      if(result.IsActive==1 ) {
        this.addComboItemForm.get('IsActive').patchValue(true)
      }
      else {
        this.addComboItemForm.get('IsActive').patchValue(false)
      }
    }
  }
  ngOnInit() {
    this.getCombosList('')
    this.commonSettingService.moreInfoTabLevel1.subscribe(
      data => {
        if (data == 'MANAGE_COMBOS') {
          this.product_combo_Id = null
          this.moreInfoTabLevel1 = data;
   
          this.manageComboService.getApplicationAreas().subscribe((res: any) => {
            this.applicationAreasList = res.data
          })
          this.manageComboService.getDeviceMakeModelList().subscribe((res: any) => {
            this.deviceMakeModelList = res.data.data;
          })
        }

      }
    )
    this.commonSettingService.moreInfoTabLevel1.subscribe(
      data => {
        this.moreInfoTabLevel2 = data;
      }
    )
  }


  filterdData(event:any){
    let data=event.target.value
    this.getCombosList(data);
  }
  getCombosList(search) {
    
    let data = {
      "perPage": this.itemsPerPage,
      "page": this.currentPage,
      'Query':search
    }
    this.manageComboService.getComboList(data).subscribe(
      (res: any) => {
        this.comboTableData = res.data.data;
        this.filterList = res.data.data
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.isSkeletonLoader=false
        
      }, err => {
        this.isSkeletonLoader=false
      }
    )
  }


  submit() {

    let data = this.addComboForm.value
    this.addComboForm.get('Description').patchValue(data.Title)

    this.setBasenDefaultValue()
    if (this.addComboForm.valid) {
      
      if (data.IsActive == true) {
        this.addComboForm.get('IsActive').patchValue('Y')
        data.IsActive ='Y'
      }
      else {
        this.addComboForm.get('IsActive').patchValue('N')
        data.IsActive ='N'
      }
      delete data.type
      this.manageComboService.getCreateProductCombo(data).subscribe((res: any) => {
        this.product_combo_Id = res.data.id;
        this.showErrorMsg = false;
        
        this.commonService.showToast("success", "Success", "Added successfully!")
        this.closeModal()
        this.currentStep = 1
      }, err => {
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        this.showErrorMsg = false;
        
      })
    }
    else {
      this.commonService.showToast('error', "Error", ("Form is invalid"))
      this.showErrorMsg = true;
      
    }
  }
  update() {

    const data = this.addComboForm.value
    this.addComboForm.get('Description').patchValue(data.Title)
    this.setBasenDefaultValue()
    if (this.addComboForm.valid) {
      
      if (data.IsActive == true) {
        this.addComboForm.get('IsActive').patchValue('Y')
        data.IsActive ='Y'
      }
      else {
        this.addComboForm.get('IsActive').patchValue('N')
        data.IsActive ='N'
      }
      delete data.type
      this.manageComboService.updateProductCombo(this.product_combo_Id, data).subscribe((res: any) => {
        this.product_combo_Id = res.data.id;
        this.showErrorMsg = false;
        
        this.commonService.showToast("success", "Success", "Updated successfully!")
        this.closeModal()
      }, err => {
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        this.showErrorMsg = false;
        
      })
    }
    else {
      this.commonService.showToast('error', "Error", ("Form is invalid"))
      this.showErrorMsg = true;
      
    }

  }

  addProductComboItems() {
    if (this.addComboItemForm.valid) {
      const data = this.addComboItemForm.value
      
      const form = new FormData()
      form.append('companyId', "UNITYCRM0021")
      form.append('userID', this.getUser().userID)
      form.append('ProductComboID', this.product_combo_Id)
      form.append('ProductItemID', data.ProductItemID)
      form.append('Quantity', data.Quantity)
      form.append('Price', data.DisplayPrice)
      form.append('DisplayPrice', data.DisplayPrice)
      form.append('SortOrder', data.SortOrder ?? 1)
      form.append('NavIconClass', data.NavIconClass)
      if (data.IsActive == true) {
        form.append('IsActive', 'Y')
      }
      else {
        form.append('IsActive', 'N')
      }
      if (this.uploadedComboItemFiles) {
        form.append('File', this.uploadedComboItemFiles)
      }

      this.manageComboItemService.addProductComboItems(form).subscribe(res => {
        
        this.getComboItemList()
        this.showErrorMsgItem = false;
        this.resetAddComboItemForm()
        this.uploadedComboItemFiles = null
        this.imageItemPath = null
      }, err => {
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        
        this.showErrorMsgItem = false;
      })
    }
    else {
      this.commonService.showToast('error', "Error", ("Form is invalid"))
      this.showErrorMsgItem = true;
    }
  }
  submitUploadImage() {
    if (this.uploadedFiles) {
      
      const form = new FormData()
      form.append('companyId', "UNITYCRM0021")
      form.append('userID', this.getUser().userID)
      form.append('ProductID', this.product_combo_Id)
      form.append('File', this.uploadedFiles)
      this.manageComboService.updateProductComboImage(form, this.product_combo_Id).subscribe(res => {
        this.resetAddComboForm()
        this.resetAddComboItemForm()
        
        this.commonService.showToast("success", "Success", "Updated successfully!")
      }, err => {

        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        
      })
    }
  }
  removeFile(file: File) {

    this.uploadedFiles = null;
  }


  removeComboItemFile(file: File) {
    console.log('removeFile', file)
    this.uploadedComboItemFiles = null;
  }


  onFileChange(event: any) {
    this.uploadedFiles = event.addedFiles[0];
  }

  onFileItemChange(event: any) {
    this.uploadedComboItemFiles = event.addedFiles[0];
  }
  addCombo() {
    this.resetAddComboItemForm()
    this.showAdd = true;
    this.product_combo_item_id = null;
    this.imageItemPath = null
  }
  editOpen(item: any) {
    this.commonSettingService.moreInfoSelectedTab1('add-combo');
    this.commonSettingService.editGroup(item.id);
    this.manageComboService.getProductComboById(item.id).subscribe((res: any) => {
      if (res.data.ApplicationAreaIDs) {
        const arr = res.data.ApplicationAreaIDs.split(',')
        const newArr = []
        arr.forEach(element => {
          newArr.push(Number(element))
        });
        res.data.ApplicationAreaID = newArr
      }
      this.addComboForm.patchValue(res.data)
      if (res.data.IsActive == 'Y') {
        this.addComboForm.get('IsActive').patchValue(true)
      }
      else {
        this.addComboForm.get('IsActive').patchValue(false)
      }
      this.setTypeValue()
      this.product_combo_Id = res?.data.id;
      this.imagePath = res?.data.ImagePath;
      this.getComboItemList()
    })
  }
  getComboItemList() {
    let data = {
      "ProductComboID": this.product_combo_Id
    }
    this.manageComboService.getProductComboItems(data).subscribe((res: any) => {
      this.comboItemList = res.data.data
      this.isCreateComboSkeletonLoader=false
      
    }, err => {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      // this.showErrorMsg = false;
      this.isCreateComboSkeletonLoader=false
      
    })
  }

  closeModal() {
    this.commonSettingService.moreInfoLevel1 = false
    this.commonSettingService.popup2Width = 0
    this.resetAddComboForm()
    this.resetAddComboItemForm()
    this.product_combo_Id = null
    this.product_combo_item_id = null;
    this.uploadedComboItemFiles = null;
    this.uploadedFiles = null
    this.showAdd = false;
    this.currentStep = 0
    // this.commonSettingService.toggleMoreInfoLevel1()
    this.commonSettingService.editId=null

  }
  closeModal1() {
    this.showAdd = false;
    this.product_combo_item_id = null;
    this.uploadedComboItemFiles = null;
  }

  openImage(path: any) {
    let modalref = this.modalService.open(ImageModalComponent,
      {
        ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true,
      }
    )
    modalref.componentInstance.path = path;
  }
  editItemOpen(item: any) {
    let data = {
      "ComboItemID": item.id
    }
    this.product_combo_item_id = item.id
    this.manageComboItemService.getProductComboItem(data).subscribe((res: any) => {
      this.addComboItemForm.patchValue(res.data.data[0])
      if (res.data.data[0].IsActive == 'N') {
        this.addComboItemForm.get('IsActive').patchValue(false)
      }
      else {
        this.addComboItemForm.get('IsActive').patchValue(true)
      }
      this.imageItemPath = res.data.data[0]?.ImagePath
    })
  }
  updateComboItem() {
    if (this.addComboItemForm.valid) {
      
      let data = {
        ...this.addComboItemForm.value
      }

      const form = new FormData()

      form.append('companyId', "UNITYCRM0021")
      form.append('userID', this.getUser().userID)
      form.append('ProductComboID', this.product_combo_Id)
      form.append('ProductItemID', data.ProductItemID)
      form.append('Quantity', data.Quantity)
      form.append('Price', data.DisplayPrice)
      form.append('DisplayPrice', data.DisplayPrice)
      form.append('SortOrder', data.SortOrder ?? 1)
      form.append('NavIconClass', data.NavIconClass)
      if (data.IsActive == true) {
        form.append('IsActive', 'Y')
      }
      else {
        form.append('IsActive', 'N')
      }
      if (this.uploadedComboItemFiles) {
        form.append('File', this.uploadedComboItemFiles)
      }
      this.manageComboItemService.updateProductComboItem(this.product_combo_item_id, form).subscribe(res => {

        this.resetAddComboItemForm()
        this.product_combo_item_id = null
        this.uploadedComboItemFiles = null
        this.imageItemPath = null
        this.showAdd = false;
        this.getComboItemList()
        this.commonService.showToast("success", "Success", "Updated successfully!")
        
      }, err => {
        
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))

      })
    }
    else {
      this.commonService.showToast('error', "Error", ("Form is invalid"))
    }
  }

  getUser() {
    return this.userDetails;
  }
  onChangeNumberOfPage() {
    this.getCombosList('')
  }
  pageChange(event) {
    this.currentPage = event
    this.getCombosList('')
  }

  resetAddComboForm() {
    const data: any = {
      isDefault: 0,
      isBase: 0,
      IsOfferOn: 0,
      SortOrder: 1,
      NavIconClass: 'slack',
      IsActive: false
    }
    this.addComboForm.reset()
    this.addComboForm.patchValue(data)
  }

  resetAddComboItemForm() {
    const data: any = {
      SortOrder: 1,
      NavIconClass: 'arrow',
      IsActive: false
    }
    this.addComboItemForm.reset()
    this.addComboItemForm.patchValue(data)
  }


  setBasenDefaultValue() {
    if (this.addComboForm.get('type').value == '10') {
      this.addComboForm.get('isDefault').patchValue(1)
      this.addComboForm.get('isBase').patchValue(0)
    }
    if (this.addComboForm.get('type').value == '00') {
      this.addComboForm.get('isDefault').patchValue(0)
      this.addComboForm.get('isBase').patchValue(0)
    }
    if (this.addComboForm.get('type').value == '01') {
      this.addComboForm.get('isDefault').patchValue(0)
      this.addComboForm.get('isBase').patchValue(1)
    }
    if (this.addComboForm.get('type').value == '11') {
      this.addComboForm.get('isDefault').patchValue(1)
      this.addComboForm.get('isBase').patchValue(1)
    }

  }
  setTypeValue() {
    if (this.addComboForm.get('isDefault').value == 1 && this.addComboForm.get('isBase').value == 0) {
      this.addComboForm.get('type').patchValue('10')
    }
    if (this.addComboForm.get('isDefault').value == 0 && this.addComboForm.get('isBase').value == 0) {
      this.addComboForm.get('type').patchValue('00')
    }
    if (this.addComboForm.get('isDefault').value == 0 && this.addComboForm.get('isBase').value == 1) {
      this.addComboForm.get('type').patchValue('01')
    }
    if (this.addComboForm.get('isDefault').value == 1 && this.addComboForm.get('isBase').value == 1) {
      this.addComboForm.get('type').patchValue('11')
    }

  }
  back(){
    this.router.navigateByUrl(`/user-settings`)
  }
}