import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '../../services/user-agent.service';
import { ManageAgentService } from '../../services/manage-agent.service';
import { MyProfileService } from 'src/app/profile/services/my-profile.service';
import { ManagePermissionService } from '../../services/manage-permission.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupUserService } from '../../services/group-user.service';
import { ManageGroupPermissionService } from '../../services/manage-group-permission.service';
import { LocalStorage } from 'ngx-webstorage';

 declare var $: any; 

@Component({
  selector: 'app-manage-group-permissions',
  templateUrl: './manage-group-permissions.component.html',
  styleUrls: ['./manage-group-permissions.component.scss']
})

export class ManageGroupPermissionComponent implements OnInit {
  profileData: any;
  permissions: any;
  permissionsMain: any;
  resource_types: any;
  resources: any=[];
  groupId: any;
  public seletedAgent:any
  public seletedGroup:any;
  
public selectedCar1:any;
public selectedCar2:any;
public selectedCar3:any;
public showFirstDropdown:any=[];
  agentList: any;
  groupList: any;

  @LocalStorage()
  private userDetails;


  constructor(private myprofileService:MyProfileService,
    private managePermissionService:ManagePermissionService,
    private manageGroupPermissionService:ManageGroupPermissionService,
    private commonService:CommonService,private route:ActivatedRoute,private _snackBar: MatSnackBar,
    private agentService:UserAgentService,private groupUserService:GroupUserService)
  {
    this.groupId=this.route.snapshot.queryParamMap.get('id')
  }

ngOnInit(){
  this.getUser()
    // this.getProfileData();
    this.getAllPermissions();
    this.getGroupList();

}


//////////////////////// set the breadcrumb here //////////////////////////

setBreadCrumb() {
  this.commonService.setbreadCrumb({
    "title": "Manage Permissions",
    "active_item": "Manage Permissions"
  })
}
  



openSnackBar(message: string, duration: number = 2000) {
  this._snackBar.open(message, undefined, {
    duration: duration, verticalPosition: 'top'

  });
}

///////////////////////// get data from local storage //////////////////////////////////

getUser() {
  
  return this.userDetails;

  // return JSON.parse(localStorage.getItem('userDetails'))
}

////////////////////////////////////////////////////////////////////////////

getAllPermissions(id?){
  id?this.groupId=id:this.groupId
  this.manageGroupPermissionService.getAllPermissions(this.groupId).subscribe((res:any)=>{
    this.profileData=res.data;
  })
     this.selectedCar2 = ''
      this.selectedCar1 = ''
      this.resources = []
}

updatePermissions(item: any, permissionType: string, value: boolean) {
  const permissionUpdate = {
    [permissionType]: value ? 1 : 0,
  };


  const obj = {
    GroupID: this.groupId, // user for which permission is being updated
    ResourceID: item?.id,
    ...permissionUpdate ,
    UpdatedBy: this.getUser().userID,
  };

  this.managePermissionService.updatePermission(item?.id, obj).subscribe((res: any) => {
    if (res.Status == true) {
      this.openSnackBar('Permission Updated Successfully.', 3000);
    }
  });
}

 
onSelectCar1(){
  this.selectedCar2=''
  this.profileData.filter((item)=>{
    if(this.selectedCar1==item.id){
      this.resource_types=item?.resource_types
    }
  })
}


onSelectCar2(){
  this.resource_types.filter((item)=>{
    if(this.selectedCar2==item.id){
      this.resources=item?.resources
      console.log("resources",this.resources)
    }
  })
}

onClearSelection1(){
  this.selectedCar2=''
}

getGroupList(){
  this.groupUserService.getGroupList().subscribe((res:any)=>{
    this.groupList=res.data
    let newArray=[]
    this.groupList.filter((item)=>{
      if(item.id==this.groupId){
        newArray.push(item);
    
        this.seletedGroup=newArray[0].Name
     
      }
    })
 
  },(err) =>{
    this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
  })
}
onCheckboxChange(item: any, permissionType: string, event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const value = inputElement.checked;

  // Ensure resource_permission is initialized as an empty object if not already
  if (!item.resource_permission) {
    item.resource_permission = {};
  }
  item.resource_permission[permissionType] = value;
  if (item.flag === 0) {
    this.createPermission(item);
  } else {
    this.updatePermissions(item, permissionType, value);
  }
}
createPermission(item: any) {
  const permissions = {
    CanView: item.resource_permission?.CanView === true ? 1 : 0,
    CanEdit: item.resource_permission?.CanEdit === true ? 1 : 0,
    CanDelete: item.resource_permission?.CanDelete === true ? 1 : 0,
  };

  const obj = {
    UserID: this.getUser().userID,
    GroupID: this.groupId, // user for which permission is being updated
    ResourceID: [item?.id],
    CanView: permissions.CanView,
    CanEdit: permissions.CanEdit,
    CanDelete: permissions.CanDelete,
    CreatedBy: this.getUser().userID,
  };

  this.managePermissionService.createPermission(obj).subscribe(
    (res: any) => {
      if (res.Status == true) {
        this.openSnackBar('Permission Created Successfully.', 3000);
      } else {
        this.openSnackBar('Failed to create permission.', 3000);
      }
    },
    (error) => {
      this.openSnackBar('Error creating permission.', 3000);
    }
  );
}

}
