import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-system-skeleton',
  templateUrl: './ticket-system-skeleton.component.html',
  styleUrls: ['./ticket-system-skeleton.component.scss']
})
export class TicketSystemSkeletonComponent implements OnInit{

  cardArray: any[] = []; // Array to hold the skeleton cards

  constructor() { }

  ngOnInit(): void {
    // Populate cardArray with 10 items (for example, you can change the number)
    this.cardArray = Array(10).fill(0);
  }
}
