import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-form-skeleton',
  templateUrl: './payment-form-skeleton.component.html',
  styleUrls: ['./payment-form-skeleton.component.scss']
})
export class PaymentFormSkeletonComponent {

}
