<!-- app.component.html -->
<!-- 

<div class="container">
  <div class="row">

    <div class="col-md-12" style="margin-top: 50px;">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let item of profileData; let i = index">
          <h2 class="accordion-header" id="heading{{i}}">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
              {{ item?.DisplayName }}
            </button>
          </h2>
          <div id="collapse{{i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
            <div class="accordion-body">
             <p *ngFor="let item of item?.resource_types">{{item?.DisplayName}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</div> -->

<!-- <div class="p-3" style="margin-top: -10px;">
  <div class="row">
      <div class="col-12">
          <app-breadcrumb></app-breadcrumb>
      </div>
  </div>
</div> -->

<div class="container">
  <div class="row custom-card py-3">
    <div class="col card-header">
      <div class="d-grid ">
        <h4 routerLink="/group-user" style="cursor: pointer;"><strong> <i
              class="fa-solid fa-arrow-left mt-1 mx-2"></i>Group Permission</strong></h4>
      </div>
    </div>

    <div class="col-md-12 mt-3">
      <label>Groups</label>
      <ng-select [(ngModel)]="seletedGroup" (ngModelChange)="getAllPermissions($event)">
        <ng-option *ngFor="let item of groupList" [value]="item.id">{{item.Name}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-6 mt-3">
      <label>Categeory</label>
      <ng-select [(ngModel)]="selectedCar1" (change)="onSelectCar1()" (clear)="onClearSelection1()">
        <ng-option *ngFor="let item of profileData" [value]="item.id">{{item.DisplayName}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-6 mt-3" *ngIf="selectedCar1">
      <label>Type</label>
      <ng-select [(ngModel)]="selectedCar2" (change)="onSelectCar2()" (clear)="onClearSelection1()">
        <ng-option *ngFor="let item of resource_types" [value]="item.id">{{item.DisplayName}}</ng-option>
      </ng-select>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 px-0">
      <div class="custom-card mt-5">
        <h4 class="mt-4 mx-4">Permissions</h4>
        <div class="card-body" *ngIf="resources?.length > 0">
          <div class="table-responsive tableFixHead">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Permissions</th>
                  <th scope="col">View</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                  <!-- <th scope="col">Action</th> -->
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of resources;let i = index">
                  <ng-container *ngIf="item.resource_permission; else noResourceFound">
                    <td class="cursor-pointer" data-toggle="tooltip" data-placement="top" title="{{item?.Description}}">
                      {{item?.DisplayName}}
                    </td>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          [checked]="item.resource_permission[0]?.CanView || false"
                          (change)="onCheckboxChange(item, 'CanView', $event)" id="readCheckbox">
                      </div>
                    </td>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          [checked]="item.resource_permission[0]?.CanEdit || false"
                          (change)="onCheckboxChange(item, 'CanEdit', $event)" id="editCheckbox">
                      </div>
                    </td>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          [checked]="item.resource_permission[0]?.CanDelete || false"
                          (change)="onCheckboxChange(item, 'CanDelete', $event)" id="deleteCheckbox">
                      </div>
                    </td>
                    <!-- <td *ngIf="item.flag == 1">
                      <button class="btn btn-primary" (click)="updatePermissions(item)">Save</button>
                    </td>
                    <td *ngIf="item.flag == 0">
                      <button class="btn btn-primary" (click)="createPermission(item)">Save</button>
                    </td> -->
                  </ng-container>
                  <ng-template #noResourceFound>
                    <td colspan="5">
                      <p>No resource found</p>
                    </td>
                  </ng-template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-danger text-center" *ngIf="resources?.length == 0" >
          <img src="https://cdn.pixabay.com/photo/2022/08/30/09/15/permission-7420699_1280.png" alt="" width="180px">
        </div>
      </div>
    </div>
  </div>

</div>