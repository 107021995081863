import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileGuardService implements CanActivate {
  @LocalStorage()
  private CustomerId

  constructor(
    private router: Router,
  ) { }

  canActivate(): boolean {
    if (!this.CustomerId) {
      this.router.navigate(['/dashboard']); // go to login if not authenticated
      return false;
    }
    return true;
  }
}
