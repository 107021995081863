import { Component, HostListener } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { PortRequestsService } from 'src/app/scheduled-downgrades/services/porting-requests.service';
import { ViewScheduleDowngradesComponent } from 'src/app/scheduled-downgrades/view-schedule-downgrades/view-schedule-downgrades.component';
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-plan-change',
  templateUrl: './plan-change.component.html',
  styleUrls: ['./plan-change.component.scss'],
  animations: [fadeInOut]
})
export class PlanChangeComponent {
  portingRequestList: any = [];
  filterportingRequestList: any = [];
  moreInfo: boolean = true;
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  currentPage: number = 1;
  showLoader: boolean = false;
  isSkeletonLoader: boolean = true;

  @LocalStorage()
  private userDetails: any;
  optionsDatalist: any;


  columnDefs = [
    { headerName: 'Customer ID', field: 'CustomerID' },
    { headerName: 'Current Plan', field: 'CurrentPlan' },
    { headerName: 'Scheduled Plan', field: 'ScheduledPlan' },
    { headerName: 'Status', field: 'Status' },
    { headerName: 'Schedule Date', field: 'ScheduleDate' },
    { headerName: 'Created By', field: 'CreatedBy' },
    { headerName: 'Created Date', field: 'CreatedDate' },
    { headerName: 'Cancelled By', field: 'CancelledBy' },
    { headerName: 'Cancel Date', field: 'CancelDate' },
    { headerName: 'Is Immediate', field: 'IsImmediate' },
    { headerName: 'Is Scheduled Email', field: 'IsScheduledEmail' },
    { headerName: 'Is Complete Email', field: 'IsCompleteEmail' },
    { headerName: 'Is Processed', field: 'IsProcessed' },
    { headerName: 'Is Subscribed', field: 'IsSubscribed' },
    { headerName: 'Is Subscription Synced', field: 'IsSubscriptionSynced' },
    { headerName: 'Is Complete', field: 'IsComplete' },
    { headerName: 'Is Failed', field: 'IsFailed' },
    { headerName: 'Is Retry Mark', field: 'IsRetryMark' },
    { headerName: 'Is Retried', field: 'IsRetried' },
    { headerName: 'Is Refunded', field: 'IsRefunded' },

    { headerName: 'Retry Counter', field: 'RetryCounter' },

    { headerName: 'Action', field: 'Action' }
  ];
searchPortIn: any;
selectedTypeFilter: any = 'All';
subscriptionTypeFilter = [
  { DisplayName: 'Complete', value: 'enterprise' },
  { DisplayName: 'Failed', value: 'basic' },
  { DisplayName: 'Cancelled', value: 'premium' },
  { DisplayName: 'Scheduled', value: 'enterprise' },
  { DisplayName: 'All', value: 'All' }
];
  constructor(
    private portingRequestsService: PortRequestsService,
    private modalService: NgbModal,
    public commonService: CommonService
  ) {}


  dropdownVisible: boolean = false;
  dropdownRow: any = null;

  onClickAction(row: any): void {
    this.dropdownRow = row;
    this.dropdownVisible = true;
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: Event): void {
    const target = event.target as HTMLElement;
    
    // Ensure the dropdown content exists
    const isClickInsideDropdown = target.closest('.dropdown-content-2');
    const isClickInsideActionIcon = target.closest('.action-icon-container');
  
    // If the click is outside both the dropdown and the action icon, close the dropdown
    if (!isClickInsideDropdown && !isClickInsideActionIcon) {
      this.dropdownVisible = false;
      this.dropdownRow = null;
      console.log('onOutsideClick: Dropdown closed');
    }
  }
   
  @HostListener('click', ['$event'])
  stopPropagation(event: Event): void {
    event.stopPropagation(); // Prevent the click from bubbling to document
  }
  
  toggleDropdown(row: any): void {
    // if (this.dropdownRow === row) {
    //   this.dropdownVisible = !this.dropdownVisible;
    // } else {
      this.dropdownVisible = true;
      this.dropdownRow = row;
    // }
  }

  reprocessData(data: any): void {
    this.portingRequestsService?.getPortingRequestStatusById(data).subscribe((res: any) => {
      if (res && res.Status) {
        const response = {
          resolutionCode: res.data?.resolutionCode,
          status: res.data?.status,
          statusDescription: res.data?.statusDescription
        };
        Swal.fire({
          title: `Status : ${res.data?.status}`,
          text: (res.data?.status !== 'Complete' && res.data?.status !== 'Cancelled') ? `Resolution Code : ${response.resolutionCode}  Status Description : ${res.data?.statusDescription}` : '',
        });
        
      } else if (res && !res.Status) {
        this.commonService.showToast('error', "Error", res?.error?.error);
        
      }
    }, err => {
      this.commonService.showToast('error', "Error", err?.error?.error);
      
    });
    this.dropdownVisible = false;
  }



  ngOnInit(): void {
    this.getScheduleDownGradeList();
    this.portingRequestsService.getClosePortInModal.subscribe((res) => {
      if (!!res) this.getScheduleDownGradeList();
    });
    setTimeout(() => {
      this.isSkeletonLoader = false;
    }, 1500);
  }

  getScheduleDownGradeList() {
    const obj = {
      perPage: this.itemsPerPage,
      page: this.currentPage
    };

    this.portingRequestsService.getPortingInRequests(obj).subscribe(
      (res: any) => {
        this.portingRequestList = res.data.data;
        this.showLoader = false;
        this.totalItems = res.data.total;
        this.filterportingRequestList = res.data.data;
      },
      (error: any) => {
        this.commonService.showToast('error', 'Error', error);
      }
    );
  }

  

  selectFilter(selectedValue: any) {
    console.log('Selected filter:', selectedValue);
  }

  viewDowngradeStatus(item: any) {
    const modalRef = this.modalService.open(ViewScheduleDowngradesComponent, {
      windowClass: 'left-aligned-modal',
      backdrop: 'static'
    });
    modalRef.componentInstance.portIn = item;
  }

  withConfirmation(item: any, id: number, csId: number) {
    const selectOptions = this.optionsDatalist
      .map(
        (option: any) => `<option value="${option.id}">${option.Description}</option>`
      )
      .join('');

    Swal.fire({
      title: 'Are you sure?',
      html: `
        <p>You won't be able to revert this!</p>
        <label class="col-12 text-start" for="selectOption">Select a Reason:</label>
        <select id="selectOption" class="swal2-input form-select">
          <option value="option">Select Reason</option>
          ${selectOptions}
        </select>
      `,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel it!'
    }).then((result) => {
      if (result.value) {
        const selectedOption = (document.getElementById('selectOption') as HTMLSelectElement).value;
        this.cancelDowngradeRequest(item, id, csId, selectedOption);
      }
    });
  }

  cancelDowngradeRequest(data: any, id: number, csId: number, selectedOption: any) {
    const obj = { companyId: 'UNITYCRM0021' };
    this.portingRequestsService.cancelDownGrade(id, csId, obj, selectedOption).subscribe(
      (res: any) => {
        if (res && res.Status) {
          this.commonService.showToast('success', 'Success', 'Cancelled successfully!');
          this.getScheduleDownGradeList();
        } else {
          this.commonService.showToast('error', 'Error', res?.error?.error || 'Cancellation failed.');
        }
      },
      (err: any) => {
        this.commonService.showToast('error', 'Error', err?.error?.error || 'An error occurred during deletion.');
      }
    );
  }

  pageChange(event: any) {
    this.currentPage = event;
    this.getScheduleDownGradeList();
  }

  onChangeNumberOfPage() {
    this.getScheduleDownGradeList();
  }

  reprocess() {
    this.getScheduleDownGradeList();

  }
  filtePortInRequests(event: any) {
    let searchValue = event.target.value;
    searchValue = searchValue.toLowerCase().trim();
    if (searchValue === '') {
      this.portingRequestsService.setPortingInRequestStatus(searchValue);
    }
  }
  portingInRequestStatus(status: any) {
    if (status) {

      status = status.toLowerCase().trim();
      this.portingRequestsService.setPortingInRequestStatus(status);
    } else {
      
      this.commonService.showToast('error', "Error", 'Invalid status value: The status is null or undefined.');
      

    }
  }

    //Search down grades page changes
    searchPortingInRequestList(res) {
    
      let obj = {
        "perPage": this.itemsPerPage,
        "page": this.currentPage,
      }
      this.portingRequestsService?.getPortingInRequestsBySearch(obj, res).subscribe((res: any) => {
        this.portingRequestList = res.data.data;
        this.showLoader = false
        this.totalItems = res?.data?.total;
        this.filterportingRequestList = res.data.data;
        
      });
    }
  getPortingInRequestStatus() {
    this.portingRequestsService.getPortingInRequestStatus().subscribe((res: any) => {
      if (res?.length) {
        this.searchPortingInRequestList(res);
      } else {
        this.getScheduleDownGradeList();
      }
    })
  }
}
