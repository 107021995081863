export interface RouteConfig {
    path: string;
    detach: boolean;
  }
//   awaiting-shipment
  export const ROUTE_CONFIG: RouteConfig[] = [

    { path: 'renewal-reports', detach: true },
    { path: 'customer-email', detach: true }, // Detachable routes
    // Add other routes here
  ];
  