import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpService) { }

  getTransactionList(data) {
    try {
      let url = 'api/transactions/list'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",

      }
      if (data?.searchTerm) {
        body.searchTerm = data?.searchTerm
      }
      if (data?.per_page) {
        body.per_page = data?.per_page
      }
      if (data?.page) {
        body.page = data?.page
      }
      if (data?.statusId) {
        body['statusId[]'] = data?.statusId
      }
      if (data?.amount) {
        body['amount'] = data?.amount
      }
      if (data?.range) {
        body['range'] = data?.range
      }
      if (data?.customStartDate) {
        body['customStartDate'] = data?.customStartDate
      }
      if (data?.customEndDate) {
        body['customEndDate'] = data?.customEndDate
      }
      if (data?.IsExport) {
        body['IsExport'] = data?.IsExport
      }

      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }
  getTransactionStat() {
    try {
      let url = 'api/transactions/getInvoicePaymentsStatusCount'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021"
      }

      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getTransactionDetails(data) {
    try {
      let url = `api/subscription/getTransactionDetails/${data.TracID}`
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      if (data?.PaymentId) {
        body.PaymentId = data?.PaymentId
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getDetails(data) {
    try {
      let url = 'api/transactions/getDetail'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      if (data?.PaymentId) {
        body.paymentId = data?.PaymentId
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getTableHeader() {
    try {
      let url = 'api/orders/showTableHeader'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "type": "transaction"
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  getRefundReason() {
    try {
      let url = 'api/subscription/getReasonsList'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body)
    } catch (err) {
      throw Error(err)
    }
  }

  refundTransaction(data) {
    try {
      let url = 'api/transactions/refundTransaction'
      let body: any = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "paymentId": data.paymentId,
        "CustomerId": data.CustomerId,
        "refundReasonId": data.refundReasonId,
        "transactionId": data.transactionId,
        "refundAmount": data.refundAmount,
        "lastFourDigits": data.lastFourDigits,
      }
      return this.http.post(url, body)
    } catch (err) {
      throw Error(err)
    }
  }
}
