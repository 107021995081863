<div class="card modern-form ">
  <form [formGroup]="advanceSearchForm" class="p-4">
    <div class="row g-3">
      <!-- Full Name & Contact Information -->
      <div class="col-md-6">
        <div class="form-floating">
          <input type="text" formControlName="firstName" class="form-control" placeholder="First Name" />
          <label>First Name</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input type="text" formControlName="lastName" class="form-control" placeholder="Last Name" />
          <label>Last Name</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input type="text" formControlName="fullName" class="form-control" placeholder="Full Name" />
          <label>Full Name</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input type="text" formControlName="alternateContactNumber" class="form-control" placeholder="Alternate Contact Number" />
          <label>Alternate Contact Number</label>
        </div>
      </div>

      <!-- Address Information -->
      <div class="col-md-6">
        <div class="form-floating">
          <input type="text" formControlName="address" class="form-control" placeholder="Address" />
          <label>Address</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-floating">
          <input type="text" maxlength="5" formControlName="zipCode" class="form-control" placeholder="Zip Code" />
          <label>Zip Code</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-floating">
          <!-- <ng-select [items]="stateOptions" formControlName="states" bindValue="StateCode" bindLabel="StateName" [multiple]="true" placeholder="Select" class="form-control">
          </ng-select> -->
          <select class="form-control" formControlName="states">
            <option value="">Select Status</option>
            <option *ngFor="let state of stateOptions" [value]="state.StateCode">{{state.StateName}}</option>
          </select>
          <label>State</label>
        </div>
      </div>

      <!-- Account & Order Information -->
      <div class="col-md-4">
        <div class="form-floating">
          <select class="form-control" formControlName="CustomerStatusID">
            <option value="">Select Status</option>
            <option *ngFor="let status of customerStatus" [value]="status.id">{{status.Name}}</option>
          </select>
          <label>Account Status</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <select class="form-control" formControlName="OrderTypeID">
            <option value="">Select Order Type</option>
            <option *ngFor="let order of orderType" [value]="order.id">{{order.Description}}</option>
          </select>
          <label>Order Type</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <select class="form-control" formControlName="PlanTypeID">
            <option value="">Select Account Type</option>
            <option *ngFor="let account of accountType" [value]="account.id">{{account.Description}}</option>
          </select>
          <label>Account Type</label>
        </div>
      </div>

      <!-- Application & Subscriber IDs -->
      <div class="col-md-6">
        <div class="form-floating">
          <input formControlName="nladApplicationId" class="form-control" placeholder="Nlad Application ID" />
          <label>Nlad Application ID</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input formControlName="nvSubscriberId" class="form-control" placeholder="NV Subscriber ID" />
          <label>NV Subscriber ID</label>
        </div>
      </div>

      <!-- Date Range -->
      <div class="col-md-6">
        <div class="form-floating">
          <input type="date" formControlName="fromDate" class="form-control" placeholder="From" />
          <label>From Date</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input type="date" formControlName="toDate" class="form-control" placeholder="To" />
          <label>To Date</label>
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="row mt-4">
      <div class="col-12 text-end">
        <button type="button" (click)="resetAdvanceSearch()" class="btn btn-outline-secondary me-2 modern-btn">Reset</button>
        <button type="button" (click)="onSubmitAdvanceSearch()" class="btn btn-primary modern-btn">Search</button>
      </div>
    </div>
  </form>
</div>
