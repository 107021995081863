<div class="position-relative d-flex justify-content-end">
  <div role="button" aria-pressed="false" aria-expanded="false"
    class="d-flex bg-transparent border-transparent px-2 align-items-center gap-2" (click)="toggleFilterDropdown()">
    <i class="fa fa-filter"></i>
    <span>Filter</span>
  </div>

  <!-- Filter Container -->
  <div class="filter-container card p-3" *ngIf="showFilterSection">
    <div class="filter-header d-flex justify-content-between align-items-center mb-2">
      <span class="fs-5 fw-semibold">Filters</span>
      <button class="btn btn-link text-muted p-0" (click)="clearFilters()">Clear</button>
    </div>

    <div class="filter-content">
      <!-- Loop through dynamic filters -->
      <div *ngFor="let filter of filters" class="filter-item mb-3">
        <label class="form-label"
          *ngIf="filter.type != 'select' || filter.type != 'buttonGroup' && filter.options.length >0">{{ filter.label
          }}</label>

        <ng-container [ngSwitch]="filter.type">
          <!-- Text Input Filter -->
          <ng-container *ngSwitchCase="'text'">
            <input type="text" class="form-control" (input)="applyFilter(filter.key, $event)"
              placeholder="{{ filter.placeholder }}">
          </ng-container>

          <!-- Date Range Filter -->
          <ng-container *ngSwitchCase="'dateRange'">
            <input [(ngModel)]="bsRangeValue" (ngModelChange)="onDateRangeChange($event)"
              [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
              bsDaterangepicker type="text" readonly placeholder="Date Range" class="form-control" />
          </ng-container>

          <!-- Select Dropdown Filter -->
          <ng-container *ngSwitchCase="'select'">
            <select class="form-select" *ngIf="filter.options.length >0" (change)="applyFilter(filter.key, $event)">
              <option *ngFor="let option of filter.options" [value]="option[filter.bindValue]">{{
                option[filter.bindLabel] }}</option>
            </select>
          </ng-container>



          <ng-container *ngSwitchCase="'buttonGroup'">
            <div class="btn-group" role="group" *ngIf="filter.options.length >0">
              <button type="button" *ngFor="let option of filter.options" class="btn btn-outline-primary"
                [ngClass]="{'active': isActive(filter.key, option[filter.bindValue])}"
                (click)="applyFilter(filter.key, option[filter.bindValue])">
                {{ option[filter.bindLabel] }}
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>