import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { ProfileService } from 'src/app/add-member-group/services/profile.service';
import { fadeInOut, fadeInOutSlow } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.scss'],
  animations:[fadeInOut]
})
export class NewProfileComponent {
  public id:any
  public showContact=false;
  public showContactCard=true;
  public changePass=true;

  public addChangeForm:FormGroup

  public showEmployee=false;
  public showEmployeeCard=true;

  public showProfile=false;
  public showProfileCard=true
  profileData: any;
  public loadUser:boolean=false
  @Input() loadAgent:boolean;

  addProMainCard:FormGroup
  @Output() loaderCHanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSkeletonLoader: boolean=true;
  constructor(private fb: UntypedFormBuilder,private router:Router,private profileService:ProfileService,private route:ActivatedRoute,private commonService:CommonService) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.params['id'];

    this.getProfileData()
  }
  back(){
    this.router.navigateByUrl(`/manage-user`)
  }
  getProfileData() {
    // this.loadUser=true
    this.profileService.getProfileData(this.id).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('An error occurred:', error); // Log the error to the console or a logging service
        // Handle the error appropriately
        // this.loadUser = false;
        // this.loadAgent = false;
        // this.errorMessage = 'Failed to load profile data. Please try again later.';
        // this.commonService.showToast('error', "Error", (error?.error?.message ?? "Some Error Occurred"))
        return of(null); // Return an observable with a null value or any default value
      })
    ).subscribe((res: any) => {
      if (res) {
        this.profileData = res.data;
        this.isSkeletonLoader=false;
        // this.addProMainCard.patchValue({
        //   name: this.profileData?.name,
        //   email: this.profileData?.email,
        //   status: this.profileData?.status?.Status
        // });
        // this.toggleActivation();
      }
 
    });
    this.addChangeForm = this.fb.group({
      email: [this.profileData?.email, [Validators.required, Validators.email]], // Prefill email and add validation
      newPassword: ['', [Validators.required, Validators.minLength(6)]],          // New password field
      confirmPassword: ['', [Validators.required, this.matchPassword('newPassword')]], // Confirm password field
      website: [this.profileData?.status?.Status || ''],  // Status field with a fallback default
    });
  }
  matchPassword(newPassword: string) {
    return (formControl: any) => {
      if (!this.addChangeForm) {
        return null;
      }
      const password = this.addChangeForm.get(newPassword)?.value;
      const confirmPassword = formControl.value;

      return password === confirmPassword ? null : { passwordMismatch: true };
    };
  }
  toggleActivation() {
    // this.batchReportLoader=!this.batchReportLoader;
    // this.loadUser=true
    this.loaderCHanged.emit(this.loadAgent)

  }


  editContact(){
    let temp=this.showContact;
    this.showContact=this.showContactCard
    this.showContactCard=temp;
  }

  submitContact(){
    this.showContact=false;
    this.showContactCard=true;
  }


  editEmployee(){
    this.showEmployee=true;
    this.showEmployeeCard=false;
  }


  submitEmployee(){
    this.showEmployee=false;
    this.showEmployeeCard=true;
  }


  editProfile(){
    this.showProfile=true;
    this.showProfileCard=false;
  }

  changePassword(){
    this.changePass=false;
  }


  submitProfile(){
    this.showProfile=false;
    this.showProfileCard=true;
  }

  updatePassword(){
    let obj={
       password:this.addChangeForm.value.newPassword,
       password_confirmation:this.addChangeForm.value.confirmPassword,
    }
    this.profileService.changePassword(obj,this.id).subscribe((res:any)=>{
      if(res.Status){
        this.changePass=true;
        this.commonService.showToast("success", "Success", 'Password Updated  Successfully');
      }else{
       
      }

    }, (err) => {
      console.log("errpr ",err.error?.error?.password?.[1])
      this.commonService.showToast('error', "Error", (err?.error?.error?.password?.[0] ?? "Some Error Occurred"))
      this.commonService.showToast('error', "Error", (err?.error?.error?.password?.[1] ?? "Some Error Occurred"))
    })
  }

  cancel(){
  this.showContact=false;
  this.showContactCard=true;
  this.showEmployee=false;
  this.showEmployeeCard=true;
  this.showProfile=false;
  this.showProfileCard=true;
  this.changePass=true;

  }
}
