import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(
    private http: HttpService
  ) { }

  getTickets(data) {
    try {
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get('api/tickets/get-tickets', body, true,true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
