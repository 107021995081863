


  
  <div class="">
    <ul ngbNav #CustomHover="ngbNav" [activeId]="1" class="nav  nav-pills  animation-nav bg-light  d-flex gap-3 mb-2">
      <li [ngbNavItem]="1">
          <a ngbNavLink>
            <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position text-nowrap">Change Plan</h5>
          </a>
          <ng-template ngbNavContent>
            <!-- <h6>Customer Details</h6> -->
            <app-new-plan-change [currentPlanId]="planId"></app-new-plan-change>
          </ng-template>
      </li>
      <li [ngbNavItem]="2">
          <a ngbNavLink>
           
            <!-- <i class="fa-solid fa-credit-card nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position text-nowrap">Free Change</h5>
          </a>
          <ng-template ngbNavContent>
            <!-- <h6>Description</h6> -->
            <app-credit-card-plan-change></app-credit-card-plan-change>
          </ng-template>
      </li>
      <li [ngbNavItem]="3">
          <a ngbNavLink>
           
            <!-- <i class="fa-solid fa-retweet nav-icon nav-tab-position"></i> -->
            <h5 class="nav-titl nav-tab-position text-nowrap">Convert To Prepaid</h5>
          </a>
          <ng-template ngbNavContent>
            <!-- <h6>Customer Reviews</h6> -->
            <app-credit-card-add-data [toupTypeData]="topupType" [label]="label" [planChange]="planChange" [planId]="planId"
        [prepaidPlans]="prepaidPlans" [planDetails]="planDetails"
        [customerAddress]="customerAddress"></app-credit-card-add-data>
          </ng-template>
      </li>
    </ul>
    <div class="card-body">
      <div class="tab-content text-muted">
        <div [ngbNavOutlet]="CustomHover"></div>
      </div>
  </div>
  </div>

<!-- <div class="d-lg-flex d-block align-items-start flex-column">
  <div class="nav nav-tabs mb-0 w-100" id="v-pills-tab" role="tablist"> -->
    <!-- <button class="nav-link active" id="v-pills-change-plan-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-change-plan" type="button" role="tab" aria-controls="v-pills-change-plan"
      aria-selected="true">
      <div class="d-flex">
        <div>
    
        </div>
        <div class="d-flex align-items-center">
          <h5>Change Plan</h5>
        </div>
      </div>
    </button>
    <button class="nav-link " id="v-pills-credit-card2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-credit-card2"
      type="button" role="tab" aria-controls="v-pills-credit-card2" aria-selected="true">
      <div class="d-flex">

        <div class="d-flex align-items-center">
          <h5>Free Change</h5>
        </div>
      </div>
    </button>
    <button class="nav-link " id="v-pills-credit-card2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-credit-card"
      type="button" role="tab" aria-controls="v-pills-credit-card" aria-selected="true">
      <div class="d-flex">
        <div>
     
          <div class="d-flex align-items-center">
            <h5>Convert To Prepaid</h5>
          </div>
        </div>
      </div>
    </button> -->
    <!-- <button class="nav-link" id="v-pills-money-order2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-money-order2"
      type="button" role="tab" aria-controls="v-pills-money-order2" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Money Order</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-money-gram2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-money-gram2"
      type="button" role="tab" aria-controls="v-pills-money-gram2" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Money Grams</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-wallet2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-wallet2"
      type="button" role="tab" aria-controls="v-pills-wallet2" aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Wallet</h5>
        </div>
      </div>
    </button>

    <button class="nav-link" id="v-pills-dealer-wallet2-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-dealer-wallet2" type="button" role="tab" aria-controls="v-pills-dealer-wallet2"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Dealer Wallet</h5>
        </div>
      </div>
    </button>
    <button class="nav-link" id="v-pills-ivr-credit-card-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-ivr-credit-card" type="button" role="tab" aria-controls="v-pills-ivr-credit-card"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>IVR Credit Card</h5>
        </div>
      </div>
    </button> -->

<!-- 
  </div> -->
  <!-- <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade" id="v-pills-credit-card2" role="tabpanel" aria-labelledby="v-pills-credit-card2-tab">
      <app-credit-card-plan-change></app-credit-card-plan-change>
    </div>
    <div class="tab-pane fade" id="v-pills-money-gram2" role="tabpanel" aria-labelledby="v-pills-money-gram2-tab">
      <app-money-gram-plan-change></app-money-gram-plan-change>
    </div>
    <div class="tab-pane fade" id="v-pills-money-order2" role="tabpanel" aria-labelledby="v-pills-money-order2-tab">
      <app-money-order-plan-change></app-money-order-plan-change>
    </div>
    <div class="tab-pane fade" id="v-pills-wallet2" role="tabpanel" aria-labelledby="v-pills-wallet2-tab">
      <app-wallet-plan-change></app-wallet-plan-change>
    </div>
    <div class="tab-pane fade" id="v-pills-dealer-wallet2" role="tabpanel" aria-labelledby="v-pills-dealer-wallet2-tab">
      <app-dealer-wallet-plan-change></app-dealer-wallet-plan-change>
    </div>
    <div class="tab-pane fade" id="v-pills-ivr-credit-card" role="tabpanel"
      aria-labelledby="v-pills-ivr-credit-card-tab">
      <app-ivr-credit-card></app-ivr-credit-card>
    </div>
    <div class="tab-pane fade m-0" id="v-pills-credit-card" role="tabpanel" aria-labelledby="v-pills-credit-card-tab">
      <app-credit-card-add-data [toupTypeData]="topupType" [label]="label" [planChange]="planChange" [planId]="planId"
        [prepaidPlans]="prepaidPlans" [planDetails]="planDetails"
        [customerAddress]="customerAddress"></app-credit-card-add-data>
    </div>
    <div class="tab-pane fade  show active" id="v-pills-change-plan" role="tabpanel"
      aria-labelledby="v-pills-change-plan-tab">
      <app-change-plan-to [currentPlanId]="planId"></app-change-plan-to>
      <div>
        <app-new-plan-change [currentPlanId]="planId"></app-new-plan-change>
      </div>
    </div>
  </div> -->