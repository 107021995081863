import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {
  isDropdownOpen: boolean = false;
  @Input() optionList: any[] = [];
  @Input() label: string;
  @Input() value: any;
  @Input() title: string;
  @Input() icon: string;
  selectedItems: any[] = [];
  @Output() selectionChanged = new EventEmitter<any>();

  constructor(private eRef: ElementRef) {}

  toggleDropdown(event: MouseEvent) {
    // Prevent the click from closing the dropdown
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  toggleSelection(item: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedItems.push(item); // Add item if checked
    } else {
      this.selectedItems = this.selectedItems.filter(i => i !== item); // Remove if unchecked
    }

    this.updateTitle();
    this.selectionChanged.emit(this.selectedItems); 
  }

  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }

  // Method to update the title based on selected items
  private updateTitle() {
    if (this.selectedItems.length > 0) {
      this.title = this.selectedItems.map(item => item[this.label]).join(', ');
    } else {
      this.title = 'Select Options';
    }
  }
}
