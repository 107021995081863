import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { ChangeApsService } from './services/change-aps.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-change-aps',
  templateUrl: './change-aps.component.html',
  styleUrls: ['./change-aps.component.scss']
})
export class ChangeApsComponent {

  loader: boolean = false;
  formData: FormGroup;
  apsCodeOptions: any = [];
  isSkeletonLoader: boolean=true;

  constructor(
    private commonService: CommonService,
    private apsService: ChangeApsService,
    public customerProfileService: CustomerProfileService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.phoneNumber.patchValue(this.customerProfileService.minimalProfileData.lineInfo.MDN.value)
    this.getApsCodes();
  }

  initializeForm() {
    this.formData = this.fb.group({
      phoneNumber: [],
      aps: [, Validators.required],
    })
  }

  get aps() {
    return this.formData.get("aps") as FormGroup;
  }
  get phoneNumber() {
    return this.formData.get("phoneNumber") as FormGroup;
  }

  async getApsCodes() {
    this.loader = true;
    try {
      const res: any = await this.apsService.getApsCodes();
      console.log('apsCodeOptions', res)
      if (res.Status) {
        this.apsCodeOptions = res.data;
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast('error', "Error", res.message);
        this.isSkeletonLoader=false
      }
    } catch (err: any) {
      this.commonService.showToast("error", "Error", err.error)
      this.isSkeletonLoader=false

    }
    this.loader = false;
  }

  


  async onSubmit() {
    this.loader = true;
    try {
      console.log(this.formData)
      let data = {
        aps: this.aps.value
      }
      console.log(data)
      const res: any = await this.apsService.updateCustomerAps(data)
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Added')
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.error)
    }
    this.loader = false;
  }

}
