<div class="container-fluid tab-form-wrapper">
  <form [formGroup]="updateBillingInfoForm">
    <div class="row form-group">
      <div class="col-md-3">
        <label>Address Line 1</label>
      </div>
      <div class="col-md-9">
        <input type="text" formControlName="address" class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Address Line 2</label>
      </div>
      <div class="col-md-9">
        <input type="text" formControlName="address2" class="form-control">
      </div>
      <div class="col-md-3"></div>

    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Country</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="countries" formControlName="country"></ng-select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>State</label>
      </div>
      <div class="col-md-9">
        <ng-select id="state" formControlName="state" (change)="onStateChange($event)">
          <ng-option value="">Select State</ng-option>
          <ng-option *ngFor="let state of states" [value]="state.StateCode">{{ state.StateName }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>City</label>
      </div>
      <div class="col-md-9">
        <ng-select id="city" formControlName="city" (change)="onCityChange($event)">
          <ng-option value="">Select City</ng-option>
          <ng-option *ngFor="let city of cities" [value]="city.CityName">{{ city.CityName }}
          </ng-option>
        </ng-select>
      </div>
    </div>
   
    
    <div class="row form-group">
      <div class="col-md-3">
        <label>Postal Code</label>
      </div>
      <div class="col-md-9">
        <ng-select id="postalcode" formControlName="postalcode">
          <ng-option value="">Select Postal Code</ng-option>
          <ng-option *ngFor="let postalCode of postalCodes" [value]="postalCode.ZipCode">{{
            postalCode.ZipCode }}
          </ng-option>
        </ng-select>
      </div>
    </div>
   

    

   

   

    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-danger me-3">Close</button>
        <button type="button" [disabled]="updateBillingInfoForm.invalid" (click)="onSubmit()"
          class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</div>

