<div class="container-fluid  " >

  <div class="custom-card  widget-hover" >
    <div class="card-bdy">
      <div class="row">
        <div class="col-md-4 text-start ">
          <h5 class="card-title text-dark">Usage Details for the Period: </h5>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
          <app-date-picker [placeholder]="datePickerPlaceHolder" (onDateRangeChange)="handleDateRangeChange($event)"></app-date-picker>
        
        </div>
      </div>
      <div class="row" *ngIf="!isSkeletonLoader" @fadeInOut   >
        <div class="row mt-3" *ngIf="list.length > 0">
          <div class="custom-datatable table-responsive">
            <table class="table table-responsive mt-3" style="text-wrap: nowrap;" >
              <thead class="bg-primary">
                <tr>
                  <ng-container *ngFor="let item of column">
                    <th scope="col">
                      {{item}}
                    </th>
                  </ng-container>
                </tr>
              </thead>
      
              <tbody>
                <tr class="shadow-showcase" *ngFor="let item of list;let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item?.MDN || '-'}}</td>
      
                  <td>{{item?.DialedNo || '-'}}</td>
      
                  <td>{{item?.CallDate | date : 'MMM d, y'}} </td>
                  <td>{{item?.RedirectPart || '-'}} </td>
                  <td>{{item?.BillableUnit || '-'}} </td>
                  <td>{{item?.Unit || '-'}} </td>
                  <td>{{item?.CallType || '-'}} </td>
                  <td>{{item?.FreeType || '-'}} </td>
                  <td>{{item?.UsagesType || '-'}} </td>
                  <td>{{item?.DestinationClass || '-'}} </td>
                 
                  <td>{{item?.PlanID || '-'}} </td>
                  <td>{{item?.Roaming || '-'}}</td>
                  <td>{{'-'}}</td>
                  <td>{{item?.BillingCode || '-'}}</td>
      
                </tr>
      
              </tbody>
            </table>
           
          </div>
          <div class="pull-right p-2 m-4 d-flex justify-content-between">
            <div class="summery">
              <button class="btn btn-primary mb-3" (click)="openSummaryModal(summaryModal)">Open Usage Summary</button>
            </div>
            <div class="pagination">
              <ngb-pagination 
            [collectionSize]="totalItems" 
            class="pagination-block" 
            [directionLinks]="true"
            [boundaryLinks]="true"
            [(page)]="currentPage"
            [pageSize]="pageSize"
            [ellipses]="true"
            [maxSize]="5"
            (pageChange)="onPageChange($event)">
            <ng-template ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
            </div>
          
  
                  
        </div>
        </div>
      </div>
      <div  *ngIf="isSkeletonLoader" @fadeInOut   style="max-width: 100%;">
        <app-skeleton-loader [tableColumn]="8" [tableRow]="10"  shape="table"></app-skeleton-loader>
      </div>
   
    </div>
  </div>
  
 
  
</div>

<ng-template #summaryModal let-modal>
  <div class="modal-header bg-primary text-white">
    <h4 class="modal-title font-weight-bold">Usage Summary</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body p-4">
    <div class="container">

     

      <div *ngFor="let date of objectKeys(summary?.byDate)" class="card mb-3 shadow-sm">
        <div class="card-body p-2">
          <div class="d-flex justify-content-between align-items-center mb-2 bg-light">
            <div class="flex-grow-1">
              <span class="text-muted font-weight-bold">Date:</span>
              <span class="text-dark fw-4 p-2">{{ date | date : 'MMM d, y'}}</span>
            </div>
            
          </div>
          
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th scope="col" class="text-center">Call Type</th>
                <th scope="col" class="text-center">Billable Unit </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let callType of objectKeys(summary?.byDate[date]?.callTypes)">
                <td class="text-center font-weight-bold">{{ callType }}</td>
                <td class="text-center">
                  {{ summary?.byDate[date]?.callTypes[callType]  }} 
                  <span *ngIf="callType === 'Data'">MB</span>
                  <span *ngIf="callType !== 'Data'">Unit</span>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>





