import { createAction, props } from '@ngrx/store';
import { Notification } from './notification.model';

export const loadNotifications = createAction(
  '[Notification] Load Notifications',
  props<{ notifications: Notification[] }>()
);

export const addNotification = createAction(
  '[Notification] Add Notification',
  props<{ notification: Notification }>()
);

export const markAsRead = createAction(
  '[Notification] Mark As Read',
  props<{ id: number }>()
);

export const markAllAsRead = createAction(
  '[Notification] Mark All As Read'
);

export const startListeningNotifications = createAction('[Notifications] Start Listening');