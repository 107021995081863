import { Component } from '@angular/core';
import * as slug from 'slug'
import { CustomerHistoryService } from './services/customer-history.service';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-customer-history',
  templateUrl: './customer-history.component.html',
  styleUrls: ['./customer-history.component.scss']
})
export class CustomerHistoryComponent {

  public tableData: any[];

  accordians:any = [
    {
      id: 1,
      label: "A/C Status Histroy",
      value: slug('AC Status Histroy'),
    },
    {
      id: 2,
      label: 'Shipment History',
      value: slug('Shipment History')
    },
    {
      id: 3,
      label: 'SIM/ICCID Histroy',
      value: slug('SIM ICCID Histroy')
    },
    {
      id: 4,
      label: 'MDN History',
      value: slug('MDN History')
    },
    {
      id: 5,
      label: 'Address History',
      value: slug('Address History')
    },
    {
      id: 6,
      label: 'Plan History',
      value: slug('Plan History')
    },
    {
      id: 7,
      label: 'NLAD History',
      value: slug('NLAD History')
    },
    {
      id: 8,
      label: 'Shipment History',
      value: slug('Shipment History')
    },
    {
      id: 9,
      label: 'A/C Search History',
      value: slug('AC Search History')
    },
    {
      id: 10,
      label: 'Resend Phone History',
      value: slug('Resend Phone History')
    },
    {
      id: 11,
      label: 'Name Update History',
      value: slug('Name Update History')
    },
    {
      id: 12,
      label: 'USPS Address History',
      value: slug('USPS Address History')
    },
    {
      id: 13,
      label: 'Customer Life Cycle History',
      value: slug('Customer Life Cycle History')
    },
    {
      id: 14,
      label: 'Port-Out History',
      value: slug('Port Out History')
    }
  ]
isSkeletonLoader: boolean=true;

  constructor(
    private commonService: CommonService,
    private customerHistoryService: CustomerHistoryService
  ) {
  }

  ngOnInit() {
    setTimeout(()=>{
      this.isSkeletonLoader=false
    },1000)
  }

  onShown(data){
    if(data.id === 1){
      this.customerHistoryService.getAcHistory().subscribe(
        (response:any) => {
          if(response.Status){
            this.tableData = response.data;
          }
        },
      (err) => {
          console.log(err);
          this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        }
      )
    }
    if(data.id === 6){
      this.customerHistoryService.getPlanChangeHistory().subscribe(
        (response:any) => {
          if(response.Status){
            this.tableData = response.data;
          }
        },
      (err) => {
          console.log(err);
          this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        }
      )
    }
  }

} 
