import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "ngx-webstorage";
import { AuthService } from "src/app/auth/services/auth.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userData: any;
  public profileImg: "assets/images/dashboard/profile.jpg";
  
  @LocalStorage()
  public userDetails;

  constructor(
    public authService: AuthService,
  ) {
    this.getUser()
  }

  getUser() {
    this.userData = this.userDetails;
  }

  ngOnInit() { }

  logoutFunc() {
    this.authService.logout()
  }
}
