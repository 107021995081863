<div class="container-fluid">
    <div class="row" *ngFor="let card of cardArray">
        <div class="col-md-12 skeleton-card d-flex align-items-center justify-content-between p-3 mb-3">
            
            <!-- Image Skeleton -->
            <div class="skeleton-image me-3"></div>
            
            <!-- Price Section -->
            <div class="d-flex flex-column text-center">
                <div class="skeleton-price mb-2"></div>
                <div class="skeleton-label"></div>
            </div>

            <!-- Data Section -->
            <div class="d-flex flex-column text-center">
                <div class="skeleton-data mb-2"></div>
                <div class="skeleton-label"></div>
            </div>

            <!-- Validity Section -->
            <div class="d-flex flex-column text-center">
                <div class="skeleton-validity mb-2"></div>
                <div class="skeleton-label"></div>
            </div>

            <!-- Monthly Section -->
            <div class="d-flex flex-column text-center">
                <div class="skeleton-monthly mb-2"></div>
                <div class="skeleton-label"></div>
            </div>

            <!-- Features Section -->
            <div class="d-flex flex-column text-center">
                <div class="skeleton-feature mb-2"></div>
                <div class="skeleton-feature mb-2"></div>
                <div class="skeleton-feature"></div>
            </div>
        </div>
    </div>
</div>
