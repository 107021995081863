import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '../../services/user-agent.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { GridOptions } from 'ag-grid-community';
import { Width } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { firstValueFrom } from 'rxjs';
import { auto } from '@popperjs/core';

declare var bootstrap: any; 
 
@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})


export class ManageUserComponent implements OnInit {

@Input() public manageUserDetails:any
  public show: boolean = false
  public addAgentForm:FormGroup;
  public check=true;
  moreInfo: boolean = false;
  popup1Width;
  popup2Width;
  checkDuplicateUser: any;
  userList: any;
  allRoleList: any;
  editId: string;
  singleUserData: any=[];
  eightCharErr: any;
  loadUser:boolean=false;
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number;
  currentPage: number = 1; 
  totalPages: number = 1;
  pagedUserList: any[];
  userEmail:string
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";

  @LocalStorage()
  private userDetails;
  applicationInfo: any[];

  constructor(private fb:FormBuilder,private userAgentService:UserAgentService,private _snackBar:MatSnackBar,
    private router:Router,private http:HttpService,private commonService:CommonService){}

  ngOnInit(): void {
    this.getApplications()
    this.addAgentForm=this.fb.group({
      fullName:["",Validators.required],
      email:[{ value: ''}, [Validators.required, Validators.email]],
      userName:["",[Validators.required,Validators.pattern('^[a-zA-Z0-9]{8,}$')]],
      password:["",[Validators.required,Validators.pattern(`^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+={};':",./<>?]{8,}$`)]],
      // newUserEmail:[false,Validators.required],
      user_type:["",Validators.required],
      role:['',Validators.required],
      application:['',Validators.required]
      // costMetrix:[false],
      // revenueMetrix:[false],
    })
    this.getUser();
    this.getUserList();
    this.getRoleList();
    
  }

  @ViewChild('popoverElement') popoverElement!: ElementRef<HTMLDivElement>;
  
  public columnDefs: any[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 10, 
      minWidth: 20, 
      maxWidth: 30  
    },
    { headerName: 'Full Name', field: 'name', sortable: true, filter: true, width: 120,  minWidth: 100, maxWidth: 250  },
    { headerName: 'User Name', field: 'userName', sortable: true, filter: true ,width: 120,  minWidth: 100, maxWidth: 250 },
    { headerName: 'Email', field: 'email', sortable: true, filter: true,width: 250,  minWidth: 150, maxWidth: 300  },
    { headerName: 'Role',  
      valueGetter: (params) => {
        const roleCode = params.data?.usergroup?.[0]?.Code;
        return this.formatText(roleCode);
      }, 
      sortable: true, filter: true  ,width: 250,  minWidth: 150, maxWidth: 300 },
    {
        headerName: 'Application',
        field: 'application',
        width: 120, 
        minWidth: 100, 
        cellStyle: { 'white-space': 'pre-line' },
        maxWidth: 250  ,
        valueGetter: (params) => {
          const app = params.data?.aqsa_tel_app_users;
            if (!app || app.length === 0) {
              return '-';
            } else if (app.length === 1) {
              return app[0].Name;
            } else {
              return app.map(user => user.Name).join(', ');
            }
          }, 
      },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 120, 
      minWidth: 1000, 
      maxWidth: 250  ,
      cellRenderer: this.actionInfoRenderer.bind(this)
    }
  ];
  public defaultColDef: any = {
    sortable: true,    
    filter: true,      
    resizable: true,   
  
    flex: 1,            
    
  };
   gridOptions:GridOptions = {
    suppressRowClickSelection: true, 
    rowSelection: 'multiple',
    columnDefs: this.columnDefs,
    onCellClicked: (params) => this.handleCellClick(params),
  };
   handleCellClick(params) {
    if (params.colDef.field === 'checkbox') {
      const selected = params.node.isSelected();
      params.node.setSelected(!selected);
    }
  }
   formatText(text: string): string {
    if (!text) return 'NONE';
    let formattedText = text.replace(/_/g, ' ');
    formattedText = formattedText.replace(/\b\w/g, char => char.toUpperCase());
    return formattedText;
  }
  
  onSelectionChanged(event: any) {
    const selectedRows = event.api.getSelectedRows();
  }
  showPopover() {
   
    const element = this.popoverElement.nativeElement as HTMLElement;
    bootstrap.Popover.getInstance(element).show();
  }
  initPopovers() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });
  }
  
  hidePopover() {
   
    const element = this.popoverElement.nativeElement as HTMLElement;
    bootstrap.Popover.getInstance(element).hide();
  }
  
  // Method to set current page

  openSnackBar(message: string, duration: number = 2000) {
    this._snackBar.open(message, undefined, {
      duration: duration, verticalPosition: 'top'
  
    });
  }

  ///////////////////////////for popup ////////////////////////////////////

  actionInfoRenderer(params){
    const element = document.createElement('div');
  
    // Create a detail button with an icon
    const detailButton1 = document.createElement('button');
    detailButton1.className = 'btn text-secondary p-1';
    const detailIcon = document.createElement('i');
    detailIcon.className = 'fa-solid fa-pen-to-square fa-lg';
    detailButton1.appendChild(detailIcon);

     // Create a horizental icon button with an icon
     const horizentalButton = document.createElement('button');
     horizentalButton.className = 'btn btn-light p-1';
     const horizentalIcon = document.createElement('i');
     horizentalIcon.className = 'fa-solid fa-ellipsis-h fa-lg';
     horizentalButton.appendChild(horizentalIcon);

     // Create an edit button with an icon
    const editButton = document.createElement('button');
    editButton.className = 'btn text-primary p-1';
    const editIcon = document.createElement('i');
    editIcon.className = 'fa-solid fa-eye fa-lg';
    editButton.appendChild(editIcon);
  
    // Create a delete button with an icon
    const deleteButton = document.createElement('button');
    deleteButton.className = 'btn text-danger p-1';
    const deleteIcon = document.createElement('i');
    deleteIcon.className = 'fas fa-trash fa-lg';
    deleteButton.appendChild(deleteIcon);
  

    // Add event listeners for the buttons
    detailButton1.addEventListener('click', () => {
      this.toggleMoreInf1();
      this.editUserData(params?.data?.id);  // Assuming `item` is available in your scope
      this.userEmail=params?.data?.email
      this.moreInfoSelectedTab1('Line Information');
    });
    editButton.addEventListener('click', () => this.navigateWithParams(params?.data?.id));
    deleteButton.addEventListener('click', () => this.deleteWithParams(params?.data?.id));
    horizentalButton.addEventListener('click', () => {
      element.removeChild(horizentalButton)
      element.appendChild(editButton)
      element.appendChild(deleteButton)
      
    });
    element.appendChild(detailButton1);
    element.appendChild(horizentalButton)
    return element;
  }
 
  
  async getApplications() {
    try {
      const data: any = await firstValueFrom(this.userAgentService.getApplication());
      if (data?.Status) {
        this.applicationInfo = data?.data;
        this.applicationInfo.push(   {
          "id": 3,
          "Name": "All",
          "IsActive": 1,
          "IsDeleted": 0,
          "CreatedDate": "2024-08-05 14:43:53"
        },)
        console.log('application info: ', this.applicationInfo);
      } else {
        this.applicationInfo = [];
      }
    } catch (error) {
      console.error('Getting error in accessing application information', error);
    }
  }

  toggleMoreInf() {
    this.check=true;
    this.moreInfo = !this.moreInfo
    if(this.moreInfo){
    this.popup1Width = '83%';
    }else{
      this.popup1Width=''
    }
  }

  moreInfoTab:string = ''

  moreInfoSelectedTab(tabName:string) {
    this.moreInfoTab = tabName
  }


  moreInfoTab1:string = ''
  moreInfo1: boolean = false;


toggleMoreInf1()
{
  this.addAgentForm.get("email").enable();
  this.editId=''
  this.addAgentForm.reset()
  this.moreInfo1= !this.moreInfo1
  if(this.moreInfo1){
  this.popup2Width = '83%';
  this.popup1Width='92%'
  }else{
    this.popup2Width=''
    this.popup1Width='83%'
  }

}

moreInfoSelectedTab1(tabName:string){
  this.moreInfoTab1 = tabName
}


/////////////////////////////////////end popup /////////////////////////////////////////////

showPassword() {
  this.show = !this.show
}


userNameVerify(event:any){
  const userNameValue = event?.target?.value;
  this.userAgentService?.userNameVerify(userNameValue).subscribe((res:any)=>{
    this.checkDuplicateUser=res.message
    if(this.checkDuplicateUser=='User not registered'){
      this.check=false
    }else if(this.checkDuplicateUser=='User is already registered'
      ){
      this.check=false;
    }else{
      this.check=true;
    }
  },(error)=>{
    this.check=true
  })
}

submitAgent(){
  if(this.addAgentForm.invalid && !this.editId){
    this.addAgentForm.markAllAsTouched();
    console.log('Invalid')
    return 
  }else{
    let roleValue = this.addAgentForm.value.role;
    let groupId = roleValue && !isNaN(Number(roleValue)) ? Number(roleValue) : null;
    let application=this.addAgentForm.value.application
    let obj={}
if(application==3){
   obj={
    name:this.addAgentForm.value.fullName,
    email:this.addAgentForm.value.email,
    userName:this.addAgentForm.value.userName,
    password:this.addAgentForm.value.password,
    UserTypeID: 1,
    status_id: this.addAgentForm.value.user_type,
    userID:this.getUser().userID,
    companyId:"UNITYCRM0021",
    groupId:groupId,
    aqsaTelApps:[1,2]
  

  }
}else{
   obj={
    name:this.addAgentForm.value.fullName,
    email:this.addAgentForm.value.email,
    userName:this.addAgentForm.value.userName,
    password:this.addAgentForm.value.password,
    UserTypeID: 1,
    status_id: this.addAgentForm.value.user_type,
    userID:this.getUser().userID,
    companyId:"UNITYCRM0021",
    groupId:groupId,
    aqsaTelApps:[this.addAgentForm.value.application]
  

  }
}
 
  if(!this.editId){

    console.log(this.http.getUser())
  this.userAgentService.saveUserData(obj).subscribe((res:any)=>{
    this.openSnackBar('User Created Successfully..',3000)
    this.getUserList()
    this.moreInfo1= !this.moreInfo1
    this.popup2Width=''
    this.popup1Width='83%'
   
  })
}else{

const response:any=this.userAgentService.updateUserData(obj,this.userEmail);
// console.log(this.http.getCustomerId());
response.subscribe((res:any)=>{
this.openSnackBar('User Updated Successfully..',3000)
this.getUserList()
this.moreInfo1= !this.moreInfo1
this.popup2Width=''
this.popup1Width='83%'
})

}



}

}


/////////////////////  For Listing of Users /////////////////////////////////
totalusers:number
getUserList(search?){
  this.loadUser=true
  let itemsPerPage = this.itemsPerPage;
  let obj={
    "per_page": itemsPerPage,
    "page": this.currentPage,
  }
  this.userAgentService.getUserList(search,obj).subscribe((res:any)=>{
    this.userList=res?.data?.data;
    this.totalusers=res?.data?.total
    this.totalItems = res.data.total
    this.currentPage = res.data.current_page
    this.first_page_url = res.data.first_page_url
    this.last_page_url = res.data.last_page_url
    this.next_page_url = res.data.next_page_url
    console.log('userlIst:', this.userList)
    this.loadUser=false
  })
  this.loadUser=false
}


////////////////////////// For popup Role List ////////////////////////////

getRoleList(){
  this.userAgentService.getRoleList().subscribe((res:any)=>{
    this.allRoleList=res.data;
    this.allRoleList.push({id:11,Code:'None',Description:'No Roles',GroupEmail:"",GroupOwner:"",GroupTypeID:"",IsActive:"",IsDeleted:"",Name:"None",created_at:""})
    console.log('role list: ',this.allRoleList)
  })
}


getUser() {
  return this.userDetails;
}

navigateWithParams(id) {
  this.router.navigate([`/profile/${id}`], {
    // queryParams: { id: id}
  });
}
deleteWithParams(id){

}

////////////////////////////// for patching Dataof User ///////////////////////////////////////////////

editUserData(id){
  console.log("edit id",id)
  this.singleUserData=[];
  this.editId= id;
  this.addAgentForm.get("email").disable();
  this.userList.filter((item)=>{
  if(item.id==this.editId){
    this.singleUserData.push(item)
  }
  })
  if(this.singleUserData[0]?.usergroup.length>0 && this.singleUserData[0]?.usergroup[0]?.Code != 'ADMIN' && this.singleUserData[0]?.usergroup[0]?.Code != 'REPORTS'){
  const roleTransform=this.capitalizeAndFormat(this.singleUserData[0]?.usergroup[0]?.Code)
  this.addAgentForm.patchValue({
    fullName:this.singleUserData[0]?.name,
    email:this.singleUserData[0]?.email,
    userName:this.singleUserData[0]?.userName,
    user_type:this.singleUserData[0]?.status?.id,
    role:roleTransform
  })
  }else {
    this.addAgentForm.patchValue({
      fullName:this.singleUserData[0]?.name,
      email:this.singleUserData[0]?.email,
      userName:this.singleUserData[0]?.userName,
      user_type:this.singleUserData[0]?.status?.id,
      role:'None'
    })
  }
  
}

capitalizeAndFormat(input: string): string {
  let lowerCased = input.toLowerCase();
  let spaced = lowerCased.replace(/_/g, ' ');
  let capitalized = spaced.replace(/\b\w/g, (char) => char.toUpperCase());
  return capitalized;
}


filterdData(event:any){
  let data=event.target.value
  this.getUserList(data);
  // console.log('getUser data: ',this.getUserList(data))
}

onChangeNumberOfPage() {

  this.getUserList()
}
// onChangeItemsPerPage() {
//   this.itemsPerPage
//   this.getUserList();
//   }
  onChangeItemsPerPage() {
    console.log("Hello Page Size Changed");
    // This method is called when the user changes the page size from the dropdown
    // Update the itemsPerPage property here
    // Example:
    // this.itemsPerPage = selectedValueFromDropdown;
    // Optionally, fetch data based on the new page size
    // Example:
    // this.getData(this.paginator.pageIndex, this.itemsPerPage);
}
pageChange(event) {
  this.currentPage = event
  this.getUserList()
}


}
