import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import status from '../../mocks/status'
import tableData from '../../mocks/review';
import { TicketSystemService } from './services/ticket-system.service';

@Component({
  selector: 'app-ticket-system',
  templateUrl: './ticket-system.component.html',
  styleUrls: ['./ticket-system.component.scss']
})
export class TicketSystemComponent implements OnInit {

  public statusData = [];
  public tableData = tableData;

  constructor(
    public commonService: CommonService,
    public ticketSystemService: TicketSystemService
  ) { }

  ngOnInit() {
    this.setBreadcrumb()
    // this.getTickets()
  }

  setBreadcrumb() {
    this.commonService.setbreadCrumb({
      "title": 'Ticket System',
      // "items": ['#'],
      "active_item": 'Ticket System'
    })
  }

  async getTickets() {
    // 
    try {
      let data = {
        "per_page": 10
      }
      const res:any = await this.ticketSystemService.getAllTickets(data)
      console.log("get all ticket systems", res)
      if (res.Status) {
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    // 
  }

}
