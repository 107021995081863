<div class="d-flex align-items-start">
  <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <button class="nav-link active" id="v-pills-credit-card-autopay-tab" data-bs-toggle="pill"
      data-bs-target="#v-pills-credit-card-autopay" type="button" role="tab" aria-controls="v-pills-credit-card-autopay"
      aria-selected="false">
      <div class="d-flex">
        <div>
          <img src="assets/images/mobile.png" class="card-img">
        </div>
        <div class="d-flex align-items-center">
          <h5>Credit Card</h5>
        </div>
      </div>
    </button>
  </div>
  <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade show active" id="v-pills-credit-card-autopay" role="tabpanel"
      aria-labelledby="v-pills-credit-card-autopay-tab">
      <app-credit-card-autopay></app-credit-card-autopay>
    </div>
  </div>
</div>