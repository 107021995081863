import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-btn',
  templateUrl: './filter-btn.component.html',
  styleUrls: ['./filter-btn.component.scss']
})
export class FilterBtnComponent {
  isDropdownOpen: boolean = false
  @Input() optionList: any
  @Input() label: any
  @Input() value: any
  @Input() title: any
  @Input() icon: any
  @Input() isBtn: boolean = false
  selectedItem: any;
  @Output() selectionChanged = new EventEmitter<any>();
  @Input() preselectedItem: any;
  constructor(private eRef: ElementRef) {

  }

  ngOnInit(): void {
    console.log(this.preselectedItem);
    if (this.preselectedItem) {
      this.selectedItem = this.preselectedItem;
    }

  }

  toggleDropdown() {
    console.log(this.isDropdownOpen);

    if (!this.isBtn) {
      this.isDropdownOpen = !this.isDropdownOpen;
    }else{
      this.selectionChanged.emit();
    }
  }
  
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  selectItem(item: any) {
    if (!this.selectedItem || this.selectedItem[this.value] !== item[this.value]) {
      this.selectedItem = item;
      this.selectionChanged.emit(item); // Emit the selected item to the parent
    }
  }

}
