import { Component } from '@angular/core';
import { CommonService } from './shared/services/common.service';
import { MessageService } from 'primeng/api';
import { PermissionService } from './shared/services/permission-service.service';
import { SearchHistoryService } from "./shared/services/search-history.service";
import { loadNotifications } from './store/notifications/notification.actions';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { Notification } from './store/notifications/notification.model';
import { NavigationEnd, Router } from '@angular/router';
// global.d.ts
// global.d.ts
export {};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MessageService],
})
export class AppComponent {
  constructor(
    public commonService: CommonService,
    private permissionService: PermissionService,
    private store: Store<AppState>,
    private searchHistoryService: SearchHistoryService,
    private router: Router,
  ) {
    const location = window.location.href;
    const urlArray = location.split(":");

    /*    if(urlArray[0] === 'http'){
         window.location.href = `https:${urlArray[1]}`;
       } */
  }

  async ngOnInit() {
    window.dataLayer = window.dataLayer || [];
    this.setupGtmTracking();
    await this.permissionService.updatePermission();
    this.searchHistoryService.initializeSearchHistory();
    const storedState = localStorage.getItem("state");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      const notifications: Notification[] =
        parsedState.notifications?.notifications || [];
      console.log("on load", notifications);
      this.store.dispatch(loadNotifications({ notifications }));
    }
    const currentCompany = localStorage.getItem("currentCompany") || "unity";
    this.changeClass(currentCompany);
  }

   /**
   * Method to set up Google Tag Manager (GTM) tracking
   */
   private setupGtmTracking(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('Current dataLayer:', window.dataLayer); // Add this line
        (window as any).dataLayer.push({
          event: 'pageview',
          pagePath: event.urlAfterRedirects,
        });
        
      }
    });
  }
  changeClass(company) {
    console.log(company);
    const companyNameClass = company?.replace(/\s+/g, "-").toLowerCase();
    const bodyElement = document.body;
    bodyElement.classList.forEach((cls) => {
      if (cls.startsWith("company-")) {
        bodyElement.classList.remove(cls);
      }
    });
    if (companyNameClass) {
      bodyElement.classList.add(`company-${companyNameClass}`);
    }
  }
}
