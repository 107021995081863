import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-card-skeleton',
  templateUrl: './small-card-skeleton.component.html',
  styleUrls: ['./small-card-skeleton.component.scss']
})
export class SmallCardSkeletonComponent implements OnInit {

  @Input() alignment: 'row' | 'column' = 'row'; // Default to 'row'
  @Input() cardCount: number = 1; // Default to 1 card

  cardArray: any[] = []; // Array to generate skeleton cards

  ngOnInit(): void {
    this.cardArray = Array(this.cardCount).fill(0); // Generate empty array for the number of cards
  }
}
