import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.scss']
})
export class TableSkeletonComponent implements OnInit{

  @Input() tableRow: number = 5; // Default to 5 rows
  @Input() tableColumn: number = 3; // Default to 3 columns
  @Input() colWidthClass: string = 'w-75'; // Default column width class (can be adjusted)

  tableRowsArray: any[] = [];
  tableColumnsArray: any[] = [];

  constructor() { }

  ngOnInit(): void {
    // Initialize the arrays based on the input values
    this.tableRowsArray = Array(this.tableRow).fill(0); // Creates an array for the number of rows
    this.tableColumnsArray = Array(this.tableColumn).fill(0); // Creates an array for the number of columns
  }
}
