<div class="card">
    <div class="card-header p-10">
        <h6 class="mb-0">Add Adjust Wallet</h6>
    </div>
    <div class="card-body">
        <div class="form theme-form">
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Customer ID</label>
                        <input class="form-control" type="text" placeholder="Customer ID" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Name</label>
                        <input class="form-control" type="text" placeholder="Name" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Wallet Balance</label>
                        <input class="form-control" type="text" placeholder="Wallet Balance" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Adjust Amount <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" placeholder="Total Amount" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label>Reason <span class="text-danger">*</span></label>
                        <textarea class="form-control" id="exampleFormControlTextarea4" rows="3"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-end">
        <button class="btn btn-primary me-1">Submit</button>
        <button class="btn btn-secondary">Cancel</button>
    </div>
</div>