<div class="container-fluid tab-form-wrapper">
  <form>
<div class="row">
  <div class="col-md-12">
    <div class="form-group">
   <label>Query HLR</label>
   <div class="d-flex justify-content-between  ">
    <input type="text" class="form-control w-75" >
    <button type="button" class="btn btn-primary">Submit</button>
   </div>
   
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
   <label>Cancel Device</label>
   <div class="d-flex justify-content-between  ">
    <input type="text" class="form-control w-75" >
    <button type="button" class="btn btn-primary">Submit</button>
   </div>
   
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
   <label>Reset Voicemail Password</label>
   <div class="d-flex justify-content-between  ">
    <input type="text" class="form-control w-75" >
    <button type="button" class="btn btn-primary">Submit</button>
   </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
    <label>Features:</label><br>
    <button type="button" class="btn btn-secondary">Load Features</button>
  </div>
  </div>
  <div class="col-md-12">
    <div class="form-group border p-2">
    <h5>Remove Wifi Calling (WFC):</h5>
    <button type="button" class="btn btn-danger mt-2">Remove WFC</button>
 </div>
  </div>
  
    <div class="col-md-12 mt-3 text-end">
      <button type="button" class="btn btn-danger me-3">Close</button>

      <button type="button" class="btn btn-primary">Submit</button>
    </div>
 
</div>
    </form>
    </div>