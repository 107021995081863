
import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FullfillmentService } from 'src/app/fullfillment/services/fullfillment.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { BulkDialogService } from '../../services/bulk-dialog.service';

@Component({
  selector: 'app-bulk-dialog',
  templateUrl: './bulk-dialog.component.html',
  styleUrls: ['./bulk-dialog.component.scss']
})
export class BulkDialogComponent implements OnInit{
 
  @Input() public bulkDialog:boolean;
  @Output() public bulkDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public payloadName:any

  @Input() public code:any;
  public pageDropdownMenu:any[]=[5,10,20,30]
  public totalItems:any =50
  public currentPage:any =1
  public itemsPerPage:any=10;
  public contentLoader:boolean;
  public closeBatchDetaiails:boolean;
  batchReportLoader: boolean;
  first_page_url: any;
  next_page_url: any;
  filterListBatch: any;
  public batchReports: any[];
  public batchBulkDetails:any[];
  provisionHandleList: any = null;
  shipmentList: any = null;
  showBatchReportDetailModal: boolean;
  filterList: any;
  showBatchReportModal: boolean;


  constructor(public fb: FormBuilder,
    public commonService: CommonService,
    public http: HttpClient,
    private fullfillmentServices: FullfillmentService,
  private bulkService:BulkDialogService){

  }
  closeBatchDetailModal() {

this.showBatchReportDetailModal = false;
this.showBatchReportModal = false;

  }
  closeBatchReportModal() {
this.showBatchReportModal = false;
this.showBatchReportDetailModal = false;

  
    this.bulkDialogChange.emit(false); 
  }

  ngOnInit(): void {
    if(this.bulkDialog=true){
      this.getBulkReport();
    }
  }
  async getBulkReport() {
    this.batchReportLoader = true;
    try {
      let data = {
        "per_page": this.itemsPerPage,
        "page": this.currentPage
      }
      console.log('getBulkReport: called')
      const res: any = await this.bulkService.getBulkReports(data,this.code);
      if (res.Status) {
        // 
        let batchReports = res.data.data        
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.first_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.batchReports = res.data.data
        this.filterListBatch = res.data.data
        this.batchReportLoader = false;
        for (let index = 0; index < batchReports.length; index++) {
          const report: any = batchReports[index];
          batchReports[index]['countData'] = await this.getBatchFilesCount(report.id)
          batchReports[index]['countLoaded']=true
        }
        this.batchReports = batchReports
        this.filterListBatch = batchReports
        this.batchReportLoader = false;
        

      } else {
        this.batchReportLoader = false;
        
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.batchReportLoader = false;
      this.commonService.showToast("error", "Error", err.message)
    }
    this.batchReportLoader = false;
  }

  async getBatchFilesCount(id) {
    let tempData = {
      IsCompleted: 0,
      IsFailed: 0,
      IsProcessed: 0,
      count: 0,
      Done:0,
      Send:0,
      Failed:0
    }
    try {
      const res: any = await this.bulkService.getBatchFilesCount(id);
      if (res.Status) {
        let data = res.data.data
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          tempData['IsCompleted'] = tempData['IsCompleted'] + element['IsCompleted']
          tempData['IsFailed'] = tempData['IsFailed'] + element['IsFailed']
          tempData['IsProcessed'] = tempData['IsProcessed'] + element['IsProcessed']
          tempData['count'] = tempData['count'] + element['count']
          tempData['Done'] = tempData['Done'] + element['Done']
          tempData['Send'] = tempData['Send'] + element['Send']
          tempData['Failed'] = tempData['Failed'] + element['Failed']
        }
        return tempData
      } else {
        this.commonService.showToast("error", "Error", res.message)
        return tempData
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.message)
      return tempData
    }
  }
  async updateStgProvision(item: any, i: any) {
    
    try {
      let res: any = await this.fullfillmentServices.updateStgProvisionHandle(item.id)
      this.batchBulkDetails[i] = res.data[0]
      this.batchBulkDetails[i] = res.data[0]
      
    }
    catch (err) {
      
    }
  }
  async getProvision(item: any) {
    
    try {
      let res: any = await this.fullfillmentServices.getProvisionHandle(item.id)
      let shipmentRes: any = await this.fullfillmentServices.getShipments(item.CustomerId)
      this.provisionHandleList = res.data.data[0];
      this.shipmentList = shipmentRes.data.data;
      
    }
    catch (err) {
      console.log(err)
      
    }
  }

  async viewBatchReportDetails(batchReport, type) {
    this.showBatchReportDetailModal = true;
    try {
      this.batchReportLoader = true;
      let fileId=batchReport.id
      let body = {
          
         //IsProcessed,isSynced,IsCompleted,IsFailed, IsCompleted
        "IsProcessed":1
      }
      const res: any = await this.bulkService.getBatchFilesDetails(body,fileId);
      // batchFilesDetails
      if (res.Status) {
        this.batchBulkDetails = res.data.data
        this.filterList = res.data.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.message)
    }
    this.batchReportLoader = false;
  }

  async refreshCount(item: any, index: any) {
    
    try {
      this.filterListBatch[index]['countData'] = await this.getBatchFilesCount(item.id)
      this.batchReports = this.filterListBatch

      
    }
    catch (err) {
      console.log(err)
      
    }

  }

  onChangeNumberOfPage(){
    this.itemsPerPage =  parseInt(this.itemsPerPage)
    this.getBulkReport()
  }
  pageChange(event){
    this.currentPage = event
    this.getBulkReport()
  }
  contentLoading(){
    this.contentLoader=true
  }
}