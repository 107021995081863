import { Component} from '@angular/core';

@Component({
  selector: 'app-billing-and-invoice',
  templateUrl: './billing-and-invoice.component.html',
  styleUrls: ['./billing-and-invoice.component.scss']
})
export class BillingAndInvoiceComponent {

  public active1 = 1;
  public active2 = 1;

}
