import { Injectable } from '@angular/core';
import { StateService, AppState } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class StateManagementService {
  constructor(private stateService: StateService) {}

  /**
   * Saves the provided state data for a specific route context.
   * Clears the state of other routes to avoid conflicts.
   * @param routeKey - The unique key for the route (e.g., 'customer-email').
   * @param args - An array of values in the order: filters, items, selectedIndex.
   */
  saveState(routeKey: string, ...args: [any?, any[]?, number?]): void {
    const [filters = {}, items = [], selectedIndex = null] = args;
    const stateKey = this.getStateKey(routeKey);

    // Clear states of all other routes
    this.clearOtherStates(routeKey);

    // Save the state for the current route
    this.stateService.setState({ [stateKey]: { filters, items, selectedIndex } });
  }

  /**
   * Restores the saved state for a specific route context.
   * Clears states for all other routes except the current one.
   * @param routeKey - The unique key for the route (e.g., 'customer-email').
   * @param updateCallback - A callback function to update variables based on the restored state.
   * @returns {boolean} - Returns true if the state was successfully restored, false otherwise.
   */
  restoreState(routeKey: string, updateCallback: (state: Partial<AppState>) => void): boolean {
    // Clear states of all other routes
    this.clearOtherStates(routeKey);

    const stateKey = this.getStateKey(routeKey);
    const savedState = this.stateService.getState()[stateKey];

    if (savedState?.filters && savedState?.items?.length > 0) {
      updateCallback(savedState);
      return true;
    }
    return false;
  }

  /**
   * Clears the saved state for a specific route context.
   * @param routeKey - The unique key for the route (e.g., 'customer-email').
   */
  clearState(routeKey: string): void {
    const stateKey = this.getStateKey(routeKey);
    this.stateService.setState({ [stateKey]: { filters: {}, items: [], selectedIndex: null } });
  }

  /**
   * Clears the states of all routes except the specified one.
   * @param currentRouteKey - The route to preserve (e.g., 'customer-email').
   */
  private clearOtherStates(currentRouteKey: string): void {
    const allKeys = Object.keys(this.stateService.getState());
    const stateToPreserve = this.getStateKey(currentRouteKey);

    const clearedState = allKeys.reduce((acc, key) => {
      if (key !== stateToPreserve) {
        acc[key] = { filters: {}, items: [], selectedIndex: null };
      }
      return acc;
    }, {});

    this.stateService.setState({
      ...this.stateService.getState(),
      ...clearedState,
    });
  }

  /**
   * Generates a unique key for a route context.
   * @param routeKey - The base key for the route.
   * @returns {string} - A unique key for the route context.
   */
  private getStateKey(routeKey: string): string {
    return `state_${routeKey}`;
  }
}
