import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plan-setup-modal',
  templateUrl: './plan-setup-modal.component.html',
  styleUrls: ['./plan-setup-modal.component.scss']
})
export class PlanSetupModalComponent {
  constructor(public modal:NgbActiveModal){}
}
