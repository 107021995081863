import { Component, OnInit } from '@angular/core';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

@Component({
  selector: 'app-customer-events',
  templateUrl: './customer-events.component.html',
  styleUrls: ['./customer-events.component.scss'],
  animations:[fadeInOut]
})
export class CustomerEventsComponent implements OnInit {

  showCustomerEventAsList1:boolean = false
  loader: any;
  hasMoreData: boolean=true;
  moreInfoData: any[];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  isLoading: any;
  constructor(
    private customerProfileService:CustomerProfileService,private commonService:CommonService
  ){

  }
  ngOnInit(): void {
    this.getActivityInfo()
  }

  toggleCustomerEventModel() {
    this.showCustomerEventAsList1 = !this.showCustomerEventAsList1
  }
  onScroll(event: any): void {
    const element = event.target;
    const scrollPosition = element.scrollHeight - element.scrollTop;
    const bottomPosition = element.clientHeight;

    if (scrollPosition <= bottomPosition + 100 && !this.loader && this.hasMoreData) {
      this.loadMoreData();
    }
  }

  async loadMoreData() {
    if (this.hasMoreData && !this.loader) {
      this.loader = true;
  
      try {
        let data = {
          "per_page": this.itemsPerPage,
          "page": this.currentPage,
        };
  
        const res3: any = await this.customerProfileService.getActivityInfo(data);
  
        if (res3.Status) {
          const newData = res3?.data?.data || [];
          const isValidData = newData.length > 0 && !newData.every(item => !item);
          if (isValidData) {
            this.moreInfoData = [...(this.moreInfoData || []), ...newData];
            this.totalItems = res3.data.total;
            this.currentPage = res3.data.current_page + 1;
            this.hasMoreData = !!res3.data.next_page_url;
          } else {
            this.hasMoreData = false;
          }
        } else {
          this.commonService.showToast('error', "Error", res3.message);
          this.hasMoreData = false; 
        }
      } catch (error) {
        console.error('Error loading data:', error);
        this.commonService.showToast('error', "Error", 'Failed to load more data.');
      } finally {
        this.loader = false; 
      }
    }
  }

  async getActivityInfo(){
    let data = {
      "per_page": this.itemsPerPage,
      "page": this.currentPage,
    };
    
    try {
    this.isLoading=true;
      const res3: any = await this.customerProfileService.getActivityInfo(data);
      
      if (res3.Status) {
        this.moreInfoData = [
          ...(Array.isArray(this.moreInfoData) ? this.moreInfoData : []),
          ...(Array.isArray(res3?.data?.data) ? res3.data.data : [])
        ];
        this.totalItems = res3.data.total;
        this.currentPage = res3.data.current_page + 1; 
        this.hasMoreData = !!res3.data.next_page_url;
      } else {
        this.commonService.showToast('error', "Error", res3.message);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      this.commonService.showToast('error', "Error", 'Failed to load more data.');
    }finally{
    this.isLoading=false;
    }
  }
}
