
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './httpService.service';

@Injectable({
  providedIn: 'root'
})
export class BulkDialogService {
  resetstagingRecords(id: any) {
    try {
      let url = `api/system/resetstagingRecords`;
      let body = {
      fileId:id
   }
   return this.http.post(url,body).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
 
  downloadFile(codeName:string){
    try {
      let url = `api/system/getStagingRecords/${codeName}`;
      let body = {
            "companyId": "UNITYCRM0021",
            "userID": this.http.getUser()?.userID,
            // "fileId": codeName,
            // "IsProcessed": 1,
            "IsExport":1,
          }
        return this.http.get(url, body);
    } catch(err){
      throw new Error(err);
    }
  }
  constructor(private http: HttpService,
    public httpService: HttpClient){

  }
 
  getBulkReports(data:any,code:any) {
    try {
      let url = "api/system/getFiles";
      let body = {
        "Code": code,
        ...data
      }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }

  getBatchFilesCount(id) {
    try {
      let url = "api/system/getFilesTransferOut";
      let body = {
        "fileId": id,
      }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }

  getBulkSimFilesCount(id){
    try {
      let url = "api/system/getStagingCounts";
      let body = {
        "fileId": id,
      }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  getBatchFilesDetails(body:any,fileId:any) {
    try {   
      let url = `api/system/getStagingRecords/${fileId}`;
      return this.http.get(url,body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  getTransferFilesDetails(body:any,fileId:any) {
    try {   
      let url = `api/system/getStagingRecords/${fileId}`;
      return this.http.get(url,body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  deactivationInformation(data){
    try {
      let url = "api/system/getBulkProcessRecord";
      let body = {
        "fileId": data.file_id,
        "StgID":data.stg_id,
        "per_page": data.per_page,
        "page": data.page,
    }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
}