import { Component } from '@angular/core';

@Component({
  selector: 'app-money-gram-purchase',
  templateUrl: './money-gram-purchase.component.html',
  styleUrls: ['./money-gram-purchase.component.scss']
})
export class MoneyGramPurchaseComponent {

}
