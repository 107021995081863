import { Component } from '@angular/core';

@Component({
  selector: 'app-dealer-wallet-purchase',
  templateUrl: './dealer-wallet-purchase.component.html',
  styleUrls: ['./dealer-wallet-purchase.component.scss']
})
export class DealerWalletPurchaseComponent {

}
