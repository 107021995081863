import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-loading-skeleton',
  templateUrl: './data-loading-skeleton.component.html',
  styleUrls: ['./data-loading-skeleton.component.scss']
})
export class DataLoadingSkeletonComponent implements OnInit {
  @Input() loaderCount: number = 5; // Default to 5 loaders if none specified
  loaders: number[] = [];

  ngOnInit() {
    this.loaders = Array(this.loaderCount).fill(0); // Creates an array with 'loaderCount' items
  }
}
