import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridOptions, IGetRowsParams } from "ag-grid-community";

import { CommonService } from "src/app/shared/services/common.service";
import { HttpService } from "src/app/shared/services/httpService.service";
import { AwaitingShipmentService } from "../services/awaiting-shipment.service";
import { LocalStorage } from "ngx-webstorage";
import { NgbDate, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { StateManagementService } from "src/app/shared/services/state-management.service";

@Component({
  selector: "app-awaiting-shipment-order",
  templateUrl: "./awaiting-shipment-order.component.html",
  styleUrls: ["./awaiting-shipment-order.component.scss"],
  animations: [fadeInOut],
})
export class AwaitingShipmentOrderComponent
  implements OnInit, AfterViewInit, DoCheck {
  buttons: string[] = [
    "All",
    "Under review",
    "Unfullfilled",
    "Under provisioning",
    "Complete",
    "Awaiting Shipment",
    "Dispatched",
  ]; // List of buttons
  activeIndex: number = 0; // Default active button index
  dropdownVisible = new Set<number>();
  itemDropdownVisible = new Set<number>();
  customerDetails: any;
  showFilterRow: boolean;
  selectedFilters: any = [];
  searchTerm: any;
  selectedFilterObject: any = {};
  selectedColumn: any = 'OrderDate';
  isSkeletonLoader: boolean = true;
  selectedOrderType: any;
  isCardClick: boolean = false;
  selectedApprovedId: any;
  firstTimeDropship: boolean = false;

  filterConfig = [
    {
      type: "text",
      key: "searchText",
      label: "Ticket Name",
      placeholder: "Enter text...",
    },
    { type: "dateRange", key: "dateRange", label: "Date Range" },
  ];
  dropdownOpen = false;
  startDate: string = "2024-06-23";
  endDate: string = "2024-06-30";
  isSearchable: any;
  searchItem: any;
  filteredData: any;
  totalOrders: number = 0;
  @Output() orderedEmitted = new EventEmitter();
  @Input() isDropshipOrder;
  public ordersData: any = {
    totalOrders: 0,
    deliveredOrders: 0,
    returnOrders: 0,
    fullfilledOrders: 0,
    orderedItems: 0,
  };
  selectedIds: number[] = [];

  @LocalStorage()
  public userDetails;
  isPaid: boolean = false;
  isUnpaid: boolean = false;
  isDispatch: boolean = false;
  isAll: boolean = false;
  itemsPerPage: number = 50;
  loader: boolean = false;
  showPlusToggle: boolean = false;
  selectedRowIds: Set<number> = new Set();
  selectAll: boolean = false;
  paymentStatus: any = "All";
  canceldata: any;
  popupParent = document.body;

  // ag-grid table:

  @ViewChild("myGrid") myGrid: AgGridAngular;
  gridOptions: Partial<GridOptions>;
  gridApi;
  gridColumnApi;
  subscription: Subscription;
  rowDatas: any[];
  columnDefs: any[];
  paginationPageSizeOptions = [5, 10, 15];
  // column definition

  // row data
  public paginatioPnageSize: number = 50;
  public paginationTotalItems: number = 0;
  // public rowDatas:any[]=[]
  cacheOverflowSize: any;
  maxConcurrentDatasourceRequests: any;
  infiniteinitaialRowCount: any;
  defaultColDef: any;

  totalItems: number = 50;
  currentPage: number = 1;
  public rowData: any = [{}];
  public columnDef = [
    "Order",
    "Delivery Date",
    "CustomerId",
    "Customer",
    "Email Address",
    "Channel",
    "Total Amount",
    "Payment Status",
    "Order Status",
    "Order Type",
    "Shipment Status",
  ];

  sortOptions = [
    { id: 1, label: "Order number" },
    { id: 2, label: "Date" },
    { id: 3, label: "Items" },
    { id: 4, label: "Destination" },
    { id: 5, label: "Customer name" },
    { id: 6, label: "Payment status" },
    { id: 7, label: "Fulfillment status" },
    { id: 8, label: "Total" },
    { id: 9, label: "Channel" },
    { id: 10, label: "Oldest to newest" },
    { id: 11, label: "Newest to oldest" },
  ];

  filters = [
    { key: "OrderId", display: "Order", name: "Name", code: "code" },
    { key: "PaymentStatus", display: "Payment status", name: "Code", code: "id" },
    { key: "OrderStatus", display: "Fullfillment status", name: "Name", code: "id" },
  ];

  headers = [
    { key: "OrderId", display: "Order" },
    { key: "OrderDate", display: "Date" },
    { key: "CustomerId", display: "Customer" },
    { key: "Channel", display: "Channel" },
    { key: "TotalAmount", display: "Total" },
    { key: "PaymentStatus", display: "Payment status" },
    { key: "OrderStatus", display: "Fullfillment status" },
    { key: "OrderItems", display: "Items" },
    { key: "ShipmentStatus", display: "Delivery status" },
    { key: "OrderType", display: "Order Type" },
  ];

  public selectedRows: Set<number> = new Set<number>();
  isDraggable: boolean = false;
  paymentStatusData: any = [];
  OrderStatusData: any = [];
  shipmentStatusData: any = [];
  customerId: any;
  @Input() isFromCustomerId: any;
  highlightedRowIndex: number;
  constructor(
    private cd: ChangeDetectorRef,
    private shipmentService: AwaitingShipmentService,
    private http: HttpService,
    private service: CommonService,
    private customerProfileService: CustomerProfileService,
    private router: Router,
    private commonService: CommonService, private stateManagementService: StateManagementService
  ) {
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteinitaialRowCount = 2;

    this.defaultColDef = {
      resizable: true,
      minWidth: 180,
    };
  }

  onDateSelection($event: Event) {
    throw new Error("Method not implemented.");
  }

  setActive(index: number, button): void {
    this.isCardClick = false;
    this.activeIndex = index;
    if (button?.Name == "All") {
      this.selectedFilterObject.OrderStatus = null
    } else {
      this.selectedFilterObject.OrderStatus = [button.id];

    }
    this.fetchData();
  }
  setCardActive() {
    this.activeIndex = 0;
    this.selectedFilterObject.OrderStatus = [1];
  }

  ngDoCheck(): void {
    this.cd.detectChanges();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    if (
      !this.stateManagementService.restoreState("awaiting-shipment", (state) => {
        this.rowDatas = state.items || [];
        this.highlightedRowIndex = state.selectedIndex ?? null; // Restore selected index
        this.isSkeletonLoader = false; // Stop loader
      })
    ) {
      // If no saved state, fetch fresh data
      // this.getPosReport();
      this.shipmentService.filterSubject$.subscribe((data) => {
        console.log(data.type);
        if (data.type === "ordertype" && data.selectedItem) {
          this.selectedOrderType = data.selectedItem.OrderType;
          this.selectedFilterObject.OrderStatus = null;
        }
        if (data.type === "orderstatus") {
          this.selectedOrderType = data.selectedItem.StatusName;
          this.selectedApprovedId = data.selectedItem.orderStatusId;
          this.selectedFilterObject.OrderStatus = null;
        } else if (data.type === "reset") {
          this.selectedOrderType = null; // Clear OrderType on reset
        }
        this.isCardClick = true;
        // this.setCardActive()
        this.fetchData();
      });
      this.loader = true;
      const data = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        searchterm: "",
        fromDate: this.startDate,
        toDate: this.endDate,
      };
  
      this.fetchData();
      this.getTableHeaders();
      this.getShipmentStatus();
    }

  }

  fromDatePicker() { }
  toDatePicker() {
    // this.getAwaitingShipments()
  }
  pageChange($event: number) {
    this.currentPage = $event;
    // this.getAwaitingShipments();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // Automatically resize columns based on content
    this.gridColumnApi.autoSizeAllColumns();
    window.addEventListener("resize", () => {
      this.gridApi.sizeColumnsToFit();
    });

    this.fetchData();
  }
  onPaginationChanged(params: any) {
    this.loader = true;
    const data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      searchterm: this.http.getCustomerId(),
      fromDate: this.startDate,
      toDate: this.endDate,
      orderStatus: this.selectedIds.length ? this.selectedIds : null,
    };

    // Fetch data for the current page
    this.fetchData();
  }

  private fetchData() {
    if (this.isFromCustomerId) {
      this.customerId = this.customerProfileService.getCustomerId();
    }

    const data: any = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
    };
    if (this.searchTerm) {
      data.searchterm = this.searchTerm;
    }
    if (this.startDate) {
      data.fromDate = this.startDate;
    }
    if (this.endDate) {
      data.toDate = this.endDate;
    }
    if (this.selectedFilterObject?.OrderId) {
      data.orderStatus = this.selectedFilterObject?.OrderId;
    }
    if (this.selectedFilterObject?.PaymentStatus) {
      data.paymentStatus = this.selectedFilterObject?.PaymentStatus;
    }
    if (this.selectedFilterObject?.OrderStatus) {
      data.shipmentStatus = this.selectedFilterObject?.OrderStatus;
    }
    if (this.selectedColumn) {
      data.sortByField = this.selectedColumn;
    }
    if (this.customerId) {
      data.CustomerId = this.customerId;
    }

    if (this.isDropshipOrder) {
      this.activeIndex = 0;
      this.firstTimeDropship = true;
      data.orderStatus = 'OP_PENDING_PROVISIONING';
    }
    if (this.isCardClick && this.selectedOrderType !== "Approved") {
      data.OrderType = this.selectedOrderType;
      data.orderStatus = 'OP_PENDING_PROVISIONING';
    }
    if (this.isCardClick && this.selectedOrderType == "Approved") {
      data.orderStatus = this.selectedApprovedId;
    }
    this.service.getAwaitingShipmentOrder(data).subscribe(
      (response) => {
        this.rowDatas = response?.data?.Orders?.data || [];
        const totalOrders = response?.data?.TotalOrders || 0;
        this.totalOrders = totalOrders;
        this.loader = false;
        this.isSkeletonLoader = false;
      },
      (error) => {
        console.error("Error fetching data:", error);
        this.loader = false;
        this.isSkeletonLoader = false;
      }
    );
  }

  onFiltersChanged(e) { }

  async toggleDropdown(index: number, item) {
    const data = {
      CustomerId: item.CustomerId,
    };

    const res: any = await this.http
      .get("api/Customer/getCustomerPersonalInfo", data, false)
      .toPromise();
    if (res.Status) {
      this.customerDetails = res.data;
    }
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index); // Hide if already visible
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index); // Show if not visible
    }
  }
  async toggleItemDropdown(index: number, item) {
    if (this.itemDropdownVisible.has(index)) {
      this.itemDropdownVisible.delete(index); // Hide if already visible
    } else {
      this.itemDropdownVisible.clear();
      this.itemDropdownVisible.add(index); // Show if not visible
    }
  }

  @HostListener("document:click", ["$event"])
  handleClickOutside(event: MouseEvent): void {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      ".position-relative"
    );

    // If click is outside the dropdown, clear the visible dropdown
    if (!clickedInsideDropdown) {
      this.dropdownVisible.clear();
      this.itemDropdownVisible.clear();
    }
  }

  handleSelectionChange(e) {
    this.selectedColumn = e?.key;
    this.fetchData();
  }

  onFilterClick() {
    this.showFilterRow = true;
  }
  closeFilter() {
    this.showFilterRow = false;
    this.selectedFilterObject = {};
    this.selectedFilters = [];
    this.searchTerm = null;
  }

  sendFilter(e: any[], items) {
    console.log("selected filter", items, e);
    const selectedIds = [];
    e.forEach((item) => {
      if (item) {
        selectedIds.push(item[items.code]);
      }
    });
    this.selectedFilterObject[items["key"]] = selectedIds;
    this.fetchData();
    console.log("selected filter", items, selectedIds);
  }
  async handleFilterSelectionChange(e) {
    this.selectedFilters = e;
    for (const val of this.selectedFilters) {
      if (!val.optionList) {
        if (val.key === "OrderId") {
          await this.getOrderStatus();
          val.optionList = this.OrderStatusData;
        } else if (val.key === "PaymentStatus") {
          await this.getPaymentStatus();
          val.optionList = this.paymentStatusData;
        } else if (val.key === "OrderStatus") {
          // await this.getShipmentStatus();
          val.optionList = this.shipmentStatusData;
        }
      }
    }

    console.log(this.selectedFilters)
  }
  clearAll() {
    this.selectedFilterObject = {};
    this.selectedFilters = [];
    this.showFilterRow = false;
    setTimeout(() => {
      this.showFilterRow = true;
    }, 10);
  }
  async sendToCustomerDashboard(CustomerID,index) {
    this.stateManagementService.saveState(
      "awaiting-shipment",
      {}, // Save filters (add appropriate filters if necessary)
      this.rowDatas, // Save row data
      index // Save selected index
    );
    await this.customerProfileService.setCustomerId(CustomerID);
    this.customerProfileService.getMinimalProfileData();
    this.router.navigate(["/customer-dashboard/customer-profile"], {
      queryParams: { showBack: true },
    });
  }

  goToDetails(order,index) {
    this.stateManagementService.saveState(
      "awaiting-shipment",
      {}, // Save filters (add appropriate filters if necessary)
      this.rowDatas, // Save row data
      index // Save selected index
    );
    this.router.navigate(
      [`/awaiting-shipment/order-details/${order.OrderId}`],
      {
        queryParams: {
          customerid: order.CustomerId,
        },
        state: { order: order, list: this.rowDatas },
      }
    );
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.headers, event.previousIndex, event.currentIndex);
  }

  changeDraggable() {
    this.isDraggable = true;
    this.canceldata = [];
    this.canceldata = [...this.headers];
  }

  closeDragDrop() {
    this.isDraggable = false;
    this.headers = [];
    this.headers = [...this.canceldata];
    this.cd.detectChanges();
  }

  async saveColumnArrangement() {
    try {
      const res: any = await this.shipmentService.saveTableHeaders(
        this.headers,
        "ShipmentOrders"
      );
      if (res.Status) {
        this.commonService.showToast(
          "success",
          "Success",
          "Updated successfully!"
        );
        this.isDraggable = false;
        this.getTableHeaders();
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
  }
  async getTableHeaders() {
    this.shipmentService.getOrderHeader().then((res: any) => {
      if (res.Status) {
        if (res?.data?.length > 0) {
          this.headers = res.data;
        }
      }
    });
  }
  async getShipmentStatus(): Promise<void> {
    try {
      const res: any = await this.shipmentService.getshipmentStatusesList();

      if (res?.Status && Array.isArray(res?.data)) {
        this.shipmentStatusData = [
          { Name: "All", id: null },
          ...res.data.map((item: any) => ({
            ...item,
            Name: item?.code ? this.capitalizeAndFormat(item.code) : "",
          }))
        ];
      } else {
        throw new Error(res?.message || "Invalid response format.");
      }
    } catch (error) {
      console.error("Error fetching shipment statuses:", error);
      this.commonService.showToast('error', "Error", error.message || "Failed to fetch shipment statuses.");
      this.shipmentStatusData = [{ Name: "All", id: null }]; // Provide a fallback option
    }
  }

  capitalizeAndFormat(name: string): string {
    return name
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
  async getOrderStatus() {
    await this.shipmentService.getOrderStatusLIst().then((res: any) => {
      if (res.Status) {
        this.OrderStatusData = res.data;
      }
    });
  }
  async getPaymentStatus() {
    await this.shipmentService.getPaymentStatusList().then((res: any) => {
      if (res.Status) {
        this.paymentStatusData = res.data;
      }
    });
  }

  searchChange(e) {
    this.searchTerm = e?.target?.value;
    this.fetchData();
  }

  getStatusBadgeClass(status: string): any {
    
    switch (status) {
      case 'PAID':
        return 'badge-success';
      case 'RECEIVED':
        return 'badge-success';
      case 'New':
        return 'badge-primary';
      case 'DECLINED':
        return 'badge-warning';
      case 'FAILED':
        return 'badge-danger';
      case 'PENDING':
        return 'badge-secondary';
      default:
        return '';
    }
  } 

  getStatusBadgeClass2(status: string): any {
    console.log("as",status)
    if(status == "New Oder CSR"){
    }
    switch (status) {
      case 'Dispatched':
        return 'badge-success';
      case 'RECEIVED':
        return 'badge-success';
      case 'Approved':
        return 'bg-yellow';
      case "New Oder CSR":
        return 'badge-primary';
      case 'Pending Review':
        return 'badge-warning';
      case 'Cancelled':
        return 'badge-danger';
      default:
        return '';
    }
  }

}
