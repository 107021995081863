import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-subscription-info',
  templateUrl: './skeleton-subscription-info.component.html',
  styleUrls: ['./skeleton-subscription-info.component.scss']
})
export class SkeletonSubscriptionInfoComponent {

}
