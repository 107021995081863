<!-- <app-breadcrumb [title]="'User'" [items]="['Apps', 'User']" [active_item]="'Edit Profile'"></app-breadcrumb> -->
<div class="container-fluid" *ngIf="!isSkeletonLoader" @fadeInOut>
  <div class="edit-profile">
    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0" (click)="back()" style="font-size: 20px; cursor: pointer;"> <i class="fa-solid fa-arrow-left" ></i> My Profile</h4>
            <div class="card-options">
              <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="addChangeForm">
              <div class="row mb-2">
                <div class="col-auto"><img class="img-70 rounded-circle" alt="" src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg" /></div>
                <div class="col">
                  <h3 class="mb-1">{{profileData?.name}}</h3>
                  <p class="mb-1">{{profileData?.email}}</p>
                  <p class="mb-2">{{this.profileData?.status?.Status}}</p>
                </div>
              </div>
            
              <div class="mb-3">
                <label class="form-label">Email-Address</label>
                <input class="form-control" placeholder="{{profileData?.email}}" formControlName="email" />
              </div>
                  <div class=" mt-2">
                           <label class="labelOld">New password</label>
                           <input class="form-control" type="passsword" placeholder="New Password"  formControlName="newPassword">
                      </div>
                      <div class=" mt-2">
                           <label class="labelOld">Confirm password</label>
                           <input class="form-control" type="passsword" placeholder="Confirm Password" formControlName="confirmPassword">
                      </div>
              <div class="mb-3">
                <label class="form-label">Status</label>
                <input class="form-control" placeholder="{{this.profileData?.status?.Status}}" formControlName="website" />
              </div>
              <div class="form-footer">
                <button class="btn btn-primary d-block w-100 me-1" (click)="updatePassword()">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <form class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="card-title mb-0">Edit Profile</h4>
                <div class="card-options">
                    <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a>
                    <a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
                </div>
            </div>
    
            <div class="card-body">
                <!-- User Information Section -->
                <div class="mb-4">
                    <!-- <h5 class="text-muted">User Information</h5> -->
                    <div class="row mb-3" [hidden]="showContact">
                        <div class="col-md-5">
                            <p><b>Contact Information</b></p>
                        </div>
                        <div class="col-md-5">
                            <p><small><b>Email: </b>{{this.profileData?.email}}</small></p>
                            <p><small><b>Phone: </b>{{this.profileData?.agent?.PhoneNumber}}</small></p>
                            <p><small><b>Address: </b>{{this.profileData?.agent?.AddressLine1}}, {{this.profileData?.agent?.AddressLine2}}</small></p>
                        </div>
                        <div class="col-md-2 text-end">
                            <a href="javascript:void(0)" (click)="editContact()"><i class="fa-solid fa-pen-to-square"></i></a>
                        </div>
                    </div>
    
                    <!-- Contact Edit Form -->
                    <div class="card" [hidden]="showContactCard">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="email" class="form-label">Email</label>
                                    <input class="form-control" id="email" type="text" placeholder="Email" />
                                </div>
                                <div class="col-md-6">
                                    <label for="phone" class="form-label">Phone Number</label>
                                    <input class="form-control" id="phone" type="text" placeholder="Phone Number" />
                                </div>
                                <div class="col-md-12 mt-3">
                                    <label for="address" class="form-label">Address</label>
                                    <input class="form-control" id="address" type="text" placeholder="Address" />
                                </div>
                            </div>
                            <div class="mt-3 text-end">
                                <button type="button" class="btn btn-primary mx-2" (click)="submitContact()">Submit</button>
                                <button type="button" class="btn btn-dark" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
    
                <hr />
    
                <!-- Employee Information Section -->
                <div class="mb-4">
                    <!-- <h5 class="text-muted">Employee Information</h5> -->
                    <div class="row mb-3" [hidden]="showEmployee">
                        <div class="col-md-5">
                            <p><b>Employee Information</b></p>
                        </div>
                        <div class="col-md-5">
                            <p><small><b>Employee ID: </b>{{this.profileData?.id}}</small></p>
                            <p><small><b>Job Title: </b>Not available</small></p>
                            <p><small><b>Type of Employee: </b>{{this.profileData?.roles?.name}}</small></p>
                            <p><small><b>Manager's Email: </b>Not available</small></p>
                            <p><small><b>Department: </b>Not available</small></p>
                        </div>
                        <div class="col-md-2 text-end">
                            <a href="javascript:void(0)" (click)="editEmployee()"><i class="fa-solid fa-pen-to-square"></i></a>
                        </div>
                    </div>
    
                    <!-- Employee Edit Form -->
                    <div class="card" [hidden]="showEmployeeCard">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="employeeId" class="form-label">Employee ID</label>
                                    <input class="form-control" id="employeeId" type="text" placeholder="Employee ID" />
                                </div>
                                <div class="col-md-6">
                                    <label for="jobTitle" class="form-label">Job Title <span class="text-danger">*</span></label>
                                    <select class="form-control" id="jobTitle" formControlName="jobTitle">
                                        <option value="">Select job title</option>
                                        <option>Software Developer</option>
                                        <option>Sales</option>
                                        <option>Designer</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="roleOfEmployee" class="form-label">Role of Employee <span class="text-danger">*</span></label>
                                    <select class="form-control" id="roleOfEmployee" formControlName="role">
                                        <option value="">Select role</option>
                                        <option>Admin</option>
                                        <option>User</option>
                                        <option>Manager</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="managerEmail" class="form-label">Manager's Email</label>
                                    <input class="form-control" id="managerEmail" type="text" placeholder="Manager's Email" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="department" class="form-label">Department <span class="text-danger">*</span></label>
                                    <select class="form-control" id="department" formControlName="department">
                                        <option value="">Select department</option>
                                        <option>Software</option>
                                        <option>Sales</option>
                                        <option>Management</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3 text-end">
                                <button type="button" class="btn btn-primary mx-2" (click)="submitEmployee()">Submit</button>
                                <button type="button" class="btn btn-dark" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <!-- Optional Footer -->
            <!-- <div class="card-footer text-end">
                <button class="btn btn-primary" type="submit">Update Profile</button>
            </div> -->
        </form>
    </div>
    

    </div>
  </div>
</div>

<div *ngIf="isSkeletonLoader" @fadeInOut class=" container-fluid ">
    <div class="div d-flex flex-row justify-content-between">
        <app-skeleton-loader [cardCount]="1" [width]="'35vw'" [height]="'60vh'" [alignment]="'column'"  shape="normal-card"></app-skeleton-loader>
        <app-skeleton-loader [cardCount]="1" [width]="'55vw'" [height]="'60vh'" [alignment]="'row'"  shape="normal-card"></app-skeleton-loader>
    </div>
</div>