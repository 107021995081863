import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import tableData from '../../mocks/review';

@Component({
  selector: 'app-add-member-group',
  templateUrl: './add-member-group.component.html',
  styleUrls: ['./add-member-group.component.scss']
})
export class AddMemberGroupComponent implements OnInit {



  constructor(
    public commonService: CommonService,
  ) { }

  ngOnInit() {
  this.setBreadCrumb()
  }

  setBreadCrumb() {
    this.commonService.setbreadCrumb({
      "title": "Add Group Members",
      "active_item": "Add Group Members"
    })
  }



}
