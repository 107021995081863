import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { COMPANYDB, CompanyDB } from 'src/app/shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { OrderHistoryService } from '../services/order-history.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-acp',
  templateUrl: './acp.component.html',
  styleUrls: ['./acp.component.scss']
})
export class AcpComponent implements OnInit {

  data: any;
  type: string;
  modalData: any;
isSkeletonLoader: boolean=true;

  constructor(

    private modalService: NgbModal,
    private orderHistoryService: OrderHistoryService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getData()
  }


  async getData() {
    
    try {
      const res: any = await this.orderHistoryService.getOrderHistoryData()
      if (res.Status) {
        this.data = res.data
        console.log('getOrderHistoryData', this.data)
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast('error', "Error", res.message)
        this.isSkeletonLoader=false
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.isSkeletonLoader=false 
    }
    
  }

  openDetailModal(content, type, data) {
    this.type = type;
    this.getSingleData(data)
    this.modalService.open(content, { backdropClass: "dark-modal", size: 'lg', centered: true });
  }

  getSingleData(data) {
    this.modalData = data
  }
}
