<div class="div d-flex flex-row flex-wrap justify-content-center align-items-center">
  <div
    class="card plancard d-flex flex-column justify-content-center align-items-center col-12 col-md-5 col-lg-4 m-2 p-3 position-relative shadow-sm"
    *ngFor="let plan of plans; let i = index"
    style="border-radius: 15px; border: 1px solid #e0e0e0;"
  >
    <!-- Green Cross Ribbon -->
    <div *ngIf="plan?.Name == currentPlan" class="ribbon">
      <span class="ribbonStyle">
        Current Plan
      </span>
    </div>

    <div class="card-body text-center">
      <h5 class="card-title mb-1" style="font-weight: bold; color: #333; font-size: 1.2rem;">
        {{ plan?.PlanData || "-" }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted" style="font-size: 1rem;" *ngIf="plan.Name != 'Unlimited Plan'">
        $ {{ plan?.Price || "-" }}/mo
      </h6>
      <h6 class="card-subtitle mb-2 text-muted" style="font-size: 1rem;" *ngIf="plan.Name === 'Unlimited Plan'">
        $<del class="mx-2" *ngIf="plan.Price != '49.00'">49</del>  <ins>{{ calculateMonthlyCost(plan.Price, plan.ExpirationDays)}}</ins>/mo
      </h6>

      <div class="divider my-3" style="width: 100%; height: 1px; background-color: #dcdcdc;"></div>

      <div class="plan-image mb-3">
        <ng-container *ngIf="plan?.ImapgePath">
          <div class="image-container" style="max-width: 80%; overflow: hidden; border-radius: 10px;">
            <img
              [src]="plan?.ImapgePath"
              alt="Plan Image"
              style="
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
              "
            />
          </div>
        </ng-container>
      </div>

      <div class="plan-details d-flex flex-column align-items-start mt-2">
        <div class="d-flex align-items-center mb-2" *ngIf="plan.Name === 'Unlimited Plan'">
          <app-feather-icons [icon]="'dollar-sign'" style="color: #6c63ff;"></app-feather-icons>
          <span class="ms-2">{{ plan.Price || "-" }}</span>
        </div>
        <div class="d-flex align-items-center mb-2" *ngIf="plan.Name === 'Unlimited Plan'">
          <app-feather-icons [icon]="'calendar'" style="color: #6c63ff;"></app-feather-icons>
          <span class="ms-2">{{ plan.ExpirationDays || "-" }} days</span>
        </div>
        <div class="d-flex align-items-center mb-2" *ngIf="plan.Name === 'Unlimited Plan'">
          <i class="fa-solid fa-piggy-bank" style="color: #6c63ff;font-size: 20px;"></i>
          <span class="ms-2">${{ calculateSavings(plan.Price, plan.ExpirationDays) }}</span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <app-feather-icons [icon]="'smartphone'" style="color: #6c63ff;"></app-feather-icons>
          <span class="ms-2">{{ plan?.Name || "-" }}</span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <app-feather-icons [icon]="'phone-call'" style="color: #6c63ff;"></app-feather-icons>
          <span class="ms-2">{{ plan?.VoiceText || "-" }} Minutes</span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <app-feather-icons [icon]="'message-square'" style="color: #6c63ff;"></app-feather-icons>
          <span class="ms-2">{{ plan?.VoiceText || "-" }} SMS</span>
        </div>
      </div>

      <button
        class="btn btn-outline-primary mt-3 w-100"
        style="border-radius: 10px; font-size: 1rem; padding: 10px 0; font-weight: 500;"
        (click)="openDiv(); getSelectedPlanId(plan?.id); onPlanSelected($event)"
      >
        View Details
      </button>
    </div>
  </div>
</div>

<!-- Slide-in Div -->
<div class="slide-in-div container-fluid" [ngClass]="{ open: isOpen }" style="margin: 0; padding: 0">
  <div class="container-fluid sticky-header" style="background-color: #f5f5f5; border-bottom: 2px solid black;">
    <div class="div d-flex flex-row justify-content-between align-items-center m-2">
      <h5 style="margin-left: 5px; letter-spacing: 3px">PLAN DETAIL</h5>
      <app-feather-icons [icon]="'x'" class="text-primary" (click)="closeDiv()" style="cursor: pointer"></app-feather-icons>
    </div>
  </div>

  <div class="content m-3">
    <div class="d-flex flex-row justify-content-evenly align-items-center p-3">
      <!-- Prorated Plan Section -->
      <div *ngIf="planSelected">
        <div class="card d-flex flex-column justify-content-center align-items-center shadow-sm" style="border-radius: 10px;border: 1px solid lightblue;">
          <div class="card-body text-center">
            <h5 class="card-title mb-1" style="font-weight: bold; color: #4a4a4a;">Prorated Plan</h5>
            <h6 class="card-subtitle mb-2 text-muted">$ {{ proratedDetails?.newPlanPrice || "-" }}</h6>

            <div class="divider my-3" style="width: 100%; height: 1px; background-color: #dcdcdc;"></div>

            <div class="plan-details d-flex flex-column align-items-start mt-2">
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'smartphone'" style="color: #6c63ff;"></app-feather-icons>
                <span class="ms-2">
                  <i><u>{{ newPlanDetails?.plan?.DisplayName || "-" }}</u></i>
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'phone-call'" style="color: #6c63ff;"></app-feather-icons>
                <span class="ms-2">{{ proratedDetails?.changeType || "-" }}</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'dollar-sign'" style="color: #6c63ff;"></app-feather-icons>
                <span class="ms-2">$ {{ proratedDetails?.proratedPrice || "-" }} <span class="text-danger"><b> [Payable Amount]</b></span></span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ proratedDetails?.validity || "-" }} Days Validity</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ paymentType }} <span class="text-success"></span> </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ proratedDetails?.effectiveDate | date }} <span class="text-success"><b>[Effective Date]</b></span> </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <ng-container *ngIf="proratedDetails?.changeType === 'Downgrade'">
                  <div class="text-danger mt-2  " role="alert" style=" width: 20em;word-wrap: break-all; white-space:normal">
                    The amount won't be taken out right now. According to the prearranged billing cycle, payment will be handled through the subscription profile.
                  </div>
                </ng-container>
              </div>
            </div>
              <!-- Additional Info -->
             <!-- Additional Info -->
<div class="extra-details mt-4 p-3" style="border: 1px solid #cce5ff; border-radius: 10px; background-color: #eaf3ff;">
  <h6 class="text-dark" style="font-weight: bold;">Additional Details</h6>
  <hr>
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex align-items-center mb-2">
      <app-feather-icons [icon]="'calendar'" style="color: #4d79ff;"></app-feather-icons>
      <span class="ms-2">Prorated Days: <b>{{ proratedDetails?.proratedDays || "0" }} Days</b></span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <app-feather-icons [icon]="'calendar'" style="color: #4d79ff;"></app-feather-icons>
      <span class="ms-2">Used Plan Days: <b>{{ proratedDetails?.usedPlanDays || "0" }} Days</b></span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <app-feather-icons [icon]="'clock'" style="color: #4d79ff;"></app-feather-icons>
      <span class="ms-2">Remaining Days: <b>{{ proratedDetails?.remainingDays || "0" }} Days</b></span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <app-feather-icons [icon]="'check-circle'" style="color: #4d79ff;"></app-feather-icons>
      <span class="ms-2">Validity: <b>{{ proratedDetails?.validity || "0" }} Days</b></span>
    </div>
  </div>

  <div class="d-flex align-items-center mb-2">
    <ng-container *ngIf="proratedDetails?.changeType === 'Downgrade'">
      <div class="text-danger mt-2" role="alert" style="width: 20em; word-wrap: break-all; white-space: normal;">
        The amount won't be taken out right now. According to the prearranged billing cycle, payment will be handled through the subscription profile.
      </div>
    </ng-container>
  </div>
</div>

            

            <div class="button-group d-flex justify-content-center mt-3">
              <button *ngIf="proratedDetails?.changeType !== 'SamePlan'" class="btn btn-outline-primary w-100" style="border-radius: 10px; font-size: 1rem; padding: 10px 0; font-weight: 500;"
              (click)="handleCheckOut(modalTemplate)">
                Check Out
              </button>
              <button *ngIf="proratedDetails?.changeType === 'SamePlan'" class="btn btn-outline-primary w-100" style="border-radius: 10px; font-size: 1rem; padding: 10px 0; font-weight: 500;" disabled>
                Current Plan
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- New and Old Plan Section -->
      <div class="d-flex flex-column flex-md-row justify-content-center align-items-stretch oldNewPlan">
        <!-- New Plan Card -->
        <div class="card  flex-fill d-flex flex-column justify-content-center align-items-center m-2 shadow-sm" style="border-radius: 10px; border: 1px solid #e0e0e0;background-color: #F9FFF7;">
          <div class="card-body text-center">
            <div  class="ribbon">
              <span class="ribbonStyleNew">
                New Plan
              </span>
            </div>
            <h5 class="card-title mb-1" style="font-weight: bold; color: #4a4a4a;">{{ newPlanDetails?.plan?.DisplayName || "-" }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">$ {{ newPlanDetails?.plan?.Price || "-" }}</h6>
      
            <div class="divider my-3" style="width: 100%; height: 1px; background-color: #dcdcdc;"></div>
      
            <div class="plan-details d-flex flex-column align-items-start mt-2">
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'smartphone'" style="color: #6c63ff;"></app-feather-icons>
                <span class="ms-2"><i><u>{{ newPlanDetails?.plan?.DisplayName || "-" }}</u></i></span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'dollar-sign'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">$ {{ newPlanDetails?.plan?.Price || "-" }}</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ newPlanDetails?.planDetail?.ExpirationDays || "-" }} Days Validity</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'radio'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ newPlanDetails?.planDetail?.PlanData || "-" }}</span>
              </div>
            </div>
          </div>
        </div>
      
        <!-- Divider between cards -->
        <div class="divider d-none d-md-block" style="width: 2px; "></div>
      
        <!-- Old Plan Card -->
        <div class="card  flex-fill d-flex flex-column justify-content-center align-items-center m-2 shadow-sm" style="border-radius: 10px; border: 1px solid #e0e0e0;background-color: #F9FFF7;">
          <div class="card-body text-center">
            <div class="ribbon">
              <span class="ribbonStyleOld">
                Old Plan
              </span>
            </div>
            <h5 class="card-title mb-1" style="font-weight: bold; color: #4a4a4a;">{{ oldPlanDetails?.plan?.DisplayName || "-" }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">$ {{ oldPlanDetails?.plan?.Price || "-" }}</h6>
      
            <div class="divider my-3" style="width: 100%; height: 1px; background-color: #dcdcdc;"></div>
      
            <div class="plan-details d-flex flex-column align-items-start mt-2">
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'smartphone'" style="color: #6c63ff;"></app-feather-icons>
                <span class="ms-2"><i><u>{{ oldPlanDetails?.plan?.DisplayName || "-" }}</u></i></span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'dollar-sign'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">$ {{ oldPlanDetails?.plan?.Price || "-" }}</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'calendar'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ oldPlanDetails?.planDetail?.ExpirationDays || "-" }} Days Validity</span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <app-feather-icons [icon]="'radio'" style="color: #9b9bf4"></app-feather-icons>
                <span class="ms-2">{{ oldPlanDetails?.planDetail?.PlanData || "-" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
  </div>
</div>





<ng-container *ngIf="isPaymentModelOpen">
  <app-plan-change-payment  (isPaymentModelOpenChange)="onIsPaymentModelOpenChange($event)" [isPaymentModelOpen]="isPaymentModelOpen" [proratedDetails]="proratedDetails" [selectedPlanId]="selectedPlanId" [selectedPlanName]="selectedPlanName" [newPlanDetails]="newPlanDetails" (transactionStatus)="isTransactionStatus()"></app-plan-change-payment>
</ng-container>


