<div class="m-2 kanban-boards">
    <ng80-board [board]="boardData" (onCardMoved)="onCardMoved($event)" (onCardClick)="onCardClick($event)"
        (onColumnMoved)="onColumnMoved($event)" (reachedEnd)="onReachedEnd($event)" *ngIf="!isSkeletonLoader"  @fadeInOut >
        <ng-template cardTpt let-item="item">
            <div [ngClass]="{'custom-card-template': true, 'drag-disabled': !canDrag}" class=" cursor-pointer"
                >
                <div>
                    <div class="d-flex justify-content-between align-items-center">
                        <h6>{{item.Title}}</h6>
                        <span class="badge badge-light-success height-max-content">#{{item.id}}</span>
                    </div>
                    <p class="clamp-3-lines" [innerHTML]="item.Description"></p>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <div role="button" aria-label="Toggle assignee popover" aria-haspopup="listbox"
                    class="icon-wrapper mt-2" aria-disabled="false" tabindex="0">


                    <!-- <div class="AvatarPlaceholder">
                        <svg class="MiniIcon UserMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                            <path
                                d="M12,14c-3.859,0-7-3.14-7-7S8.141,0,12,0s7,3.14,7,7-3.141,7-7,7Zm0-12c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm10,21v-2c0-2.757-2.243-5-5-5H7c-2.757,0-5,2.243-5,5v2c0,.552,.447,1,1,1s1-.448,1-1v-2c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v2c0,.552,.447,1,1,1s1-.448,1-1Z">
                            </path>
                        </svg>
                    </div> -->
                    <div class="avatar-circle " [style.background-color]="getColor(item.Title)">
                        {{ getInitials(item.Title) }}
                    </div>
                    <div class="AvatarPlaceholder" *ngIf="!item.DueDate">
                        <svg class="MiniIcon CalendarMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                            <path
                                d="M19.1,2H18V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H8V1c0-0.6-0.4-1-1-1S6,0.4,6,1v1H4.9C2.2,2,0,4.2,0,6.9v12.1  C0,21.8,2.2,24,4.9,24h14.1c2.7,0,4.9-2.2,4.9-4.9V6.9C24,4.2,21.8,2,19.1,2z M4.9,4H6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h8v1  c0,0.6,0.4,1,1,1s1-0.4,1-1V4h1.1C20.7,4,22,5.3,22,6.9V8H2V6.9C2,5.3,3.3,4,4.9,4z M19.1,22H4.9C3.3,22,2,20.7,2,19.1V10h20v9.1  C22,20.7,20.7,22,19.1,22z">
                            </path>
                        </svg>
                    </div>
                    <span *ngIf="item.DueDate" class="dueDate text-nowrap"
                        [ngStyle]="{'color': isPastDueDate(item.DueDate) ? 'red' : 'green'}">
                        {{ item.DueDate | customDate }}
                    </span>
                </div>
                <div class="icon-wrapper mt-2 d-flex gap-1 align-items-center">
                    <span class="custom-btn" *ngIf="item?.comments_count">
                        <span class="pe-1" >
                            {{item.comments_count}}
                        </span>
                        <i class="fa fa-comment-o"> </i>
                    </span>
                    <span class="custom-btn" *ngIf="item?.children_count">
                        <span class="pe-1">
                            {{item.children_count}}
                        </span>
                        <svg class="MiniIcon SubtaskCountToggleButton-subtaskIcon SubtaskMiniIcon" viewBox="0 0 24 24"
                            aria-hidden="true" focusable="false">
                            <path
                                d="M20,15c-1.9,0-3.4,1.3-3.9,3H7c-2.8,0-5-2.2-5-5v-3h14.1c0.4,1.7,2,3,3.9,3c2.2,0,4-1.8,4-4s-1.8-4-4-4 c-1.9,0-3.4,1.3-3.9,3H2V3c0-0.6-0.4-1-1-1S0,2.4,0,3v10c0,3.9,3.1,7,7,7h9.1c0.4,1.7,2,3,3.9,3c2.2,0,4-1.8,4-4S22.2,15,20,15z M20,7c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S18.9,7,20,7z M20,21c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S21.1,21,20,21z">
                            </path>
                        </svg>
                    </span>
                </div>


            </div>
        </ng-template>
        <ng-template columnHeaderTpt let-column="column">
            <div class="d-flex justify-content-between w-100">
                <p class="column-header d-flex gap-2 align-items-center ">
                    <span class="column-header">{{column.title}}</span>
                    <span class="fs-6 text-muted">({{column.total_tickets?column.total_tickets:0}})</span>
                </p>
                <div class="d-flex gap-2">
                    <app-feather-icons [icon]="'plus'" class="mt-1 cursor-pointer" (click)="addTicket(column)"
                        *ngIf="column.id=='1'"></app-feather-icons>
                    <!-- <app-feather-icons [icon]="'more-horizontal'" class="mt-1"></app-feather-icons> -->
                </div>
            </div>
        </ng-template>
    </ng80-board>
    <div *ngIf="isSkeletonLoader"  @fadeInOut  style="max-width: 100%;" >
            <app-skeleton-loader shape="ticket-system"></app-skeleton-loader>
    </div>
    
</div>


<app-ticket-sidebar *ngIf="isSidebarOpen" [isEditing]="isEditing" [ticketId]="selectedCard?.id" (close)="closeSidebar()"
    (save)="getAllTickets()">
</app-ticket-sidebar>