<div class="customizer-contain">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header sticky-header" *ngIf="!showCateGory_types">
      <h5 class="mb-0">
        Resources Type<i class="fa fa-question-circle cursor-pointer" style="font-size: 17px;"></i>
      </h5>
      <i class="icofont-close icon-close" (click)="closeModal1()"></i>
    </div>
    <div id="main">
      <div class="bg-light">
        <div class="col-md-12 text-end mt-0">
          <button class="btn btn-info my-3" type="button" data-toggle="collapse" data-target="#collapseExample"
            aria-expanded="false" aria-controls="collapseExample">
            Create
          </button>
        </div>
        <div class="custom-card mx-3 overflow-visible">
          <form [formGroup]="resourcesTypeForm">
            <div class="row  mt-3 px-4 py-4" id="collapseExample">
              <div class="col-md-4 mb-3">
                <label for="resourceName" class="required">Resource Type Name</label>
                <input type="text" class="form-control" id="resourceName" formControlName="resourceName"
                  placeholder="Enter resource name"
                  [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceName').invalid && resourcesTypeForm.get('resourceName').touched}">
              </div>
              <div class="col-md-4 mb-3">
                <label for="resourceDescription">Description</label>
                <input type="text" class="form-control" id="resourceDescription" formControlName="resourceDescription"
                  placeholder="Enter description"
                  [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceDescription').invalid && resourcesTypeForm.get('resourceDescription').touched}">
              </div>
              <div class="col-md-4 mb-3">
                <label for="displayName" class="required">Display Name</label>
                <input type="text" class="form-control" id="displayName" formControlName="displayName"
                  placeholder="Enter display name"
                  [ngClass]="{'is-invalid': resourcesTypeForm.get('displayName').invalid && resourcesTypeForm.get('displayName').touched}">
              </div>
              <div class="col-md-4 mb-3">
                <label for="resourceCategoryID " class="required">Resource Category</label>
                <ng-select id="resourceCategoryID" formControlName="resourceCategoryID"
                  (change)="onSelectCategory1($event)" [placeholder]="'Select or add a category'"
                  [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceCategoryID').invalid && resourcesTypeForm.get('resourceCategoryID').touched}">
                  <ng-option *ngFor="let category of resourcesGategoryList" [value]="category.id">
                    {{ category.Name }}
                  </ng-option>
                  <ng-option [value]="-1" [ngClass]="'add-category-option'">
                    + Add new Category type...
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-4 mt-4 gap-2">
                <label class="d-flex gap-1">
                  <input type="radio" formControlName="status" [value]="1" (change)="onStatusChange(1)">
                  Active
                </label>
                <label class="d-flex gap-1">
                  <input type="radio" formControlName="status" [value]="0" (change)="onStatusChange(2)">
                  Inactive
                </label>
              </div>

              <div class="col-md-12 text-end mt-3 px-0">
                <button *ngIf="!showupdate" class="btn btn-outline-info" style="font-size: 15px;" type="submit"
                  (click)="submitForm()">Submit</button>
                <div class="col-md-12 text-end " *ngIf="showupdate">
                  <button class="btn btn-outline-warning mx-2" style="font-size: 15px;" type="button"
                    (click)="update()">Update</button>
                  <button class="btn btn-outline-danger" style="font-size: 15px;" type="button"
                    (click)="resetForm()">Reset</button>
                </div>
              </div>
            </div>

          </form>
        </div>
        <!-- <div class=" table-responsive mt-2" style="height: 400px;">
        <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-alpine" [rowData]="resourcesTypeList"
          [columnDefs]="columnDefs" [headerHeight]="43" [defaultColDef]="defaultColDef"
          (cellClicked)="onCellClicked($event)">
        </ag-grid-angular>
      </div> -->

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="table-responsive marginB2" >
              <table class="table project-list-table table-nowrap align-middle table-borderless">
              <thead class="table-light">
                <tr class="text-center">
                  <th>Sr.No</th>
                  <th>Resource Type Name</th>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" *ngFor="let row of resourcesTypeList; let i = index">
                  <td>{{i+1}}</td>
                  <td >{{row?.DisplayName}}</td>
             
                  <td>{{row?.Description}}</td>
              
                  <td >{{row.resource_category?.DisplayName}}</td>
                  <td>
                    <!-- Action buttons -->
                    <button class="btn btn-sm btn-primary me-2" id="edit" data-toggle="collapse" data-target="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample" (click)="ViewOneRes(row.id)">Edit</button>
                    <button class="btn btn-sm btn-danger" (click)="deleteResouceType(row.id)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      

        <!-- <div class="row col-md-12">
        <div class="col-md-2" *ngFor="let item of resourcesGategoryList">
          <div #legend class="legend-container d-flex flex-wrap mt-2"></div>

          <p class="text-nowrap">{{item.DisplayName}}</p>

        </div>
      </div> -->
      </div>
    </div>
  </div>


  <div *ngIf="showCateGory_types==true">
    <app-manage-category (close)="closeModal3()"></app-manage-category>

  </div>