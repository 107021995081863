
<!-- Network Info Modal Start -->
<ng-template #networkInfoModal let-modal>
  <div class="modal-header modal-copy-header">
    <h5 class="headerTitle mb-0">Network Informations</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <h5>Settings</h5>
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Data Saver</th>
            <td>
              {{networkInfoDetails?.settings?.dataSaver?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
          <tr>
            <th scope="row">Data Throttle</th>
            <td>
              {{networkInfoDetails?.settings?.dataThrottle?.enabled ? 'Enable' : 'Disable'}}
              <ng-container *ngIf="networkInfoDetails?.settings?.dataThrottle?.speed">
                | {{networkInfoDetails?.settings?.dataThrottle?.speed}}
              </ng-container>
            </td>
          </tr>
          <tr>
            <th scope="row">MMS</th>
            <td>
              {{networkInfoDetails?.settings?.mms?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
          <tr>
            <th scope="row">Roam Control</th>
            <td>
              {{networkInfoDetails?.settings?.roamControl?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
          <tr>
            <th scope="row">Scam Block</th>
            <td>
              {{networkInfoDetails?.settings?.scamBlock?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
          <tr>
            <th scope="row">Tether Throttle</th>
            <td>
              {{networkInfoDetails?.settings?.tetherThrottle?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
          <tr>
            <th scope="row">Voice Email</th>
            <td>
              {{networkInfoDetails?.settings?.voicemail?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
          <tr>
            <th scope="row">Wifi Calling</th>
            <td>
              {{networkInfoDetails?.settings?.wifiCalling?.enabled ? 'Enable' : 'Disable'}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<!-- Network Info Modal End -->

<!-- Add Data Throttle Modal Start -->
<ng-template #addDataThrottleModal let-modal>
  <div class="modal-header modal-copy-header">
    <h5 class="headerTitle mb-0"> Add Data Throttle </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <label>Select Throttle Speed<span class="text-danger">*</span>:</label>
        </div>
        <div class="col-6">
          <select class="form-control" [(ngModel)]="throttleSpeed">
            <option *ngFor="let throttle of throttleSpeedList" [value]="throttle.Code">{{throttle.Name}}</option>
          </select>
        </div>
        <div class="col-12 text-center my-2">
          <button class="btn btn-primary" (click)="addThrottleSpeed()" type="button"
            [(disabled)]="!throttleSpeed">Add</button>
        </div>
      </div>
      <ng-container *ngIf="modalLoader">
        <app-loader [isChildLoader]="true"></app-loader>
      </ng-container>
    </div>
  </div>
</ng-template>
<!-- Add Data Throttle Modal End -->

<!-- Add WFC Modal Start -->
<ng-template #addWfcModal let-modal >
  
  <div class="modal-header modal-copy-header">
    <h5 class="headerTitle mb-0"> Add Wifi Calling </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal(modal)"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <form [formGroup]="wfcFormData">
        <div class="row">
          <div class="col-6 my-2">
            <label>Address 1<span class="text-danger">*</span>:</label>
          </div>
          <div class="col-6 my-2">
            <input type="text" formControlName="address1" class="form-control"  id="addressInput">
          </div>
      
          <div class="col-6 my-2">
            <label>Address 2:</label>
          </div>
          <div class="col-6 my-2">
            <input type="text" formControlName="address2" class="form-control">
          </div>
      
          <div class="col-6 my-2">
            <label>Country<span class="text-danger">*</span>:</label>
          </div>
          <div class="col-6 my-2">
            <input type="text" formControlName="country" class="form-control" >
          </div>
      
          <div class="col-6 my-2">
            <label>State<span class="text-danger">*</span>:</label>
          </div>
          <div class="col-6 my-2">
            <select formControlName="state" class="form-control">
              <option value="" disabled selected>Select State</option>
              <option *ngFor="let state of statesData" [value]="state?.StateCode">{{ state?.StateName }}</option>
            </select>
          </div>
      
          <div class="col-6 my-2">
            <label>City<span class="text-danger">*</span>:</label>
          </div>
          <div class="col-6 my-2">
            <input type="text" formControlName="city" class="form-control" >
          </div>
      
          <div class="col-6 my-2">
            <label>Zip<span class="text-danger">*</span>:</label>
          </div>
          <div class="col-6 my-2">
            <input type="text" formControlName="zipCode" class="form-control" >
          </div>
      
          <div class="col-12 text-center my-2">
            <button class="btn btn-primary"  [disabled]="!wfcFormData.valid"  (click)="onSubmitWfc()" type="button">Add WFC</button>
          </div>
        </div>
      </form>
      <ng-container *ngIf="modalLoader">
        <app-loader [isChildLoader]="true"></app-loader>
      </ng-container>
    </div>
  </div>
</ng-template>


<div class="container mt-3" *ngIf="!isPlanSkeleton" @fadeInOut>
  <form>
    <div class="row">
      <!-- Roaming Toggle Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <label class="font-weight-bold">Enable / Disable Roaming</label>
              <div class="form-check form-switch">
                <input class="form-check-input" (click)="onUpdateRoaming()" type="checkbox" [checked]="networkInfoDetails?.settings?.roamControl?.enabled">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Data Saver Toggle Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <label class="font-weight-bold">Enable / Disable Data Saver</label>
              <div class="form-check form-switch">
                <input class="form-check-input" (click)="onUpdateDataSaver()" type="checkbox" [checked]="networkInfoDetails?.settings?.dataSaver?.enabled">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete Voice Mail Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-danger mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <label class="font-weight-bold" data-toggle="tooltip" data-placement="top" title="This feature will reset the voicemail. The activity will reset the password to default." style="cursor: pointer;">
                Delete Voice Mail
              </label>
              <button type="button" (click)="onDeleteVoiceMail()" class="btn btn-danger">Delete</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Data Throttle Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <label class="font-weight-bold">Throttle Addition Feature</label>
              <button type="button" (click)="openModal(addDataThrottleModal, 'throttle')" class="btn btn-primary">Add Data Throttle</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Wifi Calling Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <label class="font-weight-bold">Add Wifi Calling (WFC)</label>
              <div class="font-success">
                <div class="media text-end icon-state">
                  <label class="switch">
                   
                    <input type="checkbox" checked="" name="wifiCalling" [(ngModel)]="wifiCallingStatus"
                    (change)="wifiCallingToggleChange($event)"><span class="switch-state bg-primary"
                   [ngClass]="{'bg-primary': wifiCallingStatus, 'bg-secondary': !wifiCallingStatus}"></span>


          
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Network Reset Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <label class="font-weight-bold">Network Reset</label>
            <div class="d-flex justify-content-between">
              <input type="text" readonly [ngModelOptions]="{standalone: true}"  [(ngModel)]="networkReset" 
              class="form-control w-75">
              <button type="button" (click)="onNetworkReset()" class="btn btn-primary">Submit</button>

           
            </div>
          </div>
        </div>
      </div>

      <!-- Query HLR Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <label class="font-weight-bold">Query HLR</label>
            <div class="d-flex justify-content-between">
              <input type="text" class="form-control w-75">
              <button type="button" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Cancel Device Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <label class="font-weight-bold">Cancel Device</label>
            <div class="d-flex justify-content-between">
              <input type="text" class="form-control w-75">
              <button type="button" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Reset Voicemail Password Card -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <label class="font-weight-bold">Reset Voicemail Password</label>
            <div class="d-flex justify-content-between">
              <input type="text" class="form-control w-75">
              <button type="button" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Load Features Button -->
      <div class="col-md-6">
        <div class="card shadow-sm border-0 mb-4">
          <div class="card-body">
            <label class="font-weight-bold">Features:</label><br>
            <button type="button" (click)="openModal(networkInfoModal, '')" class="btn btn-primary">Load Features</button>
          </div>
        </div>
      </div>

      <!-- Remove Wifi Calling Card -->
      <div class="col-md-12">
        <div class="card shadow-sm border-danger mb-4">
          <div class="card-body text-center">
            <h5 class="text-danger">Remove Wifi Calling (WFC)</h5>
            <button type="button" class="btn btn-danger mt-2">Remove WFC</button>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Renew Current Plan Card -->
        <div class="col-md-6">
          <div class="card shadow-sm border-danger mb-4">
            <div class="card-body text-center">
              <h5 class="text-dark">Renew Current Plan</h5>
              <button type="button" class="btn btn-outline-primary mt-2" (click)="onSubmitRenewPlan()">Submit</button>
            </div>
          </div>
        </div>
      
        <!-- Add Product Card -->
        <div class="col-md-6">
          <div class="card shadow-sm border-danger mb-4">
            <div class="card-body text-center">
              <h5 class="text-dark">Add Product</h5>
              <button type="button" class="btn btn-outline-primary mt-2" (click)="onAddProduct()">Submit</button>
            </div>
          </div>
        </div>
      </div>
        
     
    </div>
  </form>
</div>

<!-- Other cards like Renew Plan, Add Product, and Modals can follow similar card-based layout -->

<div *ngIf="isPlanSkeleton"  style="max-width: 100%;" @fadeInOut>
  <app-skeleton-loader [numberOfCards]="10" shape="plan-card"></app-skeleton-loader>
</div>
