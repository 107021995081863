import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { COMPANYDB, CompanyDB } from 'src/app/shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';

@Component({
  selector: 'app-linked-topup',
  templateUrl: './linked-topup.component.html',
  styleUrls: ['./linked-topup.component.scss']
})
export class LinkedTopupComponent {
  public selected = [];

  public tableItem$: Observable<CompanyDB[]>;
  public searchText;
  total$: Observable<number>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  showTop:boolean = false;
  constructor(public service: TableService) {

 /*    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(COMPANYDB) */

  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }
  toggleTopup(){
    this.showTop = !this.showTop;
  }
}
