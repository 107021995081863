import { Component } from '@angular/core';

@Component({
  selector: 'app-other-info-account',
  templateUrl: './other-info-account.component.html',
  styleUrls: ['./other-info-account.component.scss']
})
export class OtherInfoAccountComponent {

}
