<div class="container mt-0 mb-4" style="max-width: 100%;">
  <div class="card form-card shadow mb-3 mt-0" style=" max-height: 80vh;">

    <div class="p-2 bg-primary container-fluid  br-5">
      <div class=" d-flex align-items-center flex-column-mobile  justify-content-between">
        <!-- Title Column -->
        <div class=" d-flex justify-content-center justify-content-md-start mb-2 mb-md-0 ">
          <h5 class="card-title mb-0" style="font-size: 1.5rem;letter-spacing: 2px;">LINE INFORMATION</h5>
        </div>

        <!-- Status Container Column -->
        <div class=" d-flex align-items-center justify-content-center p-1">
          <!-- <div class="card "> -->
          <div class="subscriber-status d-flex align-items-center justify-content-md-end mb-2 mb-md-0 p-2 bg-light"
            style="font-size: 1rem;border-radius: 10px;">
            <b class="mr-1 text-muted" style="white-space: nowrap;">Subscriber Status:</b>
            <span [ngClass]="{
                'badge-light-success': customerMoreLineInfo?.['Subscriber Status'] === 'Active',
                'badge-light-danger': customerMoreLineInfo?.['Subscriber Status'] === 'Cancelled',
                'badge-light-warning': customerMoreLineInfo?.['Subscriber Status'] === 'Scheduled'
              }" class="text-dark">
              {{ customerMoreLineInfo?.['Subscriber Status'] || '-' }}
            </span>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>



    <div class="card-body" *ngIf="!isSkeletonLoader" @fadeInOut>
      <ng-container *ngFor="let row of formFields; let i = index">
        <div *ngIf="i % 2 === 0" class="row mb-3">

          <div class="col-12 col-md-6" *ngIf="row.label=='SLOs'">
            <div class="row">
              <div class="col-12 col-md-4 d-flex align-items-center" >
                <label for="{{formFields[i].controlName}}" class="form-label mb-0"
                  style="font-size: 1rem; font-weight: 500; white-space: normal;">
                  {{ formFields[i].label }}
                </label>
              </div>
              <div class="col-12 col-md-8 d-flex gap-2 align-items-center" style="flex-wrap: wrap;">
                <span *ngFor="let item of formFields[i].value" class="custom-badge">{{ item || 'Not Available' }}</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6" *ngIf="formFields[i] && row.label!='SLOs' ">
            <div class="row">
              <div class="col-12 col-md-4 d-flex align-items-center">
                <label for="{{formFields[i].controlName}}" class="form-label mb-0"
                  style="font-size: 1rem; font-weight: 500; white-space: normal;">
                  {{ formFields[i].label }}
                </label>
              </div>
              <div class="col-12 col-md-8">
                <div class="value-display-container">
                  {{ formFields[i].value || 'Not Available' }}
                </div>
              </div>
            </div>
          </div>


          <div class="col-12 col-md-6" *ngIf="formFields[i + 1] && row.label!='SLOs'">
            <div class="row">
              <div class="col-12 col-md-4 d-flex align-items-center">
                <label for="{{formFields[i + 1].controlName}}" class="form-label mb-0"
                  style="font-size: 1rem; font-weight: 500; white-space: normal;">
                  {{ formFields[i + 1].label }}
                </label>
              </div>
              <div class="col-12 col-md-8">
                <div class="value-display-container">
                  {{ formFields[i + 1].value || 'Not Available' }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

      <!-- <div>
        <span>SLOs :</span>
        <div>
          <span *ngFor="let item of customerMoreLineInfo?.SLOs" class="badge-light">{{item}}</span>
        </div>
      </div> -->
    </div>
    <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [formColumnCount]="1" [formRowCount]="10" shape="single-column-form"></app-skeleton-loader>
    </div>
    <!-- <div class="card-footer bg-primary"></div> -->
  </div>
</div>


<!-- <div *ngIf="isLoading" class="card-loader">
  <app-loader [isChildLoader]="true"></app-loader>
</div> -->