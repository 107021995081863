import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ManageGroupPermissionService {
  userId: string;
  companyId:string;

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) {}


getUser() {
    return this.userDetails;
}

  getAllPermissions(id:any){
    let obj={
        companyId:"UNITYCRM0021",
        groupID:id
    }
    let url="api/system/resource-permission-detail"
    return this.http.get(url,obj)
  }


  updatePermission(id:any,body:any){
  console.log(id,body,"id body>>>>>>>>>>>>>>.")
  let url=`api/system/resource-permissions/update/${id}`
  return this.http.post(url,body)

  }

}
