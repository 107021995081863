import { Component, EventEmitter, Output } from '@angular/core';
import { RegisteredCard } from '../shared/interfaces/registered-cards.interface';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';

@Component({
  selector: 'app-customer-profile-view-right-panel',
  templateUrl: './customer-profile-view-right-panel.component.html',
  styleUrls: ['./customer-profile-view-right-panel.component.scss']
})
export class CustomerProfileViewRightPanelComponent {

  
  @Output() cardSelected = new EventEmitter<any>(); 
  cards: RegisteredCard[] = []; 
  selectedCard: RegisteredCard | null = null; 
  activeTab: string = '';
  currentComponent: any = null;
  constructor(private customerDetailedInformationService:CustomerDetailedInformationService){}
  ngOnInit(): void {
    this.cards = this.customerDetailedInformationService.getregisteredCard();
    this.customerDetailedInformationService.activeTab$.subscribe(tabName => {
      this.activeTab = tabName;
      const card = this.cards.find(c => c.title === tabName);
      if (card) {
        this.currentComponent = card.componentName;
      } else {
        this.currentComponent = null;
      }
    });
  }
  selectCard(card: any) {
    this.customerDetailedInformationService.setActiveTab(card.title);
  }
}
