import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent implements OnInit {

  @Input() isChildLoader:boolean = false;
  @Input() variant: 'loader-25' | 'loader-16' | 'loader-12' = 'loader-25';
  @Input() class:string = '';

  constructor(public loader: LoaderService) {
  }

  ngOnInit() { 
    // console.log('object',this.loader.isLoading$);
  }

  ngOnDestroy() { }

}
