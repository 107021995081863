import { Component } from '@angular/core';

@Component({
  selector: 'app-money-order-plan-change',
  templateUrl: './money-order-plan-change.component.html',
  styleUrls: ['./money-order-plan-change.component.scss']
})
export class MoneyOrderPlanChangeComponent {

}
