<div class="msg-box  pb-2 mt-3" *ngIf="!fullScreen">
    <h5 class="text-white">Personal Information</h5>
    <div style="display: flex; align-items: center; margin-bottom: 0.5rem;"><img alt="CEO image"
            style="width: 35px; height: 35px; border-radius: 50%; margin-right: 10px; transition: 0.4s ease;"
            src="https://cdn-icons-png.freepik.com/256/1077/1077114.png?semt=ais_hybrid">
        <p style="margin-bottom: 0px; line-height: 1.2; width: 65%;">
            <span
                style="font-size: 14px; color: white; font-weight: 500; white-space: nowrap;">{{minimalProfileData?.personalInfo?.name?.value}}
            </span>
            <br>
            <span style="font-size: 11px; color: white; font-weight: 400;">
                {{minimalProfileData?.personalInfo?.email?.value}}
            </span>
        </p>
    </div>
    <p style="margin-bottom: 0px; color: white; line-height: 1.2; font-size: 12px; font-weight: 300;"
        class="d-flex flex-column gap-2">
    <div class="d-flex justify-content-between mb-2">
        <span>Alternate Number :</span>
        <span>
            {{minimalProfileData?.personalInfo?.phone?.value}}
        </span>
    </div>
    <div class="d-flex justify-content-between">
        <span>Account Password :</span>
        <span>
            {{minimalProfileData?.personalInfo?.accountPassword
            ?.value}}
        </span>
    </div>
    <div class="d-flex gap-2  mb-2">
        <span>
            <span class="text-nowrap">Service Address (Permanent) :</span>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.address?.value?.address1">
                {{minimalProfileData?.personalInfo?.address?.value?.address1}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.address?.value?.address2">
                {{minimalProfileData?.personalInfo?.address?.value?.address2}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.address?.value?.city">
                {{minimalProfileData?.personalInfo?.address?.value?.city}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.address?.value?.state">
                {{minimalProfileData?.personalInfo?.address?.value?.state}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.address?.value?.zipCode">
                {{minimalProfileData?.personalInfo?.address?.value?.zipCode}}
            </ng-container>
        </span>
    </div>
    <div class="d-flex gap-2  mb-2">
        <span >
            <span class="text-nowrap">Shipping Address :</span>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.mailingAddress?.value?.address1">
                {{minimalProfileData?.personalInfo?.mailingAddress?.value?.address1}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.mailingAddress?.value?.address2">
                {{minimalProfileData?.personalInfo?.mailingAddress?.value?.address2}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.mailingAddress?.value?.city">
                {{minimalProfileData?.personalInfo?.mailingAddress?.value?.city}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.mailingAddress?.value?.state">
                {{minimalProfileData?.personalInfo?.mailingAddress?.value?.state}},
            </ng-container>
            <ng-container *ngIf="minimalProfileData?.personalInfo?.mailingAddress?.value?.zipCode">
                {{minimalProfileData?.personalInfo?.mailingAddress?.value?.zipCode}}
            </ng-container>
        </span>
    </div>
   


</div>

<div *ngIf="fullScreen">
    <div class="card-form">
        <h2>Personal Information</h2>
        <form [formGroup]="personalInfoFormData">
            <div class="row">
                <div class="form-group col-12">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" formControlName="firstName" placeholder="Enter first name">
                </div>
                <div class="form-group col-12">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" formControlName="lastName" placeholder="Enter last name">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="email">Email</label>
                    <input type="email" id="email" formControlName="email" placeholder="Enter email">
                </div>
                <div class="form-group col-12">
                    <label for="alternateContact">Alternate Contact</label>
                    <input type="text" id="alternateContact" formControlName="phone"
                        placeholder="Enter alternate contact">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="dob">Date of Birth</label>
                    <input type="date" formControlName="dateOfBirth" id="dob">
                </div>
                <div class="form-group col-12">
                    <label for="ssn">SSN</label>
                    <input type="text" id="ssn" formControlName="ssn" placeholder="Enter SSN">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="serviceAddress">Service Address</label>
                    <input type="text" id="serviceAddress" formControlName="serviceAddress"
                        placeholder="Enter service address">
                </div>
                <div class="form-group col-12">
                    <label for="billingAddress">Billing Address</label>
                    <input type="text" id="billingAddress" formControlName="mailingAddress"
                        placeholder="Enter billing address">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="accountStatus">Account Status</label>
                    <input type="text" id="accountStatus" formControlName="accountStatus"
                        placeholder="Enter account status">
                </div>
                <div class="form-group col-12">
                    <label for="accountPassword">Account Password</label>
                    <input type="password" id="accountPassword" formControlName="accountPassword"
                        placeholder="Enter account password">
                </div>
            </div>

        </form>
    </div>


</div>