import { Component, OnInit } from '@angular/core';
import { TicketsService } from './services/tickets.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketSystemService } from 'src/app/ticket-system/services/ticket-system.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  bsRangeValue: any;
  isSidebarOpen: boolean = false
  data: any;
  public startDate: Date;
  public endDate: Date;
  public searchQuery: string;
  public ticketId: any = null;
  public allGroups: any = []
  public allAgents: any = []
  public ticketTypes: any = []
  public ticketStatusList: any = []
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public selectedSource: any = "";
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];
  isEditing: boolean = false
  customerId: any;
isSkeletonLoader: boolean=true;

  headers = [
    { display: 'Ticket Id', key: 'id' },
    { display: 'Name', key: 'Title' },
    { display: 'Email', key: 'Missing' }, // Email data seems missing, placeholder added
    { display: 'Subject', key: 'Description' },
    { display: 'Category', key: 'category' },
    { display: 'Status', key: 'status' },
    { display: 'Priority', key: 'priority' },
    { display: 'Created Date', key: 'CreatedDate' },
    { display: 'Created By', key: 'CreatedBy' },
    { display: 'Updated Date', key: 'UpdatedDate' },
    { display: 'Updated By', key: 'UpdatedBy' },
    { display: 'Closed Date', key: 'ClosedDate' },
    { display: 'Escalation Level', key: 'EscalationLevel' },
    { display: 'Linked Tickets', key: 'LinkedTickets' },
    { display: 'Callback Date', key: 'CallbackDate' },
    { display: 'Callback Time', key: 'CallbackTime' },
  ]
  isShowDateRange: boolean;
  isShowSearch: boolean;
  searchSubject: Subject<string> = new Subject<string>();
  constructor(
    public TicketsService: TicketsService,
    public ticketSystemService: TicketSystemService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private http: HttpService
  ) {
    this.customerId = this.http.getCustomerId()
    this.searchSubject.pipe(
      debounceTime(300), // Wait 300ms after the last keystroke before hitting the API
      distinctUntilChanged() // Only hit the API if the value actually changed
    ).subscribe(query => {
      this.getData()
    });
  }

  ngOnInit() {
    this.getData()
    this.getTicketTypes()
    this.getAgents()
  }

  async getTicketTypes() {
    try {
      const res: any = await this.ticketSystemService.getTicketTypes()
      if (res.Status) {
        this.ticketTypes = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async getData() {

    try {
      let data = {
        "fromDate": this.startDate,
        "toDate": this.endDate,
        "query": this.searchQuery,
        "page": this.currentPage,
        "per_page": this.itemsPerPage,
      }
      if (!this.startDate || this.startDate == undefined) {
        delete data['fromDate']
      }
      if (!this.endDate || this.startDate == undefined) {
        delete data['toDate']
      }
      if (!this.searchQuery) {
        delete data['query']
      }
      const res: any = await this.TicketsService.getTickets(data)
      if (res.Status) {
        this.data = res?.data
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast('error', "Error", res.message)
        this.isSkeletonLoader=false
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.isSkeletonLoader=false
    }

  }

  clearDateFilter() {
    this.endDate = null;
    this.startDate = null;
    this.searchQuery = ""
    this.getData()
  }

  openModal(ticketId?) {
    console.log(ticketId);
    if (ticketId) {
      this.ticketId = ticketId
      this.isEditing = true
      this.isSidebarOpen = true
    }
    // this.modalService.open(content, { backdropClass: "dark-modal", size: 'lg', centered: true });
  }

  commentEvent(event) {
    this.modalService.dismissAll()
  }

  pageChange(event) {
    this.currentPage = event
    this.getData()
  }

  onChangeNumberOfPage() {
    this.getData()
  }



  addEditTicketEvent(event) {
    this.modalService.dismissAll()
    this.ticketId = null
    this.getData()
  }

  async getAgents() {
    try {
      const res: any = await this.ticketSystemService.getAgents()
      if (res.Status) {
        this.allAgents = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  addTicket() {
    this.isSidebarOpen = true
  }

  closeSidebar() {
    this.isSidebarOpen = false
    this.ticketId = null
    this.isEditing = false
  }

  saveTicket(e) {

  }

  toggleSearch() {
    this.isShowSearch = !this.isShowSearch
  }

  toggleDateRange() {
    this.isShowDateRange = !this.isShowDateRange
  }

  stop(event: Event) {
    event.stopPropagation();
  }

  dataChanged() {
    this.startDate = this.bsRangeValue[0].toISOString().split('T')[0]
    this.endDate = this.bsRangeValue[1].toISOString().split('T')[0]
    if (this.startDate && this.endDate) {
      this.getData()
    }
  }

  // Called on every input change
  onSearchInput(query: string) {
    this.searchSubject.next(query); // Emit the query to the subject
  }


}
