
<div class="skeleton-loader">
    <!-- Left Section (Pie Chart and Date) -->
    <div class="skeleton-left">
      <div class="skeleton-chart"></div>
      <div class="skeleton-date"></div>
      <div class="skeleton-date"></div>
      <div class="skeleton-date"></div>
      <div class="skeleton-date"></div>
    </div>
  
    <!-- Right Section (Transaction List) -->
    <div class="skeleton-right">
      <!-- Tabs -->
      <div class="skeleton-tabs">
        <div class="skeleton-tab"></div>
        <div class="skeleton-tab"></div>
        <div class="skeleton-tab"></div>
      </div>
      <!-- Search Bar -->
      <div class="skeleton-search"></div>
      <!-- Card Placeholder -->
      <div class="skeleton-card"></div>
      <div class="skeleton-card"></div>
      <div class="skeleton-card"></div>
      <div class="skeleton-card"></div>
      <div class="skeleton-card"></div>
      <div class="skeleton-card"></div>
      <div class="skeleton-card"></div>



    </div>
  </div>
  