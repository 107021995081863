import { Component } from '@angular/core';

@Component({
  selector: 'app-money-gram-plan-change',
  templateUrl: './money-gram-plan-change.component.html',
  styleUrls: ['./money-gram-plan-change.component.scss']
})
export class MoneyGramPlanChangeComponent {

}
