import { Component } from '@angular/core';

@Component({
  selector: 'app-wallet-add-data',
  templateUrl: './wallet-add-data.component.html',
  styleUrls: ['./wallet-add-data.component.scss']
})
export class WalletAddDataComponent {

}
