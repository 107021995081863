<!-- <div class="d-flex justify-content-between">
  <p class="f-18 cursor-pointer" (click)="commonSettingService.moreComboItemSelectedTab('MANAGE_COMBOS_ITEMS')"><i
      class="fa-sharp fa-solid fa-window-restore "></i> &nbsp;&nbsp;{{manageComboItemsDetails?.DisplayName}}</p>
</div> -->

<div class="customizer-contai" 
>
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="d-flex">
      <h4 class="mb-0 text-nowrap col-md-2" (click)="back()" style="cursor: pointer;"> <i
          class="fa-solid fa-arrow-left mt-1 mx-2"></i>List of Combos Items</h4>
      <div class="col-md-10">
        <div class="d-flex justify-content-end my-2">
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Search" (keyup)="filterdData($event)">
          </div>
          <div class="">
            <button class="btn btn-primary"
              (click)="commonSettingService.moreComboItemSelectedTab1('Line Information')">+
              Add</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="table-responsive marginB2" style="height: 500px;">
          <table class="table project-list-table table-nowrap align-middle table-borderless" *ngIf="!isSkeletonLoader" @fadeInOut>
            <thead>
              <tr>
                <th scope="col">Product Item</th>
                <th scope="col">Product Combo</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Display Price</th>
                <th scope="col">Is Active</th>
                <th scope="col">Is Deleted</th>
                <th scope="col">Image Path</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of comboItemList">
                <td>{{ item.ProductComboName }}</td>
                <td>{{ item.DeviceMakeModel }}</td>
                <td>{{ item.Quantity }}</td>
                <td>{{ item.Price }}</td>
                <td>{{ item.DisplayPrice }}</td>
                <td>{{ item.IsActive }}</td>
                <td>{{ item.IsDeleted }}</td>
                <td>
                  <a *ngIf="item.ImagePath" (click)="openImage(item.ImagePath)" class="text-primary" style="cursor: pointer;">Yes</a>
                  <span *ngIf="!item.ImagePath">No</span>
                </td>
                <td>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a (click)="editOpen(item)" class="text-primary" title="Edit">
                        <i class="fa-solid fa-pen-to-square"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="text-danger" title="Delete">
                        <i class="fa-solid fa-trash"></i>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
    
          <!-- Skeleton Loader Placeholder -->
          <div *ngIf="isSkeletonLoader" @fadeInOut class="d-flex flex-column gap-4" style="max-width: 100%;">
            <app-skeleton-loader [tableColumn]="8" [tableRow]="8" shape="table"></app-skeleton-loader>
          </div>
    
          <!-- Pagination and Page Size Selection -->
          <div class="pull-right p-20 d-flex">
            <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage" (change)="onChangeNumberOfPage()">
              <option *ngFor="let page of pageDropdownMenu" [value]="page">{{ page }}</option>
            </select>
            <ngb-pagination 
              [maxSize]="10" 
              [directionLinks]="true" 
              [boundaryLinks]="true" 
              [collectionSize]="totalItems" 
              [(page)]="currentPage" 
              (pageChange)="pageChange($event)" 
              [pageSize]="itemsPerPage">
              <ng-template ngbPaginationPrevious>Previous</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
          </div>
        </div>
        <br><br>
      </div>
    </div>
    

  </div>


  <div class="customizer-contain " [ngClass]="{ open: commonSettingService.moreComboItemLevel1 == true }"
    [ngStyle]="{ 'width': commonSettingService.popup2WidthComboItem}">
    <div class="tab-content" id="c-pills-tabContentAgent">
      <div class="customizer-header">
        <h5 class="mb-0" *ngIf="!product_combo_item_id">Add New Combo Item</h5>
        <h5 class="mb-0" *ngIf="product_combo_item_id">Edit Combo Item</h5>
        <i class="icofont-close icon-close" (click)="close()"></i>
      </div>
      <div class="customizer-body custom-scrollbar">
        <div class="contain m-3">
          <form [formGroup]="addComboItemForm">
            <div class="card p-3" style="height: 70vh; overflow-y: auto;">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mt-2">
                      <label>Device Make Model*</label>

                      <ng-select (change)="onChangeDeviceMakeModel($event)" formControlName="ProductItemID"
                        [multiple]="false" required>
                        <ng-option *ngFor="let item of deviceMakeModelList"
                          [value]="item.id">{{item.Make}}-{{item.Model}}
                          ({{item.ModelNumber}}), {{item.Price}}
                          {{item.DeviceType}}</ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group mt-2">
                      <label>Product Combo ID*</label>
                      <ng-select formControlName="ProductComboID" [multiple]="false" required>
                        <ng-option *ngFor="let item of comboList" [value]="item.id">{{item?.Name}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mt-2">
                      <label>Quantity*</label>
                      <input type="number" formControlName="Quantity" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mt-2">
                      <label>Price*</label>
                      <input type="number" formControlName="Price" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mt-2">
                      <label>Display Price*</label>
                      <div class="d-flex display-div">
                        <input type="number" formControlName="DisplayPrice" class="form-control">
                        <div class="child">
                          $
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-6 my-auto ">
                    <div class="form-group mt-2 d-flex align-items-center ">
                      <label>IsActive*</label>
                      <input type="checkbox" formControlName="IsActive" class="ms-2">
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12 my-2">
                          <label>Upload Files*</label><br>
                          <ngx-dropzone (change)="onFileChange($event)">
                            <ngx-dropzone-label>
                              <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                                <h6>Drop files here or click to upload.</h6>
                              </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngIf="uploadedFiles" [removable]="true"
                              (removed)="removeFile(uploadedFiles)">
                              <ngx-dropzone-label>{{ uploadedFiles.name }} ({{ uploadedFiles.type
                                }})</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                          </ngx-dropzone>
                        </div>
                        <div *ngIf="imagePath" class="col-md-6">
                          <img [src]="imagePath" width="200px">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 my-2 text-center" *ngIf="addComboItemForm.invalid && showErrorMsg">
                    <p class="text-danger">Please fill required fields.</p>
                  </div>
                  <div class="col-md-12 text-center my-3">
                    <button type="submit" (click)="submit()" *ngIf="!product_combo_item_id"
                      class="btn btn-primary">Next</button>
                    <button type="submit" (click)="update()" *ngIf="product_combo_item_id"
                      class="btn btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>


      </div>
    </div>
  </div>