import { Component } from '@angular/core';

@Component({
  selector: 'app-money-order-add-data',
  templateUrl: './money-order-add-data.component.html',
  styleUrls: ['./money-order-add-data.component.scss']
})
export class MoneyOrderAddDataComponent {

}
