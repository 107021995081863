import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';
import { SubscriptionService } from 'src/app/subscriptions/service/subscription.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardUpdateComponent } from 'src/app/subscriptions/credit-card-update/credit-card-update.component';
import { LoaderService } from 'src/app/shared/services/loader.service';
import Swal from 'sweetalert2';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-subscription-information',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss'],
  animations:[fadeInOut]
})
export class SubscriptionInformationComponent implements OnInit {


  subscriptionMoreInformationData:any
  
formFields: any[] = [];
showHistoryDetail: any;
  subscriptionDetail: any;
  showHistoryPage: boolean;
  paymentGeneratedLink: any;
  modalRef: NgbModalRef | undefined;
  isPaymentGenerated: boolean;
  defaultPaymentLink: string = 'https://unitywireless.com';
  customerSubscriptionData: any;
  autoRenew: boolean;
isLoading: any;
isSkeletonLoader: boolean=true;

  constructor(
    private customerProfileService:CustomerProfileService,
    private httpService:HttpService,
    private commonService:CommonService,
    private customerDetailedInformationService:CustomerDetailedInformationService,
    private subscriptionService: SubscriptionService,
    private cd:ChangeDetectorRef,
    private modalService: NgbModal,
    private elementRef: ElementRef, 
    private renderer: Renderer2,private loaderService:LoaderService
  ){

  }

  ngOnInit(): void {
    this.getCustomerSubscriptionInformation()
  }
planFields:any
  async getCustomerSubscriptionInformation(){
    try {
      this.isLoading=true
      const response:any = await this.customerProfileService.getCustomerSubscription(this.httpService.getCustomerId(),true);
      if(response?.Status){
        this.customerSubscriptionData=response?.data
        this.subscriptionMoreInformationData= await this.formatSubscriptionInfo(response?.data);
    this.initializeFields();

       if (response?.data?.plan) {
        this.subscriptionMoreInformationData.plan = await this.formatSubscriptionPlanInfo(response?.data.plan);
      }
      delete this.subscriptionMoreInformationData?.Plan
      this.initializeFields()
      this.planFields=this.subscriptionMoreInformationData?.plan;
      this.isLoading=false
      }else{
        this.isLoading=false
      }
      this.isSkeletonLoader=false
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
      this.isLoading=false
    }
  }

  formatSubscriptionInfo(subscriptionInfo:any){
    const formattedInfo: any = {};
    for (const key in subscriptionInfo) {
      if (subscriptionInfo.hasOwnProperty(key)) {
        const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
        const formattedValue = this.customerDetailedInformationService.formatValue(subscriptionInfo[key]);
        formattedInfo[formattedKey] = formattedValue;

      }
    }
    return formattedInfo;
  }

  formatSubscriptionPlanInfo(planInfo: any) {
    const formattedPlan: any = {};
  
    if (Array.isArray(planInfo)) {
      formattedPlan.plans = planInfo.map(plan => {
        const formattedPlanItem: any = {};
  
        for (const key in plan) {
          if (plan.hasOwnProperty(key)) {
            const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
            const formattedValue = this.customerDetailedInformationService.formatValue(plan[key]);
            formattedPlanItem[formattedKey] = formattedValue;
          }
        }
        return formattedPlanItem;
      });
    } else {
      for (const key in planInfo) {
        if (planInfo.hasOwnProperty(key)) {
          const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
          const formattedValue = this.customerDetailedInformationService.formatValue(planInfo[key]);
          formattedPlan[formattedKey] = formattedValue;
        }
      }
    }
    return formattedPlan;
  }
  initializeFields() {
    const personalAttributes = ['First Name', 'Last Name','Email Address', 'Customer Id','Profile Id','Payment Profile Id'];
    const dateAttributes = ['Start Date', 'Next Billing Date', 'End Date','Created Date','Updated Date'];
    const subscriptionAttributes = ['Subscription Id', 'Subscription Type Id', 'Status','Remarks','Imei','Auto Renew','Enrollment Id'];
    const autoRenewAttribute=['Auto Renew']
    const planAttributes = ['plan']; 
    const remainingAttributes = [];
    const fields: any[] = [];
    personalAttributes.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        let value = this.subscriptionMoreInformationData[attr] || 'Not Available';
       
  
        fields.push({ controlName: attr, label: attr, value });
      }
    });
    autoRenewAttribute.forEach(attr=>{
      if (this.subscriptionMoreInformationData[attr]) {
        const value=this.subscriptionMoreInformationData[attr]?'Yes':'No';
        fields.push({ controlName: attr, label: attr, value });
      }
    })
    dateAttributes.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        const value = this.customerDetailedInformationService.formatDate(this.subscriptionMoreInformationData[attr]) || 'Not Available';
        fields.push({ controlName: attr, label: attr, value });
      }
    });
  
    subscriptionAttributes.forEach(attr => {
      if (this.subscriptionMoreInformationData[attr]) {
        fields.push({ controlName: attr, label: attr, value: this.subscriptionMoreInformationData[attr] });
      }
    });

    
    for (const key in this.subscriptionMoreInformationData) {
      if (
        this.subscriptionMoreInformationData.hasOwnProperty(key) &&
        !personalAttributes.includes(key) &&
        !autoRenewAttribute.includes(key) &&
        !dateAttributes.includes(key) &&
        !subscriptionAttributes.includes(key) &&
        !planAttributes.includes(key)
      ) {
        remainingAttributes.push({ controlName: key, label: key, value: this.subscriptionMoreInformationData[key] });
      }
    }
    this.formFields = [...fields, ...remainingAttributes];
    
  }

  async openHistoryPage(data: any) {
    this.showHistoryDetail = data;
    await this.getSubscriptionDetail()
    setTimeout(() => {
      this.showHistoryPage = true
    }, 40);
  }

  onPaymentHistoryClose(show: any) {
    this.showHistoryPage = show;
  }

  updatePaymentMethod(justUpdateAddress?: boolean) {
    const modalRef = this.modalService.open(CreditCardUpdateComponent, { backdropClass: "dark-modal", centered: true });
    modalRef.componentInstance.subscriptionDetail = this.subscriptionDetail;
    modalRef.componentInstance.justUpdateAddress = justUpdateAddress;
  }

  async getSubscriptionDetail() {
    try {
      
      await this.subscriptionService.getDetail(this.subscriptionMoreInformationData?.Id).subscribe(
        (res: any) => {
          this.subscriptionDetail = res?.data;
          this.cd.detectChanges()
          
        },
        (error: any) => {
           this.commonService.showToast('error', "Error", 'An error occurred while getting the subscription details.');
        }
      );
    } catch (error) {
      console.error("Exception caught:", error?.message);
      
    }
  }
  withConfirmation() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to cancel this subscription?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
      customClass: {
        popup: 'swal2-custom-width'
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.cancelsubscription()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log('Cancelled');
        this.autoRenew = true
      }

    })
  }

  async cancelsubscription() {
    try {
      this.subscriptionService.cancelSubscription(this.customerSubscriptionData, 'Cancel Subscription via the Auto-Renewal Toggle in the Customer Profile.').subscribe(
        (res: any) => {
          if (res?.Status) {
            this.autoRenew = false;
            this.customerDetailedInformationService.setAutoRenewStatus(false)
            this.commonService.showToast('success', "sucess", res?.data);
            this.getCustomerSubscriptionInformation();
            this.customerProfileService.getMinimalProfileData();
          }
        },
        (error: any) => {
          this.autoRenew = true
          this.commonService.showToast('error', "Error", 'An error occurred while cancelling the subscription.');
        }
      );
    } catch (err) {
      this.autoRenew = true
      this.commonService.showToast('error', "Error", 'An unexpected error occurred.');
    }
  }
  openModal(): void {
    const modalBackdrop = this.elementRef.nativeElement.querySelector('.modal');
    if (modalBackdrop) {
      this.renderer.setStyle(modalBackdrop, '--bs-backdrop-zindex', '0');
    }
  }
   async generatePaymentLink(content: TemplateRef<any>){
    try {
    this.customerProfileService.generatePaymentLink(this.httpService.getCustomerId()).subscribe(
      (response: any) => {
        this.paymentGeneratedLink=response
        // this.paymentGeneratedLink=true
        this.modalRef = this.modalService.open(content, { backdrop: 'static', centered: true });
      this.commonService.showToast('info', 'Info', 'Payment link generated');

      },
      (error) => {
        this.isPaymentGenerated=false
      this.commonService.showToast('error', 'Error', `Unable to generate payment link`);
      }
    );

    } catch (error) {
      console.log(error);
    }
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  copyToClipboard() {
    const linkElement = document.getElementById('linkInput');
    const linkText = linkElement?.textContent?.trim();

    if (linkText) {
      navigator.clipboard.writeText(linkText).then(() => {
        this.commonService.showToast('info', 'Info', 'Link Copied');
      }).catch(err => {
        this.commonService.showToast('error', 'Error', `Link Can't be Copied`);
      });
    } else {
      this.commonService.showToast('error', 'Error', 'No Link to Copy');
    }
  }

  openInNewWindow() {
    const url = this.paymentGeneratedLink;
    window.open(url, '_blank');
  }
  openInSameWindow() {
    const url = this.paymentGeneratedLink;
    window.location.href = url;
  }
  
}
