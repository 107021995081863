import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import tableData from '../../mocks/review';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {



  constructor(
    public commonService: CommonService,
  ) { }

  ngOnInit() {
  this.setBreadCrumb()
  }

  setBreadCrumb() {
    this.commonService.setbreadCrumb({
      "title": "Profile",
      "active_item": "Profile"
    })
  }



}
