import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-animated-skeleton-rows',
  templateUrl: './animated-skeleton-rows.component.html',
  styleUrls: ['./animated-skeleton-rows.component.scss']
})
export class AnimatedSkeletonRowsComponent implements OnInit{

  @Input() rowCount: number = 5; // Default to 5 rows
  rows: number[] = [];

  ngOnInit(): void {
    this.rows = Array(this.rowCount).fill(0);
  }
}
