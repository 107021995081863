<div class="msg-box  pb-2  mt-3" *ngIf="!fullScreen">
    <h5 class="text-white">Subscription Information</h5>
    <p style="margin-bottom: 0px; color: white; line-height: 1.2; font-size: 12px; font-weight: 300;"
        class="d-flex flex-column gap-2">
        <div class="d-flex justify-content-between mb-2">
            <span>Subscription Status :</span>
            <span>
                {{minimalProfileData?.subscription[0]?.Status
                || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span> Auto Renew :</span>
            <span class="d-flex align-items-center gap-3 ">
                <div class="media">
                    <div class=" text-end icon-state">
                        <label class="switch">
                            <input type="checkbox" checked="" [(ngModel)]="autoRenew" (change)="autoRenewSub($event)"><span
                                class="switch-state bg-primary" [ngClass]="{'bg-primary': autoRenew}"></span>
                        </label>
                    </div>
                </div>
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>SubscriptionId :</span>
            <span>
                {{minimalProfileData?.subscription[0]?.SubscriptionId
                || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Subscription Type:</span>
            <span>
                {{minimalProfileData?.subscription[0]?.SubscriptionType
                || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Start Date :</span>
            <span>
                {{
                minimalProfileData?.subscription[0]?.StartDate
                | date}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Profile Id :</span>
            <span>
                {{minimalProfileData?.subscription[0]?.ProfileId
                || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Payment Profile Id :</span>
            <span>
                {{minimalProfileData?.subscription[0]?.PaymentProfileId
                || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Created Date :</span>
            <span>
                {{minimalProfileData?.subscription[0]?.CreatedDate | date
                }}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Updated Date :</span>
            <span>
                {{minimalProfileData?.subscription[0]?.UpdatedDate | date
                }}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Renewal Date :</span>
            <span>
                {{
                minimalProfileData?.subscription[0]?.NextBillingDate | date
                }}
            </span>
        </div>
      
       
        
       

        
        
        
        
        
        
        
        

</div>


<div *ngIf="fullScreen">
    <div class="card-form">
        <div class="d-flex justify-content-between">

            <h2 class=" text-white">Subscription Information</h2>
            <div class="d-flex flex-column flex-lg-row align-items-center">
                <!-- First set of buttons -->
                <div class="d-flex flex-column flex-lg-row align-items-center mb-3 mb-lg-0">
                    <div class="d-flex justify-content-center flex-wrap">
                        <button class="btn text-light bg-transparent border-0 m-2" style="transition: color 0.3s;"
                            onmouseover="this.querySelector('i').style.color='#17a2b8';"
                            onmouseout="this.querySelector('i').style.color='white';" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="View History" (click)="openHistoryPage('payment')">
                            <i class="fas fa-history fa-3x"></i> <!-- FontAwesome Icon -->
                        </button>
                        <button class="btn text-light bg-transparent border-0 m-2" style="transition: color 0.3s;"
                            onmouseover="this.querySelector('i').style.color='#17a2b8';"
                            onmouseout="this.querySelector('i').style.color='white';" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="View Invoice" (click)="openHistoryPage('invoice')">
                            <i class="fas fa-file-invoice fa-3x"></i> <!-- FontAwesome Icon -->
                        </button>
                    </div>
                </div>

                <!-- Second set of buttons -->
                <div class="d-flex flex-column flex-lg-row align-items-center">
                    <div class="d-flex justify-content-center flex-wrap">
                        <button class="btn text-light bg-transparent border-0 m-2" style="transition: color 0.3s;"
                            onmouseover="this.querySelector('i').style.color='red';"
                            onmouseout="this.querySelector('i').style.color='white';" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Add Payment Method" (click)="updatePaymentMethod()">
                            <i class="fas fa-credit-card fa-3x"></i> <!-- FontAwesome Icon -->
                        </button>
                        <button class="btn text-light bg-transparent border-0 m-2" style="transition: color 0.3s;"
                            onmouseover="this.querySelector('i').style.color='red';"
                            onmouseout="this.querySelector('i').style.color='white';" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Update Address Details" (click)="updatePaymentMethod(true)">
                            <i class="fas fa-map-marker-alt fa-3x"></i> <!-- FontAwesome Icon -->
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <ng-container *ngFor="let item of formFields">
                <div class="col-6" *ngIf="item.label != 'SLOs'">
                    <div class="d-flex justify-content-between  mb-3">
                        <span class="text-nowrap">
                            {{item.label}} :
                        </span>
                        <span>
                            {{item.value}}
                        </span>

                    </div>
                </div>
                <div class="col-12" *ngIf="item.label == 'SLOs'">
                    <div class="d-flex justify-content-between  mb-3">
                        <span class="text-nowrap me-3">
                            {{item.label}} :
                        </span>
                        <div class='d-flex gap-2 align-items-center' style="flex-wrap: wrap;">
                            <span *ngFor="let val of item.value" class="custome-badge "> {{val}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
        <div *ngIf="showHistoryPage && subscriptionDetail" class="history-invoice">
            <app-payment-history [subscriptionDetail]="subscriptionDetail"
                [displayPaymentHistory]="showHistoryDetail==='payment'? true :false"
                (closePaymentHistory)="onPaymentHistoryClose($event)"></app-payment-history>
        </div>
    </div>
</div>