import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})

export class ManageComboService {

  companyId: string;

  constructor(
    private http: HttpService,
  ) { }

  public getComboList(body?:any): Observable<HttpResponse<any>> {
    let url = "api/product/getProductCombo"
    return this.http.get(url,body);
  }

  public getComboTypes(): Observable<HttpResponse<any>> {
    let url = "api/product/comboTypes"
    return this.http.get(url);
  }

  public postNewCombo(reqPayload): Observable<HttpResponse<any>> {
    let url = "api/product/add-device-make-model"
    return this.http.post(url, reqPayload);
  }



  getApplicationAreas() {

    let url = "api/product/applicationAreas"
    return this.http.get(url, null, false, false)
  }
  getCreateProductCombo(body: any) {
    let url = "api/product/create-product-combo"
    return this.http.post(url, body, false, true)
  }
  updateProductCombo(id: any, body: any) {
    let url = `api/product/updateProductCombo/${id}`
    return this.http.put(url, body, false, false)
  }
  getDeviceMakeModelList() {

    let url = "api/product/getDeviceMakeModel"
    return this.http.get(url, null, false, false)
  }
  addProductComboItem(body: any) {
    let url = "api/product/add-product-combo-items"
    return this.http.post(url, body, false, true)
  }
  updateProductComboImage(body: any, id: any) {
    let url = `api/orders/update-product-combo`
    return this.http.post(url, body, true, false)
  }
 

  getProductComboById(id: any) {
    let url = `api/product/getProducComboByID/${id}`
    return this.http.get(url, null, false, true)
  }
  getProductComboItems(body: any) {
    let url = "api/product/getProductComboItems"
    return this.http.get(url, body, false, false)
  }

}
