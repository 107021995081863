import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from "@angular/core";
import { NavService, Menu } from "../../../../services/nav.service";
import { SearchService } from "src/app/shared/services/search.service";
import { Router } from "@angular/router";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { CommonService } from "src/app/shared/services/common.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  public menuItems: Menu[];
  public items: Menu[];

  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;
  public isShowAdvanceSearch: boolean = false;
  @Output() searchClosed = new EventEmitter<void>();

  constructor(
    public navServices: NavService,
    public searchService: SearchService,
    public router: Router,
    public customerProfileService: CustomerProfileService,
    private commonService: CommonService,
  ) {
    this.navServices.items.subscribe((menuItems) => (this.items = menuItems));
  }

  ngOnInit() { }

  searchToggle() {
    this.navServices.search = false;
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  }

  async searchTerm(event) {
    if (!this.text) {
      return
    }
    if (event.keyCode == 13) {
      let data = {
        "search": this.text,
        "page": "1"
      }
      try {
        
        this.customerProfileService.isShowAlert = false
        const res: any = await this.searchService.searchCustomer(data);
        const searchcount: any = await this.searchService.getAppSearchCount(this.text);
        
        if (res?.Status) {
          this.searchService.setSearchData(res.data);
          this.searchService.setSearchCount(searchcount.data);
          if (res.data.data.length > 1) {
            this.router.navigateByUrl("/search-result-page")
          } else {
            await this.customerProfileService.setCustomerId(res.data.data[0]?.CustomerID)
            this.customerProfileService.getMinimalProfileData()
            this.searchService.updateSearchTerm(res.data.data[0]?.CustomerID);
            this.router.navigateByUrl("/customer-dashboard/customer-profile")
          }
          this.removeFix()
          this.searchToggle()
        } else {
          this.commonService.showToast('error', "Error", res.message)

        }
      } catch (err) {
        this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
        
      }
    }
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true;
    else this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName("body")[0].classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  }

  toggleAdvanceSearch() {
    this.isShowAdvanceSearch = !this.isShowAdvanceSearch;
  }

  closeAdvanceSearch() {
    this.toggleAdvanceSearch();
    this.removeFix()
    this.searchToggle()
  }


  async onSubmitAdvanceSearch(event: any) {
    if (event.data.length > 1) {
      this.router.navigateByUrl("/search-result-page")
    } else {
      await this.customerProfileService.setCustomerId(event.data[0]?.customerId)
      this.customerProfileService.getMinimalProfileData()
      this.router.navigateByUrl("/customer-dashboard/customer-profile")
    }
  }


  closeSearch() {
    // Emit the event to inform the parent component
    this.searchClosed.emit();
  }

}
