import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { UserSettingsDashboard } from './user_settings/user_settings_dashboard/user_settings_dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { ManagePermissionComponent } from './user_settings/user_settings_dashboard/manage-permissions/manage-permissions.component';
import { CustomerProfileGuardService } from './shared/guard/customer-profile.guard';
import { ManageGroupPermissionComponent } from './user_settings/user_settings_dashboard/manage-group-permissions/manage-group-permissions.component';
import { NewManageUserComponent } from './user_settings/user_settings_dashboard/new-manage-user/new-manage-user.component';
import { EditManageUsersComponent } from './user_settings/user_settings_dashboard/edit-manage-users/edit-manage-users.component';
import { NewProfileComponent } from './user_settings/user_settings_dashboard/new-profile/new-profile.component';


const routes: Routes = [
  { path: 'fullfillment', redirectTo: 'fullfillment/provision-view', pathMatch: 'full', data: { breadcrumb: 'Fullfillment' } },
  {
    path: '',
    component: ContentComponent,
    data: { breadcrumb: 'Home' }, // Global breadcrumb for root path
    children: [
      { path: "", redirectTo: 'dashboard', pathMatch: 'full', data: { breadcrumb: 'Dashboard' } },
      {
        path: "dashboard",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        data: { breadcrumb: 'Dashboard' },
      },
      {
        path: "search-result-page",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./search-result-page/search-result-page-routing.module").then((m) => m.SearchResultPageRoutingModule),
        data: { breadcrumb: 'Search Results' },
      },
      {
        path: 'review',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./review/review.module").then((m) => m.ReviewModule),
        data: { breadcrumb: 'Review' },
      },
      {
        path: 'fullfillment',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./fullfillment/fullfillment.module").then((m) => m.FullfillmentModule),
        data: { breadcrumb: 'Fullfillment' },
      },
      {
        path: 'awaiting-shipment',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./awaiting-shipment/awaiting-shipment.module").then((m) => m.AwaitingShipmentModule),
        data: { breadcrumb: 'Order' },
      },
      {
        path: 'porting',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./portIn-out/port.module").then((m) => m.PortModule),
        data: { breadcrumb: 'Porting' },
      },
      { 
        path: 'user-settings', 
        component: UserSettingsDashboard, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'User Settings' } 
      },
      { 
        path: 'manage-user', 
        component: NewManageUserComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Users' } 
      },
      { 
        path: 'new-profile/:id', 
        component: NewProfileComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Profile' } 
      },
      { 
        path: 'edit-users', 
        component: EditManageUsersComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Users' } 
      },
      {
        path: "manage-permissions",
        component: ManagePermissionComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Manage Permissions' },
      },
      {
        path: "manage-group-permissions",
        component: ManageGroupPermissionComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Manage Group Permissions' },
      },
      {
        path: "customer-dashboard",
        canActivate: [AuthGuardService, CustomerProfileGuardService],
        loadChildren: () => import("./customer-dashboard/customer-dashboard.module").then((m) => m.CustomerDashboardModule),
        data: { breadcrumb: 'Customer Dashboard',isCustomerProfilePage:true },
      },
      {
        path: "ticket-system",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./ticket-system/ticket-system.module").then((m) => m.TicketSystemModule),
        data: { breadcrumb: 'Ticket System' },
      },
      {
        path: "reports",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule),
        data: { breadcrumb: 'Reports' },
      },
      {
        path: "subscriptions",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./subscriptions/subscriptions.module").then((m) => m.SubscriptionsModule),
        data: { breadcrumb: 'Subscriptions' },
      },
      {
        path: "scheduled-downgrades",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./scheduled-downgrades/scheduled-downgrades.module").then((m) => m.ScheduledDowngradeModule),
        data: { breadcrumb: 'Scheduled Downgrades' },
      },
      {
        path: "excutive-dashboard",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./excutive-dashboard/my-dashboard.module").then((m) => m.MyDashboardModule),
      },
      {
        path: "excutive-dashboard",
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Executive Dashboard' },
        loadChildren: () => import("./excutive-dashboard/my-dashboard.module").then((m) => m.MyDashboardModule),
      },
      {
        path: "add-member-group",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./add-member-group/add-member-group.module").then((m) => m.AddMemberGroupModule),
        data: { breadcrumb: 'Add Member Group' },
      },
      {
        path: "profile",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule),
        data: { breadcrumb: 'Profile' },
      },

      {
        path: "bulk-report",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./bulk-reports/bulk-reports.module").then((m) => m.BulkReportsModule),
        data: { breadcrumb: 'Bulk Reports' },
      },
      {
        path: "bulk-process",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./bulk-users/bulk-users.module").then((m) => m.BulkUsersModule),
        data: { breadcrumb: 'Bulk Process' },
      },
      {
        path: "recent-customer-searches",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./recent-customer-searches/recent-customer-searches.module").then((m) => m.RecentCustomerSearchesModule),
        data: { breadcrumb: 'Recent Customer Searches' },
      },

      {
        path: "new-reports",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./reports-new/reports.module").then((m) => m.ReportsNewModule),
        data: { breadcrumb: 'Analytics Reports' },
      },
      {
        path: "transaction",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./transactions/transaction.module").then((m) => m.TransactionModule),
        data: { breadcrumb: 'Transaction' },
      },
      // {
      //   path: "plan-config",
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import("./plan-config/plan-config.module").then((m) => m.PlanConfigModule),
      //   data: { breadcrumb: 'Plan Configuration' },
      // },
      {
        path: 'auth/login',
        component: LoginComponent,
        data: { breadcrumb: 'Login' },
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/review',
    data: { breadcrumb: 'Review' }
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'disabled',
    scrollPositionRestoration: 'disabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
