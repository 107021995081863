import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) { }


  getUser() {
    return this.userDetails;
  }


  getProfileData(id){
    let url="api/unity/viewAccountDetail/"+id
    let body={
      companyId:'UNITYCRM0021'
    }
    return this.http.get(url,body)
  }


  changePassword(obj,id){
    let url="api/unity/update-password/"+id
    let body={
      companyId:'UNITYCRM0021',
      userID:this.getUser().userID,
      ...obj
    }
    console.log(body,"bodyyyyyyyyyyyyyyyy")
    return this.http.put(url,body)
  }

  
}
