import { AfterContentInit, AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

declare const google: any; // Declare the google variable
@Component({
  selector: 'app-change-address-account',
  templateUrl: './change-address-account.component.html',
  styleUrls: ['./change-address-account.component.scss']
})
export class ChangeAddressAccountComponent implements OnInit,AfterViewInit {

  formData: FormGroup;
  personalInfoData: any;
  loader: boolean = false;
  presentMailingAddress: string;
  presentServiceAddress: string;
  countries: any = [];
  states: any = [];
  cities: any = [];
  zipcodes: any = [];

  @ViewChild('uspsAddressModifyConfirmation', { static: true }) uspsAddressModifyConfirmation: TemplateRef<any>;
  @ViewChild('uspsAddressSubmitConfirmation', { static: true }) uspsAddressSubmitConfirmation: TemplateRef<any>;
  googlezipCode: string;
isSkeletonLoader: boolean=true;
  isZipCodeMissing: boolean;

  constructor(
    private customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    private fb: FormBuilder,
    private modalService: NgbModal,private cdr: ChangeDetectorRef,private appRef: ApplicationRef,
  ) { }

  ngAfterViewInit(): void {
  
   
  }

  ngOnInit() {
    this.initializeForm()
    this.getCustomerPersonalInfo();

  }

  initializeForm() {
    this.formData = this.fb.group({
      addressType: [],
      address1: [],
      address2: [],
      city: [],
      state: [],
      zipCode: [],
      country: [],
      isAddressPermanent: [],
      isByPassUsps: [false]
    })
  }

  get addressType() {
    return this.formData.get('addressType') as FormGroup;
  }

  get country() {
    return this.formData.get("country");
  }
  get state() {
    return this.formData.get("state");
  }
  get city() {
    return this.formData.get("city");
  }
  get zipCode() {
    return this.formData.get("zipCode");
  }
  get isByPassUsps() {
    return this.formData.get("isByPassUsps");
  }
  initAutocomplete(addressInput: HTMLInputElement): void {
    if (typeof google !== "undefined" && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components.map(
            (component: any) => ({
              long_name: component.long_name,
              short_name: component.short_name,
              types: component.types,
            })
          );
          this.bindAddressComponentsToForm(addressComponents);
          this.cdr.detectChanges();
        }
      });
    } else {
      console.error("Google Maps API not loaded");
    }
  }

  bindAddressComponentsToForm(components: AddressComponent[]): void {
    let address1 = "";
    let address2 = "";
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";

    components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = component.long_name;
          break;
        case "route":
          address1 += " " + component.long_name;
          break;
        case "sublocality":
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.short_name;
          break;
        case "postal_code":
          zipCode = component.long_name;
          this.googlezipCode=zipCode
          break;
        default:
          break;
      }
    });

    this.formData.patchValue({
      address1: address1.trim(),
      address2: address2.trim(),
      country: country.trim(),
      state: state.trim(),
      city: city.trim(),
      zipCode: zipCode.trim(),
    });
    this.isZipCodeMissing = !zipCode;
    this.cdr.detectChanges();
  }

  async getCustomerPersonalInfo() {
    try {
    this.loader = true;

    
    setTimeout(async () => {
      try {
        const res: any = await this.customerProfileService.getCustomerPersonalCardData();
    
        if (res.Status) {
          this.personalInfoData = res.data;
    
          // Check if mailing address exists and process it
          if (this.personalInfoData?.personalInfo?.mailingAddress) {
            let address = this.personalInfoData.personalInfo.mailingAddress.value;
            this.presentMailingAddress = `${address?.address1}, ${address?.address2}, ${address?.city}, ${address?.state}, ${address?.zipCode}`;
          }
    
          // Check if service address exists and process it
          if (this.personalInfoData?.personalInfo?.serviceAddress) {
            let address = this.personalInfoData.personalInfo.serviceAddress.value;
            this.presentServiceAddress = `${address?.address1}, ${address?.address2}, ${address?.city}, ${address?.state}, ${address?.zipCode}`;
          }
    
          // Update the form with fetched data
          this.pathFormData();
          this.isSkeletonLoader=false
          setTimeout(() => {
            const addressInput = document.getElementById("addressInput") as HTMLInputElement;
            this.initAutocomplete(addressInput);
          }, 0);
          // Manually trigger change detection after all updates
          this.cdr.detectChanges();
        } else {
          this.commonService.showToast("error", "Error", res.message);
        }
      } catch (err) {
        this.commonService.showToast("error", "Error", err);
      } finally {
        // Stop the loader when done
        this.loader = false;
      }
    }, 0);
    
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    this.loader = false;
  }


  pathFormData() {
    this.getCountries();
    // debugger;

    if (this.personalInfoData) {
      let addressType = ''
      var address = {
        addressType: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        isAddressPermanent: false
      }
      if (this.personalInfoData?.personalInfo?.serviceAddress?.value?.address1 && this.personalInfoData?.personalInfo?.mailingAddress?.value?.address1) {
        addressType = "mailing"
        address = {
          addressType: addressType,
          address1: this.personalInfoData?.personalInfo?.mailingAddress?.value?.address1,
          address2: this.personalInfoData?.personalInfo?.mailingAddress?.value?.address2,
          city: this.personalInfoData?.personalInfo?.mailingAddress?.value?.city,
          state: this.personalInfoData?.personalInfo?.mailingAddress?.value?.state,
          zipCode: this.personalInfoData?.personalInfo?.mailingAddress?.value?.zipCode,
          isAddressPermanent: this.personalInfoData?.personalInfo?.isAddressPermanent?.value ? true : false
        }
      } else {
        if (this.personalInfoData?.personalInfo?.serviceAddress?.value?.address1) {
          addressType = "service"
          address = {
            addressType: addressType,
            address1: this.personalInfoData?.personalInfo?.serviceAddress?.value?.address1,
            address2: this.personalInfoData?.personalInfo?.serviceAddress?.value?.address2,
            city: this.personalInfoData?.personalInfo?.serviceAddress?.value?.city,
            state: this.personalInfoData?.personalInfo?.serviceAddress?.value?.state,
            zipCode: this.personalInfoData?.personalInfo?.serviceAddress?.value?.zipCode,
            isAddressPermanent: this.personalInfoData?.personalInfo?.isAddressPermanent?.value ? true : false
          }
        }
        if (this.personalInfoData?.personalInfo?.mailingAddress?.value?.address1) {
          addressType = "mailing"
          address = {
            addressType: addressType,
            address1: this.personalInfoData?.personalInfo?.mailingAddress?.value?.address1,
            address2: this.personalInfoData?.personalInfo?.mailingAddress?.value?.address2,
            city: this.personalInfoData?.personalInfo?.mailingAddress?.value?.city,
            state: this.personalInfoData?.personalInfo?.mailingAddress?.value?.state,
            zipCode: this.personalInfoData?.personalInfo?.mailingAddress?.value?.zipCode,
            isAddressPermanent: this.personalInfoData?.personalInfo?.isAddressPermanent?.value ? true : false
          }
        }
      }
      console.log('address', address)
      this.formData.patchValue(address);
      console.log('address form', this.formData.value);
    }
  }

  async getCountries() {
    if(this.countries.length > 0){
      return;
    }
    this.loader = true;
    try {
      const res: any = await this.commonService.getCountries();
      if (res.Status) {
        res.data.forEach(element => {
            element["value"] = element.CountryCode,
            element["label"] = element.CountryName
        });
        this.countries = res.data
        this.formData.patchValue({
          country: res.data[0]
        });
        // this.country.disable();
        await this.onSelectCountry(res.data[0], false);
        
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      
      this.commonService.showToast("error", "Error", err.error.message)
    }
    this.loader = false;
  }

  async onSelectCountry(countryObj, callType) {
    this.loader = true;
    try {
      let data = {
        "CountryID": countryObj.id
      }
      const res: any = await this.commonService.getStates(data);
      if (res.Status) {
        res.data.forEach( item => {
          item["label"] = item.StateName;
          item["value"] = item.StateCode;
        })

        this.states = res.data
        
        if(callType){
          this.onSelectState(res.data[0], true)
          this.formData.patchValue({
            state: res.data[0],
          })
          // this.state.disable();
        }
        else {
          let state = this.states.find(el => el.StateCode == this.state.value);
           await this.onSelectState(state, false)
        }
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    this.loader = false;
  }

  async onSelectState(stateObj, callType) {
    if (!stateObj) {
      return
    }
    this.loader = true;
    try {
      this.state.value;
      let data = {
        "StateID": stateObj.id
      }
      const res: any = await this.commonService.getCities(data);

      console.log('getCities', res)
      if (res.Status) {
        res.data.forEach( item => {
          item["value"] = item.CityCode;
          item["label"] = item.CityName;
        })
        this.cities = res.data;

        if(callType){
          this.formData.patchValue({
            city: res.data[0]
          });
          // this.city.disable();

          this.onSelectCity(res.data[0], true);

        } else {
          // this.pathFormData()
          const cityData = this.personalInfoData?.personalInfo?.mailingAddress?.value?.city;
          const city = cityData ? cityData: this.cities[0].CityName;
          const selectedCity = this.cities.find( item => item.CityName === city);
          this.onSelectCity(selectedCity, false)
        }

        // console.log(selectedCity);
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    this.loader = false;
  }

  async onSelectCity(city, callType) {
    this.loader = true;
    try {
      // debugger;
      let data = {
        "CityID": city.id
      }
      // const res: any = await this.commonService.getZipCodes(data);
      // // console.log('getZipCodes', res)
      // if (res.Status) {
      //   console.log(res.data[0]);
      //   res.data.forEach( (item) =>  {
      //     item["value"] = item.id;
      //     item["label"] = item.ZipCode
      //   })
      //   this.zipcodes = res.data;
      //   // zipID: this.formData.value.zipCode.ZipCode,
        
      //   if(callType){
      //     this.formData.patchValue({
      //       zipCode: res.data[0]
      //     })
      //   }
      //   else {
      //     const zipCodeData = res.data.find(zip => zip.ZipCode == this.zipCode.value);
      //     this.formData.patchValue({
      //         zipCode: zipCodeData
      //     })          
      //   }
      // } else {
      //   this.commonService.showToast('error', "Error", res.message)
      // }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    this.loader = false;
  }


 async getCustomerZipCodeId(){
    try {
      let data={
        zipCode:this.googlezipCode || this.formData.get('zipCode').value
      }
      
      const res:any= await this.commonService.getZipCodeId(data);
      console.log(res);

      if(res?.Status){
        console.log(res);
        this.formData.patchValue({
          zipCode: res?.data?.id,
        });
      }else{
        console.log('error');
      }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
    }
  }
  async onSubmit() {
    console.log("onSubmit form:", this.formData.value);
  
      this.loader = true;
      try {
       await this.getCustomerZipCodeId()
        let address = {
          address1: this.formData.value.address1,
          address2: this.formData.value.address2,
          city: this.formData.value.city,
          state: this.formData.value.state,
          zipID: this.formData.value.zipCode,
        }
console.log('address: ',address);
        let data = {
          mailingAddress: {},
          serviceAddress: {},
          isAddressPermanent: this.formData.value.isAddressPermanent,
          bypassNlad: true,
          "usps": ! this.isByPassUsps.value
        }
        if (this.addressType.value == 'service') {
          data.serviceAddress = address
          delete data.mailingAddress
        } else if (this.addressType.value == 'mailing') {
          data.mailingAddress = address
          delete data.serviceAddress
        } else if (this.addressType.value == 'both') {
          data.serviceAddress = address
          data.mailingAddress = address
        }
        
        data.mailingAddress = {
          ...address,
          zipID: this.formData.value.zipCode
        } 
        delete data.mailingAddress["zipCode"];

        const res: any = await this.customerProfileService.updateUspsAddresses(data);

        if (res.Status) {
          this.commonService.showToast('success', "Success", 'Successfully Updated')
          this.customerProfileService.updateSubmitSuccess(true);  
        }
        else {
          this.commonService.showToast('error', "Error", res.message);
          this.customerProfileService.updateSubmitSuccess(false);  

        }
      } catch (err) {
        let errorMsg = `${err.error.message} ${err?.error?.error} `
        this.commonService.showToast("error", "Error", errorMsg);
        this.customerProfileService.updateSubmitSuccess(false);  
        
      } finally {
        // Ensure loader state change is after all async operations
        setTimeout(() => {
            this.loader = false; // Hide loader
    this.cdr.detectChanges(); 

        }, 0);
    }
      this.loader = false;
  }

  async udpateAddress() {
    this.loader = true;
    try {
      let address = {
        address1: this.formData.value.address1,
        address2: this.formData.value.address2,
        city: this.formData.value.city.CityCode,
        state: this.formData.value.state.StateCode,
        zipCode: this.formData.value.zipCode.ZipCode,
      }
      let data = {
        mailingAddress: {},
        serviceAddress: {},
        isAddressPermanent: this.formData.value.isAddressPermanent
      }
      if (this.addressType.value == 'service') {
        data.serviceAddress = address
        delete data.mailingAddress
      } else if (this.addressType.value == 'mailing') {
        data.mailingAddress = address
        delete data.serviceAddress
      } else if (this.addressType.value == 'both') {
        data.serviceAddress = address
        data.mailingAddress = address
      }
      console.log('data', data)
      const res: any = await this.customerProfileService.updateCustomerProfile(data);
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Updated')
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    this.loader = false;
  }

  openModal(content, size) {
    this.modalService.open(content, { backdropClass: "dark-modal", size: size, centered: true });
  }

  onConfirmAddressConfirmation() {
    this.udpateAddress()
  }

}
