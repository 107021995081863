import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { CustomerProfileComponent } from '../customer-profile.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { CurrencyPipe } from '@angular/common';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-line-information',
  templateUrl: './line-information.component.html',
  styleUrls: ['./line-information.component.scss'],
  animations:[fadeInOut]
})
export class LineInformationComponent implements OnInit {


customerId: any | null;
customerMoreLineInfo: any;
lineInfoFormData: FormGroup;
formFields: any[] = [];
isLoading: any;
isSkeletonLoader: boolean=true;
  constructor(
    private customerProfileService:CustomerProfileService,
    private httpService:HttpService,
    private customerProfileComponent:CustomerProfileComponent,
    private commonService:CommonService,
    private fb: FormBuilder,  private currencyPipe: CurrencyPipe,
    private customerDetailedInformationService:CustomerDetailedInformationService
  ){
  }
  async ngOnInit() {
    await this.customerProfileService.CustomerDataUpdated.subscribe(res => {
      this.customerMoreLineInfo = null;
    });
    this.customerProfileComponent.customerId$.subscribe(customerId => {
      this.customerId = customerId;
    });
    await this.getMoreLineInformation();
  }

  async getMoreLineInformation() {
    try {
      this.isLoading=true;
      const response: any = await this.customerProfileService.getLineInfoMoreCardData();
      if (response?.Status) {
        // delete response?.data?.SLOs;
        delete response?.data?.makeModel
        this.customerMoreLineInfo = this.formatLineInfo(response?.data);
        this.initializeFields();
        this.isSkeletonLoader=false
      } else {
        this.commonService.showToast('error', 'Error', response?.message);
      }
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message);
    } finally{
      this.isLoading=false
    }
  }

  formatLineInfo(lineInfo: any) {
    const formattedInfo: any = {};
    for (const key in lineInfo) {
      if (lineInfo.hasOwnProperty(key)) {
        const formattedKey = this.customerDetailedInformationService.formatKeyLabel(key);
        formattedInfo[formattedKey] = this.customerDetailedInformationService.formatValue(lineInfo[key]);
      }
    }
    return formattedInfo;
  }


  initializeFields() {
    const planAttributes = ['Plan', 'Plan Id','Plan Detail', 'Plan Price'];
    const dateAttributes = ['Activation Date', 'Expiration Date', 'Next Renewal Date','Mdn Expiry Date'];
    const shortFormAttributes = ['E Sim', 'Sim Type', 'Iccid Status','Mdn','Imei','Esn','Msisdn','Imsi','Uiccid'];
    const remainingAttributes = [];
  
    const fields: any[] = [];

    planAttributes.forEach(attr => {
      if (this.customerMoreLineInfo[attr]) {
        let value = this.customerMoreLineInfo[attr];
        
        if (attr === 'Plan Price') {
          value = this.customerDetailedInformationService.formatCurrency(value);
        }
  
        fields.push({ controlName: attr, label: attr, value });
      }
    });
  
    dateAttributes.forEach(attr => {
      if (this.customerMoreLineInfo[attr]) {
        const value = this.customerDetailedInformationService.formatDate(this.customerMoreLineInfo[attr]);
        fields.push({ controlName: attr, label: attr, value });
      }
    });
  
    shortFormAttributes.forEach(attr => {
      if (this.customerMoreLineInfo[attr]) {
        fields.push({ controlName: attr, label: attr, value: this.customerMoreLineInfo[attr] });
      }
    });
  
    for (const key in this.customerMoreLineInfo) {
      if (
        this.customerMoreLineInfo.hasOwnProperty(key) &&
        !planAttributes.includes(key) &&
        !dateAttributes.includes(key) &&
        !shortFormAttributes.includes(key)
      ) {
        remainingAttributes.push({ controlName: key, label: key, value: this.customerMoreLineInfo[key] });
      }
    }
  
    this.formFields = [...fields, ...remainingAttributes];
  }
  
  getStatusClass(status: string): string {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'status-active';
      case 'cancelled':
        return 'status-cancelled';
      case 'suspend':
      case 'scheduled':
        return 'status-suspend';
      default:
        return ''; 
    }
  }
  

}
