import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CarouselDTO } from 'src/app/shared/model/carousel.model';
import { WirelessAppDatabase } from 'src/app/shared/services/app-cache/wireless.app.db';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent {
  private carouselSubscription: Subscription;
  carousel : CarouselDTO[]
  constructor(private wirelessDb: WirelessAppDatabase) {}

  async ngOnInit() {
    const todayDate = new Date();

    this.carousel = await this.wirelessDb.Carousel.toArray();
    // Filter carousel items based on location and date conditions
    this.carousel = this.carousel.filter(item =>
      item.locations.some(location => location.Name === "Homepage Banner") && 
      new Date(item.StartDate) <= todayDate &&
      new Date(item.EndDate) >= todayDate
    );
  }

  ngOnDestroy() {
    // Unsubscribe from changes when the component is destroyed
    if (this.carouselSubscription) {
      this.carouselSubscription.unsubscribe();
    }
  }

}
