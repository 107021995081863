import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RegisteredCard } from '../shared/interfaces/registered-cards.interface';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';

@Component({
  selector: 'app-customer-profile-view-left-panel',
  templateUrl: './customer-profile-view-left-panel.component.html',
  styleUrls: ['./customer-profile-view-left-panel.component.scss']
})
export class CustomerProfileViewLeftPanelComponent implements OnInit {
 
  @Output() cardSelected = new EventEmitter<any>(); 
  cards: RegisteredCard[] = []; 
  selectedCard: RegisteredCard | null = null; 
  activeTab: string = '';
  constructor(private customerDetailedInformationService:CustomerDetailedInformationService){}

  ngOnInit(): void {
    this.cards = this.customerDetailedInformationService.getregisteredCard();
    this.customerDetailedInformationService.activeTab$.subscribe(tabName => {
      this.activeTab = tabName;
    });
    console.log('registered cards:',this.cards);
  }
  
  selectCard(card: any) {
    this.customerDetailedInformationService.setActiveTab(card.title);
  }

getIconClass(title: string): string {
  switch (title) {
    case 'Application Information':
      return 'fas fa-info-circle';
    case 'Subscription Information':
      return 'fas fa-file-alt';
    case 'Usage':
      return 'fas fa-chart-bar';
    case 'Line Information':
      return 'fas fa-phone-alt';
    case 'Customer Events':
      return 'fas fa-calendar-alt';
    case 'Personal Information':
      return 'fas fa-user';
    case 'Upcoming Plans':
      return 'fas fa-calendar-check';
    case 'Eligibility Information':
      return 'fas fa-check-circle';
    default:
      return 'fas fa-file'; 
  }
}

}
