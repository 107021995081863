import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() isChildLoader:boolean = false;
  @Input() class:string = '';
  @Input() variant:string = 'loader-39';

  constructor() {
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
