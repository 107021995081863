import { Component, Input, OnInit } from "@angular/core";
import { AddDataService } from "../services/add-data.service";
import { CommonService } from "src/app/shared/services/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpService } from "src/app/shared/services/httpService.service";
import { PlanChangeService } from "../../plan-change/services/plan-change.service";
import { fadeInOut } from "src/app/shared/services/app.animation";

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
declare const google: any; // Declare
@Component({
  selector: "app-credit-card-add-data",
  templateUrl: "./credit-card-add-data.component.html",
  styleUrls: ["./credit-card-add-data.component.scss"],
  animations:[fadeInOut]
})
export class CreditCardAddDataComponent implements OnInit {
  @Input() planChange: boolean;
  @Input() label: string = "Amount";
  @Input() planId: any;
  @Input() prepaidPlans: any[] = [];
  @Input() planDetails: any;
  @Input() customerAddress: any;
  loader: boolean = false;
  topUpsData: any = [];
  formData: FormGroup;
  expireMonthDropdown = Array.from({ length: 12 }, (_, i) => i + 1);
  currentYear: number = new Date().getFullYear();
  futureYear: number = 2050;
  expireYearDropdown = Array.from(
    { length: this.futureYear - this.currentYear + 1 },
    (_, i) => this.currentYear + i
  );
  taxAmountee: boolean = false;
  @Input() toupTypeData: string;
  states:any[]=[]
  cities:any[]=[]
  zips:any[]=[]
  selectedState: any;
  selectedCity: any;
  statesData: any;
  validCardTypes = {
    Amex: false,
    Visa: false,
    MasterCard: false,
  }; 
  taxIncluded: boolean;
  totalAmount: number;
isSkeletonLoader: boolean=true;
  categorizedTopUps: { category: string; topUps: any; }[];
  constructor(
    private dataService: AddDataService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private httpService: HttpService,
    private planChangeService: PlanChangeService
  ) {}

  ngOnInit() {
    this.getTopUps();
    this.initializeForm();
    this.getStates();
    this.getStates();
    this.grtStateId()

    this.formData.get('state').valueChanges.subscribe(selectedState => {
      this.selectedState=selectedState
      this.formData.value.state=this.selectedState
      // this.onStateSelect(this.selectedState)
  // const stateObject = this.stateList.find(state => state.StateName == this.selectedState);
    });
    this.formData.get('city').valueChanges.subscribe(selectedCity=>{
      this.selectedCity=selectedCity
      this.formData.value.city=this.selectedCity
      console.log('selected city:',this.selectedCity)
      // this.onSelectCity(this.selectedCity)
    })

    
  }

  getGooleFunctionalityStart(){
    setTimeout(() => {
      const addressInput = document.getElementById(
        "addressInput"
      ) as HTMLInputElement;
      if (addressInput) {
        this.initAutocomplete(addressInput);
      } else {
        console.error("addressInput is undefined after modal is shown");
      }
    }, 0);
  }
  initializeForm() {
    this.formData = this.fb.group({
      topUpID: [''],
      zipCode: ['',Validators.required],
      cardCode: ['',Validators.required],
      amount: [],
      cardNumber: ['',[Validators.required, Validators.pattern('^[0-9]*$')]],
      nameOnCard: ['',Validators.required],
      processingFee: [],
      expireMonth: ['',Validators.required],
      expireYear: ['',Validators.required],
      taxAmount: [],
      address1:['',Validators.required],
      address2:[''],
      state:['',Validators.required],
      city:['',Validators.required],
    });

  }

  getTotal(): number {
    const processingFee = this.formData.get('processingFee')?.value || 0;
    const taxAmount = this.formData.get('taxAmount')?.value || 0;
    const amount = this.formData.get('amount')?.value || 0;

    // Sum of processingFee, taxAmount, and amount
    return Number(processingFee) + Number(taxAmount) + Number(amount);
  }

  get topUpID() {
    return this.formData.get("topUpID");
  }
  get processingFee() {
    return this.formData.get("processingFee");
  }
  get amount() {
    return this.formData.get("amount");
  }
  get taxAmount() {
    return this.formData.get("taxAmount");
  }

  initAutocomplete(addressInput: HTMLInputElement): void {
    if (typeof google !== "undefined" && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components.map(
            (component: any) => ({
              long_name: component.long_name,
              short_name: component.short_name,
              types: component.types,
            })
          );
          this.bindAddressComponentsToForm(addressComponents);
        }
      });
    } else {
      console.error("Google Maps API not loaded");
    }
  }

  bindAddressComponentsToForm(components: AddressComponent[]): void {
    let address1 = "";
    let address2 = "";
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";

    components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = component.long_name;
          break;
        case "route":
          address1 += " " + component.long_name;
          break;
        case "sublocality":
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.short_name;
          break;
        case "postal_code":
          zipCode = component.long_name;
          break;
        default:
          break;
      }
    });

    this.formData.patchValue({
      address1: address1.trim(),
      address2: address2.trim(),
      country: country.trim(),
      state: state.trim(),
      city: city.trim(),
      zipCode: zipCode.trim(),
    });
  }
  async getTopUps() {
    this.loader = true;
    try {
      let data = {
        // topupType: "DATA", // DATA | VOICE | SMS
        // stateAbbr: "WA",
      };
      const res: any = await this.dataService.getTopUps(data);
      if (res?.Status) {
        console.log('topupdate: ',this.topUpsData);

         // Original topUpsData processing
         this.topUpsData = res?.data.map(item => ({
          planId: item.id,
          planName: item.DisplayName,
          TaxIncluded: item?.TaxIncluded,
          Price: item?.Price,
          ServiceRate: item?.ServiceRate
        })).sort((a, b) => {
          const getSize = name => {
            const match = name.match(/(\d+(\.\d+)?)\s*GB/i);
            return match ? parseFloat(match[1]) : 0; // Default to 0 if "GB" is not found
          };
          return getSize(a.planName) - getSize(b.planName);
        });
        
        console.log('topupdate: ',this.topUpsData);
      // Categorization process for categorizedTopUps
      const categorizedTopUps = res?.data?.reduce((acc, item) => {
        const category = item.topup_type?.Name || "Other";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push({
          planId: item?.id,
          planName: item?.DisplayName,
          TaxIncluded: item?.TaxIncluded,
          Price: item?.Price,
          ServiceRate: item?.ServiceRate
        });
        return acc;
      }, {});
      console.log('categorizedTopUps: ',categorizedTopUps);


      console.log('categorizedTopUps',categorizedTopUps);
  // Convert to array format for easier template handling
  this.categorizedTopUps = Object.keys(categorizedTopUps).map(key => ({
    category: key,
    topUps: categorizedTopUps[key].sort((a, b) => {
      const getSize = name => {
        const match = name.match(/(\d+(\.\d+)?)\s*GB/i);
        return match ? parseFloat(match[1]) : 0;
      };
      return getSize(a.planName) - getSize(b.planName);
    })
  }));

  this.isSkeletonLoader = false;
  this.getGooleFunctionalityStart();
      } else {
        this.commonService.showToast("error", "Error", res.message);
        this.isSkeletonLoader=false
      }
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
      this.isSkeletonLoader=false
    }
    this.loader = false;
  }

  // async onSubmit() {
  //   this.loader = true;
  //   try {
  //     let datas = {
  //       CustomerId: this.httpService.getCustomerId(),
  //       topupId: this.topUpID.value,
  //     };
  //     let datass: any;
  //     if (this.planChange) {
  //       this.onSubmitPlanChannge()
  //       return
  //     } 
  //     if (this.toupTypeData === "Y") {
  //       this.onSubmitWithCreditCard()
  //       return
  //     }
  //     if (this.toupTypeData === "N") {
  //       this.onSubmitFreeTopUp()
  //       return

  //     }
      
  //   } catch (err) {
  //     this.commonService.showToast(
  //       "error",
  //       "Error",
  //       err?.error?.message ?? "Some Error Occurred"
  //     );
  //   }
  //   this.loader = false;
  // }

  async onSubmit() {
    try {
    // Check if the form is invalid
   if (this.toupTypeData === "N") {
        await this.onSubmitFreeTopUp(); // Same here
        return;
      }
    if(this.toupTypeData === "Y" || this.toupTypeData === "N"){
      if (this.formData.invalid) {
        this.formData.markAllAsTouched(); // Mark all fields as touched to show errors
        return; // Exit the function if the form is invalid
      }
    }
   

    this.loader = true; // Set the loader to true
    
      const datas = {
        CustomerId: this.httpService.getCustomerId(),
        topupId: this.topUpID.value,  
      };

      let datass: any;
      if (this.planChange) {
        await this.onSubmitPlanChannge(); // Make sure to await the function if it's async
        return;
      }
      if (this.toupTypeData === "Y") {
        await this.onSubmitWithCreditCard(); // Same here
        return;
      }
     
      
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    } finally {
      this.loader = false; // Ensure loader is hidden after the operation
    }
  }

  async onSubmitFreeTopUp(){
    this.loader=true
    try{
      let datass: any;
      let data = {
        topUpID: this.formData.value.topUpID,
        zipCode: 35442,
        chargeCreditCard: this.toupTypeData,
        // taxAmount: await this.getTaxAmount(datas),
        // taxAmount:0,
        // taxIncluded: this.getTaxIncluded(),
        planId: this.planId,

      };

      datass = data;
    
        datass["createTransactionRequest"] = {
          refId: "123456",
          transactionRequest: {
            // amount: this.formData.value.amount,
            amount:this.totalAmount,
            quantity: 1,
            payment: {
              creditCard: {
                cardNumber: this.formData.value.cardNumber,
                expirationDate:
                  this.formData.value.expireYear +
                  "-" +
                  this.formData.value.expireMonth,
                cardCode: this.formData.value.cardCode,
                nameOnCard: this.formData.value.nameOnCard,
              },
            },
          },
        };
        const response: any = await this.dataService.addFreeCustomerTopUp(datass);
        this.loader=false
        if (response.Status) {
              this.initializeForm();
          this.commonService.showToast("success", "Success", "Added Successful.");
        } else {
          this.commonService.showToast("error", "Error", response.message);
        }
    
      }catch(err){
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
  }

  async onSubmitWithCreditCard(){
    this.loader=true
    try{
      let datass: any;
      let data = {
        topUpID: this.formData.value.topUpID,
        zipCode: this.formData.value.zipCode,
        chargeCreditCard: this.toupTypeData,
        // taxAmount: await this.getTaxAmount(datas),
        taxAmount:0,
        taxIncluded: this.getTaxIncluded(),
        planId: this.planId,

      };

      datass = data;
      datass["address"]= {
        address1:this.formData.value.address1,
        address2:this.formData.value.address2,
        state:this.formData.value.state,
        city:this.formData.value.city,
        zip:this.formData.value.zipCode
      }
        
      datass["createTransactionRequest"] = {
        refId: "123456",
        transactionRequest: {
          // amount: this.formData.value.amount,
          amount:this.totalAmount,

          quantity: 1,
          payment: {
            creditCard: {
              cardNumber: this.formData.value.cardNumber,
              expirationDate:
                this.formData.value.expireYear +
                "-" +
                this.formData.value.expireMonth,
              cardCode: this.formData.value.cardCode,
              nameOnCard: this.formData.value.nameOnCard,
            },
          },
        },
      };
        
        const response: any = await this.dataService.addCustomerTopUp(datass);
        this.loader=false
        if (response.Status) {
          this.commonService.showToast("success", "Success", "Added Successful.");
        } else {
          this.commonService.showToast("error", "Error", response.message);
        }
    
      }catch(err){
        this.loader=false
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
  }

  async onSubmitPlanChannge(){
    try{
      this.loader=true
      let datass: any;

      let value = this.prepaidPlans.find((el) => el.id == this.topUpID.value);
        let planData = {
          CustomerId: this.httpService.getCustomerId(),
          planId: this.topUpID.value,
        };
        let data = {
          // "topUpID": this.formData.value.topUpID,
          // "zipCode": 35442,
          chargeCreditCard: this.toupTypeData,
          taxAmount: await this.getPlanTax(planData),
          // "taxIncluded":this.getTaxIncluded(),
          planId: this.formData.value.topUpID,
        };
        if (value?.TaxIncluded === 0) {
          data["taxIncluded"] = false;
          data["chargeCreditCard"] = "Y";
        } else if (value?.TaxIncluded === 1) {
          data["taxIncluded"] = true;
          data["chargeCreditCard"] = "N";
        }
        data["billingAddress"] = {
          address1: this.customerAddress[0],
          address2: this.customerAddress[1],
          zipCode: this.customerAddress[2],
        };
        datass = data;
        const response: any = await this.dataService.transferToPrepaid(datass);
        this.loader=false
        if (response.Status) {
              this.initializeForm();

          this.commonService.showToast("success", "Success", "Added Successful.");
        } else {
          this.commonService.showToast("error", "Error", response.message);
        }
    }catch(err){
      this.commonService.showToast(
        "error",
        "Error",
        err?.error?.message ?? "Some Error Occurred"
      );
    }
  }
  selectedPlanOrTopUp:any
  
  async onSelectAmount() {
    if (this.planChange) {
    this.loader=true

      let value = this.prepaidPlans.find((el) => el.id == this.topUpID.value);
      this.selectedPlanOrTopUp=value

      if (value) {
        if (value?.TaxIncluded === 0) {
          this.taxAmountee = false;
          let data = {
            CustomerId: this.httpService.getCustomerId(),
            planId: this.topUpID.value,
          };

          let taxAmount = await this.getPlanTax(data);
          // console.log('taxxxx amount',taxAmount)

          this.processingFee.patchValue(value.ServiceRate);

          this.taxAmount.patchValue(taxAmount);
          this.amount.patchValue(
            parseFloat(value.Price) +
              parseFloat(taxAmount) +
              parseFloat(value.ServiceRate)
          );
          let processingFee = this.formData.get('processingFee')?.value || 0;
          const tax = this.formData.get('taxAmount')?.value || 0;
          const amount = this.formData.get('amount')?.value || 0;
      
          // Sum of processingFee, taxAmount, and amount
          const totalAmount= Number(processingFee) + Number(tax) + Number(amount);
          this.totalAmount=totalAmount
  
      
        } else if (value?.TaxIncluded === 1) {

          this.taxAmountee = true;
          // let value = this.topUpsData.find(el => el.id == this.topUpID);
          let data = {
            CustomerId: this.httpService.getCustomerId(),
            planId: this.topUpID.value,
          };
          let taxAmount = await this.getPlanTax(data);
          // console.log('taxxxx amount',taxAmount)

          // const res: any = await this.dataService.getTopUpTaxes(data);
          this.processingFee.patchValue(value.ServiceRate);
          this.taxAmount.patchValue(taxAmount);
          console.log("value:......", value);
          this.amount.patchValue(
            (parseFloat(value.Price) + parseFloat(value.ServiceRate)).toFixed(2)
          );
          let processingFee = this.formData.get('processingFee')?.value || 0;
          const tax = this.formData.get('taxAmount')?.value || 0;
          const amount = this.formData.get('amount')?.value || 0;
      
          // Sum of processingFee, taxAmount, and amount
          const totalAmount= Number(processingFee)  + Number(amount);
          this.totalAmount=totalAmount
         
        }else{
          this.taxAmountee = false;
          let data = {
            CustomerId: this.httpService.getCustomerId(),
            planId: this.topUpID.value,
          };

          let taxAmount = await this.getPlanTax(data);

          this.processingFee.patchValue(value.ServiceRate);

          this.taxAmount.patchValue(taxAmount);
          this.amount.patchValue(
            parseFloat(value.Price) +
              parseFloat(taxAmount) +
              parseFloat(value.ServiceRate)
          );
          let processingFee = this.formData.get('processingFee')?.value || 0;
          const tax = this.formData.get('taxAmount')?.value || 0;
          const amount = this.formData.get('amount')?.value || 0;
      
          // Sum of processingFee, taxAmount, and amount
          const totalAmount= Number(processingFee) + Number(tax) + Number(amount);
          this.totalAmount=totalAmount
        }

        this.loader=false
      }
    } else {
      let value = this.topUpsData.find((el) => el.planId == this.topUpID.value);
      this.selectedPlanOrTopUp=value
      this.getTaxIncluded()

      if (value) {
      this.loader=true

        if (value.TaxIncluded === 0) {

          this.taxAmountee = false;
          let data = {
            CustomerId: this.httpService.getCustomerId(),
            topupId: this.topUpID.value,
          };
          let taxAmount = await this.getTaxAmount(data);

          this.processingFee.patchValue(value.ServiceRate);

          this.taxAmount.patchValue(taxAmount);
          this.amount.patchValue(
            parseFloat(value.Price) + parseFloat(taxAmount)
          );
          let processingFee = this.formData.get('processingFee')?.value || 0;
          const tax = this.formData.get('taxAmount')?.value || 0;
          const amount = this.formData.get('amount')?.value || 0;
      
          // Sum of processingFee, taxAmount, and amount
          const totalAmount= Number(processingFee) + Number(tax) + Number(amount);
          this.totalAmount=totalAmount
        }
        if (value.TaxIncluded === 1) {

          this.taxAmountee = true;
          // let value = this.topUpsData.find(el => el.id == this.topUpID);
          let data = {
            CustomerId: this.httpService.getCustomerId(),
            topupId: this.topUpID.value,
          };

          let taxAmount = await this.getTaxAmount(data);
          this.processingFee.patchValue(value.ServiceRate);
          this.amount.patchValue(value.Price);
          let processingFee = this.formData.get('processingFee')?.value || 0;
          const tax = this.formData.get('taxAmount')?.value || 0;
          const amount = this.formData.get('amount')?.value || 0;
      
          // Sum of processingFee, taxAmount, and amount
          const totalAmount= Number(processingFee) + Number(amount);
          this.totalAmount=totalAmount
          // Check if taxAmount is defined; if not, set it to 0
          if (taxAmount !== null && taxAmount !== undefined) {
            this.taxAmount.patchValue(taxAmount);
          } else {
            this.taxAmount.patchValue(0); // Patch 0 if taxAmount is undefined or null
          }
          // const res: any = await this.dataService.getTopUpTaxes(data);
          // this.processingFee.patchValue(value.ServiceRate);
          // this.taxAmount.patchValue(taxAmount);
          // this.amount.patchValue(value.Price);
        }
        this.loader=false
      }
    }
  }

  async getStates(){
    let data={
  
    }
    try {
      const response = await this.commonService.getStates(data) as any; // Use 'any' to handle unknown response type
      if (response && response.Status === true && response.data && Array.isArray(response.data)) {
         const StatesData= response.data; 
         this.statesData=StatesData// Assign the cities array to this.cities
        StatesData.forEach(element => {
          if(element.StateName){
            this.states.push(element.StateName)
          }
          // console.log('agent form:',this.stateList)
        });
        let stateSet = new Set(this.states);
        this.states = [...stateSet];
        
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  
  grtStateId(){
    const stateObject = this.states.find(state => state.StateName == this.selectedState);
  }
  public citiesList:any;
  
  onStateSelect(state: string) {
    const stateObject = this.statesData.find(s => s.StateName === state);

  
    if (stateObject) {
      const stateId = stateObject.id;

      this.getCities(stateId);
    } else {
      console.error('Selected state not found in stateList');
    }
  }
  onSelectCity(city:string){
    const cityObject = this.citiesData.find(s => s.CityName === city);
  
    // const zipObject = this.citiesData.find(s => s.CityName === city);
    // console.log('zipobject:',zipObject)
    // if(zipObject){
      // const cityId=zipObject.id;
      console.log('city id: ',cityObject.id)
      this.getZipCodes(cityObject.id)
    // }
  
  }
  public citiesData:any
  async getCities(stateId){
    let data={
      "StateID":stateId
    }
    try {
      const response = await this.commonService.getCities(data) as any;
      if (response && response.Status === true && response.data && Array.isArray(response.data)) {
        const CitiesData = response.data;
        this.citiesData=CitiesData
  
        this.cities = CitiesData.map(city => city.CityName); // Assuming CitiesData is an array of objects with CityName property
  
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }
  public zipCodeDatas:any;
  async getZipCodes(cityId){
    let data={
      "CityID":cityId
    }
    try {
      const response = await this.commonService.getZipCodes(data) as any;
      if (response && response.Status === true && response.data && Array.isArray(response.data)) {
        const zipCodesData = response.data;
  
        this.zips = zipCodesData.map(zip => zip.ZipCode); // Assuming CitiesData is an array of objects with CityName property
  
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }
  async getPlanTax(data) {
    const response: any = await this.planChangeService.getPlanTax(data);
    
    // this.getPlanDetails();
    return response?.data?.taxAmount || 0;
  }

  async getTaxAmount(data) {
    try {
    const res: any = await this.dataService.getTopUpTaxes(data);
      if(res?.Status){
      return res?.data?.taxAmount;
        
      }else{
        this.commonService.showToast('error','Error',res?.message)
      }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
      
    }
    
  }
  getTaxIncluded(): boolean {
    let value = this.topUpsData.find((el) => el.planId == this.topUpID.value);
    if (value.TaxIncluded === 0) {
      this.taxIncluded=false
      return false;
    }
    if (value.TaxIncluded === 1) {
      this.taxIncluded=true
      return true;
    }

    return false;
  }



  

  
  onCardNumberChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // if (/[^0-9]/.test(value)) {
    //   inputElement.value = value.replace(/[^0-9]/g, '');
    //   alert('Only numeric characters are allowed.');
    // }

    this.formData.get('cardNumber')?.setValue(inputElement.value);
    const cardNumberStr = event.target.value;

    if (cardNumberStr.length === 1 || cardNumberStr.length === 16) {
      const cardType = this.getCardType(cardNumberStr);
      
      for (const key in this.validCardTypes) {
        if (this.validCardTypes.hasOwnProperty(key)) {
          this.validCardTypes[key] = key === cardType;
        }
      }

    } else if (!cardNumberStr) {
      this.resetCardType();
    }
  }

  resetCardType() {
    for (const key in this.validCardTypes) {
      if (this.validCardTypes.hasOwnProperty(key)) {
        this.validCardTypes[key] = false;
      }
    }
  }

  getCardType(cardNumber: string): string | null {
    if (!cardNumber || cardNumber.length === 0) {
      return null; 
    }

    const firstDigit = cardNumber.charAt(0);

    if (firstDigit === "4") {
      return "Visa";
    } else if (firstDigit === "5") {
      return "MasterCard";
    } else if (firstDigit === "6") {
      return "Amex";
    } else {
      return null; 
    }
  }

  isCardTypeValid(cardType: string): boolean {
    return this.validCardTypes[cardType];
  }

  getPlaceholder(fieldName: string): string {
    const control = this.formData.get(fieldName);
    
    // Define custom error messages based on the fieldName
    const errorMessages: { [key: string]: string } = {
      cardNumber: 'Card Number is required.',
      expireMonth: 'Expire Month is required.',
      expireYear: 'Expire Year is required.',
      cardCode: 'CVV is required.',
      nameOnCard: 'Name on card is required.',
      address1: 'Address is required.',
      city: 'City is required.',
      state: 'State is required.',
      zipCode: 'Zip Code is required.',
      
    };
    
    const patternErrorMessages: { [key: string]: string } = {
      cardNumber: 'Card Number must be numeric.'
    };
  
    if (control?.touched && control?.hasError('required')) {
      return errorMessages[fieldName] || 'This field is required.';
    }
  
    if (control?.touched && control?.hasError('pattern')) {
      return patternErrorMessages[fieldName] || 'Invalid input format.';
    }
  
    // Default placeholders
    const defaultPlaceholders: { [key: string]: string } = {
      cardNumber: 'Card number',
      expireMonth: 'Expire Month',
      expireYear: 'Expire Year',
      cardCode: 'CVV',
      nameOnCard: 'Name on card',
      address1: 'Address',
      city: 'City',
      state: 'State',
      zipCode: 'Zip Code',
      taxAmount:'Tax Amount',
      processingFee:'Processing Fee',
      amount:'Total Amount'
    };
  
    return defaultPlaceholders[fieldName] || 'Enter value';
  }
 
  getExpireMonthPlaceholder() {
    const expireMonthControl = this.formData.get('expireMonth');
    
    if (expireMonthControl?.touched && expireMonthControl?.hasError('required')) {
      return 'Expire Month is required.';
    }
  
    return 'Expire Month'; // Default placeholder
  }
  
  getExpireYearPlaceholder() {
    const expireYearControl = this.formData.get('expireYear');
    
    if (expireYearControl?.touched && expireYearControl?.hasError('required')) {
      return 'Expire Year is required.';
    }
  
    return 'Expire Year'; // Default placeholder
  }
  

  
 

onUpdateCard($event: Event) {
throw new Error('Method not implemented.');
}

}
