import { Component } from '@angular/core';

@Component({
  selector: 'app-renewal-report-detail',
  templateUrl: './renewal-report-detail.component.html',
  styleUrls: ['./renewal-report-detail.component.scss']
})
export class RenewalReportDetailComponent {

}
