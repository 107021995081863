<div class="card p-3 mb-3">
    <div class="row mb-3">
      <div class="col-md-2">
        <div class="bg-light skeleton-image w-100" style="height: 100px;"></div>
      </div>
      <div class="col-md-5">
        <h6 class="bg-light w-50 skeleton-text"></h6>
        <p class="bg-light w-75 skeleton-text"></p>
      </div>
      <div class="col-md-5 text-end">
        <div class="d-inline-block bg-light w-25 skeleton-text"></div>
        <div class="mt-3">
          <div class="bg-light w-50 skeleton-button" style="height: 30px;"></div>
        </div>
      </div>
    </div>
  
    <div class="row mb-3">
      <div class="col-md-6">
        <p class="bg-light w-100 skeleton-text"></p>
      </div>
      <div class="col-md-6">
        <p class="bg-light w-50 skeleton-text"></p>
      </div>
    </div>
  
    <div class="row mb-3">
      <div class="col-md-6">
        <p class="bg-light w-100 skeleton-text"></p>
        <p class="bg-light w-75 skeleton-text"></p>
      </div>
      <div class="col-md-6">
        <p class="bg-light w-100 skeleton-text"></p>
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-6">
        <p class="bg-light w-100 skeleton-text"></p>
      </div>
      <div class="col-md-6">
        <p class="bg-light w-100 skeleton-text"></p>
      </div>
    </div>
  </div>
  