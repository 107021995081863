import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service'; 
import { environment } from 'src/environments/environment.dev';


@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private inactivityTime: number;
  private activityTimeout: any;
  private authService: AuthService;
  private modalRef: any; 
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private ngZone: NgZone,
    private injector: Injector
  ) {
    this.inactivityTime=environment.INACTIVITY_SCREEN_TIME_IN_MINIUTES * 60 * 1000;
    this.setActivityListeners();
  }
  setModalRef(modalRef: any) {   // Method to set the modal reference
    this.modalRef = modalRef;
  }
  private resetTimer() {
    clearTimeout(this.activityTimeout);
      this.activityTimeout = setTimeout(() => this.showInactivityLogoutDialog(), this.inactivityTime);
    
  }

  
  private closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();  // Close the modal
      this.modalRef = null;  // Reset the reference
    }
  }
  private setActivityListeners() {
    this.ngZone.runOutsideAngular(() => {
      ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'].forEach(event => {
        window.addEventListener(event, () => this.resetTimer());
      });
    });
    // console.log('setActivityListeners')
  }
  private getAuthService(): AuthService {
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }
    return this.authService;
  }
  private showInactivityLogoutDialog(): void {
    let timeoutId: number;
  
    this.ngZone.run(() => {
      Swal.fire({
        title: 'Session Expired',
        text: 'You have been logged out due to inactivity.',
        icon: 'warning',  
        confirmButtonText: 'OK',
        allowOutsideClick: false, 
        allowEscapeKey: false 
      }).then((result) => {
        clearTimeout(timeoutId);
        if (result.isConfirmed) {
          this.closeModal(); 
          localStorage.clear();
          console.clear();
          this.getAuthService().logout();
        }   
        return result.isConfirmed;
      });
  
      timeoutId = window.setTimeout(() => {
        Swal.close();
        localStorage.clear();
        console.clear();
        this.getAuthService().logout();
      }, environment.INACTIVITY_SCREEN_TIMEOUT_TIME_IN_Millisconds); 
    });
  }
  

  public start() {
    this.resetTimer();
    // console.log('activityTimeout started')

  }

  stop() {
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout);
      this.activityTimeout = null;
      // console.log('activityTimeout')
    }
    ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'].forEach(event => {
      window.removeEventListener(event, this.resetTimer);
    });
    // console.log('removeEventListener')

  }
}
