import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [MessageService]
})
export class ToastComponent {
  constructor(public messageService: MessageService, public commonService: CommonService) {
    this.commonService.toastSubject.subscribe((toastData:any) => {
      if (toastData.type == "success") {
        this.messageService.add({ severity: 'success', summary: toastData.title, detail: toastData.message });
      } else if (toastData.type == "info") {
        this.messageService.add({ severity: 'info', summary: toastData.title, detail: toastData.message });
      } else if (toastData.type == "warn") {
        this.messageService.add({ severity: 'warn', summary: toastData.title, detail: toastData.message });
      } else if (toastData.type == "error") {
        this.messageService.add({ severity: 'error', summary: toastData.title, detail: toastData.message });
      }
    })
  }
}
