<div class="card p-3 mb-3">
    <div class="d-flex justify-content-between mb-3">
      <!-- Address Info Title Skeleton -->
      <h6 class="bg-light skeleton-text w-25"></h6>
      <!-- Update Address Button Skeleton -->
      <div class="bg-light skeleton-button w-25" style="height: 30px;"></div>
    </div>
  
    <div class="row">
      <!-- Address Field Skeleton -->
      <div class="col-md-6">
        <p class="bg-light w-50 skeleton-text"></p>
      </div>
  
      <!-- City Field Skeleton -->
      <div class="col-md-6">
        <p class="bg-light w-50 skeleton-text"></p>
      </div>
    </div>
  
    <div class="row">
      <!-- State Field Skeleton -->
      <div class="col-md-6">
        <p class="bg-light w-50 skeleton-text"></p>
      </div>
  
      <!-- Zip Field Skeleton -->
      <div class="col-md-6">
        <p class="bg-light w-50 skeleton-text"></p>
      </div>
    </div>
  
    <div class="row">
      <!-- Country Field Skeleton -->
      <div class="col-md-6">
        <p class="bg-light w-50 skeleton-text"></p>
      </div>
    </div>
  </div>
  