<div class="card">
    <div class="card-header p-10">
        <h6 class="mb-0">Add Pay Invoice</h6>
    </div>
    <div class="card-body">
        <div class="form theme-form">
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Customer ID</label>
                        <input class="form-control" type="text" placeholder="Customer ID" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Amount (A) ($) <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" placeholder="Amount (A) ($)" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Processing Fess (B) ($)</label>
                        <input class="form-control" type="text" placeholder="Processing Fess (B) ($)" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label>Total Amount</label>
                        <input class="form-control" type="text" placeholder="Total Amount" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="mb-3">
                        <label>Payment Mode <span class="text-danger">*</span></label>
                        <div class="m-t-15 m-checkbox-inline custom-radio-ml">
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline1" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline1">Credit Card</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline2" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline2">Cash</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline2" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline2">Echeck</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline3" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline3">Wallet Balance</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline4" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline4">Money Order</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline5" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline5">Money Gram</label>
                            </div>
                            <div class="form-check form-check-inline radio radio-primary">
                                <input class="form-check-input" id="radioinline6" type="radio" name="radio1"
                                    value="option1" />
                                <label class="form-check-label mb-0" for="radioinline6">Cheque</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-end">
        <button class="btn btn-primary me-1">Submit</button>
        <button class="btn btn-secondary">Cancel</button>
    </div>
</div>