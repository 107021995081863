import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AwaitingShipmentComponent } from "./awaiting-shipment.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { EditOrderComponent } from "./edit-order/edit-order.component";
import { RefundComponent } from "./refund/refund.component";

const routes: Routes = [
  {
    path: "",
    component: AwaitingShipmentComponent,
    children: [],
  },
  {
    path: "order-details/:id",
    component: OrderDetailsComponent,
  },
  {
    path: "refund/:id",
    component: RefundComponent,
  },
  {
    path: "edit-order/:id",
    component: EditOrderComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AwaitingShipmentRoutingModule {}
