<div class="timeline-container">
    <div *ngFor="let log of logData" class="timeline-item">
        <div class="timeline-icon" [ngClass]="log?.status.toLowerCase()">
            <i class="status-icon" [ngClass]="log?.status === 'New' ? 'fa fa-check-circle' : 'fa fa-times-circle'"></i>
        </div>
        <div class="timeline-content">
            <h6 [ngClass]="log?.status.toLowerCase()">Status: {{ log?.status }}</h6>
            <div class="d-flex justify-content-between">
                <div>
                    <p><strong>Action:</strong> {{ log?.action }}</p>
                    <p><strong>Type:</strong> {{ log?.type }}</p>
                    <p><strong>PO ID:</strong> {{ log?.po_id }}</p>
                </div>
                <div>
                    <p><strong>Customer ID:</strong> {{ log?.customer_id }}</p>
                    <p><strong>Created At:</strong> {{ log?.created_at }}</p>
                    <p><strong>Updated At:</strong> {{ log?.updated_at }}</p>
                </div>
            </div>
        </div>
    </div>
</div>