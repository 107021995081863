<div id="mini1" class="panel-container sliding-panel bg-light p-0 b-muted" [ngClass]="{'open': isPanelOpen, 'minimized': isMinimized}">
  <div class="container-fluid main-container col-12 p-0">
    <div class="notes-panel open-notes">
      <div class="panel-header container-fluid d-flex justify-content-between align-items-center p-3 border-bottom bg-light shadow-sm rounded">
        <div class="d-flex align-items-center">
          <!-- Optional Icon -->
          <h5 class="mb-0 text-primary " style="font-size: 1.2rem;letter-spacing: 2px;">MORE INFORMATION</h5>
        </div>
    
        <div class="d-flex gap-2 align-items-center">
          <i [ngClass]="isMinimized ? 'fa-solid fa-expand' : 'fa-solid fa-minus'" 
          class="text-primary" 
          style="cursor: pointer; font-size: 1rem;" 
          (click)="minimize()"></i>
          <i class="fa fa-times text-primary" aria-label="Close" style="cursor: pointer; font-size: 1.25rem;" (click)="togglePanel()"></i>
        </div>
      </div>

      <div class="main-content d-flex flex-row justify-content-between align-items-center">
        <div class="row flex-grow-1 w-100 mb-0" >
          <!-- Left Panel -->
          <div class="col-3 bg-light flex-grow-1 p-1 left-panel-container" >
            <app-customer-profile-view-left-panel></app-customer-profile-view-left-panel>
          </div>

          <!-- Right Panel -->
          <div class="col-9 right-panel p-0">
            <div class="card-body ">
              <app-customer-profile-view-right-panel></app-customer-profile-view-right-panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
