<div class="col-sm-12 mt-2">
    <div class="card p-20">
        <!-- <div class="row">
            <div class="col-12">
                <ngb-accordion #accT="ngbAccordion">
                    <ngb-panel id="toggle-1" title="Search Filter">
                        <ng-template ngbPanelContent>
                            <div class="d-flex">
                                <div class="input-control">
                                    <label>From</label>
                                    <input type="date" [(ngModel)]="startDate" placeholder="From"
                                        class="form-control" />
                                </div>
                                <div class="input-control ms-2">
                                    <label>To</label>
                                    <input type="date" [(ngModel)]="endDate" placeholder="To" class="form-control" />
                                </div>
                                <div class="input-control ms-2 d-flex align-items-end">
                                    <input type="search" [(ngModel)]="searchQuery" placeholder="Ticket Name"
                                        class="form-control" />
                                </div>
                                <div class="input-control ms-2 d-flex align-items-end">
                                    <app-feather-icons (click)="clearDateFilter()" [icon]="'x-circle'"
                                        class="cursor-pointer"></app-feather-icons>
                                    <app-feather-icons (click)="getTickets()" [icon]="'search'"
                                        class="ms-2 cursor-pointer"></app-feather-icons>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div> -->

        <div class="row m-t-20 m-b-20 border-bottom pb-3">
            <div class="col-md-6 my-auto">
                <div class="btn-showcase my-3">
                    <ng-container *ngFor="let source of sourceType;let i = index">
                        <ng-container>
                            <a class="tab-link mx-2 cursor-pointer"
                                [ngClass]="{'tab-border-bottom-primary': source.active}"
                                (click)="filterDataBySource(source,i)">
                                <i class="icofont icofont-ui-home"></i> {{source.DisplayName}}
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-6 my-auto">
                <div class="pull-right" *ngIf="!isDisabledMyTicket">
                    <div class="dropdown">
                        <span class="d-flex align-items-center gap-1">
                            <i (click)="toggleDropdown()" class="fas fa-ellipsis-h "
                                style="cursor: pointer; font-size: 24px;"> </i> Actions
                        </span>

                        <ul class="dropdown-menu" [class.show]="dropdownOpen" aria-labelledby="dropdownMenuButton">
                            <ng-container *ngFor="let button of buttons">
                                <ng-container *ngIf="button.resourcePermission.CanView == 1">
                                    <li>
                                        <span
                                            (click)="performAction(button,addEditTicketModal,TicketTypeModal,groupModal,addEditGroupModal)"
                                            class="dropdown-item" type="button">
                                            {{button?.DisplayName}}
                                        </span>
                                    </li>
                                    <li *ngIf="button.Name=='DOWNLOAD_TICKETS'">
                                        <button (click)="downloadAllTickets()" class="dropdown-item" type="button">
                                            DOWNLOAD ALL TICKETS
                                        </button>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </div>

                    <ng-container *ngIf="selectedIds?.length">
                        <div class="col-6 my-2">
                            <ng-select placeholder="Change Ticket Status" (change)="changeTicketStatus($event)">
                                <ng-option *ngFor="let item of allStatusList"
                                    [value]="item.id">{{item.Name}}</ng-option>
                            </ng-select>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="table-responsive signal-table">
            <table class="table table-hover"  *ngIf="!isSkeletonLoader"  @fadeInOut >
                <thead>
                    <tr>
                        <ng-container *ngFor="let item of column">
                            <th [sortable]="item.value" (sort)="onSort($event)" scope="col"
                                *ngIf="item.value !=='UpdateStatus'">
                                {{item.displayName }}
                            </th>
                            <th [sortable]="item.value" scope="col" *ngIf="item.value === 'UpdateStatus'"
                                class="updateStatus">
                                <input type="checkbox"
                                    [checked]="data?.length > 0 && data?.length === selectedIds?.length"
                                    (change)="selectAllCheckboxes($event)"> {{ item.displayName }}
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr class="shadow-showcase" *ngFor="let item of data;">
                        <ng-container *ngFor="let row of column;">
                            <ng-container *ngIf="row.displayName == '#'">
                                <td (click)="openModal(addCommentModal,item.id)">
                                    <a class="text-primary cursor-pointer">
                                        {{item[row.value]}}
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="row.value=== 'UpdateStatus'">
                                <td>
                                    <input type="checkbox" [checked]="isSelected(item.id)"
                                        (click)="toggleCheckbox(item.id)">
                                </td>
                            </ng-container>
                            <ng-container *ngIf="row.value == 'CreatedDate' || 
                            row.value == 'ClosedDate' ||row.value == 'UpdatedDate' ||row.value == 'CallbackDate'
                            ">
                                <td (click)="openModal(addCommentModal,item.id)">
                                    <a>
                                        {{item[row.value] | date:'mediumDate'}}
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container
                                *ngIf="row.displayName != 'Action' && row.displayName != '#' &&  row.value != 'UpdateStatus' && row.value != 'CreatedDate' && row.value != 'ClosedDate' && row.value != 'UpdatedDate' && row.value != 'CallbackDate'">
                                <td>
                                    {{item[row.value]}}
                                </td>
                            </ng-container>
                            <ng-container *ngIf="row.displayName == 'Action'">
                                <td>
                                    <i class="fa fa-edit cursor-pointer"
                                        (click)="onEdit(item.id,addEditTicketModal)"></i>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr *ngIf="isEmptyRow">
                        <td [colSpan]="column.length">
                            <div class="font-danger text-center">Sorry!!! No record matching....</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="isSkeletonLoader"  @fadeInOut  style="max-width: 100%;" class="m-2" >
                <app-skeleton-loader [tableColumn]="8" [tableRow]="8" shape="table"></app-skeleton-loader>
        </div>
            <div class="pull-right p-20 d-flex"  >
                <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                    (change)="onChangeNumberOfPage()">
                    <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                </select>
                <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true"
                    [collectionSize]="totalItems" [(page)]="currentPage" (pageChange)="pageChange($event)"
                    [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
            </div>
            
        </div>
    </div>
</div>

<ng-template #modalPrompt let-modal>
    <div class="modal-body">
        <div class="close-modal p-absolute" (click)="modal.close()">
            <i class="icon-close"></i>
        </div>
        <div class="p-20">
            <h5 class="m-b-3">No content is available</h5>
        </div>
        <div class="form-group mb-3 text-end">
            <button type="button" class="btn btn-light" (click)="modal.close()">Close</button>
            <button type="button" class="btn btn-primary m-l-10" disabled>Save and Close</button>
        </div>
    </div>
</ng-template>


<ng-template #addEditTicketModal let-modal>
    <div class="modal-header modal-copy-header">
        <h5 class="headerTitle mb-0">{{ticketId ? 'Edit' : 'Add'}} Ticket</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-add-edit-ticket (addEditTicketEvent)="addEditTicketEvent($event)" [id]="ticketId"></app-add-edit-ticket>
    </div>
</ng-template>

<ng-template #addCommentModal let-modal>
    <div class="modal-header modal-copy-header">
        <h5 class="headerTitle mb-0">Comments</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-manage-comments [ticketId]="ticketId" [allAgents]="allAgents" [ticketTypes]="ticketTypes"
            (commentEvent)="commentEvent($event)"></app-manage-comments>
    </div>
</ng-template>

<ng-template #addEditGroupModal let-modal>
    <div class="modal-header modal-copy-header">
        <h5 class="headerTitle mb-0">{{ticketId ? 'Edit' : 'Add'}} Group</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-add-edit-group (addEditGroupEvent)="addEditGroupEvent($event)" [id]="groupId"></app-add-edit-group>
    </div>
</ng-template>

<ng-template #groupModal let-modal>
    <div class="modal-header modal-copy-header">
        <h5 class="headerTitle mb-0">Groups</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-group-table></app-group-table>
    </div>
</ng-template>

<ng-template #TicketTypeModal let-modal>
    <div class="modal-header modal-copy-header">
        <h5 class="headerTitle mb-0">Ticket Type</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <app-ticket-type (ticketTypeOutputEvent)="ticketTypeOutputEvent($event)"></app-ticket-type>
    </div>
</ng-template>