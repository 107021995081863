<div class="dashboard-container">
    <!-- Stats Section -->
    <div class="stats-section">
      <div class="stat-card">
        <div class="skeleton stat-title"></div>
        <div class="skeleton stat-value"></div>
      </div>
      <div class="stat-card">
        <div class="skeleton stat-title"></div>
        <div class="skeleton stat-value"></div>
      </div>
      <div class="stat-card">
        <div class="skeleton stat-title"></div>
        <div class="skeleton stat-value"></div>
      </div>
      <div class="stat-card">
        <div class="skeleton stat-title"></div>
        <div class="skeleton stat-value"></div>
      </div>
    </div>
  
    <!-- Revenue Section -->
    <div class="revenue-section">
      <div class="skeleton chart"></div>
    </div>
  
    <!-- Billing Section -->
    <div class="billing-section">
      <div class="billing-card skeleton"></div>
      <div class="billing-card skeleton"></div>
    </div>
  
    <!-- Top Issues Section -->
    <div class="top-issues-section">
      <div class="skeleton issue-list"></div>
    </div>
  </div>
  