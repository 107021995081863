import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-topup-link',
  templateUrl: './add-topup-link.component.html',
  styleUrls: ['./add-topup-link.component.scss']
})
export class AddTopupLinkComponent {

  @Output() toggleTopup = new EventEmitter<boolean>();

  closeTopup() {
    this.toggleTopup.emit();
  }
  
}
