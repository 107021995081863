import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ManageNotesService {


  constructor(
    private http: HttpService,
  ) { }

  public getNoteTypeList():Observable<HttpResponse<any>>{
    let url="api/note/get-note-types"
    return this.http.get(url);
  }
  addNoteType(body:any){
    let url="api/note/add-note-type"
     return this.http.post(url,body,false,false)
   }
   getNoteTypeById(id:any){
    let url="api/note/get-note-type/" + id
     return this.http.get(url,null,false,false)
   }
   updateNoteTypeById(id:any,data){
    let url="api/note/update-note-type/" + id
     return this.http.put(url,data,false,false)
   }

}
