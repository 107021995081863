<div class="card">
    <div class="card-header p-10">
        <h6 class="mb-0">Add Wallet</h6>
    </div>
    <div class="card-body">
        <div class="form theme-form">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Customer ID</label>
                                <input class="form-control" type="text" placeholder="Customer ID" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Customer Name</label>
                                <input class="form-control" type="text" placeholder="Customer Name" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Processing Fee <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Processing Fee" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Total Amount <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Total Amount" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label>Card Number</label>
                                <input class="form-control" type="text" placeholder="Card Number" maxlength="16" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label>Name on Card</label>
                                <input class="form-control" type="text" placeholder="Name on Card" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Zip Code</label>
                                <input class="form-control" type="text" maxlength="5" placeholder="Zip Code" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>City</label>
                                <input class="form-control" type="text" placeholder="Zip Code" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Billing Address</label>
                                <textarea class="form-control" id="exampleFormControlTextarea4" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="d-block" for="chk-ani">
                                <input class="checkbox_animated" id="chk-ani" type="checkbox" checked=""> Do you want to
                                save card Details ?
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Phone Number</label>
                                <input class="form-control" type="text" placeholder="Phone Number" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label>Amount (in $) <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Amount (in $)" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label>Payment Mode <span class="text-danger">*</span></label>
                                <div class="m-t-15 m-checkbox-inline custom-radio-ml">
                                    <div class="form-check form-check-inline radio radio-primary">
                                        <input class="form-check-input" id="radioinline1" type="radio" name="radio1"
                                            value="option1" />
                                        <label class="form-check-label mb-0" for="radioinline1">Credit Card</label>
                                    </div>
                                    <div class="form-check form-check-inline radio radio-primary">
                                        <input class="form-check-input" id="radioinline2" type="radio" name="radio1"
                                            value="option1" />
                                        <label class="form-check-label mb-0" for="radioinline2">Cash</label>
                                    </div>
                                    <div class="form-check form-check-inline radio radio-primary">
                                        <input class="form-check-input" id="radioinline3" type="radio" name="radio1"
                                            value="option1" />
                                        <label class="form-check-label mb-0" for="radioinline3">Using Pin</label>
                                    </div>
                                    <div class="form-check form-check-inline radio radio-primary">
                                        <input class="form-check-input" id="radioinline4" type="radio" name="radio1"
                                            value="option1" />
                                        <label class="form-check-label mb-0" for="radioinline4">Add Wallet Throught Topup
                                            Card</label>
                                    </div>
                                    <div class="form-check form-check-inline radio radio-primary">
                                        <input class="form-check-input" id="radioinline5" type="radio" name="radio1"
                                            value="option1" />
                                        <label class="form-check-label mb-0" for="radioinline5">Skip Payment</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Type of Card</label>
                                <select class="form-control digits">
                                    <option>Hourly</option>
                                    <option>Fix price</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Security Code</label>
                                <input class="form-control" type="text" placeholder="Security Code" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>Receipt Number</label>
                                <input class="form-control" type="text" placeholder="Receipt Number" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>State/Province</label>
                                <select class="form-control digits">
                                    <option>Rajasthan</option>
                                    <option>Delhi</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Billing Address 2</label>
                                <textarea class="form-control" id="exampleFormControlTextarea4" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="d-block" for="chk-ani">
                                <input class="checkbox_animated" id="chk-ani" type="checkbox" checked="">Apply Address Suggestion
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-end">
        <button class="btn btn-primary me-1">Submit</button>
        <button class="btn btn-secondary">Cancel</button>
    </div>
</div>