import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {
  @Input() cardNumber: string = 'XXXX XXXX XXXX XXXX';
  @Input() cardHolder: string = 'CARDHOLDER NAME';
  @Input() expirationDate: string = 'MM/YY';
  @Input() cardType: 'mastercard' | 'visa' | 'amex' | 'discover' = 'visa';
  // 2nd section
  @Input() tracId: string;
  @Input() nameOnCard: string = 'CARDHOLDER NAME';
  @Input() accountNumber: string= 'XXXX XXXX XXXX XXXX';
  @Input() accountType: string;
  @Input() messageCode: string;
  @Input() createdDate: string = 'MM/YY';
  @Input() price: any;

  getCardLogo(): string {
    switch (this.accountType) {
      case 'Visa':
        return '../../../../../assets/images/credit-cards/visa.png';
      case 'MasterCard':
        return '../../../../../assets/images/credit-cards/card.png';
      case 'Amex':
        return '../../../../../assets/images/credit-cards/american-express.png';
      case 'Discover':
        return '../../../../../assets/images/credit-cards/discover.png';
      default:
        return '';
    }
  }
}
