import { Component } from '@angular/core';
@Component({
  selector: 'app-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrls: ['./customer-account.component.scss']
})
export class CustomerAccountComponent {

  // selectedTab:string = '';
  selectedTab:string = 'Change-Address';

  onSelectTab(tabName:string) {
    this.selectedTab = tabName
  }

}
