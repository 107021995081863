import { trigger, transition, style, animate } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [  // New element is entering
    style({ opacity: 0, transform: 'translateY(-10px)' }),  // Start invisible and slightly above
    animate('600ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))  // Animate to visible
  ]),
  transition(':leave', [  // Element is leaving
    animate('0ms ease-in', style({ opacity: 0, transform: 'translateY(10px)' }))  // Animate to invisible
  ])
]);

export const fadeInOutSlow = trigger('fadeInOutSlow', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-15px)' }),
    animate('1.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(15px)' }))
  ])
]);