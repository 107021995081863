<div class="global-search-container mx-0 p-0">
  <div class="row mt-2 container">
    <ng-container *ngFor="let badge of badges">
      <div *ngIf="badge?.show" class="col-auto custom-badge1 cursor-pointer mx-1 my-1"
        [ngClass]="{'bg-primary text-white': badge.id === selectedBadgeId}"
        (click)="badge.type === 'Recent Search' ? onClickRecentSearch() : setSearchQuery(badge.type)">
        <i *ngIf="badge.type === 'Customers'" class="fa fa-user m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-primary': badge.id !== selectedBadgeId}"></i>
        <i *ngIf="badge.type === 'Orders'" class="fa fa-folder m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-warning': badge.id !== selectedBadgeId}"></i>
        <i *ngIf="badge.type === 'Subscriptions'" class="fa fa-money m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-success': badge.id !== selectedBadgeId}"></i>
        <i *ngIf="badge.type === 'Tickets'" class="fa fa-ticket-alt m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-danger': badge.id !== selectedBadgeId}"></i>
        <i *ngIf="badge.type === 'Payments'" class="fa fa-credit-card m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-info': badge.id !== selectedBadgeId}"></i>
        <i *ngIf="badge.type === 'addresses'" class="fa fa-map-marker-alt m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-dark': badge.id !== selectedBadgeId}"></i>
        <i *ngIf="badge.type === 'Recent Search'" class="fa fa-search m-1"
          [ngClass]="{'badge-icon': badge.id === selectedBadgeId, 'text-muted': badge.id !== selectedBadgeId}"></i>
        {{ badge.type }}
      </div>
    </ng-container>
  </div>

  <div class="div" *ngIf="!isSkeletonLoader" @fadeInOut>
    <div class="search-suggestions mt-3" *ngIf="searchQuery && hasSearchResults()" @fadeInOut>
      <div class="section-title">Search Results</div>
      <div class="suggestion-item d-flex align-items-center justify-content-between" *ngFor="let result of searchResults; let i = index"
        (click)="onResultClick(result)">


        <div class="result-details ml-2">
          <div class="result-name">
            <ng-container *ngIf="result.type === 'Customers'">
              {{ result.firstName }} {{ result.lastName }}
            </ng-container>
            <ng-container *ngIf="result.type === 'Tickets'">
              <div class="d-flex  ">
                <span 
                class="custom-badge"
                [ngClass]="{
                  'badge-success': result.status === 'Resolved' || result.status === 'Closed',
                  'badge-danger': result.status === 'On Hold',
                  'badge-primary': result.status === 'In Progress',
                  'badge-warning': result.status === 'Escalated',
                  'badge-secondary': result.status === 'Open'
                }">
                {{ result.status }}
              </span>
              </div>
              {{ result.title }}
            </ng-container>
            <ng-container *ngIf="result.type === 'Orders'">

              <span
                  class="custom-badge"
                  [ngClass]="{
                    'badge-success': orderBadgeGroups['badge-success'].includes(result.orderStatus),
                    'badge-danger': orderBadgeGroups['badge-danger'].includes(result.orderStatus),
                    'badge-primary': orderBadgeGroups['badge-primary'].includes(result.orderStatus),
                    'badge-warning': orderBadgeGroups['badge-warning'].includes(result.orderStatus),
                    'badge-secondary': orderBadgeGroups['badge-secondary'].includes(result.orderStatus)
                  }"
                >
                {{ result.orderStatus }}
                </span>

            </ng-container>
            <ng-container *ngIf="result.type === 'Subscriptions'">
              <span 
                class="custom-badge"
                [ngClass]="{
                  'badge-success': result.status?.toLowerCase() === 'active',
                  'badge-warning': result.status?.toLowerCase() === 'suspend',
                  'badge-primary': result.status?.toLowerCase() === 'deactivated',
                  'badge-danger': result.status?.toLowerCase() === 'cancelled'
                }">
                {{ result.status }}
              </span>
            </ng-container>
            

            <ng-container *ngIf="result.type === 'Addresses'">
              Address ID: {{ result.id }}
            </ng-container>
          </div>

          <ng-container *ngIf="result.type === 'Customers'">
            <div class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center mr-3">
                <i class="fa fa-envelope text-primary mr-1"></i>
                <span class=" text-secondary"><small>{{ result.email }}</small></span>
              </div>
              <div class="d-flex align-items-center">
                <i class="fa-solid fa-phone text-success mr-1"></i>
                <span class=" text-secondary"><small>{{ result.primaryPhone }}</small></span>
              </div>
            </div>
          </ng-container> -->

          <ng-container *ngIf="result.type === 'Orders'">
            <div class="result-total">
              <h6 class="text-primary">Total Amount: <span class="text-dark">$ {{ result.totalAmount }}</span>
                <h6 class="text-secondary">Created Date: <span class="text-muted">{{ commonService.formatDate(result.createdDate) }}</span></h6>
              </h6>

              
            </div>

          </ng-container>

          <ng-container *ngIf="result.type === 'Subscriptions'">
            <div class="result-email">
              <h6 class="mr-2">Autorenew: <span *ngIf="result.autoRenew === 1">
                <i class="fa fa-check-circle text-success"></i>
              </span>
              <span *ngIf="result.autoRenew === 0">
                <i class="fa fa-times-circle text-danger"></i>
              </span>
              <h6>Next Billing: {{ commonService.formatDate(result.nextBillingDate) }}</h6>
            </h6>
              
            </div>

          </ng-container>

          <ng-container *ngIf="result.type === 'Tickets'">
            <div class="">

              <div class="d-flex  ">
                <h6 class="text-muted">Priority: <span class=" text-nowrap">{{ result.priority }}</span></h6>
                
              </div>
              
            </div>
          </ng-container>

          <ng-container *ngIf="result.type === 'Payments'">
            <div class="container-fluid p-4 rounded shadow-sm border bg-white">
              <div class="row g-4 align-items-center">
                <!-- Transaction ID -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-receipt text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Transaction ID</div>
                      <div class=" fw-semibold"><small>{{ result.tracId }}</small></div>
                    </div>
                  </div>
                </div>
                <!-- Name on Card -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-user text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Cardholder Name</div>
                      <div class=" fw-semibold"><small>{{ result.nameOnCard }}</small></div>
                    </div>
                  </div>
                </div>
                <!-- Card Number -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-credit-card text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Card Number</div>
                      <div class=" fw-semibold"><small>{{ result.accountNumber }}</small></div>
                    </div>
                  </div>
                </div>
                <!-- Card Type -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-wallet text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Card Type</div>
                      <div class=" fw-semibold"><small>{{ result.accountType }}</small></div>
                    </div>
                  </div>
                </div>
                <!-- Approval Message -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check-circle text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Approval Message</div>
                      <div class=" fw-semibold"><small>{{ result.messageCode }}</small></div>
                    </div>
                  </div>
                </div>
                <!-- Transaction Amount -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-dollar-sign text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Transaction Amount</div>
                      <div class=" fw-semibold"><small>{{ result.amount }}</small></div>
                    </div>
                  </div>
                </div>
                <!-- Transaction Date -->
                <div class="col-md-4 col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-calendar text-primary fa-lg me-3"></i>
                    <div>
                      <div class="text-muted small fw-bold">Transaction Date</div>
                      <div class=" fw-semibold"> <small>{{ commonService.formatToLocalDateTime(result.createdDate,
                        'date') }}</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </ng-container>





            <ng-container *ngIf="result.type === 'Addresses'">
              <div class="result-details">
                <strong class="text-primary">Service Address:</strong>
                <span class="text-dark">{{ result.serviceAddress1 }}, {{ result.serviceAddress2 || '' }}</span>
              </div>
              <div class="result-details">
                <strong class="text-secondary">City ID:</strong>
                <span class="text-muted">{{ result.cityId }}</span>
              </div>
              <div class="result-details">
                <strong class="text-secondary">Zip Code:</strong>
                <span class="text-muted">{{ result.zipId }}</span>
              </div>
            </ng-container>
          </div>
          <div class="div" *ngIf="result.type !== 'Payments'">
            <div class="custom-badge">


              <ng-container *ngIf="result.type === 'Customers'">
                # {{ result.customerId }}
              </ng-container>
              <ng-container *ngIf="result.type === 'Subscriptions'">
                # {{ result.subscriptionId }}
              </ng-container>

              <ng-container *ngIf="result.type === 'Tickets'">
                # {{ result.id }}
              </ng-container>
              <ng-container *ngIf="result.type === 'Orders'">
                # {{ result.id }}
              </ng-container>

            </div>
          </div>


        </div>
        <!-- <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="!isSkeletonLoader" @fadeInOut>
        <span *ngFor="let color of colorClasses" class="d-flex align-items-center mr-2">
          <div class="rounded-circle" [style.backgroundColor]="color" style="width: 15px; height: 15px;"></div>
        </span>
        <span class="ml-2 text-muted">
          {{ selectedBadgeId === 1 ? 'CustomerID' : selectedBadgeId === 2 ? 'OrderID' : selectedBadgeId === 3 ? 'SubscriptionID' :selectedBadgeId === 5 ? 'PaymentID': 'TicketID' }}
        </span>
      </div> -->
      </div>

      <div class="no-search-suggestions mt-3" *ngIf="noResultFound && !hasSearchResults()">
        <img src="assets/svg/no-item.jpg" alt="No Results Found" class="no-results-image">
        <p class="no-results-text">No search items available.<br>Try typing to see suggestions.</p>
      </div>
    </div>

    <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
      <div class="card m-2">
        <app-skeleton-loader [loaderCount]="5" shape="data-loading"></app-skeleton-loader>
      </div>
    </div>

    <div class="search-suggestions mt-0" *ngIf="isRecentSearch" @fadeInOut>
      <div class="container  p-0 recent-searches">
        <span class=" recent-text text-muted">Recents</span>
        <div class="div" *ngIf="recentSearches.length>0">
          <div *ngFor="let search of recentSearches.slice().reverse()"
            class="card mb-3 p-3 shadow-sm border-0 cursor-pointer d-flex flex-row justify-content-between align-items-center"
            (click)="handleResultClick(
         search.activeTab === 'Customers'
           ? search.items?.customerId || search.items?.CustomerID
           : search.activeTab === 'Subscriptions'
           ? search.items?.subscriptionId
           : search.activeTab === 'Tickets'
           ? search.items?.id
           : search.activeTab === 'Orders'
           ? search.items?.id
           : search.activeTab === 'Payments'
           ? { id: search.items?.id, tracId: search.items?.tracId }
           : search.items?.id,
         search.activeTab,
         search.activeTab === 'Orders' ? search.items?.customerId : undefined,
         search.searchTerm
       )">
          <div class="d-flex align-items-center">
            <!-- Icon Section -->
            <div class="rounded-circle d-flex align-items-center justify-content-center"
              style="background-color: #f7f7f7; width: 50px; height: 50px;">
              <i *ngIf="search.activeTab?.toLowerCase() === 'customers'" class="fa fa-user text-primary fa-lg"></i>
              <i *ngIf="search.activeTab?.toLowerCase() === 'orders'" class="fa fa-folder-open text-warning fa-lg"></i>
              <i *ngIf="search.activeTab?.toLowerCase() === 'subscriptions'" class="fa fa-star text-danger fa-lg"></i>
              <i *ngIf="search.activeTab?.toLowerCase() === 'tickets'" class="fa fa-ticket-alt text-success fa-lg"></i>
              <i *ngIf="search.activeTab?.toLowerCase() === 'payments'" class="fa fa-credit-card text-info fa-lg"></i>
              <i *ngIf="search.activeTab?.toLowerCase() === 'addresses'" class="fa fa-map-marker-alt text-dark fa-lg"></i>              
            </div>

              <!-- Search Term Section -->
              <div class="ml-3">
                <div class="text-muted label">Search Term:</div>
                <div class="text-dark font-weight-bold">{{ search.searchTerm }}</div>
              </div>
            </div>





            <!-- Tab Section -->
            <div class="d-flex flex-column align-items-start ml-3">
              <div class="d-flex align-items-center">
                <i class="fa fa-filter mr-2 text-primary"></i>
                <span class="text-muted label">Tab:</span>
                <span class="ml-2 text-capitalize text-dark">{{ search.activeTab }}</span>
              </div>
              <ng-container [ngSwitch]="search.activeTab">
                <div *ngSwitchCase="'Customers'" class="d-flex align-items-center">
                  <i class="fa fa-id-badge text-primary mr-2"></i>
                  <span class="text-dark font-weight-bold">
                    {{ search.items?.customerId || search.items?.CustomerID }}
                  </span>
                </div>
                <div *ngSwitchCase="'Orders'" class="d-flex align-items-center">
                  <i class="fa fa-receipt text-warning mr-2"></i>
                  <span class="text-dark font-weight-bold">{{ search.items?.id }}</span>
                </div>
                <div *ngSwitchCase="'Tickets'" class="d-flex align-items-center">
                  <i class="fa fa-ticket-alt text-success mr-2"></i>
                  <span class="text-dark font-weight-bold">{{ search.items?.id }}</span>
                </div>
                <div *ngSwitchCase="'Subscriptions'" class="d-flex align-items-center">
                  <i class="fa fa-star text-danger mr-2"></i>
                  <span class="text-dark font-weight-bold">{{ search.items?.subscriptionId }}</span>
                </div>
                <div *ngSwitchCase="'Payments'" class="d-flex align-items-center">
                  <i class="fa fa-credit-card text-info mr-2"></i>
                  <span class="text-dark font-weight-bold">{{ search.items?.id }}</span>
                </div>
                <div *ngSwitchCase="'addresses'" class="d-flex align-items-center">
                  <i class="fa fa-map-marker-alt text-dark mr-2"></i>
                  <span class="text-dark font-weight-bold">{{ search.items?.id }}</span>
                </div>
              </ng-container>
            </div>

            <!-- Timestamp Section -->
            <div class=" text-right">
              <div class="text-muted small label ">
                {{ commonService.formatToLocalDateTime(search.timestamp, 'date') }}
              </div>
              <div class="text-muted label font-weight-light">
                {{ commonService.formatToLocalDateTime(search.timestamp, 'time') }}
              </div>
            </div>
          </div>




        </div>
        <div>
        </div>

        <div *ngIf="recentSearches.length <= 0" class="text-center mt-3 p-3">
          <p class="text-muted font-weight-bold">No Recent Searches</p>
        </div>
      </div>
    </div>
  </div>