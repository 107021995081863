

<div class="custom-card">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex gap-3 align-items-center">

      <span class="custom-badge">
        #{{paymentData?.number}}
      </span>
      <span class="custom-badge">
        <i class="fa fa-circle"></i>
        {{paymentData?.status}}
      </span>
    </div>
    <span>{{ paymentData?.due_date | date: 'MM/dd/yyyy' }}</span>
  </div>
  <div class="border mt-3">
    <div class="items gap-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between gap-10">
          <span>Due Amount</span>
          <!-- <span> {{order?.order_items?.length}} item</span> -->
        </div>
        <span class="">${{ paymentData?.due_amount }}</span>
      </div>

      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between gap-10">
          <span>Discount</span>
        </div>
        <span class="">${{ paymentData?.discount }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between gap-10">
          <span>Tax</span>
        </div>
        <span class="">${{ paymentData?.tax }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between gap-10">
          <span>Subtotal</span>
        </div>
        <span class="">${{ paymentData?.subtotal }}</span>
      </div>
      
    </div>
    <div class="items border-bottom-0">
      <div class="d-flex gap-5 align-items-center justify-content-between">
        <span class="fw-semibold">Total Amount </span>
        <!-- <span>${{paidTotal.toFixed(2)}}</span> -->
        <span>${{ paymentData?.total_amount }}</span>
      </div>
    </div>

  </div>

</div>