import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-column-form-skeleton',
  templateUrl: './dynamic-column-form-skeleton.component.html',
  styleUrls: ['./dynamic-column-form-skeleton.component.scss']
})
export class DynamicColumnFormSkeletonComponent implements OnInit {

  @Input() columnCount: number = 5; // Number of columns
  @Input() rowCount: number = 5;
  columnsArray: any[] = []; // Array to hold columns and rows

  ngOnInit(): void {
    // Populate the columnsArray with rows for each column
    this.columnsArray = Array(this.columnCount).fill(0).map(() => {
      // Each column will have 5 rows for now (you can change this)
      return Array(this.rowCount).fill(0); // Generate 5 rows per column
    });
  }
}
