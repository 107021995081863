import { Component } from '@angular/core';

@Component({
  selector: 'app-free-topup',
  templateUrl: './free-topup.component.html',
  styleUrls: ['./free-topup.component.scss']
})
export class FreeTopupComponent {

}
