import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { CommonService } from '../../services/common.service';
import { OrderReviewService } from 'src/app/review/services/order-review.service';
import { Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-advance-search-order',
  templateUrl: './advance-search-order.component.html',
  styleUrls: ['./advance-search-order.component.scss']
})
export class AdvanceSearchOrderComponent {

  advanceSearchOrderForm: FormGroup;
  countryOptions = [];
  stateOptions = [];
  zipcodeOptions = [];
  advanceSearchOrderValues: any;
  selectedStatus = [];
  cards: any;
  orderType:any;
  accountType:any;
  customerStatus:any

  column = [];
  advanceSearchOrderData = [];
  @Output() closeAdvanceSearchOrder: EventEmitter<any> = new EventEmitter();
  @Output() submitAdvanceSearchOrder: EventEmitter<any> = new EventEmitter();
  @ViewChild('accT') accordion: NgbAccordion;
isSkeletonLoader: boolean=true;
  constructor(
    public searchService: SearchService,
    public commonService: CommonService,
    public fb: FormBuilder,
    public orderReviewService: OrderReviewService,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.initializeAdvanceSearchOrder()
    this.getCountries()
    this.getOrderCards();
    this.getOrderType();
    this.getAccountType();
    this.getCustomerStatus();
  }

  // getOrderType
  async getOrderType(){
    
    try {
      const res: any = await this.searchService.getOrderType();

      if (res.Status) {
        this.orderType = res.data;
        console.log("orderType :-",  this.orderType )
        this.isSkeletonLoader=false;
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }


  async getAccountType(){
    
    try {
      const res: any = await this.searchService.getAccountType();

      if (res.Status) {
        this.accountType = res.data;
        console.log("accountType :-",  this.accountType )

      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }


  async getCustomerStatus(){
    
    try {
      const res: any = await this.searchService.getCustomerStatus();

      if (res.Status) {
        this.customerStatus = res.data;
        console.log("customerStatus :-",  this.customerStatus )
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }

  initializeAdvanceSearchOrder() {
    this.advanceSearchOrderForm = this.fb.group({
      "CustomerId": [null],
      "nvApplicationId": [null],
      "nladSubscriberId":[null],
      "states": [null],
      "zipCode": [null],
      "enrollmentId": [null],
      "firstName": [null],
      "lastName": [null],
      "country": [''],
      "fromDate": [null],
      "toDate": [null],
      "alternateContactNumber": [null],
      "fullName": [null],
      // "esnNumber": [null],
      "address": [null],
      "OrderTypeID":[''],
      "CustomerStatusID":[null],
      "PlanTypeID":[null],
    })
  }

  get country() {
    return this.advanceSearchOrderForm.get("country")
  }
  get states() {
    return this.advanceSearchOrderForm.get("states")
  }

  resetAdvanceSearchOrder() {
    this.advanceSearchOrderForm.reset();
    this.advanceSearchOrderData = [];
    this.selectedStatus = [];
    this.closeAdvanceSearchOrder.emit()
    this.accordion.collapse('toggle-1');
  }

  onSelectCountry() {
    this.getStates()
  }

  async getCountries() {
    
    try {
      const res: any = await this.commonService.getCountries();

      if (res.Status) {
        this.countryOptions = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }

  async getStates() {
    
    try {
      let data = {
        "CountryID": this.country.value
      }
      const res: any = await this.commonService.getStates(data);

      if (res.Status) {
        this.stateOptions = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }

  async onSubmitAdvanceSearchOrder() {
    
    try {
      let FormData: any = this.advanceSearchOrderForm.value

      FormData = this.commonService.removeNullOrBlank(FormData)
      let data = {
        ...FormData,
        "status[]": this.selectedStatus ?? '',
        "states[]": FormData.states ?? ''
      }
      if (data['status[]'] == '') {
        delete data['status[]']
      }
      if (data['states[]'] == '') {
        delete data['states[]']
      }
      delete data.states
      const res: any = await this.orderReviewService.advanceOrderSearch(data);
      if (res.Status) {
        setTimeout(() => {
          this.searchService.advanceSearchOrderData.next(res)
        }, 500);
        this.submitAdvanceSearchOrder.emit(res.data)
        this.resetAdvanceSearchOrder()
      } else {
        this.commonService.showToast('error', "Error", res.message)

      }

    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))

    }
    
  }

  onClickStatus(event) {
    let index = this.selectedStatus.findIndex(el => el == event)
    if (index == -1) {
      this.selectedStatus.push(event)
    } else {
      this.selectedStatus.splice(index, 1)
    } 
  }

  async getOrderCards() {
    
    try {
      const res: any = await this.orderReviewService.getOrderCards()
      if (res.Status) {
        this.cards = res.data.CARDS.RO_DASHBOARD_CARD
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }

}
