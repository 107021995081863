import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root' 
})
export class RequestStateService {
  private requestInProgressSubject = new BehaviorSubject<boolean>(false);
  requestInProgress$: Observable<boolean> = this.requestInProgressSubject.asObservable();

  setRequestInProgress(state: boolean): void {
    this.requestInProgressSubject.next(state);
  }
}
