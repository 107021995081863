import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RegisteredCard } from '../shared/interfaces/registered-cards.interface';
import { PanelStateService } from '../PanelStateService';

@Component({
  selector: 'app-customer-profile-view',
  templateUrl: './customer-profile-view.component.html',
  styleUrls: ['./customer-profile-view.component.scss']
})
export class CustomerProfileViewComponent implements OnInit {
  isPanelOpen = false;
  isMinimized = false;
  @Output() isCustomerProfileViewEnabled = new EventEmitter<boolean>();
constructor(private panelStateService: PanelStateService){

}
  ngOnInit(): void {
    this.panelStateService.panelMinimized$.subscribe(minimized => {
      this.isMinimized = minimized;
      if (!this.isMinimized) {
        this.isPanelOpen = true;  
      }
    });
  }

  togglePanel() {
    this.isPanelOpen = !this.isPanelOpen;
    this.isCustomerProfileViewEnabled.emit(this.isPanelOpen)
    this.panelStateService.setPanelMinimized(this.isMinimized);
  }
  minimize() {
    this.isMinimized = !this.isMinimized;  // Toggle the minimized state
    this.panelStateService.setPanelMinimized(this.isMinimized);  // Update the shared state
  }
  
}
