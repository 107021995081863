import { Component } from '@angular/core';

@Component({
  selector: 'app-add-topup',
  templateUrl: './add-topup.component.html',
  styleUrls: ['./add-topup.component.scss']
})
export class AddTopupComponent {
  public customizer: string = "";
list:any =[
{name:"Credit Card", link:'/customer/quick-links/add-topup/creditcard'},
{name:"Cash", link:'/customer/quick-links/add-topup/cash'},

{name:"Money Order", link:'/customer/quick-links/add-topup/money-order'},
{name:"Money Gram", link:'/customer/quick-links/add-topup/money-gram'},
{name:"Wallet", link:'/customer/quick-links/add-topup/wallet'},
{name:"Dealer Wallet", link:'/customer/quick-links/add-topup/dealer-wallet'},
{name:"Free Top UP", link:'/customer/quick-links/add-topup/free-topup'},

]

Customizer(val) {
  this.customizer = val;
}
}
