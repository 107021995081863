import { Component } from '@angular/core';

@Component({
  selector: 'app-money-order',
  templateUrl: './money-order.component.html',
  styleUrls: ['./money-order.component.scss']
})
export class MoneyOrderComponent {

}
