<div class="container-fluid p-2">
  <div class="row">
    <div class="col-md-4" *ngFor="let item of [1,2,3,4,5,6]">
      <!-- <div class="card  p-3" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home-tab1"
        (click)="Customizer('option')">
        <div class="d-flex">
          <div class="w-25">
            <img src="assets/images/mobile.png" class="card-img">
          </div>
          <div class="right-content ms-2">
            <div class="d-flex justify-content-between">
              <h5>Order Id:</h5>
              <span>1024</span>
            </div>
            <div class="d-flex justify-content-between">
              <h6>Product:</h6>
              <span>S23 Ultra</span>
            </div>
            <div class="d-flex justify-content-between">
              <h6>Make:</h6>
              <span>dsfsd323</span>
            </div>
            <div class="d-flex justify-content-between">
              <h6>Quantity:</h6>
              <span>1</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="card course-box cursor-pointer" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home-tab1"
      (click)="Customizer('option')">
        <div class="card-body">
          <!-- <div class="course-widget">
            <div>
              <h5 class="mb-0">Other Information</h5>
              <p class="f-light">Type</p>
            </div>
          </div> -->
          <div class="d-flex">
            <div class="w-25">
              <img src="assets/images/mobile.png" class="card-img">
            </div>
            <div class="right-content ms-2">
              <div class="row">
                <div class="col-6">
                  <h5>Order Id:</h5>
                </div>
                <div class="col-6">
                  <span>1024</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Product:</h6>
                </div>
                <div class="col-6">
                  <span>S23 Ultra</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Make:</h6>
                </div>
                <div class="col-6">
                  <span>dsfsd323</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Quantity:</h6>
                </div>
                <div class="col-6">
                  <span>1</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="square-group">
          <li class="square-1 warning"></li>
          <li class="square-1 primary"></li>
          <li class="square-2 warning1"></li>
          <li class="square-3 danger"></li>
          <li class="square-4 light"></li>
          <li class="square-5 warning"></li>
          <li class="square-6 success"></li>
          <li class="square-7 success"></li>
        </ul>
      </div>

    </div>
  </div>
</div>

<!-- <div class="col-12">
  <div class="card course-box">
    <div class="card-body">
      <div class="course-widget">
        <div>
          <h5 class="mb-0">Other Information</h5>
          <p class="f-light">Type</p>
        </div>
      </div>
    </div>
    <ul class="square-group">
      <li class="square-1 warning"></li>
      <li class="square-1 primary"></li>
      <li class="square-2 warning1"></li>
      <li class="square-3 danger"></li>
      <li class="square-4 light"></li>
      <li class="square-5 warning"></li>
      <li class="square-6 success"></li>
      <li class="square-7 success"></li>
    </ul>
  </div>
</div> -->

<div class="customizer-contain" [ngClass]="{ open: customizer != '' }">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-body custom-scrollbar">
      <div class="tab-pane fade " [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home"
        role="tabpanel" aria-labelledby="c-pills-home-tab1">
        <i class="icofont-close icon-close" (click)="Customizer('')"></i>

        <app-product-details></app-product-details>
      </div>
    </div>
  </div>
</div>