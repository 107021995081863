import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../transaction.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
  animations:[fadeInOut]
})
export class TransactionDetailComponent {
  transaction: any = {}

  timelineEvents = [
    { status: 'Payment succeeded', icon: 'fa fa-check-circle text-success', timestamp: 'Oct 25, 2023, 9:50 PM' },
    { status: 'Payment started', icon: 'fa fa-money text-muted', timestamp: 'Oct 25, 2023, 9:50 PM' }
  ];

  item = {
    name: "MaxWest Device Copay - Unity Wireless",
    description: "This copay represents a single, one-time payment and free monthly phone service.",
    quantity: 1,
    unit_price: 10.00,
    amount: 10.00
  };

  activities = [
    {
      title: 'A Checkout Session was completed',
      time: '10/25/23, 9:50:33 PM',
      status: 'Completed'
    },
    {
      title: 'The payment pi_3O5IiQH9B5gq8q0Q0A7jzjFQ for $10.00 USD has succeeded',
      time: '10/25/23, 9:50:32 PM',
      status: 'Succeeded'
    },
    {
      title: 'ch_3O5IiQH9B5gq8q0Q09ZDJxVd was charged $10.00 USD',
      time: '10/25/23, 9:50:32 PM',
      status: 'Charged'
    },
    {
      title: 'A new payment pi_3O5IiQH9B5gq8q0Q0A7jzjFQ for $10.00 USD was created',
      time: '10/25/23, 9:50:30 PM',
      status: 'Created'
    },
    {
      title: '200 OK - A request to confirm a Checkout Session completed',
      time: '10/25/23, 9:50:30 PM',
      status: 'OK'
    }
  ];
  transactionDetail: any;
  transactionInfo: any;
  refundAmount: any;
  refundReason: any;
  refundReasonList: any=[];
isSkeletonLoader: boolean=true;


  constructor(private router: Router, private transactionService :TransactionService,private modalService:NgbModal, private route: ActivatedRoute,) {

  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class. PaymentId
    const isGlobalSearch = this.route.snapshot.queryParamMap.get('isGlobalSearch') === 'true';
    if (isGlobalSearch) {
      // Extract PaymentId and TracID from the state if accessed via global search
      const transactionData = history.state.transactionData || {};
      // this.transaction= history.state.transactionData
      this.transaction.PaymentId = transactionData.id;
      this.transaction.TracID = transactionData.tracId;
      console.log('transactionData ', transactionData);
    } else {
      // If not from global search, set transaction directly from state
      this.transaction = history.state.transaction;
    }
    console.log(this.transaction);
    if(this.transaction){
      this.getTransactionDetails()
      this.getDetails()
      this.getRefundReason()
    }
    this.transaction = {
      ...this.transaction, StatementDescriptor: 'UNITY WIRELESS INC',
      Description: '', // If empty, shows "Edit"
      PaymentID: 'pm_1051iQH9B5qg8q0QwX7pVVH',
      Owner: 'Ramona Patzan',
      OwnerEmail: 'ramonadpatzan@icloud.com',
      CardLast4: '5340',
      CardFingerprint: 'AN82h67PvTeYsdBY',
      CardExpiry: '12 / 2031',
      CardType: 'Visa debit card',
      CardIssuer: 'Green Dot Bank DBA Bonneville Bank',
      OwnerAddress: '1335 Colombard Dr, Madera, CA 93637, US',
      StreetCheck: 'Passed',
      ZipCheck: 'Passed'
    }
    console.log(this.transaction);
  }

  back() {
    this.router.navigateByUrl("/transaction")
  }

  getTransactionDetails(){
    this.transactionService.getTransactionDetails({TracID:this.transaction.TracID}).subscribe(
      (response: any) => {
        if(response){
          this.transactionDetail = response
          this.isSkeletonLoader=false;
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.isSkeletonLoader=false;
      }
    );
  }

  getDetails(){
    this.transactionService.getDetails({PaymentId:this.transaction.PaymentId}).subscribe(
      (response: any) => {
        if(response.Status){
          this.transactionInfo = response.data
          console.log(this.transactionInfo?.customer?.CustomerId);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  getRefundReason() {
    this.transactionService.getRefundReason().subscribe(
      (response: any) => {
        // this.headers = response?.data?.data || [];
        this.refundReasonList = response?.data
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  confirmRefund() {
    let data = {
      paymentId: this.transaction?.PaymentId,
      CustomerId: 3347,
      refundReasonId: parseInt(this.refundReason),
      transactionId: this.transaction?.TracID,
      refundAmount: this.refundAmount,
      lastFourDigits: this.transaction?.AccountNumber?.slice(-4)
    }
    this.transactionService.refundTransaction(data).subscribe(
      (response: any) => {
        
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  onRefundClick(modal){
    this.modalService.open(modal , { centered:true})
  }
}
