<div class="container-fluid tab-form-wrapper">
  <form>
<!-- <div class="row">
  <div class="col-md-12">
    <h5>Modify</h5>
  </div>
  </div> -->
  <div class="row form-group">
    <div class="col-md-3">
      <label>ESN/SIM Change (PC18)</label>
      
    </div>
  
  </div>
  <div class="row form-group">
    <div class="col-md-3">
    <label>Type of ESN/SIM Change</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>  
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>MDN</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Old ESN/SIM</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>New ESN/SIM</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Model</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div> 
   <div class="row form-group">
    <div class="col-md-3">
<label>Who got it back (Employee Login ID)</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Reason for Change ESN/SIM (PC732)</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
    <label>Type of Change</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>  
  </div>
  <div class="row form-group">
    <div class="col-md-3">
    <label>Keep Tagged Device</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>  
  </div>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Change Mobile Number (PC90)</label>  
    </div>
  
  </div>
  <div class="row form-group">
    <div class="col-md-3">
    <label>Old MDN</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>  
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Zip Code</label>
    </div>
    <div class="col-md-9">
      <input type="text" maxlength="5" class="form-control" >
    </div>
  </div>

  <div class="row form-group">
    <div class="col-md-12 text-end">
      <button type="button" class="btn btn-danger me-3">Close</button>

      <button type="button" class="btn btn-primary">Submit</button>
    </div>
  </div>

  </form>
  </div>
