import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {
  userId: string;
  companyId:string;

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) { 

  }

  userNameVerify(val){
    let obj={
      userName:val,
      companyId: "UNITYCRM0021",
    }
    let url="api/unity/userNameVerify"
    return this.http.post(url,obj)
  }

  getUser() {
    return this.userDetails;
  }

  saveUserData(obj){
    let url="api/unity/create-user"
    return this.http.post(url,obj)
  }

  getApplication(){

    let url="api/unity/getAqsaTelApps"
    return this.http.get(url)
  }
/////////////////////// update user details ///////////////////

  updateUserData(obj,email:string){
   const data={
      name:obj.name,
      email:obj.email,
      userName:obj.userName,
      UserTypeID: 1,
      status_id: obj.status_id,
      userID:obj.userID,
      groupId:obj.groupId,
      aqsaTelApps:obj.aqsaTelApps
    
  
    }
    
    let url=`api/unity/update-user/${obj?.userID}`
    return this.http.put(url,data)
  }


  getRoleList(){
    let body={
      companyId: "UNITYCRM0021",
      userId: this.getUser().userId,
      groupTypeName: "AGENTS"
  }
    let url="api/system/groups-list"
    return this.http.get(url,body)
  }


  getUserList(name?,obj?){
    let body={
      companyId: "UNITYCRM0021",
      userId: this.getUser().userId,
      UserTypeID: 1,
      name:name ? name : "",
      ...obj
  }
  

    let url="api/unity/get-users"
    return this.http.get(url,body)
  }
  createTags(val){
    let url="api/tickets/createTag"
    return this.http.post(url,val)
  }
  UpdateTags(val,id){
    let url="api/tickets/updateTag/"+id
    return this.http.put(url,val)
  }
  DeleteTags(id){
    let url="api/tickets/removeTag/"+id
    return this.http.delete(url)
  }
  getTagList(name?,obj?){
    let body={
      companyId: "UNITYCRM0021",
      userId: this.getUser().userId,
      searchTerm:name ? name : "",
      ...obj
  }
    let url="api/tickets/getTags"
    return this.http.get(url,body)
  }

  getTagTypeList(name?,obj?){
    let body={
      companyId: "UNITYCRM0021",
      userId: this.getUser().userId,
      searchTerm:name ? name : "",
      ...obj
  }
    let url="api/tickets/getTagTypes"
    return this.http.get(url,body)
  }
  createTagType(val){
    let url="api/tickets/createTagType"
    return this.http.post(url,val)
  }
 UpdateTagType(val,id){
    let url="api/tickets/updateTagType/"+id
    return this.http.put(url,val)
  }
  DeleteTagType(id){
    let url="api/tickets/removeTagType/"+id
    return this.http.delete(url)
  }
  getAgentList(){
    let body={
      companyId: "UNITYCRM0021",
      userId: this.getUser().userId
  }
    let url="api/system/agents-list-no-page"
    return this.http.get(url,body)
  }
 
}
