import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-pages',
  templateUrl: './customer-pages.component.html',
  styleUrls: ['./customer-pages.component.scss']
})
export class CustomerPagesComponent {

}
