import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class MandrillEmailServiceService {

  constructor(private http:HttpService) { }

/**
   * Fetch the list of sent emails from the Mailchimp API.
   * @param payload The request payload containing userID, companyId, dateFrom, dateTo, query, tags, senders, and limit.
   * @returns An Observable of the response.
   */
getSentEmail(payload: {
  CustomerId:any,
  dateFrom?: string;
  dateTo?: string;
  query?: string;
  tags?: string[];
  senders?: string[];
  limit?: number;
}): Observable<any> {
   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get('api/report/getSentEmails', payload, false, false);
}

 /**
   * Fetch the email preview for the given email ID.
   * @param payload The request payload containing companyId, userID, and emailId.
   * @returns An Observable of the email preview response.
   */
 getMandrillEmailPreview(emailObject: {
  emailId: string;
}): Observable<any> {
  const payload = {
    emailId: emailObject?.emailId
  };

  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  // Make the HTTP request
  return this.http.get('api/note/getMandrillEmailPreview', payload, false,false);
}

 /**
   * reSend the email.
   * @param payload The request payload containing companyId, userID, and emailId and senders email
   * @returns An Observable of the email preview response.
   */
 reSendEmail(emailObject: {
  emailId: string;
  toemail: string;
}): Observable<any> {
  const payload = {
    emailId: emailObject?.emailId,
    toEmail:emailObject.toemail,
  };

  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  // Make the HTTP request
  return this.http.post('api/note/reSendEmail', payload);
}

}
