import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-hollow-circle',
  templateUrl: './hollow-circle.component.html',
  styleUrls: ['./hollow-circle.component.scss']
})
export class HollowCircleComponent  {

  @Input() radius: any = 50; 
  @Input() borderWidth: any = 10;
  
    calcCircleSize(radius: string, borderWidth: string): string {
      const radiusValue = parseInt(radius, 10);
      const borderValue = parseInt(borderWidth, 10);
      return `${(radiusValue + borderValue) * 2}px`;
    }
    
    generateGradient(radius: string, borderWidth: string): string {
      return `radial-gradient(circle, transparent ${radius}, #e0e0e0 ${radius}, #e0e0e0 calc(${radius} + ${borderWidth}))`;
    }
    
}
