<div class="container-fluid p-2">
  <div class="row">
    <div class="col-md-2">
      <div class="card mt-2 p-2 cursor-pointer" *ngFor="let item of data let i = index;" [ngClass]="{'active': selectedCard == i}">
        <div class="d-flex" (click)="selectCard(i)">
          <div>
            <img src="assets/images/mobile.png" class="card-img">
          </div>
          <div class="d-flex ms-1">
            <div class="align-items-center">
              <h6>Order Id:</h6>
              <span>1024</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10">

      <div class="card p-3">
        <div class="row">
          <div class="col-md-6">

            <div class="d-flex">
              <h5>Action Type:</h5>
              <span class="ms-3">Address Change</span>
            </div>
            <div class="d-flex mt-2">
              <h5>Status:</h5>
              <span class="ms-3">Address Change</span>
            </div>
            <div class="d-flex mt-2">
              <h5>Sold to Change:</h5>
              <span class="ms-3">Address Change</span>
            </div>
          </div>
          <div class="col-md-6">

            <div class="d-flex">
              <h5>Date Type:</h5>
              <span class="ms-3">12/12/2023</span>
            </div>
            <div class="d-flex mt-2">
              <h5>Status:</h5>
              <span class="ms-3">User</span>
            </div>
            <div class="d-flex mt-2">
              <h5>Source:</h5>
              <span class="ms-3">Address Change</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>