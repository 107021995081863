<!-- <div class="container-fluid tab-form-wrapper">
  <form [formGroup]="formData">

    <div class="row form-group">
      <div class="col-md-3">
        <label>MDN Type</label>
      </div>
      <div class="col-md-9">
        <ng-select id="simType" formControlName="mdnType">
          <ng-option value="">Select Type</ng-option>
          <ng-option value="Electronic">Electronic
          </ng-option>
          <ng-option value="NonElectronic">Non Electronic
          </ng-option>
        </ng-select>
        <div class="text-danger"
          *ngIf="formData.get('mdnType').hasError('required') && formData.get('mdnType').touched">
          MDN Type is required
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>MDN</label>
      </div>
      <div class="col-md-9">
        <input type="text" formControlName="mdnNo" class="form-control">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-3">
        <label>Zip Code</label>
      </div>
      <div class="col-md-9">
        <input type="text" maxlength="5" formControlName="zipCode" class="form-control">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary" (click)="updateMDN()"
          [disabled]="this.formData.invalid">Submit</button>
      </div>
    </div>

  </form>
</div> -->


<div class="container-fluid d-flex justify-content-center mt-4" style="width: 90%;" *ngIf="!isSkeletonLoader">
  <div class="card p-4" style="width: 80%;">
    <div class="bg-light p-3 pb-2 rounded">
      <form [formGroup]="formData">
      
        <!-- MDN Type Section -->
        <div class="row form-group mb-3">
          <div class="col-md-3">
            <label>MDN Type</label>
          </div>
          <div class="col-md-9">
            <ng-select id="simType" formControlName="mdnType">
              <ng-option value="">Select Type</ng-option>
              <ng-option value="Electronic">Electronic</ng-option>
              <ng-option value="NonElectronic">Non Electronic</ng-option>
            </ng-select>
            <div class="text-danger" *ngIf="formData.get('mdnType').hasError('required') && formData.get('mdnType').touched">
              MDN Type is required
            </div>
          </div>
        </div>
  
        <!-- MDN Section -->
        <div class="row form-group mb-3">
          <div class="col-md-3">
            <label>MDN</label>
          </div>
          <div class="col-md-9">
            <input type="text" formControlName="mdnNo" class="form-control">
          </div>
        </div>
  
        <!-- Zip Code Section -->
        <div class="row form-group mb-3">
          <div class="col-md-3">
            <label>Zip Code</label>
          </div>
          <div class="col-md-9">
            <input type="text" maxlength="5" formControlName="zipCode" class="form-control">
          </div>
        </div>
  
        <!-- Submit Button -->
        <!-- <div class="row form-group">
          <div class="col-md-12 ">
            <button type="button" class="btn btn-primary" (click)="updateMDN()" [disabled]="formData.invalid">
              Submit
            </button>
          </div>
        </div> -->
  
        
      </form>
    </div>
      <div class="col-md-12 mx-0 px-0">
        <button type="submit" class="btn btn-primary  w-100 mt-3 d-flex align-items-center justify-content-center px-0 mx-0" (click)="updateMDN()" [disabled]="formData.invalid"  ><h5>Submit</h5></button>
        </div>
  </div>
</div>

<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
  <app-skeleton-loader  shape="payment-form"></app-skeleton-loader>
</div>