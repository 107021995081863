import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAgentService } from '../../services/user-agent.service';
import { ManageAgentService } from '../../services/manage-agent.service';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';

declare var bootstrap: any; 

@Component({
  selector: 'app-manage-agent',
  templateUrl: './manage-agent.component.html',
  styleUrls: ['./manage-agent.component.scss']
})


export class ManageAgentComponent implements OnInit {
 @Input() manageAgentDetails:any
  public show: boolean = false
  public addAgentForm:FormGroup;
  public check=true;
  moreInfoAgent: boolean = false;
  popup1Width;
  popup2Width;
  checkDuplicateUser: any;
  public singleAgentData=[];
  agentList: any;
  editId: any;
  masterAgentList: any;
  distributorList: any;
  states:any;
public singleAgentDatas:any


@Output() loadAgent:boolean=false;
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 10;
  public totalItems: number;
  currentPage: number = 1; 
  totalPages: number = 1;
  pagedUserList: any[];
  // loaderCHanged:boolean=false;

  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  private selectedState:any;
  private selectedCity:any;

  
  public cities:any;

  @LocalStorage()
  private userDetails;
isSkeletonLoader: boolean=true;

  handleActivationChanged(loadAgent:boolean){
    this.loadAgent = loadAgent;
    console.log('value got: ',this.loadAgent)
  }

  constructor(private fb:FormBuilder,private userAgentService:UserAgentService,private agentService:ManageAgentService,private commonService:CommonService,
private router:Router){
    this.addAgentForm=this.fb.group({
      groupName:[""],
      employeeName:[""],
      retailerName:[""],
      distributerName:[""],
      masterAgent:["Select"],
      city:["Select"],
      state:["Select"],
      zip:["Select"],
      email:[""],
      status:[""],
      user_type:[]
  })

  }

  
  ngOnInit(): void {
    this.getUser()
    this.getAgentList('')
    this.getMasterAgent();
    this.getStates();
    this.grtStateId()
    console.log('init called.......')
    this.addAgentForm.get('state').valueChanges.subscribe(selectedState => {
      this.selectedState=selectedState
      this.addAgentForm.value.state=this.selectedState
      this.onStateSelect(this.selectedState)
  // const stateObject = this.stateList.find(state => state.StateName == this.selectedState);
    });
    this.addAgentForm.get('city').valueChanges.subscribe(selectedCity=>{
      this.selectedCity=selectedCity
      this.addAgentForm.value.city=this.selectedCity
      console.log('selected city:',this.selectedCity)
      this.onSelectCity(this.selectedCity)
    })
    
  }

  @ViewChild('popoverElement') popoverElement!: ElementRef<HTMLDivElement>;
  
showPopover() {
 
  const element = this.popoverElement.nativeElement as HTMLElement;
  bootstrap.Popover.getInstance(element).show();
}
initPopovers() {
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
  });
}
hidePopover() {
 
  const element = this.popoverElement.nativeElement as HTMLElement;
  bootstrap.Popover.getInstance(element).hide();
}

  toggleMoreInfAgent() {
    this.moreInfoAgent = !this.moreInfoAgent
    if(this.moreInfoAgent){
    this.popup1Width = '83%';
    }else{
      this.popup1Width=''
    }
  }
  loadAgents(load:boolean){
    this.loadAgent=true
  }
  moreInfoTab:string = ''

  moreInfoSelectedTab(tabName:string) {
    // debugger;
    this.moreInfoTab = tabName
  }


  moreInfoTab1:string = ''
  moreInfoAgent1: boolean = false;


toggleMoreInfAgent1()
{
  this.moreInfoAgent1= !this.moreInfoAgent1
  if(this.moreInfoAgent1){
  this.popup2Width = '83%';
  this.popup1Width='92%'
  }else{
    this.editId=''
    this.popup2Width=''
    this.popup1Width='83%'
  }

}

moreInfoSelectedTab1(tabName:string){
  this.moreInfoTab1 = tabName
}

showPassword() {
  this.show = !this.show
}


// userNameVerify(event:any){
//   const userNameValue = event?.target?.value;
//   console.log(userNameValue,"userName>>>>>>>>>>>>>")
//   this.userAgentService?.userNameVerify(userNameValue).subscribe((res:any)=>{
//     this.checkDuplicateUser=res.message
//     if(this.checkDuplicateUser=='User not registered'){
//       this.check=false
//     }else if(this.checkDuplicateUser=='User is already registered'
//       ){
//       this.check=false;
//     }else{
//       this.check=true;
//     }
//   },(error)=>{
//     this.check=true
//   })
// }

submitAgent(){
  // if(this.addAgentForm.invalid){
  //   return 
  // }else{
    let obj={
      companyId: "UNITYCRM0021",
      // CustomerId:this.http.getCustomerId(),
      userID: 3, 
      UserID: 5,
      DepartmentID: 4, 
      MarketID: 5, 
      SupervisorID: 4, 
      RegistrationDate: "2023-12-28", 
      PhoneNumber: "1234567890",
      AddressLine1: "123 Main Street",
      AddressLine2: "36th Apartment",
      CountryID: 1, 
      StateID: 8, 
      CityID: 9,
      ZipID: 10, 
      SerialNumber: 123,
      ActivationDate:2023-12-28, 
      IsRoyaltyRequired: true, 
      RoyaltyAmount: 12.34, 
      AgentModeID: 2, 
      CurrentVersion: "1.0", 
      ShowGoogleMap: true, 
      PlanSetting: "Basic", 
      MigrationStatusId: 1, 
      EntityTypeID: 2,
      AgentTypeId: 1 
  } 
  if(!this.editId){
  this.agentService.saveAgent(obj).subscribe((res:any)=>{
  })
}else{
  this.agentService.updateAgent(obj).subscribe((res:any)=>{
  })

}

}


/////////////////////////////////// get Agent List ////////////////////////////////////////
filterdData(event:any){
  let data=event.target.value
  this.getAgentList(data);
  // console.log('getUser data: ',this.getUserList(data))
}

getAgentList(search){
     let obj: any = {
    "per_page": this.itemsPerPage,
    "page": this.currentPage,
    "serchTerm":search
  }
  this.agentService.getAgentList1(obj).subscribe((res:any)=>{
    this.agentList=res?.data.data;
    this.totalItems = res.data.total
    this.currentPage = res.data.current_page
    this.first_page_url = res.data.first_page_url
    this.last_page_url = res.data.last_page_url
    this.next_page_url = res.data.next_page_url
    this.isSkeletonLoader=false
    
    // console.log('cities:::',this.cities)
    
  })
  setTimeout(()=>{
    this.isSkeletonLoader=false
  },1000)
}
public statesData:any;
public stateList:any[]=[];
public stateId:any;
async getStates(){
  let data={

  }
  try {
    const response = await this.commonService.getStates(data) as any; // Use 'any' to handle unknown response type
    if (response && response.Status === true && response.data && Array.isArray(response.data)) {
       const StatesData= response.data; 
       this.statesData=StatesData// Assign the cities array to this.cities
      StatesData.forEach(element => {
        if(element.StateName){
          this.stateList.push(element.StateName)
        }
        // console.log('agent form:',this.stateList)
      });
      let stateSet = new Set(this.stateList);
      this.stateList = [...stateSet];
      
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

grtStateId(){
  console.log('statelists: ', this.stateList)
  const stateObject = this.stateList.find(state => state.StateName == this.selectedState);
  console.log('stateobject: ',stateObject)
}
public citiesList:any;

onStateSelect(state: string) {
  const stateObject = this.statesData.find(s => s.StateName === state);

  if (stateObject) {
    const stateId = stateObject.id;
    this.getCities(stateId);
  } else {
    console.error('Selected state not found in stateList');
  }
}
onSelectCity(city:string){
  const cityObject = this.citiesData.find(s => s.CityName === city);

  // const zipObject = this.citiesData.find(s => s.CityName === city);
  // console.log('zipobject:',zipObject)
  // if(zipObject){
    // const cityId=zipObject.id;
    console.log('city id: ',cityObject.id)
    this.getZipCodes(cityObject.id)
  // }

}
public citiesData:any
async getCities(stateId){
  let data={
    "StateID":stateId
  }
  try {
    const response = await this.commonService.getCities(data) as any;
    if (response && response.Status === true && response.data && Array.isArray(response.data)) {
      const CitiesData = response.data;
      this.citiesData=CitiesData
      console.log('Cities:', CitiesData);

      this.citiesList = CitiesData.map(city => city.CityName); // Assuming CitiesData is an array of objects with CityName property

      console.log('CitiesList:', this.citiesList);
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error fetching cities:', error);
  }
}
public zipCodeDatas:any;
async getZipCodes(cityId){
  let data={
    "CityID":cityId
  }
  try {
    const response = await this.commonService.getZipCodes(data) as any;
    if (response && response.Status === true && response.data && Array.isArray(response.data)) {
      const zipCodesData = response.data;
      console.log('zips:', zipCodesData);

      this.zipCodeDatas = zipCodesData.map(zip => zip.ZipCode); // Assuming CitiesData is an array of objects with CityName property

      console.log('zipLists:', this.zipCodeDatas);
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error fetching cities:', error);
  }
}
editAgentData(id){
  
  this.editId = id;
  console.log('id:',id) 

  let item = this.agentList.find(item => item.id === id); 
  if (item) {
    this.singleAgentData.push(item);
    console.log('items:',this.agentList) 
    console.log('single agent list: ', this.singleAgentData[0]); 
    // this.singleAgentDatas=this.singleAgentData[0]
    // console.log('bchd: ',item.StateID)
    // console.log('item: ',item.StateID)
    

    this.getStates()

  } else {
    console.log('Agent with ID', id, 'not found.');
  }

  // this.addAgentForm.patchValue({
  // groupName:this.singleAgentData?.,
  // employeeName:this.singleAgentData?.,
  // retailerName:this.singleAgentData?.,
  // distributerName:this.singleAgentData?.,
  // masterAgent:this.singleAgentData?.,
  // city:this.singleAgentData?.,
  // state:this.singleAgentData?.,
  // zip:this.singleAgentData?.,
  // email:this.singleAgentData?.,
  // status:this.singleAgentData?.
  // })

}

getMasterAgent(){
  this.agentService.getMasterAgent().subscribe((res:any)=>{
    this.masterAgentList=res?.data?.data
    console.log('mstter=',this.masterAgentList)
})
}

onMasterAgentSelected(event:any){
 console.log(event,this.addAgentForm.value.masterAgent,"event>>>>>>>>>>>>>")
 console.log('event id: ',event.id)
 this.getDistributorList(event.id)
}


getDistributorList(id){
 this.agentService.getDistributorList(id).subscribe((res:any)=>{
  this.distributorList=res
  
  console.log('distributor list:',this.distributorList)
 })
}


getRetailersList(){
  let obj={}
  this.agentService.getRetailersList(obj).subscribe((res:any)=>{
    console.log(res,"res of get distributorsList")
   })
}




getUser() {
  
  return this.userDetails;
}

navigateForPermission(id:any){
  this.router.navigate(["/manage-permissions"], {
    queryParams: { id: id}
  })
}

onChangeNumberOfPage() {
  this.getAgentList('')
}

pageChange(event) {
  this.currentPage = event
  this.getAgentList('')
}

}
