<div class="container-fluid p-4">
  <div class="d-flex justify-content-end">
    <div class="sideHeader">
      <div>
        <button class="custom-button" (click)="exportData()">
          <div class="d-flex gap-2 align-items-center">
            <i class="fa fa-cloud-upload"></i>
            <span class="text">Export</span>
          </div>
        </button>
      </div>
      <!-- <div>
                <button class="custom-btn ">Create Order</button>
            </div> -->
    </div>
  </div>
  <div class="d-flex gap-2 mt-3 overflow-auto">
    <app-card-stats
      class="w-100"
      [activeCardId]="selectedId"
      (click)="onCardClick(item)"
      *ngFor="let item of cardStats"
      [data]="item"
    ></app-card-stats>
  </div>
  <div>
    <app-transaction-list [selectedCard]="selectedCard"></app-transaction-list>
  </div>
</div>
