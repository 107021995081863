<div class="container-fluid mainConatiner mt-3 p-0">

  <div class="card" *ngIf="!isSkeletonLoader" @fadeInOut>
    <div class="card-header p-0">
      <div class="d-flex align-items-center justify-content-between gap-2 overflow-auto custom-row"
        *ngIf="!showFilterRow && !isDraggable">
        <ul class="d-flex gap-2 ">
          <li *ngFor="let button of shipmentStatusData; let i = index">
            <button class="custom-btn" [ngClass]="{ 'active': activeIndex === i }" (click)="setActive(i,button)">
              {{ button.Name }}
            </button>
          </li>
        </ul>
        <div class="d-flex align-items-center gap-2">
          <h6 class="mb-0">Total -{{totalOrders}}</h6>
          <app-filter-btn [icon]="'fa fa-search'" [isBtn]="true" (selectionChanged)="onFilterClick()"></app-filter-btn>
          <app-filter-btn [optionList]="headers" [icon]="'fa fa-sort-amount-asc'" [label]="'display'" [value]="'key'"
            (selectionChanged)="handleSelectionChange($event)"></app-filter-btn>
          <span (click)="changeDraggable()">
            <app-filter-btn [icon]="'fa fa-columns'" [isBtn]="true"
              (selectionChanged)="changeDraggable()"></app-filter-btn>
          </span>
          <app-filter-btn [icon]="'fa fa-filter'" [isBtn]="true" (selectionChanged)="onFilterClick()"></app-filter-btn>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between gap-2 custom-row" [ngClass]="{'r-0': showFilterRow}"
        *ngIf="showFilterRow">
        <div class="d-flex w-100 position-relative">
          <input type="text" class="form-control ps-5" placeholder="Search..." (keyup)="searchChange($event)">
          <i class="fas fa-search position-absolute" style="left: 10px; top: 50%; transform: translateY(-50%);"></i>
        </div>
        <div class="d-flex align-items-center gap-2">
          <button class="custom-btn active" (click)="closeFilter()"> Cancel </button>
          <app-filter-btn [optionList]="sortOptions" [icon]="'fa fa-sort-amount-asc'" [label]="'label'" [value]="'id'"
            (selectionChanged)="handleSelectionChange($event)"></app-filter-btn>
        </div>
      </div>
      <div *ngIf="showFilterRow" class="w-100 custom-row">
        <div class="d-flex gap-2 align-items-center">
          <app-custom-select [title]="'Add filter'" (selectionChanged)="handleFilterSelectionChange($event)"
            [optionList]="filters" [label]="'display'" [value]="'key'" [icon]="'plus'"></app-custom-select>
          <app-custom-select [title]="item.display" *ngFor="let item of selectedFilters; let i = index"
            [optionList]="item.optionList" [label]="item.name" [value]="item.code"
            (selectionChanged)="sendFilter($event,item)"></app-custom-select>
          <div *ngIf="selectedFilters?.length > 0">

            <button class="custom-btn active " (click)="clearAll()"> Clear All </button>
          </div>
        </div>

      </div>
      <div class="d-flex align-items-center gap-2 justify-content-end" *ngIf="isDraggable">
        <div class="d-flex justify-content-end gap-2 p-2">
          <button class="custom-btn active" (click)="closeDragDrop()"> Cancel </button>
          <button class="custom-btn  dark" (click)="saveColumnArrangement()"> Save </button>
        </div>
      </div>
    </div>
    <div class="card-body p-0">


      <div class="table-responsive table-hover table-striped table-card" style="min-height: 500px; overflow-x: visible;">
        <table class="table table-nowrap mb-0">
          <thead class="table-light">
            <tr cdkDropList class="header-row" [cdkDropListDisabled]="!isDraggable" cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="drop($event)" >
              <th *ngFor="let header of headers; let i = index" cdkDrag [ngClass]="{'draggable-column': isDraggable}">
                {{ header.display }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of rowDatas; let i = index" class="cursor-pointer"
              [ngClass]="{'bg-danger': item?.order_items?.length==0}" [class.highlight]="i === highlightedRowIndex">
              <td *ngFor="let header of headers" (click)="header.key !== 'CustomerId' ? goToDetails(item,i) : null">
                <ng-container [ngSwitch]="header.key">


                  <span *ngSwitchDefault>
                    {{ item[header.key] }}
                  </span>
                  <span *ngSwitchCase="'OrderDate'">
                    {{ item[header.key]| date }}
                  </span>
                  <span *ngSwitchCase="'OrderId'">
                    #{{ item[header.key] }}
                  </span>

                  <div *ngSwitchCase="'CustomerId'" class="position-relative">
                    <div class="d-flex align-items-center gap-2 customer-cell" (click)="toggleDropdown(i, item)">
                      <span>{{ item?.FirstName + " " + item?.LastName }}</span>
                      <i class="fa fa-chevron-down chevron-icon"></i>
                    </div>
                    <div class="dropdown-content"
                      [ngClass]="{'d-block': dropdownVisible.has(i), 'd-none': !dropdownVisible.has(i)}">
                      <div class="customer-card d-flex flex-column Polaris-Box gap-2">
                        <div class="d-flex justify-content-between align-item-center">
                          <span class="text-dark fw-semibold customerinfo-text">{{
                            customerDetails?.personalInfo?.firstName?.value + " " +
                            customerDetails?.personalInfo?.lastName?.value }}</span>
                          <span class="text-success custom-badge">{{ customerDetails?.personalInfo?.accountStatus?.value
                            }}</span>
                        </div>
                        <span class="plan-price customerinfo-text">{{
                          customerDetails?.personalInfo?.serviceAddress?.value?.city + ", " +
                          customerDetails?.personalInfo?.serviceAddress?.value?.state }}</span>

                        <div class="customer-address">
                          <span class="customerinfo-text">{{
                            customerDetails?.personalInfo?.serviceAddress?.value?.address1 }}</span>
                          <span class="customerinfo-text">{{ customerDetails?.personalInfo?.serviceAddress?.value?.city
                            + ", " +
                            customerDetails?.personalInfo?.serviceAddress?.value?.state + ", " +
                            customerDetails?.personalInfo?.serviceAddress?.value?.zipCode }}</span>
                        </div>

                        <div class="customer-plan d-flex flex-column">
                          <span class="plan-name customerinfo-text">{{ customerDetails?.plan?.planName?.value }}</span>
                          <span class="plan-price customerinfo-text">${{ customerDetails?.plan?.planPrice?.value }} per
                            month</span>
                        </div>

                        <div class="customer-contact">
                          <span class="customer-email customerinfo-text">{{ customerDetails?.personalInfo?.email?.value
                            }}</span>
                          <span class="plan-price customerinfo-text">{{ customerDetails?.personalInfo?.phone?.value
                            }}</span>
                        </div>

                        <button class="view-company-btn" (click)="sendToCustomerDashboard(item.CustomerId,i)">View
                          Details</button>
                      </div>
                    </div>
                  </div>



                  <div *ngSwitchCase="'PaymentStatus'">
                    <span *ngIf="item.PaymentStatus" [ngClass]="getStatusBadgeClass(item.PaymentStatus)" class="custom-badge">
                      {{ item.PaymentStatus }}
                    </span>
                  </div>
                  <div *ngSwitchCase="'OrderStatus'">
                    <span [ngClass]="getStatusBadgeClass2(item.OrderStatus)" class="custom-badge">
                      {{ item.OrderStatus }}
                    </span>
                  </div>


                  <span *ngSwitchCase="'OrderItems'" class="position-relative">
                    <div class="items-cell" (click)="toggleItemDropdown(i, item); $event.stopPropagation();">
                      <span>{{ item.order_item?.length || 0 }}</span>
                      <i class="fa fa-chevron-down chevron-icon"></i>
                    </div>
                    <div class="dropdown-content customer-card"
                      [ngClass]="{'d-block': itemDropdownVisible.has(i) && item.order_item?.length!=0, 'd-none': !itemDropdownVisible.has(i) && item.order_item?.length==0}">

                      <span class="custom-badge bg-yellow mb-2">
                        <i class="fa fa-circle" *ngIf="item.OrderStatus === 'Dispatched'"></i>
                        <i class="fa fa-circle-o" *ngIf="item.OrderStatus !== 'Dispatched'"></i>
                        {{ item.OrderStatus }}
                      </span>
                      <div class="item-details-card d-flex flex-column Polaris-Box gap-2">
                        <div *ngFor="let orderItem of item.order_item" class="order-item">
                          <div class="d-flex align-items-center gap-3 justify-content-between">
                            <div class="d-flex gap-1 align-items-center">
                              <img width="40" height="40" class="img" [src]="orderItem?.product_combo_item?.ImagePath">
                              <span class="items-title text-nowrap">
                                {{orderItem?.product_combo_item?.devicemakemodel.Make}}
                                {{orderItem?.product_combo_item?.devicemakemodel.Model}}
                              </span>
                            </div>
                            <span class="items-title text-nowrap">X 1</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>

                  <span *ngSwitchCase="'OrderType'" class="custom-badge">
                    {{ item.OrderType }}
                  </span>

                </ng-container>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="d-flex justify-content-center p-3" *ngIf="rowDatas?.length==0">
          <span class="text-muted">
            No data found
          </span>
        </div>
      </div>

      <div class="pull-right  d-flex" *ngIf="totalOrders">
        <ngb-pagination class="pagination-block" [directionLinks]="true" [boundaryLinks]="true"
          [collectionSize]="totalOrders" [(page)]="currentPage" (pageChange)="onPaginationChanged($event)" [maxSize]="3"
          [pageSize]="itemsPerPage" [ellipses]="true">
          <ng-template ngbPaginationPrevious>Previous</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>

  <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
    <div class="card" class="d-flex flex-column gap-4">
      <div class="card p-2 pt-3">
        <app-skeleton-loader [tableColumn]="9" [tableRow]="20" shape="table"></app-skeleton-loader>
      </div>
    </div>
  </div>