import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-info-account',
  templateUrl: './customer-info-account.component.html',
  styleUrls: ['./customer-info-account.component.scss']
})
export class CustomerInfoAccountComponent {

}
