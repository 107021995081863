import { Component } from '@angular/core';

@Component({
  selector: 'app-dealer-wallet',
  templateUrl: './dealer-wallet.component.html',
  styleUrls: ['./dealer-wallet.component.scss']
})
export class DealerWalletComponent {

}
