<div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-body custom-scrollbar">
      
        <div class="tab-pane" id="c-pills-home" role="tabpanel"
            aria-labelledby="c-pills-home-tab">
            <div class="notes-panel" >
                <div class="wrapper d-flex bg-white">
                    <div class="right-panel w-100 py-3">
                        <div class="container-fluid">
                          <div class="customizer-header mb-3">
                            <h4 class="mb-0" *ngIf="!editId" (click)="back()" style="cursor: pointer;">
                              <i class="fa-solid fa-arrow-left mt-1 mx-2" ></i>Add roles and data restrictions </h4>
                            <h4 class="mb-0" *ngIf="editId" (click)="back()" style="cursor: pointer;">
                              <i class="fa-solid fa-arrow-left mt-1 mx-2" ></i>Edit roles and data restrictions </h4>
                        </div>
                          <form [formGroup]="addAgentForm">
                            <div class="row d-flex justify-content-center">
                               <div class="col-md-10">
                                   <div class="card">
                                      <div class="card-body">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>Group Name</label>
                                              <input class="form-control" placeholder='' type="text" formControlName="groupName">
                                             </div>
                                            
                                             <div class="text-danger" *ngIf="addAgentForm.get('groupName').hasError('required') && addAgentForm.get('groupName').touched">
                                              Group Name is required.
                                            </div>
                                          </div>
                                        
                                          <div class="col-md-6" >
                                            <div class="form-group">
                                              <label>Employee name</label>
                                              <input  class="text-danger" class="form-control"placeholder='' type="text" formControlName="employeeName">
                                             </div>

                                             <div class="text-danger" *ngIf="addAgentForm.get('employeeName').hasError('required') && addAgentForm.get('employeeName').touched">
                                              Employee Name is required.
                                            </div>

                                          </div>

                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>Master Agent</label>
                                              <ng-select formControlName="masterAgent" (change)="onMasterAgentSelected($event)" >
                                                <ng-option *ngFor="let item of masterAgentList" [value]="item.id">{{item.MasterAgentName}}</ng-option>
                                                </ng-select>
                                             </div>

                                             <div class="text-danger" *ngIf="addAgentForm.get('masterAgent').hasError('required') && addAgentForm.get('masterAgent').touched">
                                              Master Agent is required.
                                            </div>

                                          </div>


                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>Distributer name</label>
                                              <ng-select formControlName="distributerName">
                                                <ng-option *ngFor="let item of distributorList" [value]="item.id">{{item.DistributerName}}</ng-option>
                                                </ng-select>
                                             </div>

                                             <div class="text-danger" *ngIf="addAgentForm.get('distributerName').hasError('required') && addAgentForm.get('distributerName').touched">
                                              Distributer Name is required.
                                            </div>

                                          </div>



                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>Retailer name</label>
                                              <ng-select formControlName="retailerName">
                                                <ng-option *ngFor="let item of reTailarList" [value]="item.id">{{item.RetailerName}}</ng-option>
                                                </ng-select>
                                             </div>

                                             <!-- <div class="text-danger" *ngIf="addAgentForm.get('retailerName').hasError('required') && addAgentForm.get('retailerName').touched">
                                              Retailer Name is required.
                                            </div> -->

                                          </div>
                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>State</label>
                                              <ng-select formControlName="state" >
                                                <ng-option  *ngFor="let state of statesData" [value]="state.id" >{{state.StateName}}</ng-option>
                                               
                                                </ng-select>
                                             </div>

                                             <!-- <div class="text-danger" *ngIf="addAgentForm.get('state').hasError('required') && addAgentForm.get('state').touched">
                                              State is required.
                                            </div> -->

                                          </div>
                                         
                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>City</label>
                                              <ng-select formControlName="city">
                                                <ng-option  *ngFor="let city of citiesData" [value]="city.id" >{{city.CityName}}</ng-option>

                                              </ng-select>
                                            </div>

                                             <!-- <div class="text-danger" *ngIf="addAgentForm.get('city').hasError('required') && addAgentForm.get('city').touched">
                                              City is required.
                                            </div> -->

                                          </div>
                                         
                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>Zip</label>
                                              <!-- <input  class="text-danger" class="form-control" type="text" placeholder="Enter last name" formControlName="zip"> -->
                                              <ng-select formControlName="zip" >
                                                <ng-option  *ngFor="let zip of zipCodeDatas" [value]="zip" >{{zip}}</ng-option>
                                               
                                                </ng-select>
                                             </div>

                                             <!-- <div class="text-danger" *ngIf="addAgentForm.get('zip').hasError('required') && addAgentForm.get('zip').touched">
                                              Zip is required.
                                            </div> -->

                                          </div>
                                        </div>
                                        <div class="row mt-2">
                                          <div class="col-md-6">
                                            <div class="form-group">
                                              <label>Email Address</label>
                                              <input  class="text-danger" class="form-control" type="text" placeholder="Enter email address" formControlName="email">
                                             </div>
                                          </div>         
            
                                
                                          <div class="col-md-6">
                                            <label for="exampleSelect" class="form-label">Status</label>
                                            <select class="form-select" id="exampleSelect" formControlName="status">
                                              <option value="1">Active</option>
                                              <option value="2">Inactive</option>
                                            </select>
                                      </div>

                                        </div>
                                      </div>
                                    </div>   
                               </div>
                            </div>

                           <div class="row d-flex justify-content-center marginB">
                            <div class="col-md-10">
                            <button  *ngIf="!editId" class="btn btn-primary" (click)="submitAgent()">Submit</button>
                            <button  *ngIf="editId" class="btn btn-primary" (click)="submitAgent()">Update</button>
                            </div>
                           </div>
                           </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>