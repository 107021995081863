<div class="msg-box  pb-2   mt-3" *ngIf="!fullScreen">
    <h5 class="text-white">Application Information</h5>
    <p style="margin-bottom: 0px; color: white; line-height: 1.2; font-size: 12px; font-weight: 300;"
        class="d-flex flex-column gap-2">
        <div class="d-flex justify-content-between mb-2">
            <span>Enroll ID :</span>
            <span>
                {{minimalProfileData?.accountInfo?.enrollId?.value || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span> Order Type :</span>
            <span>
                {{minimalProfileData?.accountInfo?.orderType?.value || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Approval Date :</span>
            <span>
                {{minimalProfileData?.accountInfo?.approvalDate?.value || '-' | date}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Disconnection Date :</span>
            <span>
                {{
                minimalProfileData?.accountInfo?.disconnectionDate?.value || '-' | date
                }}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Approved by :</span>
            <span>
                {{minimalProfileData?.accountInfo?.approvedBy?.value || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>ACP Start Date :</span>
            <span>
                {{minimalProfileData?.accountInfo?.acpStartDate?.value || '-' | date
                }}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Shipment Status :</span>
            <span>
                {{minimalProfileData?.accountInfo?.shipmentStatus?.value || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Activation Code :</span>
            <span>
                {{minimalProfileData?.accountInfo?.activationCode?.value
                || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Tracking Number :</span>
            <span>
                {{minimalProfileData?.accountInfo?.shiprackingNo?.value || '-'}}
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Program :</span>
            <span>
                <p class="f-w-500 col-12" *ngFor="let program of minimalProfileData?.accountInfo?.programs?.value">
                    {{program?.name}}</p>
            </span>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <span>Source :</span>
            <span>
                {{minimalProfileData?.accountInfo?.source?.value || '-'}}
            </span>
        </div>
   

</div>
<div *ngIf="fullScreen">
    <div class="card-form">
        <h2>Application Information</h2>
        <form [formGroup]="applicationInfoFormData">
            <!-- Loop through form fields to display each field in its own row -->
            <ng-container *ngFor="let field of formFields">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="firstName">{{ field.label }}</label>
                        <input type="text" id="firstName" [formControlName]="field.controlName" [placeholder]="field.placeholder">
                    </div>
                </div>
            </ng-container>
        </form>
    </div>


</div>