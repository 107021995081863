<!-- <div class="d-flex justify-content-between">
  <p class="f-18 cursor-pointer" (click)="commonSettingService.moreMakeModelSelectedTab('MANAGE_MAKE_MODELS')"><i
      class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{manageMakeModelsDetails?.DisplayName}}</p>
</div> -->

<div class="customizer-conta" >
  <div class="tab-content" id="c-pills-tabContentAgent">

    <div class="d-flex">
      <h4 class="mb-0 text-nowrap col-md-2" (click)="back()" style="cursor: pointer;"> <i
              class="fa-solid fa-arrow-left mt-1 mx-2"></i>List of Make & Models</h4>
      <div class="col-md-10">
          <div class="d-flex justify-content-end my-2">
              <div class="col-md-2">
                  <input type="text" class="form-control" placeholder="Search" (keyup)="filterdData($event)">
              </div>
              <div class="">
                  <button class="btn btn-primary" (click)="commonSettingService.moreMakeModelSelectedTab1('Line Information')">+
                      Add</button>
              </div>
          </div>
      </div>
  </div>
  <div class="row mx-3">
    <div class="col-md-12 mx-auto">
      <div class="table-responsive marginB2" >
        <table class="table table-nowrap align-middle table-borderless project-list-table" *ngIf="!isSkeletonLoader" @fadeInOut>
          <thead>
            <tr>
              <th scope="col">Make</th>
              <th scope="col">Model</th>
              <th scope="col">Model Number</th>
              <th scope="col">Network Type</th>
              <th scope="col">Sim Type</th>
              <th scope="col">WiFi</th>
              <th scope="col">Data</th>
              <th scope="col">OS</th>
              <th scope="col">Voice Only</th>
              <th scope="col">Hotspot Capable</th>
              <th scope="col">Equipment Phone Type</th>
              <th scope="col">Device Type</th>
              <th scope="col">Esim</th>
              <th scope="col">Price</th>
              <th scope="col">Price After Discount</th>
              <th scope="col">Color</th>
              <th scope="col">Weight</th>
              <th scope="col">Unit</th>
              <th scope="col">Is Active</th>
              <th scope="col">Is Deleted</th>
              <th scope="col">Created Date</th>
              <th scope="col">Device Actual Cost</th>
              <th scope="col">Device Copay Cost</th>
              <th scope="col">Device Expected Rate</th>
              <th scope="col">Sim Type Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of deviceMakeModelList">
              <td>{{ item.Make }}</td>
              <td>{{ item.Model }}</td>
              <td>{{ item.ModelNumber }}</td>
              <td>{{ item.NetworkType }}</td>
              <td>{{ item.SimType }}</td>
              <td>{{ item.WiFi }}</td>
              <td>{{ item.Data }}</td>
              <td>{{ item.OS }}</td>
              <td>{{ item.VoiceOnly }}</td>
              <td>{{ item.HotspotCapable }}</td>
              <td>{{ item.EquipmentPhoneType }}</td>
              <td>{{ item.DeviceType }}</td>
              <td>{{ item.Esim }}</td>
              <td>{{ item.Price }}</td>
              <td>{{ item.PriceAfterDiscount }}</td>
              <td>{{ item.Color }}</td>
              <td>{{ item.Weight }}</td>
              <td>{{ item.Unit }}</td>
              <td>{{ item.IsActive ? 'Yes' : 'No' }}</td>
              <td>{{ item.IsDeleted ? 'Yes' : 'No' }}</td>
              <td>{{ item.CreatedDate }}</td>
              <td>{{ item.DeviceActualCost }}</td>
              <td>{{ item.DeviceCopayCost }}</td>
              <td>{{ item.DeviceExpectedRate }}</td>
              <td>{{ item.SimTypeName }}</td>
              <td>
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <a (click)="edit(item)" class="text-primary" title="Edit">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a class="text-danger" title="Delete">
                      <i class="fa-solid fa-trash"></i>
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="isSkeletonLoader" @fadeInOut class="d-flex flex-column gap-4" style="max-width: 100%;">
          <app-skeleton-loader [tableColumn]="8" [tableRow]="8" shape="table"></app-skeleton-loader>
        </div>
      </div>
      <div class="pull-right p-20 d-flex">
        <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage" (change)="onChangeNumberOfPage()">
          <option *ngFor="let page of pageDropdownMenu" [value]="page">{{ page }}</option>
        </select>
        <ngb-pagination 
          [maxSize]="10" 
          [directionLinks]="true" 
          [boundaryLinks]="true" 
          [collectionSize]="totalItems" 
          [(page)]="currentPage" 
          (pageChange)="pageChange($event)" 
          [pageSize]="itemsPerPage">
          <ng-template ngbPaginationPrevious>Previous</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
  
      
  </div>
</div>


<div class="customizer-contain" [ngClass]="{ open: commonSettingService.moreMakeModelLevel1 == true }"
  [ngStyle]="{ 'width': commonSettingService.popup2WidthMakeModel}">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header">
      <h5 class="mb-0" *ngIf="!device_modal_id">Add New Make & Models</h5>
      <h5 class="mb-0" *ngIf="device_modal_id">Edit Make & Models</h5>
      <i class="icofont-close icon-close" (click)="close()"></i>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div class=" m-3">
        <form [formGroup]="addMakeModelForm">
          <div class="card p-3" style="height: 70vh;
            overflow-y: auto;">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Make*</label>
                    <input type="text" formControlName="Make" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Model*</label>
                    <input type="text" formControlName="Model" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Model Number*</label>
                    <input type="text" formControlName="ModelNumber" class="form-control">
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Sim Type*</label>
                    <select class="form-control form-select" formControlName="SimType">
                      <option value="Micro">Micro</option>
                      <option value="Nano">Nano</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>OS*</label>
                    <select class="form-control form-select" formControlName="OS">
                      <option value="IOS">IOS</option>
                      <option value="Android">Android</option>
                    </select>

                  </div>
                </div>

                <!-- <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Equipment Phone Type*</label>
                    <input type="text" formControlName="EquipmentPhoneType" class="form-control">
                  </div>
                </div> -->
                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Device Type*</label>
                    <select class="form-control form-select" formControlName="DeviceType">
                      <option value="Tablet">TABLET</option>
                      <option value="Phone">Phone</option>
                    </select>
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Price*</label>
                    <input type="number" formControlName="Price" class="form-control">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Color*</label>
                    <input type="text" formControlName="Color" class="form-control">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mt-2">
                    <label>Unit*</label>
                    <input type="text" formControlName="Unit" class="form-control">
                  </div>
                </div>



                <div class="col-md-3 my-auto">
                  <div class="form-group mt-2">
                    <label>Esim</label>
                    <input type="checkbox" class="ms-2" formControlName="Esim">
                  </div>
                </div>

                <div class="col-12 my-2 text-center" *ngIf="addMakeModelForm.invalid && showErrorMsg">
                  <p class="text-danger">Please fill required fields.</p>
                </div>
                <div class="col-md-12 text-center my-3">
                  <button type="submit" (click)="submit()" *ngIf="!device_modal_id"
                    class="btn btn-primary">Next</button>
                  <button type="submit" (click)="update()" *ngIf="device_modal_id"
                    class="btn btn-primary">Update</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>