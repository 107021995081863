import { Component, OnInit } from '@angular/core';
import { SettingCommonServices } from '../../services/setting-common.services';
import { ManageComboService } from '../../services/manage-combo.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageNotesService } from '../../services/manage-notes.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-manage-note-types',
  templateUrl: './manage-note-types.component.html',
  styleUrls: ['./manage-note-types.component.scss']
})
export class ManageNoteTypesComponent implements OnInit {
  moreInfoNoteTabLevel1: string = '';
  moreInfoNoteTabLevel2: string = '';
  itemId=null
  isModified: boolean = false;
  noteTypesList: any = [];
  filterValue: any;
  public pageDropdownMenu: any = [10, 20, 30];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  filterList: any = []
  addNoteForm: any = FormGroup;
  constructor(
    private fb: FormBuilder,
    public commonSettingService: SettingCommonServices,
    private router: Router,
    private manageNoteService: ManageNotesService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getNote()
    this.addNoteForm = this.fb.group({
      Name: [null],
      Description: [null],
      IsActive: [1],
      IsDeleted: [0],
      CategoryID: [1]
    })
  }
  getNote() {
    this.manageNoteService.getNoteTypeList().subscribe((res: any) => {
      this.noteTypesList = res.data
      this.filterList = res.data
    })
  }

  getNoteTypeById(item: any) {
    this.commonSettingService.moreNoteLevel1 = true
    this.addNoteForm.patchValue(item)
  }

  filterData(value: any) {
    if (this.filterValue) {
      this.filterList = this.noteTypesList.filter(obj => obj['Name'].includes(this.filterValue));
    }
    else {
      this.filterList = this.noteTypesList
    }
  }

  submit() {
    this.manageNoteService.addNoteType(this.addNoteForm.value).subscribe(res => {
      this.commonService.showToast("success", "Success", "Added successfully!")
      this.resetAddNoteForm()
      this.getNote()
      this.closeModal()
    }, err => {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    })
  }

  editItemOpen(item: any) {
    this.itemId=item.id
    this.getNoteTypeById(item)

  }

  closeModal() {
    this.resetAddNoteForm()
    this.itemId=null
    this.commonSettingService.toggleMoreNoteLevel1()
  }

  resetAddNoteForm() {
    this.addNoteForm.reset()
    this.addNoteForm.get('CategoryID').patchValue(1)
  }

  updateNoteType(){
    this.manageNoteService.updateNoteTypeById(this.itemId,this.addNoteForm.value).subscribe(res => {
      this.commonService.showToast("success", "Success", "Updated Successfully!")
      this.resetAddNoteForm()
      this.getNote()
      this.closeModal()
    }, err => {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    })
  }

 

}