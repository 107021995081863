import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import tableData from '../../../mocks/review';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketSystemService } from '../services/ticket-system.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-ticket-type',
  templateUrl: './ticket-type.component.html',
  styleUrls: ['./ticket-type.component.scss']
})
export class TicketTypeComponent {

  public data = [];

  public column: any[];

  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public isEmptyRow: boolean = false;
  public startDate: Date;
  public endDate: Date;

  public filteredData = [];
  public conditionalData = [];
  public inputFilterValue: string;
  public datePickerConfig: Partial<BsDatepickerConfig>;

  public showHideMore: boolean = true;
  private sourceTypeData = [];

  public inputElementRef: HTMLInputElement = null;
  public formData: FormGroup;
  public id: any;
  @Output() ticketTypeOutputEvent = new EventEmitter<any>();
  loader: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    public ticketSystemService: TicketSystemService,
    public commonService: CommonService
  ) {
    this.column = [
      {
        displayName: '#',
        mappingKey: ['id'],
      },
      {
        displayName: 'Ticket Type',
        mappingKey: ['Name'],
      },
      {
        displayName: 'Created By',
        mappingKey: [''],
      },
      {
        displayName: 'Created Date',
        mappingKey: ['created_at'],
      },
      {
        displayName: 'Modified By',
        mappingKey: [''],
      },
      {
        displayName: 'Status',
        mappingKey: ['IsActive'],
      },
      {
        displayName: 'Action',
        mappingKey: ['lastComment'],
        active: true
      }
    ]

  }

  ngOnInit() {
    this.initializeFormData()
    // this.setBreadcrumb()
    this.getTicketTypes()
  }

  initializeFormData() {
    this.formData = this.fb.group({
      "Name": [, Validators.required],
      "IsActive": ['', Validators.required],
      "TicketCategoryID": [3, Validators.required],
    })
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  filterByDateRange() {
    this.conditionalData = this.data.filter(item => {
      const itemDate = new Date(item.orderDate);
      return itemDate >= this.startDate && itemDate <= this.endDate;
    });
    this.paginationUpdate()
  }

  showHideMoreAction() {
    this.showHideMore = !this.showHideMore
  }

  paginationUpdate() {
    this.totalItems = this.conditionalData.length;
    this.isEmptyRow = this.conditionalData.length === 0 ? true : false;
  }


  setBreadcrumb() {
    this.commonService.setbreadCrumb({
      "title": 'Ticket Type',
      "items": ['Ticket System'],
      "active_item": 'Ticket Type'
    })
  }

  onEdit(data) {
    this.id = data.id
    this.formData.patchValue(data)
  }

  async onSubmit() {
    this.loader = true;
    try {
      if (this.id) {
        const res:any = await this.ticketSystemService.updateTicketType(this.id, this.formData.value)
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Updated successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
      } else {
        const res:any = await this.ticketSystemService.addTicketType(this.formData.value)
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Added successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
      }
      this.getTicketTypes()
      this.initializeFormData()
      this.id = null
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

  async getTicketTypes() {
    this.loader = true;
    try {
      const res: any = await this.ticketSystemService.getTicketTypes()
      if (res.Status) {
        this.data = res.data
        this.ticketTypeOutputEvent.emit(this.data)
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }


}

