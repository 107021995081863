import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomerDashboardComponent } from "./customer-dashboard.component";



const routes: Routes = [
  {
    path: "", component: CustomerDashboardComponent,
    
    
   children:[
    {
      path: "",
      loadChildren: () => import("./customer-pages/customer-pages.module").then((m) => m.CustomerPagesModule),
    },
    {
      path: "quick-links",
      loadChildren: () => import("./quick-links/quick-links.module").then((m) => m.QuickLinksModule),
    }
   ]
  },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerDashboardRoutingModule { }
