import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { ManageAgentService } from '../../services/manage-agent.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-manage-category',
  templateUrl: './manage-category.component.html',
  styleUrls: ['./manage-category.component.scss']
})
export class ManageCategoryComponent {
  @Output() close = new EventEmitter<any>();
  hepldata:any = []
  searchText = '';
  columnDefs:any[]=[]
  resourcesTypeList:any
  resourcesTypeForm:FormGroup
  resourcesGategoryList:any
  showupdate:boolean =false
  @LocalStorage()
  private userDetails;
  // noData:boolean = false
  updateID:any
  constructor(private manageAgentService:ManageAgentService,private fb: FormBuilder,private commonService :CommonService,private renderer:Renderer2){

  }
  closeModal3(): void {
    this.close.emit(); // Emit close event
  }

  ngOnInit(){
//  this.getResourcesListType()
 this.getCategory()
    this.columnDefs = [

      { headerName: 'Category', field: 'DisplayName', },
      { headerName: ' ', field: ' ',  },
      { headerName: 'Description', field: 'Description', cellRenderer: this.defaultRenderer },
      { headerName: ' ', field: ' ',  },
      { headerName: 'Action', cellRenderer: this.actionRenderer.bind(this) }
    ];
    this.resourcesTypeForm = this.fb.group({
      resourceName: ['', Validators.required],
      resourceDescription: ['', Validators.required],
      displayName: ['', Validators.required],
      status: [null, Validators.required],
 
    });
  }
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true
  };
  onCellClicked(event: any) {
    // data-des-name="${params.data.Description}" data-ResId-name="${params.data.ResourceCategoryID}" data-isAct-name="${params.data.ResourceCategoryID}"
    const target = event.event.target.closest('.icon');
    if (target) {
      const action = target.getAttribute('data-action');
      const id = target.getAttribute('data-id');
      const DisplayName = target.getAttribute('data-display-name');
      const Name = target.getAttribute('data-name');
      const Description = target.getAttribute('data-des-name');
      const ResourceCategoryID = target.getAttribute('data-ResId-name');
      const IsActive = target.getAttribute('data-isAct-name');
      if (action === 'edit') {
        this.ViewOneRes(id);
      } else if (action === 'delete') {
        // Use the id for deletion
        this.deleteCategoryType(id);
      }
    }
  }
  
  // Custom renderers
  boldRenderer(params: any) {
    if (params.value) {
      return `
        <span class="icon-with-text">
          <i class="fas fa-info-circle me-2"></i>  <!-- Your icon class -->
        </span>
      `;
    } else {
      return '-';
    }
  }
  
  defaultRenderer(params: any) {
    return params.value ? params.value : '-';
  }
  
  badgeRenderer(params: any) {
    return `<span class="badge bg-success">${params.value ? params.value : '-'}</span>`;
  }
  actionRenderer(params: any) {
    const container = document.createElement('div');
    container.innerHTML = `
          <span class="icon me-2 cursor-pointer " data-action="edit" data-id="${params.data.id}" data-display-name="${params.data.DisplayName}" data-name="${params.data.Name}" data-des-name="${params.data.Description}" data-ResId-name="${params.data.ResourceCategoryID}" data-isAct-name="${params.data.IsActive}">
      <i class="fas fa-edit"></i>
    </span>
      <span class="icon cursor-pointer " data-action="delete" data-id="${params.data.id}">
        <i class="fas fa-trash"></i>
      </span>
    `;
    const editIcon = container.querySelector('[data-action="edit"]');
    if (editIcon) {
      editIcon.addEventListener('click', () => {
        console.log('Edit icon clicked for ID:', params.data.id);
  
        const mainContainer = document.getElementById('main');
        if (mainContainer) {
          // Smoothly scroll to the top of the #main container
          mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
          console.log('Scrolling to the top of #main');
        } else {
          console.error('Main container with id="main" not found.');
        }
      });
    }
  
    return container;
  }
  
  getResourcesListType(){
    this.manageAgentService.getAllResourcesType().subscribe((res:any)=>{
     this.resourcesTypeList=res?.data;
    
    
    })
    
  }
  getCategory(){
    this.manageAgentService.getAllCategory().subscribe((res:any)=>{
     this.resourcesGategoryList=res?.data;
     console.log("resouces category type ",this.resourcesTypeList)
    
    })
    
  }
  ViewOneRes(id: any) {
    this.updateID = id;
    this.manageAgentService.showCategoryType(id).subscribe(
      (res: any) => {
        const data = res?.data;
        if (res?.Status && data) {
          const matchedItem = Array.isArray(data) 
            ? data.find((item: any) => item.id == id) 
            : (data.id == id ? data : null);
          if (matchedItem) {
            this.resourcesTypeForm.patchValue({
              displayName: matchedItem.DisplayName ?? null,
              resourceDescription: matchedItem.Description ?? '',
              status: matchedItem.IsActive ?? null,
              resourceName: matchedItem.Name
            });
            this.showupdate = true;
            const collapseElement = document.getElementById('collapseExample');
            if (collapseElement) this.renderer.addClass(collapseElement, 'show');
          }
        } 
      },
    );
  }
  
  getUser() {
    return this.userDetails;
    
  }
  submitForm() {
    if (this.resourcesTypeForm.valid) {
      const payload = {
        Name: this.resourcesTypeForm.get('resourceName').value,
        Description: this.resourcesTypeForm.get('resourceDescription').value,
        DisplayName: this.resourcesTypeForm.get('displayName').value,
        isActive: this.resourcesTypeForm.get('status').value,
      };
      this.manageAgentService.createCategory(payload).subscribe({
        next: (res: any) => {
          if (res?.Status === true) {
            this.commonService.showToast("success", "Success", "Category Created successfully!");
            this.getResourcesListType();
          } else {
            const errorMessage = res?.error.error?.Name[0] ?? "Cancellation failed.";
            this.commonService.showToast('error', "Error", errorMessage);
          }
        },
        error: (err: any) => {
          console.log(err, 'Error Occurred');
          const errorMessage = err?.error?.error.Name[0] ?? "An unexpected error occurred.";
          this.commonService.showToast('error', "Error", errorMessage);
         
        },
        complete: () => {
          console.log('Request complete');
        }
      });
    } else {
      console.log('resourcesTypeForm is invalid');
    }
  }
  async deleteCategoryType(id: any) {
    try {
      let res = await this.manageAgentService.deleteCategory(id) as any;
      if (res?.Status === true) {
        const successMessage = res?.data ?? "Category Type deleted successfully!";
        this.commonService.showToast("success", "Success", successMessage);
        this.getCategory();
      } else {
        const errorMessage = res?.error?.Name[0] ?? "Deletion failed.";
        this.commonService.showToast('error', "Error", errorMessage);
      }
    } catch (err) {
      const errorMessage = err?.error?.message ?? "Some error occurred";
      this.commonService.showToast('error', "Error", errorMessage);
    }
  }
  update() {
    if (this.resourcesTypeForm.valid) {
      let formData = {
        DisplayName: this.resourcesTypeForm.value.displayName,
        Description: this.resourcesTypeForm.value.resourceDescription,
        Name: this.resourcesTypeForm.value.resourceName,
        "IsActive":this.resourcesTypeForm.value.status,
        "UpdatedBy":this.getUser().userID
      }
     
      this.manageAgentService.updateResoure(this.updateID,formData).subscribe(res => {
        this.getCategory()
      this.commonService.showToast("success", "Success", "Updated successfully!")
  
    }, err => {
  
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.commonService.stopLoader()
    })
  }
  
  }
  resetForm() {
    this.resourcesTypeForm.reset();
  }
}
