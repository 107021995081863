<div class="container-fluid tab-form-wrapper">
  <form>
    <span class="text-danger">Customer MDN should be active to purchase new equipment, otherwise the ESN swap will failed.</span>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Equipment & Accessories Type</label>
      </div>
      <div class="col-md-5">
       <textarea class="form-control" rows="5"></textarea>
      </div>
      <div class="col-md-4 d-flex align-items-center">
        <button type="button" class="btn btn-primary">+</button>
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-9">
        <span class="text-danger">
          (Use Ctri + click to select all the Equipment & Accessories the customer needs.)
        </span>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>My Waller Amount</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control">
      </div>
    </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Phone</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Status</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Amount</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Tax Breakup</label>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Total Tax Amount</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Processing Fee</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Activation Fee</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Activation Fee Tax</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Shipping & Handling Fee</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
      <div class="d-flex align-items-center">
        <input type="checkbox" class="me-2"> 
        <label class="mb-0 checkbox-label" >Over Night Shipping</label>
       </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Over Night Shipping Fee</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Total Amount</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
      <div class="d-flex align-items-center  ">
        <input type="checkbox" class="me-2"> 
        <label class="mb-0 checkbox-label">Melissa Address Suggestion for Billing Address</label>
       </div>
       <div class="d-flex align-items-center">
        <input type="checkbox" class="me-2"> 
        <label class="mb-0 checkbox-label">Skip Payment</label>
       </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Credit Card Number</label>
    </div>
    <div class="col-md">
      <input type="number" class="form-control">
    </div>
    <div class="col-md">
      <input type="number" class="form-control">
    </div>
    <div class="col-md">
      <input type="number" class="form-control">
    </div>
    <div class="col-md">
      <input type="number" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Type Of Card</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
      <label>Security Code</label>
    </div>
    <div class="col-md-3">
      <input type="number" class="form-control">
    </div>
    <div class="col-md-2 my-auto">
      <label>Exp Date</label>
    
    </div>
    <div class="col-md-2">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
    <div class="col-md-2">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Name On Card</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Receipt Number</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>Zip Code</label>
    </div>
    <div class="col-md-9">
      <input type="text" maxlength="5" class="form-control">
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-3">
      <label>City</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="form-group row"> 
    <div class="col-md-12 text-end">
    <button type="button" class="btn btn-primary"> Submit</button>
  </div>
  </div>
  </form>
  </div>