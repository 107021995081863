<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row">
      <div class="col-md-12">
        <h5>
          CSR Screen For Change Account Type
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>First Name</label>
          <input type="text" class="form-control">
        </div>
        <div class="form-group">
          <label>Phone</label>
          <input type="number" class="form-control">
        </div>
        <div class="form-group">
          <label>Plan</label>
          <select class="form-control form-select">
            <option>Select Plan</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Last Name</label>
          <input type="text" class="form-control">
        </div>
        <div class="form-group">
          <label>SSN</label>
          <input type="number" class="form-control">
        </div>
        <div class="form-group">
          <label>Eligible Programs</label>
          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label"> Medicaid</label>
          </div>
          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label"> Supplemental Nutrition Assistance Program (SNAP) Food Stamps</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label"> Supplemental Security Income (SSI)</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label"> Federal Public Housing Assistance</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label"> Veterans Pension and Survivors Benefit Program</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label"> Special Supplemental Nutrition Program for Women, Infants, and
              Children (WIC)</label>
          </div>

        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>Address</label>
          <input type="text" class="form-control">
        </div>
        <div class="form-group">
          <label>DOB</label>
          <input type="date" class="form-control">
        </div>
        <div class="form-group">
          <label>Income Based</label><br>
          <span>
            If your income is at or below 200% of the federal
            poverty guidelines, as shown below, you can qualify for ACP.
          </span>
          <div class="form-group">
            <label>
              How many people are in your household?
            </label>
            <input type="text" class="form-control">
          </div>
          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label mt-2">
              Acknowledge that, to the best of my knowledge, no one at my
              household is receiving ACP-supported service from any other
              provider
            </label>
          </div>

        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Address</label>
          <div class="d-flex justify-content-between align-items-center  ">
            <div class="my-2 d-flex">
              <input type="checkbox" name="address" class="me-2">
              <label class="checkbox-label mb-0">Permanent</label>
            </div>
            <div class="my-2 d-flex">
              <input type="checkbox" name="address" class="me-2">
              <label class="mb-0 checkbox-label">Temporary</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Enrollment Type</label>
          <select class="form-control form-select">
            <option>Select</option>
          </select>
        </div>
        <div class="form-group">
          <label>Bypass NLAD</label>
          <div class="d-flex align-items-center">
            <input type="checkbox" class="me-2">
            <label class="mb-0 checkbox-label">Click to Bypass NLAD</label>
          </div>

        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>

  </form>
</div>