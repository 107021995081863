import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-address-form-skeleton',
  templateUrl: './address-form-skeleton.component.html',
  styleUrls: ['./address-form-skeleton.component.scss']
})
export class AddressFormSkeletonComponent {

  @Input() isAddressSummary:boolean;
  @Input() isPaymentForm: boolean;
}
