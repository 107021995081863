import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PanelStateService {
  private panelMinimized = new BehaviorSubject<boolean>(false);
  panelMinimized$ = this.panelMinimized.asObservable();

  setPanelMinimized(minimized: boolean) {
    this.panelMinimized.next(minimized);
  }
}
