import { Component } from '@angular/core';

@Component({
  selector: 'app-tag-account',
  templateUrl: './tag-account.component.html',
  styleUrls: ['./tag-account.component.scss']
})
export class TagAccountComponent {

}
