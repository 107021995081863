<div class="container-fluid tab-form-wrapper">
  <form>
    <div class="row my-3 border bg-white">
      <div class="col-md-8">
        <h5 class="border-0">Setup the topup plan in OSS</h5>
       
      </div>
  <div class="col-md-4 text-end my-auto">
        <button type="button" class="btn btn-primary" (click)="closeTopup()">Back to topup list</button>
      </div> 
    </div>
    <span class="text-primary my-3">Linked topup is working carrier wise to add another topup linked them separately.
    </span>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Topup Name</label><br>
      <input type="text" class="form-control" >
    </div>
    <div class="col-md-6">
      <label>Reload Name</label><br>
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Plan Description</label><br>
      <input type="text" class="form-control" >
    </div>
    <div class="col-md-6">
      <label>Payment Type</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Plan State *(It will allow select multiple states by press Ctri key then select states from list)</label><br>
      <select class="form-control form-select">
        <option>Select Size</option>
        </select>
    </div>
  </div>

  <div class="border p-2 my-3">
  <h5 class="my-3">Carrier Setup</h5>
  <button type="button" class="btn btn-primary">Choose Carrier</button>
</div>
  <div class="border p-2 my-3">
  <h5 class="my-3">Plan Cost</h5>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Total Plan Price</label><br>
      <input type="text" class="form-control" >
    </div>
  </div>
  </div>

  <div class="border p-2 my-3">
  <h5 class="my-3">Tax Config</h5>
  <div class="row form-group">
    <div class="col-md-12">
      <label>is Tax Config</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  </div>

  <div class="border p-2 my-3">
  <h5 class="my-3">Autopay Config</h5>
  <div class="row form-group">
    <div class="col-md-12">
      <label>Autopay Discount</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  </div>

  <div class="border p-2 my-3">
  <h5 class="my-3">Other</h5>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Credit Type</label><br>
      <input type="text" class="form-control" >
    </div>
    <div class="col-md-6">
      <label>Type</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
		
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Tribal Plan</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
		
    </div>
    <div class="col-md-6">
      <label>Status</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
		
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-6">
      <label>Topup Category</label><br>
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>

</div>
<div class="row form-group">
  <div class="col-md-12 ">
    <button type="button" class="btn btn-primary">Submit</button>
    <button type="button" class="btn btn-primary ms-3">Cancel</button>
  </div>
</div>
  </form>
  </div>