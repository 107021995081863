import { Injectable } from '@angular/core';
import { createEffect, Actions, OnInitEffects, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { addNotification, startListeningNotifications } from './notification.actions';
import { Notification } from './notification.model';
import { EchoService } from 'src/app/shared/services/echo.service';

@Injectable()
export class NotificationEffects implements OnInitEffects {
  constructor(private actions$: Actions, private echoService: EchoService, private store :Store) {}

  // Automatically starts listening for notifications
  realTimeNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startListeningNotifications), // Triggered by this custom action
      tap(() => console.log('Starting to listen to notifications...')),
      tap(() => {
        this.echoService.subscribeToNotifications().subscribe({
          next: (notification: Notification) => {
            console.log('Notification received:', notification);
            this.store.dispatch(addNotification({ notification })); // Dispatch action
          },
          error: (error) => console.error('Error in subscription:', error),
        });
      }),
      map(() => ({ type: '[Notifications] Listener Initialized' } as Action)),
      catchError((error) => {
        console.error('Error in effect:', error);
        return of({ type: '[Notifications] Listener Failed' } as Action);
      })
    )
  );


  // Dispatches an action when the effects are initialized
  ngrxOnInitEffects(): Action {
    console.log('ngrxOnInitEffects triggered');
    return startListeningNotifications(); // Ensure this action is defined
  }
}
