import { Component } from '@angular/core';

@Component({
  selector: 'app-add-data',
  templateUrl: './add-data.component.html',
  styleUrls: ['./add-data.component.scss']
})
export class AddDataComponent {
  newtabsCondition:boolean = false;
  topupType:string = 'Y';
  
  list:any =[
    {name:"Credit Card", link:'/customer/quick-links/add-data/creditcard'},
    {name:"Cash", link:'/customer/quick-links/add-data/cash'},    
    {name:"Money Order", link:'/customer/quick-links/add-data/money-order'},
    {name:"Money Gram", link:'/customer/quick-links/add-data/money-gram'},
    {name:"Wallet", link:'/customer/quick-links/add-data/wallet'},
    {name:"Dealer Wallet", link:'/customer/quick-links/add-data/dealer-wallet'},
    {name:"Free Top UP", link:'/customer/quick-links/add-data/free-topup'}    
    ]

  constructor(){}
  changeRechargeType(type:string):void{
    this.topupType = type;
  }
}
