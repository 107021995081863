import { Component } from '@angular/core';

@Component({
  selector: 'app-dealer-wallet-renew',
  templateUrl: './dealer-wallet-renew.component.html',
  styleUrls: ['./dealer-wallet-renew.component.scss']
})
export class DealerWalletRenewComponent {

}
