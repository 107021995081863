<div class="modal-header">
    <div class="d-flex col-md-11 justify-content-between">
    <h4 class="modal-title">Scheduled Downgrades</h4>
    <!-- <h4 class="mb-0 ">Total Count-</h4> -->
</div>
    <span type="button" class="close text-success" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </span>
</div>
<div class="table-responsive mt-3" @fadeInOut  style="max-height: 500px; background-color: white; border-radius:5px ;">
    <table class="table" *ngIf="!isSkeletonLoader"  @fadeInOut>
        <thead class="table-sticky">
            <tr style="background-color: #11100E; color: #fff;">
                <th scope="col" sortable="fieldName" class="text-nowrap text-white">Customer ID</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Current Plan</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Scheduled Plan</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Schedule Date</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Immediate</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Scheduled Email</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Complete Email</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Processed</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Subscribed</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Subscription Synced</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Complete</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Failed</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Retry Mark</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Retried</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Is Refunded</th>
                <th scope="col" sortable="example"class="text-nowrap text-white">CancelDate</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Cancelled By</th>
                <th scope="col" sortable="example"class="text-nowrap text-white">Retry Counter</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Created Date</th>
                <th scope="col" sortable="example" class="text-nowrap text-white">Created By</th>
               
         
            </tr>
        </thead>

        <tbody *ngIf="!showLoader">
            <tr *ngFor="let item of portOutData">
               
                <td scope="row" class="text-nowrap">
                    <ngb-highlight [result]="item.CustomerID" [term]="'CustomerID'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.CurrentPlan ? item.CurrentPlan : '-'" [term]="'MDN'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.ScheduledPlan ? item.ScheduledPlan : '-'" [term]="'MDN'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.ScheduleDate | date :'mediumDate'" [term]="'ScheduleDate'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsImmediate ? 'Yes' : 'No'" 
                      [term]="'IsImmediate'" 
                      [ngClass]="item.IsImmediate ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsScheduledEmail ? 'Yes' : 'No'" 
                      [term]="'OspAccountPass'" 
                      [ngClass]="item.IsScheduledEmail ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.ICompletesEmail ? 'Yes' : 'No'" 
                      [term]="'MarketZipCode'" 
                      [ngClass]="item.ICompletesEmail ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsProcessed ? 'Yes' : 'No'" 
                      [term]="'MDN'" 
                      [ngClass]="item.IsProcessed ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsSubscribed ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsSubscribed ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsSubscriptionSynced ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsSubscriptionSynced ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsComplete ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsComplete ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsFailed ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsFailed ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsRetryMark ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsRetryMark ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsRetried ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsRetried ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  <td class="text-nowrap">
                    <ngb-highlight 
                      [result]="item.IsRefunded ? 'Yes' : 'No'" 
                      [term]="'IsSubscribed'" 
                      [ngClass]="item.IsRefunded ? 'badge badge-outline-success' : 'badge badge-outline-danger'">
                    </ngb-highlight>
                  </td>
                  
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.CancelDate  | date :'mediumDate'" [term]="'IsSubscribed'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.CancelledBy ? item.CancelledBy :'-'" [term]="'IsSubscribed'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.RetryCounter ? item.RetryCounter : '-'" [term]="'CreatedBy'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.CreatedDate | date :'mediumDate'" [term]="'CancelledBy'"></ngb-highlight>
                </td>
                <td class="text-nowrap">
                    <ngb-highlight [result]="item.CreatedBy ? item.CreatedBy : '-' " [term]="'MDN'"></ngb-highlight>
                </td>
               
            </tr>
            


        </tbody>
        <tbody *ngIf="showLoader">
            <tr>
                <td colspan="12">
                    <div class="loader-container">
                        <label>Loading data ...</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div  *ngIf="isSkeletonLoader"  @fadeInOut style="max-width: 100%;">
      <app-skeleton-loader [tableRow]="8" [tableColumn]="6"  shape="table"></app-skeleton-loader>
  </div>





</div>