import { Component } from '@angular/core';

@Component({
  selector: 'app-free-topup-add-data',
  templateUrl: './free-topup-add-data.component.html',
  styleUrls: ['./free-topup-add-data.component.scss']
})
export class FreeTopupAddDataComponent {

}
