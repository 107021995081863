<ul class="nav nav-tabs w-100" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="plan-tab" data-bs-toggle="tab" 
    data-bs-target="#plan" type="button" role="tab" 
    aria-controls="plan" aria-selected="true">Plan</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="topup-tab" data-bs-toggle="tab" 
    data-bs-target="#topup" type="button" role="tab"
     aria-controls="topup" aria-selected="false">Topup</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="linked-tab" data-bs-toggle="tab" 
    data-bs-target="#linked" type="button" role="tab" 
    aria-controls="linked" aria-selected="false">Linked Topup</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="carrier-tab" data-bs-toggle="tab" 
    data-bs-target="#carrier" type="button" role="tab" 
    aria-controls="carrier" aria-selected="false">Carrier Details</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="plan" 
  role="tabpanel" aria-labelledby="plan-tab">
<app-plan-topup></app-plan-topup></div>
  <div class="tab-pane fade" id="topup" role="tabpanel" 
  aria-labelledby="topup-tab">
<app-topup></app-topup></div>
  <div class="tab-pane fade" id="linked" role="tabpanel" 
  aria-labelledby="linked-tab">
<app-linked-topup></app-linked-topup>
</div>
  <div class="tab-pane fade" id="carrier" role="tabpanel" 
  aria-labelledby="carrier-tab">
<app-carrier-details></app-carrier-details>
</div>
</div>