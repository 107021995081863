import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-card-renew',
  templateUrl: './credit-card-renew.component.html',
  styleUrls: ['./credit-card-renew.component.scss']
})
export class CreditCardRenewComponent {

}
