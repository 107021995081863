<div class="container-fluid">
  <!-- for desktop screen -->
  <div class="page-title d-none d-md-block">
    <div class="row">
      <div class="col-6">
        <h5 class="mb-0">{{ title }}</h5>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a (click)="routeDefault()">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>

  <!-- for mobile screen -->
  <div class="page-title d-block d-md-none">
    <div class="row">
      <div class="col-4">
        <h5 class="mb-0">{{ title }}</h5>
      </div>
      <div class="col-8">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>

</div>