import { Component, OnInit } from '@angular/core';
import { CustomerProfileService } from '../../../../shared/services/customer-profile.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-eligibility-information',
  templateUrl: './eligibility-information.component.html',
  styleUrls: ['./eligibility-information.component.scss']
})
export class EligibilityInformationComponent implements OnInit {

  eligibilityInfoFormData: FormGroup;
  formFields: any[] = [];
  editingFields: Set<string> = new Set<string>();
  editFieldName: string;
isLoading: any;

onSubmit() {
throw new Error('Method not implemented.');
}

  eligibilityInfoData:any
  constructor(
    private customerProfileService:CustomerProfileService , 
    private formBuilder:FormBuilder,
    private customerDetailedInformationService:CustomerDetailedInformationService, private commonService:CommonService
  ){
this.initializePersonalInfoForm()
  }
  ngOnInit(): void {
  this.getEligibilityInfo()
  }

  async getEligibilityInfo() {
    try {
      this.isLoading=true
      const response: any = await this.customerProfileService.getEligibilityCardData();
      if (response?.Status) {
        this.eligibilityInfoData = response?.data?.eligibilityInfo;
       await  this.buildFormFields();

        this.patchFormData();  
      } else {
       this.commonService.showToast('error','Error','Something went wrong')
      }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
 
    } finally{
      this.isLoading=false
    }
  }
  
  initializePersonalInfoForm() {
    this.eligibilityInfoFormData = this.formBuilder.group({
      enrollmentId: [{ value: '', disabled: false }, Validators.required],
      acpQualifyDate: [{ value: '', disabled: false }, Validators.required],
      nvApplicationId: [{ value: '', disabled: false }, Validators.required],
      nladSubscriberId: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      deviceQualification: [{ value: '', disabled: false }, Validators.required],
      acpDeviceOrderType: [{ value: '', disabled: false }, Validators.required],
   
    })
  }
  
  patchFormData() {
    
      this.eligibilityInfoFormData.patchValue({
        enrollmentId: this.eligibilityInfoData.enrollmentId.value,
        acpQualifyDate:  this.customerDetailedInformationService.formatDate(this.eligibilityInfoData.acpQualifyDate.value)  ,
        nvApplicationId: this.eligibilityInfoData.nvApplicationId.value || 'Not Available',
        nladSubscriberId: this.eligibilityInfoData.subscriberId.value,
        deviceQualification: this.eligibilityInfoData.deviceQualification.value,
        acpDeviceOrderType: this.eligibilityInfoData.acpDeviceOrderType.value,
      }); 
    
  }
  buildFormFields() {
    this.formFields = [
      { controlName: 'enrollmentId', label: 'Enrollment Id', editable: this.eligibilityInfoData?.enrollmentId?.editable, visible: this.eligibilityInfoData?.enrollmentId?.visible },
      { controlName: 'acpQualifyDate', label: 'ACP Qualify Date', editable: this.eligibilityInfoData?.acpQualifyDate?.editable, visible: this.eligibilityInfoData?.acpQualifyDate?.visible },
      { controlName: 'nvApplicationId', label: 'Application Id', editable: this.eligibilityInfoData?.nvApplicationId?.editable, visible: this.eligibilityInfoData?.nvApplicationId?.visible },
      { controlName: 'nladSubscriberId', label: 'NLAD Subscriber Id', editable: this.eligibilityInfoData?.subscriberId?.editable, visible: this.eligibilityInfoData?.subscriberId?.visible },
      { controlName: 'deviceQualification', label: 'Device Qualification', editable: this.eligibilityInfoData?.deviceQualification?.editable, visible: this.eligibilityInfoData?.deviceQualification?.visible  },
      { controlName: 'acpDeviceOrderType', label: 'ACP Device Order Type', editable: this.eligibilityInfoData?.acpDeviceOrderType?.editable, visible: this.eligibilityInfoData?.acpDeviceOrderType?.visible  },
    ];
  }
  enableEdit(controlName: string) {
    const control = this.eligibilityInfoData.get(controlName);
    if (control) {
      control.enable(); 
    }
  }

  removeInputField(controlName: string) {
    const field = this.formFields.find(f => f.controlName === controlName);
    if (field) {
      this.editingFields.delete(controlName);
      const fieldName = controlName === 'nladSubscriberId' ? 'subscriberId' : controlName;
      const formatValue = (fieldName: string, value: any): any => {
        if (fieldName === 'acpQualifyDate' && value) {
          return this.customerDetailedInformationService.formatDate(this.eligibilityInfoData?.acpQualifyDate?.value);
        }
        if (fieldName === '"nladSubscriberId"' && value) {
          return this.eligibilityInfoData?.subscriberId?.value;
        }
        return value || 'Not Available';
      };
      const originalValue = this.eligibilityInfoData?.[fieldName]?.value;
      const formattedValue = formatValue(controlName, originalValue);

      this.eligibilityInfoFormData.get(controlName)?.setValue(formattedValue);
    }
  }
  
  updateCustomerProfile(controlName: string) {
    let isSuccess = false;  
    let errorMessage = '';  
  
    const data: any = {};
    data[controlName] = this.eligibilityInfoFormData.get(controlName)?.value;
    this.customerProfileService.updateCustomerProfile(data)
      .then((res: any) => {

        if (res?.Status) {
          isSuccess = true;
          this.editFieldName = '';
          this.customerProfileService.getMinimalProfileData();
          this.getEligibilityInfo();
          this.removeInputField(controlName)
        } else {
          errorMessage = res?.message || 'Failed to update profile';
        }
      })
      .finally(() => {
        if (isSuccess) {
          this.commonService.showToast('success', 'Success', `${this.customerDetailedInformationService.formatKeyLabel(controlName)} updated successfully`);
        } else {
          this.commonService.showToast('error', 'Error', errorMessage || `An error occurred while updating the ${controlName}`);
          this.removeInputField(controlName)
        }
      });
  }
  onEditIconClick(editFieldName: string) {
    this.editFieldName = editFieldName;
  }


  toggleEdit(controlName: string) {
    const field = this.formFields.find(f => f.controlName === controlName);
    if (field && field.editable=='Y') {
      if (this.editingFields.has(controlName)) {
        this.editingFields.delete(controlName);
      } else {
        this.editingFields.add(controlName);
      }
    } else {
      console.warn(`${controlName} is not editable.`);
    }
  }

  isEditing(controlName: string): boolean {
    return this.editingFields.has(controlName);
  }
}
