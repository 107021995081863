import { Component } from '@angular/core';

@Component({
  selector: 'app-autopay',
  templateUrl: './autopay.component.html',
  styleUrls: ['./autopay.component.scss']
})
export class AutopayComponent {

}
