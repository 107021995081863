import { Component, Input } from '@angular/core';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { CustomerDetailedInformationService } from '../../customer-profile/shared/customer-detailed-information.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.scss']
})
export class ApplicationInfoComponent {
  @Input() minimalProfileData: any;
  @Input() fullScreen: any;
  applicationInfoFormData:any
  applicationInfodata:any
  formFields:any;



  constructor(
    private customerProfileService:CustomerProfileService,
    private formBuilder:FormBuilder,
    private customerDetailedInformationService:CustomerDetailedInformationService,
  ){
    this.initializeApplicationInfoForm()
  }
  ngOnInit(): void {
    this.getApplicationInfoData()
  }

 async  getApplicationInfoData(){
    try {
      const response: any = await this.customerProfileService.getCustomerInfoCardData();
      if(response?.Status){
        this.applicationInfodata=response?.data?.customerlInfo;
        await this.buildFormFields();
        this.patchFormData();
      }else{
        console.log('error',response?.message);
      }
    } catch (error) {
      console.log('error',error?.message);
      
    } finally{
    }
  }

  initializeApplicationInfoForm() {
    this.applicationInfoFormData = this.formBuilder.group({
      userName: [{ value: '', disabled: false }, Validators.required],
      enrollmentId: [{ value: '', disabled: false }, Validators.required],
      orderType: [{ value: '', disabled: false }, Validators.required],
      approvaldate: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      disconnectionDate: [{ value: '', disabled: false }, Validators.required],
      approvalBy: [{ value: '', disabled: false }, Validators.required],
      acpStartDate: [{ value: '', disabled: false }, Validators.required],
      shipmentStatus: [{ value: '', disabled: false }, Validators.required],
      trackingNumber: [{ value: '', disabled: false }, Validators.required],
      program: [{ value: '', disabled: false }, Validators.required],
      source: [{ value: '', disabled: false }, Validators.required],

   
    })
  }

    
  patchFormData() {
    
    this.applicationInfoFormData.patchValue({
      userName: this.applicationInfodata.userName.value,
      enrollmentId:  this.applicationInfodata.enrollId.value ,
      orderType: this.applicationInfodata.orderType.value ,
      approvaldate: this.customerDetailedInformationService.formatDate(this.applicationInfodata.approvalDate.value)  ,
      disconnectionDate:this.customerDetailedInformationService.formatDate(this.applicationInfodata.disconnectionDate.value) ,
      approvalBy: this.applicationInfodata.approvedBy.value,
      acpStartDate: this.customerDetailedInformationService.formatDate(this.applicationInfodata.acpStartDate.value)  ,
      shipmentStatus: this.applicationInfodata.shipmentStatus.value,
      trackingNumber: this.applicationInfodata.shiprackingNo.value,
      program: this.applicationInfodata.programs.value[0]?.name,
      source: this.applicationInfodata.source.value,
    }); 
  
}
buildFormFields() {
  this.formFields = [
    { controlName: 'userName', label: 'User Name', editable: this.applicationInfodata?.userName?.editable, visible: this.applicationInfodata?.userName?.visible },
    { controlName: 'enrollmentId', label: 'Enrollment Id', editable: this.applicationInfodata?.enrollId?.editable, visible: this.applicationInfodata?.enrollId?.visible },
    { controlName: 'orderType', label: 'Order Type', editable: this.applicationInfodata?.orderType?.editable, visible: this.applicationInfodata?.orderType?.visible },
    { controlName: 'approvaldate', label: 'Approval date', editable: this.applicationInfodata?.approvalDate?.editable, visible: this.applicationInfodata?.approvalDate?.visible },
    { controlName: 'disconnectionDate', label: 'Disconnection Date', editable: this.applicationInfodata?.disconnectionDate?.editable, visible: this.applicationInfodata?.disconnectionDate?.visible },
    { controlName: 'approvalBy', label: 'Approved By', editable: this.applicationInfodata?.approvedBy?.editable, visible: this.applicationInfodata?.approvedBy?.visible },
    { controlName: 'acpStartDate', label: 'ACP Start Date', editable: this.applicationInfodata?.acpStartDate?.editable, visible: this.applicationInfodata?.acpStartDate?.visible },
    { controlName: 'shipmentStatus', label: 'Shipment Status', editable: this.applicationInfodata?.shipmentStatus?.editable, visible: this.applicationInfodata?.shipmentStatus?.visible },
    { controlName: 'trackingNumber', label: 'Tracking Number', editable: this.applicationInfodata?.shiprackingNo?.editable, visible: this.applicationInfodata?.shiprackingNo?.visible },
    { controlName: 'program', label: 'Program', editable: this.applicationInfodata?.programs?.editable, visible: this.applicationInfodata?.programs?.visible },
    { controlName: 'source', label: 'Source', editable: this.applicationInfodata?.source?.editable, visible: this.applicationInfodata?.source?.visible },
  ];
}

}
