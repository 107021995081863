import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
  ) { }


  getUser() {
    return this.userDetails;
  }


  ///////////////////////////////////// Get Profile Data //////////////////////////////////////////

  getProfileData(){
    let url="api/unity/viewAccountDetail/"+this.getUser().userID
    let body={
      companyId:'UNITYCRM0021'
    }
    return this.http.get(url,body)
  }


  ///////////////////////////////////////// update Password ///////////////////////////////////////

  changePassword(obj){
    let url="api/unity/change-password"
    let body={
      companyId:'UNITYCRM0021',
      userID:this.getUser().userID,
      ...obj
    }
    console.log(body,"bodyyyyyyyyyyyyyyyy")
    return this.http.post(url,body)
  }

  
}
