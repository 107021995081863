<div class="col-sm-12">
    <div class="card p-20">
        <div class="row">
            <app-add-edit-group [singleGroupData]="singleGroupData" [id]="groupId" (addEditGroupEvent)="addEditGroupEvent()"></app-add-edit-group>
        </div>
        <hr>
        <div class="table-responsive signal-table">
            <table class="table table-hover" *ngIf="!isSkeletonLoader"  @fadeInOut >
                <thead>
                    <tr>
                        <ng-container *ngFor="let item of column">
                            <th scope="col">
                                {{item.displayName}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let item of data">
                        <tr class="shadow-showcase">
                            <ng-container *ngFor="let row of column">
                                <td *ngIf="row.mappingKey">
                                    <ng-container *ngIf="row.displayName != 'Action'">
                                        <app-table-cell [rowdata]="item" [mapping]="row.mappingKey"></app-table-cell>
                                    </ng-container>
                                    <ng-container *ngIf="row.displayName == 'Action'">
                                        <i class="fa fa-edit cursor-pointer"
                                            (click)="onEdit(item)"></i>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <tr *ngIf="isEmptyRow">
                        <td [colSpan]="column.length">
                            <div class="font-danger text-center">Sorry!!! No record matching....</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="isSkeletonLoader"  @fadeInOut  style="max-width: 100%;" class="m-2" >
                <app-skeleton-loader [tableColumn]="4" [tableRow]="4" shape="table"></app-skeleton-loader>
        </div>
            <!-- <div class="pull-right p-20">
                <ngb-pagination [collectionSize]="totalItems" [(page)]="currentPage"
                    [pageSize]="itemsPerPage"><ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
            </div> -->
        </div>
    </div>
</div>

<ng-template #modalPrompt let-modal>
    <div class="modal-body">
        <div class="close-modal p-absolute" (click)="modal.close()">
            <i class="icon-close"></i>
        </div>
        <div class="p-20">
            <h5 class="m-b-3">No content is available</h5>
        </div>
        <div class="form-group mb-3 text-end">
            <button type="button" class="btn btn-light" (click)="modal.close()">Close</button>
            <button type="button" class="btn btn-primary m-l-10" disabled>Save and Close</button>
        </div>
    </div>
</ng-template>

