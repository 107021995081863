<div class="container-fluid">
    <!-- Timeline Skeleton -->
    <div class="skeleton-timeline my-4">
      <div class="skeleton-line w-25 mb-2" style="height: 25px;"></div>
      <div class="skeleton-line w-50" style="height: 20px;"></div>
    </div>
  
    <!-- Checkout Summary Skeleton -->
    <div class="skeleton-checkout-summary my-4">
      <div class="skeleton-line w-25 mb-2" style="height: 30px;"></div>
      <div class="skeleton-line w-50 mb-4" style="height: 20px;"></div>
      <div class="skeleton-table" style="height: 150px; width: 100%;"></div>
    </div>
  
    <!-- Payment Breakdown Skeleton -->
    <div class="skeleton-payment-breakdown my-4">
      <div class="skeleton-line w-50 mb-2" style="height: 20px;"></div>
      <div class="skeleton-line w-25 mb-2" style="height: 20px;"></div>
      <div class="skeleton-line w-75" style="height: 20px;"></div>
    </div>
  
    <!-- Payment Details Skeleton -->
    <div class="skeleton-payment-details my-4">
      <div class="skeleton-line w-25 mb-2" style="height: 30px;"></div>
      <div class="skeleton-line w-75" style="height: 20px;"></div>
    </div>
  
    <!-- Payment Method Skeleton -->
    <div class="skeleton-payment-method my-4">
      <div class="skeleton-line w-100 mb-2" style="height: 30px;"></div>
      <div class="skeleton-line w-100 mb-2" style="height: 20px;"></div>
      <div class="skeleton-line w-100" style="height: 20px;"></div>
    </div>
  
    <!-- Events and Logs Skeleton -->
    <div class="skeleton-events-logs my-4">
      <div class="skeleton-line w-75 mb-2" style="height: 25px;"></div>
      <div class="skeleton-line w-50" style="height: 20px;"></div>
    </div>
  </div>
  