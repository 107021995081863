<div class="dropdown">
  <button
    class="btn btn-light dropdown-toggle"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <strong>{{selectedApplication}}</strong>
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let application of applicationTypes">
        
      <a
        class="dropdown-item"
        href="#"
        (click)="selectApplication(application?.DisplayName)"
        >{{ application?.DisplayName }}</a
      >
    </li>
  </ul>
</div>
<!--  -->
<!-- 
<div class="col">
    <label for="state">State</label>
    <div class="row">
      <div class="col-md-12">
        <select id="state" formControlName="state" class="form-control">
          <option value="" disabled selected>Select State</option>
          <option value="1">State 1</option>
          <option value="2">State 2</option>
          <option value="3">State 3</option>
        </select>
      </div>
    </div>
  </div>
   -->