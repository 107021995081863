<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    Carrier Setup</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body ">
  
  <div class="container-fluid">
    <div class="row my-2">
 <div class="col-md-6">
  <label>Carrier Code</label><br>
  <input type="text" class="form-control" >
 </div>
 <div class="col-md-6">
  <label>Plan Code</label><br>
  <input type="text" class="form-control" >
 </div>
    </div>
    <div class="row my-2">
      <div class="col-md-6">
       <label>Carrier</label><br>
       <input type="text" class="form-control" >
      </div>
      <div class="col-md-6">
       <label>Voice</label><br>
       <input type="text" class="form-control" >
      </div>
         </div>
         <div class="row my-2">
          <div class="col-md-6">
           <label>Text</label><br>
           <input type="text" class="form-control" >
          </div>
          <div class="col-md-6">
           <label>Date</label><br>
           <input type="text" class="form-control" >
          </div>
             </div>
             <div class="row my-2">
              <div class="col-md-6">
               <label>Billing Code/PWG Cost Plan</label><br>
               <select class="form-control form-select">
                <option>Select </option>
                </select>
                <span class="text-primary">
                  Note - If the Billing code/ PWG cost plan has no values in the dropdown, please contact carrier PWG to setup these Billing codes. Once they are setup they will automatically appear in the dropdown.
                </span>
              </div>
                 </div>
  </div>


</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-primary" (click)="modal.dismiss('cancel click')">Cancel</button>
  <button type="button" ngbAutofocus class="btn btn-primary ms-3" (click)="modal.close('Ok click')">Save</button>
</div>