<div class="container-fluid">
    <div class="row">
      <!-- Address Information Section (Left Column) -->
      <div class="col-md-8" >
        <!-- Title and Description -->
        <div class="skeleton-title mb-3"></div>
        <div class="skeleton-subtitle mb-4"></div>
  
        <!-- Present Addresses Section -->
        <div class="skeleton-box p-3 mb-4">
          <div class="skeleton-label w-50 mb-2"></div>
          <div class="skeleton-input mb-3"></div>
          <div class="skeleton-label w-50 mb-2"></div>
          <div class="skeleton-input mb-3"></div>
        </div>
  
        <!-- Address Type -->
        <div class="d-flex align-items-center mb-4">
          <div class="skeleton-radio w-5 me-3"></div>
          <div class="skeleton-radio w-5 me-3"></div>
          <div class="skeleton-radio w-5"></div>
        </div>
  
        <!-- Address Line 1 -->
        <div class="mb-3">
          <div class="skeleton-label w-25 mb-2"></div>
          <div class="skeleton-input w-100"></div>
        </div>
  
        <!-- Address Line 2 -->
        <div class="mb-3">
          <div class="skeleton-label w-25 mb-2"></div>
          <div class="skeleton-input w-100"></div>
        </div>
  
        <!-- Country and State Dropdowns -->
        <div class="d-flex mb-3">
          <div class="me-3 flex-fill">
            <div class="skeleton-label w-50 mb-2"></div>
            <div class="skeleton-select w-100"></div>
          </div>
          <div class="flex-fill">
            <div class="skeleton-label w-50 mb-2"></div>
            <div class="skeleton-select w-100"></div>
          </div>
        </div>
  
        <!-- City and Zip Code -->
        <div class="d-flex mb-3">
          <div class="me-3 flex-fill">
            <div class="skeleton-label w-50 mb-2"></div>
            <div class="skeleton-input w-100"></div>
          </div>
          <div class="flex-fill">
            <div class="skeleton-label w-50 mb-2"></div>
            <div class="skeleton-input w-100"></div>
          </div>
        </div>
  
        <!-- Address Type Radio Buttons -->
        <div class="d-flex align-items-center mb-4">
          <div class="skeleton-radio w-5 me-3"></div>
          <div class="skeleton-radio w-5"></div>
        </div>
  
        <!-- Submit Button -->
        <div class="skeleton-button w-100"></div>
        <div class="payment-form p-0" *ngIf="isPaymentForm">
          <app-skeleton-loader shape="payment-form"></app-skeleton-loader>
        </div>

      </div>
  
      <!-- Address Summary (Right Column) -->
      <div class="col-md-4" *ngIf="isAddressSummary">
        <div class="skeleton-summary p-4" style="height:100% !important">
          <div class="skeleton-summary-title mb-3"></div>
          <div class="skeleton-summary-item mb-2"></div>
          <div class="skeleton-summary-item mb-2"></div>
          <div class="skeleton-summary-item mb-2"></div>
          <div class="skeleton-summary-item mb-2"></div>
          <div class="skeleton-summary-item mb-2"></div>
          <div class="skeleton-summary-item"></div>
        </div>
      </div>
    </div>
  </div>
  