


         

<div class="container-fluid pb-3 m-0 " style="max-width:100% !important" >
  <div class="row px-0 mx-0">
    
    <!-- Right Section: Order Summary -->
    <div class="col-12 col-md-5 order-md-2 p-0 section mt-0 " >
      <div class="container mt-6">
        <div class="plan-card" style="background-color: transparent">
          <div class="card p-4 mt-4" style="background-color: transparent;border: 1px solid rgba(255, 255, 255, 0.2);">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div>
                <h4 class="plan-title text-white">Summary</h4>
                <p class="text-white">Order Summary and Details</p>
              </div>
              
            </div>
            <table class="table table-responsive w-100" style="background-color: white; border:1px solid lightgray" *ngIf="selectedCombosWithAmount?.selectedCombos?.length > 0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Discount Amount</th>
                  <th class="text-primary">Payable Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let combo of selectedCombosWithAmount.selectedCombos; let i = index">
                  <td>{{ i + 1 }}</td> <!-- Display item count -->
                  <td>{{ combo?.comboTitle }}</td> <!-- Display item title -->
                  <td>{{ combo?.comboDesc }}</td> <!-- Display item description -->
                  <td>{{ combo?.comboType }}</td> <!-- Display item type -->
                  <td>${{ combo?.comboPrice }}</td> <!-- Display original price -->
                  <td>${{ combo?.discountPrice }}</td> <!-- Display original price -->
                  <td class="text-primary">${{ combo?.comboPrice - combo?.discountPrice }}</td> <!-- Display original price -->
                </tr>
              </tbody>
            </table>
            
            <!-- Display total amount -->
            <div class="d-flex justify-content-between font-weight-bold mt-3 text-dark">
              <div></div>
              <div class="text-white"> <span class="text-danger"> Total Payable Amount:  </span>  ${{ amount?.value?.toFixed(2) || '0' }}</div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>

    <!-- Left Section: Additional Details -->
    <div class="col-12 col-md-7 order-md-1 section p-0">
 
          <div class="row">
            
            <div class="modal-body">
              <div class="tab-content mt-3">
            
                  <div class="container mt-4">
                     <div class="row">
                    
                      <div class="col-md-12">
                          <div class="col-md-12 jsutify-content-center">
                            
                              <div class=" p-1" style="background-color: transparent;">
                                <div class="col-md-12">
                                  <h4 class="text-white">Payment Option</h4>
                                  <p class="text-white">All transactions are secure and encrypted.</p>
                                </div>
                                <div class=" p-0 pb-2 rounded">
                                  <form [formGroup]="formData" class="mt-3 custom-form-width d-flex flex-column " >
                                  
                                    <!-- Devices Section -->
                                    <div class="form-group row mb-3">
                                      <div class="col-md-12">
                                        <ng-select 
                                          formControlName="combos" 
                                          [multiple]="true" 
                                          (change)="onSelectDeviceCombos()" 
                                          placeholder="Devices">
                                          <ng-option *ngFor="let item of deviceCombos" [value]="item">
                                            {{item.comboTitle}}, {{item.comboDesc}}, {{item.comboType}} (Price {{+item?.comboPrice - item?.discountPrice}})
                                          </ng-option>
                                        </ng-select>
                                      </div>
                                    </div>
                                    
                                    
                                    <!-- Amount Section -->
                                    <div class="form-group row mb-3">
                                      <div class="col-md-12">
                                        <input 
                                          type="text" 
                                          formControlName="amount" 
                                          readonly 
                                          class="form-control" 
                                          placeholder="Amount">
                                      </div>
                                    </div>
                                    
                              
                                    <!-- Card Holder's Name Section -->
                                    <div class="form-group mb-3">
                                      <input 
                                        type="text" 
                                        formControlName="nameOnCard" 
                                        class="form-control" 
                                        [placeholder]="getPlaceholder('nameOnCard')" 
                                        [ngClass]="{
                                          'is-invalid': formData.get('nameOnCard')?.touched && formData.get('nameOnCard')?.invalid,
                                          
                                        }"
                                      >
                                    </div>
                                    
                                    <!-- Card Number Section with Credit Card Icons -->
                                    <div class="form-group position-relative">
                                      <input 
                                        type="text" 
                                        formControlName="cardNumber" 
                                        maxlength="16" 
                                        class="form-control pe-5" 
                                        [placeholder]="getPlaceholder('cardNumber')"  
                                        [ngClass]="{
                                          'is-invalid': formData.get('cardNumber')?.touched && formData.get('cardNumber')?.invalid,
                                          'error-placeholder': formData.get('cardNumber')?.touched && formData.get('cardNumber')?.invalid
                                        }"
                                        (input)="onCardNumberChange($event)"
                                      >
                                      <div 
                                      class="credit-card-icons d-flex position-absolute end-0 top-50 translate-middle-y pe-2"
                                      *ngIf="!formData.get('cardNumber')?.touched || !formData.get('cardNumber')?.invalid">
                                      <img 
                                      src="../../../../../assets/images/credit-cards/card.png" 
                                      alt="master-card" 
                                      class="credit-card-icon me-1" 
                                      [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
                                  
                                      <img 
                                      src="../../../../../assets/images/credit-cards/american-express.png" 
                                      alt="amex" 
                                      class="credit-card-icon me-1" 
                                      [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
                                  
                                      <img 
                                      src="../../../../../assets/images/credit-cards/visa.png" 
                                      alt="visa" 
                                      class="credit-card-icon" 
                                      [ngClass]="{'disabled': !isCardTypeValid('Visa')}">
                                  </div>
                                    </div>
                              
                                    <!-- Expiry Date and CVV Section -->
                                    <div class="form-group row mb-3">
                                      <div class="col-md-6">
                                        <select formControlName="expireMonth" class="form-control form-select"
                                        [ngClass]="{
                                          'is-invalid': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid,
                                        'error-placeholder': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid
                                        }">
                                          <option [value]="''">{{ getExpireMonthPlaceholder() }}</option>
                                          <option *ngFor="let month of expireMonthDropdown" [value]="month">{{ month }}</option>
                                        </select>
                                      </div>
                                      <div class="col-md-6">
                                        <select formControlName="expireYear" class="form-control form-select"
                                        [ngClass]="{
                                          'is-invalid': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid,
                                        'error-placeholder': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid
                                        }">
                                          <option [value]="''">{{ getExpireYearPlaceholder() }}</option>
                                          <option *ngFor="let year of expireYearDropdown" [value]="year">{{ year }}</option>
                                        </select>
                                      </div>
                                    </div>
                              
                                    <div class="form-group mb-3">
                                      <input 
                                        type="password" 
                                        maxlength="4" 
                                        formControlName="cardCode" 
                                        class="form-control" 
                                        [placeholder]="getPlaceholder('cardCode')" 
                                        [ngClass]="{
                                          'is-invalid': formData.get('cardCode')?.touched && formData.get('cardCode')?.invalid,
                                          'error-placeholder': formData.get('cardCode')?.touched && formData.get('cardCode')?.invalid
                                        }"
                                      >
                                    </div>
                              
                                   
                                     <!-- Submit Button -->
                                     <div class="col-md-12 px-0" >
                                      <button type="submit" class="btn custom-form-width btn-primary submit-button w-100 mt-3 d-flex align-items-center justify-content-center px-0 mx-0" (click)="onSubmit()"  ><h5>Submit</h5></button>
                  
                                   
                                  </div>
                                  </form>
                                   
                                </div>
                                
                                </div>
                                
                          </div>
                      </div>
                    </div>
                  </div>
               </div>
            </div> 
          </div>
                  
     </div>
      

  </div>
</div>

<div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
  <app-skeleton-loader [isAddressSummary]="true" [isPaymentForm]="true" shape="address-form"></app-skeleton-loader>
</div>