import { Component } from '@angular/core';

@Component({
  selector: 'app-wallet-purchase',
  templateUrl: './wallet-purchase.component.html',
  styleUrls: ['./wallet-purchase.component.scss']
})
export class WalletPurchaseComponent {

}
