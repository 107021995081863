import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(
    private http: HttpService
  ) { }

  getActivityInfo() {
    try {
      let body = {
        "type": "ActivelityLog"
      }
      return this.http.get('api/Customer/getActivityInfo', body, false, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

}
