import { Injectable, TemplateRef } from '@angular/core';
// import { MessageService } from 'primeng/api';
import { BehaviorSubject, catchError, Observable, Subject, throwError } from 'rxjs';
import { HttpService } from './httpService.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  loader: boolean = false;
  private unsubscriber: Subject<any> = new Subject();
  public breadCrumb: BehaviorSubject<any> = new BehaviorSubject({});
  public toastSubject = new Subject();
  private searchQuerySubject = new BehaviorSubject<boolean>(false);
  private api: string = environment.PREPAID_UNITYWIRELESS

  private myTicketsSubject = new BehaviorSubject<boolean>(false);
  myTicketsSubject$ = this.myTicketsSubject.asObservable();
  async getAwaitingShipmentOrders(data: any): Promise<any> {
    try {
      const shipmentOrderurl = `api/orders/shipmentOrders`
      let para = {
        "per_page": data.per_page,
        "page": data.page,
        "searchTerm": data.searchterm,
        "fromDate": data.fromDate,
        "toDate": data.toDate

      }
      return await this.http.get(shipmentOrderurl, false, false).toPromise()
    } catch (error) {
      this.showToast("error", "Error", "Error found");
    }
  }
  getAwaitingShipmentOrder(data: any): Observable<any> {
    const shipmentOrderurl = `api/orders/shipmentOrders`;

    let para: any = {
      "per_page": data.per_page,
      "page": data.page,
      "searchTerm": data.searchterm || "",
      'orderBy': 'asc',
    };
    if (data.paymentStatus && data.paymentStatus.length > 0) {
      para["paymentStatus[]"] = data.paymentStatus;
    }
    if (data.sortByField) {
      para["sortByField"] = data.sortByField;
    }
    if (data.orderStatus && data.orderStatus.length > 0) {
      para["orderStatus[]"] = data.orderStatus;
    }
    if (data.shipmentStatus && data.shipmentStatus.length > 0) {
      para["shipmentStatus[]"] = data.shipmentStatus;
    }
    if (data.CustomerId ) {
      para["CustomerId"] = data.CustomerId
    }

    return this.http.get(shipmentOrderurl, para).pipe(
      catchError((error) => {
        this.showToast('error', 'Error', 'Error found');
        return throwError(error);
      })
    );
  }

  public carouselOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    autoplaySpeed: 500,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    navText: ["", ""],
    nav: true
  }

  constructor(
    // private messageService: MessageService
    private http: HttpService,
    private modalService: NgbModal,
  ) { }

  startLoader() {
    this.loader = true;
  }

  stopLoader() {
    this.loader = false;
  }

  public setbreadCrumb(value: any): void {
    this.breadCrumb.next(value);
  }

  openUnityWireless() {
    window.open(this.api)
  }
  showToast(type, title, message) {
    this.toastSubject.next({ type, title, message })
    // if (type == "success") {
    //   this.messageService.add({ severity: 'success', summary: title, detail: message });
    // } else if (type == "info") {
    //   this.messageService.add({ severity: 'info', summary: title, detail: message });
    // } else if (type == "warn") {
    //   this.messageService.add({ severity: 'warn', summary: title, detail: message });
    // } else if (type == "error") {
    //   this.messageService.add({ severity: 'error', summary: title, detail: message });
    // }
  }

  public createString(items) {

    let result;
    if (items.length > 1) {
      // Join all elements except the last one, then append "and" and the last element
      result = items.slice(0, -1).join(", ") + " and " + items[items.length - 1];
    } else {
      // If the array has only one item or is empty, just join them directly
      result = items.join("");
    }
    return result;
  }

  async getCountries() {
    try {
      return this.http.get('api/system/getCountries', null, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }

  async getStates(data) {
    try {
      return this.http.get('api/system/getStates', data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }

  async getCities(data) {
    try {
      return this.http.get('api/system/getCities', data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }

  async getZipCodes(data) {
    try {
      return this.http.get('api/system/getZipCodes', data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }
  async getZipCodeId(data) {
    try {
      return this.http.get('api/system/getZipCodeId', data, false, false).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }


  formatDate(date: string | null | undefined): string {
    if (!date) {
      return "Not Available";
    }
    let dateObj = date.endsWith('Z') ? new Date(date) : new Date(date + 'Z');
    if (isNaN(dateObj.getTime())) {
      return "Not Available";
    }
    let month = dateObj.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let hour = dateObj.getUTCHours() % 12 || 12;
    let minute = dateObj.getUTCMinutes().toString().padStart(2, '0');
    let second = dateObj.getUTCSeconds().toString().padStart(2, '0');
    let ampm = dateObj.getUTCHours() >= 12 ? 'PM' : 'AM';
    return `${month} ${day} ${year}, ${hour}:${minute} ${ampm}`;
  }

  public formatYearMonthDate(dateValue: Date): string {
    const dd = dateValue.getDate().toString();
    const mm = dateValue.getMonth() + 1;
    const yyyy = dateValue.getFullYear().toString();

    const dateUpdated = {
      mm: `${mm}`.length === 1 ? `0${mm}` : mm,
      dd: dd.length === 1 ? `0${dd}` : dd,
      yyyy
    }
    return `${dateUpdated.yyyy}-${dateUpdated.mm}-${dateUpdated.dd}`;
  }


  public replaceStringSpaceWithUnderScore(data): string {
    const nD = data.split(' ');
    if (nD.length === 1) {
      return nD.join('').toUpperCase();
    }
    else {
      return nD.join('_').toUpperCase();
    }
  }

  get searchQuery() {
    return this.searchQuerySubject.asObservable();
  }

  updateSearchQuery(query: boolean) {
    this.searchQuerySubject.next(query);
  }

  formateDateAndTime(dateInput) {
    const cDate = new Date(dateInput)
    const dd = cDate.getDate().toString();
    const mm = cDate.getMonth() + 1;
    const yyyy = cDate.getFullYear().toString();
    const HH = cDate.getHours();
    const MINUTES = cDate.getMinutes();
    const updatedDate = {
      mm: `${mm}`.length === 1 ? `0${mm}` : mm,
      dd: dd.length === 1 ? `0${dd}` : dd,
      yyyy,
      HH: `${HH}`.length === 1 ? `0${HH}` : HH,
      MINUTES: `${MINUTES}`.length === 1 ? `0${MINUTES}` : MINUTES,
    }
    return `${updatedDate.mm}-${updatedDate.dd}-${updatedDate.yyyy} ${updatedDate.HH}: ${updatedDate.MINUTES}`;
  }

  public open(content: TemplateRef<any>, size) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size, centered: true }).result.then(
      (result) => {
        const closeResult = `Closed with: ${result}`;
        console.warn(closeResult)
      },
      (reason) => {
        const closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.warn(closeResult)
      },
    );
  }

  setmyTicketsSubject(value: boolean) {
    this.myTicketsSubject.next(value);
  }

  public getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  removeNullOrBlank(obj) {
    for (var prop in obj) {
      if (obj[prop] === null || obj[prop] === '') {
        delete obj[prop];
      }
    }
    return obj;
  }

}
