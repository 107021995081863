import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesService } from '../services/notes.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { LocalStorage } from 'ngx-webstorage';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit {

  formData: FormGroup;
  @Output() addEditTicketEvent = new EventEmitter<string>();
  ticketType: any[];
  userList: any[];
  groupList: any[];
  status: any[];
  id: any;

  uploadedFiles: File[] = [];
  public loader: boolean = false;

  @LocalStorage()
  private userDetails;

  constructor(
    public fb: FormBuilder,
    public notesService: NotesService,
    public commonService: CommonService,
    public customerProfileService: CustomerProfileService
  ) {

    this.initializeFormData()
  }

  initializeFormData() {
    this.formData = this.fb.group({
      "Title": [null, [Validators.required]],
      "TicketTypeID": [null, [Validators.required]],
      "Description": [null, [Validators.required]],
      "CustomerId": [null, [Validators.required]],
      "CustomerName": [null, [Validators.required]],
      "ContactNumber": [],
      "AlternateNumber": [],
      "StatusID": ['', [Validators.required]],
      "AssigneeID": [this.getUser().userID, [Validators.required]],
      "AgentID": [null],
      "GroupID": [null],
      "CreatedBy": [this.getUser().userID],
      "UpdatedBy": [this.getUser().userID],
      "CategoryID": [1],
      "AssignmentType": ["AGENT"]
    })
  }

  get getAssignmentType() {
    return this.formData.get("AssignmentType") as FormGroup;
  }

  ngOnInit() {
    this.getTicketType()
    this.getUserList()
    this.getGroupList()
    this.getStatus()
    this.formData.get('CustomerId').patchValue(this.customerProfileService.minimalProfileData.personalInfo?.customerId)
    this.formData.get('CustomerName').patchValue(this.customerProfileService.minimalProfileData.personalInfo?.name?.value)
  }

  async getTicketType() {
    try {
      const res: any = await this.notesService.getTicketTypes()
      if (res.Status) {
        this.ticketType = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async getUserList() {
    try {
      const res: any = await this.notesService.getAgents()
      if (res.Status) {
        this.userList = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      console.log(err)
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async getGroupList() {
    try {
      const res: any = await this.notesService.getGroups()
      if (res.Status) {
        this.groupList = res.data
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async getStatus() {
    try {
      const res: any = await this.notesService.getTicketsStatus()
      if (res.Status) {
        this.status = res.data
        this.formData.patchValue({
          StatusID: this.status[0]?.id
        })
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async onSubmit() {
    this.loader = true;
    try {

      const payload = this.formData.value
      const res: any = await this.notesService.addTicket(payload)
      this.formData.reset();
      if (res.Status) {
        this.id = res.data.id
        if (this.uploadedFiles.length > 0) {
          this.uploadFiles()
        }
        this.commonService.showToast("success", "Success", "Added successfully!")
        this.addEditTicketEvent.emit('')
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }

  onFileChange(event: any) {
    this.uploadedFiles = Array.from(event.target.files);
  }

  async uploadFiles() {
    const form = new FormData()
    form.append('companyId', "UNITYCRM0021")
    form.append('userID', this.getUser().userID)
    for (let index = 0; index < this.uploadedFiles.length; index++) {
      const element = this.uploadedFiles[index];
      form.append('files[]', element)
    }
    form.append('TicketID', this.id)
    try {
      const res = await this.notesService.attachFile(form)
      this.id = null
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  async deleteAttachments(id = 4) {
    try {
      const res = await this.notesService.deleteAttachFile(id);
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  getUser() {
    return this.userDetails;
  }

  removeFile(file: File) {
    this.uploadedFiles = this.uploadedFiles.filter(f => f !== file);
  }

  onAssignmentTypeChange() {
    this.formData.get('AgentID').reset()
    this.formData.get('GroupID').reset()
  }
}
