import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ManageMakeModelsService {
  constructor(
    private http: HttpService,
  ) { }

  public getDeviceMakeModelList(body:any):Observable<HttpResponse<any>>{
    let url="api/product/getDeviceMakeModel"
    return this.http.get(url,body);
  }
  addDeviceMakeModel(body:any){
   let url="api/product/add-device-make-model"
     return this.http.post(url,body,false,true)
   }
   updateDeviceMakeModel(body:any,id:any){
    let url=`api/product/updateDeviceMakeModel/${id}`
      return this.http.put(url,body,false,false)
    }
}
