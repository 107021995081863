<div class="d-flex justify-content-between">
    <p class="f-18" (click)="commonSettingService.moreNoteSelectedTab('MANAGE_NOTES')"><i
            class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;Note Types</p>
</div>

<div class="customizer-contain" [ngClass]="{ open: commonSettingService.moreNote == true }"
    [ngStyle]="{ 'width': commonSettingService.popup1WidthNote }">
    <div class="tab-content" id="c-pills-tabContentAgent">
        <div class="customizer-header">
            <h5 class="mb-0">List of Notes</h5>
            <i class="icofont-close icon-close" (click)="commonSettingService.toggleMoreNote()"></i>
        </div>
        <div class="customizer-header">
            <div class="row">
                <div class="col-md-6 mt-2">
                    <input type="text" class="form-control" [(ngModel)]="filterValue" (keyup)="filterData($event)"
                        placeholder="Search">
                </div>
                <div class="col-md-6 mt-2">
                    <button class="btn btn-primary"
                        (click)="commonSettingService.moreNoteSelectedTab1('Line Information')">+ Add</button>
                </div>
            </div>
        </div>
        <div class="customizer-body custom-scrollbar">
            <div class="tab-pane fade" [ngClass]="{ 'active show': commonSettingService.moreNote}" id="c-pills-home"
                role="tabpanel" aria-labelledby="c-pills-home-tab">
                <div class="notes-panel" [ngClass]="{'open-notes': true}">
                    <div class="wrapper d-flex bg-white">
                        <div class="right-panel w-100">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12 mx-auto">
                                        <div class="table-responsive marginB2" style="height: 500px;">

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" sortable="name">Name</th>
                                                        <th scope="col" sortable="description">Description</th>
                                                        <th scope="col" >Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of filterList">

                                                        <td>
                                                            <ngb-highlight [result]="item.Name"
                                                                [term]="'Name'"></ngb-highlight>
                                                        </td>
                                                        <td>
                                                            <ngb-highlight [result]="item.Description"
                                                                [term]="'Description'"></ngb-highlight>
                                                        </td>
                                                        <td>
                                                            <p class="mx-2" (click)="editItemOpen(item)">
                                                                <i class="fa-solid fa-pen-to-square"></i>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="customizer-contain" [ngClass]="{ open: commonSettingService.moreNoteLevel1 == true }"
    [ngStyle]="{ 'width': commonSettingService.popup2WidthNote}">
    <div class="tab-content" id="c-pills-tabContentAgent">
        <div class="customizer-header">
            <h5 class="mb-0" *ngIf="!itemId">Add New Notes</h5>
            <h5 class="mb-0" *ngIf="itemId">Edit Notes</h5>
            <i class="icofont-close icon-close" (click)="closeModal()"></i>
        </div>
        <div class="customizer-body custom-scrollbar">

            <form [formGroup]="addNoteForm">
                <div class="card p-3">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mt-2">
                                    <label>Name</label>
                                    <input type="text" formControlName="Name" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group mt-2">
                                    <label>IsActive</label>
                                    <input type="checkbox" formControlName="IsActive" class="ms-2">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group mt-2">
                                    <label>IsDeleted</label>
                                    <input type="checkbox" formControlName="IsDeleted" class="ms-2">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-2">
                                    <label>Description</label>
                                    <textarea formControlName="Description" rows="5" class="form-control">
                           </textarea>
                                </div>

                            </div>
                            <div class="col-md-12 text-center my-3">
                                <button type="submit" *ngIf="!itemId" (click)="submit()" class="btn btn-primary">Submit</button>
                                <button type="submit" *ngIf="itemId" (click)="updateNoteType()" class="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>