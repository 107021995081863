import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent {
  @Input() minimalProfileData: any;
  @Input() fullScreen: any;
  personalInfoFormData: FormGroup;

  constructor(
    public customerProfileService: CustomerProfileService,
    private formBuilder: FormBuilder,
    private httpService: HttpService, private commonService: CommonService
  ) {
    this.initializePersonalInfoForm()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['minimalProfileData'] && this.minimalProfileData && this.fullScreen) {
      this.patchFormData();
    }
  }

  initializePersonalInfoForm() {
    this.personalInfoFormData = this.formBuilder.group({
      firstName: [{ value: '', disabled: false }, Validators.required],
      lastName: [{ value: '', disabled: false }, Validators.required],
      phone: [{ value: '', disabled: false }, Validators.required],
      email: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      dateOfBirth: [{ value: '', disabled: false }, Validators.required],
      ssn: [{ value: '', disabled: false }, Validators.required],
      serviceAddress: [{ value: '', disabled: false }, Validators.required],
      mailingAddress: [{ value: '', disabled: false }, Validators.required],
      accountStatus: [{ value: '', disabled: false }],
      accountPassword: [{ value: '', disabled: false }]
    });
  }
  patchFormData() {
    const serviceAddress = this.minimalProfileData?.personalInfo?.serviceAddress?.value;
    const mailingAddress = this.minimalProfileData?.personalInfo?.mailingAddress?.value;
    const combinedServiceAddress = [
      serviceAddress?.address1, serviceAddress?.address2, serviceAddress?.city, serviceAddress?.zipCode, serviceAddress?.state
    ].filter(part => part).join(', ');
    const combinedMailingAddress = [
      mailingAddress?.address1, mailingAddress?.address2, mailingAddress?.city, mailingAddress?.zipCode, mailingAddress?.state
    ].filter(part => part).join(', ');
    this.personalInfoFormData.patchValue({
      firstName: this.minimalProfileData?.personalInfo?.firstName?.value,
      lastName: this.minimalProfileData?.personalInfo?.lastName?.value,
      phone: this.minimalProfileData?.personalInfo?.phone?.value,
      email: this.minimalProfileData?.personalInfo?.email?.value,
      dateOfBirth: this.minimalProfileData?.personalInfo?.dateOfBirth?.value,
      ssn: this.minimalProfileData?.personalInfo?.ssn?.value,
      serviceAddress: combinedServiceAddress,
      mailingAddress: combinedMailingAddress,
      accountStatus: this.minimalProfileData?.personalInfo?.accountStatus?.value,
      accountPassword: this.minimalProfileData?.personalInfo?.accountPassword?.value
    });

    console.log(this.personalInfoFormData.value);
  }
}
