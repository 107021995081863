import { Component } from '@angular/core';

@Component({
  selector: 'app-change-account-type',
  templateUrl: './change-account-type.component.html',
  styleUrls: ['./change-account-type.component.scss']
})
export class ChangeAccountTypeComponent {

}
