<div class="container-fluid p-4">
    <div class="d-flex justify-content-end">
        <div class="sideHeader">
            <div>
                <button class="custom-button" (click)="exportOrder()">
                    <div class="d-flex gap-2 align-items-center">
                        <i class="fa fa-cloud-upload"></i>
                        <span class="text">Export</span>
                    </div>
                </button>
            </div>
            <div>
                <button class="custom-btn dark" (click)="openUnitywireless()">
                    Create Order
                </button>
            </div>
        </div>
    </div>

    <div class="d-flex gap-2 mt-3 overflow-auto" *ngIf="!isHideCard">
        <!-- <div class="col-xxl-2 col-md-4" *ngFor="let item of dataArray">
            <div class="card mb-0 small-widget">
                <app-sale-status [data]="item"></app-sale-status>

            </div>
        </div> -->
        <div class="d-flex flex-wrap w-100">
            <app-card-stats class="col-lg-2 col-sm-4 col-12 mb-3" *ngFor="let item of dataArray" [data]="item">
            </app-card-stats>
        </div>
    </div>

    <app-awaiting-shipment-order [isDropshipOrder]="isDropshipOrder" (orderedEmitted)="handleOrderedEmitted($event)"
        class="container-fluid" style="padding-left: 0"></app-awaiting-shipment-order>
</div>