import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carrier-setup-modal',
  templateUrl: './carrier-setup-modal.component.html',
  styleUrls: ['./carrier-setup-modal.component.scss']
})
export class CarrierSetupModalComponent {
constructor(public modal:NgbActiveModal){}
}
