<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-5 d-none d-md-block  img-fluid leftBanner"
      style="background: url('assets/images/logo/bg_2.jpg') no-repeat center bottom; background-size: cover;background-size: 100%;height: 100vh;z-index: 1000;">

      <div class="container overlay d-flex justify-content-center img-fluid  "
        style="height: 100vh;margin-top: 0;margin-right: 0; z-index: 1;">
        <div class="col-md-10   bg-light-purple " style="margin-top: 10px;width: 100%;text-align:center">
          <h1 class="display-7 fs-3" style="margin-top: 4rem;margin-left: 3rem; ">Welcome To <span class="text-success">
              <span class="div ">
                <span class="display-8  fs-3" style="text-align: center;">Unity Wireless</span>
                <span class="vr vr-dark mx-3 fs-2"></span>
                <span class="display-7 fs-3" style="text-align: center;">Mingle</span>
              </span>
            </span></h1>
          <p class="lead fs-6 "
            style="margin: 2rem;text-wrap: wrap; text-align: center;padding-left: 2%;color: lightslategrey;font-weight: 500;">
            An effective tool and operating system to manage your customers lifecycle.</p>
          <!-- <img src="path-to-your-image" class="img-fluid" alt="Impact Illustration"> -->
          <div class="d-flex flex-row justify-content-between" style="position: absolute; bottom: 0;width: 20%;">
            <a class="logo ">
              <img class="img-fluid for-light" width="50px" src="assets/images/logo/logo.png" alt="unity_wireless" />
              <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
            </a>
            <a class="logo ">
              <img class="img-fluid for-light" width="50px" src="assets/images/logo/mingle.png" alt="mingle" />

              <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
            </a>

          </div>
        </div>
      </div>
    </div>



    <div class="col-md-7 col-sm-12" style="padding-left: 0;">
      <div class="login-card p-0" style="margin-left: 0;">
        <div>
          <div class="d-flex flex-row justify-content-evenly">
            <a class="logo ">
              <img class="img-fluid for-light" width="120px" src="assets/images/logo/logo.png" alt="unity_wireless" />
              <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
            </a>
            <a class="logo ">
              <img class="img-fluid for-light" width="120px" src="assets/images/logo/mingle.png" alt="mingle" />

              <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
            </a>

          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="on">
              <h4>Sign in to your account</h4>
              <p>Enter your username & password to login</p>

              <div class="form-group">
                <label class="col-form-label">User Name*</label>
                <input class="form-control" type="text" placeholder="User Name" formControlName="email" name="username"
                  autocomplete="username" />
              </div>

              <div class="form-group">
                <label class="col-form-label">Password*</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password"
                  placeholder="*********" name="password" autocomplete="current-password" />
                <div class="show-hide" (click)="showPassword()">
                  <span *ngIf="!show">Show</span>
                  <span *ngIf="show">Hide</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="loginForm?.invalid" type="submit">
                    <span>Sign in</span>
                  </button>
                </div>
              </div>
            </form>

           
          </div>

          <span class="text-muted mt-4 small w-75 m-auto d-block">
            <b class="text-dark">Notice :</b>
            You are going to enter a system owned by Unity Wireless Inc. The information on this computer system is for official use only. This system can only be accessed by authorized users. 
            Federal or state criminal laws may be broken by unauthorized access to, use of, or modification of this computer system, the data it contains, or the data while it is being transferred to or from this system. Administrative penalties, fines, or imprisonment may be imposed on anyone who gains unauthorized access to a computer system, exceeds their authorized access authority, obtains, alters, damages, destroys, or discloses information, or obstructs authorized use of the system's data.</span>
        </div>
      </div>
      
    </div>
  </div>
</div>