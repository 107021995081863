import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { COMPANYDB, CompanyDB } from 'src/app/shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { CustomerHistoryService } from '../services/customer-history.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-customer-history-table',
  templateUrl: './customer-history-table.component.html',
  styleUrls: ['./customer-history-table.component.scss']
})
export class CustomerHistoryTableComponent {

  data: any = [];
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];

  constructor(
    private customerService: CustomerHistoryService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit() {
    this.getData()
  }

  async getData() {
    
    try {
      let data = {
        "per_page": this.itemsPerPage,
        "page": this.currentPage,
      }
      const res: any = await this.customerService.getPlanHistory(data)

      if (res.Status) {
        this.data = res.data[0]?.data
        this.totalItems = res.data[0].total
        this.currentPage = res.data[0].current_page
        this.first_page_url = res.data[0].first_page_url
        this.last_page_url = res.data[0].last_page_url
        this.next_page_url = res.data[0].next_page_url
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err.error.message);
    }
    
  }

  pageChange(event) {
    this.currentPage = event
    this.getData()
  }

  onChangeNumberOfPage() {
    this.getData()
  }


}
