<!-- <div class="p-3" style="margin-top: -10px;">
    <div class="row">
        <div class="col-12">
            <app-breadcrumb></app-breadcrumb>
        </div>
    </div>
</div> -->

<div class="container card p-3">
    <div class="row">
        <div class="col-md-6">
            <h5>Search Results Page</h5>
        </div>
        <div class="col-md-6" style="text-align: end;"  *ngIf="!isAdvanceSearched">
            <h5>Search Count : {{     searchCount}}</h5>
        </div>
        <div class="col-md-6" style="text-align: end;"  *ngIf="isAdvanceSearched">
            <h5>Search Count : {{ totalItems}}</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mx-auto">
            <div class="custom-datatable table-responsive">
                <ng-container *ngIf="!isAdvanceSearched">
                    <table class="table table-hover " style="text-wrap: nowrap;">
                        <thead>
                            <tr>
                                <th scope="col">Cust Id</th>
                                <th scope="col">Enroll Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Address </th>
                                <th scope="col">SIM</th>
                                <th scope="col">MDN</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" (click)="setUser(item)">
                                <td scope="row">
                                    {{item.CustomerID}}
                                </td>
                                <td scope="row">
                                    {{item.EnrollmentNo}}
                                </td>
                                <td scope="row">
                                    {{item.FirstName}} {{item.LastName}}
                                </td>
                                <td scope="row">
                                    {{item.ServiceAddress1}}, {{item.ServiceAddress2}}, {{item.CityName}}
                                    {{item.StateCode}}, {{item.ZipCode}}
                                </td>
                                <td scope="row">
                                    {{item.iccid}}
                                </td>
                                <td scope="row">
                                    {{item.TelephoneNumber}}
                                </td>
                                <td scope="row">
                                    {{item.AccountStatus}}
                                </td>
                                <!-- <td>
                                </td>
                                <td>
                                </td> -->
                                <!-- <td>
                                    <ngb-highlight [result]="item.position" [term]="service.searchTerm"></ngb-highlight>
                                </td> -->

                            </tr>
                        </tbody>
                    </table>
                   
                </ng-container>

                <ng-container *ngIf="isAdvanceSearched">
                    <table class="table table-hover " style="text-wrap: nowrap;">
                        <thead>
                            <tr>
                                <th scope="col" *ngFor="let item of column">{{item.displayName}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" (click)="setUser(item)">
                                <td scope="row">
                                    {{item.serialNumber}}
                                </td>
                                <td scope="row">
                                    {{item.orderDate}}
                                </td>
                                <td scope="row">
                                    {{item.orderItems}}
                                </td>
                                <td scope="row">
                                    {{item.nvNladDate}}
                                </td>
                                <td scope="row">
                                    {{item.EnrollNumber}}
                                </td>
                                <td scope="row">
                                    {{item.enrollId}}
                                </td>
                                <td scope="row">
                                    {{item.subscriberId}}
                                </td>
                                <td scope="row">
                                    {{item.customerId}}
                                </td>
                                <td scope="row">
                                    {{item.FirstName}}
                                </td>
                                <td scope="row">
                                    {{item.LastName}}
                                </td>
                                <td scope="row">
                                    {{item.FullName}}
                                </td>
                                <td scope="row">
                                    {{item.Email}}
                                </td>
                                <td scope="row">
                                    {{item.StateName}}
                                </td>
                                <td scope="row">
                                    {{item.ZipCode}}
                                </td>
                                <td scope="row">
                                    {{item.agentId}}
                                </td>
                                <td scope="row">
                                    {{item.status}}
                                </td>
                                <td scope="row">
                                    {{item.amountPaid}}
                                </td>
                                <td scope="row">
                                    {{item.address}}
                                </td>
                                <td scope="row" style="white-space: nowrap;">
                                    {{item.deviceInfo}}
                                </td>
                                <td scope="row">
                                    {{item.Tribal}}
                                </td>
                                <td scope="row">
                                    {{item.carrier}}
                                </td>
                                <td scope="row">
                                    {{item.applicationId}}
                                </td>
                                <td scope="row">
                                    {{item.EnrollmentType}}
                                </td>
                                <td scope="row">
                                    {{item.LastComment}}
                                </td>
                                <td scope="row">
                                    {{item.source}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </ng-container>
            </div>


            <div class="pull-right p-20 d-flex">
                <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                    (change)="onChangeNumberOfPage()">
                    <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                </select>
                <button class="btn btn-secondary me-3" [disabled]="!prev_page_url"
                    (click)="onPrevious()">Previous</button>
                <button class="btn btn-secondary" [disabled]="!next_page_url" (click)="onNext()">Next</button>
                
            </div>
        </div>
    </div>
</div>

