import { Component, OnInit } from '@angular/core';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-upcoming-plans',
  templateUrl: './upcoming-plans.component.html',
  styleUrls: ['./upcoming-plans.component.scss'],
  animations:[fadeInOut]
})
export class UpcomingPlansComponent implements OnInit {
  sectionHeaders: Array<{ title: string, headers: any[] }> = [];
  planDetails: any[] = [];
  moreDetails: any[] = [];
  statusInformation: any[] = [];
isLoading: any;
isSkeletonloader: boolean=true;

  constructor(private customerProfileService: CustomerProfileService, 
    private customerDetailedInformationService:CustomerDetailedInformationService,
    private commonService:CommonService
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  async initializeData(): Promise<void> {
    try {
      this.isLoading=true
      const res = await this.getPlanScheduler();
    if (res?.Status) {
      const data = res.data;
      this.sectionHeaders = this.formatHeaderData();
      this.planDetails = this.extractSectionData(data, 'Plan Details');
      this.moreDetails = this.extractSectionData(data, 'More Details');
      this.statusInformation = this.extractSectionData(data, 'Status Information');
      this.isSkeletonloader=false;
    } else{
      this.commonService.showToast('error','Error',res?.message)
    }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
      
    } finally{
      this.isLoading=false
    }
  }

  async getPlanScheduler() {
    const res = await this.customerProfileService.getPlanScheduler() as any;
    return res;
  }

  formatHeaderData() {
    return [
      {
        title: 'Plan Details',
        headers: [
          { controlName: 'CurrentPlan', label: 'Current Plan' },
          { controlName: 'ScheduledPlan', label: 'Upcoming Plan' },
          { controlName: 'ScheduleDate', label: 'Scheduled Date' },
          { controlName: 'IsImmediate', label: 'Immediate Processing' },
          { controlName: 'IsSubscribed', label: 'Subscribed Status' },
          { controlName: 'Status', label: 'Status' },
          { controlName: 'ScheduleDate', label: 'Scheduled Date' },
          { controlName: 'CancelDate', label: 'Cancellation Date' },
          { controlName: 'CancelledBy', label: 'Cancelled By' },
          { controlName: 'CreatedBy', label: 'Created By' },
          { controlName: 'CreatedDate', label: 'Creation Date' }
        ]
      },
      {
        title: 'More Details',
        headers: [
         
        ]
      }
        ];
  }
  
  isExcluded(label: string): boolean {
    return label === 'cancelledBy' || label === 'createdBy';
  }
  extractSectionData(data: any[], sectionTitle: string): any[] {
    const headers = this.sectionHeaders.find(section => section.title === sectionTitle)?.headers || [];
    return data.map(item => {
      const extractedItem = {};
      headers.forEach(header => {
        let value = item[header.controlName];
        if (value === 0 && header.controlName !='CreatedBy' && header.controlName !='CancelledBy') {
          value = 'No';
        } else if (value === 1  && header.controlName !='CreatedBy' && header.controlName !='CancelledBy') {
          value = 'Yes';
        }
        if (header.controlName.includes('Date') && value) {
          value = this.customerDetailedInformationService.formatDate(value);
        }
        extractedItem[header.label] = value || 'Not Available';
      });
      return extractedItem;
    });
  }
  
  

  getPlanDetailsHeaders() {
    return this.sectionHeaders.find(section => section.title === 'Plan Details')?.headers || [];
  }

  getmoreDetailsHeaders() {
    return this.sectionHeaders.find(section => section.title === 'More Details')?.headers || [];
  }

  getStatusInformationHeaders() {
    return this.sectionHeaders.find(section => section.title === 'Status Information')?.headers || [];
  }
}
