<div class="container-fluid mt-4" >
  <div class="row" *ngIf="!isSkeletonLoader">
    <!-- Address Information Form -->
    <div class="col-md-8"  >
      <div class="card p-1 m-0" >
        <h3 class="mb-3">Address Information</h3>
        <p class="text-muted">Please update your service and billing addresses.</p>

        <div class="form-background p-4 rounded">
          <div class="card p-2 bg-light">
            <h4 class="mb-4 text-muted">Present Addresses</h4>

          <!-- Present Service Address -->
          <div class="form-group mb-3 mx-2">
            <label for="presentServiceAddress">Present Service Address</label>
            <input type="text" id="presentServiceAddress" readonly class="form-control" [value]="presentServiceAddress">
          </div>

          <!-- Present Billing Address -->
          <div class="form-group mb-3 mx-2">
            <label for="presentBillingAddress">Present Billing Address</label>
            <input type="text" id="presentBillingAddress" readonly class="form-control" [value]="presentMailingAddress">
          </div>
          </div>

          <!-- Form for Updating Addresses -->
          <form [formGroup]="formData" class="mt-4">
            <!-- Address Type Radio Buttons -->
            <div class="form-group mb-3">
              <label>Address Type</label>
              <div class="d-flex">
                <div class="form-check me-3">
                  <input type="radio" [attr.disabled]="true" value="service" formControlName="addressType" id="serviceAddress" class="form-check-input">
                  <label for="serviceAddress" class="form-check-label ms-2">Service Address</label>
                </div>
                <div class="form-check me-3">
                  <input type="radio" value="mailing" formControlName="addressType" id="billingAddress" class="form-check-input">
                  <label for="billingAddress" class="form-check-label ms-2">Billing Address</label>
                </div>
                <div class="form-check">
                  <input type="radio" [attr.disabled]="true" value="both" formControlName="addressType" id="bothAddresses" class="form-check-input">
                  <label for="bothAddresses" class="form-check-label ms-2">Both</label>
                </div>
              </div>
            </div>

            <!-- Address Line 1 -->
            <div class="form-group mb-3">
              <label for="addressLine1">Address Line 1</label>
              <input type="text" id="addressLine1" formControlName="address1" class="form-control" id="addressInput">
            </div>

            <!-- Address Line 2 -->
            <div class="form-group mb-3">
              <label for="addressLine2">Address Line 2</label>
              <input type="text" id="addressLine2" formControlName="address2" class="form-control">
            </div>

            <!-- Country Dropdown -->
            <div class="form-group mb-3">
              <label for="country">Country</label>
              <ng-select [items]="countries" formControlName="country" id="country" (change)="onSelectCountry($event, true)"></ng-select>
            </div>

            <!-- State Dropdown -->
            <div class="form-group mb-3">
              <label for="state">State</label>
              <ng-select [items]="states" formControlName="state" id="state"></ng-select>
            </div>

            <!-- City -->
            <div class="form-group mb-3">
              <label for="city">City</label>
              <input type="text" id="city" formControlName="city" class="form-control">
            </div>

            <!-- Zip Code -->
            <div class="form-group mb-3">
              <label for="zipCode">Zip Code</label>
              <input type="text" id="zipCode" formControlName="zipCode" class="form-control">
            </div>

            <!-- Address Type Radio Buttons (Permanent/Temporary) -->
            <div class="form-group mb-4">
              <label>Address Type</label>
              <div class="d-flex">
                <div class="form-check me-3">
                  <input type="radio" id="permanentAddress" [value]="true" formControlName="isAddressPermanent" class="form-check-input">
                  <label for="permanentAddress" class="form-check-label ms-2">Permanent Address</label>
                </div>
                <div class="form-check">
                  <input type="radio" id="temporaryAddress" [value]="false" formControlName="isAddressPermanent" class="form-check-input">
                  <label for="temporaryAddress" class="form-check-label ms-2">Temporary Address</label>
                </div>
              </div>
            </div>

            <!-- Submit Button -->
            <div class="form-group text-end">
              <button type="button" [disabled]="formData.invalid" (click)="onSubmit()" class="btn btn-primary w-100">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Address Summary Section -->
    <div class="col-md-4" >
      <div class="card p-4 m-0"  >
        <h3>Address Summary</h3>
        <p class="text-muted">Review your address information before submitting.</p>

        <ul class="list-group p-4">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Address Line 1
            <span>{{ formData.value.address1 || '-' }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Address Line 2
            <span>{{ formData.value.address2 || '-' }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            City
            <span>{{ formData.value.city || '-' }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            State
            <span>{{ formData.value.state?.StateName || formData.value.state || '-' }}</span>
          </li>
          
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Zip Code
            <span>{{ formData.value.zipCode || '-' }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center total-summary">
            <strong>Address Type</strong>
            <strong>{{ formData.value.isAddressPermanent ? 'Permanent' : 'Temporary' }}</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



<div *ngIf="isSkeletonLoader" style="max-width: 100%;">
  <app-skeleton-loader [isAddressSummary]="true"  shape="address-form"></app-skeleton-loader>
</div>

<ng-template #uspsAddressSubmitConfirmation let-modal>
  <div class="modal-header modal-copy-header">
    <h5 class="headerTitle mb-0">USPS address confirmation for delivery</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <!-- <img src="" alt=""> -->
      </div>
      <div class="col-12">
        <p class="text-muted">
          USPS Suggested Address:
        </p>
        <p class="text-muted">
          Service Address: <b>Show Address</b>
        </p>
      </div>
      <div class="col-12 text-end">
        <button class="btn btn-secondary me-2" (click)="modal.dismiss('Cross click')">Modify</button>
        <button class="btn btn-success" (click)="onConfirmAddressConfirmation()">Confirm</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uspsAddressModifyConfirmation let-modal>
  <div class="modal-header modal-copy-header">
    <h5 class="headerTitle mb-0">Is there an apartment for suite number that you missing? Double check your address for
      delivery.</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <!-- <img src="" alt=""> -->
      </div>
      <div class="col-12">
        <p class="text-muted">
          Your Address:
        </p>
        <b>Show Address</b>
        <p class="text-muted">
          The provided (mailing or service) address is note Verified with USPS for delivery. Please click on modify to
          update your address.
        </p>
      </div>
      <div class="col-12 text-end">
        <button class="btn btn-secondary me-2" (click)="modal.dismiss('Cross click')">Modify</button>
        <button class="btn btn-success" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>