<!-- Page Header Start-->
<div class="page-header h-58" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <ng-container *ngIf="!isMobileView || isSearchActive">
      <app-search (searchClosed)="closeSearch()"> </app-search>
      <!-- <app-global-search></app-global-search> -->
    </ng-container>

    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
        <img class="img-fluid for-dark" src="assets/images/logo/logo.png" alt="" />
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
      
      <!-- <div class="badge-container d-flex align-items-center d-none d-md-block">
        <span class="badge badge-dark tag-pills-sm-mb">
          <div class="align-equal">
            <app-feather-icons [icon]="'user'"></app-feather-icons> Jane Doe (11356)
          </div>
        </span>
        <span class="badge badge-dark tag-pills-sm-mb">
          <app-feather-icons [icon]="'user'"></app-feather-icons> MDN 545567787
        </span>
        <span class="badge badge-dark tag-pills-sm-mb">
          <div class="align-equal">
            <app-feather-icons [icon]="'plus'"></app-feather-icons> Wallet Balance: $ 0.00
          </div>
        </span>
        <span class="badge badge-dark tag-pills-sm-mb me-2">
          <div class="align-equal">
            <app-feather-icons [icon]="'circle'"></app-feather-icons> ACP
          </div>
        </span>
        <app-feather-icons [icon]="'user'" style="position: relative;top: 6px;" class="cursor-pointer hover-primary"
                            (click)="toggleCustomerProfile()"></app-feather-icons>
      </div> -->

    </div>
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <app-swiper></app-swiper>
    </div>
    <div class="nav-right col-xxl-6 d-flex col-xl-5 col-md-6 col-8 pull-right right-header p-0 ms-auto">
      <div class="carousel-container pt-2">
        <div *ngFor="let item of carauselMsgList; let i = index">
          <marquee *ngIf="currentIndex === i" width="100%" direction="right" height="30px" class="text-primary marquee">
            {{ item.Message }}
            <img *ngIf="item.GifUrl" [src]="item.GifUrl" alt="Resource Image" class="marquee-image"
              style="width: 12px;">
          </marquee>
        </div>
      </div>

      <div class="nav-head">
        <ul class="nav-menus d-flex gap-2 align-items-center text-nowrap">
          <!-- <li class="application-nav" >
          <app-application></app-application>
        </li> -->
          <li class="language-nav" (click)="showCompanyChangeToggle()">
            <app-change-company></app-change-company>
          </li>
          <li class="language-nav" (click)="languageToggle()">
            <app-languages></app-languages>
          </li>
          <li *ngIf="isMobileView" class="language-nav">
            <span class="header-search" (click)="toggleSearch()">
              <svg>
                <use href="assets/svg/icon-sprite.svg#search"></use>
              </svg>
            </span>
          </li>
          <li class="onhover-dropdown">
            <!-- <app-bookmark></app-bookmark> -->
          </li>
          <li>
            <div class="mode" (click)="layoutToggle()">
              <svg>
                <use href="assets/svg/icon-sprite.svg#moon"></use>
              </svg>
            </div>
          </li>
          <li>
            <div class="mode">
              <app-notification-badge></app-notification-badge>
            </div>
          </li>
          <li class="profile-nav onhover-dropdown p-0">
            <app-my-account></app-my-account>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->