import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavService } from '../../../../services/nav.service';

@Component({
  selector: 'app-change-company',
  templateUrl: './change_company.component.html',
  styleUrls: ['./change_company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  public companyChange: boolean = false;  
  public Company: any[] = []
  public selectedCompany: any = {};
  public displayDropdowns: boolean = false;

  constructor(public navServices: NavService) { }

  ngOnInit() {
    this.Company = JSON.parse(localStorage.getItem("Applications"))
    setTimeout(() => {
      this.checkApplications();
    }, 1500);
  }

  checkApplications() {
    const localApplications = JSON.parse(localStorage.getItem('Applications')) || [];
    const currentCompany = localStorage.getItem('currentCompany') || 'unity';
    this.selectedCompany = localApplications.find(app => app.name === currentCompany) || null;
    this.changeClass(this.selectedCompany)
    this.displayDropdowns = localApplications.length > 1;
  }

  changeCompany(company) {
    if (!company) return; // Prevent null/undefined company input
    this.selectedCompany = company;
    localStorage.setItem('BaseUrl', company.BaseUrl || '');
    localStorage.setItem('currentCompany', company.name || '');
    const userDetailsKey = `tibss.${company.name}-userDetails`;
    const currentCompanyUserDetails = localStorage.getItem(userDetailsKey) || '';
    localStorage.setItem('tibss.userDetails', currentCompanyUserDetails);
    window.location.reload();
    this.changeClass(company)
  }

  changeClass(company) {
    const companyNameClass = company?.name?.replace(/\s+/g, '-').toLowerCase();
    const bodyElement = document.body;
    bodyElement.classList.forEach(cls => {
      if (cls.startsWith('company-')) {
        bodyElement.classList.remove(cls);
      }
    });
    if (companyNameClass) {
      bodyElement.classList.add(`company-${companyNameClass}`);
    }
  }

}
