<div class="media profile-media">
  <img class="b-r-10" src="https://e7.pngegg.com/pngimages/798/436/png-clipart-computer-icons-user-profile-avatar-profile-heroes-black.png" alt="" width="28px">
  <div class="media-body"><span>{{userData?.name}}</span>
    
    <p class="mb-0 font-roboto">{{userDetails?.userGroup[0]?.Name}} <i class="middle fa fa-angle-down"></i></p>
  </div>
  
</div>
<ul class="profile-dropdown onhover-show-div">
  <li routerLink="profile/my-profile"><a><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>