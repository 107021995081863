import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { TicketSystemService } from '../services/ticket-system.service';

@Component({
  selector: 'app-add-edit-group',
  templateUrl: './add-edit-group.component.html',
  styleUrls: ['./add-edit-group.component.scss']
})
export class AddEditGroupComponent implements OnInit {

  formData: FormGroup;
  @Output() addEditGroupEvent = new EventEmitter<string>();
  @Input() id: any;
  @Input() singleGroupData: any;
  isFormValuePatched: boolean = false;
  constructor(
    public fb: FormBuilder,
    public ticketSystemService: TicketSystemService,
    public commonService: CommonService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.initializeFormData()
  }

  initializeFormData() {
    this.formData = this.fb.group({
      "Code": [, Validators.required],
      "Description": [, Validators.required],
      "GroupTypeID": [3, Validators.required],
      "GroupEmail": ["info@aqsatel.com", Validators.required],
      "GroupOwner": [1, Validators.required],
      "IsActive": [false, Validators.required],
    })
  }

  ngOnInit() {
    // if (this.id) {
    //   console.log(' iam run')
    //   this.formData.patchValue(this.singleGroupData)
    // }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    if (this.id && !this.isFormValuePatched) {
      console.log(' iam run')
      this.formData.patchValue(this.singleGroupData)
      this.isFormValuePatched = true
    }
  }

  async getDataById() {
    
    try {
      // Missing api of get group by id

      // const res = await this.ticketSystemService.getTicketById(this.id)
      // console.log('get data ticket by id res', res)
      // this.formData.patchValue()
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }


  async onSubmit() {
    
    try {
      
      let data = this.formData.value
      if (this.id) {
        data.IsActive = this.formData.value.IsActive ? 1 : 0
        const res: any = await this.ticketSystemService.updateGroup(this.id, data)
     
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Updated successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
      } else {
        const res: any = await this.ticketSystemService.addGroup(data)
      
        if (res.Status) {
          this.commonService.showToast("success", "Success", "Added successfully!")
        } else {
          this.commonService.showToast("error", "Error", res.message)
        }
      }
      this.addEditGroupEvent.emit('')
      this.initializeFormData()
      this.id = null;
      this.singleGroupData = null;
      this.isFormValuePatched = false;
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }

  cancel() {
    this.initializeFormData()
    this.id = null;
    this.singleGroupData = null;
    this.isFormValuePatched = false;
  }

}
