import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeApsService {

  constructor(
    private http: HttpService
  ) { }

  getApsCodes() {
    try {
      return this.http.get('api/Customer/getApsCodes', null, false, true).toPromise()
    } catch (err) {
      throw Error(err);
    }
  }

  updateCustomerAps(data) {
    try {
      return this.http.post('api/Customer/ChangeCustomerAps', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

}