<div class="date-picker hiringCal round-border d-flex justify-content-end">
    <div class="f-row d-inline-block position-relative reports-datePicker">
      <button class="f-row f-a-center button1 text-dark round hover-light py-0 date-button">
        <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
        <input 
          [(ngModel)]="bsRangeValue" 
          (ngModelChange)="onDateRangeChange.emit(bsRangeValue)"
          [bsConfig]="bsConfig"
          bsDaterangepicker 
          class="pl-2 border-0 bg-transparent cursor-pointer" 
          [placeholder]="placeholder"
          type="text" 
          readonly 
          #drp="bsDaterangepicker" 
          style="min-width: 200px !important; color: #11100e;" 
        />
        <i class="fa fa-caret-square-o-down" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
      </button>
    </div>
  </div>
  