import { Component } from '@angular/core';

@Component({
  selector: 'app-executive-dashboard-skeleton-loader',
  templateUrl: './executive-dashboard-skeleton-loader.component.html',
  styleUrls: ['./executive-dashboard-skeleton-loader.component.scss']
})
export class ExecutiveDashboardSkeletonLoaderComponent {

  isLoading = true;

  skeletonStats = Array(6).fill({}); // Placeholder array for stats

}
