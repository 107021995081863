import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class FullfillmentService {
  

  public tableData = new Subject<any>();
  constructor(
    private http: HttpService,
    public httpService: HttpClient
  ) { }

  getOrderEnrollments(data) {
    try {
      let url = "api/orders/enrollments";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getProcessingFiles(requestData: { companyId: string; }): any {
    return this.http.get('api/system/checkProcessPendingFiles',requestData).toPromise();
  }
  /*   carousel card api */
  getOrderProvision(data:any) {
    for (let key in data) {
      if (data[key] === null) {
          delete data[key];
      }
  } 
    try {
      let url = "api/orders/order-provision";
      return this.http.get(url, data, false, false, null, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  /*   main card api */
  getOrdersCountByType(data:any) {
    try {
      for (let key in data) {
        if (data[key] === null) {
            delete data[key];
        }
    } 
      let url = "api/analytics/ordersCountByType";
      return this.http.get(url, data, false, false, null, false);
    } catch (err) {
      throw Error(err)
    }
  }
  getSmartFilter() {
    try {
      let url = "api/system/smart-filters";
      return this.http.get(url, null, false, false, null, false);
    } catch (err) {
      throw Error(err)
    }
  }
  getOrderTypes() {
    try {
      let url = "api/orders/orderTypes";
      return this.http.get(url, null, false, false, null, false);
    } catch (err) {
      throw Error(err)
    }
  }
  getShipperStationData() {
    try {
      let url = "api/shipping/getStores";
      return this.http.get(url, null, false, false, null, false);
    } catch (err) {
      throw Error(err)
    }
  }
  /*   submit btn api */
  getOrderForProvision(data: any) {
    try {
      let url = "api/orders/getOrderForProvision";
      return this.http.get(url, data, false, false);
    } catch (err) {
      throw Error(err)
    }
  }

  /*   export csv download api */
  getExportOrderForProvision(body: any) {
    try {
      let url = "api/orders/exportOrdersForProvision";
      return this.http.post(url, body, false, true);
    } catch (err) {
      throw Error(err)
    }
  }
  uploadFile(file: any) {
    try {
      let url = "api/system/upload-file";
      const formData: FormData = new FormData();
      formData.append('File', file, file.name);
      formData.append('Code', 'BULK_ORDER_PROVISION');
      formData.append('companyId', "UNITYCRM0021")
      formData.append('userID', this.http.getUser()?.userID)
      return this.http.postFile(url, formData, true);
    } catch (err) {
      throw new Error(err);
    }
  }

  /* create api */
  getCreateProvisionHandle(body: any) {
    try {
      let url = "api/orders/create-provision-handle";
      return this.http.post(url, body, false, true);
    } catch (err) {
      throw Error(err)
    }
  }

  getBatchReports(data:any) {
    try {
      let url = "api/system/getFiles";
      let body = {
        "Code": "BULK_ORDER_PROVISION",
        ...data
      }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  getBulkReports(data:any,code:any) {
    try {
      let url = "api/system/getFiles";
      let body = {
        "Code": code,
        ...data
      }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }

  getBatchFilesCount(id) {
    try {
      let url = "api/system/getFilesTo";
      let body = {
        "fileId": id,
      }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }

  getBatchFilesDetails(body) {
    try {
      let url = "api/system/getFilesToDetailed";
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  getBulkFilesDetails(body) {
    try {
      let url = "api/system/getFilesToDetailed";
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  updateAllStgProvisionHandle(ids:any) {
    try {
      let url = "api/system/updateStgProvisionHandle";
      let body = {
         "Ids":ids,
         "IsProcessed":0,
         "IsSynced":0,
        "IsFailed":0,
        "Remarks": null
    }
      return this.http.post(url, body, false, true).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  updateStgProvisionHandle(id:any) {
    try {
      let url = "api/system/updateStgProvisionHandle";
      let body = {
         "Ids":[id],
         "IsProcessed":0,
         "IsSynced":0,
        "IsFailed":0,
        "Remarks": null
    }
      return this.http.post(url, body, false, true).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  getProvisionHandle(id:any) {
    try {
      let url = "api/system/getProvisionHandle";
      let body = {
        "StgID": id

    }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
  getShipments(id:any) {
    try {
      let url = "api/system/getShipments";
      let body = {
        "CustomerId": id

    }
      return this.http.get(url, body, false, false).toPromise();
    } catch (err) {
      throw Error(err)
    }
  }
}

