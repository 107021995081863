import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent {
  id: any;
  customerId: any;
  customerDetails: any;
  order: any;
  countValue = 0;
  showIcons = false;
  minValue = 0;
  maxValue = 1;
  total: number;

  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: CommonService,
    private http: HttpService
  ) {

  }

  ngOnInit(): void {
    this.order = history.state.order;
    console.log(this.order);

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.route.queryParams.subscribe(params => {
      this.customerId = params['customerid'];
      if (this.customerId) {
        this.getCustomerDetais()
      }
    });
    if (this.id) {
      // this.getOrderDetails()
    }
  }

  increment(val) {
    if (this.countValue < this.maxValue) {
      this.countValue++;
    }
    this.total= val*1
  }

  decrement(val) {
    if (this.countValue > this.minValue) {
      this.countValue--;
    }
    this.total= val*1
  }

  back() {
    this.router.navigateByUrl("/awaiting-shipment")
  }

  printTable() {

  }

  getOrderDetails() {
    const data = {
      per_page: 10,
      page: 1,
      searchterm: this.id,
    };
    this.orderService.getAwaitingShipmentOrder(data).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );

  }

  async getCustomerDetais() {
    const data = {
      CustomerId: this.customerId
    }

    const res: any = await this.http.get('api/Customer/getCustomerPersonalInfo', data, false).toPromise();
    if (res.Status) {
      this.customerDetails = res.data
      console.log(this.customerDetails);
    }
  }

  openMap() {
    const address = this.customerDetails?.personalInfo?.billingAddress?.value;
    if (address) {
      const fullAddress = `${address.address1}, ${address.city}, ${address.state}, ${address.zipCode}`;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
      window.open(googleMapsUrl, '_blank');
    }
  }

  getSubTotal() {
    const total = this.order.order_items.reduce((sum, item) => {
      return sum + (item.product_combo_item?.devicemakemodel?.Price || 0);
    }, 0);
  
    return total.toFixed(2); // Ensures the total has 2 decimal places
  }
}
