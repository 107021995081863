import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-footer',
  templateUrl: './customer-footer.component.html',
  styleUrls: ['./customer-footer.component.scss']
})
export class CustomerFooterComponent {
  public today: number = Date.now();
  showNotes: boolean = false;
  showQuickLInks:boolean = false;

  public customizer: string = "";
  constructor() { }

  toggleNotes() {
    this.showNotes = !this.showNotes;
  }
  toggleQuicklinks(){
    this.showQuickLInks = !this.showQuickLInks;
  }
  Customizer(val) {
    this.customizer = val;
  }
}
