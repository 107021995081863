<div class="container-fluid">
    <div class="row widget-grid">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <ngb-accordion #accT="ngbAccordion">
                        <ngb-panel id="toggle-1" title="Invoice">
                            <ng-template ngbPanelContent>
                                <div class="table-responsive signal-table">
                                    <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs">
                                        <li [ngbNavItem]="1">
                                            <a ngbNavLink>Add Ad Hoc- Invoice</a>
                                            <ng-template ngbNavContent>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="tab-pane fade show active" id="list-home"
                                                            role="tabpanel" aria-labelledby="list-home-list">
                                                            <app-add-invoices></app-add-invoices>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="2">
                                            <a ngbNavLink>Add Wallet</a>
                                            <ng-template ngbNavContent>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="tab-pane fade show active" id="list-home"
                                                            role="tabpanel" aria-labelledby="list-home-list">
                                                            <app-add-wallet></app-add-wallet>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="3">
                                            <a ngbNavLink>E-Bill Setting</a>
                                            <ng-template ngbNavContent>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="tab-pane fade show active" id="list-home"
                                                            role="tabpanel" aria-labelledby="list-home-list">
                                                            <app-e-bill-setting></app-e-bill-setting>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="4">
                                            <a ngbNavLink>Pay Invoice($)</a>
                                            <ng-template ngbNavContent>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="tab-pane fade show active" id="list-home"
                                                            role="tabpanel" aria-labelledby="list-home-list">
                                                            <app-pay-invoice></app-pay-invoice>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="5">
                                            <a ngbNavLink>Adjust Wallet</a>
                                            <ng-template ngbNavContent>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="tab-pane fade show active" id="list-home"
                                                            role="tabpanel" aria-labelledby="list-home-list">
                                                            <app-adjust-wallet></app-adjust-wallet>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <hr />

                    <ul ngbNav #nav="ngbNav" [(activeId)]="active2" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Invoices</a>
                            <ng-template ngbNavContent>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                            aria-labelledby="list-home-list">
                                            <app-billing-table></app-billing-table>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Payments</a>
                            <ng-template ngbNavContent>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                            aria-labelledby="list-home-list">
                                            <app-billing-table></app-billing-table>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>