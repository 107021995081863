import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { EquipmentPurchaseService } from '../../equipment-purchase/services/equipment-purchase.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-change-imei',
  templateUrl: './change-imei.component.html',
  styleUrls: ['./change-imei.component.scss']
})
export class ChangeImeiComponent implements OnChanges {
  imeiControl = new FormControl()
  deviceCombo = new FormControl()
  updateNlad = new FormControl()
  loader=false
  deviceCombos:any[]=[]

  @Input() accountType:string | null;

  @Input('customerData') customerData
isSkeletonLoader: boolean=true;

  constructor(private customerProfileService: CustomerProfileService, private commonService: CommonService,private equipmentService:EquipmentPurchaseService, private httpService: HttpService) {
    
    this.getDeviceCombos()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.imeiControl.setValue(this.customerData.IMEI);
    this.isSkeletonLoader=false
  }

  async updateImei() {
    
    try {
      const data = {
        "comboId": this.deviceCombo.value,
        "imeiNumber": this.imeiControl.value,
        "IsNladUpdReq":this.updateNlad.value ? 'Y' : 'N'
      }
      const res: any = await this.customerProfileService.updateImei(data)
      if (res.Status) {
        this.commonService.showToast('success', "Success", 'Successfully Updated')
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }

  async getDeviceCombos() {
    this.loader = true;
    try {
      const res: any = await this.equipmentService.getDeviceCombos()
      if (res?.Status) {
        this.deviceCombos = res?.data
      }
      else {
        this.commonService.showToast('error', "Error", res.message)
      }
    }
    catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    this.loader = false;
  }


  async removeImei() {
    try {
      const confirmed = window.confirm("Are you sure you want to remove the IMEI?");
        if (!confirmed) {
            return; 
        }
        const requestData = {
            companyId: 'UNITYCRM0021',
            userID: this.httpService.getUser().userID,
            CustomerId: this.httpService.getCustomerId()
        };
        const res: any = await this.customerProfileService.removeImei(requestData);
        
        if (res.Status) {
            this.commonService.showToast("success", "Success", "IMEI removed successfully.");
        } else {
            this.commonService.showToast("res is undefined", "Error", res.data.message);
        }
    } catch (err) {
        this.commonService.showToast("error", "Error", err.message);
    }
  }
}


