<div *ngIf="control && control.invalid && (control.dirty || control.touched)">
    <span class="text-danger" *ngIf="control.errors['required']">
        This field is required
    </span>
    <span class="text-danger" *ngIf="control.errors['minlength']">
        Minimum length is {{ control.errors['minlength']?.requiredLength }}
    </span>
    <span class="text-danger" *ngIf="control.errors['maxlength']">
        Maximum length is {{ control.errors['maxlength']?.requiredLength }}
    </span>
    <span class="text-danger" *ngIf="control.errors['email']">
        Invalid email format
    </span>
    <!-- Add more validation messages as needed -->
</div>