<div class="container-fluid">
    <div class="card-header mt-0 mb-3  d-flex align-items-center gap-2 cursor-pointer" (click)="back()">
        <i class="fa-solid fa-arrow-left" style="font-size: 20px; cursor: pointer;"></i>
        <div class="heading">
            <h4 class="mb-0">Tags List </h4>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">

            <div class="mb-3">
                <h5 class="card-title">Tags List <span class="text-muted fw-normal ms-2">(Total: {{totalusers
                        }})</span></h5>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div class="input-group col-md-5 px-0">
                    <input type="text" class="form-control" value="" (keyup)="filterdData($event)">
                    <span class="input-group-btn">
                        <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
                    </span>
                </div>
                <div class="col-md-2 text-end mx-0 px-0">
                    <a (click)="openModal()" data-bs-toggle="modal" data-bs-target=".add-new"
                        class="btn btn-primary text-nowrap"><i class="bx bx-plus me-1"></i>Create</a>
                </div>

            </div>
        </div>
    </div>

    <div class="row" >
        <div class="col-lg-12">
            <div class="table-responsive" >
                <table class="table project-list-table table-nowrap align-middle table-borderless" *ngIf="!isSkeletonLoader" @fadeInOut >
                    <thead>
                        <tr class="text-center">
                      
                            <th scope="col">ID</th>
                            <th scope="col">Tags Name</th>
                            <th scope="col">Tags Type</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Status</th>
                            <th scope="col" style="width: 150px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let user of userList" >
                           
                            <td>{{ user.id }}</td>
                            <td>{{ user.Name }}</td>
                            <td>{{user.TagType}}</td>
                            <td> {{ user?.CreatedDate | date:'short'  }}  </td>
                            <td *ngIf="user.IsActive == 1" class="badge badge-soft-success">Active</td>
                            <td *ngIf="user.IsActive == 0" class="badge badge-soft-danger">Dective</td>
                            <td>
                                <ul class="list-inline mb-0" style="cursor: pointer;">
                                    <li class="list-inline-item" (click)="editRouter(user)">
                                        <i class="fa-solid fa-pen text-primary"></i>
                                    </li>
                                    <li class="list-inline-item mx-1" (click)="deleteTag(user.id)">
                                        <i class="fa fa-trash text-danger"></i>
                                    </li>
                               
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pull-right p-20 d-flex">
                    <ngb-pagination [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
                        [(page)]="currentPage" (pageChange)="pageChange($event)" [maxSize]="itemsPerPage"
                        [pageSize]="itemsPerPage">
                        <ng-template ngbPaginationPrevious>Previous</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                </div>
            </div>
           
        </div>
    </div>

</div>
<div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
    <app-skeleton-loader [tableColumn]="7" [tableRow]="10"  shape="table"></app-skeleton-loader>
  </div>
  <ng-template #tagModal let-modal>
    <form [formGroup]="tagForm">
      <div class="modal-header">
        <h5 class="modal-title">Create Tag Type</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="tagName" class="form-label"><strong>Tag Name:</strong></label>
          <input
            type="text"
            id="tagName"
            class="form-control"
            formControlName="tagName"
            placeholder="Enter tag name"
          />
          <div *ngIf="tagForm.get('tagName').invalid && tagForm.get('tagName').touched" class="text-danger">
            Tag Name is required.
          </div>
        </div>
        <div class="mb-3">
            <label for="isActive" class="form-label"><strong>Select Tag Type</strong></label>
            <select id="isActive" class="form-select" formControlName="tagTypeId" (ngModelChange)="onTagTypeChange($event)">
              <option *ngFor="let item of tagTypeList" [ngValue]="item.id">{{item.Name}}</option>
              <option [ngValue]="null">+Create New Tag Type</option>
            </select>
          </div>
        <div class="mb-3">
          <label for="isActive" class="form-label"><strong>Is Active:</strong></label>
          <select id="isActive" class="form-select" formControlName="isActive">
            <option [ngValue]="1">Yes</option>
            <option [ngValue]="0">No</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveTagData()" [disabled]="tagForm.invalid">Save Changes</button>
      </div>
    </form>
  </ng-template>