import { Component } from '@angular/core';
import { NavService } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent {

  selectedCard: any = 0;
  data = [1, 2, 3, 4];

  constructor() {
  }

  selectCard(data) {
    this.selectedCard = data
  }

}
