import { Component, Input } from '@angular/core';
import { NotesService } from 'src/app/customer-dashboard/customer-footer/customer-notes/services/notes.service';
import { formatDate } from '@angular/common';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
@Component({
  selector: 'app-latest-activity',
  templateUrl: './latest-activity.component.html',
  styleUrls: ['./latest-activity.component.scss']
})
export class LatestActivityComponent {
  allNotes: any = []
  @Input() fullScreen: any;
  public currentPage: number = 1;
  public itemsPerPage: number = 50;
  currentTime: string = '';
  currentDate: string = '';
  first_page_url: any;
  last_page_url: any;
  hasMoreData: any;
  next_page_url: any;
  totalItems: any;
  simType: any;

  constructor(public customerProfileService: CustomerProfileService, private notesService: NotesService){

  }
  async ngOnInit() {
    await this.customerProfileService.getMinimalProfileData(112)
    this.getData()
    this.updateDateTime();
    setInterval(() => this.updateDateTime(), 1000); // Update every second
  }
  updateDateTime() {
    const now = new Date();
    this.currentTime = now.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/New_York' // Specify the timezone (e.g., Eastern Time)
    });
    this.currentDate = now.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'America/New_York' // Specify the timezone
    });
  }
  async getData() {
    try {
      let data = {
        "perPage": this.itemsPerPage,
        "page": this.currentPage,
        "CustomerId":112
      };
      const res: any = await this.notesService.getNotes(data);
      if (res.Status) {
        const newNotes = res.data.data || [];

        // If this.allNotes is empty (first-time load), skip the filtering logic
        if (this.allNotes && this.allNotes.length > 0) {
          // Filter out notes that already exist in this.allNotes (to avoid duplicates)
          const uniqueNotes = newNotes.filter((newNote: any) =>
            !this.allNotes.some((existingNote: any) => existingNote.id === newNote.id)
          );

          // Append only unique notes
          this.allNotes = [...this.allNotes, ...uniqueNotes];
        } else {
          // First-time load, assign the new notes directly
          this.allNotes = newNotes;
        }

        // Update pagination and other details
        this.totalItems = res.data.total;
        this.currentPage = res.data.current_page + 1;
        this.first_page_url = res.data.first_page_url;
        this.last_page_url = res.data.last_page_url;
        this.next_page_url = res.data.next_page_url;
        this.hasMoreData = !!res.data.next_page_url;

        this.notesService.notesAvailable();

        // Convert CreatedDate to Date objects
        this.allNotes.forEach(item => {
          item.CreatedDate = new Date(item.CreatedDate);
        });
        console.log(this.allNotes);
        this.groupTimelineItems();
        console.log(this.allNotes);
      } else {
        this.notesService.noNotes();
        this.allNotes = [];
      }
    } catch (err) {
      this.allNotes = [];
    }
  }
  groupTimelineItems(): void {
    const groupedItems: any = [];
    let currentGroup = '';

    // Sort by CreatedDate descending
    this.allNotes.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());

    // Group items based on their date
    this.allNotes.forEach(item => {
      const itemGroup = this.getTimeGrouping(item.CreatedDate);
      item.group = "";

      if (itemGroup !== currentGroup) {
        // Insert a group marker only if it's a new group
        groupedItems.push({ id: itemGroup.hashCode(), ...item, group: itemGroup });
        currentGroup = itemGroup;
      }

      groupedItems.push(item); // Insert the note
    });

    this.allNotes = groupedItems;
    console.log(this.allNotes);
  }
  getTimeGrouping(date: Date): string {
    const today = new Date();
    const dayDiff = Math.floor((today.getTime() - date.getTime()) / (1000 * 3600 * 24));

    if (dayDiff === 0) {
      return 'Today';
    } else if (dayDiff <= 7) {
      return 'This Week';
    } else if (formatDate(today, 'MMMM y', 'en-US') === formatDate(date, 'MMMM y', 'en-US')) {
      return formatDate(date, 'MMMM y', 'en-US');
    } else if (today.getFullYear() === date.getFullYear()) {
      return formatDate(date, 'y', 'en-US');
    } else {
      return formatDate(date, 'y', 'en-US');
    }
  }
}
