import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavService } from '../../../../services/nav.service';
import { PermissionService } from 'src/app/shared/services/permission-service.service';

@Component({
  selector: 'app-change-company',
  templateUrl: './change_company.component.html',
  styleUrls: ['./change_company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  public companyChange: boolean = false;
  public Company: any[] = []
  public selectedCompany: any = {};
  public displayDropdowns: boolean = false;

  constructor(public navServices: NavService, private permissionService: PermissionService) { }

  async ngOnInit() {
    this.Company = JSON.parse(localStorage.getItem("Applications"))
    const currentCompany = localStorage.getItem('currentCompany') || 'unity';
    this.selectedCompany = this.Company.find(app => app.name !== currentCompany) || null;
    this.displayDropdowns = this.Company.length > 1;
    await this.Company.map(val => {
      if (val.name == "unity") {
        val.imgIcon = 'https://unitywireless.com/cdn/shop/files/unitywireless_04e7d271-53f8-4f14-835f-87ed2d976778_32x32.webp?v=1688574366'
      } else {
        val.imgIcon = 'https://www.minglemobile.com/cdn/shop/t/57/assets/favicon.svg?v=178931131784370144781732655519'
      }
    })
  }

 

  async changeCompany(company) {
    if (!company) return; // Prevent null/undefined company input
    this.selectedCompany = company;
    localStorage.setItem('BaseUrl', company.BaseUrl || '');
    localStorage.setItem('currentCompany', company.name || '');
    const userDetailsKey = `tibss.${company.name}-userDetails`;
    const currentCompanyUserDetails = localStorage.getItem(userDetailsKey) || '';
    localStorage.setItem('tibss.userDetails', currentCompanyUserDetails);
    await this.permissionService.updatePermission()
    window.location.reload();
    console.log(company);
    this.changeClass(company.name)
    
  }

  changeClass(company) {
    console.log(company);
    const companyNameClass = company?.replace(/\s+/g, '-').toLowerCase();
    const bodyElement = document.body;
    bodyElement.classList.forEach(cls => {
      if (cls.startsWith('company-')) {
        bodyElement.classList.remove(cls);
      }
    });
    if (companyNameClass) {
      bodyElement.classList.add(`company-${companyNameClass}`);
    }
  }

}
