import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class RenewalServiceService {

  constructor(private http: HttpService) { }


  getPosReport(data) {
    try {
      let url = "api/report/renewalCustomers";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPosLogs(data) {
    try {
      let url = "api/report/getPosLogs";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPosPayment(data) {
    try {
      let url = "api/report/getPosPayment";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPosInvoice(data) {
    try {
      let url = "api/report/getPosInvoice";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  generatePo(data) {
    try {
      let url = "api/restore/customer/subscription/po";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  forceSuspendAccount(data) {
    try {
      let url = "api/restore/customer/subscription/suspend";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  forceRenewAccount(data) {
    try {
      let url = "api/restore/customer/subscription/renew";
      return this.http.post(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTimelineData(data) {
    try {
      let url = "api/subscription/renewal/timeline";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getRenewalSummaryData(data) {
    try {
      let url = "api/subscription/renewal/summary";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
