import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medium-card-skeleton',
  templateUrl: './medium-card-skeleton.component.html',
  styleUrls: ['./medium-card-skeleton.component.scss']
})
export class MediumCardSkeletonComponent implements OnInit{

  @Input() alignment: 'row' | 'column' = 'row';
  @Input() cardCount: number = 1;

  cardArray: any[] = [];
  rowsArray: any[] = []; 
  ngOnInit(): void {
    this.cardArray = Array(this.cardCount).fill(0); // Create an array for the card skeletons
    this.rowsArray = Array(6).fill(0); 
  }
}
