<div class="customizer-links" [ngClass]="{ open: customizer != '' }">
  <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
    <!-- <a href="javascript:void(0)" class="nav-link" 
    id="c-pills-home-tab" (click)="Customizer('d-block')">
    <div class="settings"><i class="icon-settings"></i></div>
    <span>Open tabss</span>
  </a> -->
    <a href="javascript:void(0)" class="nav-link" [ngClass]="{ 'active show': customizer == 'option' }"
      id="c-pills-home-tab" (click)="Customizer('option')">
      <div class="settings"><i class="icon-settings"></i></div>
      <span>Quick option</span>
    </a>

    <!-- <a class="nav-link" 
      data-original-title="" title="">
      <div><i class="icon-settings"></i></div>
      <span>Document</span>
    </a> -->
    <a class="nav-link" data-original-title="" title="">
      <div><i class="icon-panel"></i></div>
      <span>Check features</span>
    </a>
    <!-- <a class="nav-link" target="_blank"  data-original-title="" title="">
      <div><i class="icon-shopping-cart-full"></i></div>
      <span>Buy now</span>
    </a> -->
    <a href="javascript:void(0)" class="nav-link" [ngClass]="{ 'active show': customizer == 'notes' }"
      (click)="Customizer('notes')">
      <div class="settings"><i class="icon-book"></i></div>
      <span>Notes</span>
    </a>
  </div>
</div>
<div id="mini" class="customizer-contain" *ngIf="customizer != ''" [ngClass]="{ open: customizer != '' }" >

  <div class="tab-content" id="c-pills-tabContent" >
    <div class="customizer-header d-flex">
      <h5>{{customizer == 'notes' ? 'Notes' : customizer == 'user-profie' ? 'Customer Profile':'Quick Options'}} </h5>
   <span class="d-flex gap-2 minimize">
    <i class=" fa-solid fa-minus text-primary" (click)="minimize()"></i>
      <i class="fa-solid fa-xmark text-primary" (click)="Customizer('')"></i>

      </span>

    </div>
    <div class="customizer-body custom-scrollbar">

      <div class="d-flex flex-column-mobile gap-2" id="notes" *ngIf="customizer !=='notes'">
        <div class=" bg-light px-0 hv-100" >
          <ul ngbNav #CustomHover="ngbNav" [activeId]="activeTab"
            class="nav  nav-pills  animation-nav   flex-column   gap-3 mb-2">
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <!-- <i class="fa-solid fa-mobile nav-icon nav-tab-position"></i> -->
                <h5 class="nav-titl nav-tab-position text-center">Add Data Topup</h5>
              </a>
              <ng-template ngbNavContent>

                <app-add-data></app-add-data>
                <!-- <app-new-plan-change [currentPlanId]="planId"></app-new-plan-change> -->
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>

                <!-- <i class="fa-solid fa-credit-card nav-icon nav-tab-position"></i> -->
                <h5 class="nav-titl nav-tab-position text-center">Plan Change</h5>
              </a>
              <ng-template ngbNavContent>
                <app-plan-change></app-plan-change>

              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>

                <!-- <i class="fa-solid fa-retweet nav-icon nav-tab-position"></i> -->
                <h5 class="nav-titl nav-tab-position ml-3 text-nowrap text-center">Change APS/Wps</h5>
              </a>
              <ng-template ngbNavContent>
                <app-change-aps></app-change-aps>

              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>

                <!-- <i class="fa-solid fa-retweet nav-icon nav-tab-position"></i> -->
                <h5 class="nav-titl nav-tab-position ml-3 text-nowrap text-center">Equipment Purchase</h5>
              </a>
              <ng-template ngbNavContent>
                <app-equipment-purchase></app-equipment-purchase>
                <!-- <app-credit-card-add-data></app-credit-card-add-data> -->
              </ng-template>
            </li>
            <li [ngbNavItem]="5">
              <a ngbNavLink>

                <!-- <i class="fa-solid fa-retweet nav-icon nav-tab-position"></i> -->
                <h5 class="nav-titl nav-tab-position ml-3 text-nowrap text-center">Features</h5>
              </a>
              <ng-template ngbNavContent>
                <app-features></app-features>
                <!-- <app-credit-card-add-data></app-credit-card-add-data> -->
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="w-100">
          <div class="tab-content text-muted mt-3 mt-lg-0">
            <div [ngbNavOutlet]="CustomHover"></div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="option" *ngIf="customizer !== 'option'" [ngClass]="{ 'active show': customizer == 'option' }"
        id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">

        <div class="wrapper d-lg-flex d-block bg-white quick-link">

          <div class="nav flex-column nav-tabs me-3 inside-sidebar" id="v-pills-tab" role="tablist"
            aria-orientation="vertical">
            <!-- <button class="nav-link" id="v-pills-add-topup-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-add-topup" type="button" role="tab" aria-controls="false"
              aria-selected="true">Add Topup Minute/Text</button> -->
            <button class="nav-link" (click)="onSelectSubTab('add-data-topup')" id="v-pills-add-data-tab"
              data-bs-toggle="pill" data-bs-target="#v-pills-add-data" type="button" role="tab"
              aria-controls="v-pills-add-data" aria-selected="false">Add Data Topup</button>
            <button class="nav-link" (click)="onSelectSubTab('plan-change')" id="v-pills-plan-change-tab"
              data-bs-toggle="pill" data-bs-target="#v-pills-plan-change" type="button" role="tab"
              aria-controls="v-pills-plan-change" aria-selected="false">Plan Change</button>
            <!-- <button class="nav-link" id="v-pills-change-account-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-change-account" type="button" role="tab" aria-controls="v-pills-change-account"
              aria-selected="false">Change Account Type</button> -->
            <!-- <button class="nav-link" id="v-pills-upgrade-tab" data-bs-toggle="pill" data-bs-target="#v-pills-upgrade"
              type="button" role="tab" aria-controls="v-pills-upgrade" aria-selected="false">Upgrade To Family
              Plan</button> -->
            <!-- <button class="nav-link" id="v-pills-plan-renew-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-plan-renew" type="button" role="tab" aria-controls="v-pills-plan-renew"
              aria-selected="false">Plan Renew</button> -->
            <button class="nav-link" (click)="onSelectSubTab('change-aps')" id="v-pills-settings-tab"
              data-bs-toggle="pill" data-bs-target="#v-pills-change-aps" type="button" role="tab"
              aria-controls="v-pills-change-aps" aria-selected="false">Change APS/Wps</button>
            <!-- <button class="nav-link" (click)="onSelectSubTab('add-product')" id="v-pills-product-tab" data-bs-toggle="pill" data-bs-target="#v-pills-product"
              type="button" role="tab" aria-controls="v-pills-product" aria-selected="false">Add Product</button> -->
            <button class="nav-link" (click)="onSelectSubTab('equipment-purchase')" id="v-pills-equipment-tab"
              data-bs-toggle="pill" data-bs-target="#v-pills-equipment" type="button" role="tab"
              aria-controls="v-pills-equipment" aria-selected="false">Equipment Purchase</button>
            <!--  <button class="nav-link" (click)="onSelectSubTab('usage')" id="v-pills-usage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-usage"
              type="button" role="tab" aria-controls="v-pills-usage" aria-selected="false"> Usage</button> -->

            <!-- <button class="nav-link" id="v-pills-plans-tab" data-bs-toggle="pill" data-bs-target="#v-pills-plans"
              type="button" role="tab" aria-controls="v-pills-plans" aria-selected="false">View Files</button> -->
            <button class="nav-link" (click)="onSelectSubTab('features')" id="v-pills-features-tab"
              data-bs-toggle="pill" data-bs-target="#v-pills-features" type="button" role="tab"
              aria-controls="v-pills-features" aria-selected="false">Features</button>
            <!-- <button class="nav-link" id="v-pills-existing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-existing"
              type="button" role="tab" aria-controls="v-pills-existing" aria-selected="false">Existing Subscriber
              Portin</button> -->
            <!-- <button class="nav-link" id="v-pills-autopay-tab" data-bs-toggle="pill" data-bs-target="#v-pills-autopay"
              type="button" role="tab" aria-controls="v-pills-autopay" aria-selected="false"> Autopay</button> -->
          </div>
          <div class="tab-content" id="v-pills-tabContent">

            <!-- <div class="tab-pane fade" id="v-pills-add-topup" role="tabpanel" aria-labelledby="v-pills-add-topup-tab">
              <app-add-topup></app-add-topup>
              <br><br><br><br>
              <br><br><br><br>
            </div> -->
            <div class="tab-pane fade" id="v-pills-add-data" role="tabpanel" aria-labelledby="v-pills-add-data-tab">
              <app-add-data *ngIf="selectedSubTab == 'add-data-topup'"></app-add-data>
              <br><br><br><br>
              <br><br><br><br>
            </div>
            <div class="tab-pane fade" id="v-pills-plan-change" role="tabpanel"
              aria-labelledby="v-pills-plan-change-tab">
              <app-plan-change *ngIf="selectedSubTab == 'plan-change'"></app-plan-change>
              <br><br><br><br>
              <br><br><br><br>
            </div>
            <!-- <div class="tab-pane fade" *ngIf="selectedSubTab == ''" id="v-pills-change-account" role="tabpanel"
              aria-labelledby="v-pills-change-account-tab">
              <app-change-account-type></app-change-account-type>
              <br><br><br><br>
              <br><br><br><br>
            </div> -->

            <!-- <div class="tab-pane fade " *ngIf="selectedSubTab == ''" id="v-pills-upgrade" role="tabpanel" aria-labelledby="v-pills-upgrade-tab">
              <app-upgrade-family-plan></app-upgrade-family-plan>
            </div> -->
            <!-- <div class="tab-pane fade" *ngIf="selectedSubTab == ''" id="v-pills-plan-renew" role="tabpanel" aria-labelledby="v-pills-plan-renew-tab">
              <app-plan-renew></app-plan-renew>
              <br><br><br><br>
              <br><br><br><br>
            </div> -->
            <div class="tab-pane fade" id="v-pills-change-aps" role="tabpanel" aria-labelledby="v-pills-change-aps-tab">
              <app-change-aps *ngIf="selectedSubTab == 'change-aps'"></app-change-aps>
            </div>
            <div class="tab-pane fade" id="v-pills-product" role="tabpanel" aria-labelledby="v-pills-product-tab">
              <app-add-product *ngIf="selectedSubTab == 'add-product'"></app-add-product>
            </div>

            <div class="tab-pane fade" id="v-pills-equipment" role="tabpanel" aria-labelledby="v-pills-equipment-tab">
              <app-equipment-purchase *ngIf="selectedSubTab == 'equipment-purchase'"></app-equipment-purchase>
              <br><br><br><br>
              <br><br><br><br>
            </div>
            <div class="tab-pane fade" id="v-pills-usage" role="tabpanel" aria-labelledby="v-pills-usage-tab">
              <app-usage *ngIf="selectedSubTab == 'usage'"></app-usage>
            </div>
            <!-- <div class="tab-pane fade" *ngIf="selectedSubTab == ''" id="v-pills-plans" role="tabpanel" aria-labelledby="v-pills-plans-tab">
              <app-view-plans></app-view-plans>
              <br><br><br><br>
              <br><br><br><br>
            </div> -->
            <div class="tab-pane fade" id="v-pills-features" role="tabpanel" aria-labelledby="v-pills-features-tab">
              <app-features *ngIf="selectedSubTab == 'features'"></app-features>
              <br><br><br><br>
              <br><br><br><br>
            </div>
            <!-- <div class="tab-pane fade" *ngIf="selectedSubTab == ''" id="v-pills-existing" role="tabpanel" aria-labelledby="v-pills-existing-tab">
              <app-existing></app-existing>
              <br><br><br><br>
              <br><br><br><br>
            </div> -->
            <!-- <div class="tab-pane fade" *ngIf="selectedSubTab == ''" id="v-pills-autopay" role="tabpanel" aria-labelledby="v-pills-autopay-tab">
              <app-autopay></app-autopay>
              <br><br><br><br>
              <br><br><br><br>
            </div> -->
          </div>
        </div>

      </div>

      <div class="tab-pane fade" [ngClass]="{ 'active show': customizer == 'notes' }" id="c-pills-home" role="tabpanel"
        aria-labelledby="c-pills-home-tab">
        <div class="notes-panel" [ngClass]="{'open-notes': true}">
          <div class="row">
            <div class="col-md-12 mx-auto mb-1">
              <app-customer-notes *ngIf="customizer == 'notes'"></app-customer-notes>
            </div>
          </div>
        </div>

      </div>

      <div class="tab-pane fade" *ngIf="customizer == 'user-profie'"
        [ngClass]="{ 'active show': customizer == 'user-profie' }" id="c-pills-home" role="tabpanel"
        aria-labelledby="c-pills-home-tab">
        <div class="notes-panel" [ngClass]="{'open-notes': true}">
          <div class="row">
            <div class="col-md-12 mx-auto">
              <app-customer-account></app-customer-account>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
 
</div>