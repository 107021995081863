<div class="container-fluid tab-form-wrapper">
  <form>
  <div class="row form-group">
    <div class="col-md-3">
<label>First Name</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Middle Name</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Last Name</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>SSN Number (Last 4 Digit)</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>DOB</label>
    </div>
    <div class="col-md-9">
      <input type="date" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Password</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Alternate PN</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div> 
   <div class="row form-group">
    <div class="col-md-3">
<label>Customer Security Question</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Answer</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>Email</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <h6 class="my-3">Authorize User 1</h6>
  <div class="row form-group">
    <div class="col-md-3">
<label>A/U Name</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>A/U Relationship</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>A/U Dob</label>
    </div>
    <div class="col-md-3">
      <select class="form-control form-select">
        <option>Year </option>
        </select>
    </div>
    <div class="col-md-3">
      <select class="form-control form-select">
        <option>Month </option>
        </select>
    </div>
    <div class="col-md-3">
      <select class="form-control form-select">
        <option>Day </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>A/U SSN (Last 4 digit)</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>A/U Password</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>A/U S/Q</label>
    </div>
    <div class="col-md-9">
      <select class="form-control form-select">
        <option>Select </option>
        </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3">
<label>AU S/Q Answer</label>
    </div>
    <div class="col-md-9">
      <input type="text" class="form-control" >
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12 text-end">
      <button type="button" class="btn btn-primary">Submit</button>
    </div>
  </div>
  </form>
  </div>