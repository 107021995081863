<div class="customizer-contain">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header sticky-header2">
      <h5 class="mb-0">
        Resources Category <i class="fa fa-question-circle cursor-pointer" style="font-size: 17px;"></i>
      </h5>
      <i class="icofont-close icon-close" (click)="closeModal3()"></i>
    </div>

    <div id="main">
    <div class="card ">
      <div class="col-md-12 text-end mt-3">
        <button class="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseExample"
          aria-expanded="false" aria-controls="collapseExample">
          Create
        </button>
      </div>
      <form [formGroup]="resourcesTypeForm">
        <div class="row bg-light mt-3 px-4 py-4" id="collapseExample">
          <div class="col-md-4 mb-3">
            <label for="resourceName">Category Name</label>
            <input type="text" class="form-control" id="resourceName" formControlName="resourceName"
              placeholder="Enter resource name"
              [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceName').invalid && resourcesTypeForm.get('resourceName').touched}">
          </div>
          <div class="col-md-4 mb-3">
            <label for="resourceDescription">Description</label>
            <input type="text" class="form-control" id="resourceDescription" formControlName="resourceDescription"
              placeholder="Enter description"
              [ngClass]="{'is-invalid': resourcesTypeForm.get('resourceDescription').invalid && resourcesTypeForm.get('resourceDescription').touched}">
          </div>
          <div class="col-md-4 mb-3">
            <label for="displayName">Display Name</label>
            <input type="text" class="form-control" id="displayName" formControlName="displayName"
              placeholder="Enter display name"
              [ngClass]="{'is-invalid': resourcesTypeForm.get('displayName').invalid && resourcesTypeForm.get('displayName').touched}">
          </div>
          <div class="col-md-4 mt-4 gap-2">
            <label class="d-flex gap-1">
              <input type="radio" formControlName="status" [value]="1">
              Active
            </label>
            <label class="d-flex gap-1">
              <input type="radio" formControlName="status" [value]="0">
              Inactive
            </label>
          </div>

          <div class="col-md-12 text-end mt-3 px-0">
            <button *ngIf="!showupdate" class="btn btn-outline-info" style="font-size: 15px;" type="submit"
              (click)="submitForm()">Submit</button>
            <div class="col-md-12 text-end " *ngIf="showupdate">
              <button class="btn btn-outline-warning mx-2" style="font-size: 15px;" type="button"
                (click)="update()">Update</button>
              <button class="btn btn-outline-danger" style="font-size: 15px;" type="button"
                (click)="resetForm()">Reset</button>
            </div>
          </div>
        </div>

      </form>
<!-- 
      <div class=" table-responsive mt-2" style="height: 400px;">
        <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-alpine" [rowData]="resourcesGategoryList"
          [headerHeight]="43" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
          (cellClicked)="onCellClicked($event)">
        </ag-grid-angular>
      </div> -->
      <div class="table-pagination-container">
        <!-- Table Section -->
        <div class="table-container">
          <div class="ag-grid-wrapper mt-0">
            <ag-grid-angular
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              [rowData]="resourcesGategoryList"
              [headerHeight]="43"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              (cellClicked)="onCellClicked($event)">
            </ag-grid-angular>
  
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>