import {  HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { forkJoin, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpService } from "./httpService.service";
import { AuthService } from "src/app/auth/services/auth.service";

interface SearchRecord {
  id?: number; // Auto-incremented key
  searchTerm: string;
  activeTab: string;
  items: any;
  timestamp: any;
}

@Injectable({
  providedIn: "root",
})
export class SearchHistoryService {
  private storeName = "unity_searches";
  public company = null;
  unityData: { company: string; data: SearchRecord[] } = { company: 'unity', data: [] };
  mingleData: { company: string; data: SearchRecord[] } = { company: 'mingle', data: [] };
  constructor(private dbService: NgxIndexedDBService,  private http: HttpService, private httpBackend: HttpBackend, private injector: Injector) {}

  // Initialize tables for Unity and Mingle
  async initializeSearchHistory(): Promise<void> {
    try {
      await this.initializeTables();
    } catch (error) {
      console.error("Error initializing search history:", error);
    }
  }
  private get authService(): AuthService {
    return this.injector.get(AuthService);
  }
  private async initializeTables(): Promise<void> {
    const unityStoreExists = await this.dbService
      .count("unity_searches")
      .toPromise();
    if (unityStoreExists === 0) {
      const dummyRecord: SearchRecord = {
        searchTerm: "",
        activeTab: "",
        items: null,
        timestamp: new Date(),
      };
      this.dbService.add("unity_searches", dummyRecord).subscribe({
        next: () => console.log("Unity store initialized with a dummy record"),
        error: (error) =>
          console.error("Error initializing Unity store:", error),
      });
    }

    const mingleStoreExists = await this.dbService
      .count("mingle_searches")
      .toPromise();
    if (mingleStoreExists === 0) {
      const dummyRecord: SearchRecord = {
        searchTerm: "",
        activeTab: "",
        items: null,
        timestamp: new Date(),
      };
      this.dbService.add("mingle_searches", dummyRecord).subscribe({
        next: () => console.log("Mingle store initialized with a dummy record"),
        error: (error) =>
          console.error("Error initializing Mingle store:", error),
      });
    }
  }
  getCompanyName() {
    if (!this.company) {
      this.company = localStorage.getItem("currentCompany");
    }
    return this.company;
  }
  private getStoreName(): string {
    const companyName = this.getCompanyName(); // Fetch from session storage
    return companyName === "unity" ? "unity_searches" : "mingle_searches";
  }

  // Save a search record
  saveSearchRecord(searchTerm: string, activeTab: string, items: any): void {
    const storeName = this.getStoreName();
    const timestamp = new Date();
    const record: SearchRecord = { searchTerm, activeTab, items, timestamp };
    console.log(
      "saving records: ",
      storeName,
      timestamp + "and Records : ",
      record
    );
    this.dbService.add(storeName, record).subscribe({
      next: (key) =>
        console.log(
          `Search record saved successfully in ${storeName} with key:`,
          key
        ),
      error: (error) =>
        console.error(`Error saving search record in ${storeName}:`, error),
    });
  }

  // Retrieve all search history records for the current company
  getAllSearchRecords(): Promise<SearchRecord[]> {
    const storeName = this.getStoreName(); // Determine the current company's store
    return new Promise((resolve, reject) => {
      this.dbService.getAll(storeName).subscribe({
        next: (records) => {
          // Exclude dummy records and return valid ones
          const validRecords = (records as SearchRecord[]).filter(
            (record) => record.searchTerm !== "" || record.activeTab !== ""
          );
          this.getAllRecords();
          resolve(validRecords);
        },
        error: (error) => {
          console.error(`Error fetching records from ${storeName}:`, error);
          reject(error);
        },
      });
    });
  }

  getAllRecords(): Promise<any> {
    const stores = ['unity_searches', 'mingle_searches']; // List of all store names
  
    return new Promise((resolve, reject) => {
      // Fetch data from all stores concurrently
      const fetchRequests = stores.map((store) => this.dbService.getAll(store));
  
      forkJoin(fetchRequests).subscribe({
        next: (results: SearchRecord[][]) => {
          // Separate results for each store
          const unityResults = results[0] || [];
          const mingleResults = results[1] || [];
  
          // Convert timestamps and map results into the required format
          this.unityData = {
            company: 'unity',
            data: unityResults
              .filter((record) => record.searchTerm !== '' || record.activeTab !== '')
              .map((record) => ({
                ...record,
                timestamp: this.convertTimestamp(record.timestamp), // Convert to formatted string
              })),
          };
  
          this.mingleData = {
            company: 'mingle',
            data: mingleResults
              .filter((record) => record.searchTerm !== '' || record.activeTab !== '')
              .map((record) => ({
                ...record,
                timestamp: this.convertTimestamp(record.timestamp), // Convert to formatted string
              })),
          };
  
          // Log formatted data
          console.log('Unity Data:', this.unityData);
          console.log('Mingle Data:', this.mingleData);
  
          // Combine results and resolve
          const combinedRecords = [this.unityData, this.mingleData];
          console.log('combinedRecords:', combinedRecords);
          resolve(combinedRecords);
        },
        error: (error) => {
          console.error('Error fetching records from stores:', error);
          reject(error);
        },
      });
    });
  }
  
  
  convertTimestamp(timestamp: any): string {
    // Handle both Date objects and string timestamps
    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
  
    if (isNaN(date.getTime())) {
      throw new Error('Invalid timestamp format');
    }
  
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Extract time as is
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Return formatted string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  
  
  // Clear all search records for the current company
  clearAllSearchHistory(): void {
    const storeName = this.getStoreName();
    this.dbService.clear(storeName).subscribe({
      next: () => console.log(`All search history cleared for ${storeName}.`),
      error: (error) =>
        console.error(`Error clearing search history for ${storeName}:`, error),
    });
  }

  // Cleanup old records based on retention days (for day-based cleanup)
  cleanupOldRecords(daysToKeep: number): void {
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - daysToKeep);

    const storeName = this.getStoreName();
    this.dbService.getAll(storeName).subscribe((records) => {
      (records as SearchRecord[]).forEach((record) => {
        const recordDate = new Date(record.timestamp);
        if (recordDate < cutoffDate) {
          this.dbService.deleteByKey(storeName, record.id).subscribe({
            next: () =>
              console.log(
                `Deleted record with id ${record.id} older than ${daysToKeep} days`
              ),
            error: (error) =>
              console.error(`Error deleting old record: ${error}`),
          });
        }
      });
    });
  }

  // Initial cleanup check based on environment policy
  initializeSearchHistoryCleanup(): void {
    if (environment.searchHistoryStorageBasis === "login") {
      this.clearAllSearchHistory();
    } else if (environment.searchHistoryStorageBasis === "days") {
      this.cleanupOldRecords(environment.searchHistoryDaysToKeep);
    }
  }

  // Admin configurable methods to set storage basis and retention days
  setStorageBasis(basis: "login" | "days"): void {
    environment.searchHistoryStorageBasis = basis;
    console.log(`Search history storage basis set to '${basis}'.`);
  }

  setRetentionDays(days: number): void {
    if (environment.searchHistoryStorageBasis === "days") {
      environment.searchHistoryDaysToKeep = days;
      console.log(`Search history retention updated to ${days} days.`);
    }
  }
  // Retrieve recent search records (e.g., last 5)
  getRecentSearchRecords(limit: number = 5): Promise<SearchRecord[]> {
    const storeName = this.getStoreName();
    return new Promise((resolve) => {
      this.dbService.getAll(storeName).subscribe((records) => {
        const sortedRecords = (records as SearchRecord[]).sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
        resolve(sortedRecords.slice(0, limit));
      });
    });
  }

  clearStore(storeName: string): void {
    this.dbService.clear(storeName).subscribe({
      next: () => console.log(`All search history cleared for ${storeName}.`),
      error: (error) =>
        console.error(`Error clearing search history for ${storeName}:`, error),
    });
  }

  
 async  sendRecentSearchData(searchData: any) {
    // Determine the base URL based on the company property
    let baseUrl = '';
    let token = ''; // Initialize the token variable
    let userId: number | null = null;
    if (searchData?.company === 'mingle') {
      baseUrl = environment.Prepaid_Mingle_API; // Use Mingle API base URL
      const result = await this.authService.getCompanyTokens('mingle');
      token = result.token;
      userId = result.userId;
      console.log('token: ',token);
    } else if (searchData?.company === 'unity') {
      baseUrl = environment.BASE_API; // Use Unity API base URL
      const result = await this.authService.getCompanyTokens('unity');
      token = result.token;
      userId = result.userId;

    } else {
      console.error('Invalid company type in search data:', searchData?.company);
      throw new Error('Invalid company type in search data');
    }
  
    // Set the complete API URL
    const apiUrl = `${baseUrl}api/orders/globalSearchLog`;
  
    // Create headers with the dynamically selected token
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Use the correct token
    });
  
    console.log('Sending Request to:', apiUrl);
    console.log('Headers:', headers.keys().reduce((acc, key) => {
      acc[key] = headers.get(key);
      return acc;
    }, {}));
    console.log('Payload:', searchData);
  
    // Use HttpClient to send the request
    const customHttp = new HttpClient(this.httpBackend);
  
    // Make the HTTP POST request
    return await customHttp.post(apiUrl, searchData, { headers }).toPromise();
  }
  

}
