import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuickLinksComponent } from "./quick-links.component";
import { AddDataComponent } from "./add-data/add-data.component";
import { AddTopupComponent } from "./add-topup/add-topup.component";
import { AddProductComponent } from "./add-product/add-product.component";
import { AutopayComponent } from "./autopay/autopay.component";
import { ChangeAccountTypeComponent } from "./change-account-type/change-account-type.component";
import { ChangeApsComponent } from "./change-aps/change-aps.component";
import { EquipmentPurchaseComponent } from "./equipment-purchase/equipment-purchase.component";
import { ExistingComponent } from "./existing/existing.component";
import { FeaturesComponent } from "./features/features.component";
import { PlanChangeComponent } from "./plan-change/plan-change.component";
import { PlanRenewComponent } from "./plan-renew/plan-renew.component";
import { UpgradeFamilyPlanComponent } from "./upgrade-family-plan/upgrade-family-plan.component";
import { UsageComponent } from "./usage/usage.component";
import { ViewPlansComponent } from "./view-plans/view-plans.component";
import { CreditCardComponent } from "./add-topup/credit-card/credit-card.component";
import { CashComponent } from "./add-topup/cash/cash.component";
import { MoneyOrderComponent } from "./add-topup/money-order/money-order.component";
import { MoneyGramComponent } from "./add-topup/money-gram/money-gram.component";
import { WalletComponent } from "./add-topup/wallet/wallet.component";
import { DealerWalletComponent } from "./add-topup/dealer-wallet/dealer-wallet.component";
import { FreeTopupComponent } from "./add-topup/free-topup/free-topup.component";
import { CreditCardAddDataComponent } from "./add-data/credit-card-add-data/credit-card-add-data.component";
import { CashAddDataComponent } from "./add-data/cash-add-data/cash-add-data.component";
import { MoneyOrderAddDataComponent } from "./add-data/money-order-add-data/money-order-add-data.component";
import { MoneyGramAddDataComponent } from "./add-data/money-gram-add-data/money-gram-add-data.component";
import { WalletAddDataComponent } from "./add-data/wallet-add-data/wallet-add-data.component";
import { DealerWalletAddDataComponent } from "./add-data/dealer-wallet-add-data/dealer-wallet-add-data.component";
import { NewPlanChangeComponent } from "./new-plan-change/new-plan-change.component";

const routes: Routes = [
 /*  {
    path: "",
    component: QuickLinksComponent,
    children: [
     
      { path: "add-data", component: AddDataComponent,children:[
        { path: "creditcard", component: CreditCardAddDataComponent },
        { path: "cash", component: CashAddDataComponent },
        { path: "money-order", component: MoneyOrderAddDataComponent },
        { path: "money-gram", component: MoneyGramAddDataComponent },
        { path: "wallet", component: WalletAddDataComponent },
        { path: "dealer-wallet", component: DealerWalletAddDataComponent },
      ] },
      { path: "add-topup", component: AddTopupComponent,children:[
        { path: "creditcard", component: CreditCardComponent },
        { path: "cash", component: CashComponent },
        { path: "money-order", component: MoneyOrderComponent },
        { path: "money-gram", component: MoneyGramComponent },
        { path: "wallet", component: WalletComponent },
        { path: "dealer-wallet", component: DealerWalletComponent },
        { path: "free-topup", component: FreeTopupComponent },
      ] },
      { path: "add-product", component: AddProductComponent },
      { path: "autopay", component: AutopayComponent },
      { path: "change-account", component: ChangeAccountTypeComponent },
      { path: "change-aps", component: ChangeApsComponent },
      { path: "equipment", component: EquipmentPurchaseComponent },
      { path: "existing", component: ExistingComponent },
      { path: "features", component: FeaturesComponent },
      { path: "plan-change", component: PlanChangeComponent },
      { path: "plan-renew", component: PlanRenewComponent },
      { path: "upgrade-family", component: UpgradeFamilyPlanComponent },
      { path: "usage", component: UsageComponent },
      { path: "view-plans", component: ViewPlansComponent },

    ]
  }, */

  {
    path:'plan-change',
    component:NewPlanChangeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuickLinksRoutingModule { }
