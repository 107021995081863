<div class="container">
    <div class="d-flex align-items-center justify-content-between overflow-auto">
        <div class="d-flex gap-3 align-items-center">
            <button (click)="back()" class="custom-btn bg-transparent d-flex align-items-center">
                <i class="icon-arrow-left fw-semibold"></i>
            </button>
            <span class="title heading">
                #{{this.id}}
            </span>

            <span class="custom-badge" [ngClass]="getStatusBadgeClass(order.PaymentStatus)"
                [matTooltip]="'Payment Status'" *ngIf="order?.PaymentStatus" [matTooltipPosition]="'above'">
                {{order?.PaymentStatus}}
            </span>
            <span class="custom-badge bg-yellow" *ngIf="order?.ShipmentStatus" [matTooltip]="'Fullfillment Status'"
                [matTooltipPosition]="'above'">
                {{order?.ShipmentStatus}}
            </span>
            <span class="custom-badge" [ngClass]="getStatusBadgeClass2(order.OrderStatus)" *ngIf="order?.OrderStatus"
                [matTooltip]="'Order Status'" [matTooltipPosition]="'above'">
                {{order?.OrderStatus}}
            </span>
            <span class="custom-badge " *ngIf="order?.OrderType" [matTooltip]="'Order Type'"
                [matTooltipPosition]="'above'">
                {{order?.OrderType}}
            </span>
        </div>
        <div class="d-flex gap-2 align-items-center">
            <!-- <button class="custom-btn" (click)="refundOrder()">Refund</button> -->

            <!-- <button class="custom-btn" (click)="editOrder()">Edit</button> -->

            <!-- <button class="custom-btn" (click)="printTable()">
                <div class="d-flex gap-2 align-items-center">
                    <i class="fa fa-print"></i>
                    <span class="text">Print</span>
                </div>
            </button>
            <button class="custom-btn">
                <div class="d-flex gap-2 align-items-center">
                    <span class="text">More actions</span>
                    <i class="fa fa-angle-down"></i>
                </div>
            </button> -->

            <div class="d-flex gap-1 align-items-center">
                <button class="custom-btn" (click)="onClick(true);">
                    <i class="fa fa-angle-left"></i>
                </button>
                <button class="custom-btn" (click)="onClick(false)">
                    <i class="fa fa-angle-right"></i>
                </button>
            </div>
        </div>

    </div>
    <div class="row mx-0 mt-3" @fadeInOut>
        <div class="col-lg-9">
            <div class="custom-card">
                <div class="d-flex align-items-center justify-content-between" *ngIf="order?.ShipmentStatus">
                    <span class="custom-badge  bg-yellow">
                        <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true">
                            <path
                                d="M12.53 4.78a.75.75 0 0 0-1.06-1.06l-1.47 1.47-1.47-1.47a.75.75 0 0 0-1.06 1.06l1.47 1.47-1.47 1.47a.75.75 0 0 0 1.06 1.06l1.47-1.47 1.47 1.47a.75.75 0 1 0 1.06-1.06l-1.47-1.47 1.47-1.47Z">
                            </path>
                            <path fill-rule="evenodd"
                                d="m14.844 10-.336-2.265a.75.75 0 1 1 1.484-.22l.413 2.792c.063.425.095.853.095 1.282v1.661a3.25 3.25 0 0 1-3.25 3.25h-6.5a3.25 3.25 0 0 1-3.25-3.25v-1.66c0-.43.032-.858.094-1.283l.414-2.792a.75.75 0 0 1 1.484.22l-.336 2.265h2.484a1.25 1.25 0 0 1 1.185.855l.159.474a.25.25 0 0 0 .237.171h1.558a.25.25 0 0 0 .237-.17l.159-.475a1.25 1.25 0 0 1 1.185-.855h2.484Zm-9.843 1.5-.001.09v1.66c0 .967.784 1.75 1.75 1.75h6.5a1.75 1.75 0 0 0 1.75-1.75v-1.75h-2.46l-.1.303a1.75 1.75 0 0 1-1.66 1.197h-1.56a1.75 1.75 0 0 1-1.66-1.197l-.1-.303h-2.46Z">
                            </path>
                        </svg>
                        <span class="text-nowrap">
                            {{order?.ShipmentStatus}}
                        </span>
                    </span>
                    <div class="d-flex align-items-center">
                        <app-feather-icons [icon]="'more-horizontal'" class="mt-1"></app-feather-icons>
                    </div>
                </div>
                <div class="border mt-2">
                    <div class="items">
                        <span class="text-muted">Delivery method</span>
                        <span>Shipping not required</span>
                    </div>
                    <div class="items flex-row justify-content-between" [ngClass]="{'border-bottom-0': last}"
                        *ngFor="let item of order?.order_item;let last = last">
                        <div class="d-flex align-items-center gap-3">
                            <img width="40" height="40" class="img" [src]="item.product_combo_item.ImagePath">

                            <div class="d-flex gap-1 flex-column">
                                <span class="fs-6 ">{{item.product_combo_item.devicemakemodel.Make}}
                                    {{item.product_combo_item.devicemakemodel.Model}}</span>
                                <span class="custom-badge justify-content-center">${{item.TotalPrice}}</span>
                            </div>
                        </div>
                        <div class="d-flex gap-5 align-items-center">
                            <span>${{item.TotalPrice}} × {{item.Quantity}} </span>
                            <span>${{ calculateTotalPrice(item) }}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-2 gap-2">
                    <app-filter-btn [title]="'Convert order'" (click)="convertOrder(convertOrderModal,order?.OrderType)"
                        [isBtn]="true"></app-filter-btn>
                    <button class="custom-btn dark " *ngIf="order?.OrderStatus =='Cancelled'"
                        (click)="restoreOrder()">Restore Order</button>
                    <button class="custom-btn dark " *ngIf="order?.OrderStatus !='Cancelled'"
                        (click)="cancelOrder()">Cancel Order</button>
                    <!-- <button class="custom-btn  ">Create shipping label</button> -->
                </div>
            </div>

            <div class="custom-card mt-3">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="custom-badge" [ngClass]="getStatusBadgeClass(order.PaymentStatus)"
                        [matTooltip]="'Payment Status'" *ngIf="order?.PaymentStatus" [matTooltipPosition]="'above'">
                        {{order?.PaymentStatus}}
                    </span>
                </div>
                <div class="border mt-3">
                    <div class="items gap-2">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-between gap-10">
                                <span>Subtotal</span>
                                <span> {{order.order_item?.length || 1}} item</span>
                            </div>
                            <span class="">${{getSubTotal()}}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-between gap-10">
                                <span>Shipping</span>
                                <span> International Shipping (0.0 kg: Items 0.0 kg, Package 0.0 kg)</span>
                            </div>
                            <span class="">$0.00</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-between gap-10">
                                <span class="fw-semibold">Total </span>
                                <span> </span>
                            </div>
                            <span class="">${{getSubTotal()}}</span>
                        </div>
                    </div>
                    <div class="items border-bottom-0">
                        <div class="d-flex gap-5 align-items-center justify-content-between">
                            <span>Paid </span>
                            <!-- <span>${{paidTotal.toFixed(2)}}</span> -->
                            <span>${{getSubTotal()}}</span>
                        </div>
                    </div>

                </div>

            </div>
            <div class="p-3">
                <span class="text-muted fs-6">Timeline</span>
            </div>
            <div class='position-relative'>
                <div class="timeline-card">
                    <div>
                        <div class="editor">
                            <div class="pe-2 flex-shrink">
                                <span class="avatar">
                                    <span class="initails">
                                        <svg class="Polaris-Avatar__Svg" viewBox="0 0 40 40"><text
                                                class="Polaris-Avatar__Text" x="50%" y="50%" dy="0.35em"
                                                fill="currentColor" text-anchor="middle">AK</text></svg>
                                    </span>
                                </span>

                            </div>
                            <input type="text" class="form-control border-0" placeholder="Leave a comment..."
                                [(ngModel)]="commentText">
                        </div>
                        <div class="action">
                            <div class="d-flex align-items-center p-1 gap-3 position-relative">
                                <span placement="top" ngbTooltip="Add emoji" (click)="openEmojiDropdown()">
                                    <i [attr.data-feather]="'smile'" [attr.width]="'20'" [attr.height]="'20'"
                                        [attr.stroke]="'rgba(138, 138, 138, 1)'" title="Add Emoji"></i>
                                </span>
                                <span placement="top" ngbTooltip="Mention staff" (click)="openMentionDropdown()">
                                    <i [attr.data-feather]="'at-sign'" [attr.width]="'18'" [attr.height]="'18'"
                                        [attr.stroke]="'rgba(138, 138, 138, 1)'"></i>
                                </span>
                                <!-- <span placement="top" ngbTooltip="Refrence page">
                                    <i [attr.data-feather]="'hash'" [attr.width]="'18'" [attr.height]="'18'"
                                        [attr.stroke]="'rgba(138, 138, 138, 1)'"></i>
                                </span>
                                <span placement="top" ngbTooltip="Attach file">
                                    <i [attr.data-feather]="'paperclip'" [attr.width]="'18'" [attr.height]="'18'"
                                        [attr.stroke]="'rgba(138, 138, 138, 1)'"></i>
                                </span> -->
                                <emoji-mart *ngIf="showEmojiDropdown" class="emoji-container"
                                    (emojiClick)="addEmoji($event)"></emoji-mart>

                                <div class="emoji-container" *ngIf="showStaffDropdown">
                                    <div class="staff-dropdown" *ngIf="showStaffDropdown">
                                        <div class="dropdown-search">
                                            <input type="text" class="search-input" placeholder="Search staff..."
                                                [(ngModel)]="searchQuery" (input)="filterStaff()" />
                                        </div>
                                        <ul class="dropdown-list">
                                            <li class="dropdown-item" *ngFor="let staff of filteredStaffList"
                                                (click)="selectStaff(staff)">
                                                <span class="staff-item">
                                                    <span class="avatar">{{ getInitials(staff.user.name) }}</span>
                                                    <span>{{ staff.user.name }}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <app-filter-btn [title]="'Post'" [isBtn]="true"
                                (click)="onAddCommentClick()"></app-filter-btn>
                        </div>
                    </div>
                </div>
                <div class="notification">
                    <ul class="timeline-list p-0 position-unset" *ngIf="timelineData.length>0">
                        <li>
                        </li>
                        <li>
                        </li>
                        <li class="d-flex mb-4" *ngFor="let item of timelineData">
                            <div class="activity-dot-primary"></div>
                            <div class="w-100 ms-3">
                                <span class="mb-3 fw-semibold text-muted">{{item?.ActivityDetails}}</span>
                                <div class="d-flex justify-content-between my-3">
                                    <span class=" ">{{item?.Description}}</span>
                                    <span class=" fw-semibold text-muted">{{item?.CreatedDate| date}}</span>
                                </div>
                            </div>
                        </li>

                    </ul>
                    <div class="d-flex justify-content-center  mt-3" *ngIf="timelineData.length==0">
                        <span class="text-danger">No data found</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <app-customer-information [customerDetails]="customerDetails"></app-customer-information>
        </div>
    </div>
    <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;" class="row mx-0 mt-3 p-1">
        <div class="row w-100 gap-4 mx-0">
            <div class="col-md-7">
                <app-skeleton-loader [cardCount]="1" [width]="'100%'" [height]="'20vh'" [alignment]="'column'"
                    shape="normal-card"></app-skeleton-loader>
                <app-skeleton-loader [cardCount]="1" [width]="'100%'" [height]="'25vh'" [alignment]="'column'"
                    shape="normal-card"></app-skeleton-loader>
                <app-skeleton-loader shape="timeline-card"></app-skeleton-loader>
            </div>

            <div class="col-md-4">
                <app-skeleton-loader [cardCount]="1" [width]="'100%'" [height]="'20vh'" [alignment]="'column'"
                    shape="normal-card"></app-skeleton-loader>
                <app-skeleton-loader [cardCount]="1" [width]="'100%'" [height]="'60vh'" [alignment]="'column'"
                    shape="normal-card"></app-skeleton-loader>
            </div>
        </div>
    </div>

</div>

<ng-template #convertOrderModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Convert Order</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to convert this {{ order?.OrderType }} to {{ alternateType }}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirmRefund(); modal.close()">Confirm</button>
    </div>
</ng-template>