<div class="p-3" style="margin-top: -10px;">
    <!-- <app-breadcrumb [title]="breadcrumbTitle" [items]="breadcrumbItems" [active_item]="breadcrumbTitle"></app-breadcrumb> -->
    <ng-container *ngIf="this.customerProfileService.minimalProfileData?.alerts?.length>0">
        <div *ngFor="let alert of this.customerProfileService.minimalProfileData?.alerts;let i = index">
            <!-- <ngb-alert role="alert"  [ngStyle]="{background: alert.color}"
                class="alert alert-success show fade alert-dismissible">
                {{alert.message}}
            </ngb-alert> -->
            <!-- <ngb-alert role="alert" (closed)="clearAlerts(alert)"
                class="alert show alert-success fade alert-dismissible alert-warning"
                style="/* background: rgb(44 50 65); */border-radius: 11px;background-image: linear-gradient(to right, #213fd9, #dc4bee, #d92128, #ed3f3f); border: none;">
                <img src="https://app.crowdsignal.com/images/logo-white.svg" style="
                height: 22px;
            "> {{alert.message}} <button type="button" aria-label="Close" class="btn-close ng-star-inserted"></button></ngb-alert> -->
        </div>
    </ng-container>
    <!-- <app-breadcrumb [isCustomerProfilePage]="true"></app-breadcrumb> -->


    <!-- <app-breadcrumb [isCustomerProfilePage]="true"></app-breadcrumb> -->
    <div class="d-flex justify-content-start mb-2">
        <button *ngIf="showBackButton" class="btn btn-primary btn-sm" (click)="backToOrderList()">Go back </button>
    </div>
    <!-- *ngIf="isHiddenData[0]?.isHidden == 1" -->
    <div class="card ms-10">
        <div class="card-body p-0">
            <div class="container-fluid d-flex">
                <nav class="nav my-auto nav-pills flex-sm-row">
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[0]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/customer-profile']" (click)="changeTabs('customer-profile')"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}"> <i class="icofont icofont-ui-user"></i> Customer Profile</a>
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[1]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/billing-invoice']" (click)="changeTabs('billing-invoice')"
                        routerLinkActive="active"> <i class="icofont icofont-bill-alt"></i> Billing and Invoice</a>
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[2]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/order-history']" (click)="changeTabs('order-history')"
                        routerLinkActive="active"> <i class="icofont icofont-shopping-cart"></i> Order History</a>
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[3]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/purchase']" (click)="changeTabs('purchase')"
                        routerLinkActive="active"> <i class="icofont icofont-history"></i> Purchase History</a>
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[4]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/tickets']" (click)="changeTabs('tickets')"
                        routerLinkActive="active"> <i class="icofont icofont-ticket"></i> Tickets</a>
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[5]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/customer-history']" (click)="changeTabs('customer-history')"
                        routerLinkActive="active"> <i class="icofont icofont-history"></i> Customer History</a>
                    <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark" *ngIf="isHiddenData[6]?.isHidden == 1"
                        [routerLink]="['/customer-dashboard/activity-log']" (click)="changeTabs('activity-log')"
                        routerLinkActive="active"> <i class="icofont icofont-ui-timer"></i> Activity Log</a>
                    <!-- <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark"
                        [routerLink]="['/customer-dashboard/manage-card']" (click)="changeTabs('manage-card')"
                        routerLinkActive="active"> <i class="icofont icofont-ui-v-card"></i> Manage Card</a> -->
                    <!-- <a class="tab-link flex-sm-fill text-sm-center nav-link text-dark"
                        [routerLink]="['/customer-dashboard/files']" (click)="changeTabs('files')"
                        routerLinkActive="active"> <i class="icofont icofont-file-document"></i> Files</a> -->

                </nav>
                <div class="controls d-flex justify-content-end my-2 ">
                    <app-feather-icons class="hover-primary cursor-pointer" [icon]="'arrow-left-circle'"
                        (click)="changeTabLeft()"></app-feather-icons>
                    <app-feather-icons [icon]="'arrow-right-circle'" (click)="changeTabRight()"
                        class="ms-2 hover-primary cursor-pointer"></app-feather-icons>
                    <!--         <button type="button" class="btn btn-primary" (click)="changeTabLeft()">l</button>
                <button type="button" class="btn ms-2 btn-primary" (click)="changeTabRight()">R</button> -->
                </div>

            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
    <br><br><br>

    <div style="position: fixed;bottom: -3px;width: 100%;z-index: 998;left: 102px;">
        <app-customer-footer></app-customer-footer>
    </div>


    <app-customizer [showModal]="showModal"></app-customizer>
</div>