import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'error-validators',
  templateUrl: './error-validators.component.html',
  styleUrls: ['./error-validators.component.scss']
})
export class ErrorValidatorsComponent {
  @Input() control: AbstractControl;
}
